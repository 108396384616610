.hero_container {
  height: 100vh;
  width: 100%;
  position: relative;
}

.bg_img {
  height: 100%;
  width: 100%;
}

.content_container {
  height: 70%;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -42%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  padding: 1rem 4rem 1rem 6rem;
}

.content_container>.titleText {
  width: 50%;
  font-size: 4.4rem;
  font-weight: 300;
  line-height: 4.6rem;
}

.bt_container{
  width: 50%;
  font-size: 4.4rem;
  font-weight: 300;
  line-height: 4.6rem;
}

.hoverText {
  transition:
  0.35s font-weight,
  0.35s color;
}

.hoverText:hover {
  font-weight: 900;
  color: rgb(238, 242, 255);
}

/* To the right */
.hoverText:hover + .hoverText {
  font-weight: 500;
  color: rgb(199, 210, 254);
}

.hoverText:hover + .hoverText + .hoverText {
  font-weight: 300;
}

/* To the left */
.hoverText:has(+ .hoverText:hover) {
  font-weight: 500;
  color: rgb(199, 210, 254);
}

.hoverText:has(+ .hoverText + .hoverText:hover) {
  font-weight: 300;
}

.content_img_box>img {
  height: 100%;
  width: 100%;
}

.transition-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  left: 0;
  top: 0;
  pointer-events: none;
  z-index: 2;
}

.transition-container>div {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: black;
}

.transition-background {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: black;
  z-index: 1;
  pointer-events: none;
  top: 0;
  left: 0;
}

.content_img_mb_box {
  display: none;
}

@media(max-width:1050px) {
  .content_container>.titleText {
    font-size: 3rem;
    font-weight: 400;
    line-height: 5rem;
  }
  .bt_container{
    font-size: 3.4rem;
  }
}

@media(max-width:980px) {
  .hero_container {
    height: 90vh;
  }

  .content_container>.titleText {
    font-size: 3rem;
    font-weight: 400;
    line-height: 4rem;
  }
}

@media (max-width:900px) {
  .hero_container {
    height: 85vh;
  }

  .content_container>.titleText {
    font-size: 2.5rem;
    line-height: 3rem;
  }
}

@media (max-width:800px) {
.content_container{
  padding: 1rem;
}
.bt_container{
  font-size: 2.4rem;
  line-height: 3.6rem;
}
}


@media(max-width:600px){
  .content_img_mb_box {
    display: block;
  }
  .hero_container{
    height: 763px;
  }

  .bg_img {
    display: none;
  }

  .content_container {
    flex-direction: column;
    top: 3rem;
    left: 0rem;
    transform: translate(0%, 0%);
    padding: 1rem 4rem 1rem 4rem;
    align-items: flex-start;
  }

  .content_container>.titleText {
    font-size: 3rem;
    line-height: 3.8rem;
    margin-top: 0rem;
  }

  .content_img_box>img{
    height: 300px;
    margin: 0px auto;
    width: 442px;
  }
  .content_img_mb_box>img {
    height: auto ;
    width: 100%;
  }
}

@media (max-width:500px){
  .hero_container{
    height: 790px;
  }
  .bt_container{
    font-size: 3rem;
    line-height: 4rem;
    width: 80%;
  }
  .content_container>.titleText {
    font-size: 2.4rem;
    line-height: 3.2rem;
    margin-top: 0rem;
  }
  .content_container{
    padding: 3rem 1.5rem 1.5rem 1.5rem;
    top: 0rem;
    gap: 1rem;
  }
  .content_img_box>img{
    height: 100%;
    margin: -4px auto;
    width: 100%;
  }
  /* .content_img_mb_box>img {
    height: 120vh;
  } */
}
@media(max-width:450px){
  .hero_container{
    height: 764px;
  }
  /* .content_img_mb_box>img {
    height: 110vh;
  } */
  .content_container>.titleText{
    font-size: 36px;
    line-height: 3.2rem;
    margin-top: 0rem;
    font-weight: 500;
  }
}

@media(max-width:400px){
  .hero_container{
    height: 710px;
  }

  .content_container>.titleText{
    font-size: 32px;
    line-height: 2.8rem;
  }
}

@media(max-width:376px){
  .hero_container{
    height: 690px;
  }
  /* .content_img_mb_box>img {
    height: 115vh;
  } */
  .content_container>.titleText{
    font-size: 30px;
    line-height: 2.8rem;
  }
}

@media (min-width:1650px){
  .content_container>.titleText{
    font-size: 6.4rem;
    font-weight: 600;
    line-height: 6.6rem;
    width: 50%;
  }
}