
.Ebookheading{
    position: static;
    height: 150px;
    padding-top: 85px;
    text-align: center;
    width: 100%;
    font-size: 40px;
    color: #ff7700;
    font-weight: 500;
}
.bookBox{
    position: relative;
    width: 700px;
}
.bookBoxLogo{
    width: 100%;
 }
 .BookBoxItems{
    position: absolute;
    top: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    left: 1%;
    color: #fff;
    flex-direction: column;
    gap: 2rem;
 }
 .BookBOxInnerImg{
    height: 125px;
}
.bookboxInnerhead{
    font-size: 1.3rem;
}
.bookboxInnerContent {
    text-align: center;
    font-size: 0.85rem;
    width: 100%;
    padding: 0rem 2rem;
}
.knowmore_button1{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #fff;
}
.ebkbtn{
    background: transparent;
    color: #ff7700;
    padding: 10px;
    border: 2px solid #ff7700;
    border-radius: 24px;
    cursor: pointer;
}
.ebkbtn:hover{
    color: white;
    background-color: #ff7700;

}
@media (max-width:800px) {
    .bookBox{
        width: 100%;
    }

}
@media (max-width:628px) {
    .knowmore_button1{
        bottom: 1%;
    }

.bookBoxLogo {
    width: 100%;
    height: 525px;
}
}
@media (max-width:460px){
    .bookboxInnerContent {
        font-size: 0.75rem;
    }
    .bookboxInnerhead {
        font-size: 1rem;
    }
}