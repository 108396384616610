.main_container{
  padding: 2.5rem 0.6rem;
}

.heading{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
}

@media (min-width: 1000px){
  .main_container{
    padding: 4rem 3.5rem;
  }
}