.backdrop {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  pointer-events: auto;
}

.popup {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  position: relative;
  max-width: 500px;
  width: 100%;
  max-height:84vh;
  background: #fff;
}

.flex_c {
  display: flex;
  align-items: center;
}

.cross_btn {
  border: none;
  box-shadow: none;
  border-radius: 50%;
  background: transparent;
  font-size: 1rem;
  color: #000;
  height: 20px;
  width: 20px;
  text-align: center;
}
.cross_btn:hover {
  background: #4747473b;
  transition: all 0.4s ease-in-out;
}

.form_container{
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 8px;
  overflow-y: auto;
  max-height: calc(84vh - 50px);
  margin-top: 10px;
}

.formField{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px;
}

.formField > input{
  padding: 5px;
  background: #e9e9e9;
  border: 1px solid #b4b4b4;
  color: #000000;
  border-radius: 5px;
}

.formField > input::placeholder {
  color: rgb(121, 121, 121) !important;
  opacity: 1;
}

.formField > input:focus {
  outline: none;
  border-color: #ff7700; /* Optional: Add a custom border color on focus */
}

.formField > p{
  margin: 0px !important;
}

.submit_btn{
  background: linear-gradient(90deg, #FF7700 0%, #d56706 100%);
  box-shadow: none;
  font-size: 16px;
  color: #fff;
  padding: 10px;
  border: none;
  transition: all 0.4s ease-in-out;
}

.submit_btn:hover{
  background: linear-gradient(90deg, #f39542 0%, #d46809 100%);
  transition: all 0.4s ease-in-out;
}