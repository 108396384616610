@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,700;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;0,700;1,300;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,700;1,300;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    width: 100%;
    font-family: 'Montserrat', sans-serif;
}

#navbar_knowledge .dropdown_menu {
    /* padding: 10px; */
    height: 150px !important;
    /* height: 90px !important; */
}

.dropdown_menu > .dropItem_container{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 5px 8px;
}

.dropdown_menu > .dropItem_container > .dropItem{
    padding: 6px 8px;
    text-align: center;
    color: #ff7200;
    font-size: 13px;
    letter-spacing: 1px;
    text-decoration: none !important;
    width: 100%;
}
.dropdown_menu > .dropItem:hover{
    color: #ffffff;
}

@media only screen and (min-width:1701px) {

    body {
        width: 100%;
        /*  overflow-x: hidden;  */
        font-family: 'Montserrat', sans-serif;
    }

    .Navbar {
        background-color: black;
        width: 100vw;
        height: 0px;
        /* display: flex; */
        position: fixed;
        z-index: 100;
        animation: mymovenavbar 0.6s;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
    }

    @keyframes mymovenavbar {
        20% {
            top: 0px;
            opacity: 0.1;
            height: 0px
        }

        50% {
            opacity: 0.7;
            height: 40px
        }

        100% {
            opacity: 1;
            height: 70px;
        }
    }

    .Navbar .leftside {
        width: 90%;
        display: flex;
        align-items: center;
        background-color: black;



    }

    .Navbar .leftside #logo {
        width: 148px;
        height: 35px;
        cursor: pointer;
    }


    #logo {
        width: 5px;
        margin-left: 40px;
        margin-top: 12px;
        position: relative;
        left: 13px;
    }


    .Navbar .leftside .links {

        width: 62%;
        position: relative;
        left: 18%;
        top: 2px;
        background-color: black;
        display: flex;
        justify-content: space-between;

    }


    .Navbar .leftside .links a {
        text-decoration: none;
        color: #ff7200;
        letter-spacing: 1px;
        font-size: 13px;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
    }

    .Navbar .leftside .links .active_class {

        color: #fff !important;
        text-underline-offset: 14px !important;
        text-decoration: underline !important;

    }




    #navbar_company:hover,
    #navbar_knowledge:hover {
        text-decoration: none;
    }


    .dropdown_menu {
        display: none;
        position: absolute;
        top: 17px;
        left: -26%;
        z-index: 1;
        background-color: black;
        width: 130px;
        height: 130px;
        cursor: pointer;
    }




    #navbar_company,
    #navbar_knowledge {
        position: relative;
        display: flex;
        justify-content: center;
    }

    #navbar_company:hover .dropdown_menu,
    #navbar_knowledge:hover .dropdown_menu {
        display: block;
        position: absolute;
    }

    #navbar_knowledge .dropdown_menu {
        width: 190px;
    }

    .dropdown_menu .active_class_drop_1 {

        color: #fff !important;
        text-align: center;
        justify-content: center;
        font-size: 13px;
        position: absolute;
        top: 19%;
        left: 18%;
        letter-spacing: 1px;
        text-decoration: none !important;
        cursor: pointer;
    }

    .dropdown_menu .active_class_drop_2 {
        color: #fff !important;
        font-size: 13px;
        position: absolute;
        top: 48%;
        left: 18%;
        letter-spacing: 1px;
        text-decoration: none !important;
        cursor: pointer;

    }

    .dropdown_menu .dropdown1 {
        color: #ff7200;
        text-align: center;
        justify-content: center;
        font-size: 13px;
        position: absolute;
        top: 19%;
        left: 18%;
        letter-spacing: 1px;
        text-decoration: none;
        cursor: pointer;

    }


    .dropdown_menu .dropdown1:hover {
        color: #fff;
        text-decoration: none !important;
    }

    .dropdown_menu .dropdown2 {
        color: #ff7200;
        font-size: 13px;
        position: absolute;
        top: 48%;
        left: 18%;
        letter-spacing: 1px;
        text-decoration: none;
        cursor: pointer;
    }

    .dropdown_menu .dropdown2:hover {
        text-decoration: none !important;
        color: white;
        text-underline-offset: 5px;
    }

    #careers:hover {
        text-decoration: none;
        color: white;
        text-underline-offset: 5px;

    }






    .dropdown_menu #careers {
        color: #ff7200;
        font-size: 13px;
        position: absolute;
        top: 74%;
        left: 18%;
        letter-spacing: 1px;
        text-decoration: none;
    }


    .dropdown_menu #careers:hover {
        text-decoration: none !important;
        color: white;
    }


    .Navbar .leftside .links a:hover {
        color: white;
        text-underline-offset: 14px;
        text-decoration: underline;

    }


    .dropdown1 {

        text-underline-offset: 2px;
        text-decoration: underline;
    }





    .active:after {
        color: #ff7200;
        text-underline-offset: 14px;
        text-decoration: underline;

    }


    .Navbar .leftside #hidden {
        display: flex;

    }

    .Navbar .rightside {
        width: 19%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 65px;


    }

    .contactbtn {
        width: 97px;
        height: 32px;
        border: none;
        border-radius: 6px;
        font-size: 10px;
        cursor: pointer;
        padding-left: 1px;
        background-color: #ff7200;
        font-family: 'Montserrat', sans-serif;
        text-decoration: none;
        color: #fff;

    }

    .new_btn_color {
        background-color: white;
        width: 97px;
        height: 32px;
        border: none;
        border-radius: 6px;
        font-size: 10px;
        cursor: pointer;
        color: #ff7200;

    }


    .servicebtn {
        width: 97px;
        height: 32px;
        border: none;
        border-radius: 6px;
        cursor: pointer;
        font-size: 10px;
        margin-left: 15px;
        color: #fff;
        background-color: #ff7200;
        font-family: 'Montserrat', sans-serif;
    }

    .links_for_mobile {
        display: none;
    }



    .links_navbar {
        display: none;
    }


    #reorder {
        border: none;
        outline: none;
    }

    #ui_icon {
        background-color: black;
        border: none;
        color: #fff;
        outline: none;
    }

    .reorder {
        background-color: black;
        border: none;
        color: #ff7200;
        display: none;
    }
}

@media only screen and (max-width:1701px) {

    body {
        width: 100%;
        /*  overflow-x: hidden;  */
        font-family: 'Montserrat', sans-serif;
    }

    .Navbar {
        background-color: black;
        width: 100vw;
        height: 0px;
        display: flex;
        position: fixed;
        z-index: 100;
        animation: mymovenavbar 0.6s;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
    }

    @keyframes mymovenavbar {
        20% {
            top: 0px;
            opacity: 0.1;
            height: 0px
        }

        50% {
            opacity: 0.7;
            height: 40px
        }

        100% {
            opacity: 1;
            height: 70px;
        }
    }

    .Navbar .leftside {
        width: 80%;
        display: flex;
        align-items: center;
        background-color: black;



    }

    .Navbar .leftside #logo {
        width: 138px;
        height: 30px;
        cursor: pointer;
    }


    #logo {
        width: 5px;
        margin-left: 40px;
        margin-top: 10px;
        position: relative;
        left: 13px;
        cursor: pointer !important;
    }


    .Navbar .leftside .links {

        width: 55%;
        position: relative;
        left: 22%;
        top: 0px;
        background-color: black;
        display: flex;
        justify-content: space-between;

    }


    .Navbar .leftside .links a {
        text-decoration: none;
        color: #ff7200;
        letter-spacing: 1px;
        font-size: 12px;
        cursor: pointer !important;
        font-family: 'Montserrat', sans-serif;
    }

    .Navbar .leftside .links .active_class {

        color: #fff !important;
        text-underline-offset: 14px !important;
        text-decoration: underline !important;

    }


    .dropdown_menu .active_class_drop_1 {

        color: #fff !important;
        text-align: center;
        justify-content: center;
        font-size: 12px;
        position: absolute;
        top: 23%;
        left: 18%;
        letter-spacing: 1px;
        text-decoration: none !important;
    }

    .dropdown {
        position: relative;
        display: inline-block;
    }


    #navbar_company:hover,
    #navbar_knowledge:hover {
        text-decoration: none;
    }



    .dropdown_menu {
        display: none;
        position: absolute;
        top: 16px;
        left: -33%;
        z-index: 1;
        background-color: black;
        width: 130px;
        height: 110px;
    }

    #navbar_knowledge .dropdown_menu {
        width: 150px;
        left: -8px;
    }


    #navbar_company,
    #navbar_knowledge {
        position: relative;
        display: flex;
        justify-content: center;
        cursor: pointer;


    }

    #navbar_company:hover .dropdown_menu,
    #navbar_knowledge:hover .dropdown_menu {
        display: block;
        position: absolute;
    }

    .dropdown_menu .dropdown1 {
        color: #ff7200;
        text-align: center;
        justify-content: center;
        font-size: 12px;
        position: absolute;
        top: 23%;
        left: 18%;
        letter-spacing: 1px;
        text-decoration: none;

    }

    .dropdown_menu .dropdown1:hover {
        color: #fff;
        text-decoration: none !important;
    }

    .dropdown_menu .dropdown2 {
        color: #ff7200;
        font-size: 12px;
        position: absolute;
        top: 52%;
        left: 18%;
        letter-spacing: 1px;
        text-decoration: none;
    }

    .dropdown_menu .dropdown2:hover {
        text-decoration: none !important;
        color: white;
    }

    .dropdown_menu .active_class_drop_2 {
        color: #fff !important;
        font-size: 12px;
        position: absolute;
        top: 52%;
        left: 18%;
        letter-spacing: 1px;
        text-decoration: none !important;

    }

    .dropdown_menu .dropdown3 {
        color: ff7200;
        font-size: 12px;
        position: absolute;
        top: 73%;
        left: 18%;
        letter-spacing: 1px;
        text-decoration: none;
    }

    .dropdown_menu .dropdown3:hover {
        text-decoration: none !important;
        color: white;
    }

    .dropdown_menu .active_class_drop_3 {
        color: #fff !important;
        font-size: 12px;
        position: absolute;
        top: 73%;
        left: 18%;
        letter-spacing: 1px;
        text-decoration: none !important;

    }





    .Navbar .leftside .links a:hover {
        color: white;
        text-underline-offset: 14px;
        text-decoration: underline;

    }

    /* .Navbar .leftside .links .active{

text-underline-offset: 14px;
text-decoration: underline;
text-decoration-color: #fff;
} */

    #dropdown1 {

        text-underline-offset: 2px;
        text-decoration: none;
    }





    .active:after {
        color: #ff7200;
        text-underline-offset: 14px;
        text-decoration: underline;

    }


    .Navbar .leftside #hidden {
        display: flex;

    }

    .Navbar .rightside {
        width: 24%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 65px;


    }

    .contactbtn {
        width: 91px !important;
        height: 32px;
        border: none;
        border-radius: 6px;
        font-size: 9px;
        cursor: pointer;
        padding-left: 1px;
        background-color: #ff7200;
        font-family: 'Montserrat', sans-serif;
        text-decoration: none;
        color: #fff;

    }

    .new_btn_color {
        background-color: white;
        width: 91px;
        height: 32px;
        border: none;
        border-radius: 6px;
        font-size: 10px;
        cursor: pointer;
        color: #ff7200;

    }


    .servicebtn {
        width: 91px;
        height: 32px;
        border: none;
        border-radius: 6px;
        cursor: pointer;
        font-size: 9px;
        margin-left: 15px;
        color: #fff;
        background-color: #ff7200;
        font-family: 'Montserrat', sans-serif;
    }



    .links_for_mobile {
        display: none;
    }



    .links_navbar {
        display: none;
    }


    #reorder {
        border: none;
        outline: none;
    }

    #ui_icon {
        background-color: black;
        border: none;
        color: #fff;
        outline: none;
    }

    .reorder {
        background-color: black;
        border: none;
        color: #ff7200;
        display: none;
    }

    .links_navbar1 {
        cursor: pointer;
    }
}


@media only screen and (max-width:1200px) {

    .reorder {
        display: block;
        position: absolute;
        top: 22px;
        right: 30px;
        float: right;
    }


    @keyframes slide-in {
        100% {
            transform: translateX(100%);
        }


    }

    .links a {
        display: none;
    }

    .active_class {

        display: none;

    }


    .Navbar .leftside {
        position: relative;
        width: 50%;
        background-color: black;
        animation: mymovenavbar 0.6s;
        animation-timing-function: linear;
        animation-fill-mode: forwards;

    }

    @keyframes mymovenavbar {
        20% {
            top: 0px;
            opacity: 0.1;
            height: 0px
        }

        50% {
            opacity: 0.7;
            height: 40px
        }

        100% {
            opacity: 1;
            height: 70px;
        }
    }

    .Navbar .rightside {
        width: 50%;
        display: inline-block;
        display: flex;
        background-color: black;
    }




    .Navbar .leftside #logo {
        width: 110px;
        height: 25px;
        cursor: pointer;

    }







    /*  */

    .Navbar .leftside #hidden {

        display: flex;
        position: absolute;
        left: 0%;
        flex-direction: column;
        justify-content: flex-start;
        top: 70px;
        min-height: 100vh;
        width: 100vw;
        background: black;
        text-align: center;

    }

    .links_navbar1 {
        display: none;
    }

    #navbar_company,
    #navbar_knowledge {
        display: none;
    }

    .rightside button {
        display: none;
    }

    /*  */

    .Navbar .leftside #hidden .mobile_div {
        width: 260px;
        display: flex;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;

    }


    .Navbar .leftside #hidden .links_navbar {

        position: relative;
        margin-bottom: 20px;
        top: 20px;
        left: 0px;
        display: block;
        text-align: center;
        font-size: 13px;
        justify-content: center;
        height: 3.5vw;
        padding: 0px 0px;
        letter-spacing: 1px;

    }

    .Navbar .leftside #hidden .links_navbar {
        display: inline-block !important;
    }






}




@media only screen and (max-width:901px) {

    .reorder {
        display: block;
        position: absolute;
        top: 22px;
        right: 30px;
        float: right;
    }


    @keyframes slide-in {
        100% {
            transform: translateX(100%);
        }


    }


    .Navbar .leftside {
        position: relative;
        width: 50%;
        background-color: black;
        animation: mymovenavbar 0.6s;
        animation-timing-function: linear;
        animation-fill-mode: forwards;

    }

    @keyframes mymovenavbar {
        20% {
            top: 0px;
            opacity: 0.1;
            height: 0px
        }

        50% {
            opacity: 0.7;
            height: 40px
        }

        100% {
            opacity: 1;
            height: 70px;
        }
    }

    .Navbar .rightside {
        width: 50%;
        display: inline-block;
        display: flex;
        background-color: black;
    }




    .Navbar .leftside #logo {
        width: 110px;
        height: 25px;
        cursor: pointer;

    }







    /*  */

    .Navbar .leftside #hidden {

        display: flex;
        position: absolute;
        left: 0%;
        flex-direction: column;
        justify-content: flex-start;
        top: 70px;
        min-height: 100vh;
        width: 100vw;
        background: black;
        text-align: center;

    }

    .links_navbar1 {
        display: none;
    }

    #navbar_company,
    #navbar_knowledge {
        display: none;
    }

    .rightside button {
        display: none;
    }

    /*  */

    .Navbar .leftside #hidden .mobile_div {
        width: 260px;
        display: flex;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;

        position: relative;
        top: 30px;

    }


    .Navbar .leftside #hidden .links_navbar {

        position: relative;
        margin-bottom: 20px;
        top: 0px;
        left: 0px;
        display: block;
        text-align: center;
        font-size: 12px;
        justify-content: center;
        height: 3.5vw;
        padding: 0px 0px;
        letter-spacing: 1px;

    }





}



@media only screen and (max-width:701px) {

    .reorder {
        display: block;
        position: absolute;
        top: 22px;
        right: 30px;
        float: right;
    }


    @keyframes slide-in {
        100% {
            transform: translateX(100%);
        }


    }


    .Navbar .leftside {
        position: relative;
        width: 50%;
        background-color: black;
        animation: mymovenavbar 0.6s;
        animation-timing-function: linear;
        animation-fill-mode: forwards;

    }

    @keyframes mymovenavbar {
        20% {
            top: 0px;
            opacity: 0.1;
            height: 0px
        }

        50% {
            opacity: 0.7;
            height: 40px
        }

        100% {
            opacity: 1;
            height: 70px;
        }
    }

    .Navbar .rightside {
        width: 50%;
        display: inline-block;
        display: flex;
        background-color: black;
    }




    .Navbar .leftside #logo {
        width: 110px;
        height: 25px;
        cursor: pointer;

    }







    /*  */

    .Navbar .leftside #hidden {

        display: flex;
        position: absolute;
        left: 0%;
        flex-direction: column;
        justify-content: flex-start;
        top: 70px;
        min-height: 100vh;
        width: 100vw;
        background: black;
        text-align: center;

    }

    .links_navbar1 {
        display: none;
    }

    #navbar_company,
    #navbar_knowledge {
        display: none;
    }

    .rightside button {
        display: none;
    }

    /*  */

    .Navbar .leftside #hidden .mobile_div {
        width: 260px;
        display: flex;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;

        position: relative;
        top: 50px;

    }


    .Navbar .leftside #hidden .links_navbar {

        position: relative;
        margin-bottom: 20px;
        top: 0px;
        left: 0px;
        display: block;
        text-align: center;
        font-size: 12px;
        justify-content: center;
        height: 3.5vw;
        padding: 0px 0px;
        letter-spacing: 2px;

    }





}



@media only screen and (max-width:501px) {

    .reorder {
        display: block;
        position: absolute;
        top: 22px;
        right: 30px;
        float: right;
    }


    @keyframes slide-in {
        100% {
            transform: translateX(100%);
        }


    }


    .Navbar .leftside {
        position: relative;
        width: 50%;
        background-color: black;
        animation: mymovenavbar 0.6s;
        animation-timing-function: linear;
        animation-fill-mode: forwards;

    }

    @keyframes mymovenavbar {
        20% {
            top: 0px;
            opacity: 0.1;
            height: 0px
        }

        50% {
            opacity: 0.7;
            height: 40px
        }

        100% {
            opacity: 1;
            height: 70px;
        }
    }

    .Navbar .rightside {
        width: 50%;
        display: inline-block;
        display: flex;
        background-color: black;
    }




    .Navbar .leftside #logo {
        width: 110px;
        height: 25px;
        cursor: pointer;
        position: relative;
        left: -6px;
    }







    /*  */

    .Navbar .leftside #hidden {

        display: flex;
        position: absolute;
        left: 0%;
        flex-direction: column;
        justify-content: flex-start;
        top: 70px;
        min-height: 100vh;
        width: 100vw;
        background: black;
        text-align: center;

    }

    .links_navbar1 {
        display: none;
    }

    #navbar_company,
    #navbar_knowledge {
        display: none;
    }

    .rightside button {
        display: none;
    }

    /*  */

    .Navbar .leftside #hidden .mobile_div {
        width: 260px;
        display: flex;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;

        position: relative;
        top: 30px;

    }


    .Navbar .leftside #hidden .links_navbar {

        position: relative;
        margin-bottom: 20px;
        top: 0px;
        left: 0px;
        display: block;
        text-align: center;
        font-size: 12px;
        justify-content: center;
        height: 4vw;
        padding: 0px 0px;
        letter-spacing: 2px;

    }





}


@media only screen and (max-width:401px) {

    .reorder {
        display: block;
        position: absolute;
        top: 22px;
        right: 30px;
        float: right;
    }


    @keyframes slide-in {
        100% {
            transform: translateX(100%);
        }


    }


    .Navbar .leftside {
        position: relative;
        width: 50%;
        background-color: black;
        animation: mymovenavbar 0.6s;
        animation-timing-function: linear;
        animation-fill-mode: forwards;

    }

    @keyframes mymovenavbar {
        20% {
            top: 0px;
            opacity: 0.1;
            height: 0px
        }

        50% {
            opacity: 0.7;
            height: 40px
        }

        100% {
            opacity: 1;
            height: 70px;
        }
    }

    .Navbar .rightside {
        width: 50%;
        display: inline-block;
        display: flex;
        background-color: black;
    }




    .Navbar .leftside #logo {
        width: 110px;
        height: 25px;
        cursor: pointer;

    }







    /*  */

    .Navbar .leftside #hidden {

        display: flex;
        position: absolute;
        left: 0%;
        flex-direction: column;
        justify-content: flex-start;
        top: 70px;
        min-height: 100vh;
        width: 100vw;
        background: black;
        text-align: center;

    }

    .links_navbar1 {
        display: none;
    }

    #navbar_company,
    #navbar_knowledge {
        display: none;
    }

    .rightside button {
        display: none;
    }

    /*  */

    .Navbar .leftside #hidden .mobile_div {
        width: 260px;
        display: flex;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;

        position: relative;
        top: 30px;

    }


    .Navbar .leftside #hidden .links_navbar {

        position: relative;
        margin-bottom: 20px;
        top: 0px;
        left: 0px;
        display: block;
        text-align: center;
        font-size: 11px;
        justify-content: center;
        height: 5vw;
        padding: 0px 0px;
        letter-spacing: 2px;

    }





}

img {
    display: block;
    max-width: 100%;
}


.blog_page {
    position: absolute;
    top: 70px;
    /* border: 1px solid red; */
    width: 100%;
    min-height: calc(100vh - 70px);
    background-color: black;
    color: whitesmoke;
    display: flex;
    justify-content: center;

}

.blog_discover {
    flex-basis: 60%;
    padding: 2rem;
}

.blog_discover h1 {
    line-height: 1.5;
    border-bottom: 1px solid #333;
    font-weight: bold;
}

.blog_discover h1,
.blog_explore h4 {
    text-transform: uppercase;
    color: #ff7200;
}

.blog_explore {
    flex-basis: 40%;
    padding: 2rem;
    display: flex;
    justify-content: center;
}


.blog_item_wrap {
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* border: 1px solid red; */
}

.blog_item {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #333;
}

.blog_img {
    flex-basis: 25%;
}

.blog_info {
    flex-basis: 75%;
}

.blog_title {
    font-size: 1.2rem;
    font-weight: bold;
    cursor: pointer;
}

.blog_title:hover {
    text-decoration: underline;
}

.blog_summary {
    color: #888888;
}

.blog_meta {
    display: flex;
    gap: 3rem;
    padding: 0.5rem;
}

.blog_date {
    position: relative;
    display: flex;
    gap: 0.5em;
    align-items: center;
    font-size: 0.9rem;
}

.blog_date::after {
    content: "";
    border-right: 2px solid #333;
    position: absolute;
    top: 0.25rem;
    right: -1.5rem;
    bottom: 0;
    height: 80%;
}

.blog_link {
    color: #ff7200;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.blog_link svg:hover,
.blog_entry svg:hover {
    width: 1em;
}

.explore_item_wrap {
    margin-top: 1.5rem;
    /* border: 1px solid red; */
}

.explore_item {
    display: flex;
    gap: 1rem;
    /* align-items: center; */
}

.explore_img {
    width: 8rem;
    height: 8rem;
    /* border: 1px solid red; */
}

.explore_text {
    flex-basis: 75%;
    margin-top: 2rem;
}

/* blog entry page */
.blogentry_page {
    position: absolute;
    top: 70px;
    width: 100%;
    min-height: calc(100vh - 70px);
    background-color: black;
    color: whitesmoke;
}

.blogentry_page .blog_explore {
    /* border: 1px solid #333; */
    width: min(90%, 850px);
    margin: 2rem auto;
    text-decoration: underline;
    color: #ff7200;
}


.blog_entry {
    width: min(90%, 850px);
    margin: 3rem auto;
    border-bottom: 1px solid #333;
    /* padding-right: 3rem; */
}



.blog_entry .blog_img {
    margin-bottom: 1.75rem;
}

.blog_entry h1 {
    color: #ff7200;
}

.blog_entry h1,
.blog_entry h3,
.blog_entry h5 {
    font-weight: bold;
}

.blog_entry_info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.blog_socials {
    display: flex;
    gap: 0.5rem;
}

.blog_socials a {
    text-decoration: none;
    cursor: pointer;
    color: white;

}

.blog_entry_date {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.blog_date div,
.blog_entry_date div {
    height: 2rem;
    width: 2rem;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.blog_date img,
.blog_entry_date img {
    height: 1.25rem;
    width: 1.25rem;
}

.blog_content p {
    line-height: 1.5;
}

.blog_content section {
    margin: 3rem 0;
}

.blog_content h5 {
    margin: 1rem 0;
}

.blog_content a {
    color: #ff7200;
    cursor: pointer;
}

@media only screen and (max-width: 800px) {
    .blog_page {
        flex-direction: column;
    }

    .blog_item {
        flex-direction: column;
    }

    .explore_img {
        width: 5rem;
        height: 5rem;
        border: 1px solid red;
    }




    /* .blog_entry {
        margin-bottom: 3rem;
        border-right: 1px solid #333;
        padding-right: 0;
    } */

}