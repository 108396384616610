.text_area_container{
  display: flex;
  flex-direction: column;
  /* gap: 1rem; */
  padding-right: 1.5rem;
  align-items: flex-start;
}

.body_text{
  font-size: 1rem;
}
.card {
  display: flex;
  flex-direction: column;
  /* width: 250px; */
  width: 100%;
  padding: 20px;
  /* margin: 10px; */
  border-radius: 10px;
  box-shadow: 
    0 4px 12px rgba(0, 0, 0, 0.15), /* Outer shadow */
    inset 0px 0px 18px 40px rgba(0,0,0,0.02); /* Inner shadow */
  background-color: #fff;
  transition: transform 0.2s;
  /* max-width: 300px; */
}

.card:hover {
  transform: translateY(-5px);
}

.card-icon {
  margin-bottom: 15px;
}

.card-icon img {
  width: 90px;
  height: 90px;
}

/* .card-content {
  text-align: center; 
} */

.card-title {
  font-size: 1.6em;
  margin-bottom: 10px;
  font-family: "Fjalla One", sans-serif !important;
}

.card-description {
  font-size: 1em;
  color: #666;
  margin-bottom: 15px;
}

.card-link {
  font-size: 0.9em;
  color: orange;
  text-decoration: none;
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.card-link .arrow {
  margin-left: 5px;
  transition: transform 0.2s;
}

.card-link:hover .arrow {
  transform: translateX(5px);
}

.tiles_container--outer{
  max-height: 550px;
  /* overflow-y: hidden; */
  overflow-y: auto;
  display: flex;
  gap: 10px;
  padding: 10px;
}

.tiles-container--inner{
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.uneven-column{
  margin-top: 80px;
}
.main_outer_container{
  padding: 5rem 8rem;
}

.main_outer_container_mobile{
display: none;
}

@media (max-width: 600px){
  .main_outer_container{
    padding: 3rem 2rem !important;
  }
}

@media (max-width: 960px){
  .GridContainerBox{
    display: none;
  }
  .main_outer_container_mobile{
    display: block;
    padding: 0rem 0.5rem

  }
  .button_Container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 0.5rem;
  }
  .customSlider_prevButton{

    height: 29px;
    width: 29px;
    background: transparent;
    border: none;
}
.customSlider_nextButton{
 
  height: 29px;
  width: 29px;
  background: transparent;
  border: none;

}
  .tiles-container--inner{
    flex-direction: row;
    gap: 10px;
  }
  .uneven-column{
    margin-top: 0px !important;
  }
  .tiles_container--outer{
    max-height: 550px;
    overflow-x: auto;
    gap: 10px;
    margin-top: 20px;
  }
  .tiles-container--inner{
    width: auto;
  }
  .card {
    min-width: 295px;
    min-height: 330px;
  }
  
  .card-icon img {
    width: 55px;
    height: 55px;
  }

  .card-title {
    font-size: 1.2em;
  }
  
  .card-description {
    font-size: 0.8em;
  }
}
@media (max-width:875px){
.card {
  min-width: 295px;
  min-height: 275px;
}

}
@media (max-width: 1200px){
  .uneven-column{
    margin-top: 32px;
  }

}

@media (max-width: 1150px){
  .main_outer_container{
    padding: 3rem 4rem;
  }

}

@media screen and (min-width: 1500px) {
  .body_text{
    font-size: 1.2rem;
  }
}