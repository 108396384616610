.business_container{
    height: 500vh;
    position: relative;
}
.sticky_container{
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    overflow: hidden;
	padding-top: 3rem;
}
.content_wrapper{
    display: flex;
    gap: 3rem;
    flex-direction: row;
    align-items: center;
    position: absolute;
    top: 38%;
    left: 0;
    padding-top: 40px;
}
.content_wrapper::before{
    content: "";
    position: absolute;
    height: 100%;
    width: 200%;
    top: 0;
    left: -30%;
    border-top: 20px solid #34343475;
}
.content_box{
    min-width: 350px;
    height: 340px;
    margin: 10px 10px 0 10px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-left: 2px solid;
    border-image: linear-gradient(to bottom, #ff7200 0%, transparent 100%) 1;
    padding-left: 30px;
    position: relative;
    filter: grayscale(1);
}
.content_box::before{
    content: "";
    height: 20px;
    width: 20px;
    position: absolute;
    top: -50px;
    left: -10px;
    background: linear-gradient(to bottom, #5e5e5e, transparent);
    border-radius: 50%;
}
.active_card{
    filter: grayscale(0);
}
.active_card::before{
    background: linear-gradient(to bottom, #ff7200, transparent);;
}

.content_box > .card_title{
    font-size: 17px;
    font-weight: 600;
    color: #ff7200;
}

.content_box > .card_body{
    font-size: 13px;
    color: #fff;
}

.particle_bg{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    filter: blur(0.7px);
}
  
.particle_bg::before{
    content: "";
    position: absolute;
    height: 40%;
    width: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(to bottom, #000, transparent);
    z-index: 5;
}
  
.particle_bg::after{
    content: "";
    position: absolute;
    height: 40%;
    width: 100%;
    bottom: 0;
    left: 0;
    background: linear-gradient(to top, #000, transparent);
    z-index: 5;
}

@media (min-width : 1250px) {
    .sticky_container{
        padding-top: 4.5rem;
    }
    .content_wrapper{
        gap: 6rem;
    }
    .content_box > .card_title{
        font-size: 17.5px;
        font-weight: 600;
        color: #ff7200;
    }
    .content_box > .card_body{
        font-size: 13.5px;
        color: #fff;
    }
}

@media (min-width : 1450px) {
    .sticky_container{
        padding-top: 6rem;
    }
    .content_wrapper{
        gap: 12rem;
    }
    .content_box{
        width:360px
    }
    .content_box > .card_title{
        font-size: 19px;
        font-weight: 600;
        color: #ff7200;
    }
    .content_box > .card_body{
        font-size: 15px;
        color: #fff;
    }
}