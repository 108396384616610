.sliderContainer {
  text-align: center;
  margin: 0 auto;
  width: 100%;
  padding: 3rem 0px;
}

.heading_para {
  font-size: 15px;
}

.carouselWrapper {
  height: 27vw;
  position: relative;
  width: 100%;
  margin-top: 40px;
  overflow: hidden;
}

.mainSlide {
  width: 60%;
  position: absolute;
  z-index: 2;
  transition: transform 0.5s ease;
  left: 21%;
  border: 1px solid rgb(255, 119, 0);
  border-radius: 8px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
}

.sideSlide {
  width: 40%;
  position: absolute;
  z-index: 1;
  transition: transform 0.5s ease;
  left: -3%;
  top: 17%;
  filter: grayscale(100%);
}

.nextSlide {
  width: 40%;
  position: absolute;
  z-index: 1;
  transition: transform 0.5s ease;
  right: -19%;
  top: 17%;
  filter: grayscale(100%);
}

.sideImage {
  max-width: 100%;
  height: auto;
  opacity: 0.5;
}

.awardCard {
  position: relative;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid rgb(255, 119, 0);
  margin: 0 auto;
  border-radius: 8px;
}

.iconImageContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: white;
  border-radius: 8px 8px 0px 0px
}

.iconContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 0px 8px 0px 0px;
}

.iconContainer img {
  border-radius: 0px 8px 0px 0px;
}

.awardIcon {
  max-width: 100%;
  height: auto;
}

.awardImage {
  flex: 3;
  max-width: 70%;
  height: auto;
  border-radius: 0px 9px 0px 0px
}

.awardTitle {
  font-size: 1.1rem;
  background-color: #FF7700;
  color: white;
  width: 100%;
  border-radius: 0 0 6px 6px;
  padding: 5px 0;
  height: 40px;
}

.readMore a {
  display: inline-block;
  margin-top: 10px;
  color: #615E5E;
  transition: background-color 0.3s;
  font-family: 'Fjalla One' sans-serif;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  position: relative;
}

.readMore a::before {
  content: "";
  width: 100%;
  height: 2px;
  background: #FF7700;
  position: absolute;
  bottom: -3px;
  left: 0;
}

.readMore a:hover {
  color: #FF7700
}

.navigationButtons {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}

.prevButton,
.nextButton {
  padding: 7px 8px;
  border: 2px solid #2C2C2C66;
  font-size: 14px;
  cursor: pointer;
  margin: 0 2px;
  background-color: white;
}

.prevButton:hover,
.nextButton:hover {
  border-color: black;
}

.smallMainSlide {
  display: none;
}

.smallAwardIcon {
  display: none;
}

.blackBgOnImg {
  display: none;
}

.blackBgOnImgPrev {
  display: none;
}

.blackBgOnImgNext {
  display: block;
}

.left_btn_sm_screen {
  position: absolute;
  z-index: 2;
  left: 4%;
  top: 41%;
  padding: 5px 12px 7px 10px;
  border: none;
  border-radius: 6px;
  display: none;
}

.right_btn_sm_screen {
  position: absolute;
  z-index: 2;
  right: 4%;
  top: 41%;
  padding: 5px 10px 7px 12px;
  border: none;
  border-radius: 6px;
  display: none;
}

/* Container for the dots navigation */
.dotsWrapper {
  display: none;
  justify-content: center;
  margin-top: 10px;
}

.dot {
  height: 8px;
  width: 8px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.3s;
  cursor: pointer;
}

.activeDot {
  background-color: #FF7700;
}

@media screen and (max-width:1200px) {
  .mainSlide {
    width: 58%;
  }
}

@media screen and (max-width:950px) {
  .carouselWrapper {
    margin-top: 30px;
  }

  .mainSlide {
    width: 56%;
  }

}

@media screen and (max-width:850px) {
  .awardTitle p {
    font-size: 14px;
  }

  .readMore a {
    font-size: 16px;
  }
}

@media screen and (max-width:800px) {
  .mainSlide {
    width: 54%;
  }
}


@media screen and (max-width: 750px) {
  .navigationButtons {
    display: none;
  }
  .right_btn_sm_screen{
    display: block;
  }
  .left_btn_sm_screen{
    display: block;
  }
  .carouselWrapper {
    height: 37vw;
  }

  .dotsWrapper {
    display: flex;
  }

  .mainSlide {
    display: none;
  }

  .smallMainSlide {
    display: block;
    position: absolute;
    top: 0%;
    left: 20%;
    height: 35vw;
  }

  .smallAwardIcon {
    display: block;
    position: absolute;
    top: 15%;
    left: 20vw;
    z-index: 4;
    max-width: 26vw;
  }

  .smallAwardImage {
    position: absolute;
    left: 0%;
    max-width: 65vw;
    z-index: 2;
    border-radius: 8px;

  }


  .sideSlide {
    width: 79%;
    left: -23%;
    top: 8%;
    filter: none;
  }

  .nextSlide {
    width: 79%;
    right: -14%;
    top: 8%;
    filter: none;
  }

  .blackBgOnImg {
    display: block;
    background-color: black;
    position: absolute;
    left: 0%;
    z-index: 3;
    border-radius: 8px;
    height: 105%;
    width: 65vw;
    opacity: 0.85;
  }

  .awardImage {
    border-radius: 8px;
  }

  .blackBgOnImgPrev {
    display: block;
    background-color: black;
    position: absolute;
    left: 30%;
    z-index: 3;
    border-radius: 8px;
    height: 100%;
    width: 65vw;
    opacity: 0.85;
  }

  .blackBgOnImgNext {
    display: block;
    background-color: black;
    position: absolute;
    left: 0%;
    z-index: 3;
    border-radius: 8px;
    height: 105%;
    width: 65vw;
    opacity: 0.85;
  }
}


@media screen and (max-width:650px) {
  .carouselWrapper {
    margin-top: 15px;
  }
}