.latestNews {
    margin: 0px auto;
    padding: 2rem 0px;
}

.LNslider-container {
    display: none;
}

.main_feature {
    display: flex;
    gap: 3rem;
    padding: 3rem 0px;
    font-family: "Montserrat", sans-serif;
    align-items: stretch;
    width: 80%;
    margin: 0px auto;
}

.main_feature>img {
    width: 100%;
    max-width: 31vw;
    height: 272px;
    background-size: cover;
    border-radius: 8px ;
    box-shadow: 10px 19px 22.28px 2px rgba(0, 0, 0, 0.1);

}

.vertical_line {
    border-left: 1px solid #4D4E4F !important;
    height: auto;
    align-self: stretch;
}

.main_feature_content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
}
.main_feature_title_text{
    display: flex;
    flex-direction: column;
    gap: 15px;
}


.title {
    font-size: 1.6rem;
    font-family: "Fjalla One", sans-serif;
}

.main_feature_para {
    color: #121212;
    font-weight: 400;
    text-align: justify;
    font-size: 20px;
}

.main_feature_date_btn {
    display: flex;
    font-family: var(--bs-body-font-family);
}

.main_feature_date {
    font-weight: 700;
    font-size: 22px;
    margin: 5px 34px 0px 0px;
}

.horizontal_line {
    border-bottom: 1px solid #4D4E4F;
    width: 80%;
    margin: 35px auto;
    margin-top: 0px;
}

.slider {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.slider_content {
    display: flex;
    width: 80%;
    margin: 0px auto;
    gap: 2rem;
    position: relative;
}

.slider_title {
    font-family: "Fjalla One", sans-serif;
    font-size: 1.4rem;
    height: 100%;
    background-color: #EEEEEE;
    text-align: center;
}

.card {
    flex: 0 0 31.5%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    border-radius: 0px 0px 8px 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    
}
.img_holder{
    padding: 15px;
    background-color: #EEEEEE;
}
.card img{
    width: 100%;
    height: 215px;
    object-fit: cover;
    object-position: center;
    border-radius: 8px;
    
}

.slider_left_arr {
    position: absolute;
    left: 6%;
    padding: 7px 8px;
    border: 2px solid #2C2C2C66;
    font-size: 14px;
    cursor: pointer;
    margin: 0 2px;
    background-color: white;
}

.slider_right_arr {
    position: absolute;
    right: 6%;
    padding: 7px 8px;
    border: 2px solid #2C2C2C66;
    font-size: 14px;
    cursor: pointer;
    margin: 0 2px;
    background-color: white;
}

.slider_left_arr:hover {
    border-color: black;
}

.slider_right_arr:hover {
    border-color: black;
}

.slider_left_arr:disabled {
    background-color: rgb(238, 238, 238);
    border: 2px solid #2C2C2C66;
}

.slider_right_arr:disabled {
    background-color: rgb(238, 238, 238);
    border: 2px solid #2C2C2C66;
}

.slider_date_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 14px;
   
   
}


@media screen and (max-width:1100px) {
    .main_feature {
        gap: 1.5rem;
    }
    .img_holder>img{
        height: 157px;
    }
    .title {
        font-size: 1.5rem;
    }

    .main_feature_para {
        font-size: 20px;
    }

    .main_feature_date {
        font-size: 19px;
    }

    .slider_title {
        font-size: 1.05rem;
    }

 

    .slider_right_arr {
        right: 3%;
    }
}

@media screen and (max-width:880px) {

    .slider_right_arr {
        right: 1.5%;
    }
}


@media screen and (max-width:801px) {
    .containerOfBigScreen {
        display: none;
    }

    .LNslider-container {
        display: block;
        width: 100%;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        display: flex;
        -webkit-overflow-scrolling: touch;
    }

    .LNslider-wrapper {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: 35px 10vw;
    }
    .LNslider-item {
        flex: 0 0 80vw;  
        margin-right: 2vw; 
        scroll-snap-align: center; 
        background: #fff;
        padding: 20px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        text-align: center;
        overflow: hidden;  
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }
  
      .slider-item > .LNtitle-row > h3{
        margin-bottom: 0px !important;
        color: #FF7700;
        text-align: center;
      }

      .LNslider_para{
        font-size: 17px;
      }
      .slider_date_btn{
        margin-top: 20px;
      }
      .line_Divider{
        border: none;
        border-top: 2px solid #ff7700;
        opacity: 1;
        width: 100%;
        height: 2px;
        
      }
}
@media screen and (max-width:450px){
    .LNslider_para{
        font-size: 14px;
    }
}