
.large_screens{
  display: none;
}

.software_features_section{
  padding: 2rem 0px;
  /* padding: 2rem 5rem; */
}

.features_outer_container{
  padding: 2rem 4rem;
  position: relative;
}

.feature_tabs{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.7rem;
  padding: 0px 1rem;
  overflow-x: auto;
  padding-top: 1rem;
}

.feature_tab{
  /* width: 180px; */
  min-width: 221px;
  height: 150px;
  padding: 0.1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15), inset 0px 0px 18px 40px rgba(0, 0, 0, 0.02);
  cursor: pointer;
  transition: transform 0.2s;
  z-index: 1;
  margin-bottom: 20px;
}

.feature_tab:hover {
  transform: translateY(-5px);
}

.feature_tab > img{
  height: 50px;
  width: 50px;
}

.feature_tab > .title{
  font-size: 14px;
  text-align: center;
  font-weight: 600;
}

.feature_content{
  padding: 0px 1rem;
  display: flex;
  gap: 1rem;
  /* align-items: center; */
}

.feature_content > .text_content{
  padding-top: 0.5rem;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.feature_content > .text_content > span{
  font-size: 1.3rem;
  white-space: pre-wrap;
  font-weight: 700;
  color: #FF7700;
  font-family: Fjalla One;
}

.feature_content > .text_content > p{
  font-size: 1rem;
  white-space: pre-wrap;
}

.feature_content > .img_content{
  width: 100%;
  height: 100%;
  /* min-height: 400px; */
  /* max-height: 610px; */
  max-width: 40vw;
  padding-top: 1rem;
  z-index: 1;
}

.feature_content > .img_content img{
  height: 100%;
  width: 100%;
  object-fit: contain;
}




.slider-container {
  width: 100%;
  overflow-x: auto;  
  scroll-snap-type: x mandatory;  
  display: flex;
  -webkit-overflow-scrolling: touch; 
}

.slider-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;  
  padding: 20px 10vw; 
}

.slider-item {
  flex: 0 0 80vw;  
  margin-right: 2vw; 
  scroll-snap-align: center; 
  background: #fff;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
  overflow: hidden;  
}

.slider-item img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.slider-item > .title-row{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.slider-item > .title-row > img{
  height: 50px !important;
  width: 50px !important;
}

.slider-item > .title-row > h3{
  margin-bottom: 0px !important;
  color: #FF7700;
}

@media (min-width: 801px){
  .large_screens{
    display: block;
  }
  .small_screens{
    display: none;
  }
}

@media (min-width: 1001px){
  .software_features_section{
    padding: 2rem 1rem;
  }

  .feature_tab > img{
    height: 70px;
    width: 70px;
  }

  .feature_content > .text_content{
    padding-top: 2rem;
    width: 100%;
    gap: 1rem;
  }

  .feature_content > .text_content > span{
    font-size: 2rem;
  }
  
  .feature_content > .text_content > p{
    font-size: 1rem;
  }
  
  .feature_content > .img_content{
    width: 100%;
    height: 100%;
    max-width: 45vw;
    padding-top: 2rem;
    z-index: 1;
  }
}

@media (min-width: 1250px){
  .software_features_section{
    padding: 2rem 5rem;
  }



  .feature_content > .text_content{
    padding-top: 2rem;
    width: 40%;
    gap: 1rem;
  }




}

@media screen and (min-width: 1500px) {
  .software_features_section{
    padding: 2rem 7rem;
    /* width: max-content; */
  }
  
  .feature_tab > .title{
    font-size: 16px;
  }

  .feature_content > .img_content{
    max-width: 800px;
  }
  
  .feature_content > .text_content{
    padding-top: 2rem;
    width: 50%;
    gap: 1rem;
  }


  .feature_content > .text_content > span{
    font-size: 2rem;
  }

  .feature_content > .text_content > p{
    font-size: 1rem;
  }
}

@media screen and (min-width: 1600px){
  .software_features_section{
    padding: 2rem 10rem;
    /* width: max-content; */
  }
  .feature_content > .text_content > p{
    font-size: 1.2rem;
  }
}