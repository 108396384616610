* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main_container {
  height: 672px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 0px;
}

.api_section {
  height: 100%;
  width: 100%;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;
  overflow: hidden;
}

.left_side,
.right_side {
  width: 100%;
  height: 100%;
  position: relative;
}

.left_side {
  background: #fafafa;
}

.right_side {
  background: #eeeeee;
}

.title {
  position: absolute;
  font-family: "Fjalla One", sans-serif;
  font-size: 1rem;
}

.left_title,
.right_title {
  top: 0.5rem;
}

.left_title {
  color: #575859;
  right: 2%;
}

.right_title {
  color: #ff7700;
  right: 2%;
}

.logo_container {
  height: 72px;
  width: 72px;
  border-radius: 20px;
  box-shadow:
    10px 6px 11px rgba(0, 0, 0, 0.1),
    inset 0px 2px 6px 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 50%;
  left: 52%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.logo_container::after {
  content: "";
  height: 50%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #eeeeee;
  border-radius: 15px 15px 0px 0px;
  z-index: -1;
  box-shadow: inset 0px 2px 6px 10px rgba(0, 0, 0, 0.03);
}

.logo_container::before {
  content: "";
  height: 50%;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #fafafa;
  border-radius: 0px 0px 15px 15px;
  box-shadow: inset 0px 2px 6px 10px rgba(0, 0, 0, 0.03);
}

.logo_container>img {
  height: 70%;
  width: 70%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mobile_backlines {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 50%;
  top: 56%;
  transform: translate(-50%, -50%);
  z-index: 0;
}

.mobile_backlines>img {
  height: 100%;
  width: 100%;
  object-fit: fill;
}

.desktop_backlines {
  display: none;
}

.tablet_backlines {
  display: none;
}

.api_box_container {
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  max-width: 100px;
  flex-direction: column;
  justify-content: center;
}

.api_box_container>p {
  font-family: "Fjalla One", sans-serif !important;
  color: #4e4f50;
  white-space: pre-wrap;
  flex: 1;
  font-size: 0.7rem;
  line-height: 0.86rem;
  margin: 0px;
  text-align: center;
}

.tile {
  padding: 2px;
  border: 1px solid #ff7700;
  box-shadow: 7px 10px 18px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
}

.tile>img {
  height: 60%;
  max-width: 100%;
}

#ulip {
  top: 69%;
  right: 7vw;
  flex-direction: row;
  min-width: 150px;
}

#fois {
  top: 71%;
  left: 14vw;
  flex-direction: row-reverse;
  min-width: 117px;
}

#pan {
  top: 50%;
  right: 2vw;
  flex-direction: row;
  min-width: 143px;
}

#sarathi {
  top: 21%;
  right: 2vw;
  flex-direction: column-reverse;
}

#goods {
  top: 32%;
  left: 5vw;
  flex-direction: column-reverse;
  max-width: 57px;
}

#digilocker {
  top: 14%;
  right: 28vw;
  flex-direction: column-reverse;
}

#vahan {
  top: 28%;
  left: 32vw;
  flex-direction: column-reverse;
  max-width: 74px;
}

#fastag {
  top: 14%;
  left: 12vw;
  flex-direction: column-reverse;
}





#rthighway {
  top: 16%;
    right: 15vw;
    flex-direction: row;
    max-width: 151px;
}

#custom {
  top: 30%;
  left: 30vw;
  flex-direction: column-reverse;
}

#acfi {
  top: 41%;
  left: 14vw;;
  flex-direction: column-reverse;
}

#cargo {
  top: 61%;
  left: 38vw;
}

#pcs {
  top: 71%;
  left: 7vw;
  flex-direction: column-reverse;
}

#ondc {
  top: 82%;
  left: 55%;
  flex-direction: row;
}

#iecode {
  top: 58%;
  right: 2vw;
  flex-direction: column-reverse;
}

#nlds {
  top: 44%;
  right: 6vw;
  flex-direction: row;
  min-width: 142px !important;
}

/* #rto {
  top: 16%;
  right: 4vw;
}

#monitoring_control {
  bottom: 21%;
  right: 28vw;
}

#e2e_msp {
  bottom: 38%;
  left: 24vw;
  flex-direction: column-reverse;
}

#data_transfer {
  top: 8%;
  left: 2vw;
  flex-direction: column-reverse;
}

#installation {
  bottom: 15%;
  left: 1vw;
}

#fois {
  top: 51%;
  right: 3.5vw;
}

#sim_tracking {
  top: 18%;
  right: 30vw;
  flex-direction: column-reverse;
}

#ldb {
  bottom: 15%;
  right: 37.5vw;
}

#vessel_tracking {
  top: 20%;
  left: 26vw;
}

#cargo_updates {
  bottom: 28%;
  left: 6vw;
}

#fastag {
  top: 12%;
  left: 2vw;
  flex-direction: column-reverse;
} */

@media screen and (min-width:330px) {
  .title {
    font-size: 1.2rem;
  }

  /* #rto {
    top: 13%;
    right: 3vw;
  }

  #monitoring_control {
    bottom: 34%;
    right: 29vw;
  }

  #e2e_msp {
    bottom: 48%;
    left: 23vw;
    flex-direction: column-reverse;
  }

  #data_transfer {
    top: 2%;
    left: 1vw;
    flex-direction: column-reverse;
  }

  #installation {
    bottom: 19%;
    left: 5vw;
  }

  #fois {
    top: 59%;
    right: 3.5vw;
  }

  #sim_tracking {
    top: 35%;
    right: 28vw;
    flex-direction: column-reverse;
  }

  #ldb {
    bottom: 5%;
    right: 38.5vw;
  }

  #vessel_tracking {
    top: 23%;
    left: 32vw;
  }

  #cargo_updates {
    bottom: 18%;
    left: 7vw;
  }

  #fastag {
    top: 13%;
    left: 8vw;
    flex-direction: column-reverse;
  } */
}

@media screen and (min-width:430px) {

  .left_title,
  .right_title {
    top: 1rem;
  }

  .left_title {
    right: 5%;
  }

  .right_title {
    right: 5%;
  }

  #ulip {
    top: 70%;
    right: 15.5vw;

  }

  #fois {
    left: 22vw;
  }

  #pan {
    right: 11vw;
  }

  #sarathi {
    right: 11vw;
  }

  #goods {
    top: 50%;
    left: 5vw;
    flex-direction: row-reverse;
    max-width: 128px;
  }

  #digilocker {
    right: 31vw;
  }

  #vahan {
    left: 34vw;
  }

  #fastag {
    left: 16vw;
  }





  #rthighway {
    top: 16%;
    right: 22vw;
    flex-direction: row;
  }

  #custom {
    top: 29%;
    left: 34vw;
    flex-direction: column-reverse;
  }

  #acfi {
    top: 43%;
    left: 17vw;
    flex-direction: column-reverse;
  }

  #cargo {
    top: 60%;
    left: 39vw;;
  }

  #pcs {
    top: 70%;
    left: 16vw;
    flex-direction: column-reverse;
  }

  #ondc {
    top: 82%;
    left: 54%;
    flex-direction: row;
  }

  #iecode {
    top: 62%;
    right: 7vw;
    flex-direction: column-reverse;
  }

  #nlds {
    top: 41%;
    right: 16vw;
  }

  /* #rto {
    top: 13%;
    right: 21vw;
  }

  #monitoring_control {
    bottom: 24%;
    right: 38vw;
  }

  #e2e_msp {
    bottom: 50%;
    left: 27vw;
    flex-direction: column-reverse;
  }

  #data_transfer {
    top: 0%;
    left: 9vw;
    flex-direction: column-reverse;
  }

  #installation {
    bottom: 24%;
    left: 5vw;
  }

  #fois {
    top: 60%;
    right: 10.5vw;
  }

  #sim_tracking {
    top: 35%;
    right: 31vw;
    flex-direction: column-reverse;
  }

  #ldb {
    bottom: 5%;
    right: 38.5vw;
  }

  #vessel_tracking {
    top: 23%;
    left: 35vw;
  }

  #cargo_updates {
    bottom: 18%;
    left: 13vw;
  }

  #fastag {
    top: 13%;
    left: 11vw;
  } */
}

@media screen and (min-width:530px) {
  .mobile_backlines {
    display: none;
  }

  .tablet_backlines {
    display: block;
  }

  .main_container {
    height: 1000px;
  }

  .tablet_backlines {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 55%;
    top: 58%;
    transform: translate(-50%, -50%);
    z-index: 0;
  }

  .tablet_backlines>img {
    height: 96%;
    width: 100%;
    object-fit: fill;
  }


  .logo_container {
    height: 85px;
    width: 85px;
    top: 50%;
    left: 52%;
  }

  .api_box_container>p {
    font-size: 0.9rem;
  }

  .tile {
    width: 75px;
    height: 75px;
  }

  #rthighway {
    top: 12%;
    right: 29vw;
    flex-direction: row;
}
#nlds {
  top: 41%;
  right: 22vw;
}
#iecode {
  top: 56%;
  right: 14vw;
  flex-direction: column-reverse;
}
#custom {
  top: 29%;
  left: 34vw;
}

#acfi {
  top: 46%;
  left: 17vw;
}
#pcs {
  top: 75%;
  left: 16vw;
  flex-direction: column-reverse;
}
#cargo {
  top: 64%;
  left: 35vw;
}
#ondc {
  top: 83%;
  left: 49%;
  max-width: 135px;
}
  #fastag {
    top: 12%;
    left: 13vw;
  } 
  #vahan{
    left: 31vw;
    top: 16%;
  }
  #digilocker{
    right: 31vw;
    top: 16%;
    flex-direction: column;
  }
  #goods{
    top: 49%;
    left: 1vw;
    flex-direction: row-reverse;
    max-width: 128px;
  }
  #fois{
    left: 25vw;
    top: 62%;
  }
  #sarathi{
    right: 5vw;
    top: 14%;
  }
  #pan {
  right: 17vw;
  top: 44%;
  }
  #ulip {
    top: 70%;
    right: 24.5vw;
  }
}



@media screen and (min-width:700px) {
 
  #rthighway {
    right: 34vw;
}
#nlds {
  right: 29vw;
}
#iecode {
  top: 61%;
  right: 21vw;
}
#custom {

  left: 38vw;
}

#acfi {
  top: 48%;
  left: 20vw;
}
#pcs {
  top: 77%;
  left: 19vw;
}
#cargo {
  left: 38vw;
}
#ondc {
  left: 48%;
}
  #fastag {
    top: 7%;
    left: 19vw;
  } 
  #vahan{
    left: 35vw;
    top: 15%;
  }
  #digilocker{
    right: 38vw;
    top: 14%;
    flex-direction: column;
  }
  #goods{
    top: 44%;
    left: 11vw;
    min-width: 140px;
  }
  #fois{
    left: 31vw;
    top: 62%;
    min-width: 130px;
  }
  #sarathi{
    right: 12vw;
    top: 12%;
  }
  #pan {
    right: 24vw;
    top: 44%;
  }
  #ulip {
    top: 70%;
    right: 30.5vw;
  }
}

@media screen and (min-width:860px) {
  #fastag {
    top: 7%;
    left: 25vw;
  } 
  #vahan{
    left: 38vw;
    top: 15%;
  }
  #digilocker{
    right: 38vw;
    top: 14%;
    flex-direction: column;
  }
  #goods{
    top: 44%;
    left: 16vw;
  }
  #fois{
    left: 33vw;
    top: 62%;
  }
  #sarathi{
    right: 15vw;
  }
  #pan {
    right: 27vw;
  }
  #ulip {
    right: 32.5vw;
  }






}

@media screen and (min-width:970px) {
  #rthighway {
    right: 36vw;
}
#cargo {
  left: 41vw;
}
#custom{
  top: 36%;
}
#iecode {
  top: 67%;
  
}
#pcs {
  top: 77%;
  left: 26vw;
}
#acfi {
  top: 50%;
  left: 21vw;
}
  #sarathi{
    right:20vw
  }
}

@media screen and (min-width:1050px) {
  .tablet_backlines {
    display: none;
  }

  .main_container {
    height: 750px;
  }

  .api_section {
    flex-direction: row;
  }

  .title {
    font-size: 2rem;
  }

  .left_title {
    left: 20%;
    right: unset;
  }

  .right_title {
    right: 20%;
  }

  .api_box_container>p {
    font-size: 1rem;
    line-height: unset;
    text-align: left;

  }

  .logo_container {
    height: 120px;
    width: 120px;
    border-radius: 20px;
    box-shadow:
      10px 6px 11px rgba(0, 0, 0, 0.1),
      inset 0px 2px 6px 10px rgba(0, 0, 0, 0.1);
    position: absolute;
    bottom: 28%;
    left: 50%;
    transform: translate(-50%, 8%);
    z-index: 1;
  }

  .logo_container::after {
    height: 100%;
    width: 50%;
    left: 0;
    top: 0;
    border-radius: 20px 0px 0px 20px;
  }

  .logo_container::before {
    height: 100%;
    width: 50%;
    right: 0;
    top: 0;
    left: unset;
    bottom: unset;
    border-radius: 0px 20px 20px 0px
  }

  .desktop_backlines {
    display: block;
    height: 90%;
    width: 89%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    z-index: 0;
  }

  .desktop_backlines>img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    margin-left: 35px;
  }

  .mobile_backlines {
    display: none;
  }

  .api_box_container {
    display: flex;
    align-items: center;
    gap: 15px;
    position: absolute;
    max-width: 200px;
    flex-direction: row;
    justify-content: unset;
  }

  .tile {
    width: 100px;
    height: 80px;
    padding: 8px;
    border-radius: 17px;
  }

  .tile>img {
    height: 70%;
    max-width: 100%;
  }

  #nlds {
    top: 14%;
    right: 15vw;
  }

  #iecode {
    top: 42%;
    right: 27vw;
    flex-direction: column-reverse;
  }

  #ondc {
    top: 44%;
    left: 52%;
    flex-direction: row;
    max-width: 174px;
  }

  #rthighway {
    top: 29%;
    right: 22vw;
    flex-direction: row-reverse;
    max-width: 270px;
  }

  #cargo {
    top: 62%;
    left: 19vw;
    min-width: 240px;
  }

  #custom {
    top: 71%;
    left: 5vw;
    flex-direction: row-reverse;
  }

  #acfi {
    top: 87%;
    left: 8vw;
    flex-direction: row-reverse;
    min-width: 178px;
  }

  #pcs {
    top: 77%;
    left: 24vw;
    flex-direction: row;
    min-width: 250px;
  }

  #sarathi {
    right: 12vw;
    top: 16%;
    flex-direction: row;
    min-width: 226px;
  }

  #pan {
    right: 9vw;
    top: 30%;
    min-width: 228px;
  }

  #ulip {
    top: 43%;
    right: 2vw;
    flex-direction: row;
    min-width: 230px;
  }

  #digilocker {
    right: 28vw;
    top: 46%;
    flex-direction: row-reverse;
    max-width: 171px;
  }

  #vahan {
    left: 19vw;
    top: 59%;
    min-width: 129px;
    flex-direction: column-reverse;
    gap: 0px;
  }

  #fois {
    left: 32vw;
    top: 69%;
    flex-direction: row;
  }

  #fastag {
    left: 5vw;
    top: 79%;
    flex-direction: row-reverse;
  }

  #goods {
    top: 87%;
    left: 25vw;
    flex-direction: row;
    max-width: 253px;
  }


}

@media screen and (min-width:1250px) {
  #ulip {
    right: 6vw;
  }

  #fastag {
    left: 8vw;
    top: 83%;
    flex-direction: row-reverse;
  }
  #cargo{
    top: 66%;
  }
  #iecode {
    right: 30vw;
}
#pcs{
  top: 83%;
  left: 24vw;
}
}

@media screen and (min-width:1350px) {

  .logo_container {
    height: 130px;
    width: 130px;
    bottom: 33%;
    top: 52%;
  }

  .api_box_container {
    max-width: 250px;
  }

  .tile {
    width: 125px;
    height: 85px;

  }
  #digilocker{
    max-width: 208px;
  }
  #fastag {
    left: 6vw;
}
#fois{
  top: 73%;
}
#ulip {
  right: 5vw;
  top: 46%;
}
#pan{
  top: 31%;
}

}
@media screen and (min-width:1500px){
  .tile {
    width: 140px;
    height: 90px;
  }
  #sarathi {
    right: 15vw;
    top: 10%;
}
#pan {
  top: 27%;
  right: 13vw;
}
#digilocker {
  max-width: 229px;
  right: 28vw;
}
#fois {
  top: 71%;
  left: 32vw;
}
#goods {
  top: 85%;
  left: 27vw;
}
#nlds {
  top: 10%;
  right: 17vw;
}
#rthighway {
  top: 27%;
  right: 23vw;
  flex-direction: row-reverse;
  max-width: 288px;
}
#ondc {
  top: 44%;
  left: 52%;
  flex-direction: row;
  max-width: 235px;
}
#custom {
  top: 71%;
  left: 3vw;
  flex-direction: row-reverse;
}
#acfi {
  top: 86%;
  left: 6vw;
}
#pcs{
  top: 83%;
  left: 22vw;
}
#cargo {
  top: 67%;
  left: 18vw;
}
}


@media screen and (min-width:1600px) {
  .logo_container {
    top: 57%;
}
#custom{
  top: 71%;
  left: 5vw;
  flex-direction: row;
}
#fastag {
  left: 9vw;
}
#ulip {
  right: 8vw;
}
#pcs{
  top: 86%;
}
#cargo {
  top: 70%;
  left: 18vw;
}
}

/* @media screen and (min-width:1850px) {
  #rto {
    top: 14%;
    left: 15vw;
  }

  #monitoring_control {
    top: 5%;
    right: 19vw;
    flex-direction: row-reverse;
  }

  #monitoring_control>p{
    text-align: right;
  }

  #e2e_msp {
    bottom: 24%;
    left: 22vw;
    flex-direction: row-reverse;
  }
  #e2e_msp>p{
    text-align: right;
  }

  #data_transfer {
    top: 77%;
    left: 32vw;
    flex-direction: row;
}
  
  #installation {
    bottom: 8%;
    left: 11vw;
    flex-direction: row-reverse;
  }

  #installation>p{
    text-align: right;
  }

  #fois {
    top: 11%;
    right: 16vw;
    flex-direction: row-reverse;
  }

  #fois >p{
    text-align: right;
  }

  #sim_tracking {
    top: 44%;
    left: 4vw;
    flex-direction: row-reverse;
  }

  #sim_tracking>p{
    text-align: right;
  }

  #ldb {
    bottom: 57%;
    right:16.5vw;
  }

  #vessel_tracking {
    top: 60%;
    left: 12vw;
  }

  #cargo_updates {
    bottom: 8%;
    left: 26vw;
  }

  #fastag {
    top: 78%;
    left: 0vw;
    flex-direction: row-reverse;
  }
  
  #fastag>p{
    text-align: right;
  }
} */