.body_text{
  font-weight: 200;
  line-height: 121%;
}

.small{
  font-size: 0.9rem;
}

.medium{
  font-size: 1.2rem;
}

.large{
  font-size: 1.6rem;
}
@media (max-width:600px){
  .medium{
    font-size: 1.1rem;
  }
}
