/* .main{
    position: relative;

  } */

  .main {
    height: 106vh;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-y: auto;
    scroll-behavior: smooth;
    padding-top: 8rem;
    width: 60%;
    margin: auto;
}

.main::-webkit-scrollbar {
    display: none; /* Hides the scrollbar */
}
  .gradient_line {
    width: 60%;
    height: 0;
    border-top: 2px solid;
    border-image-source: linear-gradient(90deg, #000000 3.71%, #FF7200 36.5%, #FF7200 69.5%, #000000 98%);
    border-image-slice: 1;
    margin: 1rem auto;
}
.titlehead{
    width: 75%;
    margin: auto;
}