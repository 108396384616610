/* 


.iotDevices {
  background: url('../../assets/iotDevices/iot_bg_dtop.svg');
  padding: 2.5rem 0rem;
}

.heading {
  font-size: 50px;
}

.firstLine {
  color: #ff7700;
  font-family: Fjalla One;
}

.secondLine {
  color: #fff;
  font-family: Fjalla One;
  position: relative;
}

.container {
  width: 80%;
  margin: auto;
  display: flex;
}

.heading_line {
  position: absolute;
  top: 100%;
  left: 45%;
}

.container > div {
  flex: 1;
}

.contentBox {
  margin-top: 4rem;
  color: #fff;
  text-align: justify;
}

.tabs {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.tab {
  padding: 6px 20px;
  font-size: 18px;
  color: #fff;
  border-radius: 1px;
  transition: background-color 0.3s ease;
}

.activeTab {
  background-color: #333;
}
.hexImg{
  height: 608px !important;
}

@media (max-width: 1100px) {
  .contentBox {
    margin-top: 3rem;
    font-size: 13px;
  }

  .heading {
    font-size: 45px;
  }

  .hexImg {
    display: flex;
    height: 470px !important;
  }

  .heading_line {
    left: 60%;
    width: 30%;
  }
}

@media (max-width: 860px) {
  .heading {
    font-size: 37px;
  }

  .container {
    width: 90%;
  }

  .heading_line {
    position: absolute;
    top: 100%;
    left: 60%;
    width: 25%;
  }

  .tab {
    font-size: 15px;
  }
}

@media (max-width: 700px) {
  .container {
    flex-direction: column;
  }

  .heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .tab {
    font-size: 12px;
    padding: 6px 16px;
  }
  
  .container > div {
    flex: 0 0;
  }
    .hexImg{
      min-height: 95vh !important;
    }
}
@media (max-width:650px) {
  .container > div {
    flex: 0 0;
  }
    .hexImg{
      min-height: 90vh !important;
    }
}

@media (max-width:600px) {
  .container > div {
    flex: 0 0;
  }
    .hexImg{
      min-height: 77vh !important;
    }
}
@media (max-width: 500px) {
  .heading {
    font-size: 32px;
  }

  .tab {
    font-size: 12px;
    padding: 6px 6px;
  }

  .tabs {
    margin-top: 0rem;
  }
  .container > div {
    flex: 0 0;
  }
    .hexImg{
      display: block;
      min-height: 55vh !important;
    }
}

@media (max-width: 400px) {
  .tab {
    font-size: 10px;
    padding: 6px 4px;
  }
  .container > div {
  flex: 0 0;
}
  .hexImg{
    display: block;
    min-height: 55vh !important;
  }
}
.containerHexagon {
  position: relative;
  width: 500px;
  height: 500px;
  z-index: 0;
}

.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: fit-content;
  height: 100%;

}

.imgBox {

  position: absolute;
  top: -40px;
  left: -70px;
  width: auto;
  height: auto;
  z-index: -1;
}

.imgArray {
  width: 100%;
  height: 100%;
  object-fit: cover;
} */









.iotDevices {
  background: url('../../assets/iotDevices/iot_bg_dtop.svg');
  padding: 2.5rem 0rem;
  height: 650px;
}

.heading {
  font-size: 50px;
}

.firstLine {
  color: #ff7700;
  font-family: Fjalla One;
}

.secondLine {
  color: #fff;
  font-family: Fjalla One;
  position: relative;
}

.container {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.heading_line {
  position: absolute;
  top: 100%;
  left: 45%;
}
.leftPart{
  width: 50%;
}
.containerHexagon{
  width: 50%;
  position: relative;
  z-index: 5;
}
.svgContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.imgBox{
  display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.imgArray{
  position: relative;
  /* min-height: 622px;
  min-width: 650px; */
  min-height: 555px;
  min-width: 555px;
  z-index: -2;
}
.hexSvg{
  width: auto;
  position: absolute;
  top: 44px;
  z-index: 2;
}
.contentBox{
  margin-top: 3rem;
  color: #fff;
  text-align: justify;
}
@media screen and (max-width:1300px) {
  .container {
    width: 100%;
    margin-left: 3rem !important;
  }
}
@media screen and (max-width:1160px){
  .container {
    gap: 0rem;
  }
  .containerHexagon{
  margin-right: 3rem ;
  }

  .heading_line {
    left: 60%;
    width: 30%;
  }
  .heading {
    font-size: 45px;
  }

}
@media screen and (max-width:900px) {
  
.iotDevices {
  height: auto;
}
.containerHexagon{
  margin-right: 0rem ;
  }
  .heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }     
  .heading_line {
    position: absolute;
    top: 100%;
    left: 60%;
    width: 25%;
  }
  .container{
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-left: 0rem !important;
  }
  .leftPart{
    width: 80%;
  }
  .containerHexagon{
    width: 80%;
  }
}
@media screen and (max-width:700px){
  .heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }      
  .heading {
    font-size: 40px;
  }                           
}
@media screen and (max-width:465px){
  .imgArray{
    min-height: 420px;
    min-width: 420px;
  }
  .heading {
    font-size: 30px;
  }
}