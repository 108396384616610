/* --- first section styles --- */
.main_section_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin: 3rem 0px; */
  position: relative;
  height: 700px;
}

.main_sec_flowchart{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 10vw;
  z-index: 6;
  width: 83%;
  height: 500px;

}

.main_section_container > .buttons_container{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 8%;
  left: 0;
}

.main_section_container > .buttons_container > div{
  color: #ff7700;
  font-size: 21px;
  /* font-weight: 600; */
  cursor: pointer;
}

.main_section_container > .buttons_container > div:hover{
  color: #fff;
}

.stakeholder_title{
  text-align: center;
  cursor: pointer;
  font-size: 2.3rem;
  font-family: 'Fjalla One', sans-serif;
  transition: all 0.6s;
}

.stakeholder_title:hover{
  /* color: #ffffff !important; */
  background-color: #ff7700; /* Color change on hover */
  transform: scale(1.02); /* Slight scale to indicate it is clickable */
}

.stake_Container{
  display: flex;
  position: absolute;
  justify-content: space-between;
  width: 66%;
  top: 6%;
  left: 22%;
}



.cloud_container{
  /* max-width: 250px;
  height: 200px;
  z-index: 3; */

  /* max-width: 17vw;
  height: 23vh;
  z-index: 3;
  position: absolute;
  top: 23%;
  left: 38%; */
  max-width: 17vw;
    height: 15vh;
    z-index: 3;
    position: absolute;
    top: 45%;
    top: 28%;
    left: 41%;
}



#clickableDiv {
  display: inline-block;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s ease-in; /* Smooth transition for color and scaling */
}

#clickableDiv:hover {
  background-color: #000000;
  transform: scale(1.1);
  color: #ff7700;
  border-radius: 9px;
  padding: 0.4rem;
  
}

/* #clickableDiv:active {
  transform: scale(2);
} */

#fadeOut {
  animation: fadeOutAndGrow 1s forwards;
}

@keyframes fadeOutAndGrow {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(0.7);
    opacity: 0.6;
  }
  75% {
    transform: scale(0.5);
    opacity: 0.3;
  }

  100% {
    transform: scale(0);
    opacity: 0;
  }
}

.floating_text {
  font-size: 13px;
  font-weight: 600;
  color: #ff7700;
  background-color: #000000;
  position: absolute;
  padding: 0px 10px;
  z-index: 2;
}

.flowlines_section {
  width: 100%;
  height: 100%;
  position: relative;
  /* padding-top: 4rem; */
}

.vertical_twin_arrows {
  position: absolute;
  top: 2px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 4rem;
  background-color: #000000;
  z-index: 2;
}

.flowlines_section_arrLeft {
  position: absolute;
  bottom: 0;
  left: -8px;
}

.flowlines_section_arrRight {
  position: absolute;
  bottom: 0;
  right: -8px;
}

.outer_box {
  width: 85%;
  height: 43vh;
  margin: 0px auto;
  /* border: 3px dotted #ff7700; */
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.outer_right_text {
  right: 38px;
  top: 50%;
  transform: translateY(-50%);
}

.outer_bottom_text {
  bottom: 48px;
  left: 50%;
  transform: translateX(-50%);
}

.middle_box {
  width: 75%;
  height: 18%;
  /* border: 3px dotted #ff7700; */
  border-bottom: none !important;
  position: absolute;
  bottom: 35%;
  left: 50%;
  transform: translateX(-50%);
}

.middle_box>.middle_vertical_twin_arrows {
  position: absolute;
  top: -4.9rem;
  left: 50%;
  transform: translateX(-50%);
  width: 187px;
  height: 5.1rem;
  background-color: #000000;
  z-index: 2;
}

.middle_sec_arrLeft {
  position: absolute;
  top: 0;
  left: -12px;
}

.middle_sec_arrRight {
  position: absolute;
  top: 0;
  right: -12px;
}

.middle_left_text {
  top: -10px;
  left: 13%;
}

.middle_right_text {
  top: -10px;
  right: 13%;
}

.inner_most_box {
  width: 69%;
  height: 18%;
  /* border: 3px dotted #ff7700; */
  margin-bottom: 3.5rem;
  position: relative;
  bottom: 47%;
  left: 50%;
  transform: translateX(-50%);
}

.acknowledgement_border {
  height: 1px;
  width: 82%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
}

.acknowledgement_border>.ack_arrLeft {
  position: absolute;
  left: 0;
  top: -8px;
}

.inner_most_box>.inner_vertical_twin_arrows {
  position: absolute;
  top: -6.8rem;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 7rem;
  background-color: #000000;
  z-index: 3;
}

.inner_sec_arrLeft {
  position: absolute;
  top: 0;
  left: -8px;
}

.inner_sec_arrRight {
  position: absolute;
  top: 0;
  right: -8px;
}

.inner_left_text {
  top: -10px;
  left: 13%;
}

.inner_right_text {
  top: -10px;
  right: 13%;
}

.inner_bottom_text {
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.ack_text {
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.node_chip {
  padding: 5px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  color: #ffffff;
  background: #000000;
  width: 150px;
  border-radius: 5rem;
  border: 1.5px solid #ff7700;
  position: absolute;
  z-index: 5;
}

.active_node {
  width: auto !important;
  color: #ff7700 !important;
  position: absolute;
  left: 50%;
  top: 30%;
  transform: translateX(-50%);
}

#warehouse {
  left: 0;
  top: 8%;
}

#shipper1 {
  right: 0;
  top: 8%;
}

#transporter {
  right: 0;
  bottom: 26%;
}

#shipper2 {
  left: 0;
  bottom: 26%;
}

/* --- internal styles --- */

/* Keyframes for scale animation */
@keyframes scaleIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

/* Style for the container div where the scale animation will apply */
.active1container {
  animation: scaleIn 1s ease-in-out forwards; /* Apply scale animation */
  display: flex;
  justify-content: center;
  align-items: center;
  /* Optional: Set a max width for the container */
  max-width: 850px;
  margin: 0 auto; /* Centers the content */
  perspective: 300px; /* Optional: If you want to add some 3D perspective */
}
.internal_dcloudimg{
  position: absolute;
  bottom: 25%;
  height: 15vh;
  left: 44.5%;
 }

 
.internal_container {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}

.container {
  width: 398px;
  padding: 15px;
  border: 2px dashed #ff7700;
  background-color: #3D3D3D;
  line-height: 1.5;
  min-height: 300px;
}

.section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section h3 {
  color: #ff7700;
  font-size: 16px;
  /* font-weight: bold; */
  font-family: 'Fjalla One', 'sans-serif' !important;
  min-width: 102px;
  max-width: 102px;
}

.section p {
  margin: 5px 0 0;
  font-size: 14px;
  color: #ffffff;
}

.divider {
  height: 1px;
  background-color: #ff7700;
  margin: 8px 0px 8px 24px;
}

.box_container {
  display: flex;
  gap: 8px;
  justify-content: center;
  flex-wrap: nowrap;
}
.main_box_container{
  margin: 5rem 0rem;
}

/* @media (max-width:1300px){
  .cloud_container{
    top: 19%;
  }
} */
@media (max-width:1250px) {
  .s1Dtop {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
  }
  .stakeholder_title{
    font-size: 2rem;
  }
  /* .cloud_container{
    top: 22%;
    left: 37.5%;
  } */
}

@media (max-width:1050px){
  .cloud_container{
    top: 20%;
    left: 39%;
    max-width: 15vw;
    height: 11vh;
    height: 17vh;
  }
  .stakeholder_title{
    font-size: 1.6rem;
  }
}
/* --- external styles --- */
.external_container {
  position: relative;
  padding: 20px;
  color: #ffffff;
}

.content_box1 {
  position: absolute;
  border: 2px dashed #ff7700;
  top: -2%;
  left: -6%;
  width: 275px;
  min-height: 100px;
  font-family: 'Bebas Neue Pro';
  padding: 6px;
  background-color: #3D3D3D;
  font-size: 0.95rem;
}

.content_box2 {
  position: absolute;
  border: 2px dashed #ff7700;
  top: -2%;
  right: -6%;
  width: 275px;
  min-height: 100px;
  padding: 6px;
  background-color: #3D3D3D;
  font-size: 0.95rem;
}

.content_box3 {
  position: absolute;
  border: 2px dashed #ff7700;
  bottom: -8%;
  left: -6%;
  width: 275px;
  min-height: 100px;
  padding: 6px;
  background-color: #3D3D3D;
  font-size: 0.95rem;
}

.content_box4 {
  position: absolute;
  border: 2px dashed #ff7700;
  bottom: -8%;
  right: -6%;
  width: 275px;
  min-height: 100px;
  padding: 6px;
  background-color: #3D3D3D;
  font-size: 0.95rem;
}
.content_box1, .content_box2, .content_box3, .content_box4{
  opacity: 0; /* Initially invisible */
  animation: fadeIn 4000ms ease-in-out forwards; /* Apply the fade-in animation */
}
@keyframes fadeIn {
  from {
    opacity: 0; /* Start invisible */
  }
  to {
    opacity: 1; /* End fully visible */
  }
}

.s2Dtop {
  margin: 10rem auto;
  width: 70%;
  transform: scale(0.8, 1);
}

.external_dcloudimg{

  position: absolute;
  top: 33%;
  height: 15vh;
  left: 44.5%;
}

.mslide2 {
  display: none;
  min-width: 100%;
  padding: 20px;
  position: relative;
}

.consignor{
  color: #ff7700;
  font-family: 'Fjalla One', 'sans-serif' !important ;
  font-size: 30px;
  text-align: center;
}

.cloud{
  display: flex;
  align-items: center;
  justify-content: center;
}
.cloud>img{
  height: 100px;
}
.mbox_container {
  display: flex;
  gap: 8px;
  justify-content: center;
  flex-wrap: nowrap;
}
.mcontainer {
  width: 398px;
  padding: 10px;
  border: 2px dashed #ff7700;
  background-color: #3D3D3D;
  line-height: 1.3;
  /* min-height: 280px; */
  margin-top: 7px;

}

.msection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.msection h3 {
  color: #ff7700;
  font-size: 16px;

  font-family: 'Fjalla One', 'sans-serif' !important;
  min-width: 102px;
  max-width: 102px;
}

.msection p {
  font-size: 14px;
  margin: 0px;
  color: #ffffff;
}

.mdivider {
  height: 1px;
  background-color: #ff7700;
  margin: 8px 0px 8px 24px;
}

.mslide3{
  display: none;
}
.main_sec_flowchart_mobile{
  display: none;
}
@media (max-width:1413px) {
  .s2Dtop {
    width: 66%,
  }
}

@media (max-width: 1300px) {
  .s2Dtop {
    width: 72%;
  }
}
@media (max-width:1250px){
  .container {
    width: 340px;
  }
  .section p {
    font-size: 12px;
  }
  .main_sec_flowchart{
    top: 11vw;
}
}
@media (max-width:1220px) {
  .s2Dtop {
    width: 76%;
    min-height: 411px;
  }
  .main_sec_flowchart {
    top: 13vw;
}
}

@media (max-width:1050px){
  /* .internal_container{
    display: none;
  } */
  .main_sec_flowchart {
    top: 14vw;
}
   .internal_dimg{
    display: none;
   }
   .internal_dcloudimg{
    display: none;
   }
   .main_box_container{
    margin: 1rem 0rem;
  }
   .box_container{
    flex-direction: column;
    height: 339px;
    overflow-y: auto;
    justify-content: flex-start;
    padding: 18px 8px 12px 8px;
    background-color: #201d1d;
    border-radius: 6px;
    margin-top: 10px;
   }
  .mslide3{
    display: block;
  }
}
@media (max-width:970px){
  .main_sec_flowchart {
    top: 14.5vw;
}
}
@media (max-width:900px){
  .main_sec_flowchart {
    top: 16.5vw;
}
}
@media (max-width:800px){
  .main_sec_flowchart {
    top: 18.5vw;
}
}
@media (max-width:700px){
  .main_sec_flowchart {
    top: 28.5vw;
    width: 83%;
    height: 381px;
}
}
@media (max-width:650px){
  .main_sec_flowchart {
    top: 30.5vw;
}
}
@media (max-width:600px){
  .main_sec_flowchart {
  display: none;
}
.main_sec_flowchart_mobile{
  display: block;
  position: absolute;
  z-index: 3;
  top: 27%;
  width: 84%;
  left: 8%;
  transform: scaleY(1.5);
}
.main_section_container {

  height: 500px;
}
.main_section_container > .buttons_container{
  top: -3%;
}
}

/* 
@media (max-width:850px) {
  
  .node_chip{
    padding: 5px 1px;
    font-size: 1rem;
    border-radius: 4rem;
    border: 1.5px solid #ff7700;
    }

  #freight{
    width: 215px !important;
    padding: 2px !important;
  }
  
    #warehouse{
      left: 5%;
      top: 14%;
    }
    
    #shipper1{
      right: 5%;
      top: 14%;
    }

    #transporter{
      right: 5%;
      bottom: 22%;
    }
    
    #shipper2{
      left: 5%;
      bottom: 22%;
    }

    .acknowledgement_border{
 
      width: 72%;
      
    }
} */

@media (max-width:900px) {
  /* .main_section_container {
    transform: scale(0.9);
  } */

  .external_container {
    display: none;
  }

  .mslide2 {
    display: block;
    margin-bottom: 15px;
  }
  .cloud_container {
    height: 13vh;
}
.stakeholder_title{
  font-size: 1rem;
}
}

@media (max-width:700px) {
  .main_section_container {
    /* transform: scale(0.7); */
    width:80vw;
    margin: 0px;
    margin: 2rem 0rem
  }

}




@media (max-width:500px) {
  .main_section_container {
    width: 93vw;
    /* margin: 4rem 0rem 2rem 0rem; */
  }

  .flowlines_section {
    transform: scale(1, 1.6);
    margin-top: 6.5rem;
  }

  .mcontainer{
    width: 360px;
  }

  .cloud_container{
    top: 21%;
    left: 40%;
    height: 8vh;
   
}
}


/* @media (max-width:450px) {
  .main_section_container {
    margin: 0rem 0rem -2rem 0rem;
  }

} */

@media (max-width:430px) {
  /* .main_section_container {
    transform: scale(0.4);
    margin-top: -9rem;
  } */

  /* .flowlines_section {
    transform: scale(1, 2);
    margin-top: 11rem;
  } */

  .mcontainer{
    width: 330px;
  }
}

@media (max-width:430px) {
  /* .main_section_container {
    transform: scale(0.36);
    margin-top: -16rem;
  } */

  /* .flowlines_section {
    transform: scale(1, 2);
    margin-top: 11rem;
  } */
  .main_sec_flowchart_mobile{
    display: block;
    position: absolute;
    z-index: 3;
    top: 30%;
    width: 84%;
    left: 8%;
    transform: scaleY(1.5);
  }
}


.l_shaped_line {
  position: absolute;
  top: 5%;
  z-index: 3;
  left: -11%;
}

.l_shaped_line::before,
.l_shaped_line::after {
  content: "";
  position: absolute;
  /* background-color: transparent;
  border-style: dashed; */
  /* border-color: #ff7700; */
}

.l_shaped_line::before {
  width: 215px;
  height: 0;
  border-top: 2px dashed #ff7700;
  top: 145px;
  left: 0;
}

.l_shaped_line::after {
  width: 0;
  height: 147px;
  border-left: 2px dashed #ff7700;
  top: 0;
  left: 0;
}

.l_shaped_line2{
  top: 72.3%;
  left: -11%;
  position: absolute;
  z-index: 3;
}
.l_shaped_line2::before,
.l_shaped_line2::after {
  content: "";
  position: absolute;
}

.l_shaped_line2::before {
  width: 207px;
  top: 0px;
  height: 0;
  border-top: 2px dashed #ff7700;
  left: 0;
}

.l_shaped_line2::after {
  height: 145px;
  width: 0;
  border-left: 2px dashed #ff7700;
  top: 0;
  left: 0;
}


.l_shaped_line3{
  top: 72.3%;
  right: 13%;
  position: absolute;
  z-index: 3;
}
.l_shaped_line3::before,
.l_shaped_line3::after {
  content: "";
  position: absolute;
}

.l_shaped_line3::before {
  width: 207px;
  top: 0px;
  height: 0;
  border-top: 2px dashed #ff7700;
  left: 0;
}

.l_shaped_line3::after {
  height: 145px;
  width: 0;
  border-left: 2px dashed #ff7700;
  top: 0;
  left: 207px;
}


.l_shaped_line4 {
  position: absolute;
  top: 5%;
  z-index: 3;
  right: 13%;
}

.l_shaped_line4::before,
.l_shaped_line4::after {
  content: "";
  position: absolute;
  /* background-color: transparent;
  border-style: dashed; */
  /* border-color: #ff7700; */
}

.l_shaped_line4::before {
  width: 215px;
  height: 0;
  border-top: 2px dashed #ff7700;
  top: 145px;
  left: 0;
}

.l_shaped_line4::after {
  width: 0;
  height: 147px;
  border-left: 2px dashed #ff7700;
  top: 0;
  left: 215px;
}


@media (min-width:1575px) {
  .main_sec_flowchart{
    top: 8vw;
    height: 525px;
  }
}
@media (min-width:1680px) {
  .main_sec_flowchart{
    top: 7.5vw;
  }
}
@media (min-width:1800px) {
  .main_sec_flowchart{
    top: 7vw;
  }
}



/* .wrapper{
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  height:auto;
} */
 .wrapper:hover{
  transform: scale(1.01);
 }
.extIntbutton{
     height: 70px;
     background: #000000;
     border-style: none;
     color: #ff7700;
     font-size: 23px;
     cursor: pointer;
     position: relative;
     padding: 6px;
     overflow: hidden;
     transition: all .5s;
     box-shadow: 0px 1px 2px rgba(0, 0, 0, .2);
}
.extIntbutton>span{
  position: absolute;
  display: block;
}
.extIntbutton>span:nth-child(1){
  height: 2px;
  width:240px;
  top:0px;
  left:-240px;
  background: linear-gradient(to right, rgba(0,0,0,0), #ff7700);
  border-top-right-radius: 1px;
  border-bottom-right-radius: 1px;
  animation: span1 2s linear infinite;
  animation-delay: 1s;
}

@keyframes span1{
  0%{
      left:-240px
  }
  100%{
      left:240px;
  }
}
.extIntbutton>span:nth-child(2){
  height: 70px;
  width: 2px;
  top:-70px;
  right:0px;
  background: linear-gradient(to bottom, rgba(0,0,0,0), #ff7700);
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
  animation: span2 2s linear infinite;
  animation-delay: 2s;
}
@keyframes span2{
  0%{
      top:-70px;
  }
  100%{
      top:70px;
  }
}
.extIntbutton>span:nth-child(3){
  height:2px;
  width:240px;
  right:-240px;
  bottom: 0px;
  background: linear-gradient(to left, rgba(0,0,0,0), #ff7700);
  border-top-left-radius: 1px;
  border-bottom-left-radius: 1px;
  animation: span3 2s linear infinite;
  animation-delay: 3s;
}
@keyframes span3{
  0%{
      right:-240px;
  }
  100%{
      right: 240px;
  }
}

.extIntbutton>span:nth-child(4){
  height:70px;
  width:2px;
  bottom:-70px;
  left:0px;
  background: linear-gradient(to top, rgba(0,0,0,0), #ff7700);
  border-top-right-radius: 1px;
  border-top-left-radius: 1px;
  animation: span4 2s linear infinite;
  animation-delay: 4s;
}
@keyframes span4{
  0%{
      bottom: -70px;
  }
  100%{
      bottom:70px;
  }
}


@media (max-width:650px) {
  .extIntbutton {
    width: min-content;
    padding: 0px 12px;
}
}
@media(max-width:500px){
  .extIntbutton {
    font-size: 17px;
}
}