.outer_container{
  padding: 2.5rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.slides_area{
  width: 100%;
}

.tab_container_outer{
  width: 100%;
  overflow-x: scroll;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0px;
}

.tab{
  padding: 0.3rem 1rem;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  font-size: 12.5px;
  width: 100%;
  white-space: nowrap;
  cursor: pointer;
  font-weight: 600;
}

.info_section{
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.img_container{
  width: 100%;
  height: 600px;
  position: relative;
}

.img_container > img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img_container::before{
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(to right, #0000005c, transparent);
}

.title_over_img{
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 350px;
  justify-content: center;
  align-items: center;
}

.title_over_img > span{
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  margin: 0px;
  color: #ff7700;
  text-transform: uppercase;
}

.details{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: transparent;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  z-index: 1;
}

.details::before{
  content: "";
  position: absolute;
  height: 90%;
  width: 1px;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  background: linear-gradient(to bottom, #ffffff, rgba(153, 152, 152, 0.644));
}

.details::after{
  content: "";
  position: absolute;
  height: 1px;
  width: 20px;
  bottom: 45px;
  left: 3px;
  background: linear-gradient(to left, #ffffff, rgba(153, 152, 152, 0.644));
}

.details_title{
  display: none;
  padding: 0.4rem 0px;
  position: relative;
}

.details_title > span{
  font-size: 0.8rem;
  color: #ffffff;
  font-weight: 700;
}

.details_title::before{
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0;
  height: 1px;
  width: 85%;
  background: #ff7700;
}

.details_content>ul{
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  padding-left: 14px;
}

.details_content li{
  font-size: 1rem;
  color: #ff7700;
  font-weight: 500;
  margin-top: 0.8rem;
}

.details > button{
  margin-top: auto;
}

@media (min-width: 800px){
  .outer_container{
    padding: 2.5rem 3.5rem;
  }

  .tab_container_outer{
    gap: 1rem;
  }

  .title_over_img{
    display: flex;
  }

  .img_container{
    height: 550px;
  }

  .details{
    height: 550px;
    position: relative;
    gap: 0.8rem;
  }

  .details::before{
    top: 0;
    left: 0;
    height: 100%;
    width: 1.5px;
    transform: unset;
  }
  
  .details::after{
    bottom: 45px;
    left: -10px;
    width: 34px;
    height: 1.5px;
  }

  .details_title > span{
    font-size: 1.3rem;
    color: #000000;
  }
  .details_content>ul{
    gap: 0.4rem;
    padding-left: 14px;
  }

  .details_content>li{
    font-size: 1rem;
    font-weight: 500;
  }

  .tab{
    font-size: 1rem;
    padding: 0.5rem 0.7rem;
  }
}
@media (min-width:1000px) {
  .details_content ul{
    gap: 0.5rem;
    padding-left: 14px;
  }

  .details_content li{
    font-size: 1.18rem;
    font-weight: 500;
  }
}
@media (min-width: 1300px){
  .outer_container{
    padding: 5rem 7rem;
  }

  .tab_container_outer{
    gap: 1rem;
  }

  /* .img_container{
    height: 650px;
  } */

  .details{
    /* height: 650px; */
    width: 60%;
  }

  .details_title > span{
    font-size: 1.5rem;
  }

  .details_content li{
    font-size: 1.2rem;
  }
  
  .tab{
    padding: 0.5rem 1rem;
  }
  .title_over_img {
    display: none;
  }
  .title_over_img > span{
    font-size: 2.1rem;
  }

}

@media (min-width: 1500px){
  .tab{
    font-size: 1.2rem;
  }

  .details_content li{
    font-size: 1.4rem;
  }

  .img_container{
    height: 650px;
  }

  .details{
    height: 650px;
  }
}