.main_container{
  padding: 5rem 1rem;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.slider_container__outer {
  width: 100%;
  overflow-x: auto;  /* Enable horizontal scrolling */
  scroll-snap-type: x mandatory;  /* Snap to items when scrolling */
  display: flex;
  -webkit-overflow-scrolling: touch;  /* Smooth scrolling on iOS */
}

.slider_container__inner{
  display: flex;
  width: 100%;
  padding: 20px 2vw;
  gap: 2vw;
}

.slider_item {
  /* flex: 0 0 80vw;  */
  scroll-snap-align: center;  /* Center the active item */
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 
    0 4px 12px rgba(0, 0, 0, 0.15), /* Outer shadow */
    inset 0px 0px 18px 40px rgba(0,0,0,0.02); /* Inner shadow */
  background-color: #fff;
  overflow: hidden;  /* Hide overflow within the item */
  width: 100%;
  max-width: 550px;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  min-width: 300px;
}

.slider_item .logo_rating{
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.slider_item .rating{
  display: flex;
  align-items: center;
  gap: 2px;
}

.slider_item .rating img{
  height: 25px;
  width: 25px;
} 

.slider_item > .title{
  margin: 0px;
  font-size: 18px;
  font-weight: 700;
}

.slider_item > .body{
  margin: 0px;
  font-size: 15px;
}

@media (min-width: 1000px){
  .main_container{
    padding: 5rem 4rem;
    padding-top: 2rem;
    gap: 3rem;
  }

  .slider_item {
    min-width: 350px;
  }

  .slider_item .logo_rating{
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .slider_item > .title{
    margin: 0px;
    font-size: 20px;
    font-weight: 700;
  }
  
  .slider_item > .body{
    margin: 0px;
    font-size: 16px;
  }
}