.main_container{
    position: relative;
    height: 100vh;
}
.box_border_container1{
    position: absolute;
    top: 12%;
    left: 10%;
}

.img>img{
    max-height: 650px;
    width: 100%;
}

.border-container {
    position: relative;
    width: 500px;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0F0E0EB5;
    
}

.outer-border {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid #FF7700;
    box-sizing: border-box;
    border-image-repeat: stretch;
}

.inner-border {
    position: absolute;
    width: 88%;
    height: 88%;
    border: 1px solid #FF7700;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    
.contentHeading{
    font-size: 35px;
}
}
.inner-border>img{
    height: 100px;
}
.connecting_lines {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

/* Top-left to bottom-right connecting line */
.connecting_lines::before {
    content: '';
    position: absolute;
    width: 1px;
    height: 9%;
    background-color: #FF7700;
    top: 0;
    left: 0;
    transform: rotate(-45deg);
    transform-origin: top left;
    z-index: 1;
}

/* Top-right to bottom-left connecting line */
.connecting_lines::after {
    content: '';
    position: absolute;
    width: 1px;
    height: 9%;
    background-color: #FF7700;
    top: 0;
    right: 0;
    transform: rotate(45deg);
    transform-origin: top right;
    z-index: 1;
}

/* Bottom-left to top-right connecting line */
.connecting_lines .bottom_left_to_top_right {
    content: '';
    position: absolute;
    width: 1px;
    height: 9%;
    background-color: #FF7700;
    bottom: 0;
    left: 0;
    transform: rotate(45deg);
    transform-origin: bottom left;
    z-index: 1;
}

/* Bottom-right to top-left connecting line */
.connecting_lines .bottom_right_to_top_left {
    content: '';
    position: absolute;
    width: 1px;
    height: 9%;
    background-color: #FF7700;
    bottom: 0;
    right: 0;
    transform: rotate(-45deg);
    transform-origin: bottom right;
    z-index: 1;
}

/* #FF7700 squares at each corner of the outer border */
.corner {
    position: absolute;
    width: 6px;
    height: 6px;
    background-color: #FF7700;
    z-index: 2;
}

/* Positioning the #FF7700 squares at each corner */
.top-left {
    top: -2px;
    left: -2px;
}

.top-right {
    top: -2px;
    right: -2px;
}

.bottom-left {
    bottom: -2px;
    left: -2px;
}

.bottom-right {
    bottom: -2px;
    right: -2px;
}

@media (max-width:1000px){
    .box_border_container1{
        top: 7%;
        left: 7%;
    }
}
 
@media (max-width:930px){
   .img{
    position: relative;
    width: 100%;
   }
   .img>img{
    height: 545px;
}
   .box_border_container1{
    top: 3%;
    left: 50%;
    transform: translate(-50%, 0%);
} 
}

@media (max-width:520px){
    .border-container{
        transform: scale(0.8);
    }
    .box_border_container1{
        top: 0%;
        left: 50%;
        transform: translate(-50%, -9%);
    } 
    .img>img{
        height: 408px;
    }
}

@media (max-width:410px){
    .border-container{
        transform: scale(0.7);
    }
}

@media (max-width:370px){
    .border-container{
        transform: scale(0.6);
    }
}



.video_container{
    height: 100%;
    width: 100%;
    position: relative;
}

.video_container > video{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.info_box{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 1rem;
    background: linear-gradient(transparent, #000000bd, #000000);
    z-index: 4;
}
.content_wrapper{
    width: 100%;
    max-width: 1250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    gap: 1rem;
}

@media (max-width:700px) {
    .video_container>video {
      display: block !important;
    }
  }