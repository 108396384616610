.header {
  background-color: #101010;
  color: white;
  padding: 10px 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100%;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  width: 100%;
}

.logo {
  font-size: 24px;
  font-weight: bold;
}

.logo > img{
  width: 180px;
}

.nav {
  display: flex;
  align-items: center;
}

.nav-list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 2rem;
}

.nav-item {
  position: relative;
  /* margin-left: 20px; */
}

.nav-link {
  color: white;
  text-decoration: none;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.sub-nav-item{
  border-bottom: 1px solid #242222;
}

.nav-link:hover {
  color: white;
  text-decoration: underline;
}

.nav-link:active {
  color: #FF7700;
}

.submenu {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  background-color: #444;
  list-style: none;
  padding: 0.45rem 0px 0px 0px;
  margin: 0;
  min-width: 110px;
}

.submenu.open {
  display: block;
}

.submenu .nav-item {
  margin: 0;
  padding: 0px 0.45rem 0.3rem 0.45rem;
}

.submenu .nav-item:hover{
  background: #242222;
}

.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 25px;
  height: 21px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.hamburger-line {
  width: 100%;
  height: 3px;
  background-color: white;
}

.action_btn_container{
  display: flex;
  align-items: center;
  gap: 14px;
}

.action_btn_container > button{
  box-shadow: none;
  border: none;
  padding: 0.5rem 1.2rem;
  border-radius: 0.3rem;
  color: #fff;
  font-weight: 600;
}

.action_btn_container > button:hover{
  box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.6);
}

.filled_gray{
  background: #4A4A4A;
}

.filled_orange{
  background: #FF7700;
}
.header_login_btn{
  color: #fff;
  text-decoration: none;
}
/* Media queries for responsive design */
@media (max-width: 980px){
  .nav {
    display: none;
    flex-direction: column;
    width: 100%;
    text-align: center;
    background-color: #333;
    position: absolute;
    /* top: 55px; */
    top: 40px;
    left: 0;
  }

  .nav.open {
    display: flex;
  }

  .nav-list{
    flex-direction: column;
  }

  .nav-item {
    margin: 10px 0;
  }

  .submenu {
    position: relative;
    background-color: #444;
  }

  .hamburger {
    display: flex;
  }

  .action_btn_container{
    display: none;
  }

  .logo > img{
    width: 100px;
  }
}

@media (max-width: 768px) {
  
}

@media (min-width: 1300px){
  .container{
    width: 75%;
    margin: 0 auto;
  }
}