.supply_chain_container{
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #000000;
}

.heading_box {
  text-align: center;
  padding-top: 2rem;
}

.heading_para {
  font-size: 22px;
  font-family: 'Fjalla One', 'sans-serif' !important;
  color: #ffffff;
}

.go_back_btn{
  margin: 10px 0px;
  background: transparent;
  backdrop-filter: blur(2px); 
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 50px;
  border: 1px solid #3e3e3e;
  cursor: pointer;
  transition: all 0.9s;
}

.go_back_btn:hover{
  /* color: #ff7700; */
  border-color: #ff7700;
  background-color: #ff77004b;
}