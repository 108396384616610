.accordion-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .skills {
    width: 100%;
    max-width: 800px;
    margin-top: 20px;
  }
  
  .accordion-items {
    position: relative;
    border: 2px solid #b7b7b7b7;
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 0px 20px;
    transition: border-color 0.3s ease-in-out;
    text-align: justify;
  }
  
  .accordion-items.open {
    background: linear-gradient(180deg, #000000 -6%, #565656 16%, #000000 128%) padding-box,
      linear-gradient(180deg, #FF7700 0%, #000000 100%) border-box;
    border: 3px solid transparent;
  }
  
  .accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 9px 0;
    cursor: pointer;
    gap: 10px;
  }
  
  .index-title {
    display: flex;
    gap: 20px;
  }
  
  .title {
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
  }
  
  .accordion-items.open .title {
    color: #FF7700;
  }
  
  .accordion-panel {
    font-size: 14px;
    color: #ffffff;
    overflow: hidden;
  }
  @media (max-width:500px){
    .accordion-panel {
        font-size: 13px;
      }
      .title {
        font-size: 14px;

      }
      .accordion-items {
        padding: 0px 10px;
      }
      
  }