.main {
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    margin: 5rem auto;
    width: 80%;
    height: 75vh;
    overflow-y: auto;
}
.leftbox{
    width: 30%;
    padding-top: 2rem;
}
.mobileHeading{
    display: none;
}
.heading {
    font-size: 45px;
}
.gradient_line {
    width: 60%;
    height: 0;
    border-top: 2px solid;
    border-image-source: linear-gradient(90deg, #000000 3.71%, #FF7200 36.5%, #FF7200 69.5%, #000000 98%);
    border-image-slice: 1;
    margin: 1rem auto;
}
.container {
    width: 65%;
}

.filled_orange {
    box-shadow: none;
    border: none;
    padding: 0.5rem 1.2rem;
    border-radius: 0.3rem;
    color: #fff;
    font-weight: 600;
}

.filled_orange :hover {
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.6);
}


.filled_orange {
    background: linear-gradient(90deg, #FF7700 0%, #994700 100%);
}


@media (max-width:1050px){
    .main {
        width: 90%;
    }
    .container {
        width: 95%;
    }
}

@media (max-width:880px){
    .leftbox{
        display: none;
    }
    .mobileHeading{
        display: block;
    }
    .main{
        margin-top: 1rem;
      }
}