   .cardContainer {
    height: 85vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky; /* Ensures the element "sticks" */
    top: 0; /* Sticks at the top of the viewport */
    z-index: 1; /* Keep it visible when overlapping other elements */
}
.card {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -25%;
    height: 400px;
    width: 100%;
    border-radius: 25px;
    padding: 50px;
    transform-origin: top;
    background: linear-gradient(180deg, #000000 0%, #565656 61%, #000000 100%) padding-box, linear-gradient(180deg, #FF7700 0%, #000000 100%) border-box;
    border: 3px solid transparent;
    border-radius: 15px;
    pointer-events: auto; /* Allow interaction for the current card */
}

.title {
    text-align: center;
    margin: 0px;
    font-size: 28px;
    color: #FF7700;
}

.vertical_line {
    width: 2px;
    height: 96px;
    background-color: #ffffff;
    margin-right: 20px;
}

.description {
    width: 50%;
    position: relative;
}

.card .body .imageContainer {
    position: relative;
    width: 60%;
    height: 100%;
    border-radius: 25px;
    overflow: hidden;
}

.card .body .imageContainer img {
    object-fit: cover;
}

