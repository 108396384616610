.xbl_card_outer{
  position: relative;
  width: 19vw;
  height: 25.7vw;
  min-width: 150px;
  max-width: 300px;
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.xbl_outer_border {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px solid transparent;
  border-image: linear-gradient(113deg, #331800 0%, #FF7700 50%, #000000 100%);
  border-image-slice: 5;
  box-sizing: border-box;
  border-image-repeat: stretch;
  z-index: -1;
}

.xbl_inner_border {
  position: absolute;
  width: 80%;
  height: 80%;
  border: 1px solid #8D8D8D;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: border-color 1.5s ease-in-out; /* Transition border color specifically */
}

.xbl_inner_background {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  z-index: -1;
  background: #000000c9;
  transition: background 1.5s ease-in-out; /* Transition background specifically */
}

.xbl_inner_border:hover .xbl_inner_background {
  background: radial-gradient(circle 150px, #ff77009c, #000);
}

.xbl_inner_border:hover .metric_title {
  color: rgba(255, 119, 0, 1) !important;
  transition: color 0.5s ease-in-out;
}

.xbl_inner_border > .content{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px;
  text-align: center;
  gap: 10px;
}

.mt_body_text{
  font-size: clamp(0.9rem, 1vw, 18px);
}

.xbl_connecting_lines {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

/* Top-left to bottom-right connecting line */
.xbl_connecting_lines::before {
  content: '';
  position: absolute;
  width: 1px;
  min-height: 36px;
  height: 3.4vw;
  background-color: #8D8D8D;
  top: 0;
  left: 0;
  transform: rotate(-35deg);
  transform-origin: top left;
  z-index: 1;
}

/* Top-right to bottom-left connecting line */
.xbl_connecting_lines::after {
  content: '';
  position: absolute;
  width: 1px;
  min-height: 36px;
  height: 3.4vw;
  background-color: #8D8D8D;
  top: 0;
  right: 0;
  transform: rotate(35deg);
  transform-origin: top right;
  z-index: 1;
}

/* Bottom-left to top-right connecting line */
.xbl_connecting_lines .xbl_bottom_left_to_top_right {
  content: '';
  position: absolute;
  width: 1px;
  min-height: 36px;
  height: 3.4vw;
  background-color: #8D8D8D;
  bottom: 0;
  left: 0;
  transform: rotate(35deg);
  transform-origin: bottom left;
  z-index: 1;
}

/* Bottom-right to top-left connecting line */
.xbl_connecting_lines .xbl_bottom_right_to_top_left {
  content: '';
  position: absolute;
  width: 1px;
  min-height: 36px;
  height: 3.4vw;
  background-color: #8D8D8D;
  bottom: 0;
  right: 0;
  transform: rotate(-35deg);
  transform-origin: bottom right;
  z-index: 1;
}

.xbl_corner {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #FF7700;
  z-index: 2;
}

/* Positioning the squares at each corner */
.xbl_top-left {
  top: -5px;
  left: -5px;
}

.xbl_top-right {
  top: -5px;
  right: -5px;
}

.xbl_bottom-left {
  bottom: -5px;
  left: -5px;
}

.xbl_bottom-right {
  bottom: -5px;
  right: -5px;
}
