.hero_section{
    overflow-x: hidden;
    overflow-y: auto;
    display: none;
  }
 
  .main_img_with_text_container{
    background: linear-gradient(#202020 10%, #000000);
    max-height: 680px;
    /* min-height: 95vh; */
    height: 95vh;
    position: relative;
    animation: fadeIn 3s;
  }


  .main_backdrop{
    height: 100%;
    width: 100%;
  }
  
  .main_backdrop img{
    height: 100%;
    width: 100%;
    margin-left: 10vw;
     object-fit: contain;
  }

  .main_title_section{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: absolute;
    left: 2.3rem;
    top: 50%;
    transform: translateY(-50%);
    max-width: 500px;
  }

  .content{
    font-family: "Fjalla One", sans-serif !important;
    font-size: 34px;
    font-weight: 700;
    color: #fff;
    white-space: pre-line;
    text-transform: capitalize;
    /* line-height: 3.2rem; */
  }
  .content>span{
    font-family: "Fjalla One", sans-serif !important;
  }
  .firstLine{

    font-size: 2.5rem;
  }
  .secondLine{
    font-size: 3.2rem;
  }
  .text_bg{
    background: url('../../assets/heros_section/content_background_color.svg') no-repeat center center/cover;
    margin: 6px 0px 0px 0px;;
    font-size: 2.5rem;

  }


  .button_container{
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 22px;
  }
  .app_btns{
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .app_btn{
    cursor: pointer;
    height: 30px;
    width: 30px;
  }
  
  .app_btn > img{
    height: 100%;
    width: 100%;
  }
  


.heroContainer{
    background-color: #171717;
   position: relative;
    height: 90vh;
   max-height: 585px;
}

.mobileImage {
    width: 100%;
    height: auto;
  }
  
  .d_text_container {
    position: absolute;
    top: 72%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    min-width: 100%;
  }
  .firstPara{
    color: rgba(198, 198, 198, 1);
    font-weight: 400;
    font-size: 18px;
    font-family: "Fjalla One", sans-serif;

  }

  .secondPara{
    color: rgba(255, 119, 0, 1);
    font-size: 21px;
    font-weight: 500;
    font-family: "Fjalla One", sans-serif;
    
  }
  .thirdPara{
    background: url('../../assets/heros_section//content_background_color.svg') no-repeat center center/cover;
    color: #FFFFFF;
    font-size: 22px;
    font-weight: 600;
    font-family: "Fjalla One", sans-serif;
  }
  #secondParaTab{
    display: none;
  }

@media (min-width:325px){
    .mobileImage {
        
      height: 54%;
      max-height: 391px;
      }
      .button_container{
        justify-content: center;
       margin-top: 15px;
      }
      .button_container {
        margin-bottom: 11px;
    }
}
@media (min-width: 410px) {
  .mobileImage{
      height: 56%;

  }
}
@media (min-width:500px){
    .mobileImage {
        height: 55%;
      }
      .firstPara{
        font-weight: 400;
        font-size: 20px;
      }
    
      .secondPara{
        font-size: 22px;
        font-weight: 500;
        
      }
      .thirdPara{
        font-size: 25px;
        font-weight: 600;
      }
      .button_container {
        margin-bottom: 11px;
    }
}
@media (min-width:700px){
    .mobileImage {
        height: 63%;
      }

      .d_text_container {
        top: 77%;       
      }
      .secondPara{
        font-size: 23px;
      }
}

@media only screen and (min-width:680px) and (max-width:910px){
#secondParaMob{
  display: none !important;
}
#secondParaTab{
  display: block ;
}
.mobileImage {
  height: 63%;
}
.d_text_container {

  top: 77%;
  left: 50%;
}
}




@media (min-width: 910px){

 
  .hero_section{
    display: block;
  }
  .heroContainer{
    display: none;
  }
  .button_container{
    justify-content: flex-start;
  }
  .firstLine {
    font-size: 1.6rem;
  }
  .secondLine {
    font-size: 2.2rem;
}
.text_bg {
  font-size: 2rem;
  width: 96%;
}
  }

  
@media (min-width: 1000px) {
    .main_title_section{
      left: 6rem;
    }

  }
  
@media (min-width:1200px){
  .firstLine {
    font-size: 1.8rem;
}
  .secondLine {
    font-size: 2.4rem;
}
  .text_bg {
    font-size: 2.3rem;
}
}
  @media (min-width: 1300px) {
    .main_title_section{
      left: 7rem;
      max-width: 700px;
    }
    .firstLine {
      font-size: 2.2rem;
  }
    .secondLine {
      font-size: 2.6rem;
  }
  .text_bg {
    font-size: 2.3rem;
    width: 80%;
  }
  }
  
  @media (min-width: 1400px) {

  
    .main_title_section{
      left: 8rem;
      max-width: 1000px;
    }
    .firstLine {
      font-size: 2.6rem;
  }
  .text_bg{
    width: 70%;
  }
  }