.metrics_container{
  height: 90vh;
  /* display: flex; */
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  overflow: hidden;
  display: none;
  gap: 8px;
}
.mobile_metrics_container{
  height: 100vh;
  display: flex;
  justify-content: space-evenly;
  position: relative;
  overflow: hidden;
  gap: 10px;
}

.heading{
  position: absolute;
  left: 50%;
  top: 0;
  /* max-width: 1000px; */
  transform: translateX(-50%);
  z-index: 3;
  width: 100%;
}

.mb_first_col, .mb_second_col{
  display: flex;
  flex-direction: column;
  gap: 30px;
  z-index: 4;
}
.mb_second_col{
  padding-top: 4rem;
}


@media (min-width: 901px){
  .metrics_container{
    display: flex;
  }
  .mobile_metrics_container{
    display: none;
  }
}

/* particle bg styles */
.particle_bg{
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  filter: blur(0.7px);
}

.particle_bg::before{
  content: "";
  position: absolute;
  height: 40%;
  width: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(to bottom, #000, transparent);
  z-index: 5;
}

.particle_bg::after{
  content: "";
  position: absolute;
  height: 40%;
  width: 100%;
  bottom: 0;
  left: 0;
  background: linear-gradient(to top, #000, transparent);
  z-index: 5;
}