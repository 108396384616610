.header {
  color: white;
  padding: 10px 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100%;
  transition: transform 0.3s ease, opacity 0.3s ease, background-color 0.3s ease;
}

.header.hideHeader {
  transform: translateY(-100%);
  opacity: 0;
}

.header.showHeader {
  transform: translateY(0);
  opacity: 1;
}

.header.scrolled {
  background: linear-gradient(90deg, #1D1D1D 0%, rgba(5, 5, 5, 0.33) 82.21%, rgba(0, 0, 0, 0) 100%);
  box-shadow: -8px 18px 13.6px 0px #00000040;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  width: 100%;
}

.logo {
  font-size: 24px;
  font-weight: bold;
}

.logo>img {
  width: 180px;
}

.nav {
  display: flex;
  align-items: center;
}

.nav-list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 2rem;
}

.nav-item {
  position: relative;
}

.nav-link {
  color: white;
  text-decoration: none;
  font-size: 16px;
}


.nav-link:hover {
  color: white;
  text-decoration: underline;
}

.nav-link:active {
  color: #FF7700;
}

.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 25px;
  height: 21px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.hamburger-line {
  width: 100%;
  height: 3px;
  background-color: white;
}

.action_btn_container {
  display: flex;
  align-items: center;
  gap: 14px;
}

.action_btn_container>button {
  box-shadow: none;
  border: none;
  padding: 0.5rem 1.2rem;
  border-radius: 0.3rem;
  color: #fff;
  font-weight: 600;
}

.action_btn_container>button:hover {
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.6);
}

.filled_gray {
  background: linear-gradient(270deg, rgba(74, 74, 74, 0.3) 0%, #B0B0B0 100%);
}

.filled_orange {
  background: linear-gradient(90deg, #FF7700 0%, #994700 100%);
}

.header_login_btn {
  color: #fff;
  text-decoration: none;

}

.gradient_line {
  height: 0;
  border-top: 2px solid;
  border-image-source: linear-gradient(90deg, #000000 3.71%, #FF7200 36.5%, #FF7200 69.5%, #000000 98%);
  border-image-slice: 1;
  margin: 1rem auto;
}


.header {
  position: fixed;
  width: 100%;
  z-index: 200;
  transition: transform 0.3s ease, opacity 0.3s ease, background-color 0.3s ease;
}

.header.menuOpen {
  background-color: black;
}

.nav.open {
  min-height: 100vh;
  max-height: 1000px;
  opacity: 1;
  transition: all 0.5s ease-in-out;
}

.gradient_line {
  display: none;
  width: 50vw;
  height: 2px;
  margin: 1rem auto;
  background: linear-gradient(90deg, #000000 0%, #FF7200 50%, #000000 100%);
  transition: width 1s ease-in-out;
}


.hamburger .cross {
  font-size: 24px;
  color: white;
  transition: transform 0.2s ease-in-out;
}

.hamburger-line {
  background-color: white;
  transition: all 0.3s ease;
}
#contact{
  display: none;
  box-shadow: none;
  border: none;
  padding: 0.5rem 1.2rem;
  border-radius: 0.3rem;
  color: #fff;
  font-weight: 600;
}
#login_btn{
  display: none;
  box-shadow: none;
  border: none;
  padding: 0.5rem 1.2rem;
  border-radius: 0.3rem;
  color: #fff;
  font-weight: 600;
}

@media (max-width: 980px) {

  .nav {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    background-color: #000000;
    position: absolute;
    top: 41px;
    left: 0;
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition: max-height 1s ease-in, opacity 0.2s ease-in;
    justify-content: flex-start;
    gap: 2rem;
  }

  .nav.open {
    min-height: 100vh;
    max-height: 1000px;
    opacity: 1;
    margin-top: 5px;
  }

  .nav-list {
    flex-direction: column;
  }

  .nav-item {
    margin: 10px 0;
  }


  .hamburger {
    display: flex;
  }

  .action_btn_container {
    display: none;
  }

  .logo>img {
    width: 100px;
  }
  .gradient_line{
    display: block;
  }
  #contact{
    display: block;
    /* margin-bottom: 10rem; */
    width: 50%;
    /* margin-top: 1px; */
  }
  #login_btn{
    display: block;
    /* margin-bottom: 10rem; */
    width: 50%;
    /* margin-top: 1px; */
  }
}

@media(max-width:450px){
  .nav {
    gap: 1.5rem;
  }
  #contact{
    width: 80%;
  }
  #login_btn{
    width: 80%;
  }
}

@media (min-width: 1300px) {
  .container {
    width: 85%;
    margin: 0 auto;
  }
}