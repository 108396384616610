.whatsNew {
    position: relative;
}

.container_bg {
    position: absolute;
    width: 100%;
    z-index: -1;
    /* height: -webkit-fill-available; */
    height: 100%;
}

.whatsNew>h1 {
    color: #ff7700;
    text-align: center;
    font-size: 4rem;
    font-family: 'Fjalla One';
    padding: 1rem;
}

.new_card_1{
    background: #141414;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    height: 500px !important;
    margin: 0px 0px 2rem 0px;
}
.new_img>img{
    height: 190px !important;
}
.new_data>h2{
    text-align: center;
    font-size: 1.2rem;
    color: #ff7700;
    margin-top: 0.7rem;
}
.new_data>p{
    color: #fff;
    font-size: 13.5px;
    text-align: center;
}
.new_data>a{
    color: #ff7700;
    text-align: center;
    display: grid;
}

.swiper_wrapper{
    max-width: 310px !important;
    width: 310px !important;
}

.customSlider_prevButton{
    position: absolute;
    top: 51%;
    z-index: 1;
    left: 1%;
    height: 29px;
    width: 29px;
    background: transparent;
    border: none;
}

.customSlider_nextButton{
    position: absolute;
    top: 51%;
    z-index: 1;
    right: 1%;
    height: 29px;
    width: 29px;
    background: transparent;
    border: none;

}

@media (max-width:1250px) {
    .whatsNew>h1 {
        font-size: 3rem;
    }
}


@media (max-width:850px) {
    .whatsNew>h1 {
        font-size: 2.5rem;
    }
    .new_data>h2 {
        font-size: 1rem;
    }
}
.customSlider_container {
    position: relative;
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
  }
  
  .customSlider_swiper {
    /* padding: 20px 0 20px 0; */
    /* padding-left: 20px; */
    width: 90%;
    margin: 0 auto;
  }
  
  .customSlider_swiper .swiper-slide {
    transform: none !important; /* Remove scaling effect */
    height: auto !important; /* Ensure consistent height */
  }
  
  .customSlider_swiper > .swiper-slide-active{
    height: auto !important;
  }
  
  .customSlider_container {
    position: relative;
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
    /* padding: 40px 0; */
    /* overflow-x: hidden; */
  }

  @media (min-width:1500px){
    .testimonial_card{  
      min-height: 435px; /* Ensure consistent height */
    }
  }
  @media (max-width:1300px){
    .testimonial_card{
      min-height: 410px;
    }
  }
  
  @media (max-width:700px){
    .testimonial_card{
      min-height: 350px;
    }
  }
  
  @media (max-width: 450px) {
  
    .testimonial_card{
      min-height: 420px;
    }
    /* .companydetails{
      height: 175px;
    }
     */
  }
  @media (max-width:380px){
    .testimonial_card{
      min-height: 440px;
    }
  

  }
  
  
  