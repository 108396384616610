.heading {
  position: absolute;
  left: 15%;
  z-index: 2;
  font-size: 50px;
  color: #ff7700;
  font-family: 'Fjalla One';
  top: 2%;
}

.heading_img {
  width: 50%;
  position: absolute;
  left: 62%;
  top: 90%;
}

.heading>span {
  font-family: 'Fjalla One';
  color: #fff;
}

.usecases {
  background-color: black;
  position: relative;
  width: 100%;
  /* height: 100vh; */
  overflow: hidden;
  /* Ensure the content doesn't overflow, but we'll make it work */
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.dtopImg {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.mobile_bg {
  display: none;
}

.fixedPoint,
.movablePoint {
  position: absolute;
  width: 30px;
  height: 30px;
  transform: translate(-50%, -50%);
}

.pointImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.line {
  position: absolute;
  pointer-events: none;
}

.content {
  position: absolute;
  bottom: 13%;
  left: 11%;
  color: white;
  max-width: 388px;
}

.contentBox {
  background: linear-gradient(270deg, #0B0A0A 0%, #1F1F1F 100%);
  padding: 1rem 0rem 1rem 1rem;
}

.contentBoxHead {
  color: #ff7700;
  font-size: 17px;

}

.contentBoxPara {
  font-size: 13px;
}

.tooltip {
  position: absolute;
  top: -30px;
  left: -15px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px;
  font-size: 12px;
  border-radius: 4px;
  white-space: nowrap;
  z-index: 10;
  transform: translateX(-50%);
}

@media (max-width:850px) {
  .usecases {
    height: 550px;
  }

  .dtopImg {
    height: 100%;
    object-fit: fill;
  }
  .heading{
    left: 4%;
    font-size: 45px;
    top: 2%;
}
  .content {
    max-width: 300px;
  }

  .contentBox {
    background: linear-gradient(270deg, #0B0A0A 0%, #1F1F1F 100%);
    padding: 0.5rem 0rem 0.1rem 0.5rem;
  }

  .contentBoxHead {
    color: #ff7700;
    font-size: 16px;
  }

  .contentBoxPara {
    font-size: 12px;
  }

}

@media (max-width:550px) {
  .dtopImg {
    display: none;
  }

  .mobile_bg {
    display: block;
  }

  .usecases {
    height: 600px;
  }

  .mobile_bg {
    height: 100%;
    object-fit: fill;
  }

  .fixedPoint,
  .movablePoint {
    width: 22px;
    height: 23px;
  }
  .content{
    bottom: 3%;
    left: 3%;
}
.heading {
  left: 30%;
  top: 4%;
}
}

@media (max-width:430px) {
  .container {
    transform: scale((1.5));
  }
}