.large_screens{
  display: none;
}

.slider_container{
  margin: 4rem auto 3rem auto;
  max-width: 1200px;
}

.slider{
  position: relative;
  display: none;
  flex-direction: column;
}

.slider_active{
  display: flex;
}

.image_container{
  min-height: 600px;
  max-width: 500px;
  min-width: 220px;
  width: 100%;
  position: relative;
  background: gray;
  margin-right: 15px;
}

.image_container img{
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: relative;
  z-index: 1;
}

.image_container::before{
  content: "";
  height: calc(100% + 30px);
  width: 60%;
  background: #FF7700;
  position: absolute;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 0;
}

.testimonial{
  width: 100%;
  position: relative;
  z-index: 2;
}

.quote_section {
  width: 51vw;
  min-height: 300px;
  max-height: 1000px;
  max-width: 716px;
  position: absolute;
  top: 50%;
  right: -4.3rem;
  transform: translateY(-50%);
  border-radius: 10px;
}

.quote_section .base-layer {
  position: absolute;
  top: 50%;
  left: -2rem;
  transform: translateY(-50%);
  width: 80%;
  height: 80%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1; /* Base layer */
  border-radius: 7px;
}

.quote_section .middle-layer {
  position: absolute;
  top: 50%;
  left: -1rem;
  transform: translateY(-50%);
  width: 90%;
  height: 90%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2; /* Second layer */
  border-radius: 7px;
}

.quote_section .main-content {
  width: 100%;
  min-height: 300px;
  max-height: 500px;
  /* max-width: 800px; */
  border: 1px solid #FF7700;
  z-index: 3; /* Top layer */
  padding: 1rem 2rem; /* Add padding for content */
  background: #fff;
  border-radius: 10px;
  position: relative;
}

.quote_section .main-content .quote{
  display: flex;
  max-height: 50%;
  padding: 1rem 0rem;
}

.quote_section .main-content .title{
  color: #FF7700;
  font-weight: 600;
  text-wrap: nowrap;
  margin: 0px !important;
  margin-bottom: 5px;
  font-size: 1.2rem;
}

.quote_start, .quote_end{
  font-family: "Fjalla One", sans-serif;
  color: #FF7700;
  -webkit-text-fill-color: white; /* Will override color (regardless of order) */
  -webkit-text-stroke: 3px #FF7700;
}

.quote_start{
  /* font-size: 200px; */
  height: max-content;
  align-self: flex-start;
}

.quote_end{
  /* font-size: 100px; */
  height: max-content;
  align-self: flex-end;
}

.quote_text{
  /* font-size: 0.9rem; */
  font-size: 1.5vw;
  margin: 0 1.5rem;
}

.metrics_box_container{
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  /* max-width: 350px; */
}

.metric_count_box{
  position: relative;
  padding-left: 0.4rem;
  font-size: 2rem;
  font-weight: 700;
  color: #FF7700;
}

.metric_count_box::after{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 1rem;
  border: 1px solid #FF7700;
}

.metric_text{
  font-size: 13px;
}

.controls_container{
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 2rem;
  bottom: -3rem;
  left: 0;
}

.btn_controls_container{
  display: flex;
  align-items: center;
  gap: 1.2rem;
}

.btn_controls_container > button{
  box-shadow: none;
  border: none;
  background: transparent;
  height: 30px;
  width: 30px;
}

.btn_controls_container > button > img{
  height: 30px;
  width: 30px;
}

.slider_thumb_container{
  display: flex;
  align-items: center;
  padding-right: 3rem;
}

.slider_thumb_container > button{
  box-shadow: none;
  border: none;
  background: transparent;
  font-size: 1.6rem;
}

/* ----- RESPONSIVE DESIGNS ----- */
.mobile_slider_container{
  display: flex;
  flex-direction: column;
  margin: 2rem 0px;
}

.mb_quote_section{
  border-radius: 10px;
  background: linear-gradient(white, white) padding-box,
              linear-gradient(to bottom, transparent, transparent, #FF7700) border-box ;
  border: 1px solid transparent;
  position: relative;
}

.mb_base_layer{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -1rem;
  height: 80%;
  width: 80%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1; /* Base layer */
  border-radius: 7px;
}

.mb_middle_layer{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -0.5rem;
  height: 90%;
  width: 90%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2; /* Middle layer */
  border-radius: 7px;
}

.mb_main_content{
  width: 100%;
  z-index: 3; /* Top layer */
  background: #fff;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.mb_text_content{
  width: 50%;
  padding: 1rem 0.5rem 0px 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mb_text_content > .text_container{
  display: flex;
}

.mb_text_content > .text_container > .quote_main{
  margin-left: 5px;
  font-size: 3vw;
}

.mb_text_content .name_title{
  font-size: 14px;
  color: #FF7700;
  white-space: nowrap;
  margin-bottom: 0px !important;
}

.mb_img_content{
  width: 50%;
  min-height: 155px;
  /* height: 100%; */
  height: auto;
  position: relative;
  display: flex;
  align-items: center;
  /* max-height: 500px; */
}

.mb_img_content::before{
  content: "";
  position: absolute;
  height: 100%;
  width: 80%;
  top: 0;
  right: 0;
  z-index: 0;
  background: #FF7700;
}

.mb_img_content__container{
  width: 95%;
  min-height: 155px;
  height: 95%;
  position: relative;
  z-index: 1;
}

.mb_img_content__container img{
  min-height: 155px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mb_btn_container{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 2rem;
}

.mb_btn_container > button{
  box-shadow: none;
  border: none;
  background: transparent;
  height: 30px;
  width: 30px;
}

.mb_btn_container > button > img{
  height: 30px;
  width: 30px;
}

@media (min-width: 801px){
  .large_screens{
    display: block;
  }
  .small_screens{
    display: none;
  }
  .metrics_box_container{
    display: flex;
    align-items: center;
    gap: 1rem;
    width: auto;
    /* max-width: 350px; */
  }
  .metric_count_box{
    position: relative;
    padding-left: 0.7rem;
    font-size: 4rem;
    font-weight: 700;
    color: #FF7700;
  }
  
  .metric_count_box::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 2rem;
    border: 1px solid #FF7700;
  }
  
  .metric_text{
    font-size: 1rem;
  }
}

@media (min-width: 800px) {
  .slider{
    flex-direction: row-reverse;
  }
}

@media (min-width: 1000px) {
  /* .quote_text{
    font-size: 1.1rem;
  } */

  .metric_text{
    font-size: 1.2rem;
  }
}

/* @media (min-width: 1200px) {
  .quote_text{
    font-size: 1.3rem;
  }
} */

@media (min-width: 1400px) {
  .quote_text{
    font-size: 1.2rem;
  }
  .metric_text{
    font-size: 1.2rem;
  }
  .image_container{
    max-width: 550px;
    height: 40vw;
  }
}