/* --- animated border --- */
.active_animation {
  /* background-image: linear-gradient(90deg, #ff7700 50%, transparent 50%), linear-gradient(90deg, #ff7700 50%, transparent 50%), linear-gradient(0deg, #ff7700 50%, transparent 50%), linear-gradient(0deg, #ff7700 50%, transparent 50%); */
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 15px 2px, 15px 2px, 2px 15px, 2px 15px;
  background-position: left top, right bottom, left bottom, right top;
  animation: border-dance 1s infinite linear;
  position: relative;
}

.active_animation::before{
  content: "";
  position: absolute;
  height: 100%;
  width: 50%;
  top: 0;
  left: 0;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 15px 2px, 15px 2px, 2px 15px, 2px 15px;
  background-position: left top, right bottom, left bottom, right top;
  animation: border-dance 1s infinite linear;
  background-image: var(--leftBgImage);
  transform: var(--leftTransform);
}

.active_animation::after{
  content: "";
  position: absolute;
  height: 100%;
  width: 50%;
  top: 0;
  right: 0;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 15px 2px, 15px 2px, 2px 15px, 2px 15px;
  background-position: left top, right bottom, left bottom, right top;
  animation: border-dance 1s infinite linear;
  background-image: var(--rightBgImage);
}
  
@keyframes border-dance {
  0% {
    /* background-position: left top, right bottom, left bottom, right top; */
    background-position: var(--bgPositionFromAnimation);
  }
  
  100% {
    background-position: var(--bgPositionToAnimation);
    /* background-position: left 15px top, right 15px bottom, left bottom 15px, right top 15px; */
  }
}

.round_obj_container{
  /* width: 100%; */
  width: 60vw;
  position: relative;
  height: calc(60vw / 2);
  max-width: 900px;
}


.grad_border {
  position: absolute;
  top: 7%;
  left: 0;
  /* background: 
    linear-gradient(#000 0 0) padding-box,
    linear-gradient(to right, #ff7700, transparent, transparent, #ff7700) border-box; */
  /* background: linear-gradient(45deg, #ff7700, transparent, transparent, #ff7700); */
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  isolation: isolate; /* Ensure transparency */
  color: #313149;
  padding: 1px;
  border: 2px solid transparent;
  border-radius: 50%;
  width: 100%;
  height: 53%;
  z-index: 3;
  /* animation: border-gradient-animate 4s infinite linear; */
}

@keyframes border-gradient-animate {
  0% {
    background: 
    linear-gradient(#000 0 0) padding-box, /*this is your grey background*/
    linear-gradient(to right, #ff7700, transparent, transparent, #ff7700) border-box;
  }
  
  25% {
    background: 
    linear-gradient(#000 0 0) padding-box, /*this is your grey background*/
    linear-gradient(140deg, #ff7700, transparent, transparent, #ff7700) border-box;
  }

  50% {
    background: 
    linear-gradient(#000 0 0) padding-box, /*this is your grey background*/
    linear-gradient(to bottom, #ff7700, transparent, transparent, #ff7700) border-box; 
  }

  75% {
    background: 
    linear-gradient(#000 0 0) padding-box, /*this is your grey background*/
    linear-gradient(210deg, #ff7700, transparent, transparent, #ff7700) border-box; 
  }
  
  100% {
    background: 
    linear-gradient(#000 0 0) padding-box, /*this is your grey background*/
    linear-gradient(to left, #ff7700, transparent, transparent, #ff7700) border-box;
  }
}

.filled_border{
  position: absolute;
  bottom: 9%;
  left: 0;
  padding: 4px;
  border: 2px solid #ff7700;
  border-radius: 50%;
  width: 100%;
  height: 71%;
  z-index: 2;
  background-color: #000;
}

.node_container{
  position: absolute;
  bottom: 9%;
  left: 0;
  padding: 4px;
  /* border: 2px solid #ff7700; */
  border-radius: 50%;
  width: 100%;
  height: 71%;
  z-index: 2;
  /* background-color: #000; */
}

/* .filled_border::before{
  content: "";
  width: 100%;
  position: absolute;
  bottom: -6px;
  height: 100%;
  border-radius: 50%;
  border: 2px solid #ff7700;
  background-color: #ff7700a9;
  z-index: -1;
} */

.back_circle{
  position: absolute;
  bottom: 5%;
  left: 0;
  padding: 4px;
  border: 2px solid #ff7700;
  background-color: #ff7700b2;
  border-radius: 50%;
  width: 100%;
  height: 71%;
}

.text_node{
  position: absolute;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  padding: 0px 5px;
}

.node_connector{
  width: 12vw;
  height: 1px;
  border: 2px dashed #ff7700;
  position: absolute;
}

.text_node.warehouse{
  top: 20%;
  left: 14%;
}
.node_connector.warehouse{
  top: 3.5vw;
  right: -12vw;
  transform: rotate(23deg);
}

.text_node.agent{
  top: 20%;
  right: 14%;
}
.node_connector.agent{
  top: 3.5vw;
  left: -12vw;
  transform: rotate(-22deg);
}

.text_node.customer{
  bottom: 24%;
  right: 13%;
}
.node_connector.customer{
  top: -2.5vw;
  left: -12vw;
  transform: rotate(21deg);
}

.text_node.transporter{
  bottom: 24%;
  left: 13%;
}
.node_connector.transporter{
  top: -2.5vw;
  right: -12vw;
  transform: rotate(-25deg);
}

.cyl_container{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 35%;
  height: 30%;
  z-index: 4;
}

.cloud_box{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  width: 65%;
  z-index: 5;
  opacity: 0;
  transition: all 2s ease;
}

.cloud_box.slide_up{
  top: -64%; /* Final position */
  opacity: 1; /* Fully visible */
}

.cloud_box > img{
  height: 100%;
  width: 100%;
}

.cylinder{
  position: relative;
  height: 100%;
}

.cylinder .middle{
  width: 100%;
  height: 63%;
  background-color:#000;
  border: 2px dashed #ff7700;
  border-bottom: none;
  position: absolute;
}

.cylinder .top{
  width: 100%;
  height: 81%;
  border: 2px dashed #ff7700;
  background-color:#000;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  position:absolute;
  top:-34%;
}

.cylinder .bottom{
  width: 100%;
  height: 70%;
  background-color:#000;
  border: 2px dashed #ff7700;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  position:absolute;
  top: 26%;
  box-shadow:0px 0px 10px rgba(0,0,0,0.75)
}

.middle_text{
  font-family: "Fjalla One", sans-serif;
  font-size: 3vw;
  font-weight: 600;
  color: #ff7700;
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 4;
}

@media (min-width: 900px) {
  .round_obj_container{
    height: calc(900px / 2);
  }
}

@media (max-width:900px) {
  .round_obj_container {
    /* width: 100%; */
    width: 90vw;
    position: relative;
    height: calc(101vw / 2);
    max-width: 900px;
}
}
@media (max-width:800px) {
  .round_obj_container {
    height: calc(114vw / 2);
}
}
@media (max-width:550px) {
  .round_obj_container {
    height: calc(140vw / 2);
}
}

@media (max-width:500px) {
  
.text_node.warehouse{
  top: 20%;
  left: 8%;
}

.text_node.agent{
  top: 20%;
  right: 8%;
}


.text_node.customer{
  bottom: 21%;
  right: 7%;
}

.text_node.transporter{
  bottom: 21%;
  left: 7%;
}
}



@media (max-width:500px) {
  
  .text_node.warehouse{
    top: 20%;
    left: 2%;
  }
}
@media (min-width:1600px){
  .middle_text{
    top: 41%;
   
}
}