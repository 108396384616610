.text_area_container{
  display: flex;
  flex-direction: column;
  /* gap: 1rem; */
  padding-right: 1.5rem;
}

.body_text{
  font-size: 1rem;
}
.card {
  display: flex;
  flex-direction: column;
  /* width: 250px; */
  width: 100%;
  padding: 20px;
  /* margin: 10px; */
  border-radius: 10px;
  box-shadow: 
    0 4px 12px rgba(0, 0, 0, 0.15), /* Outer shadow */
    inset 0px 0px 18px 40px rgba(0,0,0,0.02); /* Inner shadow */
  background-color: #fff;
  transition: transform 0.2s;
  /* max-width: 300px; */
}

.card:hover {
  transform: translateY(-5px);
}

.card-icon {
  margin-bottom: 15px;
}

.card-icon img {
  width: 90px;
  height: 90px;
}

/* .card-content {
  text-align: center; 
} */

.card-title {
  font-size: 1.6em;
  margin-bottom: 10px;
  font-family: "Fjalla One", sans-serif !important;
}

.card-description {
  font-size: 1em;
  color: #666;
  margin-bottom: 15px;
}

.card-link {
  font-size: 0.9em;
  color: orange;
  text-decoration: none;
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.card-link .arrow {
  margin-left: 5px;
  transition: transform 0.2s;
}

.card-link:hover .arrow {
  transform: translateX(5px);
}

.tiles_container--outer{
  max-height: 550px;
  /* overflow-y: hidden; */
  overflow-y: auto;
  display: flex;
  gap: 10px;
  padding: 10px;
}

.tiles-container--inner{
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.uneven-column{
  margin-top: 80px;
}

.metrics_container{
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* flex-wrap: wrap; */
  gap: 2rem;
  padding: 1rem 7vw;
  /* background: #FF7700; */
  position: absolute;
  top: -76px;
  left: 0;
  right: 0;
}

.metrics_box{
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  /* min-width: 200px; */
  height: 100%;
  width: 100%;
  border-radius: 0.5rem;
  background: #ff7700;
  /* box-shadow: 0px 5px 7px 0px rgba(0,0,0,0.8); */
  box-shadow: 4px 4px 9px 0px rgba(0,0,0,0.4);
}

.metrics_title{
  font-size: 50px;
  font-weight: 700;
  color: #FFF;
  margin-bottom: 3px !important;
}

.metrics_body{
  font-size: 1rem;
  font-weight: 600;
  color: #FFF;
  text-align: center;
}

.ticker_section1{
  margin-top: 4rem;
  background: #fff;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

/* .logos1 {
  overflow: hidden;
  padding: 20px 0;

  white-space: nowrap;
  position: relative;
}

.logos1:before,
.logos1:after {
  position: absolute;
  top: 0;
  width: 250px;
  height: 100%;
  content: "";
  z-index: 2;
}

.logos1:hover .logos-slide1 {
  animation-play-state: paused;
}

.logos-slide1 {
  display: inline-block;
  animation: 40s slide infinite linear;
}

.logos-slide1 img {
  height: 60px;
  margin: 0 40px;

} */



.ticker_wrapper {
  width: 100%;
  overflow: hidden;

  padding: 10px;
  position: relative;
  margin-top: 4rem;
}

.ticker {
  display: flex;
  width: max-content;
 
  animation: ticker-animation 180s linear infinite;
  gap: 3.5rem;
  animation-play-state: running;
}

.ticker-item {
  flex-shrink: 0;
  margin-right: 20px; /* Adjust margin */
}
.ticker_wrapper:hover .ticker{
  animation-play-state: paused;
}

@keyframes ticker-animation {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%); /* Move left by 50% to loop without gaps */
  }
}

.ticker img {
  width: auto; /* Adjust as needed */
  height: 60px; /* Maintain consistent height */
  display: block;
}









.main_outer_container{
  padding: 5rem 8rem;
}

@media (min-width: 801px) {
  .metrics_container{
    flex-wrap: nowrap;
  }

}

@media (max-width: 600px){
  .main_outer_container{
    padding: 3rem 2rem !important;
  }
}

@media (max-width: 960px){
  .tiles-container--inner{
    flex-direction: row;
    gap: 10px;
  }
  .uneven-column{
    margin-top: 0px !important;
  }
  .tiles_container--outer{
    max-height: 550px;
    overflow-x: auto;
    gap: 10px;
    margin-top: 20px;
  }
  .tiles-container--inner{
    width: auto;
  }
  .card {
    min-width: 250px;
  }
  
  .card-icon img {
    width: 55px;
    height: 55px;
  }

  .card-title {
    font-size: 1.2em;
  }
  
  .card-description {
    font-size: 0.8em;
  }
}
@media (max-width:1330px){
  .metrics_body{
    font-size: 0.9rem;
  }
}

@media (max-width: 1200px){
  .uneven-column{
    margin-top: 32px;
  }
  .metrics_body{
    font-size: 0.85rem;
  }
}

@media (max-width: 1150px){
  .main_outer_container{
    padding: 3rem 4rem;
  }
  .metrics_body{
    font-size: 0.8rem;
  }
}

@media (max-width: 1100px) {
  .metrics_container{
    top: -70px;
  }
  
  .metrics_title{
    font-size: 40px;
  }

  .metrics_body{
    font-size: 0.7rem;
  }
}

@media (max-width: 1000px) {
  /* .metrics_container{
    padding: 1rem 3vw;
    gap: 1rem;
  } */
  
  .metrics_title{
    font-size: 35px;
  }

  .metrics_body{
    font-size: 0.7rem;
  }
}
@media (max-width:950px) {
  .metrics_box{
    min-height: 105px;
  }
}

@media (max-width: 900px) {
  .metrics_container{
    padding: 1rem 3vw;
    gap: 1rem;
  }
}

@media (max-width: 800px) {
  .metrics_container{
    padding: 1rem 2vw;
    gap: 0.5rem;
  }

  .metrics_title{
    font-size: 30px;
  }
  
  /* .ticker_wrapper{
    margin-top: 2rem;
  } */
  .metrics_box{
    min-height: 101px;
  }
}

@media (max-width: 500px) {
  .metrics_title{
    font-size: 20px;
  }
}

@media screen and (min-width: 1500px) {
  .body_text{
    font-size: 1.5rem;
  }
}