.testimonials_outer_section{
    min-height: 80vh;
    width: 100%;
    padding: 3rem 1rem 1rem 1rem;
}
.testimonial_slider {
    max-width: 95%;
}

.testimonials_container{
    padding: 1rem;
}

@media (min-width: 1000px){
    .testimonials_outer_section{
        padding: 2rem 1rem 1rem 1rem;
    }
    .testimonials_container{
        padding: 3rem;
    }
}