.lane_container {
  /* height: 240vh; */
  position: relative;
}

.top_section {
  height: 90vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* Space between text and video */
  box-sizing: border-box;
}

.text_box {
  max-width: 1000px;
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  position: absolute;
  top: 19%;
  z-index: 4;
  background: linear-gradient(to bottom, transparent, #000000b0, transparent);
}

.mb_bg {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 3;
}

.video_bg {
  flex: 1;
  /* Allow this section to grow and fill remaining space */
  width: 100%;
  position: relative;
  /* Enables child positioning */
  overflow: hidden;
  /* Ensures content stays within bounds */
}

.video_bg>video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  /* Ensures the video covers the container without overflow */
  z-index: 1;
}

.img_mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 3;
}

.plans_section {
  width: 100%;
  height: 120vh;
  position: absolute;
  bottom: 4rem;
  left: 0;
  z-index: 4;
  display: flex;
  flex-direction: column;
}

.cards_section {
  flex: 1 1;
  position: relative;
  width: 95%;
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
  margin: 0 auto;
  gap: 1rem;
  overflow-x: auto;
}

.features_desktop{
  display: block;
}

.cards_wrapper{
  flex: 1 1;
  position: relative;
  width: 95%;
  display: none;
  justify-content: space-between;
  padding-top: 1rem;
  margin: 0 auto;
  gap: 1rem;
  overflow-x: auto;
}

.cards_wrapper>.card {
  height: 70vh;
  min-width: 230px;
  max-width: 370px;
  width: 28vw;
  background: linear-gradient(to bottom, #000, #1a1a1aba, #1a1a1a, #1a1a1a);
  border: 0.7px solid #ff7700;
  position: relative;
  border-radius: 21px;
  overflow: visible;
  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.7rem;
  color: #ffffff;
}

.cards_wrapper>.card::after {
  content: "";
  position: absolute;
  top: -0.7px;
  /* Extend beyond border */
  left: -0.7px;
  /* Extend beyond border */
  width: calc(100% + 1.6px);
  /* Add border width to both sides */
  height: calc(100% + 1.6px);
  /* Add border width to both sides */
  background: linear-gradient(to top, #000 1%, transparent, transparent, transparent, transparent, transparent);
  border-radius: inherit;
  /* Match the parent's border radius */
  z-index: 1;
  /* Ensure it stays on top */
}

.card:hover {
  /* background: linear-gradient(to bottom, #ff7700, #FE9A49B6, transparent); */
  background: linear-gradient(to bottom, #FF7700 67%, #994700 100%);
  border-color: #fff;
}

/* .card:nth-child(odd){
  align-self: flex-end;
} */

.card_title {
  text-align: center;
}

.card_divider {
  width: 80%;
  height: 1.5px;
  background: linear-gradient(to right, transparent, #ff7700, transparent);
}

.card_button {
  margin: 0 auto;
  padding: 10px 15px;
  box-shadow: none;
  border: none;
  width: 100%;
  color: #fff;
  background: #ff7700;
  border-radius: 12px;
  font-weight: 600;
}

.list_container {
  height: 100%;
  width: fit-content;
  display: flex;
  flex-direction: column;
  max-width: 300px;
  margin: 0 auto;
  z-index: 2;
}

.list_container>.list_item {
  display: flex;
  align-items: center;
}

.check_icon {
  width: 30px;
}

.list_container>.list_item>span {
  width: 100%;
  font-size: 0.8rem;
}

.card:hover .card_button {
  background: #fff;
  color: #ff7700;
}

.card:hover .card_divider {
  background: linear-gradient(to right, transparent, #ffffff, transparent);
}

.card:hover .check_icon {
  filter: brightness(0) invert(1);
}

@media (max-width:1000px) {
  .cards_wrapper>.card {
    min-width: 270px;
  }

  .cards_wrapper {
    overflow-x: auto;
    margin-bottom: 25px;
  }

}

@media (max-width:850px) {
  .plans_section {
    bottom: 7rem;
  }

  .top_section {
    height: 105vh;
  }
}

@media (max-width:700px) {
  .video_bg>video {
    display: block !important;
  }

  .plans_section {
    bottom: 10rem;
  }

  .top_section {
    height: 70vh;
  }
  
.text_box {
  top: 12%;
}
}

@media (max-width: 750px) {
  .cards_wrapper{
    display: none;
  }
  /* .features_desktop{
    display: none;
  } */
}

@media (max-width:550px) {
  .video_bg>video {
    height: 482px;
  }
  .text_box {
    top: 10%;
  }
  .mb_bg {
    display: block;
    height: 85%;
  }

  .img_mask {
    display: none;
  }

  .cards_wrapper>.card {
    min-width: 300px;
  }
}

@media (max-width:440px) {
  .video_bg>video {
    height: 350px;
  }

  .mb_bg {
    height: 60%;
  }

  .plans_section {
    bottom: 17rem;
  }

  .container {
    margin: -8rem 0rem 2rem 0rem
  }
  .text_box {
    top: 7%;
  }
}

@media (max-width:410px) {
  .container {
    margin: -6rem 0rem 2rem 0rem
  }
  .text_box {
    top: 4%;
  }
}

@media (max-width:361px) {
  .container {
    margin: -4rem 0rem 2rem 0rem
  }
}

@media(max-width:365px) {
  .cards_wrapper>.card {
    min-width: 285px;
  }
}



/* SHIPPER SECTION CSS */
.container {
  display: flex;
  justify-content: center;
  color: #FFFFFF;
  margin-bottom: 4rem;
}

.flexContainer {
  display: flex;
  justify-content: center;
  gap: 5rem;
}

.straightLine {
  border: 1px solid transparent;
  border-image: linear-gradient(180deg, #000000 0%, #FF7200 25.5%, #FF7200 25.5%, #000000 100%);
  border-image-slice: 5;
  height: 572px;
  margin-top: -4rem;
  display: block;
}

.horizontalLine {
  display: none;
  width: 100%;
  /* or a specific width */
  height: 1.5px;
  background: linear-gradient(90deg, #000000 0%, #FF7200 25.5%, #FF7200 25.5%, #000000 100%);
}

.flexContainerLeftPart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.flexContainerRighttPart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.leftContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.6rem;
}

.leftContentHead {
  font-size: 36px;
}

.leftContentpara {
  font-size: 17px;
  max-width: 319px;
  text-align: center;
}

.action_btn_container>button {
  box-shadow: none;
  border: none;
  padding: 0.5rem 1.2rem;
  border-radius: 0.3rem;
  color: #fff;
  font-weight: 600;
}

.action_btn_container>button:hover {
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.6);
}

.filled_orange {
  background: linear-gradient(90deg, #FF7700 0%, #994700 100%);
}

.border-container {
  position: relative;
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.outer-border {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px solid transparent;
  border-image: linear-gradient(113deg, #331800 0%, #FF7700 50%, #000000 100%);
  border-image-slice: 5;
  box-sizing: border-box;
  border-image-repeat: stretch;
}

.inner-border {
  position: absolute;
  width: 70%;
  height: 70%;
  border: 1px solid #8D8D8D;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner-border>img {
  height: 150px;
}

.connecting_lines {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

/* Top-left to bottom-right connecting line */
.connecting_lines::before {
  content: '';
  position: absolute;
  width: 1px;
  height: 22%;
  background-color: #8D8D8D;
  top: 0;
  left: 0;
  transform: rotate(-45deg);
  transform-origin: top left;
  z-index: 1;
}

/* Top-right to bottom-left connecting line */
.connecting_lines::after {
  content: '';
  position: absolute;
  width: 1px;
  height: 22%;
  background-color: #8D8D8D;
  top: 0;
  right: 0;
  transform: rotate(45deg);
  transform-origin: top right;
  z-index: 1;
}

/* Bottom-left to top-right connecting line */
.connecting_lines .bottom_left_to_top_right {
  content: '';
  position: absolute;
  width: 1px;
  height: 22%;
  background-color: #8D8D8D;
  bottom: 0;
  left: 0;
  transform: rotate(45deg);
  transform-origin: bottom left;
  z-index: 1;
}

/* Bottom-right to top-left connecting line */
.connecting_lines .bottom_right_to_top_left {
  content: '';
  position: absolute;
  width: 1px;
  height: 22%;
  background-color: #8D8D8D;
  bottom: 0;
  right: 0;
  transform: rotate(-45deg);
  transform-origin: bottom right;
  z-index: 1;
}

.corner {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #FF7700;
  z-index: 2;
}

/* Positioning the squares at each corner */
.top-left {
  top: -5px;
  left: -5px;
}

.top-right {
  top: -5px;
  right: -5px;
}

.bottom-left {
  bottom: -5px;
  left: -5px;
}

.bottom-right {
  bottom: -5px;
  right: -5px;
}

@media (max-width:850px) {
  .flexContainer {
    gap: 2rem
  }

  .leftContentHead {
    font-size: 32px;
  }

  .leftContentpara {
    font-size: 15px;

  }
}

@media (max-width:750px) {
  .straightLine {
    display: none;
  }

  .horizontalLine {
    display: block;
  }

  .flexContainer {
    flex-direction: column;
  }
}

@media (max-width:450px) {
  .leftContentHead {
    font-size: 30px;
  }

  .leftContentpara {
    font-size: 14px;

  }
}





/* Modal Styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.modalContent {
  background: #181818;
  /* padding-top: 2rem; */
  border-radius: 10px;
  max-width: 90%;
  /* width: 100%; */
  position: relative;
  overflow-y: hidden;
  height: 90vh;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  /* margin-bottom: 2rem; */
}

.modal_title{
  font-size: clamp(18px, 3vw, 34px);
  color: #ff7700;
  margin: 0 auto;
}

.closeModalBtn {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  color: #ff7700;
  margin: 2px 1rem 5px 0px;
}

.card {
  margin-bottom: 2rem;
}

.card_title {
  font-size: 1.6rem;
  font-weight: 600;
}

.card_button {
  background-color: #ff7a00;
  border: none;
  color: white;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 0.9rem;
}

.list_item {
  display: flex;
  align-items: center;
  margin-bottom: 0.4rem;
}

.check_icon {
  margin-right: 10px;
}

.modalSecond {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.modalContentSecond {
  background: #303030;
  border-radius: 10px;
  max-width: 90%;
  width: 65%;
  position: relative;
  height: 75vh;
  padding: 2rem;
  padding-top: 1rem;
  overflow-y: auto;
}

.modalSecondHead {
  color: #FF7700;
  font-size: clamp(18px, 3vw, 34px);
  margin: 0 auto;
}

.horizontalLine1 {
  width: 99%;
  /* or a specific width */
  height: 1.5px;
  background: linear-gradient(90deg, #FF7200 0%, #FF7200 25.5%, #000000 100%);
}

.mode_btn {
  /* display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  box-shadow: none;
  border: none;
  padding: 0.5rem 1.2rem;
  border-radius: 0.3rem;
  color: #fff;
  font-weight: 600; */

  width: 100%;
  background: #464646;
  display: flex;
  justify-content: center;
  align-self: center;
  cursor: pointer;
  color: #fff;
  padding: 10px;
  transition: all 0.3s ease-in-out;
  border: none;
}

.mode_btn:hover{
  background: #626161;
  transition: all 0.3s ease-in-out;
}

.modalBody {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  margin-top: 2rem;
}

.radioOption {
  display: flex;
  gap: 2rem;
  margin-top: 1rem;
  /* font-size: 1.3rem; */
  padding-left: 5px;
}

.allRadioOption {
  display: flex;
  gap: 5px;
  cursor: pointer;

}

input[type="radio"] {
  /* transform: scale(1.5); */
  cursor: pointer;

}

input[type="radio"]:checked {
  background-color: #FF7700;
}
#modalSecondHeadID{
  margin-top: 3rem;
  font-size: 17px;
  /*color: white; */
}

@media (max-width:1000px) {
  .modalSecondHead {
    font-size: 24px;
  }

  .modalBody {
    display: flex;
    flex-direction: column;
    max-width: 48%;
    gap: 1rem;
    margin-top: 10px;
  }
  #modalSecondHeadID{
    font-size: 17px;
    margin-top: 1rem;
  }
}
@media (max-width:600px) {
  .modalContentSecond {
    height: 85vh;
    width: 90%;
    padding: 1rem;
  }
  .modalBody {
    max-width: 68%;
  }
}




.mode_btn>img {
  margin-right: 8px;
  height: 30px;
  width: 30px;
}

/* Style for selected mode button */
.selectedMode {
  background: linear-gradient(90deg, #FF7700 0%, #994700 100%);
  color: white;
  transition: all 0.3s ease-in-out;
}
.selectedMode:hover{
  background: linear-gradient(90deg, #f39542 0%, #d46809 100%);
  transition: all 0.3s ease-in-out;
}

/* Style for unselected mode button */
/* .unselectedMode {
background-color: #2D2D2D !important;
  color: white;
  border: 1px solid gray;
} */



.getPriceBtn{
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #FF7700 0%, #994700 100%);
  gap: 0.4rem;
  box-shadow: none;
  border: none;
  padding: 0.5rem 1.2rem;
  /* border-radius: 0.3rem; */
  color: #fff;
  font-weight: 600;
}

.getPriceBtn:hover{
  background: linear-gradient(90deg, #f39542 0%, #d46809 100%);
}

/* ------------ */
.table_container{
  width: 100%;
  height: 75vh;
  overflow: auto;
  margin-bottom: 1rem;
  border: double 1px transparent;
  border-radius: 10px;
  background-image: linear-gradient(#000, #000), 
                    linear-gradient(to bottom, #ff7700, #000);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
.table {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: radial-gradient(circle, rgba(37,37,37,1) 0%, rgba(37,37,37,1) 33%, rgba(4,4,4,1)
  100%);
  color: white;
  border-collapse: collapse;
}

.headerRow,
.featureRow {
  display: flex;
}

.headerRow{
  position: sticky;
  top: 0;
  background: linear-gradient(to bottom, #000, #000000a9, transparent);
}

.cell {
  flex: 1;
  padding: 10px;
  text-align: center;
  border: 1px solid #333;
  border-right: none;
  border-left: none;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cell_btn{
  background: transparent;
  border: 1px solid #ff7700;
  border-radius: 5px;
  padding: 5px 15px;
  color: #fff;
  position: relative;
}
.highlight_btn{
  background: linear-gradient(to bottom right, #ff7700, #b95803);
}
.cell_btn > .tooltip{
  position: absolute;
  width: 100px;
  padding: 5px;
  background: #3e3e3e;
  color: #aca8a8;;
  border-radius: 5px;
  bottom: -38px;
  left: 50%;
  transform: translateX(-50%);
  visibility: hidden;
  z-index: 4;
  font-size: 13px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.cell_btn:hover .tooltip{
  visibility: visible;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

.button {
  cursor: pointer;
  background: none;
  color: inherit;
  border: none;
  font-size: inherit;
}

.highlight {
  background-color: rgba(116, 116, 116, 0.121);
}

/* contact form */
.contact_form_row{
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.formField{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px;
}

.formField > input{
  padding: 5px;
  background: #000000;
  border: 1px solid #717171;
  color: #afafaf;
  border-radius: 5px;
}

.formField > input::placeholder {
  color: rgb(180, 180, 180) !important;
  opacity: 1;
}

.formField > input:focus {
  outline: none;
  border-color: #ff7700; /* Optional: Add a custom border color on focus */
}

.formField > p{
  margin: 0px !important;
}

.price_container{
  position: sticky;
  bottom: -31px;
  background: #303030;
  padding: 10px 0px;
  margin-top: 20px;
  border-top: 1px solid gray;
}

.price{
  font-size: clamp(20px, 25px, 27px);
  font-weight: 600;
  color: #ff7700;
}

@media (max-width: 950px){
  .contact_form_row{
    flex-direction: column;
  }
}