@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

.landing_page{
  font-family: "Raleway", serif;
}

.section_wrapper{
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  /* overflow: hidden; */
  background-color: #000000;
  color: #ffffff;
}

.section{
  width: 100%;
  overflow: hidden;
}

@media (max-width: 980px) {
  .section_wrapper{
    margin-top: 0px;
  }
}