.customSlider_container {
  position: relative;
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
}

.customSlider_swiper {
  padding: 20px 0 20px 0;
  /* padding-left: 20px; */
  width: 90%;
  margin: 0 auto;
}

.customSlider_swiper .swiper-slide {
  transform: none !important; /* Remove scaling effect */
  height: auto !important; /* Ensure consistent height */
}

.customSlider_swiper > .swiper-slide-active{
  height: auto !important;
}

.customSlider_container {
  position: relative;
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  padding: 40px 0;
  /* overflow-x: hidden; */
}

.customSlider_slide {
  background: #2a2a2a; /* Card background */
  border-radius: 12px;
}

.testimonial_card{
  background: linear-gradient(180deg, #484848 0%, #090909 100%);
  color: #fff;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Add subtle shadow */
  min-height: 385px; /* Ensure consistent height */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 12px;
}

.companydetails{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  gap: 1rem;
  color: #ff7700;
  height: 140px;
  padding-top: 23px;
}
.quote {
  font-size: clamp(15px, 2vw, 17px);
}

.name {
  font-weight: bold;
}

.position {
  font-size: 13px;
}

.logo {
  height: 50px;
  width: 120px;
}

.customSlider_navButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  background: rgba(136, 136, 136, 0.5);
  color: #fff;
  border: none;
  font-size: 24px;
  padding: 0px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
  height: 45px;
  width: 45px;
}

.customSlider_prevButton {
  left: 10px;
}

.customSlider_nextButton {
  right: 10px;
}

.customSlider_navButton:hover {
  background: rgba(136, 136, 136, 0.2);
}
@media (min-width:1500px){
  .testimonial_card{  
    min-height: 435px; /* Ensure consistent height */
  }
}
@media (max-width:1300px){
  .testimonial_card{
    min-height: 410px;
  }
}

@media (max-width:700px){
  .testimonial_card{
    min-height: 350px;
  }
}

@media (max-width: 450px) {
  .customSlider_navButton {
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .customSlider_prevButton {
    left: -6vw;
  }

  .customSlider_nextButton {
    right: -6vw;
  }

  .testimonial_card{
    min-height: 420px;
  }
  .companydetails{
    height: 175px;
  }
  
}
@media (max-width:380px){
  .testimonial_card{
    min-height: 440px;
  }

  .logo{
    height: 43px;
    width: 84px;
  }
}

@media (min-width: 451px) and (max-width: 580px) {
  /* .customSlider_navButton {
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
  } */

  .customSlider_prevButton {
    left: -4vw;
  }

  .customSlider_nextButton {
    right: -4vw;
  }
}

@media (min-width: 581px) and (max-width: 730px) {
  .customSlider_prevButton {
    left: -4vw;
  }

  .customSlider_nextButton {
    right: -4vw;
  }
}

@media (min-width: 731px) and (max-width: 1000px) {
  .customSlider_prevButton {
    left: -3vw;
  }

  .customSlider_nextButton {
    right: -3vw;
  }
}

@media (min-width: 1001px) and (max-width: 1250px) {
  .customSlider_prevButton {
    left: 0;
  }

  .customSlider_nextButton {
    right: 0;
  }
}

