* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main_container {
  /* height: 672px; */
  height: 870px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 0px;
}

.api_section {
  /* height: 100%; */
  height: 800px;
  width: 100%;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;
  overflow: hidden;
}

.left_side,
.right_side {
  width: 100%;
  height: 100%;
  position: relative;
}

.left_side {
  background: #fafafa;
  height: 500px;
}

.right_side {
  background: #eeeeee;
  height: 500px;
}

.title {
  position: absolute;
  font-family: "Fjalla One", sans-serif;
  font-size: 1rem;
}

.left_title,
.right_title {
  top: 0.5rem;
}

.left_title {
  color: #575859;
  right: 2%;
}

.right_title {
  color: #ff7700;
  right: 2%;
}

.logo_container {
  height: 72px;
  width: 72px;
  border-radius: 20px;
  box-shadow: 10px 6px 11px rgba(0, 0, 0, 0.1), inset 0px 2px 6px 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 50%;
  left: 43%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.logo_container::after {
  content: "";
  height: 50%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #eeeeee;
  border-radius: 15px 15px 0px 0px;
  z-index: -1;
  box-shadow: inset 0px 2px 6px 10px rgba(0, 0, 0, 0.03);
}

.logo_container::before {
  content: "";
  height: 50%;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #fafafa;
  border-radius: 0px 0px 15px 15px;
  box-shadow: inset 0px 2px 6px 10px rgba(0, 0, 0, 0.03);
}

.logo_container>img {
  height: 70%;
  width: 70%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mobile_backlines {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 50%;
  top: 56%;
  transform: translate(-58%, -50%);
  z-index: 0;
}

.mobile_backlines>img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.desktop_backlines {
  display: none;
}

.tablet_backlines {
  display: none;
}

.api_box_container {
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  max-width: 100px;
  flex-direction: column;
  justify-content: center;
}

.api_box_container>p {
  font-family: "Fjalla One", sans-serif !important;
  color: #4e4f50;
  white-space: pre-wrap;
  flex: 1;
  font-size: 0.7rem;
  line-height: 0.95rem;
  margin: 0px;
  text-align: center;
}

.tile {
  padding: 2px;
  border: 1px solid #ff7700;
  box-shadow: 7px 10px 18px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
}

.tile>img {
  height: 60%;
  max-width: 100%;
}

#e2e_msp {
  flex-direction: column-reverse;
}

#data_transfer {
  flex-direction: column-reverse;
}

#fastag {
  flex-direction: column-reverse;
}

@media screen and (min-width:330px) {
  .title {
    font-size: 1.2rem;
  }

  .tablet_backlines {
    display: none;
  }

  #suggestion {
    top: 32%;
    left: 21vw;
    flex-direction: row;
  }

  #trip_creation {
    top: 29%;
    right: 11vw;
    flex-direction: column-reverse;
  }

  #dispatch {
    top: 21%;
    left: 2vw;
    flex-direction: row;
  }

  #booking {
    top: 52%;
    left: 35vw;
    flex-direction: row;
  }

  #setting {
    top: 54%;
    right: 9%;
  }

  #user {
    top: 66%;
    right: 33vw;
  }

  #route {
    top: 56%;
    left: 11vw;
  }

  #contract {
    top: 77%;
    left: 5vw;
  }

  #hub {
    top: 68%;
    left: 35vw;
    flex-direction: column-reverse;
  }

  #rto {
    top: 5%;
    left: 3vw;
    flex-direction: column-reverse;
  }

  #monitoring_control {
    top: 32%;
    left: 8%;
    flex-direction: row-reverse;
    max-width: 110px;
  }

  #e2e_msp {
    top: 25%;
    flex-direction: column-reverse;
    right: 25%;
  }

  #data_transfer {
    top: 37%;
    right: 36vw;
    flex-direction: column;
  }

  #installation {
    bottom: 34%;
    left: 3vw;
    flex-direction: row-reverse;
    max-width: 118px;
  }

  #fois {
    top: 11%;
    right: 41.5vw;
  }

  #sim_tracking {
    top: 36%;
    right: 2vw;
    flex-direction: column;
  }

  #ldb {
    bottom: 5%;
    right: 38.5vw;
  }

  #vessel_tracking {
    bottom: 14%;
    left: 10vw;
    flex-direction: row;
  }

  #cargo_updates {
    bottom: 6%;
    right: 9vw;
  }

  #fastag {
    bottom: 26%;
    right: 15vw;
    flex-direction: row;
    min-width: 123px;
  }
}

@media screen and (min-width:400px) {

  .left_title,
  .right_title {
    top: 1rem;
  }

  .left_title {
    right: 5%;
  }

  .right_title {
    right: 5%;
  }

  #user {
    top: 66%;
    right: 34vw;
  }

  #rto {
    top: 7%;
    left: 5vw;
  }

  #monitoring_control {
    left: 10%;
    top: 34%;
  }

  #e2e_msp {
    top: 29%;
  }

  #data_transfer {
    top: 38%;
    right: 40vw;

  }

  #installation {
    left: 8vw;
  }

  #cargo_updates {
    bottom: 5%;
    right: 14vw;

  }

  #fastag {
    bottom: 24%;
    right: 18vw;
    max-width: 120px;
  }

  #vessel {
    left: 15vw;
  }
}

@media screen and (min-width:450px) {
  #user {
    top: 66%;
    right: 39vw;
  }

  #setting {
    top: 51%;
    right: 18%;
  }

  #trip_creation {
    top: 29%;
    right: 16vw;
    flex-direction: column-reverse;
  }

  #dispatch {
    top: 17%;
    left: 4vw;
    flex-direction: row;
  }
}

@media screen and (min-width:480px) {
  #rto {
    left: 15vw;
  }

  #suggestion {
    top: 27%;
    left: 24vw;
    flex-direction: row;
  }

  #monitoring_control {
    left: 16%;
  }

  #fois {
    top: 14%;
    right: 44.5vw;
  }

  #e2e_msp {
    top: 29%;
    right: 30%;
  }

  #sim_tracking {
    top: 32%;
    right: 9vw;
    flex-direction: column-reverse;
  }

  #data_transfer {
    right: 41vw;
  }

  #fastag {
    bottom: 24%;
    right: 24vw;
  }

  #installation {
    left: 12vw;
  }

  #vessel_tracking {
    bottom: 14%;
    left: 17vw;
    flex-direction: row;
  }

  #cargo_updates {
    bottom: 2%;
    right: 18vw;
  }

  #dispatch {
    top: 11%;
    left: 8vw;
  }

  #trip_creation {
    top: 22%;
    right: 16vw;
    flex-direction: column-reverse;
  }

  #suggestion {
    top: 26%;
    left: 27vw;

  }

  #booking {
    top: 47%;

  }

  #user {
    top: 62%;

  }

  #route {
    top: 51%;
    left: 11vw;
  }

  #contract {
    top: 73%;
    left: 8vw;
  }
}

@media screen and (min-width:530px) {
  .mobile_backlines {
    display: none;
  }

  .tablet_backlines {
    display: block;
  }

  .desktop_backlines {
    display: none;
  }

  .main_container {
    height: 1000px;
  }

  .tablet_backlines {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 50%;
    top: 56%;
    transform: translate(-50%, -50%);
    z-index: 0;
  }

  .tablet_backlines>img {
    height: 90%;
    width: 100%;
    /* object-fit: cover; */
  }


  .logo_container {
    height: 85px;
    width: 85px;
    top: 50%;
    left: 50%;
  }

  .api_box_container>p {
    font-size: 0.8rem;
  }

  .tile {
    width: 65px;
    height: 65px;
  }

  #route {
    top: 45%;
    left: 21vw;
    flex-direction: row-reverse;
  }

  #contract {
    top: 68%;
    left: 27vw;
  }

  #hub {
    top: 52%;
    left: 43vw; 
  }

  #user {
    top: 56%;
    right: 33vw;
  }

  #setting {
    top: 48%;
    right: 17%;
  }
  #suggestion {
    top: 22%;
    left: 20vw;
    flex-direction: row-reverse;
    min-width: 120px;
}
#trip_creation {
  top: 24%;
  right: 21vw;
}

#dispatch {
  top: 9%;
  left: 9vw;
  flex-direction: row-reverse;
  min-width: 120px;
}
  #rto {
    top: 1%;
    max-width: 125px;
  }
  #booking{
    top: 33%;
    left: 41vw;
  }

  #e2e_msp {
    top: 9%;
    right: 30%;
  }

  #sim_tracking {
    top: 22%;
    right: 11vw;
    flex-direction: column;
  }

  #monitoring_control {
    left: 9%;
    max-width: 150px;
    top: 29%;
  }

  #data_transfer {
    top: 43.3%;
  }

  #installation {
    left: 10vw;
    max-width: 167px;
    bottom: 29%;
  }

  #fois {
    top: 13%;
    right: 44.5vw;
  }

  #vessel_tracking {
    bottom: 5%;
    left: 17vw;
    max-width: 160px;
  }

  #cargo_updates {
    bottom: 3%;
    right: 13vw;
  }

  #fastag {
    bottom: 23%;
    right: 5vw;
    max-width: 203px;
  }
}

@media screen and (min-width:610px) {
  #monitoring_control {
    left: 16%;
  }

  #fastag {
    bottom: 22%;
    right: 10vw;
  }
}

@media screen and (min-width:650px) {
  #rto {
    top: 2%;
    left: 18vw;
  }
  #hub {
    top: 54%;
    left: 45vw;
}
#booking {
  top: 36%;
  left: 44vw;
}
#suggestion {
  top: 24%;
  left: 27vw;
}
#dispatch {
  top: 9%;
  left: 19vw;
}
  #monitoring_control {
    left: 18%;
  }

  #e2e_msp {
    top: 10%;
    right: 33%;
  }

  #sim_tracking {

    right: 18vw;
  }

  #data_transfer {
    right: 39vw;
  }

  #installation {
    left: 15vw;
  }

  #vessel_tracking {
    left: 22vw;
  }

  #cargo_updates {
    bottom: 5%;
    right: 19vw;
  }

  #fastag {
    bottom: 25%;
    right: 10vw;
  }
}

@media screen and (min-width:750px) {
  #setting {
    right: 25%;
  }

  #user {
    top: 56%;
    right: 37vw;
  }
  #trip_creation {
    top: 24%;
    right: 26vw;
}


}

@media screen and (min-width:770px) {
  #rto {
    top: 1%;
    left: 23vw;
  }

  #monitoring_control {
    left: 22%;
        top: 31%;
  }

  #data_transfer {
    right: 42vw;
  }

  #installation {
    left: 20vw;
  }

  #vessel_tracking {
    left: 24vw;
  }

  #cargo_updates {
    bottom: 5%;
    right: 23vw;

  }

  #fastag {
    bottom: 23%;
    right: 17vw;
  }

  #sim_tracking {
    right: 17vw;
  }
}
@media screen and (min-width:850px){
  #contract {
    left: 30vw;
}
#route {
  top: 45%;
  left: 26vw;
  flex-direction: row-reverse;
}
#booking {
  top: 34%;
  left: 45vw;
}
#suggestion{
top: 23%;
left: 30vw;
}
#dispatch {
  top: 9%;
  left: 21vw;
}
#trip_creation {
  top: 42%;
  right: 26vw;
}

#setting {
  top: 16%;
  right: 26%;
  flex-direction: column-reverse;
}

}
@media screen and (min-width:880px) {
  .tile {
    width: 75px;
    height: 75px;
  }
  #rto {
    left: 28vw;
  }


  #setting {
    top: 15%;
    right: 23%;
  }

  #monitoring_control {
    left: 27%;
  }

  #e2e_msp {
    right: 36%;
  }

  #data_transfer {
    right: 42vw;
  }

  #installation {
    left: 24vw;
  }

  #vessel_tracking {
    left: 26vw;
  }

  #cargo_updates {
    right: 26vw;
    bottom: 2%;

  }

  #fastag {
    right: 21vw;
  }

  #sim_tracking {
    right: 26vw;
  }
}

@media screen and (min-width:1050px) {
  .tablet_backlines {
    display: none;
  }

  .main_container {
    height: 750px;
  }

  .api_section {
    flex-direction: row;
  }

  .title {
    font-size: 2rem;
  }

  .left_title {
    left: 20%;
    right: unset;
  }

  .right_title {
    right: 20%;
  }

  .api_section {
    height: 100%;
  }

  .left_side {
    height: 100%;
  }

  .right_side {
    height: 100%;
  }

  .api_box_container>p {
    font-size: 1rem;
    line-height: unset;
    text-align: left;

  }

  .logo_container {
    height: 120px;
    width: 120px;
    border-radius: 20px;
    box-shadow:
      10px 6px 11px rgba(0, 0, 0, 0.1),
      inset 0px 2px 6px 10px rgba(0, 0, 0, 0.1);
    position: absolute;
    bottom: 28%;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 1;
  }

  .logo_container::after {
    height: 100%;
    width: 50%;
    left: 0;
    top: 0;
    border-radius: 20px 0px 0px 20px;
  }

  .logo_container::before {
    height: 100%;
    width: 50%;
    right: 0;
    top: 0;
    left: unset;
    bottom: unset;
    border-radius: 0px 20px 20px 0px
  }

  .desktop_backlines {
    display: block;
    height: 90%;
    width: 89%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    z-index: 0;
  }

  .desktop_backlines>img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .mobile_backlines {
    display: none;
  }

  .api_box_container {
    display: flex;
    align-items: center;
    gap: 15px;
    position: absolute;
    max-width: 200px;
    flex-direction: row;
    justify-content: unset;
  }

  .tile {
    width: 100px;
    height: 80px;
    padding: 8px;
    border-radius: 17px;
  }

  .tile>img {
    height: 70%;
    max-width: 100%;
  }

  #hub {
    top: 32%;
    left: 31vw;
    flex-direction: row;
  }

  #route {
    top: 14%;
    left: 27vw;
    flex-direction: row;
  }

  #contract {
    left: 32vw;
    flex-direction: row;
    top: 70%;
  }

  #user {
    top: 86%;
    right: 26vw;
    flex-direction: row;
  }

  #setting {
    top: 86%;
    right: 15%;
    flex-direction: row;
  }

  #booking {
    top: 60%;
    left: 13vw;
    flex-direction: row-reverse;
  }

  #suggestion {
    top: 46%;
    left: 21vw;
    flex-direction: row-reverse;
  }

  #trip_creation {
    top: 13%;
    left: 3%;
    flex-direction: row-reverse;
  }

  #dispatch {
    top: 31%;
    left: 12vw;
    flex-direction: row-reverse;
  }

  #rto {
    top: 10%;
    left: 3vw;
    flex-direction: row-reverse;
    max-width: 225px;
  }

  #rto>p {
    text-align: right;
  }

  #monitoring_control {
    top: 24%;
    right: 3vw;
    flex-direction: row;
    max-width: 200px;
    left: auto;
  }

  #e2e_msp {
    top: 11%;
    right: 1vw;
    flex-direction: row;
  }

  #e2e_msp>p {
    text-align: right;
  }

  #data_transfer {
    top: 37%;
    left: 20vw;
    flex-direction: row;
    right: auto;
  }

  #installation {
    bottom: 0%;
    left: 8vw;
    flex-direction: row-reverse;
    max-width: 198px;
  }

  #installation>p {
    text-align: right;
  }

  #fois {
    top: 32%;
    right: 33vw;
    flex-direction: row-reverse;
    max-width: 312px !important;

  }

  #fois>p {
    text-align: right;
  }

  #sim_tracking {
    top: 49%;
    right: 24vw;
    flex-direction: row;
  }

  #ldb {
    bottom: 60%;
    right: 0.5vw;
    max-width: 276px !important;
  }

  #vessel_tracking {
    top: 60%;
    left: 27vw;
  }

  #cargo_updates {
    bottom: 2%;
    right: 1vw;
  }

  #fastag {
    top: 76%;
    left: 2vw;
    flex-direction: row-reverse;
    max-width: 200px;
  }

  #fastag>p {
    text-align: right;
  }
}

@media screen and (min-width:1350px) {

  .logo_container {
    height: 130px;
    width: 130px;
    bottom: 33%;
    top: 54%;
    transform: translate(-50%, -25%);
  }

  .api_box_container {
    max-width: 250px;
  }

  .tile {
    /* width: 147px;
    height: 100px; */

    width: 130px;
    height: 90px;

  }

  #hub {
    left: 34vw;
    top: 30%;
  }

  #route {
    left: 28vw;
    top: 12%;
  }

  #contract {
    left: 35vw;
    flex-direction: row;
    top: 70%;
  }

  #booking {
    top: 67%;
    left: 14vw;
    flex-direction: row-reverse;
  }

  #dispatch {
    top: 31%;
    left: 15vw;
    flex-direction: row-reverse;
  }

  #user {
    right: 26vw;
  }

  #trip_creation {
    left: 6%;
  }

  #monitoring_control {
    top: 25%;
    right: 8vw;
    flex-direction: row;
    max-width: 228px;
    left: auto;
  }

  #e2e_msp {
    top: 9.2%;
    left: 32vw;
    right: auto;
  }

  #data_transfer {
    left: 18vw;
    top: 40.5%;
  }

  #installation {
    max-width: 250px;
    bottom: 1%;
  }

  #sim_tracking {
    top: 53%;
    right: 26vw;
  }

  #vessel_tracking {
    top: 60%;
    left: 21vw;
    max-width: 232px;
  }

  #cargo_updates {
    bottom: 1%;
    right: 4vw;
  }

  #fastag {
    top: 76%;
    left: 1vw;
    max-width: 244px;
  }
}

/* @media screen and (min-width:1600px) {
  #ldb{
    bottom: 55%;
        right: 12.5vw;
  }
  #data_transfer {
    top: 77%;
    left: 34vw;
    flex-direction: row;
}

#installation {
    bottom: 8%;
    left: 9vw;
    flex-direction: row-reverse;
  }
} */

/* @media screen and (min-width:1850px) {
  #rto {
    top: 14%;
    left: 15vw;
  }

  #monitoring_control {
    top: 5%;
    right: 19vw;
    flex-direction: row-reverse;
  }

  #monitoring_control>p{
    text-align: right;
  }

  #e2e_msp {
    bottom: 24%;
    left: 25vw;
    flex-direction: row-reverse;
  }
  #e2e_msp>p{
    text-align: right;
  }

  #data_transfer {
    top: 77%;
    left: 36vw;
    flex-direction: row;
}
  
  #installation {
    bottom: 8%;
    left: 11vw;
    flex-direction: row-reverse;
  }

  #installation>p{
    text-align: right;
  }

  #fois {
    top: 11%;
    right: 16vw;
    flex-direction: row-reverse;
  }

  #fois >p{
    text-align: right;
  }

  #sim_tracking {
    top: 36%;
    left: 12vw;
    flex-direction: row-reverse;
  }

  #sim_tracking>p{
    text-align: right;
  }

  #ldb {
    bottom: 57%;
    right:16.5vw;
  }

  #vessel_tracking {
    top: 60%;
    left: 12vw;
  }

  #cargo_updates {
    bottom: 8%;
    left: 26vw;
  }

  #fastag {
    top: 78%;
    left: 0vw;
    flex-direction: row-reverse;
  }
  
  #fastag>p{
    text-align: right;
  }
} */