
   .fade {
    opacity: 0;
    transition: opacity 2s ease-in-out;
  }
  
  .fade-in {
    opacity: 1;
  }
  
  .fade-out {
    opacity: 0;
  }
  
  .slide {
    transform: translateX(100%);
    transition: transform 2s ease-in-out;
  }
  
  .slide-in-left {
    transform: translateX(0%);
  }
  
  .slide-out-left {
    transform: translateX(-100%);
  }
  
  .slide-in-right {
    transform: translateX(0);
    opacity: 1;
  }
  
  .slide-out-right {
    transform: translateX(10%);
    opacity: 0.5;
  }
  
  .slide-in-up {
    transform: translateY(0);
  }
  
  .slide-out-up {
    transform: translateY(-100%);
  }
  
  .slide-in-down {
    transform: translateY(0);
  }
  
  .slide-out-down {
    transform: translateY(100%);
  }
  