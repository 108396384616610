/* Container for the particles */
.dust-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none; /* Ensures particles don't interfere with user interactions */
  background-color: #000; /* Change to your desired background color */
}

/* Individual particles */
.dust-particle {
  position: absolute;
  width: 5px; /* Adjust particle size */
  height: 5px;
  background-color: white;
  border-radius: 50%;
  opacity: 0;
  animation: float 8s infinite ease-in-out;
}

/* Animation for the particles */
@keyframes float {
  0% {
    transform: translateY(100vh);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    transform: translateY(-100vh);
    opacity: 0;
  }
}

/* Random positions for particles */
.dust-particle:nth-child(n) {
  left: calc(100vw * var(--random-x));
  animation-delay: calc(8s * var(--random-delay));
}
