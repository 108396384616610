@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');
.postsDiv{
    width: 100%;
    height: 760px;
    position: relative;
    top: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
}
.postClipped{
    width: 80%;
    height: 80%;
    margin-left: auto;
    margin-right: auto;
    /* background-color: rgb(15, 15, 15); */
    position: relative;
    bottom: 20px;
    border-radius: 10px;
    overflow: auto;
    box-shadow: 1px 1px 11px #ff7200;
}
.postHeading{
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.postHeading span{
    color: #ff7200;
    font-size: 2.2rem;
    font-weight: 700;
    font-family: 'Fjalla One', sans-serif !important;
}
.postsMainDiv{
    width: 95%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: center;
    border-bottom: 1px solid #484848;
}
.postsLeft{
    width: 60%;
    height: 435px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.bgBack{
    position: absolute;
    width: 95%;
    height: 95%;
}
.postsRight{
    width: 40%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 10px;
    background-color: #101010;
    padding-top: 38px;
}
.monthPost{
    width: 90%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    top: 0px;
}
.monthPost span{
    color: grey;
    font-size: 0.8rem;
}
.postSpecial{
    color: silver !important;
    margin-left: 15px ;
    font-size: 1rem !important;
    font-weight: 700;
}

.postTop{
    width: 90%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    padding: 5px;
    margin-bottom: 10px;
}
.postTop span{
    color: #ff7200;
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 0px ;
}
.postBottom{
    width: 90%;
    height: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
}
.postBottom span{
    color: #fff;
}
.alignRight{
    display: flex;
}

/*Css 2023*/
.may-car{
    position: absolute;
    width: 60%;
}
.person-png{
    position: absolute;
    width: 70%;
}
.block-img{
    position: absolute;
    width: 60%;
}
.nfc-img{
    position: absolute;
    width: 30%;
}
.semicon{
    position: absolute;
    width: 30%;
}
.comm-img{
    position: absolute;
    height: 86%;
}
.iotimg5{
    position: absolute;
    width: 60%;
}
/* feb */
.imgabs{
    position: absolute;
    width: 90%;
    height: 90%;
}
.img1feb{
    position: absolute;
    width: 45%; 
}
.img3feb{
    position: absolute;
    width: 79%;
}
.img4feb{
    position: relative;
    right: 0px;
    width: 35%;
}
/* march */
.img1march{
    width: 45%;
    position: absolute;
    top: 30px;
    left: 34px;
}
.img2march{
    width: 45%;
    position: absolute;
    bottom: 30px;
    right: 40px;
}
.threemarch{
    width: 80%;
    z-index: 2;
    position: absolute;
}
/* april */
.img1{
    position: absolute;
    width: 70%;
    height: 52%;
}
.img2{
    position: absolute;
    width: 45% !important;
    height: 220px;
}
.img3{
    position: absolute;
    width: 95% !important;
    height: 340px;
}
/* may */
.img1abs{
    position: absolute;
    width: 42%;
    top: 20px;
    left: 46px;
}
.img2abs{
    position: absolute;
    width: 44%;
    bottom: 50px;
    right: 40px;
}
.img2may{
    position: relative;
    right: 0px;
    width: 35% !important;
    height: 220px;
}
.img3may{
    position: relative;
    left: 0px;
    width: 45% !important;
    height: 170px;
}
/* june */
.img1june{
    width: 80%;
    position: absolute;
}
.img2june{
    position: absolute;
    right: 20px;
    width: 70%;
}
.posts_footer{
    position: absolute;
    width: 100%;
    top: 700px;
}
.colorWhite{
    color: #fff !important;
    font-size: 1rem !important;
    font-weight: 400 ! important;
    letter-spacing: 1px;
    padding-left: 2px;
}
.lighter{
    font-size: 1rem !important;
    font-weight:  400 !important;
}
.colorOrange{
    color: #ff7200 !important;
    font-size: 1rem !important;
}
.fontSize{
    font-size: 1.1rem !important;
    font-weight: 700 !important;
}
.postMain{
    display: block;
}
.postMainToggle{
    display: none;
}
/* january css added 2022 */
.lynkitlogo{
    width: 70%;
    position: absolute;
}
.imgjan1{
    width: 35%;
    position: absolute;
    bottom: 28px;
    left: 40px;
}
.imgjan2{
    width: 35%;
    position: absolute;
    top: 30px;
    right: 37px;
}
.threejan{
    position: absolute;
    width: 50%;
}
.fourjan{
    position: absolute;
    width: 35%;
    right: 50px;
}
.fivejan{
    position: absolute;
    left: 50px;
    width: 35%;
}
.dotted{
    width: 12%;
    position: absolute;
    color: #ff7200;
    border: 1.2px dashed #ff7200;
}
.sixjan{
    position: absolute;
    width: 40%;
}
.sevenjan{
    position: absolute;
    width: 35%;
}
.eightjan{
    position: absolute;
    width: 35%;
}
/* dec 2021 */
.decimg{
    position: absolute;
    width: 27%;
}
.twodec{
    position: absolute;
    width: 45%;
    top: 20px;
    left: 45px;
}
.threedec{
    position: absolute;
    width: 35%;
    bottom: 30px;
    left: 75px;
}
.fourdec{
    position: absolute;
    width: 35%;
    top: 10px;
    right: 25px;
}
.fivedec{
    position: absolute;
    width: 30%;
    bottom: 30px;
    right: 40px;
}
.sixdec{
    position: absolute;
    width: 45%;
}
/* /non2021 */
.onenov{
    width: 30%;
    position: absolute;
    right: 30px;
}
.twonov{
    width: 30%;
    position: absolute;
    left: 30px;
}
.threenov{
    width: 38%;
    position: absolute;
    top: 60px;
}
.fournov{
    width: 38%;
    position: absolute;
    bottom: 60px;
}
.polar{
    position: absolute;
    color: #ff7200;
    font-size: 1.2rem;
}
.polardown{
    position: absolute;
    bottom: 20px;
    color: #ff7200;
    font-size: 1.2rem;
}
.fivenov{
    width: 70%;
    position: absolute;
}
.sixnov{
    width: 45%;
    position: absolute;
}
/* oneoct */
.oneoct{
    position: absolute;
   width: 66%;
}
.twooct{
    position: absolute;
    width: 41%;
}
.fouroct{
    position: absolute;
    width: 40%;
}
.fiveoct{
    position: absolute;
    width: 52%;
}
.sixoct{
    position: absolute;
    width: 30%;
    top: 20px;
    right: 80px;
}
.sevenoct{
    position: absolute;
    width: 30%;
    bottom: 20px;
    left: 80px;
}
/* /july */
.july3{
    position: relative;
}
.julya{
    position: absolute;
    width: 32%;
    left: 30px;
    justify-content: space-between;
}
.julyb{
    position: absolute;
    width: 21%;
    left: 42%;
}
.julyc{
    position: absolute;
    width: 29%;
    right: 0px;
}
.chipImg{
    position: absolute;
    width: 90%;
}
.chipspan{
    color: #ff7200;
    position: absolute;
    top: 74%;
    left: 25%;
}
.chipspan1{
    color: #ff7200;
    position: absolute;
    top: 74%;
    right: 20%;
}
.july33{
    position: absolute;
    width: 78%;
}
.centre{
    position: absolute;
    color: #fff;
    top: 70%;
    left: 8%;
    display: flex;
    text-align:center ;
    font-size: 0.9vw;
}
.micro{
    position: absolute;
    color: #fff;
    top: 70%;
    left: 39%;
    display: flex;
    text-align:center ;
    font-size: 0.9vw;
}
.macro{
    position: absolute;
    color: #fff;
    top: 70%;
    right: 14%;
    top: 70%;
    display: flex;
    text-align:center ;
    font-size: 0.9vw;
}
/* september */
.oned{
    position: absolute;
    left: 8%;
    width: 34%;
}
.twod{
    position: absolute;
    right: 12%;
    width: 32%;
}
.postRightAuto{
    height: auto;
    padding-top: 20px;
}
.codesSpan{
    position: relative;
    top: 0px;
}
.sep1{
    width: 22%;
    position: absolute;
    top: 10%;
    left: 10%;
}
.sep2{
    width: 22%;
    position: absolute;
    right: 10%;
    top: 10%;
}
.sep3{
    width: 21%;
    position: absolute;
    bottom: 16%;
    left: 10%;
}
.sep411{
    width: 22%;
    position: absolute;
    right: 10%;
    bottom: 11%;
}
.sep44{
    width: 22%;
    position: absolute;
    right: 10%;
    bottom: 15%;
}
.qrSpan{
    color: #ff7200;
    font-size: 0.8vw;
    position: absolute;
    top: 45%;
    left: 18%;
}
.matrixspan{
    color: #ff7200;
    font-size: 0.8vw;
    position: absolute;
    top: 45%;
    right: 16%;
}
.pdfspan{
    color: #ff7200;
    font-size:0.8vw;
    position: absolute;
    bottom: 8%;
    left: 17%;
}
.maxspan{
    color: #ff7200;
    font-size: 0.8vw;
    position: absolute;
    bottom: 8%;
    right: 16%;
}
.code39{
    color: #ff7200;
    font-size: 0.8vw;
    position: absolute;
    top: 40%;
    left: 18%;
}
.code128{
    color: #ff7200;
    font-size:0.8vw;
    position: absolute;
    top: 40%;
    right: 17%;
}
.ean13{
    color: #ff7200;
    font-size: 0.8vw;
    position: absolute;
    bottom: 8%;
    left: 18%;
}
.isbn{
    color: #ff7200;
    font-size: 0.8vw;
    position: absolute;
    bottom: 8%;
    right: 19%;
}
.sep11{
    background-color: #fff;
    width: 30%;
    position: absolute;
    left: 15%;
}
.sep12{
    background-color: #fff;
    width: 30%;
    position: absolute;
    right: 15%;
}
.sku{
    color: #ff7200;
    width: 30%;
    font-size: 0.8vw;
    position: absolute;
    top: 72%;
    left: 28%;
}
.upc{
    color: #ff7200;
    width: 30%;
    position: absolute;
    right: 3%;
    top: 72%;
    font-size: 0.8vw;
}
.underlineMe{
    text-decoration: underline;
}


@media screen and (min-device-width: 1701px){
    .postMain{
        display: block;
    }
    .postMainToggle{
        display: none;
    }
    .postsDiv{
        width: 100%;
        height: 760px;
        position: relative;
        top: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #000;
    }
    .postClipped{
        width: 75%;
        height: 80%;
        margin-left: auto;
        margin-right: auto;
        /* background-color: rgb(15, 15, 15); */
        position: relative;
        bottom: 20px;
        border-radius: 10px;
        overflow: auto;
        box-shadow: 1px 1px 11px #ff7200;
    }
    .postHeading{
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .postHeading span{
        color: #ff7200;
        font-size: 2.6rem;
        font-weight: 700;
        font-family: 'Fjalla One', sans-serif !important;
    }
    .postsMainDiv{
        width: 95%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        justify-content: center;
        border-bottom: 1px solid #484848;
    }
    .postsLeft{
        width: 55%;
        height: 435px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }
    .bgBack{
        position: absolute;
        width: 95%;
        height: 95%;
    }
    .postsRight{
        width: 45%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        border-radius: 10px;
        background-color: #101010;
        padding-top: 38px;
    }
    .monthPost{
        width: 90%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        position: absolute;
        top: 0px;
    }
    .monthPost span{
        color: grey;
        font-size: 1rem;
    }
    .postSpecial{
        color: silver !important;
        margin-left: 15px ;
        font-size: 1.1rem !important;
        font-weight: 700;
    }
    
    .postTop{
        width: 90%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
        margin-bottom: 10px;
    }
    .postTop span{
        color: #ff7200;
        font-size: 1.3rem;
        font-weight: 700;
        line-height: 20px;
    }
    .postBottom{
        width: 90%;
        height: auto;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: auto;
        margin-right: auto;
    }
    .postBottom span{
        color: #fff;
        font-size: 1.2rem;
    }
    .alignRight{
        display: flex;
    }
    /*Css 2023*/
    .may-car{
    position: absolute;
    width: 60%;
    }
    .person-png{
        position: absolute;
        width: 70%;
    }
    .block-img{
        position: absolute;
        width: 60%;
    }
    .nfc-img{
        position: absolute;
        width: 30%;
    }
    .semicon{
        position: absolute;
        width: 30%;
    }
    .comm-img{
        position: absolute;
        height: 86%;
    }
    .iotimg5{
        position: absolute;
        width: 60%;
    }
    /* feb */
    .imgabs{
        position: absolute;
        width: 90%;
        height: 90%;
    }
    .img1feb{
        position: absolute;
        width: 45%; 
    }
    .img3feb{
        position: absolute;
        width: 79%;
    }
    .img4feb{
        position: relative;
        right: 0px;
        width: 32%;
    }
    /* march */
    .img1march{
        width: 45%;
        position: absolute;
        top: 30px;
        left: 37px;
    }
    .img2march{
        width: 45%;
        position: absolute;
        bottom: 30px;
        right: 40px;
    }
    .threemarch{
        width: 80%;
        z-index: 2;
        position: absolute;
    }
    /* april */
    .img1{
        position: absolute;
        width: 70%;
        height: 52%;
    }
    .img2{
        position: absolute;
        width: 45% !important;
        height: 220px;
    }
    .img3{
        position: absolute;
        width: 95% !important;
        height: 340px;
    }
    /* may */
    .img1abs{
        position: absolute;
        width: 41%;
        top: 20px;
        left: 46px;
    }
    .img2abs{
        position: absolute;
        width: 44%;
        bottom: 50px;
        right: 40px;
    }
    .img2may{
        position: relative;
        right: 0px;
        width: 35% !important;
        height: 210px;
    }
    .img3may{
        position: relative;
        left: 0px;
        width: 45% !important;
        height: 170px;
    }
    /* june */
    .img1june{
        width: 80%;
        position: absolute;
    }
    .img2june{
        position: absolute;
        right: 20px;
        width: 70%;
    }
    .posts_footer{
        position: absolute;
        width: 100%;
        top: 700px;
    }
    .colorWhite{
        color: #fff !important;
        font-size: 1.2rem !important;
        font-weight: 400 ! important;
        letter-spacing: 1px;
        padding-left: 2px;
    }
    .lighter{
        font-size: 1rem !important;
        font-weight:  400 !important;
    }
    .colorOrange{
        color: #ff7200 !important;
        font-size: 1.1rem !important;
    }
    .fontSize{
        font-size: 1.2rem !important;
        font-weight: 700 !important;
    }
    .onenov{
        width: 26%;
        position: absolute;
        right: 30px;
    }
    .twonov{
        width: 26%;
        position: absolute;
        left: 30px;
    }
    .twooct{
        position: absolute;
        width: 38%;
    }
    .sixoct{
        position: absolute;
        width: 27%;
        top: 20px;
        right: 80px;
    }
    .sevenoct{
        position: absolute;
        width: 27%;
        bottom: 20px;
        left: 80px;
    }
}
@media screen and (min-device-width: 1201px) and (max-device-width: 1401px){
    .postMain{
        display: block;
    }
    .postMainToggle{
        display: none;
    }
    .postsDiv{
        width: 100%;
        height: 760px;
        position: relative;
        top: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #000;
    }
    .postClipped{
        width: 80%;
        height: 80%;
        margin-left: auto;
        margin-right: auto;
        /* background-color: rgb(15, 15, 15); */
        position: relative;
        bottom: 20px;
        border-radius: 10px;
        overflow: auto;
        box-shadow: 1px 1px 11px #ff7200;
    }
    .postHeading{
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .postHeading span{
        color: #ff7200;
        font-size: 1.9rem;
        font-weight: 700;
        font-family: 'Fjalla One', sans-serif !important;
    }
    .postsMainDiv{
        width: 95%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        justify-content: center;
        border-bottom: 1px solid #484848;
    }
    .postsLeft{
        width: 60%;
        height: 435px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }
    .bgBack{
        position: absolute;
        width: 95%;
        height: 95%;
    }
    .postsRight{
        width: 40%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        border-radius: 10px;
        background-color: #101010;
        padding-top: 38px;
    }
    .monthPost{
        width: 90%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        position: absolute;
        top: 0px;
    }
    .monthPost span{
        color: grey;
        font-size: 0.8rem;
    }
    .postSpecial{
        color: silver !important;
        margin-left: 15px ;
        font-size: 0.9rem !important;
        font-weight: 700;
    }
    
    .postTop{
        width: 90%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
        margin-bottom: 10px;
    }
    .postTop span{
        color: #ff7200;
        font-size: 1rem;
        font-weight: 700;
        line-height: 20px ;
    }
    .postBottom span{
        color: #fff;
        font-size: 0.9rem;
    }
    .postBottom{
        width: 90%;
        height: auto;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: auto;
        margin-right: auto;
    }
    
    .alignRight{
        display: flex;
    }
    /*Css 2023*/
    .may-car{
        position: absolute;
        width: 60%;
    }
    .person-png{
        position: absolute;
        width: 70%;
    }
    .block-img{
        position: absolute;
        width: 60%;
    }
    .nfc-img{
        position: absolute;
        width: 30%;
    }
    .semicon{
        position: absolute;
        width: 30%;
    }
    .comm-img{
        position: absolute;
        height: 86%;
    }
    .iotimg5{
        position: absolute;
        width: 60%;
    }
    /* feb */   
    .imgabs{
        position: absolute;
        width: 90%;
        height: 90%;
    }
    .img1feb{
        position: absolute;
        width: 45%; 
    }
    .img3feb{
        position: absolute;
        width: 79%;
    }
    .img4feb{
        position: relative;
        right: 0px;
        width: 35%;
    }
    /* march */
    .img1march{
        width: 45%;
        position: absolute;
        top: 30px;
        left: 33px;
    }
    .img2march{
        width: 45%;
        position: absolute;
        bottom: 30px;
        right: 40px;
    }
    .threemarch{
        width: 80%;
        z-index: 2;
        position: absolute;
    }
    /* april */
    .img1{
        position: absolute;
        width: 70%;
        height: 52%;
    }
    .img2{
        position: absolute;
        width: 45% !important;
        height: 220px;
    }
    .img3{
        position: absolute;
        width: 95% !important;
        height: 340px;
    }
    /* may */
    .img1abs{
        position: absolute;
        width: 42%;
        top: 20px;
        left: 40px;
    }
    .img2abs{
        position: absolute;
        width: 44%;
        bottom: 50px;
        right: 40px;
    }
    .img2may{
        position: relative;
        left: 20px;
        /* right: 45px; */
        width: 41% !important;
        height: 220px;
    }
    .img3may{
        position: relative;
        left: 0px;
        width: 45% !important;
        height: 170px;
    }
    /* june */
    .img1june{
        width: 80%;
        position: absolute;
    }
    .img2june{
        position: absolute;
        right: 20px;
        width: 70%;
    }
    .posts_footer{
        position: absolute;
        width: 100%;
        top: 700px;
    }
    .postTop span{
        color: #ff7200;
        font-size: 1rem;
        font-weight: 700;
        line-height: 20px ;
    }
    .postBottom span{
        color: #fff;
        font-size: 0.9rem;
    }
    .colorWhite{
        color: #fff !important;
        font-size: 0.9rem !important;
        font-weight: 400 ! important;
        letter-spacing: 0px;
        padding-left: 2px;
    }
    .lighter{
        font-size: 1rem !important;
        font-weight:  400 !important;
    }
    .colorOrange{
        color: #ff7200 !important;
    }
    .fontSize{
        font-size: 1rem !important;
        font-weight: 700 !important;
    }
    .sevenjan{
        position: absolute;
        width: 40%;
    }
    .eightjan{
        position: absolute;
        width: 40%;
    }
}
@media screen and (min-device-width: 901px) and (max-device-width: 1201px){
    .postMain{
        display: block;
    }
    .postMainToggle{
        display: none;
    }
    .postsDiv{
        width: 100%;
        height: 760px;
        position: relative;
        top: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #000;
    }
    .postClipped{
        width: 80%;
        height: 80%;
        margin-left: auto;
        margin-right: auto;
        /* background-color: rgb(15, 15, 15); */
        position: relative;
        bottom: 20px;
        border-radius: 10px;
        overflow: auto;
        box-shadow: 1px 1px 11px #ff7200;
    }
    .postHeading{
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .postHeading span{
        color: #ff7200;
        font-size: 1.9rem;
        font-weight: 700;
        font-family: 'Fjalla One', sans-serif !important;
    }
    .postsMainDiv{
        width: 100%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        justify-content: center;
        border-bottom: 1px solid #484848;
    }
    .postsLeft{
        width: 50%;
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }
    .bgBack{
        position: absolute;
        width: 95%;
        height: 95%;
    }
    .postsRight{
        width: 50%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        border-radius: 10px;
        background-color: #101010;
        padding-top: 38px;
    }
    .monthPost{
        width: 90%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        position: absolute;
        top: 0px;
    }
    .monthPost span{
        color: grey;
        font-size: 0.7rem;
    }
    .postSpecial{
        color: silver !important;
        margin-left: 15px ;
        font-size: 0.8rem !important;
        font-weight: 700;
    }
    
    .postTop{
        width: 90%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
        margin-bottom: 10px;
    }
    .postTop span{
        color: #ff7200;
        font-size: 1rem;
        font-weight: 700;
        line-height: 0px !important;
    }
    .postBottom span{
        color: #fff;
        font-size: 0.9rem;
    }
    .postBottom{
        width: 90%;
        height: auto;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: auto;
        margin-right: auto;
    }
    
    .alignRight{
        display: flex;
    }
    /*Css 2023*/
    .may-car{
        position: absolute;
        width: 60%;
    }
        .person-png{
        position: absolute;
        width: 70%;
    }
    .block-img{
        position: absolute;
        width: 60%;
    }
    .nfc-img{
        position: absolute;
        width: 30%;
    }
    .semicon{
        position: absolute;
        width: 30%;
    }
    .comm-img{
        position: absolute;
        height: 86%;
    }
    .iotimg5{
        position: absolute;
        width: 60%;
    }
    /* feb */
    .imgabs{
        position: absolute;
        width: 90%;
        height: 90%;
    }
    .img1feb{
        position: absolute;
        width: 45%; 
    }
    .img3feb{
        position: absolute;
        width: 79%;
    }
    .img4feb{
        position: absolute;
        right: 120px;
        width: 35%;
    }
    /* march */
    .img1march{
        width: 45%;
        position: absolute;
        top: 40px !important;
        left: 20px !important;
    }
    .img2march{
        width: 45%;
        position: absolute;
        bottom: 40px;
        right: 25px;
    }
    .threemarch{
        width: 80%;
        z-index: 2;
        position: absolute;
    }
    /* april */
    .img1{
        position: absolute;
        width: 70%;
        height: 100px;
    }
    .img2{
        position: absolute;
        width: 35% !important;
        height: 140px;
    }
    .img3{
        position: absolute;
        width: 95% !important;
        height: 200px;
    }
    /* may */
    .img1abs{
        position: absolute;
        width: 44%;
        top: 40px;
        left: 35px;
    }
    .img2abs{
        position: absolute;
        width: 44%;
        bottom: 50px;
        right: 40px;
    }
    .img2may{
        position: relative;
        left: 12px;
        width: 33% !important;
        height: 140px;
    }
    .img3may{
        position: relative;
        left: 0px;
        width: 40% !important;
        height: 100px;
    }
    /* june */
    .img1june{
        width: 74%;
        position: absolute;
    }
    .img2june{
        position: relative;
       left: 0px;
        width: 70%;
    }
    .posts_footer{
        position: absolute;
        width: 100%;
        top: 700px;
    }
    .postTop span{
        color: #ff7200;
        font-size: 0.9rem;
        font-weight: 700;
        line-height: 0px !important;
    }
    .postBottom span{
        color: #fff;
        font-size: 0.8rem;
    }
    .colorWhite{
        color: #fff !important;
        font-size: 0.8rem !important;
        font-weight: 400 ! important;
        letter-spacing: 0px;
        padding-left: 2px;
    }
    .lighter{
        font-size: 1rem !important;
        font-weight:  400 !important;
    }
    .colorOrange{
        color: #ff7200 !important;
        font-size: 0.8rem !important;
    }
    .fontSize{
        font-size: 0.9rem !important;
        font-weight: 700 !important;
    }
    .sevenjan{
        position: absolute;
        width: 44%;
    }
    .eightjan{
        position: absolute;
        width: 44%;
    }
    .twodec{
        position: absolute;
        width: 37%;
        top: 80px;
        left: 45px;
    }
    .threedec{
        position: absolute;
        width: 33%;
        bottom: 80px;
        left: 55px;
    }
    .fourdec{
        position: absolute;
        width: 35%;
        top: 80px;
        right: 25px;
    }
    .fivedec{
        position: absolute;
        width: 30%;
        bottom: 80px;
        right: 40px;
    }
    .sixdec{
        position: absolute;
        width: 57%;
    }
    .oneoct{
        position: absolute;
       width: 74%;
    }
    .twooct{
        position: absolute;
        width: 52%;
    }
    .fouroct{
        position: absolute;
        width: 52%;
    }
    .fiveoct{
        position: absolute;
        width: 64%;
    }
    .sixoct{
        position: absolute;
        width: 30%;
        top: 50px;
        right: 80px;
    }
    .sevenoct{
        position: absolute;
        width: 30%;
        bottom: 50px;
        left: 80px;
    }
}
@media screen and (min-device-width: 701px) and (max-device-width: 901px){
    .postMain{
        display: none;
    }
    .postMainToggle{
        display: block;
    }
    .postsDiv{
        width: 100%;
        height: 760px;
        position: relative;
        top: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #000;
    }
    .postClipped{
        width: 80%;
        height: 80%;
        margin-left: auto;
        margin-right: auto;
        /* background-color: rgb(15, 15, 15); */
        position: relative;
        bottom: 20px;
        border-radius: 10px;
        overflow: auto;
        box-shadow: 0.3px 0px 6px #ff7200;
    }
    .postHeading{
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .postHeading span{
        color: #ff7200;
        font-size: 1.7rem;
        font-weight: 700;
        font-family: 'Fjalla One', sans-serif !important;
    }
    .postsMainDiv{
        width: 100%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
        justify-content: center;
        border-bottom: 1px solid #484848;
    }
    .postsLeft{
        width: 90%;
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: -1px !important;
        position: relative;
    }
    .bgBack{
        position: absolute;
        width: 95%;
        height: 95%;
    }
    .postsRight{
        width: 90%;
        height: auto;
        display: flex;
        padding-top: 30px;
        padding-bottom: 30px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-bottom: 20px;
        position: relative;
        border-radius: 0px !important;
        background-color: #101010;
        padding-top: 38px;
    }
    .monthPost{
        width: 90%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        position: absolute;
        top: 0px;
    }
    .monthPost span{
        color: grey;
        font-size: 0.7rem;
    }
    .postSpecial{
        color: silver !important;
        margin-left: 15px ;
        font-size: 0.8rem !important;
        font-weight: 700;
    }
    
    .postTop{
        width: 90%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
        margin-bottom: 10px;
    }
    .postTop span{
        color: #ff7200;
        font-size: 1rem;
        font-weight: 700;
        line-height: 0px !important;
    }
    .postBottom span{
        color: #fff;
        font-size: 0.9rem;
    }
    .postBottom{
        width: 90%;
        height: auto;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: auto;
        margin-right: auto;
    }
    
    .alignRight{
        display: flex;
    }

    /*Css 2023*/
    .may-car{
        position: absolute;
        width: 60%;
    }
    .person-png{
        position: absolute;
        width: 70%;
    }   
    .block-img{
        position: absolute;
        width: 60%;
    }
    .nfc-img{
        position: absolute;
        width: 30%;
    }
    .semicon{
        position: absolute;
        width: 30%;
    }
    .comm-img{
        position: absolute;
        height: 86%;
    }
    .iotimg5{
        position: absolute;
        width: 60%;
    }
    /* feb */
    .imgabs{
        position: absolute;
        width: 100%;
        height: 100%;
    }
    .img1feb{
        position: absolute;
        width: 45%; 
    }
    .img3feb{
        position: absolute;
        width: 79%;
    }
    .img4feb{
        position: absolute;
        right: 120px;
        width: 35%;
    }
    .alingnCentre{
        width: 35%;
        position: relative;
        right: 0px;
    }
    /* march */
    .img1march{
        width: 45%;
        position: absolute;
        top: 40px !important;
        left: 20px !important;
    }
    .img2march{
        width: 45%;
        position: absolute;
        bottom: 40px;
        right: 25px;
    }
    .threemarch{
        width: 80%;
        z-index: 2;
        position: absolute;
    }
    /* april */
    .img1{
        position: absolute;
        width: 70%;
        height: 100px;
    }
    .img2{
        position: absolute;
        width: 35% !important;
        height: 140px;
    }
    .img3{
        position: absolute;
        width: 95% !important;
        height: 200px;
    }
    /* may */
    .img1abs{
        position: absolute;
        width: 44%;
        top: 40px;
        left: 30px;
    }
    .img2abs{
        position: absolute;
        width: 44%;
        bottom: 50px;
        right: 40px;
    }
    .img2may{
        position: relative;
        left: 12px;
        width: 27% !important;
        height: 140px;
    }
    .img3may{
        position: relative;
        left: 0px;
        width: 40% !important;
        height: 100px;
    }
    /* june */
    .img1june{
        width: 74%;
        position: absolute;
    }
    .img2june{
        position: relative;
       left: 0px;
        width: 70%;
    }
    .posts_footer{
        position: absolute;
        width: 100%;
        top: 700px;
    }
    .postTop span{
        color: #ff7200;
        font-size: 0.9rem;
        font-weight: 700;
        line-height: 0px !important;
    }
    .postBottom span{
        color: #fff;
        font-size: 0.8rem;
    }
    .colorWhite{
        color: #fff !important;
        font-size: 0.8rem !important;
        font-weight: 400 ! important;
        letter-spacing: 0px;
        padding-left: 2px;
    }
    .lighter{
        font-size: 1rem !important;
        font-weight:  400 !important;
    }
    .colorOrange{
        color: #ff7200 !important;
        font-size: 0.8rem !important;
    }
    .fontSize{
        font-size: 0.9rem !important;
        font-weight: 700 !important;
    }
    .sevenjan{
        position: absolute;
        width: 50%;
    }
    .eightjan{
        position: absolute;
        width: 50%;
    }
    .oneoct{
        position: absolute;
       width: 80%;
    }
    .twooct{
        position: absolute;
        width: 50%;
    }
    .fouroct{
        position: absolute;
        width: 52%;
    }
    .fiveoct{
        position: absolute;
        width: 65%;
    }
    .sixoct{
        position: absolute;
        width: 30%;
        top: 20px;
        right: 80px;
    }
    .sevenoct{
        position: absolute;
        width: 30%;
        bottom: 20px;
        left: 80px;
    } 
}
@media screen and (min-device-width: 501px) and (max-device-width: 701px){
    .postMain{
        display: none;
    }
    .postMainToggle{
        display: block;
    }
    .twonov{
        width: 35%;
        position: absolute;
        left: 30px;
    }
    .onenov{
        width: 37%;
        position: absolute;
        top: 20px;
    }
    .postsDiv{
        width: 100%;
        height: 760px;
        position: relative;
        top: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #000;
    }
    .postClipped{
        width: 80%;
        height: 80%;
        margin-left: auto;
        margin-right: auto;
        /* background-color: rgb(15, 15, 15); */
        position: relative;
        bottom: 20px;
        border-radius: 10px;
        overflow: auto;
        box-shadow: 0.3px 0px 6px #ff7200;
    }
    .postHeading{
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .postHeading span{
        color: #ff7200;
        font-size: 1.7rem;
        font-weight: 700;
        font-family: 'Fjalla One', sans-serif !important;
    }
    .postsMainDiv{
        width: 100%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
        justify-content: center;
        border-bottom: 1px solid #484848;
    }
    .postsLeft{
        width: 90%;
        height: 330px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: -1px !important;
        position: relative;
    }
    .bgBack{
        position: absolute;
        width: 95%;
        height: 95%;
    }
    .postsRight{
        width: 90%;
        height: auto;
        display: flex;
        padding-top: 30px;
        padding-bottom: 30px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-bottom: 20px;
        position: relative;
        border-radius: 0px !important;
        background-color: #101010;
        padding-top: 38px;
    }
    .monthPost{
        width: 90%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        position: absolute;
        top: 0px;
    }
    .monthPost span{
        color: grey;
        font-size: 0.7rem;
    }
    .postSpecial{
        color: silver !important;
        margin-left: 15px ;
        font-size: 0.8rem !important;
        font-weight: 700;
    }
    
    .postTop{
        width: 90%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
        margin-bottom: 10px;
    }
    .postTop span{
        color: #ff7200;
        font-size: 1rem;
        font-weight: 700;
        line-height: 0px !important;
    }
    .postBottom span{
        color: #fff;
        font-size: 0.9rem;
    }
    .postBottom{
        width: 90%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
    }
    
    .alignRight{
        display: flex;
    }
    /* feb */

    /*Css 2023*/
    .may-car{
        position: absolute;
        width: 60%;
        }
        .person-png{
            position: absolute;
            width: 70%;
        }
        .block-img{
            position: absolute;
            width: 60%;
        }
        .nfc-img{
            position: absolute;
            width: 30%;
        }
        .semicon{
            position: absolute;
            width: 30%;
        }
        .comm-img{
            position: absolute;
            height: 86%;
        }
        .iotimg5{
            position: absolute;
            width: 60%;
        }
    .imgabs{
        position: absolute;
        width: 100%;
        height: 100%;
    }
    .img1feb{
        position: absolute;
        width: 45%; 
    }
    .img3feb{
        position: absolute;
        width: 79%;
    }
    .img4feb{
        position: absolute;
        right: 120px;
        width: 35%;
    }
    .alingnCentre{
        width: 35%;
        position: relative;
        right: 0px;
    }
    /* march */
    .img1march{
        width: 45%;
        position: absolute;
        top: 40px !important;
        left: 20px !important;
    }
    .img2march{
        width: 45%;
        position: absolute;
        bottom: 40px;
        right: 25px;
    }
    .threemarch{
        width: 80%;
        z-index: 2;
        position: absolute;
    }
    /* april */
    .img1{
        position: absolute;
        width: 70%;
        height: 100px;
    }
    .img2{
        position: absolute;
        width: 35% !important;
        height: 140px;
    }
    .img3{
        position: absolute;
        width: 100% !important;
        height: 200px;
    }
    /* may */
    .img1abs{
        position: absolute;
        width: 44%;
        top: 40px;
        left: 30px;
    }
    .img2abs{
        position: absolute;
        width: 44%;
        bottom: 50px;
        right: 40px;
    }
    .img2may{
        position: relative;
        left: 12px;
        width: 27% !important;
        height: 120px;
    }
    .img3may{
        position: relative;
        left: 0px;
        width: 40% !important;
        height: 100px;
    }
    /* june */
    .img1june{
        width: 80%;
        position: absolute;
    }
    .img2june{
        position: relative;
       left: 0px;
        width: 70%;
    }
    .posts_footer{
        position: absolute;
        width: 100%;
        top: 700px;
    }
    .postTop span{
        color: #ff7200;
        font-size: 0.8rem;
        font-weight: 700;
        line-height: 0px !important;
    }
    .postBottom span{
        color: #fff;
        font-size: 0.75rem;
    }
    .colorWhite{
        color: #fff !important;
        font-size: 0.75rem !important;
        font-weight: 400 ! important;
        letter-spacing: 0px;
        padding-left: 2px;
    }
    .lighter{
        font-size: 0.7rem !important;
        font-weight:  400 !important;
    }
    .colorOrange{
        color: #ff7200 !important;
        font-size: 0.72rem !important;
    }
    .fontSize{
        font-size: 0.8rem !important;
        font-weight: 700 !important;
    }
    .sevenjan{
        position: absolute;
        width: 51%;
    }
    .eightjan{
        position: absolute;
        width: 51%;
    }
    .twodec{
        position: absolute;
        width: 45%;
        top: 30px;
        left: 25px;
    }
    .threedec{
        position: absolute;
        width: 32%;
        bottom: 40px;
        left: 40px;
    }
    .fourdec{
        position: absolute;
        width: 35%;
        top: 35px;
        right: 15px;
    }
    .fivedec{
        position: absolute;
        width: 30%;
        bottom:40px;
        right: 30px;
    }
    .sixdec{
        position: absolute;
        width: 54%;
    }
    .fivenov{
        width: 90%;
        position: absolute;
    }
    .polar{
        position: absolute;
        color: #ff7200;
        font-size: 0.9rem;
    }
    .polardown{
        position: absolute;
        bottom: 20px;
        color: #ff7200;
        font-size: 0.9rem;
    }
    .oneoct{
        position: absolute;
       width: 80%;
    }
    .twooct{
        position: absolute;
        width: 50%;
    }
    .fouroct{
        position: absolute;
        width: 52%;
    }
    .fiveoct{
        position: absolute;
        width: 65%;
    }
    .sixoct{
        position: absolute;
        width: 30%;
        top: 20px;
        right: 80px;
    }
    .sevenoct{
        position: absolute;
        width: 30%;
        bottom: 20px;
        left: 80px;
    }
    
}
@media screen and (min-device-width: 401px) and (max-device-width: 501px){
    .postMain{
        display: none;
    }
    .postMainToggle{
        display: block;
    }
    .postsDiv{
        width: 100%;
        height: 760px;
        position: relative;
        top: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #000;
    }
    .postClipped{
        width: 80%;
        height: 80%;
        margin-left: auto;
        margin-right: auto;
        /* background-color: rgb(15, 15, 15); */
        position: relative;
        bottom: 20px;
        border-radius: 10px;
        overflow: auto;
        box-shadow: 0.3px 0px 6px #ff7200;
    }
    .postHeading{
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .postHeading span{
        color: #ff7200;
        font-size: 1.4rem;
        font-weight: 700;
        font-family: 'Fjalla One', sans-serif !important;
    }
    .postsMainDiv{
        width: 100%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
        justify-content: center;
        border-bottom: 1px solid #484848;
    }
    .postsLeft{
        width: 90%;
        height: 280px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: -1px !important;
        position: relative;
    }
    .bgBack{
        position: absolute;
        width: 95%;
        height: 95%;
    }
    .postsRight{
        width: 90%;
        height: auto;
        display: flex;
        padding-top: 30px;
        padding-bottom: 30px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-bottom: 20px;
        position: relative;
        border-radius: 0px !important;
        background-color: #101010;
        padding-top: 38px;
    }
    .monthPost{
        width: 90%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        position: absolute;
        top: 0px;
    }
    .monthPost span{
        color: grey;
        font-size: 0.65rem;
    }
    .postSpecial{
        color: silver !important;
        margin-left: 15px ;
        font-size: 0.7rem !important;
        font-weight: 700;
    }
    
    .postTop{
        width: 90%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
        margin-bottom: 10px;
    }
    .postTop span{
        color: #ff7200;
        font-size: 1rem;
        font-weight: 700;
        line-height: 0px !important;
    }
    .postBottom span{
        color: #fff;
        font-size: 0.9rem;
    }
    .postBottom{
        width: 90%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
    }
    
    .alignRight{
        display: flex;
    }
    /* feb */

    /*Css 2023*/
    .may-car{
        position: absolute;
        width: 60%;
        }
        .person-png{
            position: absolute;
            width: 70%;
        }
        .block-img{
            position: absolute;
            width: 60%;
        }
        .nfc-img{
            position: absolute;
            width: 30%;
        }
        .semicon{
            position: absolute;
            width: 30%;
        }
        .comm-img{
            position: absolute;
            height: 86%;
        }
        .iotimg5{
            position: absolute;
            width: 60%;
        }
    .imgabs{
        position: absolute;
        width: 100%;
        height: 100%;
    }
    .img1feb{
        position: absolute;
        width: 45%; 
    }
    .img3feb{
        position: absolute;
        width: 79%;
    }
    .img4feb{
        position: relative;
        left: 0px;
        width: 35%;
    }
    .alingnCentre{
        width: 35%;
        position: relative;
        right: 0px;
    }
    /* march */
    .img1march{
        width: 45%;
        position: absolute;
        top: 40px !important;
        left: 20px !important;
    }
    .img2march{
        width: 45%;
        position: absolute;
        bottom: 40px;
        right: 25px;
    }
    .threemarch{
        width: 80%;
        z-index: 2;
        position: absolute;
    }
    /* april */
    .img1{
        position: absolute;
        width: 70%;
        height: 80px;
    }
    .img2{
        position: absolute;
        width: 35% !important;
        height: 100px;
    }
    .img3{
        position: absolute;
        width: 100% !important;
        height: 150px;
    }
    /* may */
    .img1abs{
        position: absolute;
        width: 44%;
        top: 40px;
        left: 30px;
    }
    .img2abs{
        position: absolute;
        width: 44%;
        bottom: 50px;
        right: 40px;
    }
    .img2may{
        position: relative;
        left: 12px;
        width: 24% !important;
        height: 80px;
    }
    .img3may{
        position: relative;
        left: 0px;
        width: 35% !important;
        height: 80px;
    }
    /* june */
    .img1june{
        width: 87%;
        position: absolute;
    }
    .img2june{
        position: relative;
       left: 0px;
        width: 80%;
    }
    .posts_footer{
        position: absolute;
        width: 100%;
        top: 700px;
    }
    .postTop span{
        color: #ff7200;
        font-size: 0.75rem;
        font-weight: 700;
        line-height: 0px !important;
    }
    .postBottom span{
        color: #fff;
        font-size: 0.7rem;
    }
    .colorWhite{
        color: #fff !important;
        font-size: 0.7rem !important;
        font-weight: 400 ! important;
        letter-spacing: 0px;
        padding-left: 2px;
    }
    .lighter{
        font-size: 0.7rem !important;
        font-weight:  400 !important;
    }
    .colorOrange{
        color: #ff7200 !important;
        font-size: 0.65rem !important;
    }
    .fontSize{
        font-size: 0.75rem !important;
        font-weight: 700 !important;
    }
    .sevenjan{
        position: absolute;
        width: 54%;
    }
    .eightjan{
        position: absolute;
        width: 54%;
    }
    .twodec{
        position: absolute;
        width: 45%;
        top: 30px;
        left: 25px;
    }
    .threedec{
        position: absolute;
        width: 32%;
        bottom: 40px;
        left: 40px;
    }
    .fourdec{
        position: absolute;
        width: 35%;
        top: 35px;
        right: 15px;
    }
    .fivedec{
        position: absolute;
        width: 30%;
        bottom:40px;
        right: 30px;
    }
    .sixdec{
        position: absolute;
        width: 62%;
    }
    
    .twonov{
        width: 30%;
        position: absolute;
        left: 30px;
    }
    .threenov{
        width: 38%;
        position: absolute;
        top: 60px;
    }
    .fivenov{
        width: 90%;
        position: absolute;
    }
    .polar{
        position: absolute;
        color: #ff7200;
        font-size: 0.8rem;
    }
    .polardown{
        position: absolute;
        bottom: 20px;
        color: #ff7200;
        font-size: 0.8rem;
    }
    .twonov{
        width: 35%;
        position: absolute;
        left: 30px;
    }
    .onenov{
        width: 37%;
        position: absolute;
        top: 20px;
    }
    .oneoct{
        position: absolute;
       width: 95%;
    }
    .twooct{
        position: absolute;
        width: 55%;
    }
    .fouroct{
        position: absolute;
        width: 55%;
    }
    .fiveoct{
        position: absolute;
        width: 80%;
    }
    .sixoct{
        position: absolute;
        width: 34%;
        top: 20px;
        right: 40px;
    }
    .sevenoct{
        position: absolute;
        width: 34%;
        bottom: 20px;
        left: 40px;
    }
    
}
@media screen and (max-device-width: 400px){
    .postMain{
        display: none;
    }
    .postMainToggle{
        display: block;
    }
    .postsDiv{
        width: 100%;
        height: 760px;
        position: relative;
        top: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #000;
    }
    .postClipped{
        width: 85%;
        height: 80%;
        margin-left: auto;
        margin-right: auto;
        /* background-color: rgb(15, 15, 15); */
        position: relative;
        bottom: 20px;
        border-radius: 10px;
        overflow: auto;
        box-shadow: 0.3px 0px 6px #ff7200;
    }
    .postHeading{
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .postHeading span{
        color: #ff7200;
        font-size: 1.3rem;
        font-weight: 700;
        font-family: 'Fjalla One', sans-serif !important;
    }
    .postsMainDiv{
        width: 100%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
        justify-content: center;
        border-bottom: 1px solid #484848;
    }
    .postsLeft{
        width: 90%;
        height: 280px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: -1px !important;
        position: relative;
    }
    .bgBack{
        position: absolute;
        width: 95%;
        height: 95%;
    }
    .postsRight{
        width: 90%;
        height: auto;
        display: flex;
        padding-top: 20px;
        padding-bottom: 20px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-bottom: 20px;
        position: relative;
        border-radius: 0px !important;
        background-color: #101010;
        padding-top: 38px;
    }
    .monthPost{
        width: 90%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        position: absolute;
        top: 0px;
    }
    .monthPost span{
        color: grey;
        font-size: 0.65rem;
    }
    .postSpecial{
        color: silver !important;
        margin-left: 15px ;
        font-size: 0.7rem !important;
        font-weight: 700;
    }
    
    .postTop{
        width: 90%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
        margin-bottom: 10px;
    }
    .postTop span{
        color: #ff7200;
        font-size: 1rem;
        font-weight: 700;
        line-height: 0px !important;
    }
    .postBottom span{
        color: #fff;
        font-size: 0.9rem;
    }
    .postBottom{
        width: 90%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
    }
    
    .alignRight{
        display: flex;
    }
    /* feb */

    /*Css 2023*/
    .may-car{
        position: absolute;
        width: 60%;
        }
        .person-png{
            position: absolute;
            width: 70%;
        }
        .block-img{
            position: absolute;
            width: 60%;
        }
        .nfc-img{
            position: absolute;
            width: 30%;
        }
        .semicon{
            position: absolute;
            width: 30%;
        }
        .comm-img{
            position: absolute;
            height: 86%;
        }
        .iotimg5{
            position: absolute;
            width: 60%;
        }
    .imgabs{
        position: absolute;
        width: 100%;
        height: 100%;
    }
    .img1feb{
        position: absolute;
        width: 45%; 
    }
    .img3feb{
        position: relative;
        left: 10px;
        width: 79%;
    }
    .img4feb{
        position: relative;
        left: 0px;
        width: 35%;
    }
    .alingnCentre{
        width: 35%;
        position: relative;
        right: 0px;
    }
    /* march */
    .img1march{
        width: 45%;
        position: absolute;
        top: 40px !important;
        left: 20px !important;
    }
    .img2march{
        width: 45%;
        position: absolute;
        bottom: 40px;
        right: 25px;
    }
    .threemarch{
        width: 80%;
        z-index: 2;
        position: absolute;
    }
    /* april */
    .img1{
        position: absolute;
        width: 70%;
        height: 80px;
    }
    .img2{
        position: absolute;
        width: 35% !important;
        height: 100px;
    }
    .img3{
        position: absolute;
        width: 100% !important;
        height: 150px;
    }
    /* may */
    .img1abs{
        position: absolute;
        width: 44%;
        top: 40px;
        left: 30px;
    }
    .img2abs{
        position: absolute;
        width: 44%;
        bottom: 50px;
        right: 40px;
    }
    .img2may{
        position: relative;
        left: 12px;
        width: 24% !important;
        height: 80px;
    }
    .img3may{
        position: relative;
        left: 0px;
        width: 35% !important;
        height: 80px;
    }
    /* june */
    .img1june{
        width: 92%;
        position: absolute;
    }
    .img2june{
        position: relative;
       left: 0px;
        width: 82%;
    }
    .posts_footer{
        position: absolute;
        width: 100%;
        top: 700px;
    }
    .postTop span{
        color: #ff7200;
        font-size: 0.7rem;
        font-weight: 700;
        line-height: 0px !important;
    }
    .postBottom span{
        color: #fff;
        font-size: 0.6rem;
    }
    .colorWhite{
        color: #fff !important;
        font-size: 0.6rem !important;
        font-weight: 400 ! important;
        letter-spacing: 0px;
        padding-left: 2px;
    }
    .lighter{
        font-size: 0.7rem !important;
        font-weight:  400 !important;
    }
    .colorOrange{
        color: #ff7200 !important;
        font-size: 0.6rem !important;
    }
    .fontSize{
        font-size: 0.7rem !important;
        font-weight: 700 !important;
    }
    .sevenjan{
        position: absolute;
        width: 55%;
    }
    .eightjan{
        position: absolute;
        width: 55%;
    }
    .twodec{
        position: absolute;
        width: 45%;
        top: 30px;
        left: 25px;
    }
    .threedec{
        position: absolute;
        width: 32%;
        bottom: 40px;
        left: 40px;
    }
    .fourdec{
        position: absolute;
        width: 35%;
        top: 35px;
        right: 15px;
    }
    .fivedec{
        position: absolute;
        width: 30%;
        bottom:40px;
        right: 30px;
    }
    .sixdec{
        position: absolute;
        width: 62%;
    }
    .fivenov{
        width: 97%;
        position: absolute;
    }
    .polar{
        position: absolute;
        color: #ff7200;
        font-size: 0.8rem;
    }
    .polardown{
        position: absolute;
        bottom: 20px;
        color: #ff7200;
        font-size: 0.8rem;
    }
    .twonov{
        width: 35%;
        position: absolute;
        left: 30px;
    }
    .onenov{
        width: 37%;
        position: absolute;
        top: 40px;
    }
    .oneoct{
        position: absolute;
       width: 95%;
    }
    .twooct{
        position: absolute;
        width: 55%;
    }
    .fouroct{
        position: absolute;
        width: 55%;
    }
    .fiveoct{
        position: absolute;
        width: 80%;
    }
    .sixoct{
        position: absolute;
        width: 34%;
        top: 20px;
        right: 40px;
    }
    .sevenoct{
        position: absolute;
        width: 34%;
        bottom: 20px;
        left: 40px;
    }
}