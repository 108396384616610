@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.landing_page{
  font-family: "Montserrat", sans-serif;
}

.section_wrapper{
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  overflow: hidden;
}

.section{
  width: 100%;
}

.custom_button{
  box-shadow: none;
  border: none;
  padding: 0.5rem 1.2rem;
  border-radius: 0.3rem;
  color: #fff;
  font-weight: 600;
  width: max-content;
}

.custom_button:hover{
  box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.6);
}

.filled_gray{
  background: #4A4A4A;
}

.filled_orange{
  background: #FF7700;
}

@media (max-width: 980px) {
  .section_wrapper{
    margin-top: 0px;
  }
}