body {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    overflow-x: hidden;
}
::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-thumb {
    background-color: #ff7200;
    border-radius: 7px;

}
#scrolling_to_top {
    background-color: transparent;
    z-index: 100;
    width: 35px;
    box-shadow: 1px 1px 4px black;
    height: 35px;
    background: rgb(194, 189, 189) no-repeat;
}
#scrolling_to_top:hover {
    background-color: #ff7200;
}
svg {
    vertical-align: middle;
    width: 22px;
    cursor: pointer;
}
svg:hover {
    width: 27px;
}
.carouselElem .swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
    top: 0px !important;
    right: 0px !important;
    left: 0px !important;
}


@media screen and (min-device-width: 1751px) {
    .knowledgeHeading{
        width: 100% ;
        position: relative ;
        margin-left: auto;
        margin-right: auto;
        top: 70px;
        height: 110px ;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #F5F5F5;
    }
    .knowText{
        font-size: 2.9rem;
        color: #000;
    }
    .knowSpecialText{
        font-size: 3.4rem;
        font-weight: 700;
        color: #ff7200;
    }
    .carouselElem{
        width: 100% !important;
        height: 530px !important;
        position: relative ;
        top: 70px !important;
        display: flex;
        background-color: #F5F5F5;
    }
    
    .carouselElem .swiper-slide.swiper-slide-prev{
        width: 410px !important ;
        height: 340px !important;
        position: relative;
        top: 40px;
        border-radius: 5px;
        cursor: pointer;
    }
    .carouselElem .swiper-slide.swiper-slide-active{
        width: 410px !important ;
        height: 340px !important;
        position: relative;
        top: 40px;
        border-radius: 5px;
        cursor: pointer;
    }
    .carouselElem .swiper-slide.swiper-slide-next{
        width: 410px !important;
        height: 340px !important;
        position: relative;
        top: 40px !important;
        border-radius: 5px;
        cursor: pointer;
    }
    .carouselElem .swiper-slide{
        width: 410px !important ;
        height: 340px !important;
        border-radius: 5px;
        cursor: pointer;
    }
    .carouselElem .swiper-slide:hover{
        cursor: pointer;
        box-shadow: 0px 0px 12px #484848;
    }
    .knowItems{
        width: 100%;
        height: 100%;
    }
    .knowSize{
        position: absolute !important;
        top: 0px !important;
        left: 0px !important;
        width: 100% !important;
        height: 100% !important;
        border-radius: 5px;
    }
    .knowInnerItems{
        position: relative;
        width: 100%  !important;
        height: 60%  !important;
        display: flex !important;
        flex-direction: column ! important;
        align-items: center !important;
        justify-content: space-evenly !important;
        text-align: center !important;
    }

    .swiper-slide .knowItems .knowInnerItems img{
        left: 0px !important;
        margin-left: 12px  !important;
        margin-top: -17px  !important;
        width: 70px;
        height: 70px;
    }
    .knowInnerItems span{
        color: #fff;
        font-size: 1.5rem;
        width: 80%;
        margin-left: 22px;
        letter-spacing: 0.3px;
    }
    .knowMore{
        position: relative;
        width: 100%;
        height: 40% !important;
        margin-left: auto;
        margin-right: auto;
        display: flex;
    }
    .knowmore2{
        width: 100%;
        height: 60% !important;
        display: flex !important;
        justify-content: flex-end !important;
        align-items: center !important;
    }
    .knowmore2 span{
        color: #ff7200;
        font-size: 1rem;
        font-weight: 500;
        letter-spacing: 0.7px;
        cursor: pointer;

    }
    .knowRightArrow{
        color: #ff7200;
        margin-right: 30px;
    }
    .carouselElem .swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
        position: absolute;
        left: 0px !important;
        bottom: 87px !important;
        width: 100% !important;
        justify-content: space-between;
    }
    .carouselElem .swiper-pagination-bullet {
        width: 60px !important;
        height: 30px !important;
        text-align: center;
        line-height: 20px !important;
        font-size: 1.05rem !important;
        color: #000;
        opacity: 1;
        background-color: transparent;
      }
      
    .carouselElem  .swiper-pagination-bullet-active {
        color: #000;
        font-weight: 800;
        color: #ff7200;
        font-size: 12px;
        background-color: transparent;
      }
    .carouselElem .swiper-button-prev{
        color: #ff7200;
        position: absolute;
        top: 170px !important;
        left: 0px !important;
        display: block !important;
        z-index: 100;
    }
    .carouselElem .swiper-button-next{
        color: #ff7200;
        position: absolute;
        top: 170px !important;
        right: 0px !important;
        display: block !important;
        z-index: 100;
    }
    .knowledge_footer {
        position: absolute;
        width: 100%;
        top: 655px;
    }
        
}
@media screen and (min-device-width: 1551px) and (max-device-width: 1751px){
    .knowledgeHeading{
        width: 100% ;
        position: relative ;
        margin-left: auto;
        margin-right: auto;
        top: 70px;
        height: 110px ;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #F5F5F5;
    }
    .knowText{
        font-size: 2.9rem;
        color: #000;
    }
    .knowSpecialText{
        font-size: 3.4rem;
        font-weight: 700;
        color: #ff7200;
    }
    .carouselElem{
        width: 100%;
        height: 510px !important;
        position: relative ;
        top: 70px !important;
        display: flex;
        background-color: #F5F5F5;
    }
    
    .carouselElem .swiper-slide.swiper-slide-prev{
        width: 380px !important ;
        height: 320px !important;
        position: relative;
        top: 40px !important;
        border-radius: 5px;
        cursor: pointer;
    }
    .carouselElem .swiper-slide.swiper-slide-active{
        width: 380px !important ;
        height: 320px !important;
        position: relative;
        top: 40px !important;
        border-radius: 5px;
        cursor: pointer;
    }
    .carouselElem .swiper-slide.swiper-slide-next{
        width: 380px !important;
        height: 320px !important;
        position: relative;
        top: 40px !important;
        border-radius: 5px;
        cursor: pointer;
    }
    .carouselElem .swiper-slide{
        width: 380px !important ;
        height: 320px !important;
        border-radius: 5px;
        cursor: pointer;
    }
    .carouselElem .swiper-slide:hover{
        cursor: pointer;
        box-shadow: 0px 0px 12px #484848;
    }
    .knowItems{
        width: 100% ! important;
        height: 100%  ! important;
    }
    .knowSize{
        position: absolute !important;
        top: 0px !important;
        left: 0px !important;
        width: 100% !important;
        height: 100% !important;
        border-radius: 5px;
    }
    .knowInnerItems{
        position: relative;
        width: 100%  !important;
        height: 60%  !important;
        display: flex !important;
        flex-direction: column ! important;
        align-items: center !important;
        justify-content: space-evenly !important;
        text-align: center !important;
    }
    .swiper-slide .knowItems .knowInnerItems img{
        margin-left: 12px  !important;
        margin-top: -17px  !important;
        width: 72px;
        height: 72px;
        position: relative;
        top: 10px !important;
        left: 0px !important;
    }
    .knowInnerItems span{
        color: #fff;
        width: 80%;
        font-size: 1.5rem;
        letter-spacing: 0.3px;
    }
    .knowMore{
        position: relative;
        width: 100%;
        height: 40% !important;
        margin-left: auto;
        margin-right: auto;
        display: flex !important;
        justify-content: flex-end !important;
        align-items: center !important;
    }
    .knowmore2{
        width: 100%;
        height: 60%;
        display: flex !important;
        justify-content: flex-end !important;
        align-items: center !important;
    }
    .knowmore2 span{
        color: #ff7200;
        font-size: 1rem;
        font-weight: 500;
        letter-spacing: 0.7px;
        cursor: pointer;
    }
    .knowRightArrow{
        color: #ff7200;
        margin-right: 30px;
    }
    .carouselElem .swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
        position: absolute;
        left: 0px !important;
        bottom: 87px !important ;
        width: 100% !important;
        justify-content: space-between;
    }
    .carouselElem .swiper-pagination-bullet {
        width: 60px !important;
        height: 30px;
        text-align: center;
        line-height: 20px;
        font-size: 1rem !important;
        color: #000;
        opacity: 1;
        background-color: transparent;
      }
      
    .carouselElem  .swiper-pagination-bullet-active {
        color: #000;
        font-weight: 800;
        color: #ff7200;
        font-size: 12px;
        background-color: transparent;
      }
    .carouselElem .swiper-button-prev{
        color: #ff7200;
        position: absolute;
        top: 160px !important;
        left: 0px !important;
        display: block !important;
        z-index: 100;
    }
    .carouselElem .swiper-button-next{
        color: #ff7200;
        position: absolute;
        top: 160px !important;
        right: 0px !important;
        display: block !important;
        z-index: 100;
    }
    .knowledge_footer {
        position: absolute;
        width: 100%;
        top: 630px;
    }
        
}
@media screen and (min-device-width: 1351px) and (max-device-width: 1551px){
    .knowledgeHeading{
        width: 100% ;
        position: relative ;
        margin-left: auto;
        margin-right: auto;
        top: 70px;
        height: 110px ;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #F5F5F5;
    }
    .knowText{
        font-size: 2.9rem;
        color: #000;
    }
    .knowSpecialText{
        font-size: 3.4rem;
        font-weight: 700;
        color: #ff7200;
    }
    .carouselElem{
        width: 100%;
        height: 480px !important;
        position: relative ;
        top: 70px !important;
        display: flex;
        background-color: #F5F5F5;
    }
    
    .carouselElem .swiper-slide.swiper-slide-prev{
        width: 360px !important ;
        height: 300px !important;
        position: relative;
        top: 40px !important;
        border-radius: 5px;
        cursor: pointer;
    }
    .carouselElem .swiper-slide.swiper-slide-active{
        width: 360px !important ;
        height: 300px !important;
        position: relative;
        top: 40px !important;
        border-radius: 5px;
        cursor: pointer;
    }
    .carouselElem .swiper-slide.swiper-slide-next{
        width: 360px !important;
        height: 300px !important;
        position: relative;
        top: 40px;
        border-radius: 5px;
        cursor: pointer;
    }
    .carouselElem .swiper-slide{
        width: 360px !important ;
        height: 340px !important;
        border-radius: 5px;
        cursor: pointer;
    }
    .carouselElem .swiper-slide:hover{
        cursor: pointer;
        box-shadow: 0px 0px 12px #484848;
    }
    .knowItems{
        width: 100%  ! important;
        height: 100%  ! important;
    }
    .knowSize{
        position: absolute !important;
        top: 0px !important;
        left: 0px !important;
        width: 100% !important;
        height: 100% !important;
        border-radius: 5px;
    }
    .knowInnerItems{
        position: relative;
        width: 100%  !important;
        height: 60%  !important;
        display: flex !important;
        flex-direction: column ! important;
        align-items: center !important;
        justify-content: space-evenly !important;
        text-align: center !important;
    }
    .swiper-slide .knowItems .knowInnerItems img{
        position: relative;
        top: 14px  !important;
        width: 75px  !important;
        height: 80px  !important;
        left: 0px !important;
    }
    .knowInnerItems span{
        color: #fff;
        position: relative;
        top: 10px;
        width: 80%;
        font-size: 1.4rem;
        letter-spacing: 0.3px;
    }
    .knowMore{
        position: relative;
        width: 100%;
        height: 40% !important;
        margin-left: auto;
        margin-right: auto;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }
    .knowmore2{
        width: 100%;
        height: 60%;
        display: flex !important;
        justify-content:flex-end !important;
        align-items: center !important;
    }
    .knowmore2 span{
        color: #ff7200;
        font-size: 0.9rem;
        font-weight: 500;
        letter-spacing: 0.7px;
        cursor: pointer;
    }
    .knowRightArrow{
        color: #ff7200;
        margin-right: 30px;
    }
    .carouselElem .swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
        position: absolute;
        left: 0;
        bottom: 0px !important;
        width: 100%;
        justify-content: space-between;
    }
    .carouselElem .swiper-pagination-bullet {
        width: 60px !important;
        height: 30px;
        text-align: center;
        line-height: 20px;
        font-size: 1rem !important;
        color: #000;
        opacity: 1;
        background-color: transparent;
      }
      
    .carouselElem  .swiper-pagination-bullet-active {
        color: #000;
        font-weight: 800;
        color: #ff7200;
        font-size: 12px;
        background-color: transparent;
      }
    .carouselElem .swiper-button-prev{
        color: #ff7200;
        position: absolute;
        top: 175px !important;
        display: block !important;
        left: 0px !important;
        z-index: 100;
    }
    .carouselElem .swiper-button-next{
        color: #ff7200;
        position: absolute;
        top: 175px !important;
        display: block !important;
        right: 0px !important;
        z-index: 100;
    }
   
    .knowledge_footer {
        position: absolute;
        width: 100%;
        top: 610px;
       
    }
        
}
@media screen and (min-device-width: 1101px) and (max-device-width: 1351px){
    .knowledgeHeading{
        width: 100% ;
        position: relative ;
        margin-left: auto;
        margin-right: auto;
        top: 70px;
        height: 110px ;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #F5F5F5;
    }
    .knowText{
        font-size: 2rem;
        color: #000;
    }
    .knowSpecialText{
        font-size: 2.4rem;
        font-weight: 700;
        color: #ff7200;
    }
    .carouselElem{
        width: 100%;
        height: 420px !important;
        position: relative ;
        top: 70px !important;
        display: flex;
        background-color: #F5F5F5;
    }
    
    .carouselElem .swiper-slide.swiper-slide-prev{
        width: 250px !important ;
        height: 240px !important;
        position: relative;
        top: 0px;
        border-radius: 5px;
        cursor: pointer;
    }
    .carouselElem .swiper-slide.swiper-slide-active{
        width: 250px !important ;
        height: 240px !important;
        position: relative;
        top: 40px;
        border-radius: 5px;
        cursor: pointer;
    }
    .carouselElem .swiper-slide.swiper-slide-next{
        width: 250px  !important;
        height: 240px !important;
        position: relative;
        top: 0px;
        border-radius: 5px;
        cursor: pointer;
    }
    .carouselElem .swiper-slide{
        width: 250px !important ;
        height: 240px !important;
        position: relative;
        top: 0px;
        border-radius: 5px;
        cursor: pointer;
    }
    .carouselElem .swiper-slide:hover{
        cursor: pointer;
        box-shadow: 0px 0px 12px #484848;
    }
    .knowItems{
        width: 100%  ! important;
        height: 100%  ! important;
    }
    .knowSize{
        position: absolute !important;
        top: 0px !important;
        left: 0px !important;
        width: 100% !important;
        height: 100% !important;
        border-radius: 5px;
    }
    .knowInnerItems{
        position: relative;
        width: 100%  !important;
        height: 60%  !important;
        display: flex !important;
        flex-direction: column ! important;
        align-items: center !important;
        justify-content: space-evenly !important;
        text-align: center !important;
    }
    .swiper-slide .knowItems .knowInnerItems img{
        width: 60px !important;
        height: 60px !important;
        position: relative;
        top: 10px !important;
        left: 0px !important;
    }
    .knowInnerItems span{
        color: #fff;
        font-size: 1.1rem !important;
        margin-left: 22px;
        letter-spacing: 0.3px;
        width: 80%;
        position: relative;
        top: 8px;
    }
    .knowMore{
        position: relative;
        width: 100%;
        height: 40% !important;
        margin-left: auto;
        margin-right: auto;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }
    .knowmore2{
        width: 100%;
        height: 60%;
        display: flex !important;
        justify-content: flex-end !important;
        align-items: center !important;
    }
    .knowmore2 span{
        color: #ff7200;
        font-size: 0.6rem;
        font-weight: 500;
        letter-spacing: 0.7px;
        cursor: pointer;
    }
    .knowRightArrow{
        color: #ff7200;
        margin-right: 29px;
    }
    .carouselElem .swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
        position: absolute;
        left: 0 !important;
        bottom: 70px !important;
        width: 100%;
        justify-content: space-between;
    }
    .carouselElem .swiper-pagination-bullet {
        width: 60px !important;
        height: 30px;
        text-align: center;
        line-height: 20px;
        font-size: 1rem !important;
        color: #000;
        opacity: 1;
        background-color: transparent;
      }
      
    .carouselElem  .swiper-pagination-bullet-active {
        color: #000;
        font-weight: 800;
        color: #ff7200;
        font-size: 12px;
        background-color: transparent;
      }
    .carouselElem .swiper-button-prev{
        color: #ff7200;
        position: absolute;
        top: 125px !important;
        display: block !important;
        left: 0px !important;
        z-index: 100;
    }
    .carouselElem .swiper-button-next{
        color: #ff7200;
        position: absolute;
        top: 125px !important;
        display: block !important;
        right: 0px !important;
        z-index: 100;
    }
    .knowledge_footer {
        position: absolute;
        width: 100%;
        top: 550px;
    }
    
}
@media screen and (min-device-width: 850px) and (max-device-width: 1100px){
    .knowledgeHeading{
        width: 100% ;
        position: relative ;
        margin-left: auto;
        margin-right: auto;
        top: 70px;
        height: 110px ;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #F5F5F5;
    }
    .knowText{
        font-size: 2rem;
        color: #000;
        position: relative;
        bottom: 10px;
    }
    .knowSpecialText{
        font-size: 2.3rem !important;
        font-weight: 700;
        color: #ff7200;
    }
    .carouselElem{
        width: 100%;
        height: 420px !important;
        position: relative ;
        top: 70px !important;
        display: flex;
        background-color: #F5F5F5;
    }
    
    .carouselElem .swiper-slide.swiper-slide-prev{
        width: 250px !important ;
        height: 230px !important;
        position: relative;
        top: 0px !important;
        border-radius: 5px;
        cursor: pointer;
    }
    .carouselElem .swiper-slide.swiper-slide-active{
        width: 250px !important ;
        height: 230px !important;
        position: relative;
        top: 40px !important;
        border-radius: 5px;
        cursor: pointer;
    }
    .carouselElem .swiper-slide.swiper-slide-next{
        width: 250px !important ;
        height: 230px !important;
        position: relative;
        top: 0px !important;
        border-radius: 5px;
        cursor: pointer;
    }
    .carouselElem .swiper-slide{
        width: 250px !important ;
        height: 230px !important;
        position: relative;
        top: 0px !important;
        border-radius: 5px;
        cursor: pointer;
    }
    .carouselElem .swiper-slide:hover{
        cursor: pointer;
        box-shadow: 0px 0px 12px #484848;
    }
    .knowItems{
        width: 100%  ! important;
        height: 100%  ! important;
    }
    .knowSize{
        position: absolute !important;
        top: 0px !important;
        left: 0px !important;
        width: 100% !important;
        height: 100% !important;
        border-radius: 5px;
    }
    .knowInnerItems{
        position: relative;
        width: 100%  !important;
        height: 60%  !important;
        display: flex !important;
        flex-direction: column ! important;
        align-items: center !important;
        justify-content: space-evenly !important;
        text-align: center !important;
    }
    .swiper-slide .knowItems .knowInnerItems img{
        width: 46px  !important;
        height: 46px  !important;
        position: relative;
        top: 10px  !important;
        left: 0px !important;
    }
    .knowInnerItems span{
        color: #fff;
        font-size: 1.1rem;
        letter-spacing: 0.3px;
        width: 80%;
    }
    .knowMore{
        position: relative;
        width: 100%;
        height: 40% !important;
        margin-left: auto;
        margin-right: auto;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }
    .knowmore2{
        width: 100%;
        height: 60%;
        display: flex;
        justify-content: flex-end !important;
        align-items: center !important;
    }
    .knowmore2 span{
        color: #ff7200;
        font-size: 0.6rem;
        font-weight: 500;
        letter-spacing: 0.7px;
        cursor: pointer;
    }
    .knowRightArrow{
        color: #ff7200;
        margin-right: 24px;
    }
    .carouselElem .swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
        position: absolute;
        left: 0 !important;
        bottom: 85px !important;
        width: 100% !important;
        justify-content: space-between;
    }
    .carouselElem .swiper-pagination-bullet {
        width: 60px !important;
        height: 30px;
        text-align: center;
        line-height: 20px;
        font-size: 1rem !important;
        color: #000;
        opacity: 1;
        background-color: transparent;
      }
      
    .carouselElem  .swiper-pagination-bullet-active {
        color: #000;
        font-weight: 800;
        color: #ff7200;
        font-size: 12px;
        background-color: transparent;
      }
    .carouselElem .swiper-button-prev{
        color: #ff7200;
        position: absolute;
        top: 125px !important;
        display: block !important;
        left: 0px !important;
        z-index: 100;
    }
    .carouselElem .swiper-button-next{
        color: #ff7200;
        position: absolute;
        top: 125px !important;
        display: block !important;
        right: 0px !important;
        z-index: 100;
    }
    .knowledge_footer {
        position: absolute;
        width: 100%;
        top: 550px;
    }
    
}
@media screen and (min-device-width: 651px) and (max-device-width: 850px){
    .knowledgeHeading{
        width: 100% ;
        position: relative ;
        margin-left: auto;
        margin-right: auto;
        top: 70px;
        height: 110px ;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #F5F5F5;
    }
    .knowText{
        font-size: 1.6rem;
        color: #000;
        position: relative;
        bottom: 10px;
    }
    .knowSpecialText{
        font-size: 2rem !important;
        font-weight: 700;
        color: #ff7200;
    }
    .carouselElem{
        width: 100%;
        height: 420px !important;
        position: relative ;
        top: 70px !important;
        display: flex;
        background-color: #F5F5F5;
    }
    
    .carouselElem .swiper-slide.swiper-slide-prev{
        width: 210px !important ;
        height: 230px !important;
        position: relative;
        top: 0px !important;
        border-radius: 5px;
        cursor: pointer;
    }
    .carouselElem .swiper-slide.swiper-slide-active{
        width: 210px !important ;
        height: 230px !important;
        position: relative;
        top: 40px !important;
        border-radius: 5px;
        cursor: pointer;
    }
    .carouselElem .swiper-slide.swiper-slide-next{
        width: 210px !important ;
        height: 230px !important;
        position: relative;
        top: 0px !important;
        border-radius: 5px;
        cursor: pointer;
    }
    .carouselElem .swiper-slide{
        width: 210px !important ;
        height: 230px !important;
        position: relative;
        top: 0px !important;
        border-radius: 5px;
        cursor: pointer;
    }
    .carouselElem .swiper-slide:hover{
        cursor: pointer;
        box-shadow: 0px 0px 12px #484848;
    }
    .knowItems{
        width: 100%  ! important;
        height: 100%  ! important;
    }
    .knowSize{
        position: absolute !important;
        top: 0px !important;
        left: 0px !important;
        width: 100% !important;
        height: 100% !important;
        border-radius: 5px;
    }
    .knowInnerItems{
        position: relative;
        width: 100%  !important;
        height: 60%  !important;
        display: flex !important;
        flex-direction: column ! important;
        align-items: center !important;
        justify-content: space-evenly !important;
        text-align: center !important;
    }
    .swiper-slide .knowItems .knowInnerItems img{
        position: relative;
        top: 10px  !important;
        width: 42px  !important;
        height: 42px  !important;
        left: 0px !important;
    }
    .knowInnerItems span{
        color: #fff;
        font-size: 1rem;
        letter-spacing: 0.3px;
        width: 80%;
    }
    .knowMore{
        position: relative;
        width: 100%;
        height: 40% !important;
        margin-left: auto;
        margin-right: auto;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }
    .knowmore2{
        width: 100%;
        height: 60%;
        display: flex !important;
        justify-content: flex-end !important;
        align-items: center !important;
    }
    .knowmore2 span{
        color: #ff7200;
        font-size: 0.6rem;
        font-weight: 500;
        letter-spacing: 0.7px;
        cursor: pointer;
    }
    .knowRightArrow{
        color: #ff7200;
        margin-right: 18px;
    }
    .carouselElem .swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
        position: absolute;
        left: 0;
        bottom: 81px !important;
        width: 100% !important;
        justify-content: space-between !important;
    }
    .carouselElem .swiper-pagination-bullet {
        width: 49px !important;
        height: 30px !important;
        text-align: center;
        line-height: 20px !important;
        font-size: 0.87rem !important;
        color: #000;
        opacity: 1;
        background-color: transparent;
      }
      
    .carouselElem  .swiper-pagination-bullet-active {
        color: #000;
        font-weight: 800;
        color: #ff7200;
        font-size: 12px;
        background-color: transparent;
      }
    .carouselElem .swiper-button-prev{
        color: #ff7200;
        position: absolute;
        top: 125px !important;
        display: block !important;
        left: 0px !important;
        z-index: 100;
    }
    .carouselElem .swiper-button-next{
        color: #ff7200;
        position: absolute;
        top: 125px !important;
        display: block !important;
        right: 0px !important;
        z-index: 100;
    }
    .knowledge_footer {
        position: absolute;
        width: 100%;
        top: 550px;
    }
    
}
@media screen and (min-device-width: 501px) and (max-device-width: 651px){
    .knowledgeHeading{
        width: 100% ;
        position: relative ;
        margin-left: auto;
        margin-right: auto;
        top: 70px;
        height: 110px ;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #F5F5F5;
    }
    .knowText{
        font-size: 1.5rem;
        color: #000;
        position: relative;
        bottom: 10px;
    }
    .knowSpecialText{
        font-size: 1.8rem !important;
        font-weight: 700;
        color: #ff7200;
    }
    .carouselElem{
        width: 100%;
        height: 400px !important;
        position: relative ;
        top: 70px !important;
        display: flex;
        background-color: #F5F5F5;
    }
    
    .carouselElem .swiper-slide.swiper-slide-prev{
        width: 190px !important ;
        height: 200px !important;
        position: relative;
        top: 0px !important;
        border-radius: 5px;
        cursor: pointer;
    }
    .carouselElem .swiper-slide.swiper-slide-active{
        width: 190px !important ;
        height: 200px !important;
        position: relative;
        top: 40px !important;
        border-radius: 5px;
        cursor: pointer;
    }
    .carouselElem .swiper-slide.swiper-slide-next{
        width: 190px !important ;
        height: 200px !important;
        position: relative;
        top: 0px !important;
        border-radius: 5px;
        cursor: pointer;
    }
    .carouselElem .swiper-slide{
        width: 190px !important ;
        height: 200px !important;
        position: relative;
        top: 0px !important;
        border-radius: 5px;
        cursor: pointer;
    }
    .carouselElem .swiper-slide:hover{
        cursor: pointer;
        box-shadow: 0px 0px 12px #484848;
    }
    .knowItems{
        width: 100%  ! important;
        height: 100%  ! important;
    }
    .knowSize{
        position: absolute !important;
        top: 0px !important;
        left: 0px !important;
        width: 100% !important;
        height: 100% !important;
        border-radius: 5px;
    }
    .knowInnerItems{
        position: relative;
        width: 100%;
        height: 60%;
        display: flex !important;
        flex-direction: column ! important;
        align-items: center !important;
        justify-content: space-evenly !important;
        text-align: center !important;
    }
    .swiper-slide .knowItems .knowInnerItems img{
        position: relative;
        top: 10px  !important;
        width: 40px  !important;
        height: 40px  !important;
        left: 0px !important;
    }
    .knowInnerItems span{
        color: #fff;
        font-size: 0.8rem;
        letter-spacing: 0.3px;
        position: relative;
        width: 80%;
        top: 7px;
    }
    .knowMore{
        position: relative;
        width: 100%;
        height: 40% !important;
        margin-left: auto;
        margin-right: auto;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }
    .knowmore2{
        width: 100%;
        height: 60%;
        display: flex !important;
        justify-content: flex-end !important;
        align-items: center !important;
    }
    .knowmore2 span{
        color: #ff7200;
        font-size: 0.6rem;
        font-weight: 500;
        letter-spacing: 0.7px;
        cursor: pointer;
    }
    .knowRightArrow{
        color: #ff7200;
        margin-right: 13px;
    }
    .carouselElem .swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
        position: absolute;
        left: 0 !important;
        bottom: 103px !important;
        width: 100%;
        justify-content: space-between !important;
    }
    .carouselElem .swiper-pagination-bullet {
        width: 44px !important;
        height: 24px;
        text-align: center;
        line-height: 20px;
        font-size: 0.8rem !important;
        color: #000;
        opacity: 1;
        background-color: transparent;
      }
      
    .carouselElem  .swiper-pagination-bullet-active {
        color: #000;
        font-weight: 800;
        color: #ff7200;
        font-size: 12px;
        background-color: transparent;
      }
    .carouselElem .swiper-button-prev{
        color: #ff7200;
        position: absolute;
        top: 110px !important;
        left: 0px !important;
        display: block !important;
        z-index: 100 !important;
    }
    .carouselElem .swiper-button-next{
        color: #ff7200;
        position: absolute;
        top: 110px !important;
        right: 0px !important;
        display: block !important;
        z-index: 100 !important;
    }
    .knowledge_footer {
        position: absolute;
        width: 100%;
        top: 520px;
    }
    
}
@media screen and (min-device-width: 401px) and (max-device-width: 501px){
    .knowledgeHeading{
        width: 100% !important;
        position: relative !important;
        margin-left: auto !important;
        margin-right: auto!important;
        top: 70px!important;
        height: 110px !important;
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        justify-content: center !important;
        background-color: #F5F5F5;
    }
    .knowText{
        font-size: 1.4rem !important;
        color: #000 !important;
        position: relative !important;
        bottom: 10px !important;
    }
    .knowSpecialText{
        font-size: 1.7rem !important;
        font-weight: 700;
        color: #ff7200;
    }
    .carouselElem{
        width: 100%;
        height: 380px !important;
        position: relative ;
        top: 70px !important;
        display: flex !important;
        background-color: #F5F5F5 !important;
    }
    
    .carouselElem .swiper-slide.swiper-slide-prev{
        width: 180px !important ;
        height: 190px !important;
        position: relative !important;
        top: 0px !important;
        border-radius: 5px !important;
        cursor: pointer;
    }
    .carouselElem .swiper-slide.swiper-slide-active{
        width: 180px !important ;
        height: 190px !important;
        position: relative !important;
        top: 40px !important;
        border-radius: 5px !important;
        cursor: pointer;
    }
    .carouselElem .swiper-slide.swiper-slide-next{
        width: 180px !important ;
        height: 190px !important;
        position: relative;
        top: 0px !important;
        border-radius: 5px !important;
        cursor: pointer;
    }
    .carouselElem .swiper-slide{
        width: 180px !important ;
        height: 190px !important;
        position: relative;
        top: 0px !important;
        border-radius: 5px !important;
        cursor: pointer;
    }
    .carouselElem .swiper-slide:hover{
        cursor: pointer;
        box-shadow: 0px 0px 12px #484848;
    }
    .knowItems{
        width: 100%  ! important;
        height: 100%  ! important;
    }
    .knowSize{
        position: absolute !important;
        top: 0px !important;
        left: 0px !important;
        width: 100% !important;
        height: 100% !important;
        border-radius: 5px;
    }
    .knowInnerItems{
        position: relative;
        width: 100%;
        height: 55%  !important;
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        justify-content: space-evenly !important;
        text-align: center !important;
    }
    .swiper-slide .knowItems .knowInnerItems img{
        position: relative;
        top: 10px  !important;
        width: 38px  !important;
        height: 38px  !important;
        left: 0px !important;
    }
    .knowInnerItems span{
        color: #fff;
        font-size: 0.6rem !important;
        position: relative;
        top: 12px !important;
        width: 80%;
        letter-spacing: 0px;
    }
    .knowMore{
        position: relative;
        width: 100%;
        height: 40% !important;
        margin-left: auto !important;
        margin-right: auto !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }
    .knowmore2{
        width: 100% !important;
        height: 60% !important;
        display: flex !important;
        justify-content: flex-end !important;
        align-items: center !important;
    }
    .knowmore2 span{
        color: #ff7200;
        font-size: 9px !important;
        font-weight: 500 !important;
        letter-spacing: 0.7px;
        cursor: pointer;
    }
    .knowRightArrow{
        color: #ff7200;
        margin-right: 13px;
    }
    .carouselElem .swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
        position: absolute;
        top: 80% !important;
        left: 0 !important;
        bottom: 1rem !important ;
        width: 100% !important;
        justify-content: space-between;
        padding: 0 4rem;
    }
    .carouselElem .swiper-pagination-bullet {
        width: 1rem !important;
        height: 1rem !important;
        text-align: center;
        line-height: 20px !important;
        font-size: 0.8rem !important;
        color: #000;
        opacity: 1;
        background-color: transparent;
      }
      
    .carouselElem  .swiper-pagination-bullet-active {
        color: #000;
        font-weight: 800;
        color: #ff7200;
        font-size: 12px;
        background-color: transparent;
      }
    .carouselElem .swiper-button-prev{
        color: #ff7200;
        position: absolute;
        top: 105px !important;
        left: 0px !important;
        display: block !important;
    }
    .carouselElem .swiper-button-next{
        color: #ff7200;
        position: absolute;
        top: 105px !important;
        right: 0px !important;
        display: block !important;
    }
    .knowledge_footer {
        position: absolute;
        width: 100%;
        top: 525px !important;
    }
    
}
@media only screen and (max-width: 401px) {  
    .knowledgeHeading{
        width: 100% !important;
        position: relative !important;
        margin-left: auto !important;
        margin-right: auto !important;
        top: 70px !important;
        height: 110px !important;
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        justify-content: center !important;
        background-color: #F5F5F5 !important;
        }
    .knowText{
        font-size: 1.4rem !important;
        color: #000 !important;
        position: relative;
        bottom: 10px !important;
    }
    .knowSpecialText{
        font-size: 1.7rem !important;
        font-weight: 700;
        color: #ff7200;
    }
    .carouselElem{
        width: 100%;
        height: 365px !important;
        position: relative ;
        top: 70px !important;
        display: flex !important;
        background-color: #F5F5F5;
    }
    .carouselElem .swiper-slide.swiper-slide-prev{
        width: 170px !important ;
        height: 185px !important;
        position: relative !important;
        top: 0px !important;
        border-radius: 5px;
        cursor: pointer;
    }
    .carouselElem .swiper-slide.swiper-slide-active{
        width: 170px !important ;
        height: 185px !important;
        position: relative !important;
        top: 40px !important;
        border-radius: 5px;
        cursor: pointer;
    }
    .carouselElem .swiper-slide.swiper-slide-next{
        width: 170px !important ;
        height: 185px !important;
        position: relative;
        top: 0px !important;
        border-radius: 5px;
        cursor: pointer;
    }
    .carouselElem .swiper-slide{
        width: 170px !important ;
        height: 185px !important;
        position: relative;
        top: 0px !important;
        border-radius: 5px;
        cursor: pointer;
    }
    .carouselElem .swiper-slide:hover{
        cursor: pointer;
        box-shadow: 0px 0px 12px #484848;
    }
    .knowItems{
        width: 100%  ! important;
        height: 100%  ! important;
    }
    .knowSize{
        position: absolute !important;
        top: 0px !important;
        left: 0px !important;
        width: 100%  !important;
        height: 100%  !important;
        border-radius: 5px;
    }
    .knowInnerItems{
        position: relative;
        width: 100%  !important;
        height: 60%  !important;
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        justify-content: space-evenly !important;
        text-align: center !important;
    }
    .swiper-slide .knowItems .knowInnerItems img{
       position: relative;
       top: 10px !important;
        width: 37px  !important;
        height: 37px  !important;
        left: 0px !important;
    }
    .knowInnerItems span{
        color: #fff;
        font-size: 0.7rem !important;
        width: 80%;
        letter-spacing: 0px;
    }
    .knowMore{
        position: relative;
        width: 100%;
        height: 40% !important;
        margin-left: auto;
        margin-right: auto;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }
    .knowmore2{
        width: 100%;
        height: 60% !important;
        display: flex !important;
        justify-content: flex-end !important;
        align-items: center !important;
    }
    .knowmore2 span{
        color: #ff7200;
        font-size: 9px !important;
        font-weight: 500 !important;
        letter-spacing: 0.7px !important;
        cursor: pointer;
    }
    .knowRightArrow{
        color: #ff7200;
        margin-left: 5px;
    }
    .carouselElem .swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
        position: absolute !important;
        top: 80% !important;
        left: 0 !important;
        bottom: 0 !important;
        width: 100% !important;
        justify-content: space-between;
        padding: 0 4rem;
    }
    .carouselElem .swiper-pagination-bullet {
        width: 1rem !important;
        height: 1rem !important;
        text-align: center;
        line-height: 20px;
        font-size: 0.76rem !important;
        color: #000;
        opacity: 1;
        background-color: transparent;
      }
      
    .carouselElem  .swiper-pagination-bullet-active {
        color: #000;
        font-weight: 800;
        color: #ff7200;
        font-size: 12px;
        background-color: transparent;
      }
    .carouselElem .swiper-button-prev{
        color: #ff7200;
        position: absolute;
        display: block !important;
        left: 0px !important;
        top: 100px !important;
        z-index: 100;
    }
    .carouselElem .swiper-button-next{
        color: #ff7200;
        position: absolute;
        display: block !important;
        right: 0px !important;
        top: 100px !important;
        z-index: 100;
    }
    .knowledge_footer {
        position: absolute;
        width: 100%;
        top: 520px;
    }
    }
    
    



