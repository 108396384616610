.contact_us_container{
  min-height: 130vh;
  /* height: 100%; */
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background: #000;
}
.contact_us_inner{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  z-index: 3;
  width: 100%;
  max-width: 700px;
}
.contact_box{
  background: linear-gradient(to bottom right, #1c1c1c, #121111);
  box-shadow: 2px 2px 5px #000;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: #fff;
  width: 100%;
}

.contact_form_row{
  display: flex;
  align-items: center;
  gap: 10px;
}

.formField{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px;
}

.formField > input{
  padding: 5px;
  background: #000000;
  border: 1px solid #717171;
  color: #afafaf;
  border-radius: 5px;
}

.formField > input::placeholder {
  color: rgb(180, 180, 180) !important;
  opacity: 1;
}

.formField > input:focus {
  outline: none;
  border-color: #ff7700; /* Optional: Add a custom border color on focus */
}

.formField > p{
  margin: 0px !important;
}

.horizontalLine {
  width: 99%;
  /* or a specific width */
  height: 1px;
  background: linear-gradient(90deg, #FF7200 0%, #FF7200 25.5%, #000000 100%);
}

.controls_section{
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.function_controls{
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
}
.control_btn{
  width: 100%;
  background: #2d2d2d;
  display: flex;
  justify-content: center;
  align-self: center;
  cursor: pointer;
  color: #fff;
  padding: 10px;
  transition: all 0.3s ease-in-out;
}
.control_btn:hover{
  background: #626161;
  box-shadow: 2px 2px 5px #000;
  transition: all 0.3s ease-in-out;
}

.active_btn{
  background: linear-gradient(90deg, #FF7700 0%, #994700 100%);
  transition: all 0.3s ease-in-out;
}
.active_btn:hover{
  background: linear-gradient(90deg, #f39542 0%, #d46809 100%);
  box-shadow: 2px 2px 5px #000;
  transition: all 0.3s ease-in-out;
}

.allRadioOption {
  display: flex;
  gap: 5px;
  cursor: pointer;
}

input[type="radio"] {
  transform: scale(1.5);
  cursor: pointer;
}

input[type="radio"]:checked {
  accent-color: #FF7700;
}

.submit_btn{
  background: linear-gradient(90deg, #FF7700 0%, #994700 100%);
  box-shadow: none;
  font-size: 16px;
  color: #fff;
  padding: 10px;
  border: none;
  transition: all 0.4s ease-in-out;
}

.submit_btn:hover{
  background: linear-gradient(90deg, #f39542 0%, #d46809 100%);
  box-shadow: 2px 2px 5px #000;
  transition: all 0.4s ease-in-out;
}

/* particle background */
.particle_bg{
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  filter: blur(0.7px);
  overflow: hidden;
}

.particle_bg::before{
  content: "";
  position: absolute;
  height: 40%;
  width: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(to bottom, #000, transparent);
  z-index: 5;
}

.particle_bg::after{
  content: "";
  position: absolute;
  height: 40%;
  width: 100%;
  bottom: 0;
  left: 0;
  background: linear-gradient(to top, #000, transparent);
  z-index: 5;
}

@media (max-width: 750px){
  .contact_us_container{
    min-height: 150vh;
  }
  .contact_form_row{
    flex-direction: column;
  }
  .function_controls{
    flex-direction: column;
  }
  .lanes_radios{
    flex-direction: row !important;
  }
}

@media (max-height: 1000px) {
  .contact_us_inner{
    margin-top: 3rem;
  }
}