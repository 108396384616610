.hero_section{
  overflow-x: hidden;
  overflow-y: auto;
}

/* .main_img_with_text_container::before{
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  background: url("../../assets/pexels-hu-nh-an-3895313-6778692.jpg") top center;
  background-size: cover;
  transform: translateZ(-1px) scale();
} */

.main_img_with_text_container{
  background: linear-gradient(#202020 10%, #000000);
  min-height: 95vh;
  height: 95vh;
  position: relative;
  animation: fadeIn 3s;
}

.main_backdrop{
  height: 100%;
  width: 100%;
}

.main_backdrop img{
  height: 100%;
  width: 100%;
  margin-left: 10vw;
  /* object-fit: contain; */
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.main_title_section{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: absolute;
  left: 2.3rem;
  top: 50%;
  transform: translateY(-50%);
  max-width: 500px;
}

.main_title_section > p{
  font-family: "Fjalla One", sans-serif !important;
  font-size: 50px;
  font-weight: 700;
  color: #fff;
  white-space: pre-line;
  text-transform: capitalize;
  /* line-height: 3.2rem; */
}
.main_title_section >p>span{
  font-family: "Fjalla One", sans-serif !important;
}

.metrics_container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem 2.5rem;
  background: #FF7700;
}

.metrics_box{
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  min-width: 200px;
  height: 100%;
  width: 100%;
  border-radius: 0.8rem;
  background: #fff;
  box-shadow: 0px 5px 7px 0px rgba(0,0,0,0.8);
}

.metrics_title{
  font-size: 35px;
  font-weight: 700;
  color: #FF7700;
}

.metrics_body{
  font-size: 14px;
}

.ticker_section{
  background: #0d0d0d;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.logos {
  overflow: hidden;
  padding: 20px 0;
  background: #0d0d0d;
  white-space: nowrap;
  position: relative;
}

.logos:before,
.logos:after {
  position: absolute;
  top: 0;
  width: 250px;
  height: 100%;
  content: "";
  z-index: 2;
}

.logos:before {
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), #0d0d0d);
}

.logos:after {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #0d0d0d);
}

.logos:hover .logos-slide {
  animation-play-state: paused;
}

.logos-slide {
  display: inline-block;
  animation: 35s slide infinite linear;
}

.logos-slide img {
  height: 50px;
  margin: 0 40px;
}

.button_container{
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 22px;
}

.app_btns{
  display: flex;
  align-items: center;
  gap: 1rem;
}

.app_btn{
  cursor: pointer;
  height: 30px;
  width: 30px;
}

.app_btn > img{
  height: 100%;
  width: 100%;
}

@media (min-width: 550px) {
  .main_title_section{
    left: 3rem;
  }
}

@media (min-width: 800px) {
  .main_title_section{
    left: 4rem;
  }
  .metrics_container{
    flex-wrap: nowrap;
  }
}

@media (max-width: 600px) {
  .main_backdrop img{
    width: auto;
    top: -24vw;
    aspect-ratio: 1;
    /* left: -20vw; */
  }
  .main_title_section{
    transform: none;
    top: 40%;
  }
  .main_title_section > p{
    font-size: 35px;
  }
}
@media (max-width:400px) {
  .main_title_section{
    transform: none;
    top: 35%;
  }
}
@media (max-width: 950px) {
  .main_backdrop img{
    /* width: auto; */
    margin-left: 0px;
    position: relative;
    top: -6vw;
    object-fit: cover;
  }
}

@media (min-width: 980px){
  .app_btns{
    display: none;
  }
}

@media (min-width: 1000px) {
  .main_title_section{
    left: 6rem;
  }

  .main_title_section > p{
    font-size: 55px;
  }
}

@media (min-width: 1300px) {
  .main_title_section{
    left: 7rem;
    max-width: 700px;
  }
}

@media (min-width: 1400px) {
  .main_title_section > p{
    font-size: 72px;
  }

  .main_title_section{
    left: 8rem;
    max-width: 1000px;
  }
}