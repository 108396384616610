.heading_mb{
  position: absolute;
  top: 2%;
  left: 10%;
  z-index: 2;
}
#forklift_mb{
  top: 29%;
  left: 4%;
}
#forklift_htl_mb{
  width: 10vw;
  top: -1px;
  left: 36px;
  rotate: -91deg;
  display: none;
}
#forklift_vtl_mb{
  width: 14vw;
  bottom: -3px;
  right: -16vw;
  rotate: 59deg;
  display: none;
}
#forklift_details_mb{
  left: 19vw;
  rotate: 32deg;
  width: 432%;
}
/* bikes */
#bikes_mb{
  top: 69%;
  left: 19%;
}
#bikes_htl_mb{
  width: 17vw;
  bottom: -1px;
  left: 36px;
  rotate: 25deg;
  display: none;
}
#bikes_vtl_mb{
  width: 15vw;
  bottom: -3px;
  right: -16vw;
  rotate: 59deg;
  display: none;
}
#bikes_details_mb{
  left: -16vw;
  rotate: -84deg;
  width: 432%;
}
/* field staff */
#field_staff_mb{
  top: 57%;
  left: 5%;
}
#field_staff_htl_mb{
  width: 17vw;
  bottom: -1px;
  right: -59px;
  rotate: 36deg;
  display: none;
}
#field_staff_vtl_mb{
  width: 22vw;
  bottom: -4px;
  right: -24vw;
  rotate: 51deg;
  display: none;
}
#field_staff_details_mb{
  left: -10vw;
  rotate: -87deg;
  width: 314%;
}
/* chain */
#chain_mb{
  top: 56%;
  right: 36%;
}
#chain_htl_mb{
  width: 17vw;
  bottom: -3px;
  left: 11px;
  rotate: 119deg;
  display: none;
}
#chain_vtl_mb{
  width: 22vw;
  bottom: -2px;
  right: -23vw;
  rotate: -29deg;
  display: none;
}
#chain_details_mb{
  left: -10vw;
  rotate: -91deg;
  width: 314%;
}
/* trucks */
#trucks_mb{
  bottom: 25%;
  right: 5%;
}
#trucks_htl_mb{
  width: 20vw;
  bottom: -3px;
  left: 11px;
  rotate: 148deg;
  display: none;
}
#trucks_vtl_mb{
  width: 18vw;
  bottom: -2px;
  right: -19vw;
  rotate: -29deg;
  display: none;
}
#trucks_details_mb{
  left: -10vw;
  rotate: -118deg;
  width: 314%;
}
/* container */
#boxes_mb{
  bottom: 47%;
  right: calc(15% + 6vw);
  /* right: 5%; */
}
#boxes_htl_mb{
  width: 20vw;
  top: -1px;
  left: 84px;
  rotate: 240deg;
  display: none;
}
#boxes_vtl_mb{
  width: 19vw;
  bottom: -2px;
  right: -21vw;
  rotate: 27deg;
  display: none;
}
#boxes_details_mb{
  left: 26vw;
  rotate: -267deg;
  width: 244%;
}

/* railway */
#railway_mb{
  bottom: 16%;
  left: 33%;
}
#railway_htl_mb{
  width: 20vw;
  top: -1px;
  left: 11px;
  rotate: 240deg;
  display: none;
}
#railway_vtl_mb{
  width: 19vw;
  bottom: -2px;
  right: -20vw;
  rotate: 27deg;
  display: none;
}
#railway_details_mb{
  left: 31vw;
  rotate: -267deg;
  width: 239%;
}
/* vans */
#vans_mb{
  bottom: 46%;
  right: 14%;
}
#vans_htl_mb{
  width: 12vw;
  top: -1px;
  left: 11px;
  rotate: 197deg;
  display: none;
}
#vans_vtl_mb{
  width: 19vw;
  bottom: -2px;
  right: -20vw;
  rotate: -69deg;
  display: none;
}
#vans_details_mb{
  left: -3vw;
  rotate: -128deg;
  width: 239%;
}
/* buses */
#buses_mb{
  top: 22%;
  left: 12%;
}
#buses_htl_mb{
  width: 12vw;
  top: -1px;
  left: 36px;
  rotate: 305deg;
  display: none;
}
#buses_vtl_mb{
  width: 19vw;
  bottom: -2px;
  right: -20vw;
  rotate: -28deg;
  display: none;
}
#buses_details_mb{
  left: 24vw;
  rotate: -277deg;
  width: 239%;
}

@media screen and (min-width: 430px){
  #forklift_htl_mb{
    width: 11vw;
  }
  #forklift_vtl_mb{
    width: 13vw;
    right: -14vw;
    rotate: 74deg;
  }
  #forklift_details_mb{
    left: 14vw;
    rotate: 17deg;
    width: 524%;
  }

  #boxes_htl_mb{
    left: 11px;
  }
  #boxes_vtl_mb{
    width: 25vw;
    right: -25vw;
    rotate: 27deg;
  }
  #boxes_details_mb{
    left: 15vw;
    rotate: -267deg;
    width: 186%;
  }

  #trucks_mb{
    right: 17vw;
  }
}