* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main_container {
  height: 672px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 0px;
}

.api_section {
  height: 100%;
  width: 100%;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;
  overflow: hidden;
}

.left_side,
.right_side {
  width: 100%;
  height: 100%;
  position: relative;
}

.left_side {
  background: #fafafa;
}

.right_side {
  background: #eeeeee;
}

.title {
  position: absolute;
  font-family: "Fjalla One", sans-serif;
  font-size: 1rem;
}

.left_title,
.right_title {
  top: 0.5rem;
}

.left_title {
  color: #575859;
  right: 2%;
}

.right_title {
  color: #ff7700;
  right: 2%;
}

.logo_container {
  height: 72px;
  width: 72px;
  border-radius: 20px;
  box-shadow:
    10px 6px 11px rgba(0, 0, 0, 0.1),
    inset 0px 2px 6px 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 50%;
  left: 46%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.logo_container::after {
  content: "";
  height: 50%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #eeeeee;
  border-radius: 15px 15px 0px 0px;
  z-index: -1;
  box-shadow: inset 0px 2px 6px 10px rgba(0, 0, 0, 0.03);
}

.logo_container::before {
  content: "";
  height: 50%;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #fafafa;
  border-radius: 0px 0px 15px 15px;
  box-shadow: inset 0px 2px 6px 10px rgba(0, 0, 0, 0.03);
}

.logo_container>img {
  height: 70%;
  width: 70%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mobile_backlines {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 50%;
  top: 56%;
  transform: translate(-50%, -50%);
  z-index: 0;
}

.mobile_backlines>img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.desktop_backlines {
  display: none;
}

.tablet_backlines {
  display: none;
}

.api_box_container {
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  max-width: 100px;
  flex-direction: column;
  justify-content: center;
}

.api_box_container>p {
  font-family: "Fjalla One", sans-serif !important;
  color: #4e4f50;
  white-space: pre-wrap;
  flex: 1;
  font-size: 0.7rem;
  line-height: 0.86rem;
  margin: 0px;
  text-align: center;
}

.tile {
  padding: 2px;
  border: 1px solid #ff7700;
  box-shadow: 7px 10px 18px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
}

.tile>img {
  height: 60%;
  max-width: 100%;
}

#rto {
  top: 16%;
  right: 4vw;
}

#monitoring_control {
  bottom: 21%;
  right: 28vw;
}

#e2e_msp {
  bottom: 38%;
  left: 24vw;
  flex-direction: column-reverse;
}

#data_transfer {
  top: 8%;
  left: 2vw;
  flex-direction: column-reverse;
}

#installation {
  bottom: 15%;
  left: 1vw;
}

#fois {
  top: 51%;
  right: 3.5vw;
}

#sim_tracking {
  top: 18%;
  right: 30vw;
  flex-direction: column-reverse;
}

#ldb {
  bottom: 15%;
  right: 37.5vw;
}

#vessel_tracking {
  top: 20%;
  left: 26vw;
}

#cargo_updates {
  bottom: 28%;
  left: 6vw;
}

#fastag {
  top: 12%;
  left: 2vw;
  flex-direction: column-reverse;
}

@media screen and (min-width:330px) {
  .title {
    font-size: 1.2rem;
  }

  #rto {
    top: 13%;
    right: 3vw;
  }

  #monitoring_control {
    bottom: 34%;
    right: 29vw;
  }

  #e2e_msp {
    bottom: 48%;
    left: 23vw;
    flex-direction: column-reverse;
  }

  #data_transfer {
    top: 2%;
    left: 1vw;
    flex-direction: column-reverse;
  }

  #installation {
    bottom: 19%;
    left: 5vw;
  }

  #fois {
    top: 59%;
    right: 3.5vw;
  }

  #sim_tracking {
    top: 35%;
    right: 28vw;
    flex-direction: column-reverse;
  }

  #ldb {
    bottom: 5%;
    right: 38.5vw;
  }

  #vessel_tracking {
    top: 23%;
    left: 32vw;
  }

  #cargo_updates {
    bottom: 18%;
    left: 7vw;
  }

  #fastag {
    top: 13%;
    left: 8vw;
    flex-direction: column-reverse;
  }
}

@media screen and (min-width:430px) {
  .left_title,
  .right_title {
    top: 1rem;
  }

  .left_title {
    right: 5%;
  }

  .right_title {
    right: 5%;
  }

  #rto {
    top: 13%;
    right: 21vw;
  }

  #monitoring_control {
    bottom: 24%;
    right: 38vw;
  }

  #e2e_msp {
    bottom: 50%;
    left: 27vw;
    flex-direction: column-reverse;
  }

  #data_transfer {
    top: 0%;
    left: 9vw;
    flex-direction: column-reverse;
  }

  #installation {
    bottom: 24%;
    left: 5vw;
  }

  #fois {
    top: 60%;
    right: 10.5vw;
  }

  #sim_tracking {
    top: 35%;
    right: 31vw;
    flex-direction: column-reverse;
  }

  #ldb {
    bottom: 5%;
    right: 38.5vw;
  }

  #vessel_tracking {
    top: 23%;
    left: 35vw;
  }

  #cargo_updates {
    bottom: 18%;
    left: 13vw;
  }

  #fastag {
    top: 13%;
    left: 11vw;
  }
}

@media screen and (min-width:530px) {
  .mobile_backlines {
    display: none;
  }

  .tablet_backlines {
    display: block;
  }

  .main_container {
    height: 1000px;
  }

  .tablet_backlines {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 50%;
    top: 60%;
    transform: translate(-50%, -50%);
    z-index: 0;
  }

  .tablet_backlines>img {
    height: 90%;
    width: 100%;
    object-fit: cover;
  }


  .logo_container {
    height: 85px;
    width: 85px;
    top: 50%;
    left: 52%;
  }

  .api_box_container>p {
    font-size: 0.9rem;
  }

  .tile {
    width: 75px;
    height: 75px;
  }

  #rto {
    top: 39%;
    right: 2vw;
  }

  #monitoring_control {
    bottom: 11%;
    right: 26vw;
  }

  #e2e_msp {
    bottom: 39%;
    left: 33vw;
    flex-direction: column-reverse;
  }

  #data_transfer {
    top: 10%;
    left: 9vw;
    flex-direction: column-reverse;
  }

  #installation {
    bottom: 27%;
    left: 5vw;
  }

  #fois {
    top: 37%;
    right: 1.5vw;
  }

  #sim_tracking {
    top: 21%;
    right: 21vw;
    flex-direction: column-reverse;
  }

  #ldb {
    bottom: 5%;
    right: 16.5vw;
  }

  #vessel_tracking {
    top: 14%;
    left: 45vw;
  }

  #cargo_updates {
    bottom: 23%;
    left: 22vw;
  }

  #fastag {
    top: 28%;
    left: 11vw;
  }
}



@media screen and (min-width:700px) {
  #rto {
    top: 34%;
    right: 2vw;
  }

  #monitoring_control {
    bottom: 20%;
    right: 28vw;
  }

  #e2e_msp {
    bottom: 35%;
    left: 37vw;
    flex-direction: column-reverse;
  }

  #data_transfer {
    top: 10%;
    left: 13vw;
    flex-direction: column-reverse;
  }

  #installation {
    bottom: 30%;
    left: 7vw;
  }

  #fois {
    top: 41%;
    right: 4.5vw;
  }

  #sim_tracking {
    top: 27%;
    right: 23vw;
    flex-direction: column-reverse;
  }

  #ldb {
    bottom: 5%;
    right: 23.5vw;
  }

  #vessel_tracking {
    top: 14%;
    left: 46vw;
  }

  #cargo_updates {
    bottom: 23%;
    left: 32vw;
  }

  #fastag {
    top: 33%;
    left: 11vw;
  }
}

@media screen and (min-width:860px) {
  #rto {
    top: 28%;
    right: 11vw;
  }

  #monitoring_control {
    bottom: 20%;
    right: 33vw;
  }

  #e2e_msp {
    bottom: 35%;
    left: 43vw;
    flex-direction: column-reverse;
  }

  #data_transfer {
    top: 10%;
    left: 28vw;
    flex-direction: column-reverse;
  }

  #installation {
    bottom: 40%;
    left: 7vw;
  }

  #fois {
    top: 49%;
    right: 4.5vw;
  }

  #sim_tracking {
    top: 33%;
    right: 23vw;
    flex-direction: column-reverse;
  }

  #ldb {
    bottom: 1%;
    right: 23.5vw;
  }

  #vessel_tracking {
    top: 14%;
    left: 46vw;
  }

  #cargo_updates {
    bottom: 23%;
    left: 37vw;
  }

  #fastag {
    top: 39%;
    left: 11vw;
  }
}

@media screen and (min-width:970px) {
  #rto {
    top: 20%;
  }

  #data_transfer {
    left: 34vw;
  }

  #ldb {
    right: 31.5vw;
  }
}

@media screen and (min-width:1050px) {
  .tablet_backlines {
    display: none;
  }

  .main_container {
    height: 750px;
  }

  .api_section {
    flex-direction: row;
  }

  .title {
    font-size: 2rem;
  }

  .left_title {
    left: 20%;
    right: unset;
  }

  .right_title {
    right: 20%;
  }

  .api_box_container>p {
    font-size: 1rem;
    line-height: unset;
    text-align: left;
 
  }

  .logo_container {
    height: 120px;
    width: 120px;
    border-radius: 20px;
    box-shadow:
      10px 6px 11px rgba(0, 0, 0, 0.1),
      inset 0px 2px 6px 10px rgba(0, 0, 0, 0.1);
    position: absolute;
    bottom: 28%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }

  .logo_container::after {
    height: 100%;
    width: 50%;
    left: 0;
    top: 0;
    border-radius: 20px 0px 0px 20px;
  }

  .logo_container::before {
    height: 100%;
    width: 50%;
    right: 0;
    top: 0;
    left: unset;
    bottom: unset;
    border-radius: 0px 20px 20px 0px
  }

  .desktop_backlines {
    display: block;
    height: 90%;
    width: 89%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    z-index: 0;
  }

  .desktop_backlines>img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .mobile_backlines {
    display: none;
  }

  .api_box_container {
    display: flex;
    align-items: center;
    gap: 15px;
    position: absolute;
    max-width: 200px;
    flex-direction: row;
    justify-content: unset;
  }

  .tile {
    width: 100px;
    height: 80px;
    padding: 8px;
    border-radius: 17px;
  }

  .tile>img {
    height: 70%;
    max-width: 100%;
  }

  #rto {
    top: 16%;
    left: 9vw;
  }

  #monitoring_control {
    top: 5%;
    right: 23vw;
    flex-direction: row-reverse;
  }

  #monitoring_control>p{
    text-align: right;
  }

  #e2e_msp {
    bottom: 29%;
    left: 14vw;
    flex-direction: row-reverse;
  }
  #e2e_msp>p{
    text-align: right;
  }

  #data_transfer {
    top: 74%;
    left: 31vw;
    flex-direction: row;
}

  #installation {
    bottom: 8%;
    left: 0vw;
    flex-direction: row-reverse;
  }

  #installation>p{
    text-align: right;
  }

  #fois {
    top: 11%;
    right: 8vw;
    flex-direction: row-reverse;
    max-width: 314px !important;
    
  }
  #fois >p{
    text-align: right;
  }

  #sim_tracking {
    top: 40%;
    left: 4vw;
    flex-direction: row-reverse;
  }
  #sim_tracking>p{
    text-align: right;
  }
  #ldb {
    bottom: 60%;
    right: 0.5vw;
    max-width: 276px !important;
  }

  #vessel_tracking {
    top: 60%;
    left: 15vw;
  }

  #cargo_updates {
    bottom: 11%;
    left: 30vw;
  }

  #fastag {
    top: 78%;
    left: 2vw;
    flex-direction: row-reverse;
  }
  #fastag>p{
    text-align: right;
  }
}

@media screen and (min-width:1350px) {

  .logo_container{
    height: 130px;
    width: 130px;
    bottom: 33%;
    top: 54%;
  }
 
  .api_box_container{
    max-width: 250px;
  }
  .tile{
    width: 147px;
    height: 100px;

  }

  #rto {
    top: 14%;
    left: 11vw;
  }

  #monitoring_control {
    top: 5%;
    right: 19vw;
    flex-direction: row-reverse;
  }

  #monitoring_control>p{
    text-align: right;
  }

  #e2e_msp {
    bottom: 27%;
    left: 20vw;
    flex-direction: row-reverse;
  }
  #e2e_msp>p{
    text-align: right;
  }

  #data_transfer {
    top: 77%;
    left: 32vw;
    flex-direction: row;
}
  
  #installation {
    bottom: 8%;
    left: 5vw;
    flex-direction: row-reverse;
  }
  #installation>p{
    text-align: right;
  }

  #fois {
    top: 11%;
    right: 12vw;
    flex-direction: row-reverse;
  }

  #fois >p{
    text-align: right;
  }

  #sim_tracking {
    top: 46%;
    left: 4vw;
    flex-direction: row-reverse;
  }

  #sim_tracking>p{
    text-align: right;
  }

  #ldb {
    bottom: 56%;
    right:8.5vw;
    max-width: 297px !important;
  }

  #vessel_tracking {
    top: 62%;
    left: 15vw;
  }

  #cargo_updates {
    bottom: 8%;
    left: 26vw;
  }

  #fastag {
    top: 78%;
    left: 0vw;
    flex-direction: row-reverse;
  }
  #fastag>p{
    text-align: right;
  }

}
@media screen and (min-width:1600px) {
  #ldb{
    bottom: 55%;
        right: 12.5vw;
  }
}

@media screen and (min-width:1850px) {
  #rto {
    top: 14%;
    left: 15vw;
  }

  #monitoring_control {
    top: 5%;
    right: 19vw;
    flex-direction: row-reverse;
  }

  #monitoring_control>p{
    text-align: right;
  }

  #e2e_msp {
    bottom: 24%;
    left: 22vw;
    flex-direction: row-reverse;
  }
  #e2e_msp>p{
    text-align: right;
  }

  #data_transfer {
    top: 77%;
    left: 32vw;
    flex-direction: row;
}
  
  #installation {
    bottom: 8%;
    left: 11vw;
    flex-direction: row-reverse;
  }

  #installation>p{
    text-align: right;
  }

  #fois {
    top: 11%;
    right: 16vw;
    flex-direction: row-reverse;
  }

  #fois >p{
    text-align: right;
  }

  #sim_tracking {
    top: 44%;
    left: 4vw;
    flex-direction: row-reverse;
  }

  #sim_tracking>p{
    text-align: right;
  }

  #ldb {
    bottom: 57%;
    right:16.5vw;
  }

  #vessel_tracking {
    top: 60%;
    left: 12vw;
  }

  #cargo_updates {
    bottom: 8%;
    left: 26vw;
  }

  #fastag {
    top: 78%;
    left: 0vw;
    flex-direction: row-reverse;
  }
  
  #fastag>p{
    text-align: right;
  }
}