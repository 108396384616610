@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,700;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;0,700;1,300;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,700;1,300;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap');
@import url('react-responsive-carousel/lib/styles/carousel.min.css');
@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');
@import "swiper/swiper.min.css";
@import "swiper/components/effect-coverflow/effect-coverflow.min.css";
@import "swiper/components/pagination/pagination.min.css";
@import "swiper/components/navigation/navigation.min.css";

@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@400;700&display=swap');


* {
    padding: 0;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    /* box-sizing: border-box; */
}

.up_moving_button {
    color: #ff7200;
    background-color: white;
}


html {
    padding: 0px;
    margin: 0px;
    width: 100vw;
    font-family: 'Montserrat', sans-serif;

}

body {
    width: 100%;
    overflow-x: hidden;
    font-family: 'Montserrat', sans-serif;
}

.clr-orange {
    color: #ff7200;
}

.video_part {
    width: 100%;
    height: 125vh;
    position: relative;
    bottom: 10px;
    /*  border: 1px solid orange; */





}

.video_part .headings {
    width: 100%;
    height: 83%;
    position: relative;
    top: 20px;


    /*   background-color: black; */



}

#video_hardware {


    background-position: relative;

    min-width: 100% !important;
    min-height: 100%;
    position: relative;
    bottom: 5%;
    left: 0%;

}

#hardware {
    color: #fff;
}

.main {
    width: 100%;
    height: 159vh;
    position: static;
    margin-top: -298px;
    background-color: white;



}

.main .headings {
    max-width: 100%;
    height: 45%;
    position: relative;
    top: 70px;
    left: 0px;
    z-index: 1;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 86%);

}

#aligning_text1,
#aligning_text2,
#aligning_text3,
#aligning_text3,
#aligning_text4,
#aligning_text {
    color: black;
    font-weight: bolder;
    font-size: 14px;
    position: relative;
    right: -5px;
    font-family: 'Montserrat', sans-serif;


}

#aligning_text1 {
    color: black;
    position: relative;
    top: 0px;
    left: 8px;
    font-size: 12px;
    font-weight: 500;
}

#aligning_text2 {
    color: black;
    position: relative;
    top: 0px;
    left: -12px;
    font-size: 12px;
    font-weight: 500;

}


#aligning_text3 {
    color: black;
    position: relative;
    top: 0px;
    left: -25px;
    font-size: 14px;
    font-weight: 500;
}

#aligning_text4 {
    color: black;
    position: relative;
    top: -1px;
    left: 0px;
    font-size: 14px;
    font-weight: 500;
}

#aligning_text {
    color: black;
    position: relative;
    top: 7px;
    left: -2px;
    font-size: 14px;
    font-weight: 500;
}


#align {

    position: relative;
    left: 895px;
    color: black;
    font-weight: 500;
}

.pRel {
    position: relative;
}

.banner_headings {
    width: 59%;
    height: 35%;
    color: #ff7200;
    position: relative;
    bottom: 510px;

    left: 88px;


}

.banner_headings h4 {
    font-size: 90px;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;

}


.banner_headings h6 {
    color: #fff;
    font-size: 14px;
    position: relative;
    font-weight: 300;
    font-family: 'Montserrat', sans-serif;
    top: 10px;
    left: 8px;
}

.three_btns_for_videos {
    width: 4%;
    height: 20px;
    display: flex;

    position: relative;
    bottom: 150px;
    color: #ff7200;
    margin: auto;
    justify-content: space-between;



}

.three_btns_for_videos button {

    width: 10px;
    height: 10px;
    border-radius: 12px;
    border: none;
}

.banner_btn {
    background-color: #ff7200;
    border: none;
}


#aligning_text {
    position: relative;
    right: 5px;
    top: 7px;
}


.main h6 {
    text-align: center;
    margin-top: 12px;
    color: #fff;

    font-size: 21px;
    font-weight: lighter;



}

.Product_heading {

    width: 50%;
    height: auto;
    display: block;
    margin: auto;

}

.Product_heading h2 {
    color: #ff7200;
    position: relative;
    top: 109px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    font-size: 26px;
    letter-spacing: 0.1px;

}

.Product_titles {

    width: 82%;
    height: 6%;
    position: relative;
    top: 127px;

    display: flex;
    justify-content: space-between;
    text-decoration: none;
    margin: auto;
    gap: 2rem;

}

.Product_titles a:hover {
    border-bottom: 1px solid #ff7200;
    background-color: transparent;
    color: white;
    text-decoration: none;

}

.active {

    background-color: transparent;
    text-decoration: none;

}

#ll {
    color: #fff;
}




.Product_titles a {
    color: #fff;
    font-size: 20px;
    padding-top: 0px;
    cursor: pointer;
    text-decoration: none;
}

.Product_titles img {
    color: #fff;
}

.Product_titles .active {
    border-bottom: 1px solid #ff7200;
    background-color: transparent;

}

#icon_img1 {
    vertical-align: middle;
    width: 60px;

}

#icon_img2 {
    vertical-align: middle;
    position: relative;
    right: 20px;
    width: 60px;

}

#icon_img3 {
    vertical-align: middle;
    position: relative;
    right: 33px;
    width: 68px;

}

#icon_img4 {
    vertical-align: middle;
    position: relative;
    right: 8px;
    width: 60px;

}




#icon_img7 {
    vertical-align: text-top;
    position: relative;
    height: 36px;
    width: 36px;
    top: -8px;

}
#aligning_text7{
    position: relative;
    top: -10px;
    left: 0px;
    color: black;
    font-size: 14px;
    font-weight: 500;
}
#icon_img5 {
    vertical-align: text-top;
    position: relative;
    right: 15px;
    width: 50px;

}

#icon_5 {
    cursor: pointer;
    margin-right: 2px;
}


#align {
    position: absolute;
    top: 29px;
    right: 264px;
    color: black;
    font-size: 14px;
    font-weight: 700;
}


.sub {
    /*  border: 1px solid red; */
    position: relative;
    top: 220px;
    height: 60%;
}

.background_div {
    width: 40%;
    height: 70%;

    position: relative;
    top: 11%;
    left: 50%;
}

#product_images {
    width: 80%;
}

/* 
.background_1{
    width: 66%;
    height: 70%;
    border: 6px solid #ff7200;
    margin-top: -20%;
    position: relative;
    left: 16%;
   
}
.background_2{
    width: 63.3%;
    height: 57%;
    border: 6px solid #ff7200;
    position: relative;
    top: -51%;
    left: 38%;
   
}
 */


.left_div {
    width: 45%;
    height: 76%;
    /*  border: 1px solid green;  */
    position: relative;
    top: -555px;
    left: 55%;

}

#video_product_1 {
    margin-top: 60px;
}

#video_product_2 {
    width: 82%;
    height: 60%;
    margin-top: 72px;
    margin-left: 114px;
}

.right_div {
    width: 40%;
    height: 660px;
    /*  border: 1px solid orange; */

}

.right_div #head1 {
    color: #ff7200;
    font-size: 28px;
    font-weight: 600;
    letter-spacing: -0.9px;
    line-height: 27px;
    font-family: 'Montserrat', sans-serif;

}

.right_div #head2 {
    color: black;
    margin-top: 0px;
    text-align: justify;
    font-family: 'Montserrat', sans-serif;
    line-height: 140%;
    text-align: justify;
    font-size: 13px;
    letter-spacing: 1.2px;
    line-height: 27px;
    font-weight: 300;
}

.right_div button {
    width: 103px;
    height: 25px;
    margin-top: 28px;
    padding: 0px;
    background-color: #ff7200;
    border-radius: 14px;
    color: white;
    border: none;
    font-weight: 300;
    cursor: pointer;

}

.Product_footer {
    position: relative;
    top: 114px;
    left: 0px;
    width: 100%;
    height: 123px;
    /* background-color: rgb(31, 38, 38); */
    background-color: rgb(26, 25, 25);

}

.Product_footer .Numbers {
    /* border: 1px solid red; */
    display: flex;
    position: relative;
    top: 32px;
}

.Product_footer h1 {
    color: #ff7200;
    font-size: 32px;
    margin: auto;
}

.clients {
    /*   */
    display: flex;
    position: relative;
    top: 35px;
}

.Product_details p {
    margin: auto;
    font-size: 14px;
    color: white;
}

.clients #one {
    position: relative;
    left: 18px;
}


.clients #two {
    position: relative;
    left: 40px;
}

.clients #three {
    position: relative;
    left: 20px;
}

.clients #four {
    position: relative;
    left: 7px;

}

.clients span {
    color: white;
    position: relative;
    right: 7px;
}


.Enterprise_part {
    background-color: rgb(25, 29, 29);
    position: relative;
    top: -143px;
    width: 100%;
    height: 103vh;
    /*   border: 1px solid red; */
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 90%);
}

.Enterprise_part h1 {


    font-size: 34px;
    text-align: center;
    color: #ff7200;
    font-weight: 400;
    margin-top: 20px;
    font-family: 'Montserrat', sans-serif;

    position: relative;
    top: 60px;
}

.right_div button {
    width: 83px;
    height: 30px;
    margin-top: -10px;
    padding: 0px;
    background-color: #ff7200;
    border-radius: 14px;
    color: white;
    border: none;
    font-size: 10px;
    font-weight: 400;
    cursor: pointer;
}

#lll {
    position: relative;
    left: -33px;
    bottom: 20px;
    font-size: 10px;
}


.home_page_footer {
    position: relative;
    /* bottom: 160px; */
    background-color: #161616;
}

.products_swiper {
    display: none;
}

#scrolling_to_top {
    background-color: transparent;
    z-index: 100;
    width: 35px;
    box-shadow: 1px 1px 4px black;
    height: 35px;
    background: rgb(194, 189, 189) no-repeat;

}

#scrolling_to_top:hover {
    background-color: #ff7200 !important;
}


svg {
    vertical-align: middle;
    width: 22px;
    cursor: pointer;
}

svg:hover {
    width: 27px;
}




::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-thumb {
    background-color: #ff7200 !important;
    border-radius: 7px;

}





.bg-orange {
    background-color: #ff7200 !important;
    color: white !important;
}



.main_description {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    gap: 2em;
    /* max-width: 60%; */
    padding: 2em 20%;
    margin: 0 auto;
    border-bottom: 1px solid grey;
    height: auto;
    /* background-color: red; */
}

.apiHeading {
    color: #ff7200;
    font-family: 'Fjalla One', sans-serif;
    font-weight: 800 !important;
    text-align: center;
    font-size: 3.6vw;
}

@media only screen and (max-width:800px) {
    .apiHeading {
        font-size: 3vh;
    }
}

.apiSubheading {
    text-align: center;
    color: #ffffff;
}

.apiFeatures {
    display: flex;
    gap: 1em;
    color: #ffffff;
}

.apibox1 {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    gap: 1.4rem
}

.apiimg1 {
    width: 5.4rem;
}

.apidefinition {
    font-size: 0.8rem;
}

.apipara {
    color: #ffffff;
    text-align: center;
    /* background-color: red; */
    /* padding: 0 4rem; */

}

.main_company {
    background-color: black;
    width: 100%;
    position: absolute;
    top: 70px;
}

.main_company .api_section {
    display: flex;
    justify-content: space-between;
    gap: 1em;
    margin-top: 1em;
}

.main_company .api_section_title {
    flex-basis: 33%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ff7200;
    /* font-size: 1rem; */
    padding: 2em;
    /* background-color: red; */
    /* border-right: 1px solid grey; */
    border-bottom: 1px solid grey;
    position: relative;
    text-align: center;
}

.main_company .api_section_title::after {
    content: "";
    position: absolute;
    top: 0;
    right: -3%;
    border-right: 1px solid grey;
    height: auto;
    bottom: 0;
}

.main_company .api_section_content {
    flex-basis: 65%;
    display: flex;
    flex-wrap: wrap;
    /* gap: 1em; */
    border-bottom: 1px solid grey;
}

.api_box {
    /* background-color: green; */
    flex-basis: 48%;
    /* margin: 2em; */
    /* flex: 1 1 0px; */
    padding: 2em;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    /* border-right: 1px solid grey; */
    border-bottom: 1px solid grey;
    position: relative;
}

.api_box:last-child {
    border-bottom: none;
}

.api_box:nth-of-type(odd)::after {
    content: "";
    position: absolute;
    border-right: 1px solid grey;
    height: auto;
    top: 1em;
    bottom: 1em;
    right: -0.75em;
}

.b-none {
    border: none;
}

.api_box h6 {
    color: #ff7200;
}

.api_box p {
    color: white;
    font-size: 0.8rem;
}

.api_img img {
    width: 16em;
    height: 7em;
}

.api-footer {
    /* position: absolute; */
    /* width: 100%; */
    /* top: 1217px; */
    margin-top: 2em;
}

.sales_pitch {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3em;
}

.sales_heading {
    color: #fff;
    font-size: 1.2rem;
    text-align: center;
}

.sales_note {
    color: rgb(105, 105, 105);
    /* border: 1px solid red; */
    max-width: 30em;
    text-align: center;
    margin-top: 0.75em;
}

/* media query 1 */
@media only screen and (min-width:2281px) {
    .customers_div_3 img {
        filter: grayscale(100%);
    }
    .banner_headings_messgae {
        width: 100%;
        height: 5%;
        color: white;
        bottom: 720px;
        position: relative;
        z-index: 10;
        text-align: center;
    }

    .PEnterDegign {
        display: none;
    }

    .EnterpriseTextElementNew {
        display: none;
    }

    * {
        margin: 0px;
        box-sizing: border-box;
        padding: 0px;
    }

    .mainMobile {
        display: none;
    }

    .whatsNew500 {
        display: none;
    }

    .api-div500 {
        display: none;
    }

    .counts {
        font-size: 3.5rem;
    }

    body {
        width: 100%;
        height: 100%;
        display: inline;
        z-index: -1;

    }


    #lll {
        position: relative;
        left: 30px;
        font-size: 9px;
        font-weight: lighter;
        text-decoration: none;
    }

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #ff7200 !important;
        border-radius: 7px;
        cursor: pointer;

    }


    .banner_video_slider {
        display: none !important;
    }


    .video_part {
        width: 100%;
        height: 1260px;
        position: relative;
        z-index: 1;


    }

    .video_part .headings {
        width: 100%;
        height: 66%;
        position: relative;
        top: 20px;
    }

    .video_part p {
        position: absolute;
        top: 100px;
        font-size: 30px;
        color: black;
        z-index: -1;
        visibility: hidden;

    }

    video {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50px;
        object-fit: initial;
        z-index: 1;
    }


    .three_btns_for_videos {
        width: 4.5%;
        height: 30px;
        display: flex;
        position: relative;

        top: -70px;
        color: #ff7200;
        justify-content: center;
        align-items: center;
        justify-content: space-between;
        z-index: 10;



    }

    .three_btns_for_videos button {

        width: 16px;
        height: 16px;
        border-radius: 12px;
        border: none;

    }


    .banner_headings {
        width: 41%;
        height: 20%;
        color: #ff7200;

        bottom: 560px;
        position: relative;
        left: 55px;
        z-index: 10;

        /*  border: 0.2px solid red;  */
    }

    .banner_headings h4 {
        font-size: 119px;
        font-weight: 900;
        text-shadow: 1px 5px 1px black;
        font-family: 'Fjalla One', sans-serif;
        line-height: 115px;
    }


    .banner_headings h6 {
        color: #fff;
        font-size: 23px;
        text-shadow: 1px 2px 1px black;
        position: relative;
        top: 6px;
        left: 2px;
        font-weight: 500;
        font-family: 'Montserrat', sans-serif;
    }

    .banner_btn {
        background-color: #ff7200;
        border: none;
    }


    .products_swiper {
        display: none;
    }



    .main {
        width: 100%;
        height: 910px;
        position: relative;
        top: -85px;
        background-color: #f7f7f7;
        z-index: 2;
    }


    .Product_heading {
        position: relative;
        text-align: center;
        justify-content: center;
        align-items: center;


    }

    .Product_heading h2 {

        font-size: 100px;
        color: black;
        text-shadow: none;
        position: relative;
        top: 30px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 1px;
        background-image: linear-gradient(#ff7200, #ff7200);
        letter-spacing: 0px;
        background-repeat: no-repeat;
        line-height: 180px;
        background-size: 0% 0px;
        background-position: bottom;


    }


    .Product_titles {

        width: 87%;
        height: 70px;
        position: relative;
        top: 39px;
        left: -1%;
        display: flex;
        justify-content: space-between;
        text-decoration: none;
        z-index: 10;

    }

    .Product_titles img {
        display: inline-table;
    }




    #ll {
        color: #fff;
    }




    .Product_titles button {
        color: #fff;
        font-size: 22px;
        padding-top: 1px;
        cursor: pointer;
        border: none;
        background-color: transparent;
        width: 167px;
        text-decoration: none;
    }

    .Product_titles img {
        color: #fff;
    }

    .Product_titles .active {


        background-color: #ff7200;
        border-radius: 38px;
        width: 244px;
        height: 80px;
        justify-content: center;
        text-align: center;
        border: none;
        box-shadow: 3px 3.5px 1px grey;
        position: relative;
        bottom: 9px;

    }

    #active_img {
        vertical-align: middle;
        width: 49px;
        height: 52px;
        filter: brightness(0) invert(1);


    }

    #active_img5 {
        vertical-align: middle;
        width: 44px;
        height: 46px;
        filter: brightness(0) invert(1);
        position: relative;
        top: 10px;
        right: 1px;


    }

    #icon_img1 {
        vertical-align: middle;
        width: 56px;
        position: relative;
        bottom: 5px;


    }

    #icon_img2 {
        vertical-align: middle;
        width: 52px;
        height: 48px;
        position: relative;
        left: -8px;
        bottom: 6px;

    }

    #icon_img3 {
        vertical-align: middle;
        width: 50px;
        height: 57px;
        position: relative;
        left: -1px;
        bottom: 7px;

    }

    #icon_img4 {
        vertical-align: middle;
        width: 50px;
        position: relative;
        bottom: 9px;
        left: 5px;
        right: 0px;
        height: 52px;


    }

    #icon_img5 {
        vertical-align: top;
        position: relative;
        top: 1px;
        right: -10px;
        height: 51px;
        width: 49px;

    }

    #icon_5 {
        cursor: pointer;

    }

    #icon_img6 {
        vertical-align: top;
        position: relative;
        bottom: 10px;
        left: 3px;
        height: 52px;
        width: 52px;

    }

    #active_text {
        color: #fff;
        font-size: 16px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        position: relative;
        left: 6px;


    }

    #active_text_2 {
        color: #fff;
        font-size: 16px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        position: relative;
        right: -5px;
        top: 6px;


    }

    #active_text1 {
        color: #fff;
        font-size: 16px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;

        display: block;
        position: relative;
        bottom: 14px;
        right: -22px;


    }

    #aligning_text1 {
        color: black;
        position: relative;
        top: -3px;
        left: 5px;
        font-size: 16px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
    }

    #aligning_text2 {
        color: black;
        position: relative;
        top: -5px;
        left: 2px;
        font-size: 16px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
    }


    #aligning_text3 {
        color: black;
        position: relative;
        top: -5px;
        left: 4px;
        font-size: 16px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
    }

    #aligning_text4 {
        color: black;
        position: relative;
        top: -6px;
        left: 10px;
        font-size: 16px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
    }

    #aligning_text {
        color: black;
        position: relative;
        top: 5px;
        left: 20px;
        font-size: 16px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
    }

    #aligning_text5 {
        color: black;
        position: relative;
        top: -4px;
        left: 9px;
        font-size: 16px;
        font-weight: 500;
    }

    #warehouse1 {
        color: black;
        display: block;
        position: relative;
        bottom: 24px;
        right: -24px;
    }



    #twoD {
        color: black;
        position: relative;
        top: -1px;
        left: 10px;
    }

    #product_images {
        width: 744px;
        position: relative;
        left: 15%;
        bottom: -4%;

    }

    #video_product_2 {
        width: 100%;
        height: 87%;
        margin-top: 0px;
        margin-left: -2px;
    }


    .neeProd {
        width: 90%;
        height: auto;
        display: flex;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
        margin-top: 105px;
        box-shadow: 0px 0px 10px grey;
        border-radius: 10px;
    }

    .newProdLeft {
        width: 52%;
        padding: 12px;
    }

    .prodSpan {
        font-size: 52px;
        /* padding-left: 1.5%; */
        font-weight: 600;
        position: relative;
        top: 15px;
        line-height: 60px;
        color: #ff7200;
        font-family: 'Fjalla One', sans-serif;
    }

    .prodData {
        font-size: 19px;
        font-weight: 300;
        text-align: justify;
        text-shadow: none;
        line-height: 24px;
        letter-spacing: 0px;
    }

    .prodBtn {
        width: auto;
        height: auto;
        padding: 9px;
        border: none;
        font-size: 10px;
        background-color: #ff7200;
        cursor: pointer;
        border-radius: 20px;
        color: #fff;
        box-shadow: 2px 2px grey;
        text-decoration: none;
    }

    .prodBtn:hover,
    .prodBtn:focus,
    .prodBtn:active {
        text-decoration: none;
        color: #fff;
    }

    .newProdRight {
        width: 48%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 22px;
        padding-bottom: 22px;
        overflow: hidden;
    }

    .prodImage {
        width: 46%;
        height: 450px;
    }

    .enterprise_left1 {
        display: block;
    }

    .enterprise_left2 {
        display: none;
    }

    .achievements_div {
        width: 100%;
        height: 1055px;
        background-color: #070707;
        /* position: absolute; */
        
        /* top: 1795px; */
        position: relative;
        text-align: center;
        z-index: 3;
    }

    .achievements_div h1 {

        font-size: 100px;
        color: #ff7200;
        text-shadow: none;
        position: relative;
        top: 29px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-image: linear-gradient(#fff, #fff);

        background-repeat: no-repeat;
        line-height: 190px;
        background-size: 0% 0px;
        background-position: bottom;


    }

    .achivementElem {
        width: 80%;
        height: 71%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 21px;
    }

    .achiveR1 {
        width: 100%;
        height: 50%;
        display: flex;
    }

    .achieverc1 {
        width: 20%;
        height: 100%;
        margin-right: 8px;
        position: relative;
    }

    .achieveImg {
        width: 100%;
        height: 100%;
        position: relative;
        justify-content: flex-start;
        text-align: left;
        align-items: center;
        z-index: 1;
    }

    .achieve100 {
        position: absolute;
        top: 20px;
        left: 30px;
        font-size: 3.5rem;
        font-weight: 600;
        color: #ff7200;
        z-index: 10;
    }

    .achieveClient {
        position: absolute;
        top: 80px;
        left: 30px;
        font-weight: 500;
        z-index: 10;
        font-size: 1.5rem;
    }

    .achieveInstall {
        position: absolute;
        top: 110px;
        left: 30px;
        font-weight: 500;
        z-index: 10;
        font-size: 1.3rem;
    }

    .achieverc2 {
        width: 30%;
        height: 100%;
        margin-right: 8px;
    }

    .achiveI1 {
        width: 100%;
        height: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #ff7200;
        box-shadow: 0px 0px 24px black inset;
        margin-bottom: 8px;
    }

    .threefive {
        font-size: 2.5rem;
        color: #fff;
        font-weight: 700;
        position: relative;
        top: 10px;
    }

    .iot {
        font-size: 1.5rem;
        font-weight: 400;
        color: #fff;
    }

    .achiveI2 {
        width: 100%;
        height: 57%;
        position: relative;
    }

    .vehicle_text {
        color: #ff7200;
        font-size: 4.5rem;
        font-weight: 600;
        position: absolute;
        z-index: 10;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .vehicle_sub_text {
        color: #fff;
        position: absolute;
        z-index: 10;
        font-size: 1.5rem;
        top: 45%;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .achieverc3 {
        width: 20%;
        height: 100%;
        margin-right: 8px;
    }

    .achieve50 {
        width: 100%;
        height: 49%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #464444;
        box-shadow: inset 1px 1px 22px #000;
        margin-bottom: 6px;
    }

    .achieve50b {
        width: 100%;
        height: 49%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #d3d3d3;
        box-shadow: inset 1px 1px 19px #000;
        margin-bottom: 6px;
    }

    .m100m {
        color: #ff7200;
        font-size: 2.5rem;
        font-weight: 600;
    }

    .img_overlay {
        position: absolute !important;
    }

    .overlay_main_text {
        position: absolute;
        top: 10px;
        left: 80px;
        z-index: 10;
        color: #ff7200;
        font-size: 4rem;
        font-weight: 600;
    }

    .overlay_sub_text {
        color: #fff;
        position: absolute;
        top: 90px;
        left: 120px;
        font-size: 2rem;
        z-index: 10;
    }

    .transactions {
        color: #fff;
        position: relative;
        bottom: 7px;
        font-size: 1.25rem;
    }

    .m100black {
        color: #000;
        font-size: 2.5rem;
        font-weight: 600;
    }


    .transactionsm {
        color: #000;
        position: relative;
        bottom: 7px;
        font-size: 1.5rem;
    }

    .achieveI31 {
        width: 100%;
        height: 48%;

        margin-bottom: 8px;
    }

    .achieveI32 {
        width: 100%;
        height: 49%;
        margin-bottom: 8px;
    }

    .achieverc4 {
        width: 30%;
        height: 100%;
    }

    .achiveR2 {
        width: 100%;
        height: 50%;
        display: flex;
        position: relative;
    }

    .ach5 {
        width: 29%;
        height: 100%;
        margin-left: 8px;
        margin-top: 8px;
        position: relative;
    }

    .fifteen {
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 10;
    }

    .fifteenTrips {
        position: absolute;
        top: 80px;
        left: 35px;
        z-index: 10;
        font-size: 1.5rem;
    }

    .ach6 {
        width: 21%;
        height: 100%;
        margin-top: 8px;
        position: relative;
    }

    .ach7 {
        width: 20%;
        height: 100%;
        margin-top: 8px;
        margin-left: 8px;
        position: relative;
    }

    .m1000 {
        color: #fff;
        position: absolute;
        top: 20px;
        left: 20px;
        font-size: 2.5rem;
        z-index: 10;
    }

    .lynkassist {
        color: #fff;
        position: absolute;
        top: 60px;
        left: 20px;
        font-size: 1.5rem;
        z-index: 10;
    }

    .lynkassistLoc {
        color: #fff;
        position: absolute;
        top: 90px;
        left: 20px;
        font-size: 1.5rem;
        z-index: 10;
    }

    .ach8 {
        width: 30%;
        height: 100%;
        margin-left: 8px;
        margin-top: 8px;
        display: flex;
        position: relative;
    }

    .m3 {
        position: absolute;
        top: 10px;
        left: 60px;
        z-index: 10;
        color: #ff7200;
        font-size: 6rem;
        font-weight: 600;
    }

    .rfid_cir {
        color: #fff;
        position: absolute;
        top: 130px;
        left: 60px;
        font-size: 2rem;
        z-index: 10;
    }


    .lynkit_testomonial {
        width: 100%;
        height: 990px;
        /* position: absolute;
        top: 2850px; */
        position: relative;
        display: flex;
        align-content: center;
        justify-content: center;
    }

    .Enterprise_div {
        width: 100%;
        height: 890px;
        /* position: absolute;
        top: 3824px; */
        position: relative;
        display: flex;
        justify-content: center;
        overflow-x: hidden;
    }

    .Enterprise_div h1 {
        font-size: 100px;
        color: #ff7200;
        text-shadow: none;
        position: absolute;
        top: 8px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-image: linear-gradient(#fff, #fff);
        background-repeat: no-repeat;
        line-height: 186px;
        background-size: 0% 0px;
        background-position: bottom;
        letter-spacing: 0px !important;
        z-index: 2;
    }

    .enterprise_main_div {
        width: 80%;
        height: 500px;
        /*  background-color: aqua; */
        position: relative;
        top: 270px;
        display: flex;
    }

    .Enterprise_div img {
        position: absolute;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .enterOuter {
        width: 100%;
        height: 84%;
        z-index: 1;
        position: relative;
        top: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .enterInner {
        width: 90%;
        height: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .enterTop {
        width: 90%;
        height: 60%;
        display: flex;
        position: relative;
    }

    .enterpriseSvgCls {
        width: 100%;
        height: 100px;
        position: absolute;
        z-index: 101;
    }

    .enterBot {
        width: 90%;
        height: 20%;
        display: flex;
        position: relative;
    }

    .EnterpriseTextElement {
        width: 90%;
        height: auto;
        padding: 15px;
        position: relative;
        text-align: justify;
        /* border: 1px solid #404040; */
        /* box-shadow: 0px 0px 10px #585858; */
        /* background-color: #fff; */
    }

    .enterSpan {
        font-weight: 300;
        color: #F5F5F5 !important;
    }

    .enterpriseClose {
        width: 20px;
        height: 20px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: -10px;
        right: -10px;
        /* background-color: #ff7200; */
        border-radius: 50%;
    }

    .entSpanActive {
        font-size: 14px;
        color: #ff7200;
        font-weight: 800;
        font-family: 'Bai Jamjuree', sans-serif ! important;
    }

    .enterBox {
        width: 20%;
        height: 100%;
    }

    .boxBorder {
        border-right: 1px solid #ff7200;
    }

    .boxBorder:last-child {
        border: none;
    }

    .enterData {
        width: 100%;
        height: 25%;
        position: relative;
        top: 28%;
        display: flex;
    }

    .entCont1 {
        width: 40%;
        height: 100%;
        display: flex;
        justify-content: center !important;
        align-items: center !important;
    }

    .entCont2 {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .entImg {
        width: 25% !important;
        height: 75% !important;
        position: relative;
        left: 5% !important;
    }

    .entSpan {
        font-size: 19px;
        color: #fff;
        font-weight: 800;
        font-family: 'Bai Jamjuree', sans-serif ! important;
    }

    .leftMover {
        position: relative;
        left: 15% !important;
    }

    .bottomMover {
        position: relative;
        bottom: 18% !important;
    }

    .enterBW {
        width: 95%;
        height: 35%;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        justify-content: flex-start;
        align-items: center;
        display: flex;
    }

    .enterBW1 {
        width: 95%;
        height: 20% !important;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        display: flex;
        margin-left: auto;
        margin-right: auto;
    }

    .enterOrange {
        color: #ff7200;
        font-size: 18px;
        font-weight: 500;
        font-family: 'Bai Jamjuree', sans-serif ! important;
    }

    .enterWhite {
        color: #fff !important;
        font-size: 16px;
        font-weight: 400;
        font-family: 'Bai Jamjuree', sans-serif !important;

    }

    .enterWhiteSmall {
        color: #fff !important;
        font-size: 14px;
        text-decoration: underline;
        text-underline-offset: 4px;
        text-decoration-color: #ff7200;
        cursor: pointer;
        font-weight: 400;
        font-family: 'Bai Jamjuree', sans-serif !important;
    }

    .enterWhiteSmall:hover {
        color: #ff7200 !important;
        text-underline-offset: 4px;
        text-decoration-color: #ff7200;
        font-weight: 400;
        font-family: 'Bai Jamjuree', sans-serif !important;
    }



    .homeSlider_swiper > .swiper-container {
        width: 100%;

        position: absolute;
        top: 34px;
        right: 0px;
        overflow: visible;

    }

    .homeSlider_swiper > .swiper-slide {
        background-position: center;
        background-size: cover;
        width: 420px;
        height: 400px;
        transition: all 200ms linear;
        position: relative;
        background-color: #141414;
        box-shadow: 2px 2px 10px black;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;



    }

    .homeSlider_swiper > .swiper-slide>img {
        width: 40%;
        position: relative;
        bottom: 59px;
        left: 25%;

    }

    .homeSlider_swiper > .swiper-slide h2 {
        font-size: 29px;
        color: #ff7200;
        text-align: center;
        font-weight: 700;
        font-family: 'Fjalla One', sans-serif;



    }

    .homeSlider_swiper > .swiper-slide-active {
        width: 490px;
        height: 440px;



    }

    .homeSlider_swiper > .swiper-slide.swiper-slide-active .initial_data {
        display: none;
    }

    .supply_class {
        width: 90%;
        position: absolute;
        top: 215px;
        left: 5%;
        /* border: 2px solid red; */

    }

    .supply_class p {
        color: black;
        font-size: 16px;
        text-align: justify;
        line-height: 21px;

    }

    .block_class {
        width: 95%;
        position: absolute;
        top: 189px;
        left: 2.5%;
        /* border: 2px solid red; */

    }

    .block_class p {
        color: black;
        font-size: 16px;
        text-align: justify;
        line-height: 20.8px;

    }

    .iot_class {
        width: 95%;
        position: absolute;
        top: 232px;
        left: 2.5%;
        /* border: 2px solid red; */

    }

    .iot_class p {
        color: black;
        font-size: 16px;
        text-align: justify;
        line-height: 20px;

    }

    .active_para {
        width: 84%;
        position: absolute;
        top: 220px;
        left: 8%;

    }

    .active_para p {
        color: black;
        font-size: 18px;
        text-align: justify;
    }

    .container .swiper-button-next {
        color: #fff !important;
        position: absolute;
        top: 200px !important;

    }


    .container .swiper-button-prev {
        color: #fff !important;
        position: absolute;
        top: 200px !important;
    }

    .swiper-pagination {
        position: absolute;
        top: 510px !important;
        z-index: 10;
    }

    .container swiper-pagination-current {
        background: black !important;
    }

    .container .swiper-pagination-bullet-active {
        width: 14px;
        height: 14px;
        background-color: #fff !important;



    }

    .container .swiper-pagination-bullet {
        opacity: 1;
        width: 14px;
        height: 14px;
        background-color: black;
    }


    .customer_div1 {
        display: none;
    }

    .customer_div {
        width: 100%;
        height: 700px;

        /* position: absolute;
        top: 4705px ; */
        position: relative;
        display: flex;
        justify-content: center;
    }

    .customer_div h1 {

        font-size: 100px;
        color: black;
        text-shadow: none;
        position: absolute;
        top: 40px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;

        background-image: linear-gradient(#ff7200, #ff7200);
        background-repeat: no-repeat;
        line-height: 149px;
        background-size: 0% 6px;
        background-position: bottom;
        letter-spacing: 0px !important;


    }

    .customers_div_1 {
        width: 96%;
        height: 228px;

        position: absolute;
        top: 270px;
        display: flex;
        justify-content: space-around;

    }

    .customers_div_1 div {
        width: 14%;
        height: 218px;

        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }

    .customers_div_1 img {
        /* filter: grayscale(100%); */
    }

    .customers_div_1 img:hover {
        cursor: pointer;
        filter: none;
        /* filter: grayscale(0%); */
    }


    .customers_div_2 {
        width: 96%;
        height: 228px;

        position: absolute;
        top: 520px;
        display: flex;
        justify-content: space-around;

    }

    .customers_div_2 div {
        width: 16%;
        height: 218px;

        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }


    .customers_div_2 img {
        /* filter: grayscale(100%); */
    }

    .customers_div_2 img:hover {
        cursor: pointer;
        filter: none;
        /* filter: grayscale(0%); */
    }

    .customers_div_3 {
        width: 24%;
        height: 218px;
        /* border: 2px solid green; */
        position: absolute;
        top: 771px;
        display: flex;
        justify-content: space-between;
    }

    .customers_div_3 div {
        width: 50%;
        height: 218px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }

    .customer_images {
        width: 180px;
        /* height: 120px; */
    }

    #cma {
        width: 180px;
        height: 140px;
    }

    #bharat {
        width: 150px;
        height: 169px;
    }

    #aditya {
        width: 210px;
        height: 170px !important;
    }

    #lg {
        width: 150px;
        height: 80px;
    }

    #dadri {
        width: 200px;
        height: 150px;
    }

    #hundred {
        position: relative;
        top: 14px;

    }

    #client_100 {
        position: relative;
        top: 14px;

    }

    #more_3500 {
        width: 100%;
        height: 66px;
        position: relative;
        top: 750px;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    #sch {
        width: 50%;
    }

    #more_3500 h1 {
        position: relative;
        font-size: 52px;
        color: #424141;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
        background-image: linear-gradient(white, white);
        background-repeat: none;
        background-size: 0% 0px;
        font-family: 'Fjalla One', sans-serif;

    }

    #more_3500 h1:hover {
        color: black;
    }

    /*  */

    .whatsNew {
        width: 100%;
        height: 740px;
        /* position: absolute;
        top: 5594px ; */
        position: relative;
        justify-content: center;


    }





    .whatsNew img {
        width: 100%;
        height: 100%;
        z-index: -1;

    }

    .whatsNew h1 {

        z-index: 1;
        color: #ff7200;
        position: relative;
        bottom: 715px;
        align-items: center;
        text-align: center;
        letter-spacing: 0px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 1px;
        background-image: linear-gradient(#fff, #fff);
        font-size: 100px;
        background-repeat: no-repeat;
        line-height: 160px;
        background-size: 0% 6px;
        background-position: bottom;
    }

    .whatsNew3cards {
        width: 100%;
        height: 550px !important;
        /* border: 2px solid cyan;    */
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        bottom: 735px;
        border-radius: 12px !important;
        overflow: hidden;
    }


    .whatsNew3cards .swiper-slide {
        background-position: center;
        background: #141414;
        background-size: cover;
        width: 420px !important;
        height: 500px !important;
        transition: all 200ms linear;
        position: relative;
        top: 0px;
        left: 0px;
        /* background-color: rebeccapurple; */
        box-shadow: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 60px;
    }



    .whatsNew3cards .swiper-slide-active {
        width: 420px !important;
        height: 500px !important;
        position: relative;
        top: 0px;
        box-shadow: none;

    }

    .new_card_1 {
        max-width: 100% !important;
        height: 500px;
        display: flex !important;
        flex-direction: column;
        position: relative;
        left: 0px;
        /* border: 1px solid red;  */
        align-items: center;
        text-align: center;
        box-shadow: none !important;
    }

    .new_img {
        width: 100%;
        height: 177px;
        position: relative;
        left: 0px;
        top: 1rem;

    }

    .whatsNew3cards .swiper-slide-active img {
        position: relative;
        top: 0px;

    }

    .new_img img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: absolute;
        left: 0px;

    }

    .new_data {

        position: relative;
        /* top: 175px; */
        display: flex !important;
        flex-direction: column;
        align-items: center !important;
        text-align: center !important;


    }

    .new_data h2 {
        color: #ff7200;
        font-size: 22px;
        position: relative;
        left: 0px !important;
        display: flex !important;
        text-align: center !important;
        align-items: center !important;
        justify-content: center !important;
        font-weight: 700;
        margin-top: 34px;
        font-family: 'Montserrat', sans-serif;
    }

    .new_data p {
        position: relative;
        top: 24px;
        color: white;
        font-size: 15px;
        font-weight: 300;

        padding-left: 12px;
        padding-right: 12px;
    }

    #learn_more {
        position: relative;
        top: 22px;
        color: #ff7200;
        font-size: 14px;

    }

    .whatsNew3cards .swiper-button-next {
        color: white !important;
        position: absolute;
        top: 200px !important;
        right: 0px !important;
    }


    .whatsNew3cards .swiper-button-prev {
        color: white !important;
        position: absolute;
        top: 200px !important;
        left: 0px !important;
    }


    .whatsNew3cards .swiper-pagination {
        display: none;
    }

    #rightarrow1 {
        color: #fff;
        display: none;
        position: relative;
    }

    .lynkit_feed {
        width: 100%;
        height: 1119px;
        background-color: rgb(22, 22, 22);
        /* position: absolute; */
        /* background-color: rgb(224, 223, 223); */
        /* top: 7119px; */
        position: relative;
    }

    .lynkit_feed h1 {
        color: #ff7200;
        position: relative;
        top: 33px;
        align-items: center;
        text-align: center;
        letter-spacing: 0px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 1px;
        font-size: 100px;

    }

    .feed_inner {
        width: 90%;
        height: 540px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 109px;
        display: flex;

    }


    .feed_right {
        width: 100%;
        height: 720px;
        box-shadow: 0px 0px 10px grey;
        /* border: 1px solid red; */
    }

    .awardsSection {
        background-color: #fff !important;
        height: auto;
        position: relative;
        /* top: 4477px; */
    }

    .awardsInner {
        height: 100%;
    }

    .awardsHeading {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
    }

    .awardsSpans {
        font-size: 100px;
        color: #000;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        position: relative;
        top: 30px;
        font-weight: 800;
    }

    .awardsListing {
        width: 100%;
        height: 750px;
        position: relative;
        top: 72px;
        /* display: flex; */
        align-items: center;
    }

    .awardsListing700 {
        display: none;
    }

    .awardsRow1 {
        width: 78%;
        height: 250px;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
    }

    .awardBox2 {
        width: 25%;
        height: auto;
        margin-left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 5px;
    }

    .awardBox {
        width: 20%;
        height: 240px;
        margin-left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .awardImg {
        max-width: 100%;
        max-height: 100%;
        height: 100%;
        object-fit: contain;
    }

    .awardsListing::-webkit-scrollbar {
        width: 5px;
        cursor: pointer;
    }

    /* Track */
    .awardsListing::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px grey;
        border-radius: 10px;
        cursor: pointer;
    }

    /* Handle */
    .awardsListing::-webkit-scrollbar-thumb {
        background: rgb(7, 7, 7);
        border-radius: 10px;
        cursor: pointer;
    }

    /* Handle on hover */
    .awardsListing::-webkit-scrollbar-thumb:hover {
        background: #ff7200;
        cursor: pointer;
    }

    .grandParent1 {
        width: 100%;
        height: auto;
    }

    .grandParent2 {
        width: 100%;
        height: 100vh;
        display: none;
    }

    .modalOuter {
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0px;
        z-index: 9999999999;
        background-color: #00000087;
        animation: popup 1s;
    }

    @keyframes popup {
        0% {
            transform: scale(1);
            transform: translateY(400px);
            animation-timing-function: ease-out;
            opacity: 0.1;
        }

        50% {
            transform: scale(1.2);
            opacity: 0.2;
        }

        60% {
            transform: scale(1.1);
            opacity: 0.4;
        }

        70% {
            transform: scale(1.2);
            opacity: 0.6;
        }

        80% {
            transform: scale(1);
            opacity: 0.8;
        }

        90% {
            transform: scale(1.1);
            opacity: 1;
        }

        100% {
            transform: scale(1);
            opacity: 0.7;
        }
    }

    .modalCover {
        bottom: auto;
        left: 50%;
        max-height: calc(100% - 100px);
        padding-right: 0 !important;
        padding-top: 0px;
        padding-bottom: 10px;
        position: fixed;
        right: auto;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 55%;
        z-index: 9999999;
        background-color: #fff;
        height: auto;
        border: 2px solid #ff7200;
    }

    .modalInnerCover {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .modalHeader {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        flex-direction: column;
        padding: 12px;
    }

    .modalClose {
        width: 35px;
        height: 35px;
        position: absolute;
        cursor: pointer;
        top: 5px;
        right: 5px;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .modalIcon {
        color: #ff7200;
        font-size: 2.5rem;
    }

    .nodalImage {
        width: 10%;
    }

    .modalSpan {
        font-size: 1.4vw;
        color: #000;
    }

    .modalText {
        font-weight: 300;
        color: #202020 !important;
    }

    .modalBodi {
        width: 100%;
        height: auto;
        padding: 16px;
    }

    .banner_video_slider {
        display: none !important;
    }

    .api-div {
        position: absolute;
        height: 1200px;
        top: 70px;
        width: 100%;
        background-color: rgb(22, 22, 22);
        align-items: center;
    }


    .api_card_1 {
        max-width: 100% !important;
        height: 427px;
        display: flex !important;
        flex-direction: column;
        position: absolute;
        left: 0px;
        /* border: 1px solid red; */
        align-items: center;
        text-align: center;
    }

    .api-swiper {
        width: 100%;
        height: 510px;
        /* border: 2px solid cyan; */
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 5%;
        border-radius: 12px !important;
        overflow: hidden;
    }

    .api-swiper .swiper-slide {
        background-position: center;
        background: #030303;
        background-size: cover;
        width: 333px !important;
        height: 460px;
        transition: all 200ms linear;
        position: relative;
        top: 10px;
        left: 100px;
        /* background-color: rebeccapurple; */
        box-shadow: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 30px;
        margin-left: 30px;
        border-radius: 10px;
        border: 1px solid #ff7700;

    }

    .api-card-data {

        position: relative;
        top: 175px;
        width: 100%;
        height: 240px;
        display: flex;
        flex-direction: column;
    }

    .api-card-data h2 {
        color: #ff7200;
        font-size: 18px;
        position: relative;
        left: 0px;
        text-align: center;
        align-items: center !important;
        font-weight: 700;
        margin: 18px 8px 5px;
        /* font-family: 'Montserrat', sans-serif; */
        font-family: 'Montserrat', sans-serif;
    }

    .api-card-data p {
        position: relative;
        top: 9px;
        color: white;
        font-size: 13px;
        font-weight: 300;

        padding-left: 16px;
        padding-right: 16px;
    }

    .api-card-img {
        width: 100%;
        height: 177px;
        position: absolute;
        left: 0px;
        top: 0px;
        border-radius: 10px;


    }

    .api-card-img img {
        width: 85%;
        height: 100%;
        object-fit: contain;
        position: relative;
        left: 0px;

    }

    .api-swiper .swiper-slide-active {
        width: 300px;
        height: 460px;
        position: relative;
        top: 10px;


    }

    .api-swiper .swiper-slide-active img {
        position: relative;
        top: 0px;

    }


    .api-swiper .swiper-button-next {
        display: none;
    }

    .api-swiper .swiper-button-prev {
        display: none;
    }


    .api-swiper .swiper-pagination {
        bottom: -55px;
        left: 4.5%;
    }

    .swiper-pagination-bullet-active {
        opacity: 1;
        background: #ff7700;
        filter: brightness(1.5);
    }

    .swiper-pagination-bullet {
        background: #8f8f8f;
    }

    .home_page_footer {
        width: 100%;
        /* position: absolute;
        top: 8170px; */
        position: relative;
    }


}

/* media query 2 */
@media only screen and (min-width:1901px) and (max-width:2281px) {
    * {
        margin: 0px;
        box-sizing: border-box;

    }

    .PEnterDegign {
        display: none;
    }

    .EnterpriseTextElementNew {
        display: none;
    }

    .whatsNew500 {
        display: none;
    }

    .api-div500 {
        display: none;
    }

    .mainMobile {
        display: none;
    }

    body {
        width: 100%;
        height: 100%;
        display: inline;
        z-index: -1;

    }

    .counts {
        font-size: 3rem;
    }

    #lll {
        position: relative;
        left: 30px;
        font-size: 6px;
        font-weight: lighter;
        text-decoration: none;
    }

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #ff7200;
        border-radius: 7px;
    }

    .banner_video-_slider {
        display: none !important;
    }

    .video_part {
        width: 100%;
        height: 1100px;
        position: relative;

        z-index: 1;


    }

    .video_part p {
        position: absolute;
        top: 100px;
        font-size: 30px;
        color: black;
        z-index: -1;
        visibility: hidden;

    }


    .video_part .headings {
        width: 100%;
        height: 72%;
        position: relative;
        top: 20px;
    }

    video {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50px;
        object-fit: initial;
        z-index: 1;
    }


    .three_btns_for_videos {
        width: 5%;
        height: 30px;
        display: flex;
        position: relative;
        top: -34px;
        color: #ff7200;
        justify-content: center;
        align-items: center;
        justify-content: space-between;
        z-index: 10;



    }

    .three_btns_for_videos button {

        width: 14px;
        height: 14px;
        border-radius: 12px;
        border: none;

    }


    .banner_headings {
        width: 35%;
        height: 20%;
        color: #ff7200;

        bottom: 505px;
        position: relative;
        left: 55px;
        z-index: 10;

        /*  border: 0.2px solid red;  */
    }

    .banner_headings h4 {
        font-size: 99px;
        font-weight: 900;
        text-shadow: 1px 5px 1px black;
        font-family: 'Fjalla One', sans-serif;
        line-height: 98px;
    }


    .banner_headings h6 {
        color: #fff;
        font-size: 19px;
        text-shadow: 1px 2px 1px black;
        position: relative;
        top: 6px;
        left: 2px;
        font-weight: 500 !important;
        font-family: 'Montserrat', sans-serif;
    }

    .banner_btn {
        background-color: #ff7200;
        border: none;
    }

    .main {
        width: 100%;
        /* height: 718px; */
        height: 800px;
        position: relative;
        top: -5px;
        background-color: #f7f7f7;
        z-index: 2;
        top: 50px;
    }


    .Product_heading {
        position: relative;
        text-align: center;
        justify-content: center;
        align-items: center;


    }

    .Product_heading h2 {

        font-size: 80px;
        color: black;
        text-shadow: none;
        position: relative;
        top: 10px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-image: linear-gradient(#ff7200, #ff7200);

        background-repeat: no-repeat;
        line-height: 140px;
        background-size: 0% 0px !important;
        background-position: bottom;
    }

    .Product_titles {
        width: 87%;
        height: 70px;
        position: relative;
        top: 20px;
        left: 0%;
        display: flex;
        justify-content: space-between;
        text-decoration: none;
        z-index: 5;
    }

    .Product_titles img {
        display: inline-table;
    }

    #ll {
        color: #fff;
    }

    .Product_titles button {
        color: #fff;
        font-size: 22px;
        padding-top: 1px;
        cursor: pointer;
        border: none;
        background-color: transparent;
        width: 167px;
        text-decoration: none;
    }

    .Product_titles img {
        color: #fff;
    }

    .Product_titles .active {


        background-color: #ff7200;
        border-radius: 38px;
        width: 184px;
        height: 70px;
        justify-content: center;
        text-align: center;
        border: none;
        box-shadow: 3px 3.5px 1px grey;
        position: relative;
        bottom: 5px;

    }

    #active_img {
        vertical-align: middle;
        width: 40px;
        height: 42px;
        filter: brightness(0) invert(1);


    }

    #active_img5 {
        vertical-align: middle;
        width: 37px;
        height: 38px;
        filter: brightness(0) invert(1);
        position: relative;
        top: 6px;
        right: -1px;


    }

    #icon_img1 {
        vertical-align: middle;
        width: 50px;
        position: relative;
        bottom: 5px;


    }

    #icon_img2 {
        vertical-align: middle;
        width: 45px;
        height: 40px;
        position: relative;
        left: -8px;
        bottom: 6px;

    }

    #icon_img3 {
        vertical-align: middle;
        width: 58px;
        height: 58px;
        position: relative;
        left: -1px;
        bottom: 7px;

    }

    #icon_img4 {
        vertical-align: middle;
        width: 48px;
        position: relative;
        bottom: 9px;
        left: 26px;

        height: 50px;


    }

    #icon_img5 {
        vertical-align: top;
        position: relative;
        top: 1px;
        right: -15px;
        height: 42px;
        width: 42px;

    }

    #icon_5 {
        cursor: pointer;

    }

    #icon_img6 {
        vertical-align: top;
        position: relative;
        bottom: 10px;
        left: 33px;
        height: 52px;
        width: 48px;

    }

    #active_text {
        color: #fff;
        font-size: 15px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;


    }

    #active_text_2 {
        color: #fff;
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        position: relative;
        right: -5px;
        top: 4px;
    }

    #active_text1 {
        color: #fff;
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        display: block;
        position: relative;
        bottom: 12px;
        right: -17px;


    }

    #aligning_text1 {
        color: black;
        position: relative;
        top: -3px;
        left: 5px;
        font-size: 15px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
    }

    #aligning_text2 {
        color: black;
        position: relative;
        top: -5px;
        left: 2px;
        font-size: 15px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
    }

    #aligning_text3 {
        color: black;
        position: relative;
        top: -5px;
        left: 3px;
        font-size: 15px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
    }

    #aligning_text4 {
        color: black;
        position: relative;
        top: -12px;
        left: 34px;
        font-size: 15px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
    }

    #aligning_text {
        color: black;
        position: relative;
        top: 0px;
        left: 22px;
        font-size: 15px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
    }

    #aligning_text5 {
        color: black;
        position: relative;
        top: -4px;
        left: 39px;
        font-size: 15px;
        font-weight: 500;
    }

    #warehouse1 {
        color: black;
        display: block;
        position: relative;
        bottom: 15px;
        right: -20px;
    }



    #twoD {
        color: black;
        position: relative;
        top: -1px;
        left: 10px;
    }


    #product_images {
        width: 564px;
        position: relative;
        left: 17%;
        bottom: -4%;


    }



    #video_product_2 {
        width: 100%;
        height: 87%;
        margin-top: 0px;
        margin-left: -2px;
    }

    .neeProd {
        width: 87%;
        height: auto;
        display: flex;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
        margin-top: 45px;
        box-shadow: 0px 0px 10px grey;
        border-radius: 10px;
    }

    .newProdLeft {
        width: 57%;
        padding: 12px;
    }

    .prodSpan {
        font-size: 52px;
        /* padding-left: 1.5%; */
        font-weight: 600;
        position: relative;
        top: 15px;
        line-height: 60px;
        color: #ff7200;
        font-family: 'Fjalla One', sans-serif;
    }

    .prodData {
        font-size: 17px;
        font-weight: 300;
        text-align: justify;
        text-shadow: none;
        line-height: 24px;
        letter-spacing: 0px;
    }

    .prodBtn {
        width: auto;
        height: auto;
        padding: 9px;
        border: none;
        font-size: 10px;
        background-color: #ff7200;
        cursor: pointer;
        border-radius: 20px;
        color: #fff;
        box-shadow: 2px 2px grey;
        text-decoration: none;
    }

    .prodBtn:hover,
    .prodBtn:focus,
    .prodBtn:active {
        text-decoration: none;
        color: #fff;
    }

    .newProdRight {
        width: 43%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 22px;
        padding-bottom: 22px;
        overflow: hidden;
    }

    .prodImage {
        width: 50%;
        height: 350px;
    }

    .enterprise_left1 {
        display: block;
    }

    .enterprise_left2 {
        display: none;
    }

    .achievements_div {
        width: 100%;
        height: 930px;
        /* position: absolute; */
        background-color: #070707;
        /* top: 1580px; */
        position: relative;
        text-align: center;
        z-index: 5;
    }

    .achievements_div h1 {

        font-size: 80px;
        color: #ff7200;
        text-shadow: none;
        position: relative;
        top: 20px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-image: linear-gradient(#fff, #fff);

        background-repeat: no-repeat;
        line-height: 160px;
        background-size: 0% 6px;
        background-position: bottom;
    }

    .achivementElem {
        width: 80%;
        height: 71%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 21px;
    }

    .achiveR1 {
        width: 100%;
        height: 50%;
        display: flex;
    }

    .achieverc1 {
        width: 20%;
        height: 100%;
        margin-right: 8px;
        position: relative;
    }

    .achieveImg {
        width: 100%;
        height: 100%;
        position: relative;
        justify-content: flex-start;
        text-align: left;
        align-items: center;
        z-index: 1;
    }

    .achieve100 {
        position: absolute;
        top: 20px;
        left: 30px;
        font-size: 3rem;
        font-weight: 600;
        color: #ff7200;
        z-index: 10;
    }

    .achieveClient {
        position: absolute;
        top: 70px;
        left: 30px;
        font-weight: 500;
        z-index: 10;
        font-size: 1.3rem;
    }

    .achieveInstall {
        position: absolute;
        top: 90px;
        left: 30px;
        font-weight: 500;
        z-index: 10;
        font-size: 1.3rem;
    }

    .achieverc2 {
        width: 30%;
        height: 100%;
        margin-right: 8px;
    }

    .achiveI1 {
        width: 100%;
        height: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #ff7200;
        box-shadow: 0px 0px 24px black inset;
        margin-bottom: 8px;
    }

    .threefive {
        font-size: 2.3rem;
        color: #fff;
        font-weight: 700;
        position: relative;
        top: 10px;
    }

    .iot {
        font-size: 1.3rem;
        font-weight: 400;
        color: #fff;
    }


    .achiveI2 {
        width: 100%;
        height: 57%;
        position: relative;
    }

    .vehicle_text {
        color: #ff7200;
        font-size: 4.5rem;
        font-weight: 600;
        position: absolute;
        z-index: 10;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .vehicle_sub_text {
        color: #fff;
        position: absolute;
        z-index: 10;
        font-size: 1.5rem;
        top: 45%;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .achieverc3 {
        width: 20%;
        height: 100%;
        margin-right: 8px;
    }

    .achieve50 {
        width: 100%;
        height: 49%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #464444;
        box-shadow: inset 1px 1px 22px #000;
        margin-bottom: 6px;
    }

    .achieve50b {
        width: 100%;
        height: 49%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #d3d3d3;
        box-shadow: inset 1px 1px 19px #000;
        margin-bottom: 6px;
    }

    .m100m {
        color: #ff7200;
        font-size: 2.3rem;
        font-weight: 600;
    }

    .transactions {
        color: #fff;
        position: relative;
        bottom: 7px;
        font-size: 1rem;
    }

    .img_overlay {
        position: absolute !important;
    }

    .overlay_main_text {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -70%);

        z-index: 10;
        color: #ff7200;
        font-size: 2.25rem;
        font-weight: 600;
    }

    .overlay_sub_text {
        color: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, 0);
        font-size: 1rem;
        z-index: 10;
    }

    .transactionsm {
        color: #000;
        position: relative;
        bottom: 7px;
        font-size: 1.3rem;
    }

    .m100black {
        color: #000;
        font-size: 2.3rem;
        font-weight: 600;
    }



    .achieveI31 {
        width: 100%;
        height: 48%;

        margin-bottom: 8px;
    }

    .achieveI32 {
        width: 100%;
        height: 49%;
        margin-bottom: 8px;
    }

    .achieverc4 {
        width: 30%;
        height: 100%;
    }

    .achiveR2 {
        width: 100%;
        height: 50%;
        display: flex;
        position: relative;
    }

    .ach5 {
        width: 29%;
        height: 100%;
        margin-left: 8px;
        margin-top: 8px;
        position: relative;
    }

    .fifteen {
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 10;
    }

    .fifteenTrips {
        position: absolute;
        top: 70px;
        left: 35px;
        z-index: 10;
        font-size: 1.3rem;
    }

    .ach6 {
        width: 21%;
        height: 100%;
        margin-top: 8px;
        position: relative;
    }

    .ach7 {
        width: 20%;
        height: 100%;
        margin-top: 8px;
        margin-left: 8px;
        position: relative;
    }

    .m1000 {
        color: #fff;
        position: absolute;
        top: 20px;
        left: 20px;
        font-size: 2.3rem;
        z-index: 10;
    }

    .lynkassist {
        color: #fff;
        position: absolute;
        top: 60px;
        left: 20px;
        font-size: 1.3rem;
        z-index: 10;
    }

    .lynkassistLoc {
        color: #fff;
        position: absolute;
        top: 80px;
        left: 20px;
        font-size: 1.3rem;
        z-index: 10;
    }

    .ach8 {
        width: 30%;
        height: 100%;
        margin-left: 8px;
        margin-top: 8px;
        display: flex;
        position: relative;
    }

    .m3 {
        position: absolute;
        top: 20px;
        left: 60px;
        z-index: 10;
        color: #ff7200;
        font-size: 5rem;
        font-weight: 600;
    }

    .rfid_cir {
        color: #fff;
        position: absolute;
        top: 110px;
        left: 60px;
        font-size: 2rem;
        z-index: 10;
    }

    .lynkit_testomonial {
        width: 100%;
        height: 950px;
        /* position: absolute;
        top: 2452px; */
        position: relative;
    }

    .Enterprise_div {
        width: 100%;
        height: 800px;
        /* position: absolute;
        top: 3349px; */
        position: relative;
        display: flex;
        justify-content: center;
        overflow-x: hidden;
    }

    .Enterprise_div h1 {
        font-size: 80px;
        color: #ff7200;
        text-shadow: none;
        position: absolute;
        top: 22px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-image: linear-gradient(#fff, #fff);
        background-repeat: no-repeat;
        line-height: 146px;
        background-size: 0% 6px;
        background-position: bottom;
        z-index: 2;
    }

    .enterprise_main_div {
        width: 80%;
        height: 500px;
        /*  background-color: aqua; */
        position: relative;
        top: 259px;
        display: flex;
    }

    .Enterprise_div img {
        position: absolute;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .enterOuter {
        width: 100%;
        height: 84%;
        z-index: 1;
        position: relative;
        top: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .enterInner {
        width: 90%;
        height: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .enterTop {
        width: 90%;
        height: 60%;
        display: flex;
        position: relative;
    }

    .enterpriseSvgCls {
        width: 100%;
        height: 100px;
        position: absolute;
        z-index: 101;
    }

    .enterBot {
        width: 90%;
        height: 20%;
        display: flex;
        position: relative;
    }

    .EnterpriseTextElement {
        width: 90%;
        height: auto;
        padding: 15px;
        position: relative;
        text-align: justify;
        /* border: 1px solid #404040; */
        /* box-shadow: 0px 0px 10px #585858; */
        /* background-color: #fff; */
    }

    .enterSpan {
        font-weight: 300;
        color: #F5F5F5 !important;
    }

    .enterpriseClose {
        width: 20px;
        height: 20px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: -10px;
        right: -10px;
        /* background-color: #ff7200; */
        border-radius: 50%;
    }

    .entSpanActive {
        font-size: 14px;
        color: #ff7200;
        font-weight: 800;
        font-family: 'Bai Jamjuree', sans-serif ! important;
    }

    .enterBox {
        width: 20%;
        height: 100%;
    }

    .boxBorder {
        border-right: 1px solid #ff7200;
    }

    .boxBorder:last-child {
        border: none;
    }

    .enterData {
        width: 100%;
        height: 25%;
        position: relative;
        top: 28%;
        display: flex;
    }

    .entCont1 {
        width: 40%;
        height: 100%;
        display: flex;
        justify-content: center !important;
        align-items: center !important;
    }

    .entCont2 {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .entImg {
        width: 25% !important;
        height: 75% !important;
        position: relative;
        left: 5% !important;
    }

    .entSpan {
        font-size: 17px;
        color: #fff;
        font-weight: 800;
        font-family: 'Bai Jamjuree', sans-serif ! important;
    }

    .leftMover {
        position: relative;
        left: 15% !important;
    }

    .bottomMover {
        position: relative;
        bottom: 18% !important;
    }

    .enterBW {
        width: 95%;
        height: 35%;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        justify-content: flex-start;
        align-items: center;
        display: flex;
    }

    .enterBW1 {
        width: 95%;
        height: 20% !important;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        display: flex;
        margin-left: auto;
        margin-right: auto;
    }

    .enterOrange {
        color: #ff7200;
        font-size: 16px;
        font-weight: 500;
        font-family: 'Bai Jamjuree', sans-serif ! important;
    }

    .enterWhite {
        color: #fff !important;
        font-size: 14px;
        font-weight: 400;
        font-family: 'Bai Jamjuree', sans-serif !important;

    }

    .enterWhiteSmall {
        color: #fff !important;
        font-size: 12px;
        text-decoration: underline;
        text-underline-offset: 4px;
        text-decoration-color: #ff7200;
        cursor: pointer;
        font-weight: 400;
        font-family: 'Bai Jamjuree', sans-serif !important;
    }

    .enterWhiteSmall:hover {
        color: #ff7200 !important;
        text-underline-offset: 4px;
        text-decoration-color: #ff7200;
        font-weight: 400;
        font-family: 'Bai Jamjuree', sans-serif !important;
    }

    .homeSlider_swiper > .swiper-container {
        width: 100%;
        position: absolute;
        right: 0px;
        overflow: visible;
    }

    .homeSlider_swiper > .swiper-slide {
        background-position: center;
        background-size: cover;
        width: 360px;
        height: 360px;
        transition: all 200ms linear;
        position: relative;
        background-color: #141414;
        box-shadow: 2px 2px 10px black;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;



    }

    .homeSlider_swiper > .swiper-slide>img {
        width: 40%;
        position: relative;
        bottom: 59px;
        left: 29%;

    }

    .homeSlider_swiper > .swiper-slide h2 {
        font-size: 30px;
        color: #ff7200;
        position: relative;
        bottom: 20px;
        text-align: center !important;
        font-weight: 700;
        font-family: 'Fjalla One', sans-serif;


    }

    .homeSlider_swiper > .swiper-slide-active {
        width: 400px;
        height: 400px;



    }

    .homeSlider_swiper > .swiper-slide.swiper-slide-active .initial_data {
        display: none;
    }

    .supply_class {
        width: 90%;
        position: absolute;
        top: 188px;
        left: 5%;
        /* border: 2px solid red; */
        cursor: default !important;

    }

    .supply_class p {
        color: black;
        font-size: 16px;
        text-align: justify;
        line-height: 19px;

    }

    .block_class {
        width: 90%;
        position: absolute;
        top: 163px;
        left: 5%;
        /* border: 2px solid red; */

    }

    .block_class p {
        color: black;
        font-size: 16px;
        text-align: justify;
        line-height: 19px;

    }

    .iot_class {
        width: 95%;
        position: absolute;
        top: 196px;
        left: 2.5%;
        /* border: 2px solid red; */

    }

    .iot_class p {
        color: black;
        font-size: 15.5px;
        text-align: justify;
        line-height: 19px;

    }

    .active_para {
        width: 84%;
        position: absolute;
        top: 220px;
        left: 8%;

    }

    .active_para p {
        color: black;
        font-size: 16px;
        text-align: justify;
    }

    .container .swiper-button-next {
        color: #fff !important;
        position: absolute;
        top: 160px !important;
    }

    .container .swiper-button-prev {
        color: #fff !important;
        position: absolute;
        top: 160px !important;
    }

    .container .swiper-pagination {
        position: absolute;
        top: 470px !important;
        z-index: 10;
    }

    .container swiper-pagination-current {
        background: black;
    }

    .container .swiper-pagination-bullet-active {
        width: 10px;
        height: 10px;
        background-color: #fff !important;



    }

    .container .swiper-pagination-bullet {
        opacity: 1;

        background-color: black;
    }




















    .customer_div1 {
        display: none;
    }

    .customer_div {
        width: 100%;
        height: 800px;

        /* position: absolute;
        top: 4115px; */
        position: relative;
        display: flex;
        justify-content: center;
    }

    .customer_div h1 {

        font-size: 80px;
        color: black;
        text-shadow: none;
        position: absolute;
        top: 39px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-image: linear-gradient(#ff7200, #ff7200);
        background-repeat: no-repeat;
        line-height: 149px;
        background-size: 0% 6px;
        background-position: bottom;
        letter-spacing: 0px !important;


    }

    .customers_div_1 {
        width: 94%;
        height: 198px;

        position: absolute;
        top: 230px;
        display: flex;
        justify-content: space-around;

    }

    .customers_div_1 div {
        width: 13%;
        height: 198px;

        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }

    .customers_div_1 img {
        /* filter: grayscale(100%); */
    }

    .customers_div_1 img:hover {
        cursor: pointer;
        filter: none;
        /* filter: grayscale(0%); */
    }


    .customers_div_2 {
        width: 94%;
        height: 198px;

        position: absolute;
        top: 456px;
        display: flex;
        justify-content: space-around;

    }

    .customers_div_2 div {
        width: 15.4%;
        height: 198px;

        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }

    .customers_div_2 img:hover {
        cursor: pointer;
        filter: none;
        /* filter: grayscale(0%); */
    }

    .customers_div_3 {
        width: 23%;
        height: 182px;
        /* border: 2px solid green; */
        position: absolute;
        top: 680px;
        display: flex;
        justify-content: space-between;
    }

    .customers_div_3 div {
        width: 50%;
        height: 198px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }

    .customers_div_3 img {
        filter: grayscale(100%) !important;
    }

    .customers_div_3 img:hover {
        cursor: pointer;
        filter: none;
        filter: grayscale(0%);
    }

    .customer_images {
        width: 160px;
    }

    #cma {
        width: 170px;
        height: 140px;
    }

    #bharat {
        width: 125px;
        height: 139px;
    }

    #aditya {
        width: 140px;
        height: 130px;
    }

    #lg {
        width: 145px;
        height: 80px;
    }

    #dadri {
        width: 150px;
        height: 130px;
    }

    #hundred {
        position: relative;
        top: 14px;

    }

    #client_100 {
        position: relative;
        top: 14px;

    }

    #sch {
        width: 56%;
    }

    #more_3500 {
        width: 100%;
        height: 66px;
        position: relative;
        top: 660px;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    #more_3500 h1 {
        position: relative;
        font-size: 50px;
        color: #424141;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
        background-image: linear-gradient(white, white);
        background-repeat: none;
        background-size: 0% 0px;
        font-family: 'Fjalla One', sans-serif;

    }

    #more_3500 h1:hover {
        color: black;
    }


    .whatsNew {
        width: 100%;
        height: 740px;
        /* position: absolute;
        top: 4915px; */
        position: relative;
        justify-content: center;


    }





    .whatsNew img {
        width: 100%;
        height: 100%;
        z-index: -1;

    }

    .whatsNew h1 {

        z-index: 1;
        color: #ff7200;
        position: relative;
        bottom: 727px;
        align-items: center;
        text-align: center;
        letter-spacing: 1px !important;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 1px;
        background-image: linear-gradient(#fff, #fff);
        font-size: 80px;
        background-repeat: no-repeat;
        line-height: 160px;
        background-size: 0% 6px;
        background-position: bottom;
        letter-spacing: 0px !important;
    }


    .whatsNew3cards {
        width: 100%;
        height: 540px;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        bottom: 712px;
        border-radius: 12px !important;
        overflow: hidden;
    }

    .whatsNew3cards .swiper-slide {
        background-position: center;
        background: #141414;
        background-size: cover;
        width: 420px !important;
        height: 520px !important;
        transition: all 200ms linear;
        position: relative;
        top: 0px;
        left: 0px;
        /* background-color: rebeccapurple; */
        box-shadow: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 60px;
    }

    .whatsNew3cards .swiper-slide-active {
        width: 420px !important;
        height: 520px;
        position: relative;
        top: 0px;
        box-shadow: none;

    }

    .new_card_1 {
        max-width: 100% !important;
        height: 510px;
        display: flex !important;
        flex-direction: column;
        position: relative;
        left: 0px;
        /* border: 1px solid red;  */
        align-items: center;
        text-align: center;
        box-shadow: none !important;
    }

    .new_img {
        width: 100%;
        height: 177px;
        position: relative;
        left: 0px;
        top: 1rem;

    }

    .whatsNew3cards .swiper-slide-active img {
        position: relative;
        top: 5px;

    }

    .new_img img {
        width: 100%;
        height: 96%;
        object-fit: contain;
        position: absolute;
        left: 0px;

    }

    .new_data {
        position: relative;
        top: 1rem;
        display: flex !important;
        flex-direction: column;
        align-items: center !important;
        text-align: center !important;
    }

    .new_data h2 {
        color: #ff7200;
        font-size: 22px;
        position: relative;
        left: 0px !important;
        display: flex !important;
        text-align: center !important;
        align-items: center !important;
        justify-content: center !important;
        font-weight: 700;
        margin-top: 28px;
        font-family: 'Montserrat', sans-serif;

    }

    .new_data p {
        position: relative;
        top: 24px;
        color: white;
        font-size: 15px;
        font-weight: 300;

        padding-left: 12px;
        padding-right: 12px;
    }

    #learn_more {
        position: relative;
        top: 22px;
        color: #ff7200;
        font-size: 14px;

    }

    .whatsNew3cards .swiper-button-next {
        color: white !important;
        position: absolute !important;
        top: 197px !important;
        right: 0px !important;
    }


    .whatsNew3cards .swiper-button-prev {
        color: white !important;
        position: absolute;
        top: 197px !important;
        left: 0px !important;
    }


    .whatsNew3cards .swiper-pagination {
        display: none;
    }

    #rightarrow1 {
        color: #fff;
        display: none;
        position: relative;
    }

    .lynkit_feed {
        width: 100%;
        /* height: 1119px; */
        height: 1172px;
        background-color: rgb(22, 22, 22);
        /* position: absolute; */
        /* background-color: rgb(224, 223, 223); */
        /* top: 6404px; */
        position: relative;
    }

    .lynkit_feed h1 {
        color: #ff7200;
        position: relative;
        top: 33px;
        align-items: center;
        text-align: center;
        letter-spacing: 0px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 1px;
        font-size: 80px;

    }

    .feed_inner {
        width: 90%;
        height: 540px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 109px;
        display: flex;

    }


    .feed_right {
        width: 100%;
        height: 720px;
        box-shadow: 0px 0px 10px grey;
        /* border: 1px solid red; */
    }

    .awardsSection {
        height: auto;
        position: relative;
        /* top: 4140px; */
    }

    .awardsInner {
        height: 100%;
    }

    .awardsHeading {
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: center;
    }

    .awardsSpans {
        font-size: 80px;
        color: #000;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        position: relative;
        top: 25px;
        font-weight: 800;
    }

    .awardsListing {
        width: 100%;
        height: 656px;
        position: relative;
        /* overflow-x: scroll; */
        top: 100px;
        /* display: flex; */
        align-items: center;
    }

    .awardsListing700 {
        display: none;
    }

    .awardsRow1 {
        width: 78%;
        height: 250px;
        display: flex;
        margin-left: auto;
        margin-right: auto;
    }

    .awardBox2 {
        width: 25%;
        height: auto;
        margin-left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 6px;
    }

    .awardImg {
        width: 510px;
        max-height: 100%;
        height: 100%;
        object-fit: contain;

    }

    .awardsListing::-webkit-scrollbar {
        width: 5px;
        cursor: pointer;
    }

    /* Track */
    .awardsListing::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px grey;
        border-radius: 10px;
        cursor: pointer;
    }

    /* Handle */
    .awardsListing::-webkit-scrollbar-thumb {
        background: rgb(7, 7, 7);
        border-radius: 10px;
        cursor: pointer;
    }

    /* Handle on hover */
    .awardsListing::-webkit-scrollbar-thumb:hover {
        background: #ff7200;
        cursor: pointer;
    }

    .grandParent1 {
        width: 100%;
        height: auto;
    }

    .grandParent2 {
        width: 100%;
        height: 100vh;
    }

    .modalOuter {
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0px;
        z-index: 9999999999;
        background-color: #00000087;
        animation: popup 1s;
    }

    @keyframes popup {
        0% {
            transform: scale(1);
            transform: translateY(400px);
            animation-timing-function: ease-out;
            opacity: 0.1;
        }

        50% {
            transform: scale(1.2);
            opacity: 0.2;
        }

        60% {
            transform: scale(1.1);
            opacity: 0.4;
        }

        70% {
            transform: scale(1.2);
            opacity: 0.6;
        }

        80% {
            transform: scale(1);
            opacity: 0.8;
        }

        90% {
            transform: scale(1.1);
            opacity: 1;
        }

        100% {
            transform: scale(1);
            opacity: 0.7;
        }
    }

    .modalCover {
        bottom: auto;
        left: 50%;
        max-height: calc(100% - 100px);
        padding-right: 0 !important;
        padding-top: 0px;
        padding-bottom: 10px;
        position: fixed;
        right: auto;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 55%;
        z-index: 9999999;
        background-color: #fff;
        height: auto;
        border: 2px solid #ff7200;
    }

    .modalInnerCover {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .modalHeader {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 14px;
        flex-direction: column;
    }

    .modalClose {
        width: 35px;
        height: 35px;
        position: absolute;
        cursor: pointer;
        top: 5px;
        right: 5px;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .modalIcon {
        color: #ff7200;
        font-size: 2.5rem;
    }

    .nodalImage {
        width: 9%;
    }

    .modalSpan {
        font-size: 1.4vw;
        color: #000;
    }

    .modalText {
        font-weight: 300;
        color: #202020 !important;
    }

    .modalBodi {
        width: 100%;
        height: auto;
        padding: 16px;
    }

    .banner_video_slider {
        display: none !important;
    }

    .api-div {
        position: absolute;
        height: 1100px;
        top: 70px;
        width: 100%;
        background-color: rgb(22, 22, 22);
        align-items: center;
    }


    .api_card_1 {
        max-width: 100% !important;
        height: 427px;
        display: flex !important;
        flex-direction: column;
        position: absolute;
        left: 0px;
        /* border: 1px solid red; */
        align-items: center;
        text-align: center;
    }

    .api-swiper {
        width: 100%;
        height: 510px;
        /* border: 2px solid cyan; */
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 5%;
        border-radius: 12px !important;
        overflow: hidden;
    }

    .api-swiper .swiper-slide {
        background-position: center;
        background: #030303;
        background-size: cover;
        width: 333px !important;
        height: 460px;
        transition: all 200ms linear;
        position: relative;
        top: 10px;
        left: 100px;
        /* background-color: rebeccapurple; */
        box-shadow: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 30px;
        margin-left: 30px;
        border-radius: 10px;
        border: 1px solid #ff7700;

    }

    .api-card-data {

        position: relative;
        top: 175px;
        width: 100%;
        height: 240px;
        display: flex;
        flex-direction: column;
    }

    .api-card-data h2 {
        color: #ff7200;
        font-size: 18px;
        position: relative;
        left: 0px;
        text-align: center;
        align-items: center !important;
        font-weight: 700;
        margin: 40px 8px 0px;
        /* font-family: 'Montserrat', sans-serif; */
        font-family: 'Montserrat', sans-serif;
    }

    .api-card-data p {
        position: relative;
        top: 9px;
        color: white;
        font-size: 13px;
        font-weight: 300;

        padding-left: 16px;
        padding-right: 16px;
    }

    .api-card-img {
        width: 100%;
        height: 177px;
        position: absolute;
        left: 0px;
        top: 0px;
        border-radius: 10px;


    }

    .api-card-img img {
        width: 85%;
        height: 100%;
        object-fit: contain;
        position: relative;
        left: 0px;

    }

    .api-swiper .swiper-slide-active {
        width: 300px;
        height: 460px;
        position: relative;
        top: 10px;


    }

    .api-swiper .swiper-slide-active img {
        position: relative;
        top: 0px;

    }


    .api-swiper .swiper-button-next {
        color: #ff7722 !important;
        position: absolute !important;
        right: 60px !important;
        top: 200px !important;
    }

    .api-swiper .swiper-button-prev {
        color: #ff7722 !important;
        position: absolute !important;
        top: 200px !important;
        left: 65px !important;
    }


    .api-swiper .swiper-pagination {
        bottom: -55px;
        left: 4.5%;
    }

    .swiper-pagination-bullet-active {
        opacity: 1;
        background: #ff7700;
        filter: brightness(1.5);
    }

    .swiper-pagination-bullet {
        background: #8f8f8f;
    }

    .home_page_footer {
        width: 100%;
        /* position: absolute;
        top: 7457px; */
        position: relative;
    }
    .banner_headings_messgae {
        width: 100%;
        height: 5%;
        color: white;
        bottom: 686px;
        position: relative;
        z-index: 10;
        text-align: center;
    }

}

/* media query 3 */

@media only screen and (min-width:1537px) and (max-width:1901px) {

    * {
        margin: 0px;
        box-sizing: border-box;
    }

    .PEnterDegign {
        display: none;
    }

    .EnterpriseTextElementNew {
        display: none;
    }

    .whatsNew500 {
        display: none;
    }

    .api-div500 {
        display: none;
    }

    .mainMobile {
        display: none;
    }

    body {
        width: 100%;
        height: 100%;
        display: inline;
        z-index: -1;

    }

    .counts {
        font-size: 3rem;
    }

    #lll {
        position: relative;
        left: 30px;
        font-size: 6px;
        font-weight: lighter;
        text-decoration: none;
    }




    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #ff7200 !important;
        border-radius: 7px;

    }


    .video_part {
        width: 100%;
        height: 935px;
        position: relative;
        z-index: 1;


    }

    .video_part p {
        position: absolute;
        top: 100px;
        font-size: 30px;
        color: black;
        z-index: -1;
        visibility: hidden;
    }


    .video_part .headings {
        width: 100%;
        height: 74%;
        position: relative;
        top: 20px;
    }

    video {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50px;
        object-fit: initial;
        z-index: 1;
    }


    .three_btns_for_videos {
        width: 5%;
        height: 30px;
        display: flex;
        position: relative;
        top: -30px;
        color: #ff7200;
        justify-content: center;
        align-items: center;
        justify-content: space-between;
        z-index: 10;



    }

    .three_btns_for_videos button {

        width: 14px;
        height: 14px;
        border-radius: 12px;
        border: none;

    }


    .banner_headings {
        width: 39%;
        height: 20%;
        color: #ff7200;

        bottom: 425px;
        position: relative;
        left: 55px;
        z-index: 10;

        /* border: 0.2px solid red;  */
    }

    .banner_headings h4 {
        font-size: 89px;
        font-weight: 900;
        text-shadow: 1px 5px 1px black;
        font-family: 'Fjalla One', sans-serif !important;
        line-height: 85px;
    }


    .banner_headings h6 {
        color: #fff;
        font-size: 19px;
        text-shadow: 1px 4px 1px black;
        position: relative;
        top: 8px;
        left: 2px;
        font-weight: 500;
        font-family: 'Montserrat', sans-serif;
    }

    .banner_btn {
        background-color: #ff7200;
        border: none;
    }

    .Product_heading {
        position: relative;
        text-align: center;
        justify-content: center;
        align-items: center;


    }

    .Product_heading h2 {

        font-size: 74px;
        font-weight: 700;
        letter-spacing: -2px;
        text-shadow: none;
        position: relative;
        top: 70px;
        font-family: 'Montserrat', sans-serif;
        background-image: linear-gradient(#ff7200, #ff7200);
        color: black;
        background-repeat: no-repeat;
        line-height: 120px;
        background-size: 26% 4px;
        background-position: bottom;
    }

    .main {
        width: 100%;
        /* height: 772px; */
        height: 900px;
        position: relative;
        background-color: #f7f7f7;
        z-index: 2;
        top: 110px;
    }


    .Product_heading {
        position: relative;
        text-align: center;
        justify-content: center;
        align-items: center;


    }

    .Product_heading h2 {

        font-size: 70px;
        color: black;
        text-shadow: none;
        position: relative;
        top: 10px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-image: linear-gradient(#ff7200, #ff7200);

        background-repeat: no-repeat;
        line-height: 140px;
        background-size: 0% 6px;
        background-position: bottom;


    }


    .Product_titles {

        width: 87%;
        height: 70px;
        position: relative;
        top: 40px;
        left: -1%;
        display: flex;
        justify-content: space-between;
        text-decoration: none;

    }

    .Product_titles img {
        display: inline-table;
    }




    #ll {
        color: #fff;
    }




    .Product_titles button {
        color: #fff;
        font-size: 22px;
        padding-top: 1px;
        cursor: pointer;
        border: none;
        background-color: transparent;
        width: 167px;
        text-decoration: none;
    }

    .Product_titles img {
        color: #fff;
    }

    .Product_titles .active {


        background-color: #ff7200;
        border-radius: 38px;
        width: 184px;
        height: 70px;
        justify-content: center;
        text-align: center;
        border: none;
        box-shadow: 3px 3.5px 1px grey;
        position: relative;
        bottom: 5px;

    }

    #active_img {
        vertical-align: middle;
        width: 40px;
        height: 42px;
        filter: brightness(0) invert(1);


    }

    #active_img5 {
        vertical-align: middle;
        width: 37px;
        height: 40px;
        filter: brightness(0) invert(1);
        position: relative;
        top: 9px;
        right: -2px;


    }

    #icon_img1 {
        vertical-align: middle;
        width: 50px;
        position: relative;
        bottom: 5px;


    }

    #icon_img2 {
        vertical-align: middle;
        width: 45px;
        height: 40px;
        position: relative;
        left: -8px;
        bottom: 6px;

    }

    #icon_img3 {
        vertical-align: middle;
        width: 58px;
        height: 58px;
        position: relative;
        left: -1px;
        bottom: 7px;

    }

    #icon_img4 {
        vertical-align: middle;
        width: 42px;
        position: relative;
        bottom: 9px;
        left: 33px;
        right: 0px;
        height: 47px;


    }

    #icon_img5 {
        vertical-align: top;
        position: relative;
        top: 1px;
        right: -16px;
        height: 42px;
        width: 40px;

    }

    #icon_5 {
        cursor: pointer;

    }

    #icon_img6 {
        vertical-align: top;
        position: relative;
        bottom: 10px;
        left: 31px;
        height: 44px;
        width: 42px;

    }

    #active_text {
        color: #fff;
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;


    }

    #active_text_2 {
        color: #fff;
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        position: relative;
        right: -5px;
        top: 4px;


    }

    #active_text1 {
        color: #fff;
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;

        display: block;
        position: relative;
        bottom: 11px;
        right: -17px;


    }

    #aligning_text1 {
        color: black;
        position: relative;
        top: -3px;
        left: 5px;
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
    }

    #aligning_text2 {
        color: black;
        position: relative;
        top: -5px;
        left: -3px;
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
    }


    #aligning_text3 {
        color: black;
        position: relative;
        top: -5px;
        left: 2px;
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
    }

    #aligning_text4 {
        color: black;
        position: relative;
        top: -9px;
        left: 37px;
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
    }

    #aligning_text {
        color: black;
        position: relative;
        top: 0px;
        left: 19px;
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
    }

    #aligning_text5 {
        color: black;
        position: relative;
        top: -9px;
        left: 35px;
        font-size: 14px;
        font-weight: 500;
    }

    #warehouse1 {
        color: black;
        display: block;
        position: relative;
        bottom: 15px;
        right: -20px;


    }




    #twoD {
        color: black;
        position: relative;
        top: -1px;
        left: 10px;
    }

    .sub .left_div {
        width: 48%;
        height: 85%;
        /*  border: 1px solid red;  */
        position: absolute;
        top: 11px;






    }

    #product_images {
        width: 87%;
        position: relative;
        left: 8%;
        bottom: -4%;


    }



    #video_product_2 {
        width: 100%;
        height: 87%;
        margin-top: 0px;
        margin-left: -2px;
    }

    .neeProd {
        width: 90%;
        height: auto;
        display: flex;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 105px;
        box-shadow: 0px 0px 10px grey;
        border-radius: 10px;
    }

    .newProdLeft {
        width: 57%;
        padding: 12px;
    }

    .prodSpan {
        font-size: 50px;
        /* padding-left: 1.5%; */
        font-weight: 600;
        position: relative;
        top: 15px;
        line-height: 49px;
        color: #ff7200;
        font-family: 'Fjalla One', sans-serif;
    }

    .prodData {
        font-size: 15px;
        font-weight: 300;
        text-align: justify;
        text-shadow: none;
        line-height: 24px;
        letter-spacing: 0px;
    }

    .prodBtn {
        width: auto;
        height: auto;
        padding: 9px;
        border: none;
        font-size: 10px;
        background-color: #ff7200;
        cursor: pointer;
        border-radius: 20px;
        color: #fff;
        box-shadow: 2px 2px grey;
        text-decoration: none;
    }

    .prodBtn:hover,
    .prodBtn:focus,
    .prodBtn:active {
        text-decoration: none;
        color: #fff;
    }

    .newProdRight {
        width: 43%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 22px;
        padding-bottom: 22px;
        overflow: hidden;
    }

    .prodImage {
        width: 55%;
        height: 350px;
    }

    /* *********** */

    .enterprise_left1 {
        display: block;
    }

    .enterprise_left2 {
        display: none;
    }

    .achievements_div {
        width: 100%;
        height: 918px;
        /* position: absolute; */
        background-color: #070707;
        /* top: 1513px; */
        position: relative;
        text-align: center;
        z-index: 3;
    }

    .achievements_div h1 {

        font-size: 70px;
        color: #ff7200;
        text-shadow: none;
        position: relative;
        top: 20px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-image: linear-gradient(#fff, #fff);
        background-repeat: no-repeat;
        line-height: 140px;
        background-size: 0% 6px;
        background-position: bottom;
    }

    .achivementElem {
        width: 88%;
        height: 72%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 21px;
    }

    .achiveR1 {
        width: 100%;
        height: 50%;
        display: flex;
    }

    .achieverc1 {
        width: 20%;
        height: 100%;
        margin-right: 8px;
        position: relative;
    }

    .achieveImg {
        width: 100%;
        height: 100%;
        position: relative;
        justify-content: flex-start;
        text-align: left;
        align-items: center;
        z-index: 1;
    }

    .achieve100 {
        position: absolute;
        top: 20px;
        left: 30px;
        font-size: 2.5rem;
        font-weight: 600;
        color: #ff7200;
        z-index: 10;
    }

    .achieveClient {
        position: absolute;
        top: 70px;
        left: 30px;
        font-weight: 500;
        z-index: 10;
    }

    .achieveInstall {
        position: absolute;
        top: 90px;
        left: 30px;
        font-weight: 500;
        z-index: 10;
    }

    .achieverc2 {
        width: 30%;
        height: 100%;
        margin-right: 8px;
    }

    .achiveI1 {
        width: 100%;
        height: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #ff7200;
        box-shadow: 0px 0px 24px black inset;
        margin-bottom: 8px;
    }

    .threefive {
        font-size: 2.1rem;
        color: #fff;
        font-weight: 700;
        position: relative;
        top: 10px;
    }

    .iot {
        font-size: 1rem;
        font-weight: 400;
        color: #fff;
    }

    .achiveI2 {
        width: 100%;
        height: 57%;
        position: relative;
    }

    .vehicle_text {
        color: #ff7200;
        font-size: 4.5rem;
        font-weight: 600;
        position: absolute;
        z-index: 10;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .vehicle_sub_text {
        color: #fff;
        position: absolute;
        z-index: 10;
        font-size: 1.25rem;
        top: 45%;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .achieverc3 {
        width: 20%;
        height: 100%;
        margin-right: 8px;
    }

    .achieve50 {
        width: 100%;
        height: 49%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #464444;
        box-shadow: inset 1px 1px 22px #000;
        margin-bottom: 6px;
    }

    .achieve50b {
        width: 100%;
        height: 49%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #d3d3d3;
        box-shadow: inset 1px 1px 19px #000;
        margin-bottom: 6px;
    }

    .m100m {
        color: #ff7200;
        font-size: 1.8rem;
        font-weight: 600;
    }

    .transactions {
        color: #fff;
        position: relative;
        bottom: 7px;
        font-size: 0.8rem;

    }

    .img_overlay {
        position: absolute !important;
    }

    .overlay_main_text {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -70%);

        z-index: 10;
        color: #ff7200;
        font-size: 2rem;
        font-weight: 600;
    }

    .overlay_sub_text {
        color: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, 0);
        font-size: 0.8rem;
        z-index: 10;
    }

    .m100black {
        color: #000;
        font-size: 1.8rem;
        font-weight: 600;
    }

    .transactionsm {
        color: #000;
        position: relative;
        bottom: 7px;
    }

    .achieveI31 {
        width: 100%;
        height: 48%;

        margin-bottom: 8px;
    }

    .achieveI32 {
        width: 100%;
        height: 49%;
        margin-bottom: 8px;
    }

    .achieverc4 {
        width: 30%;
        height: 100%;
    }

    .achiveR2 {
        width: 100%;
        height: 50%;
        display: flex;
        position: relative;
    }

    .ach5 {
        width: 29%;
        height: 100%;
        margin-left: 8px;
        margin-top: 8px;
        position: relative;
    }

    .fifteen {
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 10;
    }

    .fifteenTrips {
        position: absolute;
        top: 70px;
        left: 35px;
        z-index: 10;
    }

    .ach6 {
        width: 21%;
        height: 100%;
        margin-top: 8px;
        position: relative;
    }

    .ach7 {
        width: 20%;
        height: 100%;
        margin-top: 8px;
        margin-left: 8px;
        position: relative;
    }

    .m1000 {
        color: #fff;
        position: absolute;
        top: 20px;
        left: 20px;
        font-size: 2rem;
        z-index: 10;
    }

    .lynkassist {
        color: #fff;
        position: absolute;
        top: 60px;
        left: 20px;
        font-size: 1rem;
        z-index: 10;
    }

    .lynkassistLoc {
        color: #fff;
        position: absolute;
        top: 80px;
        left: 20px;
        font-size: 1rem;
        z-index: 10;
    }

    .ach8 {
        width: 30%;
        height: 100%;
        margin-left: 8px;
        margin-top: 8px;
        display: flex;
        position: relative;
    }

    .m3 {
        position: absolute;
        top: 20px;
        left: 60px;
        z-index: 10;
        color: #ff7200;
        font-size: 4rem;
        font-weight: 600;
    }

    .rfid_cir {
        color: #fff;
        position: absolute;
        top: 100px;
        left: 60px;
        font-size: 1.5rem;
        z-index: 10;
    }

    .lynkit_testomonial {
        width: 100%;
        height: 1000px;
        /* position: absolute;
        top: 2376px; */
        position: relative;
    }

    .Enterprise_div {
        width: 100%;
        height: 748px;
        background-color: transparent !important;
        /* position: absolute;
        top: 3295px; */
        position: relative;
        display: flex;
        justify-content: center;
        overflow-x: hidden;
        overflow: hidden;
    }

    .Enterprise_div h1 {
        font-size: 70px;
        color: #ff7200;
        text-shadow: none;
        position: absolute;
        top: 33px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-image: linear-gradient(#fff, #fff);
        background-repeat: no-repeat;
        line-height: 130px;
        background-size: 0% 6px;
        background-position: bottom;
        letter-spacing: 0px !important;
        z-index: 2;
    }

    .Enterprise_div img {
        position: absolute;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 1;
    }


    .enterprise_main_div {
        width: 80%;
        height: 500px;
        /*  background-color: aqua; */
        position: relative;
        top: 250px;
        display: flex;
    }

    .enterOuter {
        width: 100%;
        height: 84%;
        z-index: 1;
        position: relative;
        top: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .enterInner {
        width: 90%;
        height: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .enterTop {
        width: 90%;
        height: 60%;
        display: flex;
        position: relative;
    }

    .enterpriseSvgCls {
        width: 100%;
        height: 100px;
        position: absolute;
        z-index: 101;
    }

    .enterBot {
        width: 90%;
        height: 20%;
        display: flex;
        position: relative;
    }

    .EnterpriseTextElement {
        width: 90%;
        height: auto;
        padding: 15px;
        position: relative;
        text-align: justify;
        /* border: 1px solid #404040; */
        /* box-shadow: 0px 0px 10px #585858; */
        /* background-color: #fff; */
    }

    .enterSpan {
        font-weight: 300;
        color: #F5F5F5 !important;
    }

    .enterpriseClose {
        width: 20px;
        height: 20px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: -10px;
        right: -10px;
        /* background-color: #ff7200; */
        border-radius: 50%;
    }

    .entSpanActive {
        font-size: 14px;
        color: #ff7200;
        font-weight: 800;
        font-family: 'Bai Jamjuree', sans-serif ! important;
    }

    .enterBox {
        width: 20%;
        height: 100%;
    }

    .boxBorder {
        border-right: 1px solid #ff7200;
    }

    .boxBorder:last-child {
        border: none;
    }

    .enterData {
        width: 100%;
        height: 25%;
        position: relative;
        top: 28%;
        display: flex;
    }

    .entCont1 {
        width: 40%;
        height: 100%;
        display: flex;
        justify-content: center !important;
        align-items: center !important;
    }

    .entCont2 {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .entImg {
        width: 25% !important;
        height: 75% !important;
        position: relative;
        left: 5% !important;
    }

    .entSpan {
        font-size: 15px;
        color: #fff;
        font-weight: 800;
        font-family: 'Bai Jamjuree', sans-serif ! important;
    }

    .leftMover {
        position: relative;
        left: 15% !important;
    }

    .bottomMover {
        position: relative;
        bottom: 18% !important;
    }

    .enterBW {
        width: 95%;
        height: 35%;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        justify-content: flex-start;
        align-items: center;
        display: flex;
    }

    .enterBW1 {
        width: 95%;
        height: 20% !important;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        display: flex;
        margin-left: auto;
        margin-right: auto;
    }

    .enterOrange {
        color: #ff7200;
        font-size: 15px;
        font-weight: 500;
        font-family: 'Bai Jamjuree', sans-serif ! important;
    }

    .enterWhite {
        color: #fff !important;
        font-size: 12px;
        font-weight: 400;
        font-family: 'Bai Jamjuree', sans-serif !important;

    }

    .enterWhiteSmall {
        color: #fff !important;
        font-size: 11px;
        text-decoration: underline;
        text-underline-offset: 4px;
        text-decoration-color: #ff7200;
        cursor: pointer;
        font-weight: 400;
        font-family: 'Bai Jamjuree', sans-serif !important;
    }

    .enterWhiteSmall:hover {
        color: #ff7200 !important;
        text-underline-offset: 4px;
        text-decoration-color: #ff7200;
        font-weight: 400;
        font-family: 'Bai Jamjuree', sans-serif !important;
    }

    .homeSlider_swiper > .swiper-container {
        width: 100%;
        position: absolute;
        right: 0px;
        overflow: visible;

    }

    .homeSlider_swiper > .swiper-slide {
        background-position: center;
        background-size: cover;
        width: 350px;
        height: 350px;
        transition: all 200ms linear;
        position: relative;
        background-color: #141414;
        box-shadow: 2px 2px 10px black;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;



    }

    .homeSlider_swiper > .swiper-slide>img {
        width: 38%;
        height: 169px;
        position: relative;
        bottom: 40px;
        left: 17%;

    }

    .homeSlider_swiper > .swiper-slide h2 {
        font-size: 28px;
        color: #ff7200;
        position: relative;
        left: 6%;
        font-family: 'Fjalla One', sans-serif;
        font-weight: 700;


    }

    .homeSlider_swiper > .swiper-slide-active {
        width: 400px;
        height: 400px;

    }

    .homeSlider_swiper > .swiper-slide.swiper-slide-active .initial_data {
        display: none;
    }

    .supply_para {
        font-size: 34px !important;
        position: relative;
        top: 22px;
        left: 129px !important;
    }

    .iot_para {
        font-size: 34px !important;
        position: relative;
        top: 53px;
        width: 57% !important;
        left: 105px !important;
    }

    .block_para {
        font-size: 34px !important;
        position: relative;
        top: 22px;
        left: 89px !important;
    }


    .supply_class {
        width: 84%;
        position: absolute;
        top: 189px;
        left: 8%;
        /* border: 2px solid red; */

    }

    .supply_class p {
        color: black;
        font-size: 14px;
        text-align: justify;
        line-height: 17px;

    }

    .block_class {
        width: 84%;
        position: absolute;
        top: 155px;
        left: 8%;
        /* border: 2px solid red; */

    }

    .block_class p {
        color: black;
        font-size: 14px;
        text-align: justify;
        line-height: 17px;

    }

    .iot_class {
        width: 90%;
        position: absolute;
        top: 192px;
        left: 5%;
        /* border: 2px solid red; */

    }

    .iot_class p {
        color: black;
        font-size: 14px;
        text-align: justify;
        line-height: 17px;

    }

    .container .swiper-button-next {
        color: #fff !important;
        display: block !important;
        position: absolute !important;
        top: 200px !important;
    }


    .container .swiper-button-prev {
        color: #fff !important;
        display: block !important;
        position: absolute !important;
        top: 200px !important;
    }

    .container .swiper-pagination {
        position: absolute !important;
        top: 444px !important;
        z-index: 10;
    }

    .container swiper-pagination-current {
        background: black !important;
    }

    .container .swiper-pagination-bullet-active {
        width: 8px;
        height: 8px;
        background-color: #fff !important;



    }

    .swiper-pagination-bullet {
        opacity: 1;

        background-color: black !important;
    }




















    .customer_div1 {
        display: none;
    }

    .customer_div {
        width: 100%;
        height: 700px;
        /* position: absolute;
        top: 4020px; */
        position: relative;
        display: flex;
        justify-content: center;
    }

    .customer_div h1 {

        font-size: 70px;
        color: black;
        text-shadow: none;
        position: absolute;
        top: 30px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-image: linear-gradient(#ff7200, #ff7200);
        background-repeat: no-repeat;
        line-height: 140px;
        background-size: 0% 6px;
        background-position: bottom;
        letter-spacing: 0px !important;


    }

    .customers_div_1 {
        width: 97%;
        height: 198px;
        /* border: 1px solid red; */
        position: absolute;
        top: 222px;
        display: flex;
        justify-content: space-around;

    }

    .customers_div_1 div {
        width: 13%;
        height: 198px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }

    .customers_div_1 img {
        /* filter: grayscale(100%); */
    }

    .customers_div_1 img:hover {
        cursor: pointer;
        filter: none;
        /* filter: grayscale(0%); */
    }


    .customers_div_2 {
        width: 97%;
        height: 198px;

        position: absolute;
        top: 450px;
        display: flex;
        justify-content: space-around;

    }

    .customers_div_2 div {
        width: 16%;
        height: 198px;

        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }


    .customers_div_2 img {
        /* filter: grayscale(100%); */
    }

    .customers_div_2 img:hover {
        cursor: pointer;
        filter: none;
        /* filter: grayscale(0%); */
    }

    .customers_div_3 {
        width: 24%;
        height: 182px;
        /* border: 2px solid green;  */
        position: absolute;
        top: 680px;
        display: flex;
        justify-content: space-between;

    }

    .customers_div_3 div {
        width: 50% !important;
        height: 199px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }

    .customers_div_3 img {
        filter: grayscale(100%) !important;
    }

    .customers_div_3 img:hover {
        cursor: pointer;
        filter: none;
        filter: grayscale(0%);
    }

    .customer_images {
        width: 150px;
    }

    #cma {
        width: 135px;
        height: 120px;
    }

    #bharat {
        width: 110px;
        height: 124px;
    }

    #aditya {
        width: 130px;
        height: 120px;
    }

    #lg {
        width: 135px;
        height: 80px;
    }

    #dadri {
        width: 120px;
        height: 110px;
    }

    #hundred {
        position: relative;
        top: 14px;

    }

    #sch {
        width: 65%;
    }

    #client_100 {
        position: relative;
        top: 14px;

    }

    #more_3500 {
        width: 100%;
        height: 66px;
        position: relative;
        top: 652px;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    #more_3500 h1 {
        position: relative;
        font-size: 46px;
        color: #424141;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
        background-image: linear-gradient(white, white);
        background-repeat: none;
        background-size: 0% 0px;
        font-family: 'Fjalla One', sans-serif;
    }

    #more_3500 h1:hover {
        color: black;
    }

    /*  */
    .whatsNew {
        width: 100%;
        height: 750px;
        /* position: absolute;
        top: 4800px; */
        position: relative;
        justify-content: center;


    }





    .whatsNew img {
        width: 100%;
        height: 100%;
        z-index: -1;

    }

    .whatsNew h1 {

        z-index: 1;
        color: #ff7200;
        position: relative;
        bottom: 729px;
        align-items: center;
        text-align: center;

        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-image: linear-gradient(#fff, #fff);
        font-size: 70px;
        background-repeat: no-repeat;
        line-height: 130px;
        background-size: 0% 6px;
        background-position: bottom;
        letter-spacing: 0px !important;
    }

    .whatsNew3cards {
        width: 100%;
        height: 520px;
        /* border: 2px solid cyan;    */
        justify-content: center;

        margin-left: auto;
        margin-right: auto;
        position: relative;
        bottom: 690px;

        overflow: hidden;
    }


    .whatsNew3cards .swiper-slide {
        background-position: center;
        background: #141414;
        background-size: cover;
        width: 380px !important;
        height: 510px;
        transition: all 200ms linear;
        position: relative;
        top: 0px;
        left: 0px;
        /* background-color: rebeccapurple; */
        box-shadow: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 60px;
    }

    .swiper-slide:nth-child(2n+1) {
        background-image: transparent !important;
    }

    .whatsNew3cards .swiper-slide-active {
        width: 380px;
        height: 510px;
        position: relative;
        top: 0px;
        box-shadow: none;

    }

    .new_card_1 {
        max-width: 100% !important;
        height: 500px;
        display: flex !important;
        flex-direction: column;
        position: relative;
        left: 0px;
        /* border: 1px solid red;  */
        align-items: center;
        text-align: center;
        box-shadow: none !important;
    }

    .new_img {
        width: 100%;
        height: 177px;
        position: relative;
        left: 0px;
        top: 1rem;

    }

    .whatsNew3cards .swiper-slide-active img {
        position: relative;
        top: 0px;

    }

    .new_img img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: absolute;
        left: 0px;

    }

    .new_data {
        position: relative;
        top: 1rem;
        display: flex !important;
        flex-direction: column;
        align-items: center !important;
        text-align: center !important;
    }

    .new_data h2 {
        color: #ff7200;
        font-size: 22px;
        position: relative;
        left: 0px !important;
        display: flex !important;
        text-align: center !important;
        align-items: center !important;
        justify-content: center !important;
        font-weight: 700;
        margin-top: 18px;
        font-family: 'Montserrat', sans-serif;

    }

    .new_data p {
        position: relative;
        top: 14px;
        color: white;
        font-size: 15px;
        font-weight: 300;

        padding-left: 12px;
        padding-right: 12px;
    }

    #learn_more {
        position: relative;
        top: 20px;
        color: #ff7200;
        font-size: 14px;

    }

    .whatsNew3cards .swiper-button-next {
        color: white !important;
        position: absolute;
        right: 0px !important;
        top: 190px !important;
    }


    .whatsNew3cards .swiper-button-prev {
        color: white !important;
        position: absolute;
        top: 190px !important;
        left: 0px !important;
    }


    .whatsNew3cards .swiper-pagination {
        display: none;
    }

    #rightarrow1 {
        color: #fff;
        display: none;
        position: relative;
    }

    .lynkit_feed {
        width: 100%;
        /* height: 1079px; */
        height: 1180px;
        background-color: rgb(22, 22, 22);
        /* position: absolute; */
        /* background-color: rgb(224, 223, 223); */
        /* top: 6180px; */
        position: relative;
    }

    .lynkit_feed h1 {
        color: #ff7200;
        position: relative;
        top: 33px;
        align-items: center;
        text-align: center;
        letter-spacing: 0px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 1px;
        font-size: 70px;

    }

    .feed_inner {
        width: 90%;
        height: 540px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 124px;
        display: flex;

    }


    .feed_right {
        width: 100%;
        height: 720px;
        box-shadow: 0px 0px 10px grey;
        /* border: 1px solid red; */
    }

    #hide_me {
        display: none;
    }

    .awardsSection {
        height: 650px;
        position: relative;
        /* top: 4141px; */
    }

    .awardsInner {
        height: 100%;
    }

    .awardsHeading {
        width: 100%;
        height: 70px;
        display: flex;
        position: relative;
        top: 30px;
        justify-content: center;
    }

    .awardsSpans {
        font-size: 70px;
        color: #000;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-size: 0% 4px;
        font-weight: 800;
        background-position: bottom;
    }

    .awardsListing {
        width: 100%;
        height: auto;
        position: relative;
        top: 95px;
        align-items: center;
    }

    .awardsListing700 {
        display: none;
    }

    .awardsRow1 {
        width: 72%;
        height: 200px;
        display: flex;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
    }

    .awardBox2 {
        width: 27%;
        height: auto;
        margin-left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 7px;
    }

    .awardImg {
        max-width: 100%;
        max-height: 100%;
        height: 100%;
        object-fit: contain;

    }






    .awardsListing::-webkit-scrollbar {
        width: 5px;
        cursor: pointer;
    }

    /* Track */
    .awardsListing::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px grey;
        border-radius: 10px;
        cursor: pointer;
    }

    /* Handle */
    .awardsListing::-webkit-scrollbar-thumb {
        background: rgb(7, 7, 7);
        border-radius: 10px;
        cursor: pointer;
    }

    /* Handle on hover */
    .awardsListing::-webkit-scrollbar-thumb:hover {
        background: #ff7200;
        cursor: pointer;
    }

    .grandParent1 {
        width: 100%;
        height: auto;
    }

    .grandParent2 {
        width: 100%;
        height: 100vh;
    }

    .modalOuter {
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0px;
        z-index: 9999999999;
        background-color: #00000087;
        animation: popup 1s;
    }

    @keyframes popup {
        0% {
            transform: scale(1);
            transform: translateY(400px);
            animation-timing-function: ease-out;
            opacity: 0.1;
        }

        50% {
            transform: scale(1.2);
            opacity: 0.2;
        }

        60% {
            transform: scale(1.1);
            opacity: 0.4;
        }

        70% {
            transform: scale(1.2);
            opacity: 0.6;
        }

        80% {
            transform: scale(1);
            opacity: 0.8;
        }

        90% {
            transform: scale(1.1);
            opacity: 1;
        }

        100% {
            transform: scale(1);
            opacity: 0.7;
        }
    }

    .modalCover {
        bottom: auto;
        left: 50%;
        max-height: calc(100% - 100px);
        padding-right: 0 !important;
        padding-top: 0px;
        padding-bottom: 10px;
        position: fixed;
        right: auto;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 56%;
        z-index: 9999999;
        background-color: #fff;
        height: auto;
        border: 2px solid #ff7200;
    }

    .modalInnerCover {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .modalHeader {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 14px;
        flex-direction: column;
    }

    .nodalImage {
        width: 10%;
    }

    .modalText {
        font-weight: 300;
        color: #202020 !important;
    }

    .modalClose {
        width: 35px;
        height: 35px;
        position: absolute;
        cursor: pointer;
        top: 5px;
        right: 5px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .modalIcon {
        color: red;
        font-size: 2.5rem;
    }

    .modalSpan {
        font-size: 1.4vw;
        color: #000;
    }

    .modalBodi {
        width: 100%;
        height: auto;
        padding: 16px;
    }

    .banner_video_slider {
        display: none !important;
    }

    .api-div {
        position: absolute;
        height: 1100px;
        top: 70px;
        width: 100%;
        background-color: rgb(22, 22, 22);
        align-items: center;
    }


    .api_card_1 {
        max-width: 100% !important;
        height: 427px;
        display: flex !important;
        flex-direction: column;
        position: absolute;
        left: 0px;
        /* border: 1px solid red; */
        align-items: center;
        text-align: center;
    }

    .api-swiper {
        width: 100%;
        height: 510px;
        /* border: 2px solid cyan; */
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 5%;
        border-radius: 12px !important;
        overflow: hidden;
    }

    .api-swiper .swiper-slide {
        background-position: center;
        background: #030303;
        background-size: cover;
        width: 333px !important;
        height: 460px;
        transition: all 200ms linear;
        position: relative;
        top: 10px;
        left: 100px;
        /* background-color: rebeccapurple; */
        box-shadow: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 30px;
        margin-left: 30px;
        border-radius: 10px;
        border: 1px solid #ff7700;

    }

    .api-card-data {

        position: relative;
        top: 175px;
        width: 100%;
        height: 240px;
        display: flex;
        flex-direction: column;
    }

    .api-card-data h2 {
        color: #ff7200;
        font-size: 18px;
        position: relative;
        left: 0px;
        text-align: center;
        align-items: center !important;
        font-weight: 700;
        margin: 40px 8px 0px;
        /* font-family: 'Montserrat', sans-serif; */
        font-family: 'Montserrat', sans-serif;
    }

    .api-card-data p {
        position: relative;
        top: 9px;
        color: white;
        font-size: 13px;
        font-weight: 300;

        padding-left: 16px;
        padding-right: 16px;
    }

    .api-card-img {
        width: 100%;
        height: 177px;
        position: absolute;
        left: 0px;
        top: 0px;
        border-radius: 10px;


    }

    .api-card-img img {
        width: 85%;
        height: 100%;
        object-fit: contain;
        position: relative;
        left: 0px;

    }

    .api-swiper .swiper-slide-active {
        width: 300px;
        height: 460px;
        position: relative;
        top: 10px;


    }

    .api-swiper .swiper-slide-active img {
        position: relative;
        top: 0px;

    }


    .api-swiper .swiper-button-next {
        color: #ff7722 !important;
        position: absolute !important;
        right: 60px !important;
        top: 200px !important;
    }

    .api-swiper .swiper-button-prev {
        color: #ff7722 !important;
        position: absolute !important;
        top: 200px !important;
        left: 65px !important;
    }


    .api-swiper .swiper-pagination {
        bottom: -55px;
        left: 0.5%;
    }

    .swiper-pagination-bullet-active {
        opacity: 1;
        background: #ff7700 !important;
        filter: brightness(2);
    }

    .swiper-pagination-bullet {
        background: #8f8f8f !important;
    }

    .home_page_footer {
        width: 100%;
        /* position: absolute;
        top: 7200px; */
        position: relative;
    }
    .banner_headings_messgae {
        width: 100%;
        height: 5%;
        color: white;
        bottom: 596px;
        position: relative;
        z-index: 10;
        text-align: center;
    }

}

/* media query 4 */

@media only screen and (max-width:1537px) {

    body {
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .PEnterDegign {
        display: none;
    }

    .EnterpriseTextElementNew {
        display: none;
    }

    .mainMobile {
        display: none;
    }

    .whatsNew500 {
        display: none;
    }

    .api-div500 {
        display: none;
    }

    .counts {
        font-size: 2rem;
    }

    #lll {
        position: relative;
        left: 30px;
        font-size: 6px;
        font-weight: lighter;
        text-decoration: none;
    }

    ::-webkit-scrollbar {
        width: 5px !important;
        height: 5px;
        cursor: pointer !important;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #ff7200 !important;
        border-radius: 7px;
        cursor: pointer !important;

    }



    .banner_video_slider {
        display: none !important;
    }


    .video_part {
        width: 100%;
        height: 1040px;
        position: relative;
        z-index: 2;

    }

    .video_part p {
        position: absolute;
        top: 100px;
        font-size: 30px;
        color: black;
        z-index: -1;
        visibility: hidden;
    }

    .video_part .headings {
        width: 100%;
        height: 58.6%;
        position: relative;
        top: 20px;
    }

    video {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50px;
        object-fit: initial;
        z-index: 1;
    }


    .three_btns_for_videos {
        width: 4%;
        height: 30px;
        display: flex;
        position: relative;

        top: -35px;
        color: #ff7200;
        justify-content: center;
        align-items: center;
        justify-content: space-between;
        z-index: 10;



    }

    .three_btns_for_videos button {

        width: 11px;
        height: 11px;
        border-radius: 12px;
        border: none;

    }


    .banner_headings {
        width: 42%;
        height: 20%;
        color: #ff7200;

        bottom: 420px;
        position: relative;
        left: 55px;
        z-index: 10;

        /*  border: 0.2px solid red;  */
    }

    .banner_headings h4 {
        font-size: 79px;
        font-weight: 900;
        text-shadow: 1px 5px 1px black;
        font-family: 'Fjalla One', sans-serif;
        line-height: 76px;

    }


    .banner_headings h6 {
        color: #fff;
        font-size: 17px;
        text-shadow: 1px 2px 1px black;
        position: relative;
        top: 6px;
        left: 2px;
        font-weight: 500;

    }

    .banner_headings_messgae {
        width: 100%;
        height: 5%;
        color: white;
        bottom: 540px;
        position: relative;
        z-index: 10;
        text-align: center; 
    }

    .banner_headings_messgae h6 {
        font-size: 17px;
        font-weight: 600;
        text-shadow: 1px 3px 1px black;
        /* font-family: 'Fjalla One', sans-serif; */
        line-height: 76px;

    }

    /*  */

    .banner_btn {
        background-color: #ff7200;
        border: none;
    }





    .main {
        width: 100%;
        /* height: 715px; */
        height: 100%;
        position: relative;
        top: -73px;
        background-color: #f7f7f7;
        z-index: 3;
    }


    .Product_heading {
        position: relative;
        text-align: center;
        justify-content: center;
        align-items: center;


    }

    .Product_heading h2 {

        font-size: 54px;
        color: black;
        text-shadow: none;
        position: relative;
        top: -8px;
        font-weight: 900;

        letter-spacing: 0px;
        background-image: linear-gradient(#ff7200, #ff7200);

        background-repeat: no-repeat;
        line-height: 120px;
        background-size: 0% 4px;
        background-position: bottom;
        font-family: 'Fjalla One', sans-serif !important;


    }


    .Product_titles {

        width: 90%;
        height: 70px;
        position: relative;
        top: 9px;
        left: -1%;
        display: flex;
        justify-content: space-between;
        text-decoration: none;
        /* border: 1px solid red; */

    }






    #ll {
        color: #fff;
    }




    .Product_titles button {
        color: #fff;
        font-size: 22px;
        padding-top: 1px;
        cursor: pointer;
        border: none;
        background-color: transparent;
        width: 167px;
        text-decoration: none;
        display: flex !important;
        justify-content: center ! important;
        align-items: center !important;
        text-align: center !important;
    }

    .Product_titles img {
        color: #fff;
    }

    .Product_titles .active {
        background-color: #ff7200;
        border-radius: 38px;
        width: 174px;
        height: 67px;
        display: flex !important;
        justify-content: center ! important;
        align-items: center !important;
        text-align: center !important;
        border: none;
        box-shadow: 3px 3.5px 1px grey;

    }

    #active_img {
        display: flex !important;
        vertical-align: middle;
        width: 40px;
        height: 42px;
        filter: brightness(0) invert(1);
        margin-right: 8px;
    }

    #active_img5 {
        vertical-align: middle;
        width: 37px;
        height: 38px;
        filter: brightness(0) invert(1);
        position: relative;
    }

    #icon_img1 {
        vertical-align: middle;
        width: 45px;
        position: relative;
        bottom: 5px;


    }

    #icon_img2 {
        vertical-align: middle;
        width: 45px;
        height: 40px;
        position: relative;
        left: -8px;
        bottom: 6px;

    }

    #icon_img3 {
        vertical-align: middle;
        width: 58px;
        height: 58px;
        position: relative;
        left: -1px;
        bottom: 7px;

    }

    #icon_img4 {
        vertical-align: middle;
        width: 42px;
        position: relative;
        bottom: 9px;
        left: 23px;
        right: 0px;
        height: 43px;


    }

    #icon_img5 {
        vertical-align: top;
        position: relative;
        top: 1px;
        right: -19px;
        height: 42px;
        width: 40px;

    }

    #icon_img6 {
        vertical-align: top;
        position: relative;
        bottom: 10px;
        left: 27px;
        height: 42px;
        width: 42px;

    }

    #icon_5 {
        cursor: pointer;

    }

    #active_text {
        color: #fff;
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        position: relative;
        left: 2px;



    }

    #active_text_2 {
        color: #fff;
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        position: relative;
        right: -5px;
        top: 4px;



    }

    #active_text1 {
        color: #fff;
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;

        display: block;
        position: relative;
    }

    #aligning_text1 {
        color: black;
        position: relative;
        top: -3px;
        left: 5px;
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;

    }

    #aligning_text2 {
        color: black;
        position: relative;
        top: -5px;
        left: 2px;
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;

    }



    #aligning_text3 {
        color: black;
        position: relative;
        top: -6px;
        left: 5px;
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;

    }

    #aligning_text4 {
        color: black;
        position: relative;
        top: -12px;
        left: 27px;
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;

    }

    #aligning_text {
        color: black;
        position: relative;
        top: 0px;
        left: 25px;
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;

    }

    #aligning_text5 {
        color: black;
        position: relative;
        top: -9px;
        left: 29px;
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;

    }

    #warehouse1 {
        color: black;
        display: block;
        position: relative;
    }

    .neeProd {
        width: 90%;
        height: auto;
        display: flex;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
        margin-top: 45px;
        box-shadow: 0px 0px 10px grey;
        border-radius: 10px;
    }

    .newProdLeft {
        width: 60%;
        padding: 12px;
    }

    .prodSpan {
        font-size: 50px;
        /* padding-left: 1.5%; */
        font-weight: 600;
        position: relative;
        top: 15px;
        line-height: 49px;
        color: #ff7200;
        font-family: 'Fjalla One', sans-serif;
    }

    .prodData {
        font-size: 15px;
        font-weight: 300;
        text-align: justify;
        text-shadow: none;
        line-height: 24px;
        letter-spacing: 0px;
    }

    .prodBtn {
        width: auto;
        height: auto;
        padding: 9px;
        border: none;
        font-size: 10px;
        background-color: #ff7200;
        cursor: pointer;
        border-radius: 20px;
        color: #fff;
        box-shadow: 2px 2px grey;
        text-decoration: none;
    }

    .prodBtn:hover,
    .prodBtn:focus,
    .prodBtn:active {
        text-decoration: none;
        color: #fff;
    }

    .newProdRight {
        width: 40%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 22px;
        padding-bottom: 22px;
        overflow: hidden;
    }

    .prodImage {
        width: 70%;
        height: auto;
    }

    #twoD {
        color: black;
        position: relative;
        top: -1px;
        left: 10px;
    }

    #product_images {
        width: 75%;
        height: auto;
        position: relative;
        margin-left: auto;

    }

    #video_product_2 {
        width: 100%;
        height: 87%;
        margin-top: 0px;
        margin-left: -2px;
    }

    .enterprise_left2 {
        display: none;
    }

    .achievements_div {
        width: 100%;
        height: 798px;
        /* position: absolute; */
        background-color: #070707;
        /* top: 1380px; */
        position: relative;
        text-align: center;
        z-index: 4;
    }

    .achievements_div h1 {

        font-size: 54px;
        color: #ff7200;
        text-shadow: none;
        position: relative;
        top: 5px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-image: linear-gradient(#fff, #fff);
        background-repeat: no-repeat;
        line-height: 120px;
        background-size: 0% 4px;
        background-position: bottom;
    }

    .achivementElem {
        width: 80%;
        height: 75%;
        margin-left: auto;
        margin-right: auto;
    }

    .achiveR1 {
        width: 100%;
        height: 50%;
        display: flex;
    }

    .achieverc1 {
        width: 20%;
        height: 100%;
        margin-right: 8px;
        position: relative;
    }

    .achieveImg {
        width: 100%;
        height: 100%;
        position: relative;
        justify-content: flex-start;
        text-align: left;
        align-items: center;
        z-index: 1;
    }

    .achieve100 {
        position: absolute;
        top: 20px;
        left: 30px;
        font-size: 2rem;
        font-weight: 600;
        color: #ff7200;
        z-index: 10;
    }

    .achieveClient {
        position: absolute;
        top: 60px;
        left: 30px;
        font-weight: 500;
        z-index: 10;
    }

    .achieveInstall {
        position: absolute;
        top: 80px;
        left: 30px;
        font-weight: 500;
        z-index: 10;
    }

    .achieverc2 {
        width: 30%;
        height: 100%;
        margin-right: 8px;
    }

    .achiveI1 {
        width: 100%;
        height: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #ff7200;
        box-shadow: 0px 0px 24px black inset;
        margin-bottom: 8px;
    }

    .threefive {
        font-size: 2.1rem;
        color: #fff;
        font-weight: 700;
        position: relative;
        top: 10px;
    }

    .iot {
        font-size: 1rem;
        font-weight: 400;
        color: #fff;
    }

    .achiveI2 {
        width: 100%;
        height: 57%;
        position: relative;
    }

    .vehicle_text {
        color: #ff7200;
        font-size: 3.5rem;
        font-weight: 600;
        position: absolute;
        z-index: 10;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .vehicle_sub_text {
        color: #fff;
        position: absolute;
        z-index: 10;
        font-size: 1rem;
        top: 45%;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .achieverc3 {
        width: 20%;
        height: 100%;
        margin-right: 8px;
    }

    .achieve50 {
        width: 100%;
        height: 49%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #464444;
        box-shadow: inset 1px 1px 22px #000;
        margin-bottom: 6px;
    }

    .achieve50b {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #d3d3d3;
        box-shadow: inset 1px 1px 19px #000;
        margin-bottom: 6px;
    }

    .m100m {
        color: #ff7200;
        font-size: 1.8rem;
        font-weight: 600;
    }

    .transactions {
        color: #fff;
        position: relative;
        bottom: 7px;
        font-size: 0.7rem;

    }

    .img_overlay {
        position: absolute !important;
    }

    .overlay_main_text {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -70%);

        z-index: 10;
        color: #ff7200;
        font-size: 1.5rem;
        font-weight: 600;
    }

    .overlay_sub_text {
        color: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, 0);
        font-size: 0.7rem;
        z-index: 10;
    }

    .m100black {
        color: #000;
        font-size: 1.8rem;
        font-weight: 600;
    }

    .transactionsm {
        color: #000;
        position: relative;
        bottom: 7px;
    }

    .achieveI31 {
        width: 100%;
        height: 48%;

        margin-bottom: 8px;
    }

    .achieveI32 {
        width: 100%;
        height: 49%;
        margin-bottom: 8px;
    }

    .achieverc4 {
        width: 30%;
        height: 100%;
        margin-right:5px;
    }

    .achiveR2 {
        width: 100%;
        height: 50%;
        display: flex;
        position: relative;
    }

    .ach5 {
        width: 29%;
        height: 100%;
        margin-left: 8px;
        margin-top: 8px;
        margin-right: 5px;
        position: relative;
    }

    .fifteen {
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 10;
    }

    .fifteenTrips {
        position: absolute;
        top: 60px;
        left: 35px;
        z-index: 10;
    }

    .ach6 {
        width: 21%;
        height: 100%;
        margin-top: 8px;
        position: relative;
    }

    .ach7 {
        width: 20%;
        height: 100%;
        margin-top: 8px;
        margin-left: 8px;
        position: relative;
    }

    .m1000 {
        color: #fff;
        position: absolute;
        top: 20px;
        left: 20px;
        font-size: 2rem;
        z-index: 10;
    }

    .lynkassist {
        color: #fff;
        position: absolute;
        top: 60px;
        left: 20px;
        font-size: 1rem;
        z-index: 10;
    }

    .lynkassistLoc {
        color: #fff;
        position: absolute;
        top: 80px;
        left: 20px;
        font-size: 1rem;
        z-index: 10;
    }

    .ach8 {
        width: 30%;
        height: 100%;
        margin-left: 8px;
        margin-top: 8px;
        display: flex;
        position: relative;
    }

    .m3 {
        position: absolute;
        top: 10px;
        left: 40px;
        z-index: 10;
        color: #ff7200;
        font-size: 3rem;
        font-weight: 600;
    }

    .rfid_cir {
        color: #fff;
        position: absolute;
        top: 80px;
        left: 40px;
        font-size: 1.25rem;
        z-index: 10;
    }

    .lynkit_testomonial {
        width: 100%;
        height: 680px;
        /* position: absolute;
        top: 2100px; */
        position: relative;
    }


    .Enterprise_div {
        width: 100%;
        height: 680px;
        /* position: absolute;
        top: 2745px; */
        position: relative;
        display: flex;
        justify-content: center;
        overflow-x: hidden;
        z-index: 3;
    }

    .Enterprise_div h1 {
        font-size: 54px;
        color: #ff7200;
        text-shadow: none;
        position: absolute;
        top: 1px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-image: linear-gradient(#fff, #fff);
        background-repeat: no-repeat;
        line-height: 110px;
        background-size: 0% 4px;
        background-position: bottom;
        letter-spacing: 0px !important;
        z-index: 2;
    }

    .Enterprise_div img {
        position: absolute;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .enterOuter {
        width: 100%;
        height: 84%;
        z-index: 1;
        position: relative;
        top: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .enterInner {
        width: 90%;
        height: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .enterTop {
        width: 90%;
        height: 60%;
        display: flex;
        position: relative;
    }

    .enterpriseSvgCls {
        width: 100%;
        height: 100px;
        position: absolute;
        z-index: 101;
    }

    .enterBot {
        width: 90%;
        height: 20%;
        display: flex;
        position: relative;
    }

    .EnterpriseTextElement {
        width: 90%;
        height: auto;
        padding: 15px;
        position: relative;
        text-align: justify;
        /* border: 1px solid #404040; */
        /* box-shadow: 0px 0px 10px #585858; */
        /* background-color: #fff; */
    }

    .enterSpan {
        font-weight: 300;
        color: #F5F5F5 !important;
    }

    .enterpriseClose {
        width: 20px;
        height: 20px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: -10px;
        right: -10px;
        /* background-color: #ff7200; */
        border-radius: 50%;
    }

    .enterBox {
        width: 20%;
        height: 100%;
    }

    .boxBorder {
        border-right: 1px solid #ff7200;
    }

    .boxBorder:last-child {
        border: none;
    }

    .enterData {
        width: 100%;
        height: 25%;
        position: relative;
        top: 28%;
        display: flex;
        cursor: pointer;
    }

    .entCont1 {
        width: 40%;
        height: 100%;
        display: flex;
        justify-content: center !important;
        align-items: center !important;
    }

    .entCont2 {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .entImg {
        width: 25% !important;
        height: 75% !important;
        position: relative;
        left: 5% !important;
    }

    .entSpan {
        font-size: 14px;
        color: #fff;
        font-weight: 800;
        font-family: 'Bai Jamjuree', sans-serif ! important;
    }

    .entSpanActive {
        font-size: 14px;
        color: #ff7200;
        font-weight: 800;
        font-family: 'Bai Jamjuree', sans-serif ! important;
    }

    .leftMover {
        position: relative;
        left: 15% !important;
    }

    .bottomMover {
        position: relative;
        bottom: 18% !important;
    }

    .enterBW {
        width: 95%;
        height: 35%;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        justify-content: flex-start;
        align-items: center;
        display: flex;
    }

    .enterBW1 {
        width: 95%;
        height: 20% !important;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        display: flex;
        margin-left: auto;
        margin-right: auto;
    }

    .enterOrange {
        color: #ff7200;
        font-size: 14px;
        font-weight: 500;
        font-family: 'Bai Jamjuree', sans-serif ! important;
    }

    .enterWhite {
        color: #fff !important;
        font-size: 11px;
        font-weight: 400;
        font-family: 'Bai Jamjuree', sans-serif !important;

    }

    .enterWhiteSmall {
        color: #fff !important;
        font-size: 10px;
        text-decoration: underline;
        text-underline-offset: 4px;
        text-decoration-color: #ff7200;
        cursor: pointer;
        font-weight: 400;
        font-family: 'Bai Jamjuree', sans-serif !important;
    }

    .enterWhiteSmall:hover {
        color: #ff7200 !important;
        text-underline-offset: 4px;
        text-decoration-color: #ff7200;
        font-weight: 400;
        font-family: 'Bai Jamjuree', sans-serif !important;
    }



    .homeSlider_swiper > .swiper-container {
        width: 100%;

        position: relative;
        right: 60px;
        overflow: visible;

    }

    .homeSlider_swiper > .swiper-slide {
        background-position: center;
        background-size: cover;
        width: 340px;
        height: 340px;
        transition: all 200ms linear;
        position: relative;
        background-color: #141414;
        box-shadow: 2px 2px 10px black;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .homeSlider_swiper > .swiper-slide>img {
        width: 37%;
        position: relative;
        bottom: 29px;
        left: 30%;

    }

    .homeSlider_swiper > .swiper-slide h2 {
        font-size: 25px;
        font-weight: 700;
        color: #ff7200;
        position: relative;
        text-align: center;
        word-wrap: break-word;
        white-space: initial;
        font-family: 'Fjalla One', sans-serif;
    }

    .homeSlider_swiper > .swiper-slide-active {
        width: 400px;
        height: 400px;
    }

    .homeSlider_swiper > .swiper-slide.swiper-slide-active .initial_data {
        display: none;
    }

    .supply_para {
        font-size: 32px !important;
        position: relative;
        top: 40px;
        left: 55px !important;
    }

    .block_para {
        font-size: 32px !important;
        position: relative;
        top: 38px;
        left: 56px !important;

    }

    .iot_para {
        font-size: 32px !important;
        position: relative;
        top: 45px;
        left: 90px !important;
        width: 61% !important;
    }

    .supply_class {
        width: 84%;
        position: absolute;
        top: 170px;
        left: 8%;
        /* border: 2px solid red; */

    }

    .supply_class p {
        color: black;
        font-size: 12px;
        text-align: justify;
        line-height: 17px;

    }

    .block_class {
        width: 84%;
        position: absolute;
        top: 150px;
        left: 8%;
        /* border: 2px solid red; */

    }

    .block_class p {
        color: black;
        font-size: 12px;
        text-align: justify;
        line-height: 17px;

    }

    .iot_class {
        width: 84%;
        position: absolute;
        top: 169px;
        left: 8%;
        /* border: 2px solid red; */

    }

    .iot_class p {
        color: black;
        font-size: 12px;
        text-align: justify;
        line-height: 17px;

    }

    .container .swiper-button-next {
        color: white !important;
        display: block !important;
        position: absolute;
        top: 180px !important;

    }

    .container .swiper-button-prev {
        color: white !important;
        display: block !important;
        position: absolute;
        top: 180px !important;


    }

    .container .swiper-pagination {
        position: absolute !important;
        top: 430px !important;
        z-index: 10;
    }


    .container swiper-pagination-current {
        background: black !important;
    }

    .container .swiper-pagination-bullet-active {
        width: 8px;
        height: 8px;
        background-color: #fff !important;



    }

    .container .swiper-pagination-bullet {
        opacity: 1;

        background-color: black;
    }




















    .customer_div1 {
        display: none;
    }

    .customer_div {
        width: 100%;
        height: 700px;
        /* position: absolute;
        top: 3409px; */
        position: relative;
        display: flex;
        justify-content: center;
    }

    .customer_div h1 {
        font-size: 54px;
        color: black;
        text-shadow: none;
        position: absolute;
        top: 20px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-image: linear-gradient(#ff7200, #ff7200);
        background-repeat: no-repeat;
        line-height: 110px;
        background-size: 0% 4px;
        background-position: bottom;
        letter-spacing: 0px !important;
    }

    .customers_div_1 {
        width: 95%;
        position: absolute;
        top: 170px;
        display: flex;
        justify-content: space-around;
    }

    .customers_div_1 div {
        width: 13.5%;
        height: 178px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }

    .customers_div_1 img:hover {
        cursor: pointer;
        filter: none;
        /* filter: grayscale(0%); */
    }


    .customers_div_2 {
        width: 95%;
        height: 182px;
        /* border: 2px solid green;  */
        position: absolute;
        top: 390px;
        display: flex;
        justify-content: space-around;

    }

    .customers_div_2 div {
        width: 13.5% !important;
        height: 170px;

        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }

    .customers_div_2 img:hover {
        cursor: pointer;
        filter: none;
        /* filter: grayscale(0%); */
    }

    /* 333 */
    .customers_div_3 {
        width: 24%;
        height: 182px;
        /* border: 2px solid green;  */
        position: absolute;
        top: 640px;
        display: flex;
        justify-content: space-between;

    }

    .customers_div_3 div {
        width: 49% !important;
        height: 170px;

        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }

    .customers_div_3 img:hover {
        cursor: pointer;
        filter: none;
        filter: grayscale(0%);
    }



    .customer_images {
        width: 110px;
    }

    #sch {
        width: 55%;
    }

    #cma {
        width: 100px;
        height: 100px;
    }

    #bharat {
        width: 100px;
        height: 130px;
    }

    #aditya {
        width: 110px;
        height: 100px;
    }

    #lg {
        width: 100px;
        height: 60px;
    }

    #dadri {
        width: 115px;
        height: 100px;
    }

    #hundred {
        position: relative;
        top: 14px;

    }

    #client_100 {
        position: relative;
        top: 14px;

    }

    #more_3500 {
        width: 100%;
        height: 66px;
        position: relative;
        top: 565px;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    #more_3500 h1 {
        position: relative;
        font-size: 40px;
        color: #424141;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
        background-image: linear-gradient(white, white);
        background-repeat: none;
        background-size: 0% 0px;
        font-family: 'Fjalla One', sans-serif;

    }

    #more_3500 h1:hover {
        color: black;
    }

    /*  */
    .whatsNew {
        width: 100%;
        height: 650px;
        /* position: absolute;
        top: 4075px; */
        position: relative;
    }



    .whatsNew img {
        width: 100%;
        height: 100%;
        z-index: -1;

    }

    .whatsNew h1 {

        z-index: 1;
        color: #ff7200;
        position: relative;
        bottom: 635px;
        align-items: center;
        text-align: center;
        letter-spacing: 0px !important;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 1px;
        background-image: linear-gradient(#fff, #fff);
        font-size: 54px;
        background-repeat: no-repeat;
        line-height: 100px;
        background-size: 0% 4px;
        background-position: bottom;
    }

    .whatsNew3cards {
        width: 100%;
        height: 510px;
        /* border: 2px solid cyan;    */
        justify-content: center;

        margin-left: auto;
        margin-right: auto;
        position: relative;
        bottom: 610px;
        border-radius: 12px !important;
        overflow: hidden;
    }


    .whatsNew3cards .swiper-slide {
        background-position: center;
        background: #141414;
        background-size: cover;
        width: 300px !important;
        height: 490px;
        transition: all 200ms linear;
        position: relative;
        top: 0px;
        left: 100px;
        /* background-color: rebeccapurple; */
        box-shadow: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 60px;
    }

    .swiper-slide:nth-child(2n+1) {
        background-image: transparent !important;
    }

    .whatsNew3cards .swiper-slide-active {
        width: 300px;
        height: 490px;
        position: relative;
        top: 0px;
        box-shadow: none;

    }

    .new_card_1 {
        max-width: 100% !important;
        height: 470px;
        display: flex !important;
        flex-direction: column;
        position: relative;
        left: 0px;
        /* border: 1px solid red;  */
        align-items: center;
        text-align: center;
        box-shadow: none !important;
    }

    .new_img {
        width: 100%;
        height: 177px;
        position: relative;
        left: 0px;
        top: 1rem;

    }

    .whatsNew3cards .swiper-slide-active img {
        position: relative;
        top: 0px;

    }

    .new_img img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: absolute;
        left: 0px;

    }

    .new_data {
        position: relative;
        top: 1rem;
        width: 100%;
        height: 240px;
        display: flex;
        flex-direction: column;
    }

    .new_data h2 {
        color: #ff7200;
        font-size: 20px;
        position: relative;
        left: 0px;
        text-align: center;
        align-items: center !important;
        font-weight: 700;
        margin-top: 18px;
        /* font-family: 'Montserrat', sans-serif; */
        font-family: 'Montserrat', sans-serif;
    }

    .new_data p {
        position: relative;
        top: 9px;
        color: white;
        font-size: 13px;
        font-weight: 300;

        padding-left: 12px;
        padding-right: 12px;
    }

    #learn_more {
        position: relative;
        top: 5px;
        color: #ff7200;
        font-size: 13px;

    }

    .whatsNew3cards .swiper-button-next {
        color: white !important;
        position: absolute !important;
        right: -50px !important;
        top: 200px !important;
    }


    .whatsNew3cards .swiper-button-prev {
        color: white !important;
        position: absolute !important;
        top: 200px !important;
        left: 65px !important;
    }


    .whatsNew3cards .swiper-pagination {
        display: none;
    }

    #rightarrow1 {
        color: #fff;
        display: none;
        position: relative;
    }

    .lynkit_feed {
        width: 100%;
        /* height: 1022px; */
        height: 1135px;
        background-color: rgb(22, 22, 22);
        /* position: absolute; */
        /* background-color: rgb(224, 223, 223); */
        /* top: 5277px; */
        position: relative;
    }

    .lynkit_feed h1 {
        color: #ff7200;
        position: relative;
        top: 30px;
        align-items: center;
        text-align: center;
        letter-spacing: 0px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 1px;
        font-size: 54px;

    }

    .feed_inner {
        width: 90%;
        height: 540px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 99px;
        display: flex;
    }


    .feed_right {
        width: 100%;
        height: 720px;
        box-shadow: 0px 0px 10px grey;
        /* border: 1px solid red; */
    }

    #hide_me {
        display: none;
    }

    .awardsSection {
        height: 530px;
        position: relative;
        /* top: 3270px; */
    }

    .awardsInner {
        height: 100%;
    }

    .awardsHeading {
        width: 100%;
        height: 70px;
        display: flex;
        position: relative;
        top: 23px;
        justify-content: center;
    }

    .awardsSpans {
        font-size: 54px;
        color: #000;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        font-weight: 800 !important;
        background-size: 0% 4px;
        background-position: bottom;
    }

    .awardsListing700 {
        display: none;
    }

    .awardsListing {
        width: 100%;
        height: auto;
        position: relative;
        top: 52px;
        /* display: flex; */
        align-items: center;
    }

    .awardsRow1 {
        width: 90%;
        height: 189px;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
    }

    .awardBox2 {
        width: 20%;
        height: 180px;
        margin-left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
    }

    .awardImg {
        max-width: 100%;
        max-height: 100%;
        height: 100%;
        object-fit: contain;

    }

    .awardsListing::-webkit-scrollbar {
        width: 5px;
        cursor: pointer;
    }

    /* Track */
    .awardsListing::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px grey;
        border-radius: 10px;
        cursor: pointer;
    }

    /* Handle */
    .awardsListing::-webkit-scrollbar-thumb {
        background: rgb(7, 7, 7);
        border-radius: 10px;
        cursor: pointer;
    }

    /* Handle on hover */
    .awardsListing::-webkit-scrollbar-thumb:hover {
        background: #ff7200;
        cursor: pointer;
    }

    .grandParent1 {
        width: 100%;
        height: auto;
    }

    .grandParent2 {
        width: 100%;
        height: 100vh;
    }

    .modalOuter {
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0px;
        z-index: 9999999999;
        background-color: #00000087;
        animation: popup 1s;
    }

    .modalCover {
        bottom: auto;
        left: 50%;
        max-height: calc(100% - 100px);
        padding-right: 0 !important;
        padding-top: 0px;
        padding-bottom: 10px;
        position: fixed;
        right: auto;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 55%;
        z-index: 9999999;
        background-color: #fff;
        height: auto;
        border: 2px solid #ff7200;
    }

    .modalInnerCover {
        width: 100%;
        height: 100%;
    }

    @keyframes popup {
        0% {
            transform: scale(1);
            transform: translateY(400px);
            animation-timing-function: ease-out;
            opacity: 0.1;
        }

        50% {
            transform: scale(1.2);
            opacity: 0.2;
        }

        60% {
            transform: scale(1.1);
            opacity: 0.4;
        }

        70% {
            transform: scale(1.2);
            opacity: 0.6;
        }

        80% {
            transform: scale(1);
            opacity: 0.8;
        }

        90% {
            transform: scale(1);
            opacity: 1;
        }

        100% {
            transform: scale(1);
            opacity: 0.7;
        }
    }

    .modalHeader {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        flex-direction: column;
        position: relative;
    }

    .nodalImage {
        width: 12%;
        height: 100px;
    }

    .modalClose {
        width: 35px;
        height: 35px;
        /* background-color: grey; */
        position: absolute;
        cursor: pointer;
        top: 5px;
        right: 5px;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .modalIcon {
        color: #ff7200;
        font-size: 20px;
        scale: 1.2;
    }

    .modalIcon:hover {
        scale: 1.5;
    }

    .modalSpan {
        font-size: 1.4vw;
        color: #000;
        margin-left: 22px;
    }

    .modalBodi {
        width: 100%;
        height: auto;
        padding: 16px;
    }

    .modalText {
        font-weight: 300;
        color: #202020 !important;
    }

    .banner_video_slider {
        display: none !important;
    }


    .api-div {
        position: absolute;
        height: 1100px;
        top: 70px;
        width: 100%;
        background-color: rgb(22, 22, 22);
        align-items: center;
    }

    /* .api-footer{
        position: absolute;
        width: 100%;
        top: 1108px;
    } */


    .api_card_1 {
        max-width: 100% !important;
        height: 427px;
        display: flex !important;
        flex-direction: column;
        position: absolute;
        left: 0px;
        /* border: 1px solid red; */
        align-items: center;
        text-align: center;
    }

    .api-swiper {
        width: 100%;
        height: 510px;
        /* border: 2px solid cyan; */
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 5%;
        border-radius: 12px !important;
        overflow: hidden;
    }

    .api-swiper .swiper-slide {
        background-position: center;
        background: #030303;
        background-size: cover;
        width: 300px !important;
        height: 460px;
        transition: all 200ms linear;
        position: relative;
        top: 10px;
        left: 100px;
        /* background-color: rebeccapurple; */
        box-shadow: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 30px;
        margin-left: 30px;
        border-radius: 10px;
        border: 1px solid #ff7700;

    }

    .api-card-data {

        position: relative;
        top: 175px;
        width: 100%;
        height: 240px;
        display: flex;
        flex-direction: column;
    }

    .api-card-data h2 {
        color: #ff7200;
        font-size: 18px;
        position: relative;
        left: 0px;
        text-align: center;
        align-items: center !important;
        font-weight: 700;
        margin: 18px 8px 5px;
        /* font-family: 'Montserrat', sans-serif; */
        font-family: 'Montserrat', sans-serif;
    }

    .api-card-data p {
        position: relative;
        top: 9px;
        color: white;
        font-size: 13px;
        font-weight: 300;

        padding-left: 16px;
        padding-right: 16px;
    }

    .api-card-img {
        width: 100%;
        height: 177px;
        position: absolute;
        left: 0px;
        top: 0px;
        border-radius: 10px;


    }

    .api-card-img img {
        width: 85%;
        height: 100%;
        object-fit: contain;
        position: relative;
        left: 0px;

    }

    .api-swiper .swiper-slide-active {
        width: 300px;
        height: 460px;
        position: relative;
        top: 10px;


    }

    .api-swiper .swiper-slide-active img {
        position: relative;
        top: 0px;

    }


    .api-swiper .swiper-button-next {
        color: #ff7722 !important;
        position: absolute !important;
        right: -50px !important;
        top: 200px !important;
    }

    .api-swiper .swiper-button-prev {
        color: #ff7722 !important;
        position: absolute !important;
        top: 200px !important;
        left: 65px !important;
    }


    .api-swiper .swiper-pagination {
        bottom: -55px;
        left: 4.5%;
    }

    .swiper-pagination-bullet-active {
        opacity: 1;
        background: #ff7700;
        filter: brightness(1.5);
    }

    .swiper-pagination-bullet {
        background: #8f8f8f;
    }


    .home_page_footer {
        width: 100%;
        /* position: absolute;
        top: 6242px; */
        position: relative;
    }
}

/* media query 5 */

@media only screen and (max-width:1300px) {


    body {
        width: 100%;
        height: 100%;
        display: inline;
        z-index: -1;

    }

    .EnterpriseTextElementNew {
        display: none;
    }

    .PEnterDegign {
        display: none;
    }

    .mainMobile {
        display: none;
    }

    ::-webkit-scrollbar {
        width: 6px;
    }

    .whatsNew500 {
        display: none;
    }

    .api-div500 {
        display: none;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #ff7200;
        border-radius: 7px;

    }

    #lll {
        position: relative;
        left: 30px;
        font-size: 6px;
        font-weight: lighter;
    }

    .banner_video_slider {
        display: none !important;
    }

    .video_part {
        width: 100%;
        height: 1100px;
        position: relative;
        top: 0px;
        z-index: 2;

    }

    .video_part .headings {
        width: 100%;
        height: 52%;
        position: relative;
        top: 20px;
    }

    video {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50px;
        object-fit: initial;
        z-index: 1;
    }


    .three_btns_for_videos {
        width: 5%;
        height: 30px;
        display: flex;
        position: relative;

        top: -40px;
        color: #ff7200;
        justify-content: center;
        align-items: center;
        justify-content: space-between;
        z-index: 10;



    }

    .three_btns_for_videos button {

        width: 11px;
        height: 11px;
        border-radius: 12px;
        border: none;

    }


    .banner_headings {
        width: 34%;
        height: 20%;
        color: #ff7200;

        bottom: 360px;
        position: relative;
        left: 55px;
        z-index: 10;

        /*  border: 0.2px solid red;  */
    }

    .banner_headings h4 {
        font-size: 59px;
        font-weight: 900;
        text-shadow: 1px 5px 1px black;
        font-family: 'Fjalla One', sans-serif;
        line-height: 62px;
    }


    .banner_headings h6 {
        color: #fff;
        font-size: 15px;
        text-shadow: 1px 2px 1px black;
        position: relative;
        top: 6px;
        left: 2px;
        font-weight: 500;
        font-family: 'Montserrat', sans-serif;
    }

    .banner_btn {
        background-color: #ff7200;
        border: none;
    }





    .main {
        width: 100%;
        /* height: 1018px; */
        height: 100%;
        position: relative;
        margin-top: -389px;
        background-color: #f7f7f7;
        z-index: 2;

    }



    .Product_titles {

        width: 90%;
        height: 65px;
        position: relative;
        top: 20px;
        left: -1%;
        display: flex;
        justify-content: space-between;
        text-decoration: none;
        z-index: 10;

    }

    .Product_titles img {
        display: inline-table;
    }




    #ll {
        color: #fff;
    }




    .Product_titles a {
        color: #fff;
        font-size: 22px;
        padding-top: 1px;
        cursor: pointer;
        text-decoration: none;
    }

    .Product_titles img {
        color: #fff;
    }

    /* .Product_titles .active {
    border-bottom: 1px solid #ff7200;
    background-color: transparent;
    
} */


    #icon_img1 {
        vertical-align: middle;
        width: 46px;


    }

    #icon_img2 {
        vertical-align: middle;
        width: 52px;
        height: 50px;
        position: relative;
        left: -8px;

    }

    #icon_img3 {
        vertical-align: middle;
        width: 59px;
        height: 58px;
        position: relative;
        left: -1px;

    }

    #icon_img4 {
        vertical-align: middle;
        width: 44px;
        position: relative;
        left: 32px;
        height: 50px;


    }

    #icon_img5 {
        vertical-align: top;
        position: relative;
        right: -3px;
        height: 47px;
        width: 41px;

    }

    #icon_img6 {
        vertical-align: top;
        position: relative;
        bottom: 5px;
        left: 28px;
        height: 48px;
        width: 46px;

    }

    #icon_5 {
        cursor: pointer;

    }

    #aligning_text5 {
        position: relative;
        top: 0px;
    }

    #aligning_text1 {
        color: black;
        position: relative;
        top: -3px;
        left: 1px;
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
    }

    #aligning_text2 {
        color: black;
        position: relative;
        top: -5px;
        left: -4px;
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
    }




    #aligning_text3 {
        color: black;
        position: relative;
        top: -7px;
        left: 1px;
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
    }

    #aligning_text4 {
        color: black;
        position: relative;
        top: -9px;
        left: 19px;
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
    }

    #aligning_text {
        color: black;
        position: relative;
        top: 4px;
        left: 10px;
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
    }

    #aligning_text4 {
        color: black;
        position: relative;
        top: -9px;
        left: 37px;
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
    }





    .sub {
        /*  border: 1px solid red;  */
        position: relative;
        top: 94px;
        background-color: #fff;
        width: 90%;
        height: 590px;
        border-radius: 10px;

    }

    #twoD {
        color: black;
        position: relative;
        top: -1px;
        left: 10px;
    }

    .sub .left_div {
        width: 45%;
        height: 85%;
    }

    #product_images {
        width: 93%;
        position: relative;
        left: 4%;
        bottom: -8%;


    }

    #active_img5 {
        vertical-align: middle;
        width: 37px;
        height: 38px;
        filter: brightness(0) invert(1);
        position: relative;
        right: 1px;

    }

    #active_text_2 {
        color: #fff;
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        position: relative;
        right: -2px;
        top: 4px;
    }

    #active_text1 {
        color: #fff;
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        display: block;
        position: relative;
    }


    #video_product_2 {
        width: 100%;
        height: 87%;
        margin-top: 0px;
        margin-left: -2px;
    }

    .sub .right_div {
        width: 49%;
        height: 70%;




    }

    .sub .right_div #head1 {

        font-size: 42px;
        padding-left: 1%;
        font-weight: 700;
        position: relative;
        top: 15px;
        line-height: 42px;

    }

    .sub .right_div #head2 {

        font-size: 13px;
        font-weight: 500;
        margin-top: 35px;
        text-align: justify;
        text-shadow: none;
        letter-spacing: 0px;
        position: relative;
        left: 9px;
        font-weight: 300;

    }

    .right_div button {
        width: 80px;
        height: 32px;
        display: flex;
        position: relative;
        bottom: -25px;
        left: -21px;
        border-radius: 15px;
        font-weight: 300;
        font-size: 10px;
        text-decoration: none;
        padding-left: 11px;
        padding-top: 10px;

    }

    #warehouse1 {
        color: black;
        display: block;
        position: relative;
    }

    .achievements_div {
        width: 100%;
        height: 778px;
        /* position: absolute;
        top: 1330px; */
        position: relative;
        background-color: #070707;

    }

    .achievements_image {
        width: 100%;
        height: 546px;
    }

    #blur_image {
        width: 100%;
        height: 470px;
    }

    .achivements_div_1 {
        width: 75%;
        height: 172px;
        /*  border: 1px solid red; */
        position: absolute;
        bottom: 334px;
        left: 12.5%;
        display: flex;
        justify-content: space-between;
    }

    .achivements_div_1 div {
        width: 21%;
        height: 170px;
        /*  border: 3px solid yellow; */
        background-color: #302f2f;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        box-shadow: inset 0px 0px 39px rgb(17, 16, 16);
        box-shadow: 4px 4px 10px black;
        border-radius: 7px;


    }

    .achivements_div_1 div h1 {
        color: #ff7200;
        font-weight: 900;
    }


    .achivements_div_1 div p {
        font-size: 19px;
        color: white;
        font-weight: 500;
    }




    .achivements_div_2 {
        width: 75%;
        height: 172px;

        position: absolute;
        bottom: 120px;
        left: 12.5%;
        display: flex;
        justify-content: space-between;
    }

    .achivements_div_2 div {
        width: 21%;
        height: 170px;
        /*  border: 3px solid yellow; */
        background-color: #302f2f;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        box-shadow: inset 0px 0px 39px rgb(17, 16, 16);
        box-shadow: 4px 4px 10px black;
        border-radius: 7px;


    }


    .achivements_div_2 div h1 {
        color: #ff7200;
        font-weight: 900;
    }

    #hundred {
        position: relative;
        top: 14px;

    }

    #client_100 {
        position: relative;
        top: 14px;

    }

    .iot_h {
        position: relative;
        top: 14px;
    }

    .iot_p {
        position: relative;
        top: 13px;
    }

    .achivements_div_2 div p {
        font-size: 19px;
        color: white;
        font-weight: 400;
    }

    .customer_div1 {
        display: none;
    }

    .lynkit_testomonial {
        width: 100%;
        height: 700px;
        /* position: absolute;
        top: 2022px; */
        position: relative;
    }


    .Enterprise_div {
        width: 100%;
        height: 500px;
        /* position: absolute;
        top: 2666px; */
        position: relative;
        display: flex;
        justify-content: center;
        overflow-x: hidden;
    }

    .Enterprise_div h1 {
        font-size: 54px;
        color: #ff7200;
        text-shadow: none;
        position: absolute;
        top: -15px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-image: linear-gradient(#fff, #fff);
        background-repeat: no-repeat;
        line-height: 110px;
        background-size: 0% 4px;
        letter-spacing: 0px !important;
        background-position: bottom;
    }

    .enterInner {
        width: 100%;
        height: 90%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .entSpan {
        font-size: 12px;
        color: #fff;
        font-weight: 800;
        font-family: 'Bai Jamjuree', sans-serif ! important;
    }

    .enterOrange {
        color: #ff7200;
        font-size: 12px;
        font-weight: 500;
        font-family: 'Bai Jamjuree', sans-serif ! important;
    }

    .enterWhite {
        color: #fff !important;
        font-size: 10px;
        font-weight: 400;
        font-family: 'Bai Jamjuree', sans-serif !important;
    }

    .enterWhiteSmall {
        color: #fff !important;
        font-size: 9px;
        text-decoration: underline;
        text-underline-offset: 4px;
        text-decoration-color: #ff7200;
        cursor: pointer;
        font-weight: 400;
        font-family: 'Bai Jamjuree', sans-serif !important;
    }

    /*  */
    .enterprise_left1 {
        display: block;
    }

    .enterprise_main_div {
        width: 80%;
        height: 500px;
        /*  background-color: aqua; */
        position: relative;
        top: 190px;
        display: flex;
    }

    .customer_div {
        width: 100%;
        height: 700px;

        /* position: absolute;
        top: 3147px ; */
        position: relative;
        display: flex;
        justify-content: center;

    }

    .customer_div h1 {

        font-size: 54px;
        color: black;
        text-shadow: none;
        position: absolute;
        top: 20px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px !important;
        background-image: linear-gradient(#ff7200, #ff7200);
        background-repeat: no-repeat;
        line-height: 110px;
        background-size: 0% 4px;
        background-position: bottom;


    }

    .customers_div_1 {
        width: 98%;
        height: 160px;
        /* border: 2px solid black; */
        position: absolute;
        top: 180px;
        display: flex;
        justify-content: space-around;

    }

    .customers_div_1 div {
        width: 14%;
        height: 160px;

        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }

    .customers_div_1 img {
        /* filter: grayscale(100%); */
    }

    .customers_div_1 img:hover {
        cursor: pointer;
        filter: none;
        /* filter: grayscale(0%); */
    }


    .customers_div_2 {
        width: 98%;
        height: 160px;

        position: absolute;
        top: 390px;
        display: flex;
        justify-content: space-around;

    }

    .customers_div_2 div {
        width: 16%;
        height: 160px;

        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }

    .customers_div_2 img:hover {
        cursor: pointer;
        filter: none;
        filter: grayscale(0%);
        ;
    }


    .customer_images {
        width: 112px;
    }

    #cma {
        width: 100px;
        height: 105px;
    }

    #bharat {
        width: 105px;
        height: 115px;
    }

    #aditya {
        width: 110px;
        height: 105px;
    }

    #lg {
        width: 100px;
        height: 60px;
    }

    #dadri {
        width: 110px;
        height: 100px;
    }

    #more_3500 {
        width: 100%;
        height: 66px;
        position: relative;
        top: 567px;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    #more_3500 h1 {
        position: relative;
        font-size: 42px;
        color: #424141;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
        background-image: linear-gradient(white, white);
        background-repeat: none;
        background-size: 0% 0px;
        font-family: 'Fjalla One', sans-serif;
    }

    #more_3500 h1:hover {
        color: black;
    }

    /*  */
    .whatsNew {
        width: 100%;
        height: 650px;
        /* position: absolute;
        top: 3814px; */
        position: relative;
        justify-content: center;
    }

    .whatsNew>img {
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .whatsNew h1 {

        z-index: 1;
        color: #ff7200;
        position: relative;
        bottom: 636px;
        align-items: center;
        text-align: center;

        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px !important;
        background-image: linear-gradient(#fff, #fff);
        font-size: 54px;
        background-repeat: no-repeat;
        line-height: 100px;
        background-size: 0% 4px;
        background-position: bottom;
    }

    .whatsNew3cards {
        width: 100%;
        height: 490px;
        /* border: 2px solid cyan;    */
        justify-content: center;
        position: relative;
        bottom: 615px;
        border-radius: 12px !important;
        overflow: hidden;
    }


    .whatsNew3cards .swiper-slide {
        background-position: center;
        background: #141414;
        background-size: cover;
        width: 320px;
        height: 490px;
        transition: all 200ms linear;
        position: relative;
        top: 0px;
        left: 75px;
        /* background-color: rebeccapurple; */
        box-shadow: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 60px;
    }



    .whatsNew3cards .swiper-slide-active {
        width: 320px;
        height: 490px;
        position: relative;
        top: 0px;
        box-shadow: none;

    }

    .new_card_1 {
        max-width: 100% !important;
        height: 470px;
        display: flex !important;
        flex-direction: column;
        position: relative;
        left: 0px;
        /* border: 1px solid red;  */
        align-items: center;
        text-align: center;
        box-shadow: none !important;
    }

    .new_img {
        width: 100%;
        height: 177px;
        position: relative;
        left: 0px;
        top: 1rem;

    }

    .whatsNew3cards .swiper-slide-active img {
        position: relative;
        top: 0px;

    }

    .new_img img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: absolute;
        left: 0px;

    }

    .new_data {
        position: relative;
        top: 1rem;
        display: flex;
        flex-direction: column;
    }

    .new_data h2 {
        color: #ff7200;
        font-size: 18px;
        position: relative;
        left: 0px;
        top: 7px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        margin-top: 18px;
    }

    .new_data p {
        position: relative;
        top: -14px;
        color: white;
        font-size: 12px;
        font-weight: 300;
        margin-top: 33px;
        padding-left: 12px;
        padding-right: 12px;
    }

    #learn_more {
        position: relative;
        top: -4px;
        color: #ff7200;
        font-size: 13px;

    }

    .whatsNew3cards .swiper-button-next {
        color: white;
        position: absolute;
        top: 190px !important;
        right: -50px;
    }


    .whatsNew3cards .swiper-button-prev {
        color: white;
        position: absolute;
        top: 190px !important;
        left: 65px;
    }


    .whatsNew3cards .swiper-pagination {
        display: none;
    }

    .lynkit_feed {
        width: 100%;
        /* height: 1010px; */
        height: 1120px;
        background-color: rgb(22, 22, 22);
        /* position: absolute; */
        /* background-color: rgb(224, 223, 223); */
        /* top: 5020px; */
        position: relative;
    }

    .lynkit_feed h1 {
        color: #ff7200;
        position: relative;
        top: 30px;
        align-items: center;
        text-align: center;
        letter-spacing: 0px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 1px;
        font-size: 54px;

    }

    .feed_inner {
        width: 90%;
        height: 540px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 70px;
        display: flex;
    }

    .awardsSection {
        /* height: 790px; */
        height: 565px;
        position: relative;
        /* top: 2740px */
    }

    .feed_right {
        width: 100%;
        height: 720px;
        /* border: 1px solid red; */
    }

    .awardsListing700 {
        display: none;
    }

    .awardsListing {
        width: 100%;
        height: auto;
        position: relative;
        top: 60px;
        align-items: center;
    }

    .awardsRow1 {
        width: 100%;
        height: 200px;
        display: flex;
        justify-content: center;
    }

    .awardBox2 {
        width: 20%;
        height: 180px;
        margin-left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .awardBox {
        width: 20%;
        height: 180px;
        margin-left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .awardImg {
        max-width: 100%;
        max-height: 100%;
        height: 100%;
        object-fit: contain;

    }

    .banner_video_slider {
        display: none !important;
    }

    .EnterpriseTextElement {
        width: 90%;
        height: auto;
        padding: 8px;
        position: relative;
        text-align: justify;
        /* border: 1px solid #404040; */
        /* box-shadow: 0px 0px 10px #585858; */
        /* background-color: #fff; */
    }

    .enterSpan {
        font-size: 10px;
        font-weight: 300;
        color: #F5F5F5 !important;
    }

    .enterpriseClose {
        width: 20px;
        height: 20px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: -10px;
        right: -10px;
        /* background-color: #ff7200; */
        border-radius: 50%;
    }

    .entSpanActive {
        font-size: 11px;
        color: #ff7200;
        font-weight: 800;
        font-family: 'Bai Jamjuree', sans-serif ! important;
    }

    .entSpan {
        font-size: 11px;
        color: #fff;
        font-weight: 800;
        font-family: 'Bai Jamjuree', sans-serif ! important;
    }

    .neeProd {
        width: 90%;
        height: auto;
        display: flex;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
        margin-top: 55px;
        box-shadow: 0px 0px 10px grey;
        border-radius: 10px;
    }

    .prodSpan {
        font-size: 42px;
        /* padding-left: 1.5%; */
        font-weight: 600;
        position: relative;
        top: 15px;
        line-height: 49px;
        color: #ff7200;
        font-family: 'Fjalla One', sans-serif;
    }

    .prodData {
        font-size: 13px;
        font-weight: 500;
        margin-top: 35px;
        text-align: justify;
        text-shadow: none;
        letter-spacing: 0;
        position: relative;
        left: 9px;
        font-weight: 300;
    }

    .prodImage {
        width: 65%;
        height: 316px;
    }

    .api-div {
        position: absolute;
        height: 1100px;
        top: 70px;
        width: 100%;
        background-color: rgb(22, 22, 22);
        align-items: center;
    }


    .api_card_1 {
        max-width: 100% !important;
        height: 427px;
        display: flex !important;
        flex-direction: column;
        position: absolute;
        left: 0px;
        /* border: 1px solid red; */
        align-items: center;
        text-align: center;
    }

    .api-swiper {
        width: 100%;
        height: 510px;
        /* border: 2px solid cyan; */
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 5%;
        border-radius: 12px !important;
        overflow: hidden;
    }

    .api-swiper .swiper-slide {
        background-position: center;
        background: #030303;
        background-size: cover;
        width: 300px !important;
        height: 460px;
        transition: all 200ms linear;
        position: relative;
        top: 10px;
        left: 100px;
        /* background-color: rebeccapurple; */
        box-shadow: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 30px;
        margin-left: 30px;
        border-radius: 10px;
        border: 1px solid #ff7700;

    }

    .api-card-data {

        position: relative;
        top: 175px;
        width: 100%;
        height: 240px;
        display: flex;
        flex-direction: column;
    }

    .api-card-data h2 {
        color: #ff7200;
        font-size: 18px;
        position: relative;
        left: 0px;
        text-align: center;
        align-items: center !important;
        font-weight: 700;
        margin: 5px 8px 0px;
        /* font-family: 'Montserrat', sans-serif; */
        font-family: 'Montserrat', sans-serif;
    }

    .api-card-data p {
        position: relative;
        top: 9px;
        color: white;
        font-size: 13px;
        font-weight: 300;

        padding-left: 16px;
        padding-right: 16px;
    }

    .api-card-img {
        width: 100%;
        height: 177px;
        position: absolute;
        left: 0px;
        top: 0px;
        border-radius: 10px;


    }

    .api-card-img img {
        width: 85%;
        height: 100%;
        object-fit: contain;
        position: relative;
        left: 0px;

    }

    .api-swiper .swiper-slide-active {
        width: 300px;
        height: 460px;
        position: relative;
        top: 10px;


    }

    .api-swiper .swiper-slide-active img {
        position: relative;
        top: 0px;

    }


    .api-swiper .swiper-button-next {
        color: #ff7722 !important;
        position: absolute !important;
        right: -50px !important;
        top: 200px !important;
    }

    .api-swiper .swiper-button-prev {
        color: #ff7722 !important;
        position: absolute !important;
        top: 200px !important;
        left: 65px !important;
    }


    .api-swiper .swiper-pagination {
        bottom: -55px;
        left: 6.5%;
    }

    .swiper-pagination-bullet-active {
        opacity: 1;
        background: #ff7700 !important;
        filter: brightness(2);
    }

    .swiper-pagination-bullet {
        background: #8f8f8f !important;
    }
    .banner_headings_messgae {
        width: 100%;
        height: 5%;
        color: white;
        bottom: 506px;
        position: relative;
        z-index: 10;
        text-align: center;
    }
    .home_page_footer {
        width: 100%;
        /* position: absolute;
        top: 5966px; */
        position: relative;
    }



}

/* media query 6 */

@media only screen and (max-width:1250px) {


    body {
        width: 100%;
        height: 100%;
        display: inline;
        z-index: -1;

    }

    .EnterpriseTextElementNew {
        display: none;
    }

    .PEnterDegign {
        display: none;
    }

    .mainMobile {
        display: none;
    }

    .whatsNew500 {
        display: none;
    }

    .api-div500 {
        display: none;
    }

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #ff7200;
        border-radius: 7px;

    }


    #lll {
        position: relative;
        left: 30px;
        font-size: 6px;
        font-weight: lighter;
    }


    .video_part {
        width: 100%;
        height: 1100px;
        position: relative;
        top: 0px;
        z-index: 1;

    }

    .video_part .headings {
        width: 100%;
        height: 54%;
        position: relative;
        top: 20px;
    }

    video {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50px;
        object-fit: initial;
        z-index: 1;
    }


    .three_btns_for_videos {
        width: 5%;
        height: 30px;
        display: flex;
        position: relative;

        top: -44px;
        color: #ff7200;
        justify-content: center;
        align-items: center;
        justify-content: space-between;
        z-index: 10;



    }

    .three_btns_for_videos button {

        width: 11px;
        height: 11px;
        border-radius: 12px;
        border: none;

    }


    .banner_headings {
        width: 40%;
        height: 20%;
        color: #ff7200;

        bottom: 360px;
        position: relative;
        left: 55px;
        z-index: 10;

        /*  border: 0.2px solid red;  */
    }

    .banner_headings h4 {
        font-size: 59px;
        font-weight: 900;
        text-shadow: 1px 5px 1px black;
        font-family: 'Fjalla One', sans-serif;
        line-height: 62px;
    }


    .banner_headings h6 {
        color: #fff;
        font-size: 16px;
        text-shadow: 1px 2px 1px black;
        position: relative;
        top: 0px;
        left: 2px;
        font-weight: 500;
        font-family: 'Montserrat', sans-serif;
    }

    .banner_btn {
        background-color: #ff7200;
        border: none;
    }





    .main {
        width: 100%;
        /* height: 699px; */
        height: 100%;
        position: relative;
        margin-top: -368px;
        background-color: #f7f7f7;
        z-index: 2;
    }

    .Product_heading {
        position: relative;
        text-align: center;
        justify-content: center;
        align-items: center;


    }

    .Product_heading h2 {

        font-size: 48px;
        font-weight: 900;
        text-shadow: none;
        position: relative;
        top: 0px;
        letter-spacing: 0px !important;
        font-family: 'Montserrat', sans-serif;

    }

    .Product_titles {

        width: 93%;
        height: 63px;
        position: relative;
        top: 19px;
        left: -1%;
        display: flex;
        justify-content: space-between;
        text-decoration: none;
        /* border: 2px solid red; */

    }

    .Product_titles img {
        display: inline-table;
    }




    #ll {
        color: #fff;
    }




    .Product_titles a {
        color: #fff;
        font-size: 22px;
        padding-top: 1px;
        cursor: pointer;
        text-decoration: none;
    }

    .Product_titles img {
        color: #fff;
    }

    /* .Product_titles .active {
    border-bottom: 1px solid #ff7200;
    background-color: transparent;
    
} */


    #icon_img1 {
        vertical-align: middle;
        width: 42px;


    }

    #icon_img2 {
        vertical-align: middle;
        width: 45px;
        height: 45px;
        position: relative;
        left: -8px;

    }

    #icon_img3 {
        vertical-align: middle;
        width: 44px;
        height: 48px;
        position: relative;
        left: -1px;

    }

    #icon_img4 {
        vertical-align: middle;
        width: 45px;
        position: relative;
        left: 27px;
        height: 47px;


    }

    #icon_img5 {
        vertical-align: top;
        position: relative;
        right: -21px;
        top: -7px;
        height: 39px;
        width: 38px;


    }

    #active_img5 {
        vertical-align: middle;
        width: 32px;
        height: 36px;
        filter: brightness(0) invert(1);
        position: relative;
        right: 0px;


    }

    #icon_img6 {
        vertical-align: top;
        position: relative;
        bottom: 10px;
        left: 36px;
        height: 46px;
        width: 46px;

    }

    #icon_5 {
        cursor: pointer;

    }

    #aligning_text1 {
        color: black;
        position: relative;
        top: -3px;
        left: 1px;
        font-size: 14px;
        font-weight: 500;
    }

    #aligning_text2 {
        color: black;
        position: relative;
        top: -3px;
        left: -2px;
        font-size: 14px;
        font-weight: 500;
    }


    #aligning_text3 {
        color: black;
        position: relative;
        top: -7px;
        left: 2px;
        font-size: 14px;
        font-weight: 500;
    }

    #aligning_text4 {
        color: black;
        position: relative;
        top: -10px;
        left: 28px;
        font-size: 14px;
        font-weight: 500;
    }

    #aligning_text {
        color: black;
        position: relative;
        top: -6px;
        left: 23px;
        font-size: 14px;
        font-weight: 500;
    }


    #aligning_text5 {
        color: black;
        position: relative;
        top: -9px;
        left: 39px;
        font-size: 14px;
        font-weight: 500;
    }

    #active_text_2 {
        color: #fff;
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        position: relative;
        left: 3px;
        top: 6px;


    }




    .sub {
        /*  border: 1px solid red;  */
        position: relative;
        top: 70px;
        background-color: #fff;
        width: 92%;
        height: 567px;
        border-radius: 10px;

    }

    #twoD {
        color: black;
        position: relative;
        top: -1px;
        left: 10px;
    }

    .sub .left_div {
        width: 45%;
        height: 85%;





    }

    #product_images {
        width: 96%;
        height: 500px;
        position: relative;
        left: 4%;
        top: 6%;


    }



    #video_product_2 {
        width: 100%;
        height: 87%;
        margin-top: 0px;
        margin-left: -2px;
    }

    .sub .right_div {
        width: 46%;
        height: 70%;


    }

    .sub .right_div #head1 {

        font-size: 42px;
        padding-left: 1%;
        font-weight: 700;
        position: relative;
        top: 15px;
        line-height: 43px;

    }

    .sub .right_div #head2 {

        font-size: 13px;
        font-weight: 300;
        line-height: 20px;
        margin-top: 35px;
        text-align: justify;
        text-shadow: none;
        letter-spacing: 0px;
        position: relative;
        left: 9px;
        top: 10px;

    }

    .right_div button {
        width: 80px;
        height: 32px;
        display: flex;
        position: relative;
        bottom: -38px;
        left: -21px;
        border-radius: 15px;
        font-weight: 300;
        font-size: 10px;
        text-decoration: none;
        padding-left: 10px;

    }

    #warehouse1 {
        color: black;
        display: block;
        position: relative;
    }

    #active_text1 {
        color: #fff;
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        display: block;
        position: relative;
    }

    .Product_titles .active {
        background-color: #ff7200;
        border-radius: 38px;
        width: 173px;
        height: 63px;
        justify-content: center;
        text-align: center;
        border: none;
        box-shadow: 3px 3.5px 1px grey;
        position: relative;
        bottom: 8px;
    }

    #active_img {
        height: 39px;
    }

    .achievements_div {
        width: 100%;
        height: 771px;
        /* position: absolute;
        top: 1345px; */
        position: relative;
        background-color: #070707;
    }

    .achievements_image {
        width: 100%;
        height: 546px;
    }

    #blur_image {
        width: 100%;
        height: 470px;
    }

    .achivements_div_1 {
        width: 75%;
        height: 172px;
        /*  border: 1px solid red; */
        position: absolute;
        bottom: 334px;
        left: 12.5%;
        display: flex;
        justify-content: space-between;
    }

    .achivements_div_1 div {
        width: 21%;
        height: 170px;
        /*  border: 3px solid yellow; */
        background-color: #302f2f;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        box-shadow: inset 0px 0px 39px rgb(17, 16, 16);
        box-shadow: 4px 4px 10px black;
        border-radius: 7px;


    }

    .achivements_div_1 div h1 {
        color: #ff7200;
        font-weight: 900;
    }


    .achivements_div_1 div p {
        font-size: 18px;
        color: white;
        font-weight: 500;
    }




    .achivements_div_2 {
        width: 75%;
        height: 172px;

        position: absolute;
        bottom: 120px;
        left: 12.5%;
        display: flex;
        justify-content: space-between;
    }

    .achivements_div_2 div {
        width: 21%;
        height: 170px;
        /*  border: 3px solid yellow; */
        background-color: #302f2f;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        box-shadow: inset 0px 0px 39px rgb(17, 16, 16);
        box-shadow: 4px 4px 10px black;
        border-radius: 7px;


    }


    .achivements_div_2 div h1 {
        color: #ff7200;
        font-weight: 900;
    }

    #hundred {
        position: relative;
        top: 14px;

    }

    #client_100 {
        position: relative;
        top: 14px;

    }

    .iot_h {
        position: relative;
        top: 14px;
    }

    .iot_p {
        position: relative;
        top: 13px;
    }

    .achivements_div_2 div p {
        font-size: 18px;
        color: white;
        font-weight: 400;
    }

    .Enterprise_div {
        width: 100%;
        height: 500px !important;
        /* position: absolute;
        top: 2660px; */
        position: relative;
        display: flex;
        justify-content: center;
        overflow-x: hidden;
    }

    .Enterprise_div h1 {
        font-size: 48px;
        color: #ff7200;
        text-shadow: none;
        position: absolute;
        top: 0px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-image: linear-gradient(#fff, #fff);
        background-repeat: no-repeat;
        line-height: 110px;
        background-size: 0% 4px;
        background-position: bottom;
        letter-spacing: 0px !important;
    }

    .enterprise_main_div {
        width: 80%;
        height: 500px;
        /*  background-color: aqua; */
        position: relative;
        top: 200px !important;
        display: flex;
    }

    .enterprise_left {
        width: 50%;
        height: 500px;
        /*  background-color: bisque; */
        display: flex;
        justify-content: center;
        align-items: center;
    }



    .enterprise_headings {
        color: #fff;
        font-size: 10px;
        display: block;
        align-items: center;
        text-align: center;
        cursor: pointer;
    }

    .enterprise_main_div {
        width: 80%;
        height: 500px;
        /*  background-color: aqua; */
        position: relative;
        top: 270px;
        display: flex;



    }

    .customer_div {
        width: 100%;
        height: 700px;
        /* position: absolute;
        top: 3141px; */
        position: relative;
        display: flex;
        justify-content: center;
    }

    .customer_div h1 {

        font-size: 48px;
        color: black;
        text-shadow: none;
        position: absolute;
        top: 20px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-image: linear-gradient(#ff7200, #ff7200);
        background-repeat: no-repeat;
        line-height: 110px;
        background-size: 0% 4px;
        background-position: bottom;
        letter-spacing: 0px !important;


    }

    .customers_div_1 {
        width: 97%;
        height: 160px;
        position: absolute;
        top: 170px;
        display: flex;
        justify-content: space-around;

    }

    .customers_div_1 div {
        width: 16%;
        height: 160px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }

    .customers_div_1 img {
        /* filter: grayscale(100%); */
    }

    .customers_div_1 img:hover {
        cursor: pointer;
        filter: none;
        /* filter: grayscale(0%); */
    }


    .customers_div_2 {
        width: 97%;
        height: 160px;

        position: absolute;
        top: 367px;
        display: flex;
        justify-content: space-around;

    }

    .customers_div_2 div {
        width: 16%;
        height: 160px;

        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }


    .customers_div_2 img {
        /* filter: grayscale(100%); */
    }

    .customers_div_2 img:hover {
        cursor: pointer;
        filter: none;
        /* filter: grayscale(0%); */
    }

    .customers_div_3 {
        width: 25%;
        height: 182px;
        /* border: 2px solid green; */
        position: absolute;
        top: 600px;
        display: flex;
        justify-content: space-between;
    }

    .customer_images {
        width: 107px;
    }

    #cma {
        width: 90px;
        height: 90px;
    }

    #bharat {
        width: 95px;
        height: 110px;
    }

    #aditya {
        width: 90px;
        height: 90px;
    }

    #lg {
        width: 95px;
        height: 60px;
    }

    #dadri {
        width: 110px;
        height: 95px;
    }


    #more_3500 {
        width: 100%;
        height: 66px;
        position: relative;
        top: 536px;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    #more_3500 h1 {
        position: relative;
        font-size: 46px;
        color: #424141;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
        background-image: linear-gradient(white, white);
        background-repeat: none;
        background-size: 0% 0px;
        font-family: 'Fjalla One', sans-serif;
    }

    #more_3500 h1:hover {
        color: black;
    }


    .whatsNew {
        width: 100%;
        height: 620px;
        /* position: absolute;
        top: 3774px; */
        position: relative;
        justify-content: center;
    }



    .whatsNew h1 {

        z-index: 1;
        color: #ff7200;
        position: relative;
        bottom: 610px;
        align-items: center;
        text-align: center;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-image: linear-gradient(#fff, #fff);
        font-size: 48px;
        background-repeat: no-repeat;
        line-height: 100px;
        background-size: 0% 4px;
        background-position: bottom;
        letter-spacing: 0px !important;
    }



    .whatsNew3cards {
        width: 100%;
        height: 490px;
        /* border: 2px solid cyan;    */
        justify-content: center;


        position: relative;
        bottom: 615px;
        border-radius: 0px !important;
        overflow: hidden;
    }


    .whatsNew3cards .swiper-slide {
        background-position: center;
        background: #141414;
        background-size: cover;
        width: 320px;
        height: 490px;
        transition: all 200ms linear;
        position: relative;
        top: 0px;
        left: 70px;
        box-shadow: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 50px;
    }



    .whatsNew3cards .swiper-slide-active {
        width: 320px;
        height: 490px;
        position: relative;
        top: 0px;
        box-shadow: none;

    }

    .new_card_1 {
        max-width: 100% !important;
        height: 480px;
        display: flex !important;
        flex-direction: column;
        position: relative;
        left: 0px;
        /* border: 1px solid red;  */
        align-items: center;
        text-align: center;
        box-shadow: none !important;
    }

    .new_img {
        width: 100%;
        height: 177px;
        position: relative;
        left: 0px;
        top: 1rem;

    }

    .whatsNew3cards .swiper-slide-active img {
        position: relative;
        top: 0px;

    }

    .new_img img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: absolute;
        left: 0px;

    }

    .new_data {
        position: relative;
        top: 1rem;
        display: flex;
        flex-direction: column;
    }

    .new_data h2 {
        color: #ff7200;
        font-size: 18px;
        position: relative;
        left: 0px;
        top: 7px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        margin-top: 18px;
    }

    .new_data p {
        position: relative;
        top: 0px;
        color: white;
        font-size: 12px;
        font-weight: 300;
        margin-top: 33px;
        padding-left: 12px;
        padding-right: 12px;
    }

    #learn_more {
        position: relative;
        top: 4px;
        color: #ff7200;
        font-size: 13px;

    }

    .whatsNew3cards .swiper-button-next {
        color: white;
        position: absolute;
        right: -50px;
        top: 200px !important;
    }


    .whatsNew3cards .swiper-button-prev {
        color: white;
        position: absolute;
        left: 65px;
        top: 200px !important;
    }


    .whatsNew3cards .swiper-pagination {
        display: none;
    }

    .achievements_div h1 {
        font-size: 48px;
        letter-spacing: 0px;
    }

    .lynkit_feed {
        width: 100%;
        /* height: 1010px; */
        height: 1100px;
        background-color: rgb(22, 22, 22);
        /* position: absolute; */
        /* background-color: rgb(224, 223, 223); */
        /* top: 4938px; */
        position: relative;
    }

    .lynkit_feed h1 {
        color: #ff7200;
        position: relative;
        top: 30px;
        align-items: center;
        text-align: center;
        letter-spacing: 0px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 1px;
        font-size: 48px;

    }

    .feed_inner {
        width: 90%;
        height: 540px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 70px;
        display: flex;
    }

    .awardsSection {
        /* height: 630px; */
        height: 560px;
        position: relative;
        /* top: 2964px; */
    }

    .awardsListing {
        width: 100%;
        height: auto;
        position: relative;
        top: 50px;
        align-items: center;
    }

    .awardsSpans {
        font-size: 48px;
        color: #000;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        font-weight: 800 !important;
        background-size: 0% 4px;
        background-position: bottom;
    }

    .awardsRow1 {
        width: 100%;
        height: 200px;
        display: flex;
        justify-content: center;
    }

    .awardBox2 {
        width: 20%;
        height: 180px;
        margin-left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .feed_right {
        width: 100%;
        height: 720px;
        /* border: 1px solid red; */
    }

    .api-div {
        position: absolute;
        height: 1100px;
        top: 70px;
        width: 100%;
        background-color: rgb(22, 22, 22);
        align-items: center;
    }


    .api_card_1 {
        max-width: 100% !important;
        height: 427px;
        display: flex !important;
        flex-direction: column;
        position: absolute;
        left: 0px;
        /* border: 1px solid red; */
        align-items: center;
        text-align: center;
    }

    .api-swiper {
        width: 100%;
        height: 510px;
        /* border: 2px solid cyan; */
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 5%;
        border-radius: 12px !important;
        overflow: hidden;
    }

    .api-swiper .swiper-slide {
        background-position: center;
        background: #030303;
        background-size: cover;
        width: 300px !important;
        height: 460px;
        transition: all 200ms linear;
        position: relative;
        top: 10px;
        left: 100px;
        /* background-color: rebeccapurple; */
        box-shadow: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 30px;
        margin-left: 30px;
        border-radius: 10px;
        border: 1px solid #ff7700;

    }

    .api-card-data {

        position: relative;
        top: 175px;
        width: 100%;
        height: 240px;
        display: flex;
        flex-direction: column;
    }

    .api-card-data h2 {
        color: #ff7200;
        font-size: 18px;
        position: relative;
        left: 0px;
        text-align: center;
        align-items: center !important;
        font-weight: 700;
        margin: 5px 8px 0px;
        /* font-family: 'Montserrat', sans-serif; */
        font-family: 'Montserrat', sans-serif;
    }

    .api-card-data p {
        position: relative;
        top: 9px;
        color: white;
        font-size: 13px;
        font-weight: 300;

        padding-left: 16px;
        padding-right: 16px;
    }

    .api-card-img {
        width: 100%;
        height: 177px;
        position: absolute;
        left: 0px;
        top: 0px;
        border-radius: 10px;


    }

    .api-card-img img {
        width: 85%;
        height: 100%;
        object-fit: contain;
        position: relative;
        left: 0px;

    }

    .api-swiper .swiper-slide-active {
        width: 300px;
        height: 460px;
        position: relative;
        top: 10px;


    }

    .api-swiper .swiper-slide-active img {
        position: relative;
        top: 0px;

    }


    .api-swiper .swiper-button-next {
        color: #ff7722 !important;
        position: absolute !important;
        right: -50px !important;
        top: 200px !important;
    }

    .api-swiper .swiper-button-prev {
        color: #ff7722 !important;
        position: absolute !important;
        top: 200px !important;
        left: 65px !important;
    }


    .api-swiper .swiper-pagination {
        bottom: -55px;
        left: 5.5%;
    }

    .swiper-pagination-bullet-active {
        opacity: 1;
        background: #ff7700 !important;
        filter: brightness(2);
    }

    .swiper-pagination-bullet {
        background: #8f8f8f !important;
    }

    .home_page_footer {
        /* position: absolute;
        top: 5884px; */
        position: relative;
    }

    .banner_video_slider {
        display: none !important;
    }

    .prodImage {
        width: 85%;
        height: 316px;
    }

    .prodData {
        font-size: 13px;
        font-weight: 300;
        line-height: 20px;
        margin-top: 35px;
        text-align: justify;
        text-shadow: none;
        letter-spacing: 0;
        position: relative;
        left: 9px;
        top: 10px;
    }
    .banner_headings_messgae {
        width: 100%;
        height: 5%;
        color: white;
        bottom: 535px;
        position: relative;
        z-index: 10;
        text-align: center;
    }
    .awardsListing700 {
        display: none;
    }
}

/* media query 7 */
@media only screen and (max-width:1200px) {


    body {
        width: 100%;
        height: 100%;
        display: inline;
        z-index: -1;

    }

    .EnterpriseTextElementNew {
        display: none;
    }

    .PEnterDegign {
        display: none;
    }

    .mainMobile {
        display: none;
    }

    .whatsNew500 {
        display: none;
    }

    .api-div500 {
        display: none;
    }

    .counts {
        font-size: 1.75rem;
    }

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #ff7200;
        border-radius: 7px;

    }


    #lll {
        position: relative;
        left: 30px;
        font-size: 6px;
        font-weight: lighter;
    }



    .video_part {
        width: 100%;
        height: 1020px;
        position: relative;
        top: 0px;
        z-index: 2;

    }

    .video_part .headings {
        width: 100%;
        height: 48%;
        position: relative;
        top: 20px;
    }

    video {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50px;
        object-fit: initial;
        z-index: 1;
    }


    .three_btns_for_videos {
        width: 4.6%;
        height: 30px;
        display: flex;
        position: relative;

        top: -22px;
        color: #ff7200;
        justify-content: center;
        align-items: center;
        justify-content: space-between;
        z-index: 10;



    }

    .three_btns_for_videos button {

        width: 11px;
        height: 11px;
        border-radius: 12px;
        border: none;

    }


    .banner_headings {
        width: 41%;
        height: 20%;
        color: #ff7200;

        bottom: 320px;
        position: relative;
        left: 50px;
        z-index: 10;

        /*  border: 0.2px solid red;  */
    }

    .banner_headings h4 {
        font-size: 56px;
        font-weight: 900;
        text-shadow: 1px 5px 1px black;
        font-family: 'Fjalla One', sans-serif;
        line-height: 59px;
    }


    .banner_headings h6 {
        color: #fff;
        font-size: 14px;
        text-shadow: 1px 2px 1px black;
        position: relative;
        top: -2px;
        left: 2px;
        font-weight: 500;
        font-family: 'Montserrat', sans-serif;
    }

    .banner_btn {
        background-color: #ff7200;
        border: none;
    }





    .main {
        width: 100%;
        height: 710px;
        position: relative;
        margin-top: -388px;
        background-color: #f7f7f7;
        z-index: 2;
    }


    .Product_heading {
        position: relative;
        text-align: center;
        justify-content: center;
        align-items: center;


    }

    .Product_heading h2 {

        font-size: 48px;
        font-weight: 900;
        text-shadow: none;
        position: relative;
        top: -10px;
        background-image: linear-gradient(#ff7200, #ff7200);
        letter-spacing: 0px !important;
        background-repeat: no-repeat;
        line-height: 120px;
        background-size: 0% 4px;
        background-position: bottom;


    }


    .Product_titles {

        width: 93%;
        height: 63px;
        position: relative;
        top: 9px;
        left: 0%;
        display: flex;
        justify-content: space-between;
        text-decoration: none;

    }

    .Product_titles img {
        display: inline-table;
    }




    #ll {
        color: #fff;
    }




    .Product_titles a {
        color: #fff;
        font-size: 22px;
        padding-top: 1px;
        cursor: pointer;
        text-decoration: none;
    }

    .Product_titles img {
        color: #fff;
    }

    /* .Product_titles .active {
    border-bottom: 1px solid #ff7200;
    background-color: transparent;
    
} */


    #icon_img1 {
        vertical-align: middle;
        width: 43px;


    }

    #icon_img2 {
        vertical-align: middle;
        width: 47px;
        height: 44px;
        position: relative;
        left: -8px;

    }

    #icon_img3 {
        vertical-align: middle;
        width: 53px;
        height: 54px;
        position: relative;
        left: -1px;

    }

    #icon_img4 {
        vertical-align: middle;
        width: 43px;
        position: relative;
        left: 13px;
        height: 47px;


    }

    #icon_img5 {
        vertical-align: top;
        position: relative;
        left: 12px;
        top: -2px;
        height: 41px;
        width: 38px;

    }

    #icon_img6 {
        vertical-align: top;
        position: relative;
        bottom: 10px;
        left: 31px;
        height: 50px;
        width: 46px;

    }

    #icon_img6 {
        vertical-align: top;
        position: relative;
        bottom: 10px;
        left: 22px;
        height: 46px;
        width: 46px;

    }

    #aligning_text5 {
        color: black;
        position: relative;
        top: -8px;
        left: 22px;
        font-size: 13px;
        font-weight: 500;
    }

    #icon_5 {
        cursor: pointer;

    }

    #aligning_text1 {
        color: black;
        position: relative;
        top: -7px;
        left: 4px;
        font-size: 13px;
        font-weight: 500;
    }

    #aligning_text2 {
        color: black;
        position: relative;
        top: -3px;
        left: -4px;
        font-size: 13px;
        font-weight: 500;
    }

    #active_img5 {
        vertical-align: middle;
        width: 31px;
        height: 34px;
        filter: brightness(0) invert(1);
        position: relative;
        right: 2px;
    }

    #warehouse1 {
        color: black;
        display: block;
        position: relative;
    }

    .Product_titles .active {
        background-color: #ff7200;
        border-radius: 38px;
        width: 152px;
        height: 60px;
        justify-content: center;
        text-align: center;
        border: none;
        box-shadow: 3px 3.5px 1px grey;
    }


    #aligning_text3 {
        color: black;
        position: relative;
        top: -6px;
        left: 4px;
        font-size: 13px;
        font-weight: 500;
    }

    #aligning_text4 {
        color: black;
        position: relative;
        top: -14px;
        left: 13px;
        font-size: 13px;
        font-weight: 500;
    }

    #aligning_text {
        color: black;
        position: relative;
        top: -1px;
        left: 14px;
        font-size: 13px;
        font-weight: 500;
    }

    #active_text_2 {
        color: #fff;
        font-size: 13px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        position: relative;
        right: 8px;
        top: 5px;


    }






    .sub {
        /*  border: 1px solid red;  */
        position: relative;
        top: 72px;
        background-color: #fff;
        width: 92%;
        height: 548px;
        border-radius: 10px;

    }

    #twoD {
        color: black;
        position: relative;
        top: -1px;
        left: 10px;
    }

    .sub .left_div {
        width: 45%;
        height: 85%;

    }

    #product_images {
        width: 92%;
        height: 460px;
        position: relative;
        left: 4%;
        top: 7%;


    }



    #video_product_2 {
        width: 100%;
        height: 87%;
        margin-top: 0px;
        margin-left: -2px;
    }

    .sub .right_div {
        width: 49%;
        height: 70%;




    }

    .sub .right_div #head1 {

        font-size: 42px;
        padding-left: 1%;
        font-weight: 700;
        position: relative;
        top: 15px;
        line-height: 43px;
        font-family: 'Fjalla One', sans-serif;
    }

    .sub .right_div #head2 {

        font-size: 12px;
        font-weight: 300;
        margin-top: 35px;
        text-align: justify;
        text-shadow: none;
        letter-spacing: 0px;
        position: relative;
        left: 9px;

    }

    .right_div button {
        width: 80px;
        height: 33px;
        display: flex;
        position: relative;
        bottom: -38px;
        left: -21px;
        border-radius: 15px;
        font-weight: 300;
        font-size: 10px;
        text-decoration: none;
        padding-left: 12px;

    }

    #active_text1 {
        color: #fff;
        font-size: 13px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;

        display: block;
        position: relative;
    }

    #active_text {
        font-size: 13px;
    }

    .achievements_div {
        width: 100%;
        height: 818px;
        /* position: absolute;
        top: 1270px; */
        position: relative;
        background-color: #070707;
    }

    .achievements_image {
        width: 100%;
        height: 546px;
    }

    #blur_image {
        width: 100%;
        height: 470px;
    }

    .achivements_div_1 {
        width: 75%;
        height: 172px;
        /*  border: 1px solid red; */
        position: absolute;
        bottom: 334px;
        left: 12.5%;
        display: flex;
        justify-content: space-between;
    }

    .achivements_div_1 div {
        width: 21%;
        height: 170px;
        /*  border: 3px solid yellow; */
        background-color: #302f2f;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        box-shadow: inset 0px 0px 39px rgb(17, 16, 16);
        box-shadow: 4px 4px 10px black;
        border-radius: 7px;


    }

    .achievements_div h1 {

        font-size: 48px;
        color: #ff7200;
        text-shadow: none;
        position: relative;
        top: 0px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-image: linear-gradient(#fff, #fff);

        background-repeat: no-repeat;
        line-height: 129px;
        background-size: 0% 4px;
        background-position: bottom;


    }


    .achivements_div_1 div p {
        font-size: 16px;
        color: white;
        font-weight: 500;
    }




    .achivements_div_2 {
        width: 75%;
        height: 172px;

        position: absolute;
        bottom: 120px;
        left: 12.5%;
        display: flex;
        justify-content: space-between;
    }


    .achivements_div_2 div {
        width: 21%;
        height: 170px;
        /*  border: 3px solid yellow; */
        background-color: #302f2f;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        box-shadow: inset 0px 0px 39px rgb(17, 16, 16);
        box-shadow: 4px 4px 10px black;
        border-radius: 7px;


    }


    .achivements_div_2 div h1 {
        color: #ff7200;
        font-weight: 900;
    }

    #hundred {
        position: relative;
        top: 14px;

    }

    #client_100 {
        position: relative;
        top: 14px;

    }

    .iot_h {
        position: relative;
        top: 14px;
    }

    .iot_p {
        position: relative;
        top: 13px;
    }

    .achivements_div_2 div p {
        font-size: 16px;
        color: white;
        font-weight: 400;
    }

    .achivementElem {
        width: 89%;
        height: 64%;
        margin-left: auto;
        margin-right: auto;
    }

    .achiveR1 {
        width: 100%;
        height: 50%;
        display: flex;
    }

    .achieverc1 {
        width: 20%;
        height: 100%;
        margin-right: 8px;
        position: relative;
    }

    .achieveImg {
        width: 100%;
        height: 100%;
        position: relative;
        justify-content: flex-start;
        text-align: left;
        align-items: center;
        z-index: 1;
    }

    .achieve100 {
        position: absolute;
        top: 30px;
        left: 30px;
        font-size: 1.7rem;
        font-weight: 600;
        color: #ff7200;
        z-index: 10;
    }

    .achieveClient {
        position: absolute;
        top: 60px;
        left: 30px;
        font-weight: 500;
        z-index: 10;
    }

    .achieveInstall {
        position: absolute;
        top: 80px;
        left: 30px;
        font-weight: 500;
        z-index: 10;
    }

    .achieverc2 {
        width: 30%;
        height: 100%;
        margin-right: 8px;
    }

    .achiveI1 {
        width: 100%;
        height: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #ff7200;
        box-shadow: 0px 0px 24px black inset;
        margin-bottom: 8px;
    }

    .threefive {
        font-size: 1.7rem;
        color: #fff;
        font-weight: 700;
        position: relative;
        top: 10px;
    }

    .iot {
        font-size: 1rem;
        font-weight: 400;
        color: #fff;
    }

    .achiveI2 {
        width: 100%;
        height: 57%;
        position: relative;
    }

    .vehicle_text {
        color: #ff7200;
        font-size: 3.5rem;
        font-weight: 600;
        position: absolute;
        z-index: 10;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .vehicle_sub_text {
        color: #fff;
        position: absolute;
        z-index: 10;
        font-size: 1rem;
        top: 45%;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .achieverc3 {
        width: 20%;
        height: 100%;
        margin-right: 8px;
    }

    .achieve50 {
        width: 100%;
        height: 49%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #464444;
        box-shadow: inset 1px 1px 22px #000;
        margin-bottom: 6px;
    }

    .achieve50b {
        width: 100%;
        height: 49%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #d3d3d3;
        box-shadow: inset 1px 1px 19px #000;
        margin-bottom: 6px;
    }

    .m100m {
        color: #ff7200;
        font-size: 1.7rem;
        font-weight: 600;
    }

    .transactions {
        color: #fff;
        position: relative;
        bottom: 7px;
        font-size: 0.65rem;

    }

    .img_overlay {
        position: absolute !important;
    }

    .overlay_main_text {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -70%);

        z-index: 10;
        color: #ff7200;
        font-size: 1.5rem;
        font-weight: 600;
    }

    .overlay_sub_text {
        color: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, 0);
        font-size: 0.7rem;
        z-index: 10;
    }

    .m100black {
        color: #000;
        font-size: 1.7rem;
        font-weight: 600;
    }

    .transactionsm {
        color: #000;
        position: relative;
        bottom: 7px;
    }

    .achieveI31 {
        width: 100%;
        height: 48%;

        margin-bottom: 8px;
    }

    .achieveI32 {
        width: 100%;
        height: 49%;
        margin-bottom: 8px;
    }

    .achieverc4 {
        width: 30%;
        height: 100%;
    }

    .achiveR2 {
        width: 100%;
        height: 50%;
        display: flex;
        position: relative;
    }

    .ach5 {
        width: 29%;
        height: 100%;
        margin-left: 8px;
        margin-top: 8px;
        position: relative;
    }

    .fifteen {
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 10;
    }

    .fifteenTrips {
        position: absolute;
        top: 60px;
        left: 35px;
        z-index: 10;
    }

    .ach6 {
        width: 21%;
        height: 100%;
        margin-top: 8px;
        position: relative;
    }

    .ach7 {
        width: 20%;
        height: 100%;
        margin-top: 8px;
        margin-left: 8px;
        position: relative;
    }

    .m1000 {
        color: #fff;
        position: absolute;
        top: 20px;
        left: 10px;
        font-size: 1.7rem;
        z-index: 10;
    }

    .lynkassist {
        color: #fff;
        position: absolute;
        top: 60px;
        left: 10px;
        font-size: 0.9rem;
        z-index: 10;
    }

    .lynkassistLoc {
        color: #fff;
        position: absolute;
        top: 80px;
        left: 10px;
        font-size: 0.9rem;
        z-index: 10;
    }

    .ach8 {
        width: 30%;
        height: 100%;
        margin-left: 8px;
        margin-top: 8px;
        display: flex;
        position: relative;
    }

    .m3 {
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 10;
        color: #ff7200;
        font-size: 2.5rem;
        font-weight: 600;
    }

    .rfid_cir {
        color: #fff;
        position: absolute;
        top: 70px;
        left: 20px;
        font-size: 1.25rem;
        z-index: 10;
    }

    .lynkit_testomonial {
        width: 100%;
        height: 700px !important;
        /* position: absolute;
        top: 1940px; */
        position: relative;
        z-index: 4;
    }

    .Enterprise_div {
        width: 100%;
        height: 540px !important;
        /* position: absolute;
        top: 2590px; */
        position: relative;
        display: flex;
        justify-content: center;
        overflow-x: hidden;
        z-index: 10;
        overflow: hidden;
    }

    .Enterprise_div h1 {
        font-size: 48px;
        color: #ff7200;
        text-shadow: none;
        position: absolute;
        top: -27px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-image: linear-gradient(#fff, #fff);
        background-repeat: no-repeat;
        line-height: 129px;
        background-size: 0% 4px;
        background-position: bottom;
        letter-spacing: 0px !important;
    }

    .enterOuter {
        width: 100%;
        height: 75%;
        z-index: 1;
        position: relative;
        top: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .enterInner {
        width: 90%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .enterprise_main_div {
        width: 80%;
        height: 500px;
        /*  background-color: aqua; */
        position: relative;
        top: 180px !important;
        display: flex;

    }

    .entSpan {
        font-size: 11px;
        color: #fff;
        font-weight: 800;
        font-family: 'Bai Jamjuree', sans-serif ! important;
    }

    .enterOrange {
        color: #ff7200;
        font-size: 11px;
        font-weight: 500;
        font-family: 'Bai Jamjuree', sans-serif ! important;
    }

    .enterprise_left {
        width: 50%;
        height: 500px;
        /*  background-color: bisque; */
        display: flex;
        justify-content: center;
        align-items: center;

    }


    .homeSlider_swiper > .swiper-container {
        width: 100%;

        position: absolute;
        right: 0px;
        overflow: visible;

    }

    .homeSlider_swiper > .swiper-slide {
        background-position: center;
        background-size: cover;
        width: 270px;
        height: 320px;
        transition: all 200ms linear;
        position: relative;
        background-color: #141414;
        box-shadow: 2px 2px 10px black;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;


    }

    .homeSlider_swiper > .swiper-slide>img {
        width: 36%;
        height: 135px;
        position: relative;
        bottom: 29px;
        left: 30%;

    }

    .homeSlider_swiper > .swiper-slide h2 {
        font-size: 22px;
        color: #ff7200;
        position: relative;
        left: 4%;
        word-wrap: break-word;
        white-space: initial;



    }

    .homeSlider_swiper > .swiper-slide-active {
        width: 400px;
        height: 400px;



    }

    .homeSlider_swiper > .swiper-slide.swiper-slide-active .initial_data {
        display: none;
    }

    .supply_para {
        font-size: 30px !important;
        position: relative;
        top: 30px;
        left: 57px !important;
    }

    .block_para {
        font-size: 30px !important;
        position: relative;
        top: 30px;
        left: 36px !important;
        width: 69% !important;

    }

    .iot_para {
        font-size: 30px !important;
        position: relative;
        top: 45px;
        left: 82px !important;
        width: 55% !important;
    }

    .supply_class {
        width: 84%;
        position: absolute;
        top: 160px;
        left: 8%;
        /* border: 2px solid red; */

    }

    .supply_class p {
        color: black;
        font-size: 12px;
        text-align: justify;
        line-height: 17px;

    }

    .block_class {
        width: 84%;
        position: absolute;
        top: 147px;
        left: 8%;
        /* border: 2px solid red; */

    }

    .block_class p {
        color: black;
        font-size: 12px;
        text-align: justify;
        line-height: 17px;

    }

    .iot_class {
        width: 84%;
        position: absolute;
        top: 172px;
        left: 8%;
        /* border: 2px solid red; */

    }

    .iot_class p {
        color: black;
        font-size: 12px;
        text-align: justify;
        line-height: 17px;

    }

    .swiper-button-next {
        color: #fff;
    }

    .swiper-button-prev {
        color: #fff;
    }

    .swiper-pagination {
        position: absolute;
        top: 430px;
        z-index: 10;
    }

    swiper-pagination-current {
        background: black;
    }

    .swiper-pagination-bullet-active {
        width: 8px;
        height: 8px;
        background-color: #fff !important;



    }

    .swiper-pagination-bullet {
        opacity: 1;

        background-color: black;
    }

    /*  */
    .customer_div {
        width: 100%;
        height: 700px;

        /* position: absolute;
        top: 3104px; */
        position: relative;
        display: flex;
        justify-content: center;
    }

    .customer_div h1 {

        font-size: 48px;
        color: black;
        text-shadow: none;
        position: absolute;
        top: 20px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-image: linear-gradient(#ff7200, #ff7200);
        background-repeat: no-repeat;
        line-height: 110px;
        background-size: 0% 4px;
        background-position: bottom;
        letter-spacing: 0px !important;


    }

    .customers_div_1 {
        width: 98%;
        height: 140px;
        /* border: 2px solid red; */
        position: absolute;
        top: 170px;
        display: flex;
        justify-content: space-around;

    }

    .customers_div_1 div {
        width: 16%;
        height: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }

    .customers_div_1 img {
        /* filter: grayscale(100%); */
    }

    .customers_div_1 img:hover {
        cursor: pointer;
        filter: none;
        /* filter: grayscale(0%); */
    }


    .customers_div_2 {
        width: 98%;
        height: 140px;

        position: absolute;
        top: 350px;
        display: flex;
        justify-content: space-around;

    }

    .customers_div_2 div {
        width: 16%;
        height: 140px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }

    .customers_div_2 img:hover {
        cursor: pointer;
        filter: none;
    }

    .customers_div_3 {
        width: 25%;
        height: 182px;
        position: absolute;
        top: 579px;
        display: flex;
        justify-content: space-between;
    }

    .customer_images {
        width: 99px;
    }

    #cma {
        width: 100px;
        height: 100px;
    }

    #bharat {
        width: 90px;
        height: 105px;
    }

    #aditya {
        width: 95px;
        height: 95px;
    }

    #lg {
        width: 95px;
        height: 60px;
    }

    #dadri {
        width: 92px;
        height: 90px;
    }

    #more_3500 {
        width: 100%;
        height: 66px;
        position: relative;
        top: 506px;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    #more_3500 h1 {
        position: relative;
        font-size: 35px;
        color: #424141;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
        background-image: linear-gradient(white, white);
        background-repeat: none;
        background-size: 0% 0px;
        font-family: 'Fjalla One', sans-serif;

    }

    #more_3500 h1:hover {
        color: black;
    }

    /*  */




    .whatsNew {
        width: 100%;
        height: 610px;
        /* position: absolute;
        top: 3713px; */
        position: relative;
        justify-content: center;
    }



    .whatsNew h1 {

        z-index: 1;
        color: #ff7200;
        position: relative;
        bottom: 610px;
        align-items: center;
        text-align: center;

        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 1px;
        background-image: linear-gradient(#fff, #fff);
        font-size: 48px;
        background-repeat: no-repeat;
        line-height: 100px;
        background-size: 0% 4px;
        background-position: bottom;
        letter-spacing: 0px !important;
    }

    .whatsNew img {
        width: 100%;
        height: 100%;

    }

    .whatsNew3cards {
        width: 100%;
        height: 510px;
        /* border: 2px solid cyan;    */
        justify-content: center;

        margin-left: auto;
        margin-right: auto;
        position: relative;
        bottom: 610px;
        border-radius: 12px !important;
        overflow: hidden;
    }


    .whatsNew3cards .swiper-slide {
        background-position: center;
        background: #141414;
        background-size: cover;
        width: 320px;
        height: 480px;
        transition: all 200ms linear;
        position: relative;
        top: 0px;
        left: 37px;
        /* background-color: rebeccapurple; */
        box-shadow: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 60px;
    }



    .whatsNew3cards .swiper-slide-active {
        width: 320px;
        height: 480px;
        position: relative;
        top: 0px;
        box-shadow: none;

    }

    .new_card_1 {
        max-width: 100% !important;
        height: 480px;
        display: flex !important;
        flex-direction: column;
        position: relative;
        left: 0px;
        /* border: 1px solid red;  */
        align-items: center;
        text-align: center;
        box-shadow: none !important;
    }

    .new_img {
        width: 100%;
        height: 177px;
        position: relative;
        left: 0px;
        top: 1rem;

    }

    .whatsNew3cards .swiper-slide-active img {
        position: relative;
        top: 0px;

    }

    .new_img img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: absolute;
        left: 0px;

    }

    .new_data {
        position: relative;
        top: 1rem;
        display: flex;
        flex-direction: column;
    }

    .new_data h2 {
        color: #ff7200;
        font-size: 18px;
        position: relative;
        left: 0px;
        top: 7px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        margin-top: 18px;
    }

    .new_data p {
        position: relative;
        top: -6px;
        color: white;
        font-size: 12px;
        font-weight: 300;
        margin-top: 33px;
        padding-left: 12px;
        padding-right: 12px;
    }

    #learn_more {
        position: relative;
        top: -4px;
        color: #ff7200;
        font-size: 13px;

    }

    .whatsNew3cards .swiper-button-next {
        color: white;
        position: absolute;
        top: 200px !important;
        right: 20px !important;
    }


    .whatsNew3cards .swiper-button-prev {
        color: white;
        position: absolute;
        top: 200px !important;
        left: 15px !important;
    }


    .whatsNew3cards .swiper-pagination {
        display: none;
    }

    .lynkit_feed {
        width: 100%;
        /* height: 1010px; */
        height: 1100px;
        background-color: rgb(22, 22, 22);
        /* position: absolute; */
        /* background-color: rgb(224, 223, 223); */
        /* top: 4805px; */
        position: relative;
    }

    .lynkit_feed h1 {
        color: #ff7200;
        position: relative;
        top: 30px;
        align-items: center;
        text-align: center;
        letter-spacing: 0px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 1px;
        font-size: 48px;

    }

    .feed_inner {
        width: 90%;
        height: 540px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 70px;
        display: flex;
    }

    .feed_right {
        width: 100%;
        height: 720px;
        /* border: 1px solid red; */
    }

    .awardsSection {
        height: 500px;
        position: relative;
        /* top: 2973px; */
    }

    .awardsInner {
        height: 100%;
    }

    .awardsHeading {
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: center;
    }

    .awardsSpans {
        font-size: 48px;
        color: #000;
        position: relative;
        top: 10px;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-size: 0% 4px;
        background-position: bottom;
    }

    .awardsListing700 {
        display: none;
    }

    .awardsListing {
        width: 100%;
        height: auto;
        position: relative;
        top: 60px;
        /* display: flex; */
        align-items: center;
    }

    .awardsRow1 {
        width: 75%;
        height: 160px;
        display: flex;
        justify-content: center;
    }

    .awardBox2 {
        width: 50%;
        height: 100%;
        margin-left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .nodalImage {
        width: 15%;
    }

    .modalText {
        font-size: 12px !important;
        font-weight: 300;
        color: #202020 !important;
    }

    .awardBox {
        width: 20%;
        height: 150px;
        margin-left: 0px;
        /* box-shadow: 0px 0px 9px #101010; */
    }

    .awardImg {
        max-width: 100%;
        max-height: 100%;
        height: 100%;
        object-fit: contain;

    }

    .banner_video_slider {
        display: none !important;
    }

    .prodImage {
        width: 88%;
        height: auto;
    }

    .neeProd {
        width: 90%;
        height: 436px;
        display: flex;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
        /* margin-top: 55px; */
        box-shadow: 0px 0px 10px grey;
        border-radius: 10px;
    }


    .api-div {
        position: absolute;
        height: 1100px;
        top: 70px;
        width: 100%;
        background-color: rgb(22, 22, 22);
        align-items: center;
    }


    .api_card_1 {
        max-width: 100% !important;
        height: 427px;
        display: flex !important;
        flex-direction: column;
        position: absolute;
        left: 0px;
        /* border: 1px solid red; */
        align-items: center;
        text-align: center;
    }

    .api-swiper {
        width: 100%;
        height: 510px;
        /* border: 2px solid cyan; */
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 5%;
        border-radius: 12px !important;
        overflow: hidden;
    }

    .api-swiper .swiper-slide {
        background-position: center;
        background: #030303;
        background-size: cover;
        width: 300px !important;
        height: 460px;
        transition: all 200ms linear;
        position: relative;
        top: 10px;
        left: 100px;
        /* background-color: rebeccapurple; */
        box-shadow: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 30px;
        margin-left: 30px;
        border-radius: 10px;
        border: 1px solid #ff7700;

    }

    .api-card-data {

        position: relative;
        top: 175px;
        width: 100%;
        height: 240px;
        display: flex;
        flex-direction: column;
    }

    .api-card-data h2 {
        color: #ff7200;
        font-size: 18px;
        position: relative;
        left: 0px;
        text-align: center;
        align-items: center !important;
        font-weight: 700;
        margin: 5px 8px 0px;
        /* font-family: 'Montserrat', sans-serif; */
        font-family: 'Montserrat', sans-serif;
    }

    .api-card-data p {
        position: relative;
        top: 9px;
        color: white;
        font-size: 13px;
        font-weight: 300;

        padding-left: 16px;
        padding-right: 16px;
    }

    .api-card-img {
        width: 100%;
        height: 177px;
        position: absolute;
        left: 0px;
        top: 0px;
        border-radius: 10px;


    }

    .api-card-img img {
        width: 85%;
        height: 100%;
        object-fit: contain;
        position: relative;
        left: 0px;

    }

    .api-swiper .swiper-slide-active {
        width: 300px;
        height: 460px;
        position: relative;
        top: 10px;


    }

    .api-swiper .swiper-slide-active img {
        position: relative;
        top: 0px;

    }


    .api-swiper .swiper-button-next {
        color: #ff7722 !important;
        position: absolute !important;
        right: -50px !important;
        top: 200px !important;
    }

    .api-swiper .swiper-button-prev {
        color: #ff7722 !important;
        position: absolute !important;
        top: 200px !important;
        left: 65px !important;
    }


    .api-swiper .swiper-pagination {
        top: 490px;
        left: 5.5%;
    }

    .swiper-pagination-bullet-active {
        opacity: 1;
        background: #ff7700 !important;
        filter: brightness(2);
    }

    .swiper-pagination-bullet {
        background: #8f8f8f !important;
    }
    .banner_headings_messgae {
        width: 100%;
        height: 5%;
        color: white;
        bottom: 432px;
        position: relative;
        z-index: 10;
        text-align: center;
    }
    .home_page_footer {
        /* position: absolute;
        top: 5750px; */
        position: relative;
        width: 100%;
    }
}

/* media query 8 */

@media only screen and (max-width:1100px) {


    body {
        width: 100%;
        height: 100%;
        display: inline;
        z-index: -1;

    }

    .EnterpriseTextElementNew {
        display: none;
    }

    .PEnterDegign {
        display: none;
    }

    .mainMobile {
        display: none;
    }

    .whatsNew500 {
        display: none;
    }

    .api-div500 {
        display: none;
    }

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #ff7200;
        border-radius: 7px;

    }

    #lll {
        position: relative;
        left: 30px;
        font-size: 6px;
        font-weight: lighter;
    }



    .video_part {
        width: 100%;
        height: 1020px;
        position: relative;
        top: 0px;
        z-index: 1;

    }

    .video_part .headings {
        width: 100%;
        height: 46%;
        position: relative;
        top: 20px;
    }

    video {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50px;
        object-fit: initial;
        z-index: 1;
    }


    .three_btns_for_videos {
        width: 5%;
        height: 30px;
        display: flex;
        position: relative;

        top: -30px;
        color: #ff7200;
        justify-content: center;
        align-items: center;
        justify-content: space-between;
        z-index: 10;



    }

    .three_btns_for_videos button {

        width: 10px;
        height: 10px;
        border-radius: 12px;
        border: none;

    }


    .banner_headings {
        width: 41%;
        height: 20%;
        color: #ff7200;

        bottom: 310px;
        position: relative;
        left: 55px;
        z-index: 10;

        /*  border: 0.2px solid red;  */
    }

    .banner_headings h4 {
        font-size: 59px;
        font-weight: 900;
        text-shadow: 1px 5px 1px black;
        font-family: 'Fjalla One', sans-serif;
        line-height: 59px;
    }


    .banner_headings h6 {
        color: #fff;
        font-size: 14px;
        text-shadow: 1px 2px 1px black;
        position: relative;
        top: -2px;
        left: 2px;
        font-weight: 500;
        font-family: 'Montserrat', sans-serif;
    }


    .banner_btn {
        background-color: #ff7200;
        border: none;
    }




    .Product_titles .active {


        background-color: #ff7200;
        border-radius: 38px;
        width: 143px;
        height: 52px;
        justify-content: center;
        text-align: center;
        border: none;
        box-shadow: 3px 3.5px 1px grey;
        position: relative;
        bottom: 5px;
    }

    #active_text {
        font-size: 12px;
    }





    .main {
        width: 100%;
        /* height: 858px; */
        height:100%;
        position: relative;
        margin-top: -413px;
        background-color: #f7f7f7;
        z-index: 2;

    }


    .Product_heading {
        position: relative;
        text-align: center;
        justify-content: center;
        align-items: center;


    }

    .Product_heading h2 {

        font-size: 48px;
        font-weight: 900;
        text-shadow: none;
        position: relative;
        top: -5px;
        font-family: 'Montserrat', sans-serif;
        letter-spacing: 1px;
        background-image: linear-gradient(#ff7200, #ff7200);
        letter-spacing: 0px !important;
        background-repeat: no-repeat;
        line-height: 100px;
        background-size: 0% 4px;
        background-position: bottom;

    }


    .Product_titles {
        /* border: 2px solid red; */
        width: 95%;
        height: 60px;
        position: relative;
        top: 29px;
        left: 1%;
        display: flex;
        justify-content: space-between;
        text-decoration: none;

    }

    .Product_titles img {
        display: inline-table;
    }




    #ll {
        color: #fff;
    }




    .Product_titles a {
        color: #fff;
        font-size: 22px;
        padding-top: 1px;
        cursor: pointer;
        text-decoration: none;
    }

    .Product_titles img {
        color: #fff;
    }

    /* .Product_titles .active {
    border-bottom: 1px solid #ff7200;
    background-color: transparent;
    
} */


    #icon_img1 {
        vertical-align: middle;
        width: 42px;


    }

    #icon_img2 {
        vertical-align: middle;
        width: 42px;
        height: 39px;
        position: relative;
        left: -8px;

    }

    #icon_img3 {
        vertical-align: middle;
        width: 50px;
        height: 48px;
        position: relative;
        left: -1px;

    }

    #icon_img4 {
        vertical-align: middle;
        width: 41px;
        position: relative;
        left: 14px;
        height: 44px;


    }

    #icon_img5 {
        vertical-align: top;
        position: relative;
        right: -9px;
        height: 39px;
        width: 35px;

    }

    #icon_img6 {
        vertical-align: top;
        position: relative;
        bottom: 10px;
        left: 15px;
        height: 42px;
        width: 42px;

    }

    #icon_5 {
        cursor: pointer;

    }

    #aligning_text1 {
        color: black;
        position: relative;
        top: -5px;
        left: 0px;
        font-size: 12px;
        font-weight: 500;
    }

    #aligning_text2 {
        color: black;
        position: relative;
        top: -6px;
        left: -3px;
        font-size: 12px;
        font-weight: 500;
    }


    #aligning_text3 {
        color: black;
        position: relative;
        top: -7px;
        left: 1px;
        font-size: 12px;
        font-weight: 500;
    }

    #aligning_text4 {
        color: black;
        position: relative;
        top: -10px;
        left: 14px;
        font-size: 12px;
        font-weight: 500;
    }

    #aligning_text {
        color: black;
        position: relative;
        top: -1px;
        font-size: 12px;
        font-weight: 500;
    }

    #aligning_text5 {
        color: black;
        position: relative;
        top: -9px;
        left: 17px;
        font-size: 12px;
        font-weight: 500;
    }






    .sub {
        /*  border: 1px solid red;  */
        position: relative;
        top: 80px;
        background-color: #fff;
        width: 92%;
        height: 530px;

    }

    #twoD {
        color: black;
        position: relative;
        top: -1px;
        left: 10px;
    }

    .sub .left_div {
        width: 45%;
        height: 85%;
    }

    #product_images {
        width: 99%;
        height: 420px;
        position: relative;
        left: 0%;
        bottom: 1%;


    }



    #video_product_2 {
        width: 100%;
        height: 87%;
        margin-top: 0px;
        margin-left: -2px;
    }

    .sub .right_div {
        width: 46%;
        height: 70%;


    }

    .sub .right_div #head1 {

        font-size: 34px;
        padding-left: 2%;
        font-weight: 700;
        line-height: 34px;
        position: relative;
        top: 15px;

    }

    .sub .right_div #head2 {

        font-size: 12px;
        font-weight: 300;
        margin-top: 35px;
        text-align: justify;
        text-shadow: none;
        letter-spacing: 0px;
        position: relative;
        left: 9px;
        line-height: 19px;

    }

    .right_div button {
        width: 80px;
        height: 32px;
        display: flex;
        position: relative;
        bottom: -35px;
        left: -21px;
        border-radius: 15px;
        font-weight: 300;
        font-size: 9px;
        padding-left: 14px;
        padding-top: 11px;

    }

    #active_text1 {
        color: #fff;
        font-size: 12px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        display: block;
        position: relative;

    }

    #active_text_2 {
        font-size: 12px;
        position: relative;
        top: 2px;
        left: 6px;
    }

    #warehouse1 {
        color: black;
        display: block;
        position: relative;
    }

    #active_img {
        width: 37px;
        height: 36px;
    }

    #active_img5 {
        position: relative;
        width: 31px;
        height: 33px;
    }

    .achievements_div {
        width: 100%;
        height: 788px;
        /* position: absolute;
        top: 1220px; */
        position: relative;
        background-color: #070707;
    }

    .achievements_div h1 {
        font-size: 48px;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 1px;
        background-image: linear-gradient(#fff, #fff);

        background-repeat: no-repeat;
        line-height: 120px;
        background-size: 0% 4px;
        background-position: bottom;
        letter-spacing: 0px !important;
    }

    .achievements_image {
        width: 100%;
        height: 546px;
    }

    #blur_image {
        width: 100%;
        height: 470px;
    }

    .achivements_div_1 {
        width: 75%;
        height: 172px;
        /*  border: 1px solid red; */
        position: absolute;
        bottom: 334px;
        left: 12.5%;
        display: flex;
        justify-content: space-between;
    }

    .achivements_div_1 div {
        width: 21%;
        height: 170px;
        /*  border: 3px solid yellow; */
        background-color: #302f2f;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        box-shadow: inset 0px 0px 39px rgb(17, 16, 16);
        box-shadow: 4px 4px 10px black;
        border-radius: 7px;


    }

    .achivements_div_1 div h1 {
        color: #ff7200;
        font-weight: 900;

    }


    .achivements_div_1 div p {
        font-size: 16px;
        color: white;
        font-weight: 500;
    }




    .achivements_div_2 {
        width: 75%;
        height: 172px;

        position: absolute;
        bottom: 120px;
        left: 12.5%;
        display: flex;
        justify-content: space-between;
    }

    .achivements_div_2 div {
        width: 21%;
        height: 170px;
        /*  border: 3px solid yellow; */
        background-color: #302f2f;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        box-shadow: inset 0px 0px 39px rgb(17, 16, 16);
        box-shadow: 4px 4px 10px black;
        border-radius: 7px;


    }


    .achivements_div_2 div h1 {
        color: #ff7200;
        font-weight: 900;
    }

    #hundred {
        position: relative;
        top: 14px;

    }

    #client_100 {
        position: relative;
        top: 14px;

    }

    .iot_h {
        position: relative;
        top: 14px;
    }

    .iot_p {
        position: relative;
        top: 13px;
    }

    .achivements_div_2 div p {
        font-size: 16px;
        color: white;
        font-weight: 400;
    }

    .achivementElem {
        width: 89%;
        height: 69%;
        margin-left: auto;
        margin-right: auto;
    }

    .achiveR1 {
        width: 100%;
        height: 50%;
        display: flex;
    }

    .achieverc1 {
        width: 20%;
        height: 100%;
        margin-right: 8px;
        position: relative;
    }

    .achieveImg {
        width: 100%;
        height: 100%;
        position: relative;
        justify-content: flex-start;
        text-align: left;
        align-items: center;
        z-index: 1;
    }

    .achieve100 {
        position: absolute;
        top: 30px;
        left: 15px;
        font-size: 1.7rem;
        font-weight: 600;
        color: #ff7200;
        z-index: 10;
    }

    .achieveClient {
        position: absolute;
        top: 60px;
        left: 15px;
        font-weight: 500;
        z-index: 10;
    }

    .achieveInstall {
        position: absolute;
        top: 80px;
        left: 15px;
        font-weight: 500;
        z-index: 10;
    }

    .achieverc2 {
        width: 30%;
        height: 100%;
        margin-right: 8px;
    }

    .achiveI1 {
        width: 100%;
        height: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #ff7200;
        box-shadow: 0px 0px 24px black inset;
        margin-bottom: 8px;
    }

    .threefive {
        font-size: 1.7rem;
        color: #fff;
        font-weight: 700;
        position: relative;
        top: 10px;
    }

    .iot {
        font-size: 1rem;
        font-weight: 400;
        color: #fff;
    }

    .achiveI2 {
        width: 100%;
        height: 57%;
        position: relative;
    }

    .vehicle_text {
        color: #ff7200;
        font-size: 3rem;
        font-weight: 600;
        position: absolute;
        z-index: 10;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .vehicle_sub_text {
        color: #fff;
        position: absolute;
        z-index: 10;
        font-size: 0.9rem;
        top: 45%;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .achieverc3 {
        width: 20%;
        height: 100%;
        margin-right: 8px;
    }

    .achieve50 {
        width: 100%;
        height: 49%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #464444;
        box-shadow: inset 1px 1px 22px #000;
        margin-bottom: 6px;
    }

    .achieve50b {
        width: 100%;
        height: 49%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #d3d3d3;
        box-shadow: inset 1px 1px 19px #000;
        margin-bottom: 6px;
    }

    .m100m {
        color: #ff7200;
        font-size: 1.7rem;
        font-weight: 600;
    }

    .transactions {
        color: #fff;
        position: relative;
        bottom: 7px;
        font-size: 0.65rem;
    }

    .img_overlay {
        position: absolute !important;
    }

    .overlay_main_text {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -70%);

        z-index: 10;
        color: #ff7200;
        font-size: 1.25rem;
        font-weight: 600;
    }

    .overlay_sub_text {
        color: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, 0);
        font-size: 0.6rem;
        z-index: 10;
    }

    .m100black {
        color: #000;
        font-size: 1.7rem;
        font-weight: 600;
    }

    .transactionsm {
        color: #000;
        position: relative;
        bottom: 7px;
    }

    .achieveI31 {
        width: 100%;
        height: 48%;

        margin-bottom: 8px;
    }

    .achieveI32 {
        width: 100%;
        height: 49%;
        margin-bottom: 8px;
    }

    .achieverc4 {
        width: 30%;
        height: 100%;
    }

    .achiveR2 {
        width: 100%;
        height: 50%;
        display: flex;
        position: relative;
    }

    .ach5 {
        width: 29%;
        height: 100%;
        margin-left: 8px;
        margin-top: 8px;
        position: relative;
    }

    .fifteen {
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 10;
    }

    .fifteenTrips {
        position: absolute;
        top: 60px;
        left: 15px;
        z-index: 10;
    }

    .ach6 {
        width: 21%;
        height: 100%;
        margin-top: 8px;
        position: relative;
    }

    .ach7 {
        width: 20%;
        height: 100%;
        margin-top: 8px;
        margin-left: 8px;
        position: relative;
    }

    .m1000 {
        color: #fff;
        position: absolute;
        top: 20px;
        left: 10px;
        font-size: 1.7rem;
        z-index: 10;
    }

    .lynkassist {
        color: #fff;
        position: absolute;
        top: 60px;
        left: 10px;
        font-size: 0.9rem;
        z-index: 10;
    }

    .lynkassistLoc {
        color: #fff;
        position: absolute;
        top: 80px;
        left: 10px;
        font-size: 0.9rem;
        z-index: 10;
    }

    .ach8 {
        width: 30%;
        height: 100%;
        margin-left: 8px;
        margin-top: 8px;
        display: flex;
        position: relative;
    }

    .m3 {
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 10;
        color: #ff7200;
        font-size: 2.5rem;
        font-weight: 600;
    }

    .rfid_cir {
        color: #fff;
        position: absolute;
        top: 70px;
        left: 20px;
        font-size: 1.25rem;
        z-index: 10;
    }

    .lynkit_testomonial {
        width: 100%;
        height: 600px !important;
        /* position: absolute;
        top: 1900px; */
        position: relative;
        z-index: 4;
    }

    .Enterprise_div {
        width: 100%;
        height: 480px !important;
        /* position: absolute;
        top: 2484px; */
        position: relative;
        display: flex;
        justify-content: center;
        overflow: hidden;
    }

    .Enterprise_div h1 {
        font-size: 48px;
        color: #ff7200;
        text-shadow: none;
        position: absolute;
        top: -17px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-image: linear-gradient(#fff, #fff);
        background-repeat: no-repeat;
        line-height: 110px;
        background-size: 0% 4px;
        background-position: bottom;
        letter-spacing: 0px !important;
    }

    .enterSpan {
        font-size: 9px;
        font-weight: 300;
        color: #F5F5F5 !important;
    }

    .enterprise_main_div {
        width: 80%;
        height: 500px;
        /*  background-color: aqua; */
        position: relative;
        top: 200px !important;
        display: flex;
    }

    .enterOuter {
        top: 70px;
    }

    .enterprise_left {
        width: 50%;
        height: 500px;
        /*  background-color: bisque; */
        display: flex;
        justify-content: center;
        align-items: center;
    }



    .homeSlider_swiper > .swiper-container {
        width: 100%;

        position: absolute;
        right: 0px;
        top: 30px;
        overflow: visible;

    }

    .homeSlider_swiper > .swiper-slide {
        background-position: center;
        background-size: cover;
        width: 270px;
        height: 280px;
        transition: all 200ms linear;
        position: relative;
        background-color: #141414;
        box-shadow: 2px 2px 10px black;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;


    }

    .homeSlider_swiper > .swiper-slide>img {
        width: 36%;
        height: 130px;
        position: relative;
        bottom: 29px;
        left: 30%;

    }

    .homeSlider_swiper > .swiper-slide h2 {
        font-size: 20px;
        color: #ff7200;
        position: relative;
        left: 7%;
        word-wrap: break-word;
        white-space: initial;



    }

    .homeSlider_swiper > .swiper-slide-active {
        width: 280px;
        height: 250px;



    }

    .homeSlider_swiper > .swiper-slide.swiper-slide-active .initial_data {
        display: none;
    }

    .supply_para {
        font-size: 26px !important;
        position: relative;
        top: 22px;
        left: 60px !important;
    }

    .block_para {
        font-size: 26px !important;
        position: relative;
        top: 18px;
        left: 45px !important;

    }

    .iot_para {
        font-size: 26px !important;
        position: relative;
        top: 34px;
        left: 80px !important;
        width: 55% !important;
    }

    .supply_class {
        width: 84%;
        position: absolute;
        top: 150px;
        left: 8%;
        /* border: 2px solid red; */

    }

    .supply_class p {
        color: black;
        font-size: 12px;
        text-align: justify;
        line-height: 17px;

    }

    .block_class {
        width: 90%;
        position: absolute;
        top: 131px;
        left: 5%;
        /* border: 2px solid red; */

    }

    .block_class p {
        color: black;
        font-size: 12px;
        text-align: justify;
        line-height: 16px;

    }

    .iot_class {
        width: 90%;
        position: absolute;
        top: 159px;
        left: 5%;
        /* border: 2px solid red; */

    }

    .iot_class p {
        color: black;
        font-size: 12px;
        text-align: justify;
        line-height: 16px;

    }

    .container .swiper-button-next {
        color: #fff !important;
        position: absolute;
        top: 150px !important;
    }



    .container .swiper-button-prev {
        color: #fff;
        position: absolute;
        top: 150px !important;
    }

    .container .swiper-pagination {
        position: absolute;
        top: 366px !important;
        z-index: 10;
    }

    .container swiper-pagination-current {
        background: black;
    }

    .container .swiper-pagination-bullet-active {
        width: 8px;
        height: 8px;
        background-color: #fff !important;



    }

    .container .swiper-pagination-bullet {
        opacity: 1;

        background-color: black;
    }

    /*  */
    .customer_div {
        width: 100%;
        /* height: 700px; */
        height: 565px;
        /* position: absolute;
        top: 2934px; */
        position: relative;
        display: flex;
        justify-content: center;
    }

    .customer_div h1 {

        font-size: 48px;
        color: black;
        text-shadow: none;
        position: absolute;
        top: 20px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px !important;
        background-image: linear-gradient(#ff7200, #ff7200);
        background-repeat: no-repeat;
        line-height: 110px;
        background-size: 0% 4px;
        background-position: bottom;


    }

    .customers_div_1 {
        width: 97%;
        height: 130px;

        position: absolute;
        top: 145px;
        display: flex;
        justify-content: space-around;

    }

    .customers_div_1 div {
        width: 16%;
        height: 130px;

        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }

    .customers_div_1 img {
        /* filter: grayscale(100%); */
    }

    .customers_div_1 img:hover {
        cursor: pointer;
        filter: none;
        /* filter: grayscale(0%); */
    }


    .customers_div_2 {
        width: 97%;
        height: 130px;

        position: absolute;
        top: 315px;
        display: flex;
        justify-content: space-around;

    }

    .customers_div_2 div {
        width: 16%;
        height: 130px !important;

        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }


    .customers_div_2 img {
        /* filter: grayscale(100%); */
    }

    .customers_div_2 img:hover {
        cursor: pointer;
        filter: none;
        filter: grayscale(0%);
        ;
    }

    .customers_div_3 {
        width: 25%;
        height: 168px;
        /* border: 2px solid green; */
        position: absolute;
        top: 529px;
        display: flex;
        justify-content: space-between;
    }

    .customers_div_3 div {
        width: 49% !important;
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }

    .customer_images {
        width: 86px;
    }

    #cma {
        width: 90px;
        height: 85px;
    }

    #bharat {
        width: 80px;
        height: 95px;
    }

    #aditya {
        width: 85px;
        height: 84px;
    }

    #lg {
        width: 82px;
        height: 55px;
    }

    #dadri {
        width: 80px;
        height: 80px;
    }

    #more_3500 {
        width: 100%;
        height: 66px;
        position: relative;
        top: 445px;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    #more_3500 h1 {
        position: relative;
        font-size: 35px;
        color: #424141;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
        background-image: linear-gradient(white, white);
        background-repeat: none;
        background-size: 0% 0px;
        font-family: 'Fjalla One', sans-serif;

    }

    #more_3500 h1:hover {
        color: black;
    }




    .whatsNew {
        width: 100%;
        height: 640px;
        /* position: absolute;
        top: 3467px; */
        position: relative;
        justify-content: center;
    }

    .whatsNew h1 {
        font-size: 48px;
        position: relative;
        bottom: 625px !important;
    }

    .whatsNew3cards {
        width: 100%;
        height: 510px;
        /* border: 2px solid cyan;    */
        justify-content: center;

        margin-left: auto;
        margin-right: auto;
        position: relative;
        bottom: 650px;
        border-radius: 12px !important;
        overflow: hidden;
    }


    .whatsNew3cards .swiper-slide {
        background-position: center;
        background: #141414;
        background-size: cover;
        width: 320px;
        height: 500px;
        transition: all 200ms linear;
        position: relative;
        top: 0px;
        left: 39px;
        letter-spacing: 0px !important;
        box-shadow: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 30px;

    }



    .whatsNew3cards .swiper-slide-active {
        width: 320px;
        height: 500px;
        position: relative;
        top: 0px;
        box-shadow: none;

    }

    .new_card_1 {
        max-width: 100% !important;
        height: 490px;
        display: flex !important;
        flex-direction: column;
        position: relative;
        left: 0px;
        /* border: 1px solid red;  */
        align-items: center;
        text-align: center;
        box-shadow: none !important;
    }

    .new_img {
        width: 100%;
        height: 177px;
        position: relative;
        left: 0px;
        top: 1rem;
    }

    .whatsNew3cards .swiper-slide-active img {
        position: relative;
        top: 0px;

    }

    .new_img img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: absolute;
        left: 0px;

    }

    .new_data {

        position: relative;
        top: 1rem;
        display: flex;
        flex-direction: column;

    }

    .new_data h2 {
        color: #ff7200;
        font-size: 16px;
        position: relative;
        left: 0px;
        top: 18px;
        font-family: 'Montserrat', sans-serif;

        font-weight: 700;
        margin-top: 18px;
    }

    .new_data p {
        position: relative;
        top: 0px;
        color: white;
        font-size: 11px;
        font-weight: 300;
        margin-top: 33px;
        padding-left: 12px;
        padding-right: 12px;
    }

    #learn_more {
        position: relative;
        top: 24px;
        color: #ff7200;
        font-size: 13px;

    }

    .whatsNew3cards .swiper-button-next {
        color: white;
        position: absolute;
        top: 200px !important;
        right: 20px !important;
    }


    .whatsNew3cards .swiper-button-prev {
        color: white;
        position: absolute;
        top: 200px !important;
        left: 13px !important;
    }


    .whatsNew3cards .swiper-pagination {
        display: none;
    }

    .lynkit_feed {
        width: 100%;
        /* height: 1010px; */
        height: 1080px;
        background-color: rgb(22, 22, 22);
        /* position: absolute; */
        /* background-color: rgb(224, 223, 223); */
        /* top: 4580px !important; */
        position: relative;
    }

    .lynkit_feed h1 {
        color: #ff7200;
        position: relative;
        top: 30px;
        align-items: center;
        text-align: center;
        letter-spacing: 0px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 1px;
        font-size: 48px;

    }

    .feed_inner {
        width: 90%;
        height: 540px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 70px;
        display: flex;
    }


    .feed_right {
        width: 100%;
        height: 700px;
        /* border: 1px solid red; */
    }

    .awardsSection {
        /* height: 610px; */
        height: 525px;
        position: relative;
        /* top: 2630px; */
    }

    .awardsInner {
        height: 100%;
    }

    .awardsHeading {
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: center;
    }

    .awardsSpans {
        font-size: 48px;
        color: #000;
        position: relative;
        top: 10px;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-size: 0% 4px;
        background-position: bottom;
    }

    .awardsListing700 {
        display: none;
    }

    .awardsListing {
        width: 100%;
        height: auto;
        position: relative;
        top: 60px;
        align-items: center;
    }

    .awardsRow1 {
        width: 75%;
        height: 150px;
        display: flex;
        justify-content: center;
    }

    .nodalImage {
        width: 15%;
        height: 80px;
        margin-bottom: 14px;
    }

    .modalText {
        font-weight: 300;
        color: #202020 !important;
    }

    .awardBox2 {
        width: 60% !important;
        height: 100%;
        margin-left: 0px;
        display: flex;
        align-items: center;
    }


    .awardBox {
        width: 20%;
        height: 150px;
        margin-left: 0px;
        /* box-shadow: 0px 0px 9px #101010; */
    }

    .awardImg {
        max-width: 100%;
        max-height: 100%;
        height: 100%;
        object-fit: contain;

    }

    .banner_video_slider {
        display: none !important;
    }

    .prodSpan {
        font-size: 36px;
        /* padding-left: 1.5%; */
        font-weight: 600;
        position: relative;
        top: 15px;
        line-height: 49px;
        color: #ff7200;
        font-family: 'Fjalla One', sans-serif;
    }

    .prodData {
        font-size: 12px;
        font-weight: 300;
        line-height: 20px;
        margin-top: 35px;
        text-align: justify;
        text-shadow: none;
        letter-spacing: 0;
        position: relative;
        left: 9px;
        top: 4px;
    }

    .prodImage {
        width: 86%;
        height: auto;
    }

    .api-div {
        position: absolute;
        height: 1100px;
        top: 70px;
        width: 100%;
        background-color: rgb(22, 22, 22);
        align-items: center;
    }


    .api_card_1 {
        max-width: 100% !important;
        height: 427px;
        display: flex !important;
        flex-direction: column;
        position: absolute;
        left: 0px;
        /* border: 1px solid red; */
        align-items: center;
        text-align: center;
    }

    .api-swiper {
        width: 100%;
        height: 510px;
        /* border: 2px solid cyan; */
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 5%;
        border-radius: 12px !important;
        overflow: hidden;
    }

    .api-swiper .swiper-slide {
        background-position: center;
        background: #030303;
        background-size: cover;
        width: 300px !important;
        height: 460px;
        transition: all 200ms linear;
        position: relative;
        top: 10px;
        left: 100px;
        /* background-color: rebeccapurple; */
        box-shadow: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 30px;
        margin-left: 30px;
        border-radius: 10px;
        border: 1px solid #ff7700;

    }

    .api-card-data {

        position: relative;
        top: 175px;
        width: 100%;
        height: 240px;
        display: flex;
        flex-direction: column;
    }

    .api-card-data h2 {
        color: #ff7200;
        font-size: 18px;
        position: relative;
        left: 0px;
        text-align: center;
        align-items: center !important;
        font-weight: 700;
        margin: 5px 8px 0px;
        /* font-family: 'Montserrat', sans-serif; */
        font-family: 'Montserrat', sans-serif;
    }

    .api-card-data p {
        position: relative;
        top: 9px;
        color: white;
        font-size: 13px;
        font-weight: 300;

        padding-left: 16px;
        padding-right: 16px;
    }

    .api-card-img {
        width: 100%;
        height: 177px;
        position: absolute;
        left: 0px;
        top: 0px;
        border-radius: 10px;


    }

    .api-card-img img {
        width: 85%;
        height: 100%;
        object-fit: contain;
        position: relative;
        left: 0px;

    }

    .api-swiper .swiper-slide-active {
        width: 300px;
        height: 460px;
        position: relative;
        top: 10px;


    }

    .api-swiper .swiper-slide-active img {
        position: relative;
        top: 0px;

    }


    .api-swiper .swiper-button-next {
        color: #ff7722 !important;
        position: absolute !important;
        right: 10px !important;
        top: 200px !important;
    }

    .api-swiper .swiper-button-prev {
        color: #ff7722 !important;
        position: absolute !important;
        top: 200px !important;
        left: 10px !important;
    }


    .api-swiper .swiper-pagination {
        top: 490px;
        left: 5.5%;
    }

    .swiper-pagination-bullet-active {
        opacity: 1;
        background: #ff7700 !important;
        filter: brightness(2);
    }

    .swiper-pagination-bullet {
        background: #8f8f8f !important;
    }
    .banner_headings_messgae {
        width: 100%;
        height: 5%;
        color: white;
        bottom: 412px;
        position: relative;
        z-index: 10;
        text-align: center;
    }
    .home_page_footer {
        /* position: absolute;
        top: 5514px; */
        position: relative;
        width: 100%;
    }

}

/* media query 9 */

@media only screen and (max-width:1000px) {
    #sch {
        width: 40%;
    }

    .main_company .api_section {
        flex-direction: column;
    }

    .mainMobile {
        display: none;
    }

    .EnterpriseTextElementNew {
        display: none;
    }

    .PEnterDegign {
        display: none;
    }

    body {
        width: 100%;
        height: 100%;
        display: inline;
        z-index: -1;

    }

    .counts {
        font-size: 1.5rem;
    }

    .whatsNew500 {
        display: none;
    }

    .api-div500 {
        display: none;
    }

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #ff7200;
        border-radius: 7px;

    }


    #lll {
        position: relative;
        left: 30px;
        font-size: 6px;
        font-weight: lighter;
    }



    .video_part {
        width: 100%;
        height: 890px;
        position: relative;
        top: 0px;
        z-index: 2;

    }

    .video_part .headings {
        width: 100%;
        height: 46%;
        position: relative;
        top: 20px;
    }

    video {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50px;
        object-fit: initial;
        z-index: 1;
    }


    .three_btns_for_videos {
        width: 5%;
        height: 30px;
        display: flex;
        position: relative;

        top: -15px;
        color: #ff7200;
        justify-content: center;
        align-items: center;
        justify-content: space-between;
        z-index: 10;



    }

    .three_btns_for_videos button {

        width: 10px;
        height: 10px;
        border-radius: 12px;
        border: none;

    }


    .banner_headings {
        width: 42%;
        height: 20%;
        color: #ff7200;

        bottom: 250px;
        position: relative;
        left: 459x;
        z-index: 10;

        /*  border: 0.2px solid red;  */
    }

    .banner_headings h4 {
        font-size: 50px;
        font-weight: 900;
        text-shadow: 1px 5px 1px black;
        font-family: 'Fjalla One', sans-serif;
        line-height: 53px;
    }


    .banner_headings h6 {
        color: #fff;
        font-size: 13px;
        text-shadow: 1px 2px 1px black;
        position: relative;
        top: 0px;
        left: 2px;
        font-weight: 500;
        font-family: 'Montserrat', sans-serif;
    }

    .banner_btn {
        background-color: #ff7200;
        border: none;
    }





    .main {
        width: 100%;
        /* height: 868px; */
        height: 700px;
        position: relative;
        top: 0px;
        background-color: #f7f7f7;
        z-index: 3;


    }

    .Product_heading {
        position: relative;
        text-align: center;
        justify-content: center;
        align-items: center;


    }

    .Product_heading h2 {

        font-size: 44px;
        font-weight: 900;
        text-shadow: none;
        position: relative;
        top: -5px;
        font-family: 'Montserrat', sans-serif;
        letter-spacing: 0px !important;
        background-image: linear-gradient(#ff7200, #ff7200);

        background-repeat: no-repeat;
        line-height: 100px;
        background-size: 0% 4px;
        background-position: bottom;

    }



    .Product_titles {
        /* border: 1px solid red; */
        width: 98%;
        height: 57px;
        position: relative;
        top: 9px;
        left: 0%;
        display: flex;
        justify-content: space-between;
        text-decoration: none;

    }

    .Product_titles img {
        display: inline-table;
    }




    #ll {
        color: #fff;
    }

    #active_img {
        width: 30px;
        height: 30px;
    }


    .Product_titles a {
        color: #fff;
        font-size: 22px;
        padding-top: 1px;
        cursor: pointer;
        text-decoration: none;
    }

    .Product_titles img {
        color: #fff;
    }



    #icon_img1 {
        vertical-align: middle;
        width: 36px;


    }

    #icon_img2 {
        vertical-align: middle;
        width: 32px;
        height: 31px;
        position: relative;
        left: 1px;


    }

    #warehouse1 {
        color: black;
        display: block;
        position: relative;
    }

    #icon_img3 {
        vertical-align: middle;
        width: 42px;
        height: 43px;
        position: relative;
        left: 3px;


    }

    #icon_img4 {
        vertical-align: middle;
        width: 32px;
        position: relative;
        left: 11px;
        height: 32px;


    }

    #icon_img5 {
        vertical-align: top;
        position: relative;
        top: -0px;
        left: 4px;
        height: 32px;
        width: 28px;

    }

    #icon_img6 {
        vertical-align: top;
        position: relative;
        top: -9px;
        left: 9px;
        height: 34px;
        width: 33px;

    }

    #icon_5 {
        cursor: pointer;

    }

    #aligning_text1 {
        color: black;

        font-size: 10px;
        font-weight: 500;
    }

    #aligning_text2 {
        color: black;
        position: relative;
        left: 4px;
        font-size: 10px;
        font-weight: 500;
    }


    #aligning_text3 {
        color: black;
        position: relative;
        left: 6px;
        font-size: 10px;
        font-weight: 500;
    }

    #aligning_text4 {
        color: black;
        font-size: 10px;
        font-weight: 500;
        position: relative;
        top: -10px;
        left: 13px;
    }

    #aligning_text {
        color: black;
        position: relative;
        left: 9px;
        font-size: 10px;
        font-weight: 500;
    }

    #aligning_text5 {
        color: black;
        position: relative;
        top: -12px;
        left: 12px;
        font-size: 10px;
        font-weight: 500;
    }





    .sub {
        /*  border: 1px solid red;  */
        position: relative;
        top: 56px;
        background-color: #fff;
        width: 90%;
        height: 492px;

    }

    #twoD {
        color: black;
        position: relative;
        top: -1px;
        left: 10px;
    }

    .sub .left_div {
        width: 45%;
        height: 85%;

    }

    #product_images {
        width: 105%;
        height: 360px;
        position: relative;
        left: -11%;
        top: 4%;


    }



    #video_product_2 {
        width: 100%;
        height: 85%;
        margin-top: 0px;
        margin-left: -2px;
    }

    .sub .right_div {
        width: 44%;
        height: 70%;
        position: absolute;
        left: 2%;
        top: 2%;
    }

    .sub .right_div #head1 {

        font-size: 26px;
        padding-left: 1%;
        font-weight: 600;
        position: relative;
        top: 15px;
        line-height: 27px;

    }

    .sub .right_div #head2 {

        font-size: 11px;
        font-weight: 300;
        margin-top: 35px;
        text-align: justify;
        text-shadow: none;
        letter-spacing: 0px;
        position: relative;
        left: 9px;
        line-height: 16px;

    }

    .right_div button {
        width: 70px;
        height: 27px;
        display: flex;
        position: relative;
        bottom: -37px;
        left: -21px;
        border-radius: 15px;
        font-weight: 300;
        font-size: 9px;
        padding-left: 11px;
        padding-top: 8px;

    }

    #active_img5 {
        vertical-align: middle;
        width: 27px;
        height: 27px;
        filter: brightness(0) invert(1);
        position: relative;

    }

    #active_text {
        color: #fff;
        font-size: 10px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        position: relative;
        bottom: 2px;


    }

    #active_text_2 {
        color: #fff;
        font-size: 10px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        position: relative;
        left: 6px;
        top: 2px;


    }

    #active_text1 {
        color: #fff;
        font-size: 10px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;

        display: block;
        position: relative;
    }

    .Product_titles .active {


        background-color: #ff7200;
        border-radius: 38px;
        width: 144px;
        height: 50px;
        justify-content: center;
        text-align: center;
        border: none;
        box-shadow: 3px 3.5px 1px grey;
        position: relative;
        bottom: 5px;
        left: 0px;

    }







    .achievements_div {
        width: 100%;
        height: 728px;
        /* position: absolute; */
        /* top: 1127px; */
        position: relative;
        background-color: #070707;
        z-index: 3;
    }

    .achievements_div h1 {
        font-size: 44px;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 1px;
        background-image: linear-gradient(#fff, #fff);
        letter-spacing: 0px !important;
        background-repeat: no-repeat;
        line-height: 106px;
        background-size: 0% 4px;
        background-position: bottom;
    }




    .achievements_image {
        width: 100%;
        height: 546px;
    }

    #blur_image {
        width: 100%;
        height: 430px;
    }

    .achivements_div_1 {
        width: 75%;
        height: 172px;
        /*  border: 1px solid red; */
        position: absolute;
        bottom: 270px;
        left: 12.5%;
        display: flex;
        justify-content: space-between;
    }

    .achivements_div_1 div {
        width: 21%;
        height: 145px;
        /*  border: 3px solid yellow; */
        background-color: #302f2f;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        box-shadow: inset 0px 0px 39px rgb(17, 16, 16);
        box-shadow: 4px 4px 10px black;
        border-radius: 7px;


    }

    .achivements_div_1 div h1 {
        color: #ff7200;
        font-weight: 900;
        font-size: 28px;
    }


    .achivements_div_1 div p {
        font-size: 14px;
        color: white;
        font-weight: 500;
    }




    .achivements_div_2 {
        width: 75%;
        height: 172px;

        position: absolute;
        bottom: 80px;
        left: 12.5%;
        display: flex;
        justify-content: space-between;
    }

    .achivements_div_2 div {
        width: 21%;
        height: 145px;
        /*  border: 3px solid yellow; */
        background-color: #302f2f;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        box-shadow: inset 0px 0px 39px rgb(17, 16, 16);
        box-shadow: 4px 4px 10px black;
        border-radius: 7px;


    }


    .achivements_div_2 div h1 {
        color: #ff7200;
        font-weight: 900;
        font-size: 28px;
    }

    #hundred {
        position: relative;
        top: 14px;

    }

    #client_100 {
        position: relative;
        top: 14px;

    }

    .iot_h {
        position: relative;
        top: 14px;
    }

    .iot_p {
        position: relative;
        top: 13px;
    }

    .achivements_div_2 div p {
        font-size: 14px;
        color: white;
        font-weight: 400;
    }

    .achivementElem {
        width: 95%;
        height: 60%;
        margin-left: auto;
        margin-right: auto;
    }

    .achiveR1 {
        width: 100%;
        height: 50%;
        display: flex;
    }

    .achieverc1 {
        width: 20%;
        height: 100%;
        margin-right: 8px;
        position: relative;
    }

    .achieveImg {
        width: 100%;
        height: 100%;
        position: relative;
        justify-content: flex-start;
        text-align: left;
        align-items: center;
        z-index: 1;
    }

    .achieve100 {
        position: absolute;
        top: 30px;
        left: 15px;
        font-size: 1.5rem;
        font-weight: 600;
        color: #ff7200;
        z-index: 10;
    }

    .achieveClient {
        position: absolute;
        top: 60px;
        left: 15px;
        font-size: 0.9rem;
        font-weight: 500;
        z-index: 10;
    }

    .achieveInstall {
        position: absolute;
        top: 80px;
        left: 15px;
        font-size: 0.9rem;
        font-weight: 500;
        z-index: 10;
    }

    .achieverc2 {
        width: 30%;
        height: 100%;
        margin-right: 8px;
    }

    .achiveI1 {
        width: 100%;
        height: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #ff7200;
        box-shadow: 0px 0px 24px black inset;
        margin-bottom: 8px;
    }

    .threefive {
        font-size: 1.5rem;
        color: #fff;
        font-weight: 700;
        position: relative;
        top: 10px;
    }

    .iot {
        font-size: 0.9rem;
        font-weight: 400;
        color: #fff;
    }

    .achiveI2 {
        width: 100%;
        height: 57%;
        position: relative;
    }

    .vehicle_text {
        color: #ff7200;
        font-size: 2.75rem;
        font-weight: 600;
        position: absolute;
        z-index: 10;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .vehicle_sub_text {
        color: #fff;
        position: absolute;
        z-index: 10;
        font-size: 0.75rem;
        top: 45%;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .achieverc3 {
        width: 20%;
        height: 100%;
        margin-right: 8px;
    }

    .achieve50 {
        width: 100%;
        height: 49%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #464444;
        box-shadow: inset 1px 1px 22px #000;
        margin-bottom: 6px;
    }

    .achieve50b {
        width: 100%;
        height: 49%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #d3d3d3;
        box-shadow: inset 1px 1px 19px #000;
        margin-bottom: 6px;
    }

    .m100m {
        color: #ff7200;
        font-size: 1.5rem;
        font-weight: 600;
    }

    .transactions {
        color: #fff;
        position: relative;
        bottom: 7px;
        font-size: 0.6rem;
        font-weight: 400;
    }

    .img_overlay {
        position: absolute !important;
    }

    .overlay_main_text {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -70%);

        z-index: 10;
        color: #ff7200;
        font-size: 1.25rem;
        font-weight: 600;
    }

    .overlay_sub_text {
        color: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, 0);
        font-size: 0.6rem;
        z-index: 10;
    }

    .m100black {
        color: #000;
        font-size: 1.5rem;
        font-weight: 600;
    }

    .transactionsm {
        color: #000;
        position: relative;
        bottom: 7px;
        font-size: 0.9rem;
    }

    .achieveI31 {
        width: 100%;
        height: 48%;

        margin-bottom: 8px;
    }

    .achieveI32 {
        width: 100%;
        height: 49%;
        margin-bottom: 8px;
    }

    .achieverc4 {
        width: 30%;
        height: 100%;
    }

    .achiveR2 {
        width: 100%;
        height: 50%;
        display: flex;
        position: relative;
    }

    .ach5 {
        width: 29%;
        height: 100%;
        margin-left: 8px;
        margin-top: 8px;
        position: relative;
    }

    .fifteen {
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 10;
    }

    .fifteenTrips {
        position: absolute;
        top: 60px;
        left: 15px;
        z-index: 10;
        font-size: 0.9rem;
    }

    .ach6 {
        width: 21%;
        height: 100%;
        margin-top: 8px;
        position: relative;
    }

    .ach7 {
        width: 20%;
        height: 100%;
        margin-top: 8px;
        margin-left: 8px;
        position: relative;
    }

    .m1000 {
        color: #fff;
        position: absolute;
        top: 20px;
        left: 10px;
        font-size: 1.5rem;
        z-index: 10;
    }

    .lynkassist {
        color: #fff;
        position: absolute;
        top: 52px;
        left: 10px;
        font-size: 0.85rem;
        z-index: 10;
    }

    .lynkassistLoc {
        color: #fff;
        position: absolute;
        top: 68px;
        left: 10px;
        font-size: 0.85rem;
        z-index: 10;
    }

    .ach8 {
        width: 30%;
        height: 100%;
        margin-left: 8px;
        margin-top: 8px;
        display: flex;
        position: relative;
    }

    .enterInner {
        width: 95%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .m3 {
        position: absolute;
        top: 10px;
        left: 20px;
        z-index: 10;
        color: #ff7200;
        font-size: 2.5rem;
        font-weight: 600;
    }

    .rfid_cir {
        color: #fff;
        position: absolute;
        top: 60px;
        left: 20px;
        font-size: 1rem;
        z-index: 10;
    }

    .lynkit_testomonial {
        width: 100%;
        /* height: 490px !important; */
        height: 100%;
        /* position: absolute;
        top: 1688px; */
        position: relative;
    }

    .Enterprise_div {
        width: 100%;
        height: 420px !important;
        /* position: absolute;
        top: 2251px; */
        position: relative;
        display: flex;
        justify-content: center;
        overflow-x: hidden;
    }

    .Enterprise_div h1 {
        font-size: 44px;
        color: #ff7200;
        text-shadow: none;
        position: absolute;
        top: -22px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px !important;
        background-image: linear-gradient(#fff, #fff);
        background-repeat: no-repeat;
        line-height: 110px;
        background-size: 0% 4px;
        background-position: bottom;
    }

    .entSpanActive {
        font-size: 9px;
        color: #ff7200;
        font-weight: 800;
        font-family: 'Bai Jamjuree', sans-serif ! important;
    }

    .enterprise_main_div {
        width: 100%;
        height: 500px;
        /*  background-color: aqua; */
        position: relative;
        top: 226px !important;
        display: flex;
    }

    .enterTop {
        width: 100%;
        height: 60%;
        display: flex;
        position: relative;
    }

    .enterBot {
        width: 100%;
        height: 20%;
        display: flex;
        position: relative;
    }

    .EnterpriseTextElement {
        width: 100%;
        height: auto;
        padding: 1px;
        position: relative;
        text-align: justify;
        /* border: 1px solid #404040; */
        /* box-shadow: 0px 0px 10px #585858; */
        /* background-color: #fff; */
    }

    .enterSpan {
        font-size: 8px;
        font-weight: 300;
        color: #F5F5F5 !important;
    }

    .enterpriseClose {
        width: 20px;
        height: 20px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: -10px;
        right: -10px;
        /* background-color: #ff7200; */
        border-radius: 50%;
    }

    .entSpan {
        font-size: 9px;
        color: #fff;
        font-weight: 800;
        font-family: 'Bai Jamjuree', sans-serif ! important;
    }

    .enterOrange {
        color: #ff7200;
        font-size: 10px;
        font-weight: 500;
        font-family: 'Bai Jamjuree', sans-serif ! important;
    }

    .enterWhite {
        color: #fff !important;
        font-size: 9px;
        font-weight: 400;
        font-family: 'Bai Jamjuree', sans-serif !important;
    }

    .enterWhiteSmall {
        color: #fff !important;
        font-size: 8px;
        text-decoration: underline;
        text-underline-offset: 4px;
        text-decoration-color: #ff7200;
        cursor: pointer;
        font-weight: 400;
        font-family: 'Bai Jamjuree', sans-serif !important;
    }

    .enterprise_left {
        width: 50%;
        height: 500px;
        /*  background-color: bisque; */
        display: flex;
        justify-content: center;
        align-items: center;
    }


    .supply_class {
        width: 84%;
        position: absolute;
        top: 130px;
        left: 8%;
        /* border: 2px solid red; */

    }

    .supply_class p {
        color: black;
        font-size: 11px;
        text-align: justify;
        line-height: 17px;

    }

    .block_class {
        width: 90%;
        position: absolute;
        top: 120px;
        left: 5%;
        /* border: 2px solid red; */

    }

    .block_class p {
        color: black;
        font-size: 11px;
        text-align: justify;
        line-height: 16px;

    }

    .iot_class {
        width: 90%;
        position: absolute;
        top: 142px;
        left: 5%;
        /* border: 2px solid red; */

    }

    .iot_class p {
        color: black;
        font-size: 11px;
        text-align: justify;
        line-height: 16px;

    }

    .customer_div {
        width: 100%;
        height: 700px;

        /* position: absolute;
        top: 2560px; */
        position: relative;
        display: flex;
        justify-content: center;
        display: none;
    }


    .customer_div1 {
        width: 100%;
        /* height: 900px; */
        height: 1260px;
        /* position: absolute;
        top: 2650px; */
        position: relative;
        display: flex;
        justify-content: center;

    }

    .customer_div1 h1 {

        font-size: 44px;
        color: black;
        text-shadow: none;
        position: absolute;
        top: 10px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px !important;
        background-image: linear-gradient(#ff7200, #ff7200);
        background-repeat: no-repeat;
        line-height: 110px;
        background-size: 0% 4px;

    }

    .customers_div_1 {
        width: 90%;
        height: 155px;

        position: absolute;
        top: 155px;
        display: flex;
        justify-content: space-around;

    }

    .customers_div_1 div {
        width: 25%;
        height: 155px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }

    .customers_div_1 img {
        /* filter: grayscale(100%); */
    }

    .customers_div_1 img:hover {
        cursor: pointer;
        filter: none;
        /* filter: grayscale(0%); */
    }


    .customers_div_2 {
        width: 90% !important;
        height: 155px !important;
        position: absolute;
        top: 325px;
        display: flex;
        justify-content: space-around;

    }

    .customers_div_2 div {
        width: 25% !important;
        height: 155px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }

    .customers_div_3 {
        width: 90%;
        height: 155px;

        position: absolute;
        top: 497px;
        display: flex;
        justify-content: space-around;

    }

    .customers_div_3 div {
        width: 25% !important;
        height: 155px !important;

        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }

    .customers_div_3 img:hover {
        cursor: pointer;
        filter: none;

    }

    .customers_div_4 {
        width: 90% !important;
        height: 155px !important;
        position: absolute;
        top: 669px;
        display: flex;
        justify-content: space-around;

    }

    .customers_div_4 div {
        width: 25% !important;
        height: 155px !important;

        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }

    .customers_div_4 img {
        /* filter: grayscale(100%); */
    }

    .customers_div_4 img:hover {
        cursor: pointer;
        filter: none;
        /* filter: grayscale(0%); */
    }

    /* 5 */

    .customers_div_5 {
        width: 90% !important;
        height: 155px;
        position: absolute;
        top: 840px;
        display: flex;
        justify-content: space-around;

    }

    .customers_div_5 div {
        width: 25% !important;
        height: 155px;

        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }

    .customers_div_6 {
        width: 90% !important;
        height: 155px;
        position: absolute;
        top: 1015px;
        display: flex;
        justify-content: space-around;
    }

    .customers_div_6 div {
        width: 25% !important;
        height: 155px;

        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }


    .customer_images {
        width: 125px;
    }

    #cma {
        width: 150px;
        height: 150px;
    }

    #bharat {
        width: 100px;
        height: 118px;
    }

    #aditya {
        width: 130px;
        height: 105px;
    }

    #konike {
        width: 100px;
        height: 110px;
    }

    #lg {
        width: 100px;
        height: 120px;
    }

    #dadri {
        width: 140px;
        height: 100px;
    }

    #more_3500 {
        width: 100%;
        height: 66px;
        position: relative;
        top: 1175px;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    #more_3500 h1 {
        position: relative;
        font-size: 30px;
        color: black;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
        background-image: linear-gradient(white, white);
        background-repeat: none;
        background-size: 0% 0px;
        font-family: 'Fjalla One', sans-serif;
    }



    /*  */
    .homeSlider_swiper > .swiper-container {
        width: 100%;

        position: relative;
        right: 50px;
        overflow: visible;

    }

    .homeSlider_swiper > .swiper-container {
        width: 100%;
        position: absolute;
        right: 21px;
        top: 0px;
        overflow: visible;

    }

    .homeSlider_swiper > .swiper-slide {
        background-position: center;
        background-size: cover;
        width: 220px;
        height: 280px;
        transition: all 200ms linear;
        position: relative;
        background-color: #141414;
        box-shadow: 2px 2px 10px black;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;


    }

    .homeSlider_swiper > .swiper-slide>img {
        width: 39%;
        height: 110px;
        position: relative;
        bottom: 29px;
        left: 30%;

    }

    .homeSlider_swiper > .swiper-slide h2 {
        font-size: 17px;
        color: #ff7200;
        position: relative;
        font-family: 'Fjalla One', sans-serif;
        left: 1%;
        word-wrap: break-word;
        white-space: initial;



    }

    .homeSlider_swiper > .swiper-slide-active {
        width: 280px;
        height: 250px;



    }

    .homeSlider_swiper > .swiper-slide.swiper-slide-active .initial_data {
        display: none;
    }

    .supply_para {
        font-size: 25px !important;
        position: relative;
        top: 12px;
        left: 50px !important;
    }

    .block_para {
        font-size: 25px !important;
        position: relative;
        top: 13px;
        left: 40px !important;
        width: 66% !important;

    }

    .iot_para {
        font-size: 25px !important;
        position: relative;
        top: 22px;
        left: 50px !important;
        width: 59% !important;
    }

    .active_para {
        width: 84%;
        position: absolute;
        top: 130px;
        left: 8%;

    }

    .active_para p {
        color: black;
        font-size: 12px;
        text-align: justify;
    }

    .container .swiper-button-next {
        color: #fff;
    }

    .container .swiper-button-prev {
        color: #fff;
    }

    .container .swiper-pagination {
        position: absolute;
        top: 366px !important;
        z-index: 10;
    }

    .container swiper-pagination-current {
        background: black;
    }

    .container .swiper-pagination-bullet-active {
        width: 8px;
        height: 8px;
        background-color: #fff !important;



    }

    .swiper-pagination-bullet {
        opacity: 1;

        background-color: black;
    }

    /*  */






    .whatsNew {
        width: 100%;
        height: 600px;
        /* position: absolute;
        top: 3902px; */
        position: relative;
        justify-content: center;


    }

    .whatsNew img {
        width: 100%;
        height: 100%;
    }

    .whatsNew h1 {
        font-size: 44px;
        position: relative;
        bottom: 599px !important;
        letter-spacing: 0px !important;
    }



    .whatsNew3cards {
        width: 100%;
        height: 530px;
        /* border: 2px solid cyan;     */
        justify-content: center;
        position: relative;
        bottom: 630px;
        border-radius: 12px !important;
        overflow: hidden;
    }


    .whatsNew3cards .swiper-slide {
        background-position: center;
        background: #141414;
        background-size: cover;
        width: 260px !important;
        height: 510px;
        transition: all 200ms linear;
        position: relative;
        top: 0px;
        box-shadow: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 5px;
    }



    .whatsNew3cards .swiper-slide-active {
        width: 260px;
        height: 510px;
        position: relative;
        top: 0px;
        box-shadow: none;

    }

    .new_card_1 {
        max-width: 100% !important;
        height: 480px;
        display: flex !important;
        flex-direction: column;
        position: relative;
        left: 0px;
        /* border: 1px solid red;  */
        align-items: center;
        text-align: center;
        box-shadow: none !important;
    }

    .new_img {
        width: 100%;
        height: 177px;
        position: relative;
        left: 0px;
        top: 1rem;

    }

    .whatsNew3cards .swiper-slide-active img {
        position: relative;
        top: 0px;

    }

    .new_img img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: absolute;
        left: 0px;

    }

    .new_data {

        position: relative;
        top: 1rem;
        display: flex;
        flex-direction: column;
    }

    .new_data h2 {
        color: #ff7200;
        font-size: 14.5px;
        position: relative;
        left: 0px;
        top: 7px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        margin-top: 18px;
    }

    .new_data p {
        position: relative;
        top: 0px;
        color: white;
        font-size: 11px;
        font-weight: 300;
        margin-top: 33px;
        padding-left: 12px;
        padding-right: 12px;
    }

    #learn_more {
        position: relative;
        top: 24px;
        color: #ff7200;
        font-size: 13px;

    }

    .whatsNew3cards .swiper-button-next {
        color: white;
        position: absolute;
        top: 200px !important;
        right: 0px !important;
    }


    .whatsNew3cards .swiper-button-prev {
        color: white;
        position: absolute;
        top: 200px !important;
        left: 30px !important;
    }


    .whatsNew3cards .swiper-pagination {
        display: none;
    }

    .lynkit_feed {
        width: 100%;
        /* height: 1010px; */
        height: 1080px;
        background-color: rgb(22, 22, 22);
        /* position: absolute;
        top: 4960px !important; */
        position: relative;
    }

    .lynkit_feed h1 {
        color: #ff7200;
        position: relative;
        top: 30px;
        align-items: center;
        text-align: center;
        letter-spacing: 0px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 1px;
        font-size: 44px;

    }

    .feed_inner {
        width: 90%;
        height: 540px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 70px;
        display: flex;
    }


    .feed_right {
        width: 100%;
        height: 720px;
        /* border: 1px solid red; */
    }

    .awardsSection {
        height: 470px;
        position: relative;
        /* top: 3142px; */
    }

    .awardsInner {
        height: 100%;
    }

    .awardsHeading {
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: center;
    }

    .awardsSpans {
        font-size: 44px;
        color: #000;
        position: relative;
        top: 10px;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-size: 0% 4px;
        background-position: bottom;
    }

    .awardsListing700 {
        display: none;
    }

    .awardsListing {
        width: 100%;
        height: auto;
        position: relative;
        top: 60px;
        align-items: center;
    }

    .awardsRow1 {
        width: 80%;
        height: 140px;
        display: flex;
        justify-content: center;
    }

    .awardBox2 {
        width: 45%;
        height: 100%;
        margin-left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .awardBox {
        width: 20%;
        margin-left: 0px;
        height: 58%;
    }

    .awardImg {
        max-width: 100%;
        max-height: 100%;
        height: 100%;
        /* object-fit: contain; */

    }

    .grandParent1 {
        width: 100%;
        height: auto;
    }

    .grandParent2 {
        width: 100%;
        height: 100vh;
    }

    .modalOuter {
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0px;
        z-index: 9999999999;
        background-color: #00000087;
    }

    .modalCover {
        bottom: auto;
        left: 50%;
        max-height: calc(100% - 100px);
        padding-right: 0 !important;
        padding-top: 0px;
        padding-bottom: 10px;
        position: fixed;
        right: auto;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 70%;
        z-index: 9999999;
        background-color: #fff;
        height: auto;
        border: 2px solid #ff7200;
    }

    .modalInnerCover {
        width: 100%;
        height: 100%;
    }

    .modalHeader {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 13px;
        flex-direction: column;
    }

    .modalClose {
        width: 35px;
        height: 35px;
        /* background-color: #fff; */
        position: absolute;
        cursor: pointer;
        top: 5px;
        right: 5px;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .modalIcon {
        color: red;
        font-size: 1rem;
    }

    .nodalImage {
        width: 15%;
        height: 80px;
        margin-bottom: 14px;
    }

    .modalText {
        font-weight: 300;
        color: #202020 !important;
    }

    .modalSpan {
        font-size: 2.5vw;
        color: #000;
    }

    .modalBodi {
        width: 100%;
        height: auto;
        padding: 11px;
    }

    .modalText {
        font-size: 11px;
        line-height: 0px;
    }

    .banner_video_slider {
        display: none !important;
    }

    .neeProd {
        width: 97%;
        height: 425px;
        display: flex;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
        margin-top: 33px;
        box-shadow: 0px 0px 10px grey;
        border-radius: 10px;
    }

    .prodSpan {
        font-size: 30px;
        /* padding-left: 1.5%; */
        font-weight: 600;
        position: relative;
        top: 5px;
        line-height: 28px;
        color: #ff7200;
        font-family: 'Fjalla One', sans-serif;
    }

    .prodData {
        font-size: 10px;
        font-weight: 300;
        /* margin-top: 35px; */
        text-align: justify;
        text-shadow: none;
        letter-spacing: 0 !important;
        line-height: 0px !important;
    }

    .prodBtn {
        width: auto;
        height: auto;
        padding: 6px;
        border: none;
        font-size: 10px;
        background-color: #ff7200;
        cursor: pointer;
        border-radius: 20px;
        color: #fff;
        box-shadow: 2px 2px grey;
        text-decoration: none;
    }

    .prodImage {
        width: 94%;
        height: auto;
    }

    .api-div {
        position: absolute;
        height: 1100px;
        top: 70px;
        width: 100%;
        background-color: rgb(22, 22, 22);
        align-items: center;
    }


    .api_card_1 {
        max-width: 100% !important;
        height: 427px;
        display: flex !important;
        flex-direction: column;
        position: absolute;
        left: 0px;
        /* border: 1px solid red; */
        align-items: center;
        text-align: center;
    }

    .api-swiper {
        width: 100%;
        height: 510px;
        /* border: 2px solid cyan; */
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 5%;
        border-radius: 12px !important;
        overflow: hidden;
    }

    .api-swiper .swiper-slide {
        background-position: center;
        background: #030303;
        background-size: cover;
        width: 300px !important;
        height: 460px;
        transition: all 200ms linear;
        position: relative;
        top: 10px;
        left: 100px;
        /* background-color: rebeccapurple; */
        box-shadow: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 30px;
        margin-left: 30px;
        border-radius: 10px;
        border: 1px solid #ff7700;

    }

    .api-card-data {

        position: relative;
        top: 175px;
        width: 100%;
        height: 240px;
        display: flex;
        flex-direction: column;
    }

    .api-card-data h2 {
        color: #ff7200;
        font-size: 18px;
        position: relative;
        left: 0px;
        text-align: center;
        align-items: center !important;
        font-weight: 700;
        margin: 5px 8px 0px;
        /* font-family: 'Montserrat', sans-serif; */
        font-family: 'Montserrat', sans-serif;
    }

    .api-card-data p {
        position: relative;
        top: 9px;
        color: white;
        font-size: 13px;
        font-weight: 300;

        padding-left: 16px;
        padding-right: 16px;
    }

    .api-card-img {
        width: 100%;
        height: 177px;
        position: absolute;
        left: 0px;
        top: 0px;
        border-radius: 10px;


    }

    .api-card-img img {
        width: 85%;
        height: 100%;
        object-fit: contain;
        position: relative;
        left: 0px;

    }

    .api-swiper .swiper-slide-active {
        width: 300px;
        height: 460px;
        position: relative;
        top: 10px;


    }

    .api-swiper .swiper-slide-active img {
        position: relative;
        top: 0px;

    }


    .api-swiper .swiper-button-next {
        color: #ff7722 !important;
        position: absolute !important;
        right: 10px !important;
        top: 200px !important;
    }

    .api-swiper .swiper-button-prev {
        color: #ff7722 !important;
        position: absolute !important;
        top: 200px !important;
        left: 10px !important;
    }


    .api-swiper .swiper-pagination {
        top: 490px;
        left: 5.5%;
    }

    .swiper-pagination-bullet-active {
        opacity: 1;
        background: #ff7700 !important;
        filter: brightness(2);
    }

    .swiper-pagination-bullet {
        background: #8f8f8f !important;
    }
    .banner_headings_messgae {
        width: 100%;
        height: 5%;
        color: white;
        bottom: 352px;
        position: relative;
        z-index: 10;
        text-align: center;
    }
    .home_page_footer {
        /* position: absolute;
        top: 5908px; */
        position: relative;
        width: 100%;
    }

}

/* media query 10 */

@media only screen and (max-width:800px) {
    body {
        width: 100%;
        height: 100%;
        display: inline;
        z-index: -1;

    }

    .EnterpriseTextElementNew {
        display: none;
    }

    .generalBtn {
        position: absolute;
        bottom: 9px;
        color: #383838;
        font-size: 15px;
        cursor: pointer;
        text-underline-offset: 3px;
    }

    ::-webkit-scrollbar {
        width: 6px;
    }

    .PEnterDegign {
        display: none;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #ff7200;
        border-radius: 7px;

    }

    .counts {
        font-size: 1.4rem;
    }

    .mainMobile {
        display: block;
    }

    #lll {
        position: relative;
        left: 30px;
        font-size: 6px;
        font-weight: lighter;
        text-decoration: none;
    }

    .video_part {
        width: 100%;
        /* height: 890px; */
        height: 60vh;
        position: relative;
        top: 0px;

        z-index: 1;
    }

    .video_part .headings {
        width: 100%;
        /* height: 49%; */
        height:90%;
        position: relative;
        top: 20px;
        display: block !important;

    }

    video {
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 50px;
        object-fit: initial;
        z-index: 1;
    }


    .three_btns_for_videos {
        width: 5.5%;
        height: 30px;
        display: flex;
        position: relative;

        top: -4px;
        color: #ff7200;
        justify-content: center;
        align-items: center;
        justify-content: space-between;
        z-index: 13;



    }

    .three_btns_for_videos button {

        width: 9px;
        height: 9px;
        border-radius: 12px;
        border: none;

    }


    .banner_headings {
        width: 50%;
        height: 20%;
        color: #ff7200;

        bottom: 269px;
        position: relative;
        left: 39px;
        z-index: 10;

        /*  border: 0.2px solid red;  */
    }

    .banner_headings h4 {
        font-size: 52px;
        font-weight: 900;
        text-shadow: 1px 5px 1px black;
        font-family: 'Fjalla One', sans-serif;
        line-height: 52px;
    }


    .banner_headings h6 {
        color: #fff;
        font-size: 11px;
        text-shadow: 1px 2px 1px black;
        position: relative;
        top: 0px;
        left: 2px;
        font-weight: 500;
        font-family: 'Montserrat', sans-serif;
    }

    .banner_btn {
        background-color: #ff7200;
        border: none;
    }





    .main {
        width: 100%;
        height: 666px;
        position: relative;
        overflow: hidden;

        background-color: #ff7200;
        z-index: 2;


    }


    .Product_heading {
        position: relative;
        text-align: center;
        justify-content: center;
        align-items: center;


    }

    .Product_heading h2 {

        font-size: 44px;
        font-weight: 900;
        text-shadow: none;
        color: black;
        position: relative;
        top: -10px;
        font-family: 'Montserrat', sans-serif;
        letter-spacing: 0px !important;
        background-image: linear-gradient(#fff, #fff);

        background-repeat: no-repeat;
        line-height: 100px;
        background-size: 0% 4px;
        background-position: bottom;



    }



    .Product_titles {
        display: none;

    }

    .sub {
        display: none;
    }

    .products_swiper {
        width: 100%;
        height: 440px;
        position: absolute;
        top: 222px;
        display: block;
    }


    .swiper-container1 {
        width: 100%;
        height: 720px;
        position: absolute;
        top: 9%;
        overflow: visible;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        z-index: 9999999;
    }

    .swiper-container1 .swiper-slide {
        position: relative;
        left: -100px;
        width: 62% !important;
        height: 630px !important;
        transition: all 200ms linear;
        background-color: #fff !important;
        box-shadow: none;
        margin-right: 5px;
        border-radius: 7px;
        display: flex;
        align-items: center;
        flex-direction: column;
        overflow: visible !important;
    }

    .proMobSlide {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .proMobTop {
        width: 100%;
        height: 16%;
        background-color: #000;
        border: 1px solid #282828;
    }

    .proMobHead {
        width: 100%;
        height: 60px;
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;
        justify-content: center !important;
        align-items: center !important;
        position: relative !important;
    }

    .proMobSpan {
        color: #ff7200;
        font-size: 17px;
        font-weight: 700;
        padding-left: 5px;
    }

    .proMobData {
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        padding: 4px;
    }

    .proMobBottom {
        width: 100%;
        height: 84%;
        border-radius: 10px;
        background-color: #fff;
    }

    .proMobImage {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center !important;
        align-items: center ! important;
        padding: 15px;
    }



    .knowMoreBtn {
        width: auto;
        height: 20px;
        background-color: #ff7200;
        color: #fff;
        font-size: 0.7rem;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 2px;
        border: none;
        cursor: pointer;
        box-shadow: 0px 0px 4px grey;
        position: absolute;
        bottom: 9px;
        z-index: 99999;
    }

    .cardContenSpan {
        font-size: 11px;
    }

    .cardContBody {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        flex-direction: column;
        position: relative;
    }

    .myGreyBtn {
        width: auto;
        height: 23px;
        border-radius: 4px;
        cursor: pointer;
        font-size: 0.7rem;
        background-color: #ff7200;
        border: none;
        color: #fff;
        position: absolute;
        top: 13px;
        right: 20px;
    }

    .backArrow {
        font-size: 12px;
        color: #fff;
    }

    /* .generalBtn{
        width: auto;
        height: 24px;
        padding-left: 12px;
        padding-right: 12px;
        color: #fff;
        border-radius: 12%;
        background-color: #606060;
        position: absolute;
        bottom: 9px;
    } */


    #read_more_div {
        width: 130px !important;
        height: 100px;

    }

    #file {
        width: 92%;
        height: 3px;
        position: absolute;
        bottom: -3%;
        left: 28px;
        background: transparent;



    }

    progress {
        -webkit-appearance: none;
        appearance: none;
    }

    progress::-webkit-progress-bar {
        background-color: lightgray;
        width: 100%;
    }

    progress::-webkit-progress-value {
        background-color: black !important;
    }

    #numbers_progress {
        position: absolute;
        bottom: -3%;

    }


    progress::-webkit-progress-value {
        background: black;
    }

    progress::-moz-progress-bar {
        background: lightcolor;
    }


    .swiper-slide-duplicate-next {
        background-color: white !important;
    }

    .swiper-button-next {
        color: #ff7200;
        display: none;
    }

    .swiper-button-prev {
        color: #ff7200;
        display: none;
    }




    .swiper-container1 .swiper-pagination {
        display: none;

    }


    .achievements_div {
        width: 100%;
        height: 578px;
        /* position: absolute;
        top: 1360px; */
        position: relative;
        background-color: #070707;
    }

    .achievements_div h1 {
        font-size: 44px;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px !important;
        background-image: linear-gradient(#fff, #fff);
        letter-spacing: 1px !important;
        background-repeat: no-repeat;
        line-height: 100px;
        background-size: 0% 4px;
        background-position: bottom;
    }

    .achivementElem {
        width: 98%;
        height: 60%;
        margin-left: auto;
        margin-right: auto;
    }

    .achiveR1 {
        width: 100%;
        height: 50%;
        display: flex;
    }

    .achieverc1 {
        width: 20%;
        height: 100%;
        margin-right: 8px;
        position: relative;
    }

    .achieveImg {
        width: 100%;
        height: 100%;
        position: relative;
        justify-content: flex-start;
        text-align: left;
        align-items: center;
        z-index: 1;
    }

    .achieve100 {
        position: absolute;
        top: 30px;
        left: 15px;
        font-size: 1.3rem;
        font-weight: 600;
        color: #ff7200;
        z-index: 10;
    }

    .achieveClient {
        position: absolute;
        top: 55px;
        left: 15px;
        font-size: 0.8rem;
        font-weight: 500;
        z-index: 10;
    }

    .achieveInstall {
        position: absolute;
        top: 68px;
        left: 15px;
        font-size: 0.8rem;
        font-weight: 500;
        z-index: 10;
    }

    .achieverc2 {
        width: 30%;
        height: 100%;
        margin-right: 8px;
    }

    .achiveI1 {
        width: 100%;
        height: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #ff7200;
        box-shadow: 0px 0px 24px black inset;
        margin-bottom: 8px;
    }

    .threefive {
        font-size: 1.3rem;
        color: #fff;
        font-weight: 600;
        position: relative;
        top: 10px;
    }

    .iot {
        font-size: 0.8rem;
        font-weight: 400;
        color: #fff;
    }

    .achiveI2 {
        width: 100%;
        height: 57%;
        position: relative;
    }

    .vehicle_text {
        color: #ff7200;
        font-size: 2.25rem;
        font-weight: 600;
        position: absolute;
        z-index: 10;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .vehicle_sub_text {
        color: #fff;
        position: absolute;
        z-index: 10;
        font-size: 0.7rem;
        top: 45%;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .achieverc3 {
        width: 20%;
        height: 100%;
        margin-right: 8px;
    }

    .achieve50 {
        width: 100%;
        height: 49%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #464444;
        box-shadow: inset 1px 1px 22px #000;
        margin-bottom: 6px;
    }

    .achieve50b {
        width: 100%;
        height: 49%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #d3d3d3;
        box-shadow: inset 1px 1px 19px #000;
        margin-bottom: 6px;
    }

    .m100m {
        color: #ff7200;
        font-size: 1.3rem;
        font-weight: 600;
    }

    .transactions {
        color: #fff;
        position: relative;
        bottom: 7px;
        font-size: 0.55rem;

        font-weight: 400;
    }

    .img_overlay {
        position: absolute !important;
    }

    .overlay_main_text {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -70%);

        z-index: 10;
        color: #ff7200;
        font-size: 1rem;
        font-weight: 600;
    }

    .overlay_sub_text {
        color: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, 0);
        font-size: 0.5rem;
        z-index: 10;
    }

    .m100black {
        color: #000;
        font-size: 1.3rem;
        font-weight: 600;
    }

    .transactionsm {
        color: #000;
        position: relative;
        bottom: 7px;
        font-size: 0.8rem;
    }

    .achieveI31 {
        width: 100%;
        height: 48%;

        margin-bottom: 8px;
    }

    .achieveI32 {
        width: 100%;
        height: 49%;
        margin-bottom: 8px;
    }

    .achieverc4 {
        width: 30%;
        height: 100%;
    }

    .achiveR2 {
        width: 100%;
        height: 50%;
        display: flex;
        position: relative;
    }

    .ach5 {
        width: 29%;
        height: 100%;
        margin-left: 8px;
        margin-top: 8px;
        position: relative;
    }

    .fifteen {
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 10;
    }

    .fifteenTrips {
        position: absolute;
        top: 55px;
        left: 15px;
        z-index: 10;
        font-size: 0.8rem;
    }

    .ach6 {
        width: 21%;
        height: 100%;
        margin-top: 8px;
        position: relative;
    }

    .ach7 {
        width: 20%;
        height: 100%;
        margin-top: 8px;
        margin-left: 8px;
        position: relative;
    }

    .m1000 {
        color: #fff;
        position: absolute;
        top: 20px;
        left: 10px;
        font-size: 1.3rem;
        z-index: 10;
    }

    .lynkassist {
        color: #fff;
        position: absolute;
        top: 47px;
        left: 10px;
        font-size: 0.8rem;
        z-index: 10;
    }

    .lynkassistLoc {
        color: #fff;
        position: absolute;
        top: 62px;
        left: 10px;
        font-size: 0.8rem;
        z-index: 10;
    }

    .ach8 {
        width: 30%;
        height: 100%;
        margin-left: 8px;
        margin-top: 8px;
        display: flex;
        position: relative;
    }


    .m3 {
        position: absolute;
        top: 10px;
        left: 20px;
        z-index: 10;
        color: #ff7200;
        font-size: 2.5rem;
        font-weight: 600;
    }

    .rfid_cir {
        color: #fff;
        position: absolute;
        top: 60px;
        left: 20px;
        font-size: 1rem;
        z-index: 10;
    }

    .achievements_image {
        width: 100%;
        height: 546px;
    }

    #blur_image {
        width: 100%;
        height: 430px;
    }

    .achivements_div_1 {
        width: 90%;
        height: 172px;
        /* border: 1px solid red; */
        position: absolute;
        bottom: 270px;
        left: 5%;
        display: flex;
        justify-content: space-between;
    }

    .achivements_div_1 div {
        width: 23%;
        height: 145px;
        /*  border: 3px solid yellow; */
        background-color: #867878;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        box-shadow: inset 0px 0px 39px rgb(17, 16, 16);
        box-shadow: 4px 4px 10px black;
        border-radius: 7px;


    }

    .achivements_div_1 div h1 {
        color: #ff7200;
        font-weight: 900;
        font-size: 28px;
    }


    .achivements_div_1 div p {
        font-size: 12px;
        color: white;
        font-weight: 500;
    }




    .achivements_div_2 {
        width: 90%;
        height: 172px;

        position: absolute;
        bottom: 80px;
        left: 5%;
        display: flex;
        justify-content: space-between;
    }

    .achivements_div_2 div {
        width: 23%;
        height: 145px;
        /*  border: 3px solid yellow; */
        background-color: #302f2f;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        box-shadow: inset 0px 0px 39px rgb(17, 16, 16);
        box-shadow: 4px 4px 10px black;
        border-radius: 7px;


    }


    .achivements_div_2 div h1 {
        color: #ff7200;
        font-weight: 900;
        font-size: 28px;
    }

    #hundred {
        position: relative;
        top: 0px;

    }

    #client_100 {
        position: relative;
        top: 0px;

    }

    .iot_h {
        position: relative;
        top: 14px;
    }

    .iot_p {
        position: relative;
        top: 13px;
    }

    .achivements_div_2 div p {
        font-size: 12px;
        color: white;
        font-weight: 500;
    }

    .lynkit_testomonial {
        width: 100%;
        height: 600px !important;
        /* position: absolute;
        top: 1824px; */
        position: relative;
    }

    .Enterprise_div {
        width: 100%;
        height: 420px !important;
        /* position: absolute;
        top: 2397px; */
        position: relative;
        display: flex;
        justify-content: center;
        overflow: hidden;
    }

    .Enterprise_div h1 {
        font-size: 44px;
        color: #ff7200;
        text-shadow: none;
        position: absolute;
        top: -13px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px !important;
        background-image: linear-gradient(#fff, #fff);
        background-repeat: no-repeat;
        line-height: 110px;
        background-size: 0% 4px;
        background-position: bottom;
    }

    .enterprise_main_div {
        width: 80%;
        height: 500px;
        /*  background-color: aqua; */
        position: relative;
        top: 260px !important;
        display: flex;
    }

    .enterInner {
        width: 98%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .entSpanActive {
        font-size: 8.5px;
        color: #ff7200;
        font-weight: 800;
        font-family: 'Bai Jamjuree', sans-serif ! important;
    }

    .enterOrange {
        color: #ff7200;
        font-size: 9px;
        font-weight: 500;
        font-family: 'Bai Jamjuree', sans-serif ! important;
    }

    .enterWhite {
        color: #fff !important;
        font-size: 8px;
        font-weight: 400;
        font-family: 'Bai Jamjuree', sans-serif !important;
    }

    .enterprise_left {
        width: 50%;
        height: 500px;
        /*  background-color: bisque; */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .supply_class {
        width: 84%;
        position: absolute;
        top: 160px;
        left: 8%;
        /* border: 2px solid red; */

    }

    .supply_class p {
        color: black;
        font-size: 10px;
        text-align: justify;
        line-height: 17px;

    }

    .block_class {
        width: 90%;
        position: absolute;
        top: 145px;
        left: 5%;
        /* border: 2px solid red; */

    }

    .block_class p {
        color: black;
        font-size: 10px;
        text-align: justify;
        line-height: 16px;

    }

    .iot_class {
        width: 90%;
        position: absolute;
        top: 165px;
        left: 5%;
        /* border: 2px solid red; */

    }

    .iot_class p {
        color: black;
        font-size: 10px;
        text-align: justify;
        line-height: 16px;

    }

    .customer_div1 {
        width: 100%;
        /* height: 900px; */
        height: 1200px;
        /* position: absolute;
        top: 2787px; */
        position: relative;
        display: flex;
        justify-content: center;

    }

    .customer_div1 h1 {
        font-size: 44px;
        color: black;
        text-shadow: none;
        position: absolute;
        top: 20px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-image: linear-gradient(#ff7200, #ff7200);
        background-repeat: no-repeat;
        line-height: 110px;
        background-size: 0% 4px;
        background-position: bottom;
        letter-spacing: 0px !important;
    }

    .customers_div_1 {
        width: 90%;
        height: 140px;
        position: absolute;
        top: 155px;
        display: flex;
        justify-content: space-around;

    }

    .customers_div_1 div {
        width: 24%;
        height: 140px;

        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }

    .customers_div_2 {
        width: 90% !important;
        height: 140px;

        position: absolute;
        top: 320px;
        display: flex;
        justify-content: space-around;

    }

    .customers_div_2 div {
        width: 24% !important;
        height: 140px;

        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }

    .customers_div_3 {
        width: 90% ! important;
        height: 140px;

        position: absolute;
        top: 486px;
        display: flex;
        justify-content: space-around;

    }

    .customers_div_3 div {
        width: 24% !important;
        height: 140px;

        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }

    .customers_div_4 {
        width: 90% !important;
        height: 140px;

        position: absolute;
        top: 652px;
        display: flex;
        justify-content: space-around;

    }


    .customers_div_4 div {
        width: 24% !important;
        height: 140px;

        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }

    .customers_div_5 {
        width: 90% !important;
        height: 140px;
        position: absolute;
        top: 820px;
        display: flex;
        justify-content: space-around;
        z-index: 10;
    }


    .customers_div_5 div {
        width: 24% !important;
        height: 140px;

        display: flex;
        align-items: center;
        justify-content: center;
        /* box-shadow: 4px 4px 10px grey; */
    }

    .customers_div_6 {
        width: 90% !important;
        height: 140px;
        position: absolute;
        top: 974px;
        display: flex;
        justify-content: space-around;
        z-index: 10;
    }


    .customers_div_6 div {
        width: 24% !important;
        height: 140px;

        display: flex;
        align-items: center;
        justify-content: center;
        /* box-shadow: 4px 4px 10px grey; */
    }

    .customer_images {
        width: 115px;
    }

    #cma {
        width: 110px;
        height: 129px;
    }

    #bharat {
        width: 100px;
        height: 110px;
    }

    #aditya {
        width: 100px;
        height: 87px;
    }

    #lg {
        width: 100px;
        height: 115px;
    }

    #dadri {
        width: 130px;
        height: 90px;
    }

    .homeSlider_swiper > .swiper-container {
        width: 100vw;
        /* top: -40px; */
        position: relative;
        right: 90px;
        overflow: visible;
    }

    .homeSlider_swiper > .swiper-slide {
        background-position: center;
        background-size: cover;
        width: 200px !important;
        height: 240px !important;
        transition: all 200ms linear;
        position: relative;
        background-color: #141414;
        box-shadow: 2px 2px 10px grey;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;


    }

    .homeSlider_swiper > .swiper-slide>img {
        width: 39%;
        height: 90px;
        position: relative;
        bottom: 39px;

    }

    .homeSlider_swiper > .swiper-slide h2 {
        font-size: 16px;
        color: #ff7200;
        position: relative;
        left: 0%;
        font-family: 'Fjalla One', sans-serif;


    }

    .homeSlider_swiper > .swiper-slide-active {
        width: 230px;
        height: 280px;



    }

    .homeSlider_swiper > .swiper-slide.swiper-slide-active .initial_data {
        display: none;
    }

    .supply_para {
        font-size: 24px !important;
        position: relative;
        top: 32px;
        left: 22px !important;
    }

    .block_para {
        font-size: 25px !important;
        position: relative;
        top: 29px;
        left: 55px !important;
        width: 48% !important;

    }

    .iot_para {
        font-size: 24px !important;
        position: relative;
        top: 45px;
        left: 22px !important;
        width: 63% !important;
    }

    .active_para {
        width: 88%;
        height: 210px;
        position: absolute;
        top: 160px;
        left: 6%;

    }

    .active_para p {
        color: black;
        font-size: 11px;
        text-align: justify;
        line-height: 16px;
    }

    .container .swiper-button-next {
        color: #fff;
    }

    .container .swiper-button-prev {
        color: #fff;
    }

    .container .swiper-pagination {
        position: absolute;
        top: 350px !important;
        z-index: 10;
    }

    .container swiper-pagination-current {
        background: #fff;
    }

    .container .swiper-pagination-bullet-active {
        width: 8px;
        height: 8px;
        background-color: #fff !important;



    }

    .swiper-pagination-bullet {
        opacity: 1;
        background-color: black;
    }

    /*  */

    #more_3500 {
        width: 100%;
        height: 66px;
        position: relative;
        top: 1112px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 8;
    }

    #more_3500 h1 {
        position: relative;
        font-size: 30px;
        color: black;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
        background-image: linear-gradient(white, white);
        background-repeat: none;
        background-size: 0% 0px;
        font-family: 'Fjalla One', sans-serif;
    }

    .whatsNew {
        width: 100%;
        height: 570px;
        /* position: absolute;
        top: 3985px; */
        position: relative;
        justify-content: center;
    }

    .whatsNew h1 {
        font-size: 44px;
        font-weight: 900;
        letter-spacing: 0px !important;
    }

    .whatsNew img {
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .whatsNew h1 {
        z-index: 1;
        color: #ff7200;
        position: relative;
        bottom: 570px !important;
        align-items: center;
        text-align: center;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-image: linear-gradient(#fff, #fff);
        background-repeat: no-repeat;
        line-height: 100px;
        background-size: 0% 4px;
        background-position: bottom;
    }

    .whatsNew img {
        width: 100%;
        height: 100%;
    }

    .whatsNew3cards {
        width: 100%;
        height: 510px;
        justify-content: center;
        position: relative;
        bottom: 590px;
        border-radius: 0px !important;
        overflow: hidden;
    }

    .whatsNew3cards .swiper-container {
        width: 100%;
        left: 0px;
        position: relative;
    }

    .whatsNew3cards .swiper-slide {
        background-position: center;
        background: #141414;
        background-size: cover;
        width: 32% !important;
        height: 470px !important;
        transition: all 200ms linear;
        position: relative;
        top: 0px;
        /* left: 0px; */
        box-shadow: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 6px;
    }



    .whatsNew3cards .swiper-slide-active {
        width: 32%;
        height: 470px;
        position: relative;
        top: 0px;
        box-shadow: none;

    }

    .new_card_1 {
        max-width: 100% !important;
        height: 460px;
        display: flex !important;
        flex-direction: column;
        position: relative;
        top: 0px;
        left: 0px;
        /* border: 1px solid red;  */
        align-items: center;
        text-align: center;
        box-shadow: none !important;
    }

    .new_img {
        width: 100%;
        height: 177px;
        position: relative;
        left: 0px;
        top: 1rem;
    }

    .whatsNew3cards .swiper-slide-active img {
        position: relative;
        top: 0px;

    }

    .new_img img {
        width: 100%;
        height: 90%;

        object-fit: contain;
        position: absolute;
        left: 0px;

    }

    .new_data {
        position: relative;
        top: 1rem;
        display: flex;
        flex-direction: column;
    }

    .new_data h2 {
        color: #ff7200;
        font-size: 13px;
        position: relative;
        left: 0px;
        top: 10px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        margin-top: 18px;
    }

    .new_data p {
        position: relative;
        top: 0px;
        color: white;
        font-size: 10.7px;
        font-weight: 300;
        margin-top: 25px;
        padding-left: 12px;
        padding-right: 12px;
    }

    #learn_more {
        position: relative;
        top: 0px;
        color: #ff7200;
        font-size: 13px;

    }

    .whatsNew3cards .swiper-button-next {
        display: block !important;
        color: rgb(255, 219, 219);
        position: absolute;
        top: 200px !important;
        right: 0px !important;
    }


    .whatsNew3cards .swiper-button-prev {
        display: block !important;
        color: white;
        position: absolute;
        top: 200px !important;
        left: 0px !important;
    }


    .whatsNew3cards .swiper-pagination {
        display: none;
    }

    .lynkit_feed {
        width: 100%;
        /* height: 1010px; */
        height: 1080px;
        background-color: rgb(22, 22, 22);
        /* position: absolute; */
        /* background-color: rgb(224, 223, 223); */
        /* top: 4960px !important; */
        position: relative;
    }

    .lynkit_feed h1 {
        color: #ff7200;
        position: relative;
        top: 30px;
        align-items: center;
        text-align: center;
        letter-spacing: 0px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 1px;
        font-size: 44px;

    }

    .feed_inner {
        width: 90%;
        height: 540px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 70px;
        display: flex;
    }


    .feed_right {
        width: 100%;
        height: 720px;
        /* border: 1px solid red; */
    }

    .awardsListing700 {
        display: none;
    }

    .awardsSection {
        height: 455px;
        position: relative;
        /* top: 3660px; */
    }

    .awardsInner {
        height: 100%;
    }

    .awardsHeading {
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: center;
    }

    .awardsSpans {
        font-size: 44px;
        color: #000;
        position: relative;
        top: 0px;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-size: 0% 4px;
        background-position: bottom;
    }

    .awardsListing {
        width: 100%;
        height: auto;
        position: relative;
        top: 30px;
        align-items: center;
        /* background-color: rgb(0 0 0 / 2%); */
    }

    .awardBox {
        width: 20%;
        height: 74%;
        margin-left: 0px;
        position: relative;
    }

    .awardsRow1 {
        width: 82%;
        height: 140px;
        display: flex;
        justify-content: center;
    }

    .awardBox2 {
        width: 52%;
        height: 100%;
        margin-left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .awardImg {
        max-width: 100%;
        max-height: 100%;
        height: 100%;
        object-fit: contain;

    }

    .grandParent1 {
        width: 100%;
        height: auto;
    }

    .grandParent2 {
        width: 100%;
        height: 100vh;
    }

    .modalOuter {
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0px;
        z-index: 9999999999;
        background-color: #00000087;
    }

    .modalCover {
        bottom: auto;
        left: 50%;
        max-height: calc(100% - 100px);
        padding-right: 0 !important;
        padding-top: 0px;
        padding-bottom: 10px;
        position: fixed;
        right: auto;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 85%;
        z-index: 9999999;
        background-color: #fff;
        height: auto;
        border: 2px solid #ff7200;
    }

    .modalInnerCover {
        width: 100%;
        height: 100%;
    }

    .modalHeader {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 13px;
        flex-direction: column;
        position: relative;
    }

    .modalClose {
        width: 35px;
        height: 35px;
        position: absolute;
        cursor: pointer;
        top: 5px;
        right: 5px;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .modalIcon {
        color: red;
        font-size: 2.5rem;
    }

    .modalSpan {
        font-size: 2.5vw;
        color: #000;
    }

    .modalBodi {
        width: 100%;
        height: auto;
        padding: 1px;
    }

    .modalText {
        font-size: 10px;
        line-height: 0px;
    }

    .banner_video_slider {
        display: none !important;
    }

    /* ************************ */
    .main {
        display: none;
    }

    .mainMobile {
        width: 100%;
        height: 865px;
        /* position: absolute;
        top: 506px; */
        position: relative;
        overflow: hidden;
        z-index: 2;
    }

    .mainMobTop {
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #000;
    }

    .mainMobBottom {
        width: 100%;
        height: 50%;
        background-color: #ff7200;
    }

    .mobProductHead {
        font-size: 44px;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px !important;
        background-image: linear-gradient(#fff, #fff);
        letter-spacing: 1px !important;
        background-repeat: no-repeat;
        line-height: 100px;
        background-size: 0% 4px;
        color: #fff;
        background-position: bottom;
    }

    .mainMob2 {
        width: 100%;
        height: 120px;
        border: 1px solid red;
    }

    .EnterpriseTextElement {
        width: 100%;
        height: 128px;
        padding: 10px;
        position: relative;
        text-align: justify;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .enterpriseClose {
        width: 20px;
        height: 20px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: -3px;
        right: 0px;
        /* background-color: #ff7200; */
        border-radius: 50%;
        /* overflow: visible; */
        z-index: 9999;
    }

    .bodyImagee {
        /* width: 90% !important; */
        width: 50% !important;
        height: 25% ! important;
        position: relative !important;
        top: 0px !important;
        left: 0% !important;
    }

    .bodyImageeSmall {
        width: 87% !important;
        height: 25% ! important;
        position: relative !important;
        top: 0px !important;
        left: 0% !important;
    }

    .readmoree {
        color: #ff7200;
        text-decoration: underline;
        text-underline-offset: 4px;
        cursor: pointer;
        font-size: 11px;
        position: absolute;
        bottom: 10px;
    }

    .readmoree:hover,
    .readmoree:focus,
    .readmoree:active {
        color: #ff7200;
        text-decoration: underline;
    }

    .bodyTextt {
        font-size: 12px;
        margin-top: 20px;
    }

    .proMobImg {
        width: 18% ! important;
        height: 80% ! important;
        position: relative !important;
        top: 0px !important;
        right: 0px !important;
        bottom: 0px !important;
        left: 0px !important;
    }

    .mobImages {
        width: 9% !important;
        height: 35px !important;
        position: relative !important;
        top: 0px !important;
        bottom: 20px !important;
        padding-right: 5px;
    }

    .mobSpans {
        color: #ff7200;
        font-weight: 900;
    }

    .api-div {
        position: absolute;
        height: 1100px;
        top: 70px;
        width: 100%;
        background-color: rgb(22, 22, 22);
        align-items: center;
    }


    .api_card_1 {
        max-width: 100% !important;
        height: 427px;
        display: flex !important;
        flex-direction: column;
        position: absolute;
        left: 0px;
        /* border: 1px solid red; */
        align-items: center;
        text-align: center;
    }

    .api-swiper {
        width: 100%;
        height: 510px;
        /* border: 2px solid cyan; */
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 5%;
        border-radius: 12px !important;
        overflow: hidden;
    }

    .api-swiper .swiper-slide {
        background-position: center;
        background: #030303;
        background-size: cover;
        width: 300px !important;
        height: 460px !important;
        transition: all 200ms linear;
        position: relative;
        top: 10px;
        left: 100px;
        /* background-color: rebeccapurple; */
        box-shadow: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 30px;
        margin-left: 30px;
        border-radius: 10px;
        border: 1px solid #ff7700;

    }

    .api-card-data {

        position: relative;
        top: 175px;
        width: 100%;
        height: 240px;
        display: flex;
        flex-direction: column;
    }

    .api-card-data h2 {
        color: #ff7200;
        font-size: 18px;
        position: relative;
        left: 0px;
        text-align: center;
        align-items: center !important;
        font-weight: 700;
        margin: 5px 8px 0px;
        /* font-family: 'Montserrat', sans-serif; */
        font-family: 'Montserrat', sans-serif;
    }

    .api-card-data p {
        position: relative;
        top: 9px;
        color: white;
        font-size: 13px;
        font-weight: 300;

        padding-left: 16px;
        padding-right: 16px;
    }

    .api-card-img {
        width: 100%;
        height: 177px;
        position: absolute;
        left: 0px;
        top: 0px;
        border-radius: 10px;


    }

    .api-card-img img {
        width: 85%;
        height: 100%;
        object-fit: contain;
        position: relative;
        left: 0px;

    }

    .api-swiper .swiper-slide-active {
        width: 300px;
        height: 460px;
        position: relative;
        top: 10px;


    }

    .api-swiper .swiper-slide-active img {
        position: relative;
        top: 0px;

    }


    .api-swiper .swiper-button-next {
        color: #ff7722 !important;
        position: absolute !important;
        right: 10px !important;
        top: 200px !important;
    }

    .api-swiper .swiper-button-prev {
        color: #ff7722 !important;
        position: absolute !important;
        top: 200px !important;
        left: 10px !important;
    }


    .api-swiper .swiper-pagination {
        top: 490px;
        left: 5.5%;
    }

    .swiper-pagination-bullet-active {
        opacity: 1;
        background: #ff7700 !important;
        filter: brightness(2);
    }

    .swiper-pagination-bullet {
        background: #8f8f8f !important;
    }
    .banner_headings_messgae {
        width: 100%;
        height: 5%;
        color: white;
        /* bottom: 376px; */
        bottom: 76%;
        position: relative;
        z-index: 10;
        text-align: center;
    }
    .banner_headings_messgae>h6{
        font-size: 13px;
    }
    .home_page_footer {
        /* position: absolute;
        top: 5907px; */
        position: relative;
        width: 100%;
    }


}

/* media query 11 */

@media only screen and (max-width:700px) {

    .generalBtn {
        position: absolute;
        bottom: 9px;
        color: #383838;
        font-size: 13px;
        cursor: pointer;
        text-underline-offset: 3px;
    }

    .EnterpriseTextElementNew {
        display: none;
    }

    body {
        width: 100%;
        height: 100%;
        display: inline;
        z-index: -1;
    }

    .counts {
        font-size: 1.3rem;
    }

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #ff7200;
        border-radius: 7px;

    }

    html,
    body {
        width: 100%;
        height: 100%;
        margin: 0px;
        padding: 0px;
        overflow-x: visible !important;
    }

    /* *{
        border: 2px solid red;
        background-color: pink;
    } */

    .api_box {
        flex-basis: 100%;
    }

    #lll {
        position: relative;
        left: 30px;
        font-size: 6px;
        font-weight: lighter;
    }




    .video_part {
        width: 100%;
        /* height: 460px; */
        height: 481px;
        position: relative;
        top: 0px;
        z-index: -2;
        background: black;

    }

    .video_part .headings {
        width: 100%;
        height: 79%;
        position: relative;
        top: 20px;
    }

    video {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50px;
        object-fit: initial;
        z-index: 1;

    }


    .three_btns_for_videos {
        width: 6%;
        height: 30px;
        display: flex;
        position: relative;
        top: 30px;
        color: #ff7200;
        justify-content: center;
        align-items: center;
        justify-content: space-between;
        z-index: 10;



    }

    .three_btns_for_videos button {

        width: 7px;
        height: 7px;
        border-radius: 12px;
        border: none;

    }


    .banner_headings {
        width: 68%;
        height: 20%;
        color: #ff7200;

        bottom: 200px;
        position: relative;
        left: 55px;
        z-index: 10;
        /* display: none !important; */

        /*  border: 0.2px solid red;  */
    }

    .banner_headings h4 {
        font-size: 48px;
        font-weight: 700;
        text-shadow: 1px 5px 1px black;
        font-family: 'Montserrat', sans-serif;
        line-height: 42px;
    }


    .banner_headings h6 {
        color: #fff;
        font-size: 11px;
        text-shadow: 1px 4px 1px black;
        position: relative;
        top: 6px;
        left: 2px;
        font-weight: 300;
        font-family: 'Montserrat', sans-serif;
    }

    /*  */
    /*  */

    .banner_video_slider {
        width: 100% !important;
        height: 411px !important;
        position: absolute;
        top: 70px;
        /* position: relative; */
        left: 0px;

        display: flex;
        display: block !important;
        justify-content: center;
        overflow: hidden;
        align-items: center;

    }

    .banner_video_slider .swiper-wrapper {
        width: 100vw !important;
        height: 414px;
        position: absolute;
        left: 70px;
        top: 40px;

    }

    .banner_video_slider .swiper-slide {
        width: 100vw !important;
        height: 100% !important;
        margin-right: 0px;
    }


    /*  */

    .mobile_heading_div {
        width: 60%;
        height: 160px;
        position: relative;
        left: 21%;
        justify-content: center !important;
        align-items: center;
        text-align: center;
        z-index: 10;
        /* border: 1px solid red;   */

    }

    .mobile_heading_div h2 {
        font-size: 48px !important;
        font-weight: 700;
        text-shadow: 1px 2px 1px black;
        font-family: 'Fjalla One', sans-serif !important;
        line-height: 50px;
        position: absolute;
        top: 0px;
        left: 0px !important;
    }

    .mobile_heading_div h4 {
        color: #fff;
        font-size: 11px;
        text-shadow: 1px 2px 1px black;
        font-weight: 500;
        font-family: 'Montserrat', sans-serif;
        position: relative;
        text-align: center;

    }

    /* 0 */
    .swiper-container-horizontal>.swiper-pagination-bullets,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {

        height: 20px;
        position: absolute;
        top: 420px !important;
        left: 10%;
        justify-content: center;
        align-items: center;
        display: flex;
        z-index: 100;
    }


    .banner_video_slider_inner .swiper-pagination .swiper-pagination-bullets {
        position: absolute;
        top: 220px !important;
    }

    .banner_video_slider_inner .swiper-pagination {
        position: absolute !important;
        top: 200px !important;
        left: 0px !important;
        border: 1px solid #ff7200 !important;
        z-index: 1;
        display: block !important;

    }

    swiper-pagination-current {
        background: #fff;
    }

    .swiper-pagination-bullet-active {
        width: 8px;
        height: 8px;
        background-color: #ff7200 !important;



    }

    .swiper-pagination-bullet {
        opacity: 1;

        background-color: white;
    }











    .banner_video_slider_inner video {
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
    }


    /* video {
        display: none;

    } */






    /*  */
    /*  */
    .main {
        width: 100%;
        height: 650px;
        position: relative;
        top: 422px;
        background-color: #ff7200;
        z-index: -2;
        overflow: hidden;


    }



    .Product_heading {
        position: relative;
        text-align: center;
        justify-content: center;
        align-items: center;


    }

    .Product_heading h2 {

        font-size: 38px;
        font-weight: 900;
        text-shadow: none;
        color: black;
        position: relative;
        top: -3px;
        font-family: 'Montserrat', sans-serif;
        letter-spacing: 0px !important;
        background-image: linear-gradient(#fff, #fff);

        background-repeat: no-repeat;
        line-height: 100px;
        background-size: 0% 4px;
        background-position: bottom;



    }



    .Product_titles {
        display: none;

    }

    .sub {
        display: none;
    }

    .products_swiper {
        width: 100%;
        height: 440px;
        position: absolute;
        top: 207px;
        display: block;
    }


    .swiper-container1 {
        width: 100vw;
        height: auto;
        /* position: relative; */
        top: 19%;
        left: -10px;
        overflow: visible;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;



    }

    .swiper-container1 .swiper-slide {
        position: relative;
        left: -75px;
        width: 65% !important;
        height: 610px !important;
        transition: all 200ms linear;
        background-color: #fff !important;
        box-shadow: none;
        margin-right: 7px;
        border-radius: 7px;
        display: flex;
        flex-direction: column;
        overflow: visible !important;

    }

    #file {
        width: 92%;
        height: 3px;
        position: absolute;
        bottom: -27px;
        left: 40px;
        background: transparent;



    }

    progress {
        -webkit-appearance: none;
        appearance: none;
    }

    progress::-webkit-progress-bar {
        background-color: lightgray;
        width: 100%;
    }

    progress::-webkit-progress-value {
        background-color: black !important;
    }

    #numbers_progress {
        position: absolute;
        bottom: -40px;
        left: 50%;

    }


    progress::-webkit-progress-value {
        background: black;
    }

    progress::-moz-progress-bar {
        background: lightcolor;
    }


    .swiper-slide-duplicate-next {
        background-color: white !important;
    }

    .container .swiper-button-next {
        color: #ff7200;
        display: block !important;
    }

    .container .swiper-button-prev {
        color: #ff7200;
        display: block !important;
    }




    .swiper-container1 .swiper-pagination {
        display: none !important;

    }

    /*  */
    .achievements_div {
        width: 100%;
        height: 482px;
        /* position: absolute;
        top: 1290px; */
        position: relative;
        background-color: #070707;
    }

    .achievements_div h1 {
        font-size: 38px;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px !important;
        background-image: linear-gradient(#fff, #fff);
        letter-spacing: 0px !important;
        background-repeat: no-repeat;
        line-height: 90px;
        background-size: 0% 4px;
        background-position: bottom;
    }

    .lynkit_testomonial {
        width: 100%;
        /* height: 490px !important; */
        height:100% !important;
        /* position: absolute;
        top: 1726px; */
        position: relative;
    }

    .Enterprise_div {
        width: 100%;
        height: 360px ! important;
        /* position: absolute;
        top: 2273px; */
        position: relative;
        display: flex;
        justify-content: center;
        overflow-x: hidden;
    }

    .enterOuter {
        width: 100%;
        height: 80%;
        z-index: 1;
        position: relative;
        top: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .Enterprise_div h1 {
        font-size: 38px;
        color: #ff7200;
        text-shadow: none;
        position: absolute;
        top: -22px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px !important;
        background-image: linear-gradient(#fff, #fff);
        background-repeat: no-repeat;
        line-height: 110px;
        background-size: 0% 4px;
        background-position: bottom;
    }

    .enterprise_main_div {
        width: 80%;
        height: 600px;
        /* background-color: aqua;  */
        position: relative;
        top: 240px;
        display: flex;
    }

    .enterTop {
        width: 100%;
        height: 50%;
        display: flex;
        position: relative;
    }

    .entSpan {
        font-size: 8px;
        color: #fff;
        font-weight: 800;
        font-family: 'Bai Jamjuree', sans-serif ! important;
    }

    .enterOrange {
        color: #ff7200;
        font-size: 8px;
        font-weight: 500;
        font-family: 'Bai Jamjuree', sans-serif ! important;
    }

    .enterWhite {
        color: #fff !important;
        font-size: 7px;
        font-weight: 400;
        font-family: 'Bai Jamjuree', sans-serif !important;
    }

    .enterWhiteSmall {
        color: #fff !important;
        font-size: 7px;
        text-decoration: underline;
        text-underline-offset: 4px;
        text-decoration-color: #ff7200;
        cursor: pointer;
        font-weight: 400;
        font-family: 'Bai Jamjuree', sans-serif !important;
    }

    .PEnterDegign {
        display: none;
    }

    .achivementElem {
        width: 98%;
        height: 69%;
        margin-left: auto;
        margin-right: auto;
    }

    .achiveR1 {
        width: 100%;
        height: 50%;
        display: flex;
    }

    .achieverc1 {
        width: 20%;
        height: 100%;
        margin-right: 4px;
        position: relative;
    }

    .achieveImg {
        width: 100%;
        height: 100%;
        position: relative;
        justify-content: flex-start;
        text-align: left;
        align-items: center;
        z-index: 1;
    }

    .achieve100 {
        position: absolute;
        top: 30px;
        left: 10px;
        font-size: 1.1rem;
        font-weight: 600;
        color: #ff7200;
        z-index: 10;
    }

    .achieveClient {
        position: absolute;
        top: 50px;
        left: 10px;
        font-size: 0.7rem;
        font-weight: 500;
        z-index: 10;
    }

    .achieveInstall {
        position: absolute;
        top: 64px;
        left: 10px;
        font-size: 0.7rem;
        font-weight: 500;
        z-index: 10;
    }

    .achieverc2 {
        width: 30%;
        height: 100%;
        margin-right: 4px;
    }

    .achiveI1 {
        width: 100%;
        height: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #ff7200;
        box-shadow: 0px 0px 24px black inset;
        margin-bottom: 4px;
    }

    .threefive {
        font-size: 1.1rem;
        color: #fff;
        font-weight: 600;
        position: relative;
        top: 10px;
    }

    .iot {
        font-size: 0.7rem;
        font-weight: 400;
        color: #fff;
        position: relative;
        top: 4px;
    }

    .achiveI2 {
        width: 100%;
        height: 57%;
        position: relative;
    }

    .vehicle_text {
        color: #ff7200;
        font-size: 2rem;
        font-weight: 600;
        position: absolute;
        z-index: 10;
        top: 5%;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .vehicle_sub_text {
        color: #fff;
        position: absolute;
        z-index: 10;
        font-size: 0.6rem;
        top: 45%;
        left: 50%;
        transform: translate(-50%, 0);
    }


    .achieverc3 {
        width: 20%;
        height: 100%;
        margin-right: 4px;
    }

    .achieve50 {
        width: 100%;
        height: 49%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #464444;
        box-shadow: inset 1px 1px 22px #000;
        margin-bottom: 4px;
    }

    .achieve50b {
        width: 100%;
        height: 49%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #d3d3d3;
        box-shadow: inset 1px 1px 19px #000;
        margin-bottom: 4px;
    }

    .m100m {
        color: #ff7200;
        font-size: 1.1rem;
        font-weight: 600;
    }

    .transactions {
        color: #fff;
        position: relative;
        bottom: 7px;
        font-size: 0.4rem;

        font-weight: 400;
    }

    .img_overlay {
        position: absolute !important;
    }

    .overlay_main_text {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -70%);

        z-index: 10;
        color: #ff7200;
        font-size: 0.9rem;
        font-weight: 600;
    }

    .overlay_sub_text {
        color: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, 0);
        font-size: 0.45rem;
        z-index: 10;
    }

    .m100black {
        color: #000;
        font-size: 1.1rem;
        font-weight: 600;
    }

    .transactionsm {
        color: #000;
        position: relative;
        bottom: 7px;
        font-size: 0.7rem;
    }

    .achieveI31 {
        width: 100%;
        height: 48%;

        margin-bottom: 4px;
    }

    .achieveI32 {
        width: 100%;
        height: 49%;
        margin-bottom: 4px;
    }

    .achieverc4 {
        width: 30%;
        height: 100%;
    }

    .achiveR2 {
        width: 100%;
        height: 50%;
        display: flex;
        position: relative;
    }

    .ach5 {
        width: 29%;
        height: 100%;
        margin-left: 4px;
        margin-top: 4px;
        position: relative;
    }

    .fifteen {
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 10;
    }

    .fifteenTrips {
        position: absolute;
        top: 50px;
        left: 12px;
        z-index: 10;
        font-size: 0.7rem;
    }

    .ach6 {
        width: 21%;
        height: 100%;
        margin-top: 4px;
        position: relative;
    }

    .ach7 {
        width: 20%;
        height: 100%;
        margin-top: 4px;
        margin-left: 4px;
        position: relative;
    }

    .m1000 {
        color: #fff;
        position: absolute;
        top: 20px;
        left: 8px;
        font-size: 1.1rem;
        z-index: 10;
    }

    .lynkassist {
        color: #fff;
        position: absolute;
        top: 42px;
        left: 8px;
        font-size: 0.7rem;
        z-index: 10;
    }

    .lynkassistLoc {
        color: #fff;
        position: absolute;
        top: 57px;
        left: 8px;
        font-size: 0.7rem;
        z-index: 10;
    }

    .ach8 {
        width: 30%;
        height: 100%;
        margin-left: 4px;
        margin-top: 4px;
        display: flex;
        position: relative;
    }

    .m3 {
        position: absolute;
        top: 10px;
        left: 20px;
        z-index: 10;
        color: #ff7200;
        font-size: 2rem;
        font-weight: 600;
    }

    .rfid_cir {
        color: #fff;
        position: absolute;
        top: 50px;
        left: 20px;
        font-size: 0.9rem;
        z-index: 10;
    }

    .homeSlider_swiper > .swiper-container {
        width: 100vw;
        top: -40px;
        position: relative;
        right: 72px;
        overflow: visible;

    }

    .homeSlider_swiper > .swiper-slide {
        background-position: center;
        background-size: cover;
        width: 195px !important;
        height: 240px;
        transition: all 200ms linear;
        position: relative;
        background-color: #141414;
        box-shadow: 2px 2px 10px grey;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;


    }

    .homeSlider_swiper > .swiper-slide>img {
        width: 48%;
        position: relative;
        bottom: 30px;

    }

    .homeSlider_swiper > .swiper-slide h2 {
        font-size: 16px;
        color: #ff7200;
        position: relative;
        left: 0%;
        font-weight: 700;

        font-family: 'Fjalla One', sans-serif;

    }

    .homeSlider_swiper > .swiper-slide-active {
        width: 230px;
        height: 280px;



    }

    .homeSlider_swiper > .swiper-slide.swiper-slide-active .initial_data {
        display: none;
    }

    .supply_para {
        font-size: 25px !important;
        position: relative;
        top: 29px;
        left: 27px !important;
    }

    .block_para {
        font-size: 25px !important;
        position: relative;
        top: 29px;
        left: 56px !important;
        width: 48% !important;

    }

    .iot_para {
        font-size: 24px !important;
        position: relative;
        top: 45px;
        left: 16px !important;
        width: 66% !important;
    }

    .supply_class {
        width: 84%;
        position: absolute;
        top: 150px;
        left: 8%;
        /* border: 2px solid red; */

    }

    .supply_class p {
        color: black;
        font-size: 10px;
        text-align: justify;
        line-height: 17px;

    }

    .block_class {
        width: 90%;
        position: absolute;
        top: 140px;
        left: 5%;
        /* border: 2px solid red; */

    }

    .block_class p {
        color: black;
        font-size: 10px;
        text-align: justify;
        line-height: 16px;

    }

    .iot_class {
        width: 90%;
        position: absolute;
        top: 165px;
        left: 5%;
        /* border: 2px solid red; */

    }

    .iot_class p {
        color: black;
        font-size: 10px;
        text-align: justify;
        line-height: 16px;

    }

    .swiper-button-next {
        color: #fff;
    }

    .swiper-button-prev {
        color: #fff;
    }

    .enterprise_left1 .swiper-pagination {
        position: absolute;
        top: 359px !important;
        left: 0px;
        z-index: 10;
        display: block !important;
    }

    .enterprise_left1 swiper-pagination-current {
        background: #fff;
    }

    .enterprise_left1 .swiper-pagination-bullet-active {
        width: 8px;
        height: 8px;
        background-color: #fff !important;
    }

    .enterprise_left1 .swiper-pagination-bullet {
        opacity: 1;
        background-color: black;
    }

    .customer_div1 {
        width: 100%;
        /* height: auto; */
        /* position: absolute;
        top: 2597px; */
        position: relative;
        display: flex;
        justify-content: center;

    }

    .customer_div1 h1 {
        font-size: 38px;
        color: black;
        text-shadow: none;
        position: absolute;
        top: 28px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-image: linear-gradient(#ff7200, #ff7200);
        background-repeat: no-repeat;
        line-height: 110px;
        background-size: 0% 4px;
        background-position: bottom;
        letter-spacing: 0px !important;
    }

    .customers_div_1 {
        width: 93%;
        height: 140px;
        position: absolute;
        top: 155px;
        display: flex;
        justify-content: space-around;

    }

    .customers_div_1 div {
        width: 27%;
        height: 140px;

        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }

    .customers_div_2 {
        width: 93% !important;
        height: 140px;

        position: absolute;
        top: 320px;
        display: flex;
        justify-content: space-around;

    }

    .customers_div_2 div {
        width: 27% !important;
        height: 140px !important;

        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }

    .customers_div_3 {
        width: 93% !important;
        height: 140px;

        position: absolute;
        top: 487px;
        display: flex;
        justify-content: space-around;

    }

    .customers_div_3 div {
        width: 27% ! important;
        height: 140px;

        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }


    .customers_div_4 {
        width: 93% ! important;
        height: 140px;
        position: absolute;
        top: 664px;
        display: flex;
        justify-content: space-around;

    }

    .customers_div_4 div {
        width: 27% !important;
        height: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }

    .customers_div_5 {
        width: 93% ! important;
        height: 140px;
        position: absolute;
        top: 840px;
        display: flex;
        justify-content: space-around;
        z-index: 10;
    }

    .customers_div_5 div {
        width: 27% !important;
        height: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }

    .customers_div_6 {
        width: 93% ! important;
        height: 140px;
        position: absolute;
        top: 1000px;
        display: flex;
        justify-content: space-around;
        z-index: 10;
    }

    .customers_div_6 div {
        width: 27% !important;
        height: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }

    .customer_images {
        width: 102px;
    }

    #cma {
        width: 120px;
        height: 140px;
    }

    #bharat {
        width: 90px;
        height: 108px;
    }

    #aditya {
        width: 90px;
        height: 83px;
    }

    #lg {
        width: 90px;
        height: 100px;
    }

    #dadri {
        width: 94px;
        height: 90px;
    }

    #more_3500 {
        width: 100%;
        height: 66px;
        position: relative;
        top: 1122px;
        z-index: 8;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #more_3500 h1 {
        position: relative;
        font-size: 28px;
        color: black;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
        background-image: linear-gradient(white, white);
        background-repeat: none;
        background-size: 0% 0px;
        font-family: 'Fjalla One', sans-serif;
    }

    .entImg {
        width: 22% !important;
        height: 75% !important;
        position: relative;
        left: 5% !important;
    }

    /*  */
    .whatsNew {
        width: 100%;
        height: 550px !important;
        /* position: absolute;
        top: 3812px; */
        position: relative;
        justify-content: center;
    }

    .whatsNew img {
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .whatsNew h1 {

        z-index: 1;
        color: #ff7200;
        position: relative;
        bottom: 545px !important;
        align-items: center;
        text-align: center;
        font-size: 38px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 1px;
        background-image: linear-gradient(#fff, #fff);
        letter-spacing: 0px !important;
        background-repeat: no-repeat;
        line-height: 100px;
        background-size: 0% 4px;
        background-position: bottom;
    }

    .whatsNew3cards {
        width: 100%;
        height: 480px;
        justify-content: center;
        position: relative;
        left: 0px;
        bottom: 560px;
        border-radius: 0px !important;
        overflow: none;
    }

    .whatsNew3cards .swiper-wrapper {
        width: 100%;
        height: auto;
        /* left: 33px; */
        position: relative;
    }

    .whatsNew3cards .swiper-slide {
        background: #141414;
        width: 191px !important;
        height: 470px !important;
        transition: all 200ms linear;
        margin-right: 5px;
    }



    .whatsNew3cards .swiper-slide-active {
        width: 191px;
        height: 470px;
    }

    .new_card_1 {
        width: 240px !important;
        height: 427px;
        display: flex !important;
        flex-direction: column;
        position: relative;
        top: 20px;
        left: 0px;
        align-items: center;
        text-align: center;
        /* border: 1px solid red; */
    }

    .new_img {
        width: 100%;
        height: 154px;
        position: relative;
        left: 0px;
        top: 1rem;
    }

    .whatsNew3cards .swiper-slide-active img {
        position: relative;
        top: -5px;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .new_img img {
        width: 100%;
        height: 80%;
        position: absolute;
        top: 10px;
        left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .new_data {
        position: relative;
        top: 1rem;
        display: flex;
        flex-direction: column;
    }

    .new_data h2 {
        color: #ff7200;
        font-size: 11px;
        position: relative;
        left: 0px;
        top: 0px;
        line-height: 18px;
        font-weight: 700;
        margin-top: 0px;
        font-family: 'Montserrat', sans-serif;
    }

    .new_data p {
        position: relative;
        top: -8px;
        color: white;
        font-size: 10px;
        font-weight: 300;
        margin-top: 23px;
        padding-left: 12px;
        padding-right: 12px;
    }

    #learn_more {
        position: relative;
        top: -4px;
        color: #ff7200;
        font-size: 10px;

    }

    .whatsNew3cards .swiper-button-next {
        display: block !important;
        color: rgb(117, 58, 58);
        position: absolute;
        top: 220px !important;
    }


    .whatsNew3cards .swiper-button-prev {
        display: block !important;
        color: white;
        position: absolute;
        top: 220px !important;
    }


    .whatsNew3cards .swiper-pagination {
        display: none;
    }

    .lynkit_feed {
        width: 100%;
        /* height: 1010px; */
        height: 1080px;
        background-color: rgb(22, 22, 22);
        /* position: absolute; */
        /* background-color: rgb(224, 223, 223); */
        /* top: 4777px !important; */
        position: relative;
    }

    .lynkit_feed h1 {
        color: #ff7200;
        position: relative;
        top: 20px;
        align-items: center;
        text-align: center;
        letter-spacing: 0px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 1px;
        font-size: 38px;

    }

    .feed_inner {
        width: 90%;
        height: 540px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 70px;
        display: flex;
    }


    .feed_right {
        width: 100%;
        height: 720px;
        /* border: 1px solid red; */
    }

    .awardsSection {
        height: 450px;
        position: relative;
        /* top: 3900px; */
    }

    .awardsInner {
        height: 100%;
    }

    .awardsHeading {
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: center;
    }

    .awardsSpans {
        font-size: 38px;
        color: #000;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-size: 0% 4px;
        background-position: bottom;
    }

    /*  */
    .awardsListing {
        width: 100%;
        height: auto;
        position: relative;
        top: 30px;
        align-items: center;
        /* background-color: rgb(0 0 0 / 2%); */
    }

    .awardBox {
        width: 16% !important;
        height: 74%;
        margin-left: 0px;
        position: relative;
    }

    .awardsRow1 {
        width: 85%;
        height: 140px;
        display: flex;
        justify-content: space-between;
    }

    .awardBox2 {
        width: 50%;
        height: 100%;
        margin-left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .mainMobile {
        width: 100%;
        height: 820px;
        /* position: absolute;
        top: 480px; */
        position: relative;
        overflow: hidden;
        z-index: 2;
    }

    .mainMobTop {
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #000;
    }

    .mainMobBottom {
        width: 100%;
        height: 50%;
        background-color: #ff7200;
    }

    .mobProductHead {
        font-size: 44px;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px !important;
        background-image: linear-gradient(#fff, #fff);
        letter-spacing: 1px !important;
        background-repeat: no-repeat;
        line-height: 100px;
        background-size: 0% 4px;
        color: #fff;
        background-position: bottom;
    }

    .mainMob2 {
        width: 100%;
        height: 120px;
        border: 1px solid red;
    }

    .proMobImage {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center !important;
        align-items: center ! important;
        padding: 15px;
        padding-top: 4px;
    }

    .proMobTop {
        width: 100%;
        height: 19%;
        background-color: #000;
        border: 1px solid #282828;
    }

    .myGreyBtn {
        width: auto;
        height: 23px;
        border-radius: 4px;
        cursor: pointer;
        font-size: 0.7rem;
        background-color: #ff7200;
        border: none;
        color: #fff;
        position: absolute;
        top: 3px;
        right: 5px;
    }

    .cardContenSpan {
        font-size: 10.5px;
    }

    .mobProductHead {
        font-size: 38px;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px !important;
        background-image: linear-gradient(#fff, #fff);
        letter-spacing: 1px !important;
        background-repeat: no-repeat;
        line-height: 100px;
        background-size: 0% 4px;
        color: #fff;
        background-position: bottom;
    }

    .proMobImg {
        width: 18% ! important;
        height: 82% ! important;
        position: relative !important;
        top: 0px !important;
        right: 0px !important;
        bottom: 20px !important;
        left: 0px !important;
    }

    .api-div {
        position: absolute;
        height: 1100px;
        top: 70px;
        width: 100%;
        background-color: rgb(22, 22, 22);
        align-items: center;
    }


    .api_card_1 {
        max-width: 100% !important;
        height: 427px;
        display: flex !important;
        flex-direction: column;
        position: absolute;
        left: 0px;
        /* border: 1px solid red; */
        align-items: center;
        text-align: center;
    }

    .api-swiper {
        width: 100%;
        height: 510px;
        /* border: 2px solid cyan; */
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 5%;
        border-radius: 12px !important;
        overflow: hidden;
    }

    .api-swiper .swiper-slide {
        background-position: center;
        background: #030303;
        background-size: cover;
        width: 300px !important;
        height: 460px !important;
        transition: all 200ms linear;
        position: relative;
        top: 55px !important;
        left: 100px;
        /* background-color: rebeccapurple; */
        box-shadow: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 30px;
        margin-left: 30px;
        border-radius: 10px;
        border: 1px solid #ff7700;

    }

    .api-card-data {

        position: relative;
        top: 175px;
        width: 100%;
        height: 240px;
        display: flex;
        flex-direction: column;
    }

    .api-card-data h2 {
        color: #ff7200;
        font-size: 18px;
        position: relative;
        left: 0px;
        text-align: center;
        align-items: center !important;
        font-weight: 700;
        margin: 5px 8px 0px;
        /* font-family: 'Montserrat', sans-serif; */
        font-family: 'Montserrat', sans-serif;
    }

    .api-card-data p {
        position: relative;
        top: 9px;
        color: white;
        font-size: 13px;
        font-weight: 300;

        padding-left: 16px;
        padding-right: 16px;
    }

    .api-card-img {
        width: 100%;
        height: 177px;
        position: absolute;
        left: 0px;
        top: 0px;
        border-radius: 10px;


    }

    .api-card-img img {
        width: 85%;
        height: 100%;
        object-fit: contain;
        position: relative;
        left: 0px;

    }

    .api-swiper .swiper-slide-active {
        width: 300px;
        height: 460px;
        position: relative;
        top: 10px;


    }

    .api-swiper .swiper-slide-active img {
        position: relative;
        top: 0px;

    }


    .api-swiper .swiper-button-next {
        color: #ff7722 !important;
        position: absolute !important;
        right: 10px !important;
        top: 200px !important;
    }

    .api-swiper .swiper-button-prev {
        color: #ff7722 !important;
        position: absolute !important;
        top: 200px !important;
        left: 10px !important;
    }


    .api-swiper .swiper-pagination {
        display: none;
    }

    .swiper-pagination-bullet-active {
        opacity: 1;
        background: #ff7700 !important;
        filter: brightness(2);
    }

    .swiper-pagination-bullet {
        background: #8f8f8f !important;
    }
    .banner_headings_messgae {
        width: 100%;
        height: 5%;
        color: white;
        /* bottom: 106px; */
        bottom: 64%;
        /* left: 3%; */
        padding: 0px 1rem 0px 2rem;
        position: relative;
        z-index: 99999;
        display: flex;
        text-align: center;
    }
    .home_page_footer {
        /* position: absolute;
        top: 5729px; */
        position: relative;
        width: 100%;
    }




}

/* media query 12 */

@media only screen and (max-width:600px) {

    .mobileFont {
        font-size: 0.5rem !important;
    }
    .banner_headings_messgae h6{
       line-height: normal !important;
    }
    .banner_headings_messgae {
            /* width: 400px; */
            bottom: 60%;

    }
    body {
        width: 100%;
        height: 100%;
        display: inline;
        z-index: -1;

    }

    .EnterpriseTextElementNew {
        display: block;
    }

    .enterOuter {
        display: none;
    }

    .counts {
        font-size: 1.2rem;
    }

    ::-webkit-scrollbar {
        width: 6px;

    }

    ::-webkit-scrollbar-thumb {
        background-color: #ff7200 !important;
        border-radius: 7px;


    }




    #lll {
        position: relative;
        left: 30px;
        font-size: 6px;
        font-weight: lighter;

    }


    .video_part {
        width: 100%;
        /* height: 460px; */
        height: 431px;
        position: relative;
        top: 0px;
        z-index: -2;
    }

    .video_part .headings {
        width: 100%;
        height: 79%;
        position: relative;
        top: 20px;
    }

    video {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50px;
        object-fit: initial;
        z-index: 1;
    }


    .three_btns_for_videos {
        width: 6%;
        height: 30px;
        display: flex;
        position: relative;

        top: 380px;
        color: #ff7200;
        justify-content: center;
        align-items: center;
        justify-content: space-between;
        z-index: 10;



    }

    .three_btns_for_videos button {

        width: 7px;
        height: 7px;
        border-radius: 12px;
        border: none;

    }


    .banner_headings {
        width: 68%;
        height: 20%;
        color: #ff7200;

        bottom: 200px;
        position: relative;
        left: 55px;
        z-index: 10;

        /*  border: 0.2px solid red;  */
    }

    .banner_headings h4 {
        font-size: 38px;
        font-weight: 700;
        text-shadow: 1px 5px 1px black;
        font-family: 'Montserrat', sans-serif;
        line-height: 34px;
    }


    .banner_headings h6 {
        color: #fff;
        font-size: 11px;
        text-shadow: 1px 4px 1px black;
        position: relative;
        top: 6px;
        left: 2px;
        font-weight: 300;
        font-family: 'Montserrat', sans-serif;
    }



    .banner_btn {
        background-color: #ff7200;
        border: none;
    }

    /*  */
    /*  */



    .banner_video_slider {
        width: 100% !important;
        height: 362px !important;
        position: absolute;
        top: 70px;
        left: 0px;
        /* background-color: antiquewhite; */
        display: flex;
        display: block !important;
        justify-content: center;
        overflow: hidden;
        align-items: center;

    }

    .banner_video_slider .swiper-wrapper {
        width: 100vw !important;
        height: 362px;
        position: absolute;
        left: 60px;
        top: 40px;
        /* background-color: antiquewhite; */
    }

    .banner_video_slider .swiper-slide {
        width: 100vw !important;
        height: 100%;
        margin-right: 0px;
    }


    /*  */

    .mobile_heading_div {
        width: 73%;
        height: 120px;
        position: relative;
        left: 15%;
        justify-content: center !important;
        align-items: center;
        text-align: center;
        z-index: 10;
        /* border: 1px solid red; */


    }

    .mobile_heading_div h2 {
        font-size: 43px !important;
        font-weight: 700;
        text-shadow: 1px 2px 1px black;
        font-family: 'Fjalla One', sans-serif !important;
        line-height: 44px;
        position: absolute;
        top: 0px !important;
        left: 0px !important;
    }

    .mobile_heading_div h4 {
        color: #fff;
        font-size: 11px;
        text-shadow: 1px 2px 1px black;
        font-weight: 500;
        font-family: 'Montserrat', sans-serif;
        position: relative;
        top: -5px;
        text-align: center;

    }


    /* 0 */
    .swiper-container-horizontal>.swiper-pagination-bullets,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {

        height: 20px;
        position: absolute;
        top: 370px !important;
        left: 13%;
        justify-content: center;
        align-items: center;
        display: flex;
        z-index: 100;
    }


    .banner_video_slider_inner .swiper-pagination .swiper-pagination-bullets {
        position: absolute;
        top: 220px !important;
    }

    .banner_video_slider_inner .swiper-pagination {
        position: absolute !important;
        top: 200px !important;
        left: 0px !important;
        border: 1px solid #ff7200 !important;
        z-index: 1;
        display: block !important;

    }

    swiper-pagination-current {
        background: #fff;
    }

    .swiper-pagination-bullet-active {
        width: 8px;
        height: 8px;
        background-color: #FF5000 !important;

    }

    .swiper-pagination-bullet {
        opacity: 1;

        background-color: white;
    }

    .banner_video_slider_inner video {
        display: block;
        position: absolute;
        top: 0px;
        left: 12px;
        width: 100%;
        height: 100%;
    }

    .producst_para button {
        width: 105px;
        height: 25px;
        background-color: transparent;
        border: none;
        font-size: 13px;
        border-radius: 14px;
        color: #ff7200;
        position: absolute;
    }

    /* video {
        display: none;

    } */

    /*  */

    .main {
        width: 100%;
        height: 650px;
        position: relative;
        top: 382px;
        background-color: #ff7200;
        z-index: -1;
        overflow: hidden;

    }



    .Product_heading {
        position: relative;
        text-align: center;
        justify-content: center;
        align-items: center;


    }

    .Product_heading h2 {

        font-size: 35px;
        font-weight: 900;
        text-shadow: none;
        position: relative;
        top: 0px !important;
        font-family: 'Montserrat', sans-serif;
        letter-spacing: 0px !important;
        background-image: linear-gradient(#fff, #fff);

        background-repeat: no-repeat;
        line-height: 90px;
        background-size: 0% 4px;
        background-position: bottom;
        width: 105%;


    }

    .Product_titles {


        display: none;


    }

    .Product_titles img {
        display: inline-table;
    }




    .Product_titles {
        display: none;

    }

    .sub {
        display: none;
    }

    .products_swiper {
        width: 100%;
        height: 440px;
        position: absolute;
        top: 208px;
        display: block;
    }


    .swiper-container1 {
        width: 100vw;
        height: 350px;
        position: relative;
        top: 0px;
        left: 49px;
        overflow: visible;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;



    }

    .swiper-container1 .swiper-slide {
        position: relative;
        left: -64px;
        width: 388px !important;
        height: 470px !important;
        transition: all 200ms linear;
        background-color: #fff !important;
        box-shadow: none;
        margin-right: 20px;
        border-radius: 7px;
        display: flex;
        flex-direction: column;
        overflow: visible !important;

    }



    #file {
        width: 93%;
        height: 3px;
        position: absolute;
        bottom: 62px;
        left: -31px;
        background: transparent;



    }

    progress {
        -webkit-appearance: none;
        appearance: none;
    }

    progress::-webkit-progress-bar {
        background-color: lightgray;
        width: 100%;
    }

    progress::-webkit-progress-value {
        background-color: black !important;
    }

    #numbers_progress {
        position: absolute;
        bottom: 44px;
        left: 40%;

    }


    progress::-webkit-progress-value {
        background: black;
    }

    progress::-moz-progress-bar {
        background: lightcolor;
    }


    .swiper-slide-duplicate-next {
        background-color: white !important;
    }

    .swiper-button-next {
        color: #ff7200;
        display: none;
    }

    .swiper-button-prev {
        color: #ff7200;
        display: none;
    }

    .swiper-container1 .swiper-pagination {
        position: absolute;
        top: 348px;
        z-index: 10;
        display: none;
    }





    .Product_titles a {
        color: #fff;
        font-size: 22px;
        padding-top: 1px;
        cursor: pointer;
        text-decoration: none;
    }





    .sub {
        display: none;

    }

    /*  */
    .achievements_div {
        width: 100%;
        height: 462px;
        /* position: absolute;
        top: 1240px; */
        position: relative;
        background-color: #070707;
    }

    .achievements_div h1 {
        font-size: 35px;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-image: linear-gradient(#fff, #fff);
        letter-spacing: 0px !important;
        background-repeat: no-repeat;
        line-height: 90px;
        background-size: 0% 4px;
        background-position: bottom;
    }

    .achivementElem {
        width: 98%;
        height: 62%;
        margin-left: auto;
        margin-right: auto;
    }

    .achiveR1 {
        width: 100%;
        height: 50%;
        display: flex;
    }

    .achieverc1 {
        width: 20%;
        height: 100%;
        margin-right: 4px;
        position: relative;
    }

    .achieveImg {
        width: 100%;
        height: 100%;
        position: relative;
        justify-content: flex-start;
        text-align: left;
        align-items: center;
        z-index: 1;
    }

    .achieve100 {
        position: absolute;
        top: 30px;
        left: 10px;
        font-size: 0.95rem;
        font-weight: 600;
        color: #ff7200;
        z-index: 10;
    }

    .achieveClient {
        position: absolute;
        top: 45px;
        left: 10px;
        font-size: 0.60rem;
        font-weight: 500;
        z-index: 10;
    }

    .achieveInstall {
        position: absolute;
        top: 54px;
        left: 10px;
        font-size: 0.6rem;
        font-weight: 500;
        z-index: 10;
    }

    .achieverc2 {
        width: 30%;
        height: 100%;
        margin-right: 4px;
    }

    .achiveI1 {
        width: 100%;
        height: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #ff7200;
        box-shadow: 0px 0px 24px black inset;
        margin-bottom: 4px;
    }

    .threefive {
        font-size: 0.95rem;
        color: #fff;
        font-weight: 600;
        position: relative;
        top: 10px;
    }

    .iot {
        font-size: 0.6rem;
        font-weight: 400;
        color: #fff;
        position: relative;
        top: 4px;
    }

    .achiveI2 {
        width: 100%;
        height: 57%;
        position: relative;
    }

    .vehicle_text {
        color: #ff7200;
        font-size: 1.75rem;
        font-weight: 600;
        position: absolute;
        z-index: 10;
        top: 5%;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .vehicle_sub_text {
        color: #fff;
        position: absolute;
        z-index: 10;
        font-size: 0.4rem;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .achieverc3 {
        width: 20%;
        height: 100%;
        margin-right: 4px;
    }

    .achieve50 {
        width: 100%;
        height: 49%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #464444;
        box-shadow: inset 1px 1px 22px #000;
        margin-bottom: 4px;
    }

    .achieve50b {
        width: 100%;
        height: 49%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #d3d3d3;
        box-shadow: inset 1px 1px 19px #000;
        margin-bottom: 4px;
    }

    .m100m {
        color: #ff7200;
        font-size: 0.95rem;
        font-weight: 600;
    }

    .transactions {
        color: #fff;
        position: relative;
        bottom: 7px;
        font-size: 0.3rem;

        font-weight: 400;
    }

    .img_overlay {
        position: absolute !important;
    }

    .overlay_main_text {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -70%);

        z-index: 10;
        color: #ff7200;
        font-size: 0.8rem;
        font-weight: 600;
    }

    .overlay_sub_text {
        color: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, 0);
        font-size: 0.4rem;
        z-index: 10;
    }

    .m100black {
        color: #000;
        font-size: 0.95rem;
        font-weight: 600;
    }

    .transactionsm {
        color: #000;
        position: relative;
        bottom: 7px;
        font-size: 0.6rem;
    }

    .achieveI31 {
        width: 100%;
        height: 48%;

        margin-bottom: 4px;
    }

    .achieveI32 {
        width: 100%;
        height: 49%;
        margin-bottom: 4px;
    }

    .achieverc4 {
        width: 30%;
        height: 100%;
    }

    .achiveR2 {
        width: 100%;
        height: 50%;
        display: flex;
        position: relative;
    }

    .ach5 {
        width: 29%;
        height: 100%;
        margin-left: 4px;
        margin-top: 4px;
        position: relative;
    }

    .fifteen {
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 10;
    }

    .fifteenTrips {
        position: absolute;
        top: 447x;
        left: 12px;
        z-index: 10;
        font-size: 0.6rem;
    }

    .ach6 {
        width: 21%;
        height: 100%;
        margin-top: 4px;
        position: relative;
    }

    .ach7 {
        width: 20%;
        height: 100%;
        margin-top: 4px;
        margin-left: 4px;
        position: relative;
    }

    .m1000 {
        color: #fff;
        position: absolute;
        top: 20px;
        left: 8px;
        font-size: 0.95rem;
        z-index: 10;
    }

    .lynkassist {
        color: #fff;
        position: absolute;
        top: 38px;
        left: 8px;
        font-size: 0.56rem;
        z-index: 10;
    }

    .lynkassistLoc {
        color: #fff;
        position: absolute;
        top: 50px;
        left: 8px;
        font-size: 0.56rem;
        z-index: 10;
    }

    .ach8 {
        width: 30%;
        height: 100%;
        margin-left: 4px;
        margin-top: 4px;
        display: flex;
        position: relative;
    }

    .m3 {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 10;
        color: #ff7200;
        font-size: 1.75rem;
        font-weight: 600;
    }

    .rfid_cir {
        color: #fff;
        position: absolute;
        top: 40px;
        left: 10px;
        font-size: 0.9rem;
        z-index: 10;
    }

    .lynkit_testomonial {
        width: 100%;
        /* height: 490px !important; */
        height: 100% !important;
        /* position: absolute;
        top: 1640px; */
        position: relative;
    }

    .Enterprise_div {
        width: 100%;
        height: 400px !important;
        /* position: absolute;
        top: 2025px; */
        position: relative;
        display: flex;
        justify-content: center;
        overflow: hidden;
    }

    .Enterprise_div h1 {
        font-size: 35px;
        color: #ff7200;
        text-shadow: none;
        position: absolute;
        top: -10px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px !important;
        background-image: linear-gradient(#fff, #fff);
        background-repeat: no-repeat;
        line-height: 110px;
        background-size: 0% 4px;
        background-position: bottom;
    }

    .enterprise_main_div {
        width: 80%;
        height: 500px;
        /*  background-color: aqua; */
        position: relative;
        top: 260px;
        display: flex;



    }


    .enterprise_left1 {
        display: none;
    }

    .enterprise_left2 {
        display: block;
        background-color: #070707;
    }

    /*  */

    .enterprise_left2 .swiper-container {
        width: 100%;
        height: 250px;
        background-color: transparent !important;
        position: absolute;
        top: 130px;
        left: 12px;
        overflow: visible !important;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

    }

    .enterprise_left2 .swiper-container .swiper-slide {
        background-position: center !important;
        background-size: cover !important;
        position: relative;
        left: 0px;
        width: 205px !important;
        height: 320px !important;
        transition: all 200ms linear;
        margin-right: 5px;
        background-color: #141414;
        box-shadow: none;
    }

    .active_slide_data11 {
        width: 300px;
        height: 370px !important;
        position: absolute;
        background-color: #fff;
        box-shadow: inset 2px 1px 18px grey;
    }

    .flipCardData {
        height: 100% ! important;
        display: flex;
        flex-direction: column !important;
    }

    .active_imggg {
        width: 100%;
        height: 100% !important;
        position: relative;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column !important;
    }

    .activeImagge {
        width: 50% !important;
        height: 150px ! important;
        position: absolute;
        top: 1px !important;
        left: 0% !important;

    }

    .activeHeading {
        position: relative;
        top: 10px;
        color: #000 ! important;
    }

    .activeBBtn {
        width: auto;
        height: 23px;
        background-color: transparent !important;
        border-radius: 12px;
        border: none;
        color: #ff7200 !important;
        font-size: 12px !important;
        position: relative;
        top: 0px;
        font-family: 'Fjalla One', sans-serif;
    }

    .enterprise_left2 button {
        width: auto;
        height: 23px;
        background-color: transparent !important;
        border-radius: 12px;
        border: none;
        color: #fff;
        font-size: 11px;
        position: relative;
        top: 15px;
        font-family: 'Fjalla One', sans-serif;
    }

    .enterprise_left2 .initial_data .initial_data_img {
        width: 45% !important;
        height: 110px !important;
        position: relative;
        bottom: 39px;
        left: 10px;
    }

    .homeSlider_swiper > .swiper-container{
        width: 45% !important;
        height: 110px !important;
        position: relative;
        bottom: 39px;
        left: 10px;
    }

    .homeSlider_swiper > .swiper-slide{
        width: 45% !important;
        height: 110px !important;
        position: relative;
        bottom: 39px;
        left: 10px;
    }

    .homeSlider_swiper > .swiper-slide h2 {
        font-size: 20px;
        color: #ff7200;
        font-family: 'Fjalla One', sans-serif;
        font-weight: 900;
        position: relative;
        bottom: 4px;






    }



    /*  */

    .homeSlider_swiper > .swiper-slide.swiper-slide-active {
        z-index: 11 !important;

    }

    #right_arrow_icon {
        font-size: 15px;
        position: relative;
        left: 5px;
    }

    .flip-card-inner {
        position: absolute;
        top: -21px;
        left: -61px;
        width: 330px;
        height: 360px;
        text-align: center;
        transition: transform 0.5s;
        transform-style: preserve-3d;
        transform: rotateY(180deg);
        animation: opacity 1s;
        animation-fill-mode: forwards;
        animation-timing-function: linear;
        animation-delay: 0s;
        animation-iteration-count: 1;
        background-color: transparent !important;
        z-index: 11;

    }

    @keyframes opacity {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 1;
        }



    }


    .active_para {
        width: 100%;
        height: 100%;
        background-color: #ff7200;
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: #fff;

        transform: rotateY(180deg);
    }

    .para_div {
        width: 90%;
        height: 100%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        background-color: #fff;
        animation: opacity1 0.1s;
        animation-fill-mode: forwards;
        animation-timing-function: linear;
        animation-delay: 0s;
        animation-iteration-count: 1;


    }

    @keyframes opacity1 {
        0% {
            opacity: 0.2;
        }

        50% {
            opacity: -1;
        }

        100% {
            opacity: 1;
        }
    }

    .active_para p {
        color: black;
        padding-top: 37px;
        font-size: 11px;
        text-align: justify;

    }

    .active_para h4 {
        color: #ff7200 !important;
        font-weight: 900;
        position: relative;
        top: 26px;
        font-size: 24px;
        font-family: 'Fjalla One', sans-serif;
    }

    .swiper-button-next {
        color: #fff;
    }

    .swiper-button-prev {
        color: #fff;
    }



    .enterprise_left2 .swiper-pagination {
        position: absolute;
        top: 370px !important;
        left: -13px;
        z-index: 10;

    }

    swiper-pagination-current {
        background: #fff;
    }

    .enterprise_left2 .swiper-pagination-bullet-active {
        width: 8px;
        height: 8px;
        background-color: #ff7200 !important;



    }

    .enterprise_left2 .swiper-pagination-bullet {
        opacity: 1;

        background-color: #fff;
    }

    #close_button {
        width: 30px;
        height: 0px;
        position: absolute;
        top: 0px;
        right: -14px;
        background-color: #fff !important;
        color: black;
        font-size: 17px;
        font-weight: bold !important;

    }

    .customer_div1 {
        width: 100%;
        /* height: 900px; */
        height: 1110px;
        /* position: absolute;
        top: 2388px; */
        position: relative;
        display: flex;
        justify-content: center;

    }



    .customer_div1 h1 {

        font-size: 35px;
        color: black;
        text-shadow: none;
        position: absolute;
        top: 27px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-image: linear-gradient(#ff7200, #ff7200);
        background-repeat: no-repeat;
        line-height: 110px;
        background-size: 0% 4px;
        background-position: bottom;
        letter-spacing: 0px !important;


    }

    .customers_div_1 {
        width: 97%;
        height: 120px;
        position: absolute;
        top: 150px;
        display: flex;
        justify-content: space-around;

    }

    .customers_div_1 div {
        width: 26%;
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }

    .customers_div_2 {
        width: 97% !important;
        height: 120px;
        position: absolute;
        top: 290px;
        display: flex;
        justify-content: space-around;

    }

    .customers_div_2 div {
        width: 26% !important;
        height: 120px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }

    .customers_div_3 {
        width: 97% !important;
        height: 120px;

        position: absolute;
        top: 432px;
        display: flex;
        justify-content: space-around;

    }

    .customers_div_3 div {
        width: 26% !important;
        height: 120px;

        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }

    .customers_div_4 {
        width: 97% ! important;
        height: 120px;

        position: absolute;
        top: 606px;
        display: flex;
        justify-content: space-around;

    }

    .customers_div_4 div {
        width: 26% ! important;
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }

    .customers_div_5 {
        width: 97% ! important;
        height: 120px;
        position: absolute;
        top: 777px;
        display: flex;
        justify-content: space-around;
        z-index: 10;
    }

    .customers_div_5 div {
        width: 26% !important;
        height: 120px;

        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }

    .customers_div_6 {
        width: 97% ! important;
        height: 120px;
        position: absolute;
        top: 917px;
        display: flex;
        justify-content: space-around;
        z-index: 10;
    }

    .customers_div_6 div {
        width: 26% !important;
        height: 120px;

        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }

    .customer_images {
        width: 98px;
    }

    #cma {
        width: 100px;
        height: 130px;
    }

    #bharat {
        width: 85px;
        height: 105px;
    }

    #konike {
        width: 80px;
        height: 90px;
    }

    #aditya {
        width: 95px;
        height: 78px;
    }

    #lg {
        width: 70px;
        height: 80px;
    }

    #dadri {
        width: 75px;
        height: 80px;
    }


    #more_3500 {
        width: 100%;
        height: 66px;
        position: relative;
        top: 1020px;
        z-index: 8;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #more_3500 h1 {
        position: relative;
        font-size: 26px;
        color: black;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
        background-image: linear-gradient(white, white);
        background-repeat: none;
        background-size: 0% 0px;
        font-family: 'Fjalla One', sans-serif;
    }

    .whatsNew500 {
        width: 100%;
        height: 510px;
        /* position: absolute;
        top: 3500px; */
        position: relative;
        display: block;
    }

    .whatsNew500 h1 {
        font-size: 35px;
        z-index: 10;
        z-index: 1;
        color: #ff7200;
        position: relative;
        bottom: 505px !important;
        align-items: center;
        text-align: center;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px !important;
        background-image: linear-gradient(#fff, #fff);
        letter-spacing: 0px !important;
        background-repeat: no-repeat;
        line-height: 100px;
        background-size: 0% 4px;
        background-position: bottom;
    }

    .whatsNew500 img {
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .cardNew {
        width: 100%;
        height: 72%;
        position: absolute;
        bottom: 38px;
        overflow: hidden;
        border-radius: 0px !important;
    }

    .cardNew .swiper-container {
        width: 100%;
        height: 100%;
        top: -40px;
        left: 2px;
        position: absolute;
    }

    .cardNew .swiper-wrapper {
        width: 100%;
        height: 100%;
        position: relative;
    }

    .cardNew .swiper-slide {
        background: #141414;
        width: 195px !important;
        height: 390px !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        top: 20px;
        box-shadow: none;
        margin-right: 5px !important;
    }

    .cardNew .myCard {
        width: 100%;
        height: 100%;
        display: flex;
        text-align: center;
    }

    .cardNew .swiper-pagination {
        display: none;
    }

    .cardNew .swiper-button-next {
        display: block !important;
        color: rgb(255, 219, 219);
        position: absolute;
        top: 220px !important;
    }

    .cardNew .swiper-button-prev {
        display: block !important;
        color: white;
        position: absolute;
        top: 220px !important;
    }

    /*  */
    .whatsNew {
        display: none;
    }

    .api-div {
        display: none;
    }

    .new_img {
        width: 100%;
        height: 162px;
        position: absolute;
        left: 0px;
        top: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .whatsNew3cards .swiper-slide-active img {
        position: relative;
        top: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .new_img img {
        width: 100% !important;
        height: 70%;
        position: absolute;
        top: 30px;
        left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .new_data {
        position: relative;
        top: 175px;
        display: flex;
        flex-direction: column;
    }

    .new_data h2 {
        color: #ff7200;
        font-size: 15px;
        position: absolute;
        left: 0px;
        top: -0px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        line-height: 16px;
        margin-top: 0px;
    }

    .new_data p {
        position: relative;
        top: 10px;
        color: white;
        font-size: 10px;
        font-weight: 300;
        margin-top: 65px;
        padding-left: 8px;
        padding-right: 8px;
    }

    #learn_more {
        position: relative;
        top: 4px;
        color: #ff7200;
        font-size: 10px;
    }

    .lynkit_feed {
        width: 100%;
        /* height: 1010px; */
        height: 1080px;
        background-color: rgb(22, 22, 22);
        /* position: absolute; */
        /* background-color: rgb(224, 223, 223); */
        /* top: 4381px  !important; */
        position: relative;
    }

    .lynkit_feed h1 {
        color: #ff7200;
        position: relative;
        top: 20px;
        align-items: center;
        text-align: center;
        letter-spacing: 0px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 1px;
        font-size: 35px;

    }

    .feed_inner {
        width: 90%;
        height: 540px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 70px;
        display: flex;
    }


    .feed_right {
        width: 100%;
        height: 720px;
        /* border: 1px solid red; */
    }

    .awardsSection {
        height: 410px;
        position: relative;
        /* top: 3546px; */
    }

    .awardsInner {
        height: 100%;
    }

    .awardsHeading {
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: center;
    }

    .awardsSpans {
        font-size: 35px;
        color: #000;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-size: 0% 4px;
        background-position: bottom;
    }

    .awardsListing {
        width: 100%;
        height: auto;
        position: relative;
        top: 2px;
        align-items: center;
    }

    .awardBox {
        width: 20%;
        height: 74%;
        margin-left: 0px;
        position: relative;
    }

    .awardsRow1 {
        width: 82%;
        height: 140px;
        display: flex;
        justify-content: space-around;
    }

    .awardBox2 {
        width: 50%;
        height: 100%;
        margin-left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    /* *************** */
    .mainMobile {
        width: 100%;
        height: 810px;
        /* position: absolute;
        top: 432px; */
        position: relative;
        overflow: hidden;
        z-index: 2;
    }

    .swiper-container1 {
        width: 100%;
        height: 800px;
        position: absolute;
        top: 8%;
        overflow: visible;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        z-index: 9999999;
    }

    .swiper-container1 .swiper-slide {
        position: relative;
        left: -64px;
        width: 78% !important;
        height: 640px !important;
        transition: all 200ms linear;
        background-color: #fff !important;
        box-shadow: none;
        margin-right: 8px;
        border-radius: 7px;
        display: flex;
        align-items: center;
        flex-direction: column;
        overflow: visible !important;
    }

    .proMobSlide {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .proMobTop {
        width: 100%;
        height: 17%;
        background-color: #000;
        border: 2px solid #383838;
    }

    .proMobHead {
        width: 100%;
        height: 60px;
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;
        justify-content: center !important;
        align-items: center !important;
        position: relative !important;
    }

    .proMobImg {
        width: 18% ! important;
        height: 80% ! important;
        position: relative !important;
        top: 0px !important;
        right: 0px !important;
        bottom: 0px !important;
        left: 0px !important;
    }

    .mobImages {
        width: 9% !important;
        height: 35px !important;
        position: relative !important;
        top: 0px !important;
        bottom: 20px !important;
        padding-right: 5px;
    }

    .alter50 {
        width: 44%;
        height: 40px;
    }

    .alter501 {
        width: 56%;
        height: 40px;
    }

    .proMobImg {
        width: 20% ! important;
        height: 100% ! important;
        position: relative;
        top: 0px;
    }

    .proMobSpan {
        color: #ff7200;
        font-size: 17px;
        font-weight: 700;
    }

    .proMobData {
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        padding: 4px;
    }

    .proMobBottom {
        width: 100%;
        height: 84%;
        border-radius: 10px;
        background-color: #fff;
    }

    .proMobImage {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center !important;
        align-items: center ! important;
        padding: 12px !important;
    }

    .bodyImagee {
        width: 100% !important;
        height: 75% ! important;
        position: relative !important;
        top: 0px !important;
        left: 0% !important;
    }

    .knowMoreBtn {
        width: auto;
        height: 20px;
        background-color: #ff7200;
        color: #fff;
        font-size: 0.7rem;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 2px;
        border: none;
        cursor: pointer;
        box-shadow: 0px 0px 4px grey;
        position: absolute;
        bottom: 9px;
        z-index: 99999;
    }

    .cardContenSpan {
        font-size: 10.2px;
    }

    .cardContBody {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        flex-direction: column;
        position: relative;
    }

    .myGreyBtn {
        width: auto;
        height: 23px;
        border-radius: 4px;
        cursor: pointer;
        font-size: 0.7rem;
        background-color: #ff7200;
        border: none;
        color: #fff;
        position: absolute;
        top: 13px;
        right: 20px;
    }

    .backArrow {
        font-size: 12px;
        color: #fff;
    }

    .generalBtn {
        position: absolute;
        bottom: 9px;
        color: #383838;
        font-size: 13px;
        cursor: pointer;
        text-underline-offset: 3px;
    }

    .generalBtn:hover,
    .generalBtn:focus,
    .generalBtn:active {
        color: #ff7200;
    }

    .mobProductHead {
        font-size: 35px;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px !important;
        background-image: linear-gradient(#fff, #fff);
        letter-spacing: 1px !important;
        background-repeat: no-repeat;
        line-height: 100px;
        background-size: 0% 4px;
        color: #fff;
        background-position: bottom;
    }

    .nodalImage {
        width: 20%;
        height: 80px;
        margin-bottom: 7px;
    }

    .modalText {
        font-size: 10px !important;
        line-height: 0px ! important;
    }

    .bodyImagee {
        /* width: 90% !important; */
        width: 50% !important;
        height: 25% ! important;
        position: relative !important;
        top: 0px !important;
        left: 0% !important;
    }

    .bodyImageeSmall {
        width: 90% !important;
        height: 25% ! important;
        position: relative !important;
        top: 0px !important;
        left: 0% !important;
    }

    .PEnterDegign {
        width: 100%;
        height: 60%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        position: relative;
        top: 22%;
        overflow: visible;
    }

    .Enterprise_div img {
        position: absolute;
        left: 0px;
        width: 700px;
        height: 100%;
        z-index: 1;
    }

    .enterInnerNew {
        width: 98%;
        height: 100%;
        display: block;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: scroll;
    }

    .enterTopNew {
        width: 700px;
        height: 65%;
        display: flex;
        position: relative;
    }

    .enterBotNew {
        width: 700px;
        height: 35%;
        display: -webkit-flex;
        display: flex;
        position: relative;

    }

    .leftMover {
        position: relative;
        left: 16% !important;
    }

    .EnterpriseTextElementNew {
        width: 90%;
        height: 300px;
        /* border: 1px solid #303030; */
        position: relative;
        top: 19%;
        padding: 7px;
        z-index: 10000;
        /* padding: 9px;
        position: relative;
        text-align: justify;
        overflow-y: auto;
        overflow-x: hidden; */
    }

    .stdBox {
        width: 100%;
        height: auto;
        display: flex;
        /* justify-content: center; */
        align-items: center;
        flex-direction: column;
    }

    .Enterprise_div>img {
        position: absolute;
        left: 0px;
        width: 700px;
        height: 100%;
        z-index: 1;
    }

    .mySiMGHeading {
        position: absolute;
        top: 9%;
        left: 50%;
        font-size: 14px;
        color: #fff;
        /* border: 1px solid red; */
    }

    .mySiMG {
        position: absolute;
        width: 15% !important;
        height: 60px !important;
        left: 31% ! important;
        top: 3%;
        display: block;
    }

    .stddBoxes {
        width: 100%;
        height: auto;
        position: relative;
        top: 60px;
        font-size: 11px;
        padding: 5px;
        text-align: justify;
    }

    .enterData {
        width: 100%;
        height: 32%;
        position: relative;
        top: 22%;
        display: flex;
        cursor: pointer;
    }

    .enterInnerNew::-webkit-scrollbar {
        display: none;
    }

    .enterpriseClose {
        width: 20px;
        height: 20px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: -8px;
        right: -10px;
        /* background-color: #ff7200; */
        border-radius: 50%;
        /* overflow: visible; */
        z-index: 9999;
    }

    .enterSpan {
        font-size: 9px;
        font-weight: 300;
        color: #F5F5F5 !important;
    }

    .sideArrow {
        width: 90%;
        height: 20px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: -30px;
    }

    .arrowEnt {
        color: #ff7200;
    }

    .proMobImg {
        width: 18% ! important;
        height: 82% ! important;
        position: relative !important;
        top: 0px !important;
        right: 0px !important;
        bottom: 20px !important;
        left: 0px !important;
    }

    .api-div500 {
        width: 100%;
        height: 1000px;
        position: absolute;
        top: 70px;
        display: block;
        background-color: #161616;
    }



    .apicard500 {
        width: 100%;
        height: 78%;
        position: absolute;
        top: 505px;
        overflow: hidden;
        border-radius: 0px !important;
    }

    .apicard500 .swiper-container {
        width: 100%;
        height: 100%;
        top: -40px;
        left: 2px;
        position: absolute;
    }

    .apicard500 .swiper-wrapper {
        width: 100%;
        height: 100%;
        position: relative;
    }

    .apicard500 .swiper-slide {
        background: black;
        width: 180px !important;
        height: 390px !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        top: 45px;
        box-shadow: none;
        margin-right: 5px !important;
    }


    .apicard500 .myCard {
        width: 100%;
        height: 100%;
        display: flex;
        text-align: center;
    }

    .apicard500 .swiper-pagination {
        display: none;
    }

    .apicard500 .swiper-button-next {
        display: block !important;
        color: rgb(255, 219, 219);
        position: absolute;
        top: 220px !important;
    }

    .apicard500 .swiper-button-prev {
        display: block !important;
        color: white;
        position: absolute;
        top: 220px !important;
    }

    .apinew_img {
        width: 100%;
        height: 156px;
        position: absolute;
        left: 0px;
        top: 0px;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .apinew_img img {
        width: 80% !important;
        margin: auto 10%;
        height: 70%;
        position: absolute;
        top: 15px;
        left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .apinew_data {

        position: relative;
        top: 170px;
        display: flex;
        flex-direction: column;
    }

    .apinew_data h2 {
        color: #ff7200;
        font-size: 12px;
        position: absolute;
        width: 100%;
        left: 0px;
        height: 45px;
        top: -15px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        margin-top: 0px;
        line-height: 14px;
    }

    .apinew_data p {
        position: relative;
        top: -10px;
        color: white;
        font-size: 10px;
        font-weight: 300;
        margin-top: 40px;
        padding-left: 8px;
        padding-right: 8px;
    }

    .home_page_footer {
        /* position: absolute;
        top: 5328px; */
        position: relative;
        width: 100%;
    }
}

/* media query 13 */

@media only screen and (max-width:500px) {

    .mobileFont {
        font-size: 0.5rem !important;
    }

    body {
        width: 100%;
        height: 100%;
        display: inline;
        z-index: -1;

    }

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #ff7200;
        border-radius: 7px;

    }


    #lll {
        position: relative;
        left: 30px;
        font-size: 6px;
        font-weight: lighter;
    }

    .main_description {
        height: auto;
    }

    .apiFeatures {
        flex-direction: column;
    }

    .apiimg1 {
        width: 3rem;
    }

    .video_part {
        width: 100%;
        /* height: 480px; */
        height: 449px;
        position: relative;
        top: 0px;
        z-index: -2;
    }

    .video_part .headings {
        width: 100%;
        height: 79%;
        position: relative;
        top: 20px;
    }

    video {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50px;
        object-fit: initial;
        z-index: 1;
    }


    .three_btns_for_videos {
        width: 6%;
        height: 30px;
        display: flex;
        position: relative;

        top: 395px;
        color: #ff7200;
        justify-content: center;
        align-items: center;
        justify-content: space-between;
        z-index: 10;



    }

    .three_btns_for_videos button {

        width: 7px;
        height: 7px;
        border-radius: 12px;
        border: none;

    }


    .banner_headings {
        width: 74%;
        height: 20%;
        color: #ff7200;

        bottom: 200px;
        position: relative;
        left: 32px;
        z-index: 10;

        /*  border: 0.2px solid red;  */
    }

    .banner_headings h4 {
        font-size: 32px;
        font-weight: 700;
        text-shadow: 1px 5px 1px black;
        font-family: 'Montserrat', sans-serif;
        line-height: 27px;
    }


    .banner_headings h6 {
        color: #fff;
        font-size: 10px;
        text-shadow: 1px 4px 1px black;
        position: relative;
        top: 6px;
        left: 2px;
        font-weight: 300;
        font-family: 'Montserrat', sans-serif;
    }



    .banner_btn {
        background-color: #ff7200;
        border: none;
    }


    .Product_heading {
        position: relative;
        text-align: center;
        justify-content: center;
        align-items: center;


    }

    .Product_heading h2 {

        font-size: 30px;
        font-weight: 900;
        text-shadow: none;
        position: relative;
        top: -2px;
        line-height: 90px;
        color: black;
        font-family: 'Montserrat', sans-serif;
        letter-spacing: 0px !important;
        background-image: linear-gradient(#fff, #fff);

        background-repeat: no-repeat;
        line-height: 80px;
        background-size: 0% 4px;
        background-position: bottom;

    }


    .Product_titles {
        display: none
    }

    ;

    .banner_headings {
        width: 62%;
        height: 35%;
        color: #ff7200;
        position: relative;
        bottom: 190px;
        position: relative;
        left: 40px;

        /* border: 0.2px solid red; */
    }

    /*  */
    /*  */
    .banner_video_slider {
        width: 100% !important;
        height: 379px !important;
        position: absolute;
        top: 70px;
        left: 0px;
        /* background-color: antiquewhite; */
        display: flex;
        display: block !important;
        justify-content: center;
        overflow: hidden;
        align-items: center;


    }

    .banner_video_slider .swiper-wrapper {
        width: 100vw !important;
        height: 379px;
        position: absolute;
        left: 60px;
        top: 40px;

    }

    .banner_video_slider .swiper-slide {
        width: 100vw !important;
        height: 100%;
        margin-right: 0px;
    }


    /*  */
    .mobile_heading_div {
        width: 70%;
        height: 120px;
        position: relative;
        left: 18%;
        justify-content: center !important;
        align-items: center;
        text-align: center;
        z-index: 10;

        /* border: 1px solid red; */


    }

    .mobile_heading_div h2 {
        font-size: 36px !important;
        font-weight: 900;
        text-shadow: 1px 2px 1px black;
        font-family: 'Fjalla One', sans-serif !important;
        line-height: 39px;
        position: absolute;
        top: 0px;
        left: 0px !important;
    }

    .mobile_heading_div h4 {
        color: #fff;
        font-size: 11px;
        text-shadow: 1px 2px 1px black;
        font-weight: 500;
        font-family: 'Montserrat', sans-serif;
        position: relative;
        top: 81px !important;
        text-align: center;

    }

    /* 0 */
    .swiper-container-horizontal>.swiper-pagination-bullets,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {

        height: 20px;
        position: absolute;
        top: 390px !important;
        left: 15%;
        justify-content: center;
        align-items: center;
        display: flex;
        z-index: 100;

    }


    .banner_video_slider_inner .swiper-pagination .swiper-pagination-bullets {
        position: absolute;
        top: 220px !important;

    }

    .banner_video_slider_inner .swiper-pagination {
        position: absolute !important;
        top: 200px !important;
        left: 0px !important;
        border: 1px solid #ff7200 !important;
        z-index: 1;
        display: block !important;

    }

    swiper-pagination-current {
        background: #fff;
    }

    .swiper-pagination-bullet-active {
        width: 7px !important;
        height: 7px !important;
        background-color: #FF5000 !important;



    }

    .swiper-pagination-bullet {
        opacity: 1;
        width: 7px !important;
        height: 7px !important;
        background-color: white;
    }

    .banner_video_slider_inner video {
        display: block;
        position: absolute;
        top: 0px;
        left: 12px;
        width: 100%;
        height: 100%;
    }

    .producst_para button {
        width: 105px;
        height: 25px;
        background-color: transparent;
        border: none;
        font-size: 12px;
        border-radius: 14px;
        color: #ff7200;
        position: absolute;
        bottom: 22px;
        right: 143px;


    }

    /* video {
        display: none;

    } */




    /*  */
    /*  */


    .main {
        width: 100%;
        height: 627px;
        position: relative;
        top: 379px;
        overflow: hidden;
        background-color: #ff7200;
        z-index: -1;


    }

    .Product_titles {
        display: none;

    }

    .sub {
        display: none;
    }

    .products_swiper {
        width: 100%;
        height: 560px;
        position: absolute;
        top: 8px;
        display: block;
    }


    .swiper-container1 {
        width: 100vw;
        height: 344px;
        position: relative;
        top: 185px;
        /* left: 70px; */
        left: 5px !important;
        overflow: visible;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;



    }

    .swiper-container1 .swiper-slide {
        position: relative;
        left: 2%;
        width: 93% !important;
        height: 540px !important;
        transition: all 200ms linear;
        background-color: #fff !important;
        box-shadow: none;
        margin-right: 10px;
        border-radius: 7px;
        display: flex;
        flex-direction: column;
        overflow: visible !important;

    }



    #file {
        width: 85% ! important;
        height: 3px;
        position: absolute;
        bottom: -122px;
        left: 25px;
        background: transparent;



    }

    progress::-webkit-progress-bar {
        background-color: lightgray;
        width: 100%;
    }

    progress::-webkit-progress-value {
        background-color: black !important;
    }

    progress {
        -webkit-appearance: none;
        appearance: none;
    }

    #numbers_progress {
        position: absolute;
        bottom: -137px;
        left: 44%;
    }


    progress::-webkit-progress-value {
        background: black;
    }

    progress::-moz-progress-bar {
        background: white;
    }


    .swiper-slide-duplicate-next {
        background-color: white !important;
    }

    .swiper-button-next {
        color: #ff7200;
        display: none;
    }

    .swiper-button-prev {
        color: #ff7200;
        display: none;
    }

    .swiper-container1 .swiper-pagination {
        position: absolute;
        top: 348px;
        z-index: 10;
        display: none;
    }






    .sub {
        display: none;

    }

    /*  */

    .achievements_div {
        width: 100%;
        height: 433px;
        /* position: absolute;
        top: 1165px; */
        position: relative;
        background-color: #070707;
    }

    .achievements_div h1 {
        font-size: 30px;
        position: relative;
        top: -4px;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px !important;
        background-image: linear-gradient(#fff, #fff);
        background-repeat: no-repeat;
        line-height: 90px;
        background-size: 0% 4px;
        background-position: bottom;
    }

    .achivementElem {
        width: 98%;
        height: 62%;
        margin-left: auto;
        margin-right: auto;
    }

    .achiveR1 {
        width: 100%;
        height: 50%;
        display: flex;
    }

    .achieverc1 {
        width: 20%;
        height: 100%;
        margin-right: 2px;
        position: relative;
    }

    .achieveImg {
        width: 100%;
        height: 100%;
        position: relative;
        justify-content: flex-start;
        text-align: left;
        align-items: center;
        z-index: 1;
    }

    .achieve100 {
        position: absolute;
        top: 10px;
        left: 10px;
        font-size: 0.8rem;
        font-weight: 600;
        color: #ff7200;
        z-index: 10;
    }

    .achieveClient {
        position: absolute;
        top: 27px;
        left: 10px;
        font-size: 0.55rem;
        font-weight: 500;
        z-index: 10;
    }

    .achieveInstall {
        position: absolute;
        top: 39px;
        left: 10px;
        font-size: 0.55rem;
        font-weight: 500;
        z-index: 10;
    }

    .achieverc2 {
        width: 30%;
        height: 100%;
        margin-right: 2px;
    }

    .achiveI1 {
        width: 100%;
        height: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #ff7200;
        box-shadow: 0px 0px 24px black inset;
        margin-bottom: 2px;
    }

    .threefive {
        font-size: 0.8rem;
        color: #fff;
        font-weight: 600;
        position: relative;
        /* top: 10px; */
    }

    .iot {
        font-size: 0.53rem;
        font-weight: 400;
        color: #fff;
        position: relative;
        top: 4px;
    }

    .achiveI2 {
        width: 100%;
        height: 57%;
        position: relative;
    }

    .vehicle_text {
        color: #ff7200;
        font-size: 1.5rem;
        font-weight: 600;
        position: absolute;
        z-index: 10;
        top: 5%;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .vehicle_sub_text {
        color: #fff;
        position: absolute;
        z-index: 10;
        font-size: 0.3rem;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .achieverc3 {
        width: 20%;
        height: 100%;
        margin-right: 2px;
    }

    .achieve50 {
        width: 100%;
        height: 49%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #464444;
        box-shadow: inset 1px 1px 22px #000;
        margin-bottom: 2px;
    }

    .achieve50b {
        width: 100%;
        height: 49%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #d3d3d3;
        box-shadow: inset 1px 1px 19px #000;
        margin-bottom: 2px;
    }

    .m100m {
        color: #ff7200;
        font-size: 0.8rem;
        font-weight: 600;
    }

    .transactions {
        color: #fff;
        position: relative;
        bottom: 3px;
        font-size: 0.3rem;
        font-weight: 400;
    }

    .img_overlay {
        position: absolute !important;
    }

    .overlay_main_text {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -70%);

        z-index: 10;
        color: #ff7200;
        font-size: .5rem;
        font-weight: 600;
    }

    .overlay_sub_text {
        color: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, 0);
        font-size: 0.3rem;
        z-index: 10;
    }

    .m100black {
        color: #000;
        font-size: 0.8rem;
        font-weight: 600;
    }

    .transactionsm {
        color: #000;
        position: relative;
        bottom: 7px;
        font-size: 0.55rem;
    }

    .achieveI31 {
        width: 100%;
        height: 48%;

        margin-bottom: 2px;
    }

    .achieveI32 {
        width: 100%;
        height: 49%;
        margin-bottom: 2px;
    }

    .achieverc4 {
        width: 30%;
        height: 100%;
    }

    .achiveR2 {
        width: 100%;
        height: 50%;
        display: flex;
        position: relative;
    }

    .ach5 {
        width: 29%;
        height: 100%;
        margin-left: 2px;
        margin-top: 2px;
        position: relative;
    }

    .fifteen {
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 10;
    }

    .fifteenTrips {
        position: absolute;
        top: 25px;
        left: 9px;
        z-index: 10;
        font-size: 0.55rem;
    }

    .ach6 {
        width: 21%;
        height: 100%;
        margin-top: 2px;
        position: relative;
    }

    .ach7 {
        width: 20%;
        height: 100%;
        margin-top: 2px;
        margin-left: 2px;
        position: relative;
    }

    .m1000 {
        color: #fff;
        position: absolute;
        top: 10px;
        left: 8px;
        font-size: 0.8rem;
        z-index: 10;
    }

    .lynkassist {
        color: #fff;
        position: absolute;
        top: 26px;
        left: 8px;
        font-size: 0.52rem;
        z-index: 10;
    }

    .lynkassistLoc {
        color: #fff;
        position: absolute;
        top: 37px;
        left: 8px;
        font-size: 0.52rem;
        z-index: 10;
    }

    .ach8 {
        width: 30%;
        height: 100%;
        margin-left: 2px;
        margin-top: 2px;
        display: flex;
        position: relative;
    }

    .m3 {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 10;
        color: #ff7200;
        font-size: 1.5rem;
        font-weight: 600;
    }

    .rfid_cir {
        color: #fff;
        position: absolute;
        top: 40px;
        left: 10px;
        font-size: 0.75rem;
        z-index: 10;
    }

    .lynkit_testomonial {
        width: 100%;
        height: 410px !important;
        /* position: absolute;
        top: 1540px; */
        position: relative;
    }

    /*  */
    .Enterprise_div {
        width: 100%;
        height: 410px !important;
        /* position: absolute;
        top: 1905px; */
        position: relative;
        display: flex;
        justify-content: center;

    }

    .Enterprise_div h1 {
        font-size: 30px;
        color: #ff7200;
        text-shadow: none;
        position: absolute;
        top: -8px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        background-image: linear-gradient(#fff, #fff);
        background-repeat: no-repeat;
        line-height: 88px;
        background-size: 0% 4px;
        background-position: bottom;
        letter-spacing: 0px !important;
    }



    .enterprise_main_div {
        width: 100%;
        height: 500px;
        /* background-color: aqua; */
        position: relative;
        top: 150px;
        display: flex;

    }


    .enterprise_left1 {
        display: none;
    }

    .enterprise_left2 {
        display: block;
        background-color: #070707;
    }

    /*  */

    .enterprise_left2 .swiper-container {
        width: 100%;
        height: 350px;
        background-color: transparent !important;
        position: absolute;
        top: 122px;
        left: 4px;
        overflow: visible !important;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

    }

    .enterprise_left2 .swiper-container .swiper-slide {
        background-position: center !important;
        background-size: cover !important;
        position: relative;
        left: 0px;
        width: 205px !important;
        height: 320px !important;
        background: #1c1c1c;
        transition: all 200ms linear;
        margin-right: 5px;
        background-color: #141414;
        box-shadow: none;






    }


    .enterprise_left2 .swiper-container .swiper-slide img {
        width: 51% !important;
        height: 148px !important;
        position: relative;
        bottom: 39px;
        left: 10px;

    }

    .enterprise_left2 .swiper-container .swiper-slide .initial_data .initial_data_img {
        width: 45% !important;
        height: 110px !important;
        position: relative;
        bottom: 39px;
        left: 10px;
    }






    .swiper-slide h2 {
        font-size: 21px;
        color: #ff7200;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;





    }

    .enterprise_left2 button {
        width: 102px;
        height: 23px;
        background-color: black;
        border-radius: 12px;
        border: none;
        color: white;
        font-size: 11px;
        position: relative;
        top: 17px;
        font-family: 'Fjalla One', sans-serif;
    }

    /*  */

    .swiper-slide.swiper-slide-active {
        z-index: 11 !important;

    }

    .flip-card-inner {
        position: absolute;
        top: -27px;
        left: -49px;
        width: 300px;
        height: 375px;
        text-align: center;
        transition: transform 0.5s;
        transform-style: preserve-3d;
        transform: rotateY(180deg);
        animation: opacity 1s;
        animation-fill-mode: forwards;
        animation-timing-function: linear;
        animation-delay: 0s;
        animation-iteration-count: 1;
        background-color: transparent !important;

    }

    @keyframes opacity {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 1;
        }
    }


    .active_para {
        width: 100%;
        height: 100%;
        background-color: #ff7200;
        position: absolute;
        top: 0px;
        background-color: #fff;
        transform: rotateY(180deg);
    }

    .para_div {
        width: 90%;
        height: 100%;
        position: relative;
        top: 0px;
        margin-left: auto;
        margin-right: auto;
        background-color: #fff;
        animation: opacity2 0.1s;
        animation-fill-mode: forwards;
        animation-timing-function: linear;
        animation-delay: 0s;
        animation-iteration-count: 1;


    }

    @keyframes opacity2 {
        0% {
            opacity: -1;
            z-index: -1;
        }

        50% {
            opacity: -1;
        }

        100% {
            opacity: 1;
            z-index: 1;
        }



    }

    .active_para p {
        color: black;
        padding-top: 45px;
        font-size: 11px;
        text-align: justify;

    }

    .active_para h4 {
        color: #ff7200 !important;
        font-weight: 900;
        position: relative;
        top: 26px;
        font-size: 21px;
    }

    .swiper-button-next {
        color: #fff;
    }

    .swiper-button-prev {
        color: #fff;
    }

    .enterprise_left2 .swiper-pagination {
        position: absolute;
        top: 340px;
        z-index: 10;
        display: block;
    }

    .enterprise_left2 .swiper-pagination {
        position: absolute;
        top: 375px !important;
        left: 0px;
        z-index: 10;

    }

    swiper-pagination-current {
        background: #fff;
    }

    .enterprise_left2 .swiper-pagination-bullet-active {
        width: 8px;
        height: 8px;
        background-color: #ff7200 !important;



    }

    .enterprise_left2 .swiper-pagination-bullet {
        opacity: 1;
        background-color: #fff;
    }

    #close_button {
        width: 30px;
        height: 0px;
        position: absolute;
        top: 0px;
        right: -18px;
        background-color: #fff !important;
        color: black;
        font-size: 17px;
        font-weight: bold !important;

    }

    /*  */
    .customer_div1 {
        width: 100%;
        /* height: 900px; */
        height: 980px;
        /* position: absolute;
        top: 2264px; */
        position: relative;
        display: flex;
        justify-content: center;
    }

    .customer_div1 h1 {
        font-size: 30px;
        color: black;
        text-shadow: none;
        position: absolute;
        top: 47px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-image: linear-gradient(#ff7200, #ff7200);
        background-repeat: no-repeat;
        line-height: 80px;
        background-size: 0% 4px;
        background-position: bottom;
        letter-spacing: 0px !important;


    }

    .customers_div_1 {
        width: 98%;
        height: 110px;

        position: absolute;
        top: 148px;
        display: flex;
        justify-content: space-around;

    }

    .customers_div_1 div {
        width: 24%;
        height: 110px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }



    .customers_div_1 img:hover {
        cursor: pointer;
    }


    .customers_div_2 {
        width: 98% !important;
        height: 110px;

        position: absolute;
        top: 280px;
        display: flex;
        justify-content: space-around;

    }

    .customers_div_2 div {
        width: 24% !important;
        height: 110px !important;

        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }


    .customers_div_3 {
        width: 98%;
        height: 110px !important;

        position: absolute;
        top: 412px;
        display: flex;
        justify-content: space-around;

    }

    .customers_div_3 div {
        width: 24% !important;
        height: 110px !important;

        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }


    .customers_div_4 {
        width: 98% !important;
        height: 110px !important;
        position: absolute;
        top: 544px;
        display: flex;
        justify-content: space-around;

    }

    .customers_div_4 div {
        width: 24%;
        height: 110px !important;

        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }

    .customers_div_5 {
        width: 98% !important;
        height: 110px !important;
        position: absolute;
        top: 678px;
        display: flex;
        justify-content: space-around;
    }

    .customers_div_5 div {
        width: 24% !important;
        height: 110px !important;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }

    .customers_div_6 {
        width: 98% !important;
        height: 110px !important;
        position: absolute;
        top: 808px;
        display: flex;
        justify-content: space-around;
    }

    .customers_div_6 div {
        width: 24% !important;
        height: 110px !important;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }

    .customer_images {
        width: 80px;
    }

    #konike {
        width: 70px;
        height: 80px;
    }

    #cma {
        width: 95px;
        height: 125px;
    }

    #bharat {
        width: 70px;
        height: 80px;
    }

    #aditya {
        width: 80px;
        height: 72px;
    }

    #lg {
        width: 70px;
        height: 80px;
    }

    #dadri {
        width: 70px;
        height: 79px;
    }


    #more_3500 {
        width: 100%;
        height: 66px;
        position: relative;
        top: 875px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 8;
    }

    #more_3500 h1 {
        position: relative;
        font-size: 23px;
        color: black;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
        background-image: linear-gradient(white, white);
        background-repeat: none;
        background-size: 0% 0px;
        font-family: 'Fjalla One', sans-serif;
    }

    .whatsNew500 {
        width: 100%;
        height: 515px;
        /* position: absolute;
        top: 3242px; */
        position: relative;
    }

    .whatsNew500 h1 {
        font-size: 30px;
        z-index: 1;
        color: #ff7200;
        position: relative;
        bottom: 520px !important;
        align-items: center;
        text-align: center;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px !important;
        background-image: linear-gradient(#fff, #fff);
        letter-spacing: 0px !important;
        line-height: 100px;
        background-position: bottom;
    }

    .whatsNew500 img {
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .cardNew {
        width: 100%;
        height: 78%;
        position: absolute;
        bottom: 8px;
        overflow: hidden;
        border-radius: 0px !important;
    }

    .cardNew .swiper-container {
        width: 100%;
        height: 100%;
        top: -40px;
        left: 2px;
        position: absolute;
    }

    .cardNew .swiper-wrapper {
        width: 100%;
        height: 100%;
        position: relative;
    }

    .cardNew .swiper-slide {
        background: #141414;
        width: 180px !important;
        height: 390px !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        top: 20px;
        box-shadow: none;
        margin-right: 5px !important;
    }


    .cardNew .myCard {
        width: 100%;
        height: 100%;
        display: flex;
        text-align: center;
    }

    .cardNew .swiper-pagination {
        display: none;
    }

    .cardNew .swiper-button-next {
        display: block !important;
        color: rgb(255, 219, 219);
        position: absolute;
        top: 220px !important;
    }

    .cardNew .swiper-button-prev {
        display: block !important;
        color: white;
        position: absolute;
        top: 220px !important;
    }

    .new_img {
        width: 100%;
        height: 156px;
        position: absolute;
        left: 0px;
        top: 0px;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .whatsNew3cards .swiper-slide-active img {
        position: relative;
        top: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .new_img img {
        width: 100%;
        height: 70%;
        position: absolute;
        top: 29px;
        left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .new_data {

        position: relative;
        top: 175px;
        display: flex;
        flex-direction: column;
    }

    .new_data h2 {
        color: #ff7200;
        font-size: 12px;
        position: absolute;
        left: 0px;
        top: -15px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        margin-top: 0px;
        line-height: 14px;
    }

    .new_data p {
        position: relative;
        top: -10px;
        color: white;
        font-size: 10px;
        font-weight: 300;
        margin-top: 65px;
        padding-left: 8px;
        padding-right: 8px;
    }

    #learn_more {
        position: relative;
        top: -22px;
        color: #ff7200;
        font-size: 10px;

    }

    .whatsNew3cards .swiper-button-next {
        display: block !important;
        color: rgb(255, 219, 219);
        position: absolute;
        top: 220px !important;
        right: -72px !important;
    }


    .whatsNew3cards .swiper-button-prev {
        display: block !important;
        color: white;
        position: absolute;
        top: 220px !important;
        left: 76px !important;
    }


    .whatsNew3cards .swiper-pagination {
        display: none;
    }


    .lynkit_feed {
        width: 100%;
        /* height: 1010px; */
        height: 1080px;
        background-color: rgb(22, 22, 22);
        /* position: absolute; */
        /* background-color: rgb(224, 223, 223); */
        /* top: 4079px !important; */
        position: relative;
    }

    .lynkit_feed h1 {
        color: #ff7200;
        position: relative;
        top: 20px;
        align-items: center;
        text-align: center;
        letter-spacing: 0px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 1px;
        font-size: 30px;

    }

    .feed_inner {
        width: 90%;
        height: 540px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 70px;
        display: flex;
    }


    .feed_right {
        width: 100%;
        height: 720px;
        /* border: 1px solid red; */
    }

    .awardsSection {
        height: 350px;
        position: relative;
        /* top: 3269px; */
    }

    .awardsInner {
        height: 100%;
    }

    .awardsHeading {
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: center;
    }

    .awardsSpans {
        font-size: 30px;
        color: #000;
        position: relative;
        top: 4px;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-size: 0% 4px;
        background-position: bottom;
    }

    .awardsListing {
        width: 100%;
        height: auto;
        position: relative;
        top: 2px;
        align-items: center;
        /* background-color: rgb(0 0 0 / 2%); */
    }

    .awardBox {
        width: 20%;
        height: 74%;
        margin-left: 0px;
        position: relative;
    }

    .awardsRow1 {
        width: 81%;
        height: 110px;
        display: flex;
        justify-content: center;
        justify-content: space-around !important;
    }

    .proMobTop {
        width: 100%;
        height: 17%;
        background-color: #000;
        border: 2px solid #383838;
    }

    .awardBox2 {
        width: 47% ! important;
        height: 100%;
        margin-left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .mobProductHead {
        font-size: 30px;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px !important;
        background-image: linear-gradient(#fff, #fff);
        letter-spacing: 1px !important;
        background-repeat: no-repeat;
        line-height: 100px;
        background-size: 0% 4px;
        color: #fff;
        background-position: bottom;
    }

    .mainMobile {
        width: 100%;
        height: 720px;
        /* position: absolute;
        top: 448px; */
        position: relative;
        overflow: hidden;
        z-index: 2;
    }

    .swiper-container1 {
        width: 100vw;
        height: 350px;
        position: absolute;
        top: 34%;
        left: 68px;
        overflow: visible;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .proMobImage {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center !important;
        align-items: center ! important;
        padding: 20px !important;
        padding-top: 4px !important;
    }

    .bodyImagee {
        /* width: 85% !important; */
        width: 50% !important;
        height: 75% ! important;
        position: relative !important;
        top: 0px !important;
        left: 0% !important;
    }

    .bodyTextt {
        font-size: 10.5px;
        margin-top: 20px;
    }

    .bodyImageeSmall {
        width: 81% !important;
        height: 25% ! important;
        position: relative !important;
        top: 0px !important;
        left: 0% !important;
    }

    .PEnterDegign {
        width: 100%;
        height: 60%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        position: relative;
        top: 22%;
        overflow: visible;
    }

    .enterInnerNew {
        width: 98%;
        height: 100%;
        display: block;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: scroll;
    }

    .enterTopNew {
        width: 700px;
        height: 68%;
        display: flex;
        position: relative;
    }

    .enterBotNew {
        width: 700px;
        height: 30%;
        display: -webkit-flex;
        display: flex;
        position: relative;

    }

    .leftMover {
        position: relative;
        left: 16% !important;
    }

    .EnterpriseTextElementNew {
        width: 98%;
        height: auto;
        /* border: 1px solid #303030; */
        position: relative;
        top: 15%;
        padding: 0px;
        z-index: 10000;
        /* padding: 9px;
        position: relative;
        text-align: justify;
        overflow-y: auto;
        overflow-x: hidden; */
    }

    .stdBox {
        width: 100%;
        height: auto;
        display: flex;
        /* justify-content: center; */
        align-items: center;
        flex-direction: column;
    }

    .Enterprise_div>img {
        position: absolute;
        left: 0px;
        width: 700px;
        height: 100%;
        z-index: 1;
    }

    .mySiMGHeading {
        position: absolute;
        top: 6%;
        left: 48%;
        font-size: 13px;
        color: #fff;
        /* border: 1px solid red; */
    }

    .mySiMG {
        position: absolute;
        width: 15% !important;
        height: 58px !important;
        left: 29% ! important;
        top: 2%;
        display: block;
    }

    .stddBoxes {
        width: 100%;
        height: 300px;
        position: relative;
        top: 60px;
        overflow: scroll;
        padding: 5px;
        text-align: justify;
        padding-top: 12px;
    }

    .enterData {
        width: 100%;
        height: 32%;
        position: relative;
        top: 22%;
        display: flex;
        cursor: pointer;
    }

    .enterInnerNew::-webkit-scrollbar {
        display: none;
    }

    .enterpriseClose {
        width: 20px;
        height: 20px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: -8px;
        right: -1px;
        /* background-color: #ff7200; */
        border-radius: 50%;
        /* overflow: visible; */
        z-index: 9999;
    }

    .enterSpan {
        font-size: 9.6px;
        font-weight: 300;
        color: #F5F5F5 !important;
    }

    .entImg {
        width: 25% !important;
        height: 65% !important;
        position: relative;
        left: 5% !important;
    }

    .leftMover {
        position: relative;
        left: 14% ! important;
    }

    .proMobImg {
        width: 18% ! important;
        height: 82% ! important;
        position: relative !important;
        top: 0px !important;
        right: 0px !important;
        bottom: 20px !important;
        left: 0px !important;
    }

    .proMobHead {
        width: 100%;
        height: 60px;
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;
        justify-content: center !important;
        align-items: center !important;
        position: relative !important;
    }

    .proMobImg {
        width: 18% ! important;
        height: 80% ! important;
        position: relative !important;
        top: 0px !important;
        right: 0px !important;
        bottom: 0px !important;
        left: 0px !important;
    }

    .mobImages {
        width: 9% !important;
        height: 33px !important;
        position: relative !important;
        top: 0px !important;
        bottom: 20px !important;
        padding-right: 5px;
    }

    .api-div500 {
        width: 100%;
        height: 1000px;
        position: absolute;
        top: 70px;
        display: block;
        background-color: #161616;
    }



    .apicard500 {
        width: 100%;
        height: 78%;
        position: absolute;
        top: 505px;
        overflow: hidden;
        border-radius: 0px !important;
    }

    .apicard500 .swiper-container {
        width: 100%;
        height: 100%;
        top: -40px;
        left: 2px;
        position: absolute;
    }

    .apicard500 .swiper-wrapper {
        width: 100%;
        height: 100%;
        position: relative;
    }

    .apicard500 .swiper-slide {
        background: black;
        width: 180px !important;
        height: 390px !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        top: 45px;
        box-shadow: none;
        margin-right: 5px !important;
    }


    .apicard500 .myCard {
        width: 100%;
        height: 100%;
        display: flex;
        text-align: center;
    }

    .apicard500 .swiper-pagination {
        display: none;
    }

    .apicard500 .swiper-button-next {
        display: block !important;
        color: rgb(255, 219, 219);
        position: absolute;
        top: 220px !important;
    }

    .apicard500 .swiper-button-prev {
        display: block !important;
        color: white;
        position: absolute;
        top: 220px !important;
    }

    .apinew_img {
        width: 100%;
        height: 156px;
        position: absolute;
        left: 0px;
        top: 0px;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .apinew_img img {
        width: 80% !important;
        margin: auto 10%;
        height: 70%;
        position: absolute;
        top: 15px;
        left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .apinew_data {

        position: relative;
        top: 170px;
        display: flex;
        flex-direction: column;
    }

    .apinew_data h2 {
        color: #ff7200;
        font-size: 12px;
        position: absolute;
        width: 100%;
        left: 0px;
        height: 45px;
        top: -15px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        margin-top: 0px;
        line-height: 14px;
    }

    .apinew_data p {
        position: relative;
        top: -10px;
        color: white;
        font-size: 10px;
        font-weight: 300;
        margin-top: 40px;
        padding-left: 8px;
        padding-right: 8px;
    }

    .home_page_footer {
        /* position: absolute;
        top: 5024px; */
        position: relative;
        width: 100%;
    }


}

/* media query 14 */
@media only screen and (max-width:440px) {
.banner_headings_messgae {
    /* width: 330px; */
    bottom: 57%;
    /* left: 5%; */
}
}
@media only screen and (max-width:415px) {


    body {
        width: 100%;
        height: 100%;
        display: inline;
        z-index: -1;

    }

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #ff7200;
        border-radius: 7px;

    }


    #lll {
        position: relative;
        left: 30px;
        font-size: 6px;
        font-weight: lighter;
    }


    .video_part {
        width: 100%;
        /* height: 450px; */
        height:398px;
        position: relative;
        top: 0px;
        z-index: -2;
    }

    .video_part .headings {
        width: 100%;
        height: 79%;
        position: relative;
        top: 20px;
    }

    video {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50px;
        object-fit: initial;
        z-index: 1;
    }


    .three_btns_for_videos {
        width: 8%;
        height: 30px;
        display: flex;
        position: relative;

        top: 375px;
        color: #ff7200;
        justify-content: center;
        align-items: center;
        justify-content: space-between;
        z-index: 10;
    }

    .three_btns_for_videos button {

        width: 7px;
        height: 7px;
        border-radius: 12px;
        border: none;

    }


    .banner_headings {
        width: 74%;
        height: 20%;
        color: #ff7200;

        bottom: 160px;
        position: relative;
        left: 32px;
        z-index: 10;

        /*  border: 0.2px solid red;  */
    }

    .banner_headings h4 {
        font-size: 30px;
        font-weight: 700;
        text-shadow: 1px 5px 1px black;
        font-family: 'Montserrat', sans-serif;
        line-height: 27px;
    }


    .banner_headings h6 {
        color: #fff;
        font-size: 9px;
        text-shadow: 1px 4px 1px black;
        position: relative;
        top: 6px;
        left: 2px;
        font-weight: 300;
        font-family: 'Montserrat', sans-serif;
    }


    .banner_btn {
        background-color: #ff7200;
        border: none;
    }

    .Product_heading {
        position: relative;
        text-align: center;
        justify-content: center;
        align-items: center;


    }

    .Product_heading h2 {

        font-size: 23px;
        font-weight: 700;
        text-shadow: none;
        position: relative;
        top: -19px;
        line-height: 90px;
        color: black;
        font-family: 'Montserrat', sans-serif;
        letter-spacing: 1px;
        background-image: linear-gradient(#fff, #fff);

        background-repeat: no-repeat;
        line-height: 80px;
        background-size: 0% 4px;
        background-position: bottom;

    }


    .Product_titles {
        display: none
    }

    ;

    .banner_headings {
        width: 64%;
        height: 35%;
        color: #ff7200;

        bottom: 170px;
        position: relative;
        left: 35px;

        /* border: 0.2px solid red; */
    }


    .Product_heading {
        position: relative;
        text-align: center;
        justify-content: center;
        align-items: center;



    }

    .Product_heading h2 {

        font-size: 26px;
        font-weight: 900;
        text-shadow: none;
        position: relative;
        top: 0px;
        line-height: 90px;
        color: black;
        font-family: 'Montserrat', sans-serif;
        letter-spacing: 0px !important;
        background-image: linear-gradient(#fff, #fff);

        background-repeat: no-repeat;
        line-height: 80px;
        background-size: 0% 4px;
        background-position: bottom;

    }


    .Product_titles {
        display: none
    }

    ;



    .banner_headings h4 {
        font-size: 30px;
        font-weight: 700;
    }


    .banner_headings h6 {
        color: #fff;
        font-size: 9px;
        position: relative;
        top: -3px;
        left: 2px;
    }

    /*  */
    /*  */


    .banner_video_slider {
        width: 100% !important;
        height: 329px !important;
        position: absolute;
        top: 70px;
        left: 0px;
        /* background-color: antiquewhite; */
        display: flex;
        display: block !important;
        justify-content: center;
        overflow: hidden;
        align-items: center;



    }

    .banner_video_slider .swiper-wrapper {
        width: 100vw !important;
        height: 329px;
        position: absolute;
        left: 60px;
        top: 40px;

    }

    .banner_video_slider .swiper-slide {
        width: 100vw !important;
        height: 100%;
        margin-right: 0px;
    }


    /*  */
    .mobile_heading_div {
        width: 70%;
        height: 100px;
        position: relative;
        left: 18%;
        justify-content: center !important;
        align-items: center;
        text-align: center;
        z-index: 10;
        /* border: 1px solid red;       */

    }

    .mobile_heading_div h2 {
        font-size: 33px !important;
        font-weight: 700;
        text-shadow: 1px 2px 1px black;
        font-family: 'Fjalla One', sans-serif;
        line-height: 35px;
        position: absolute;
        top: 0px;
        left: 0px !important;
    }

    .mobile_heading_div h4 {
        color: #fff;
        font-size: 10px;
        text-shadow: 1px 1px 1px black;
        font-weight: 500;
        font-family: 'Montserrat', sans-serif;
        position: relative;
        top: -2px !important;
        text-align: center;

    }

    /* 0 */
    .swiper-container-horizontal>.swiper-pagination-bullets,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {

        height: 20px;
        position: absolute;
        top: 340px !important;
        left: 17.5%;
        justify-content: center;
        align-items: center;
        display: flex;
        z-index: 100;

    }


    .banner_video_slider_inner .swiper-pagination .swiper-pagination-bullets {
        position: absolute;
        top: 220px !important;

    }

    .banner_video_slider_inner .swiper-pagination {
        position: absolute !important;
        top: 200px !important;
        left: 0px !important;
        border: 1px solid #ff7200 !important;
        z-index: 1;
        display: block !important;

    }

    swiper-pagination-current {
        background: #fff;
    }

    .swiper-pagination-bullet-active {
        width: 7px !important;
        height: 7px !important;
        background-color: #FF5000 !important;



    }

    .swiper-pagination-bullet {
        opacity: 1;
        width: 7px !important;
        height: 7px !important;
        background-color: white;
    }

    .banner_video_slider_inner video {
        display: block;
        position: absolute;
        top: 0px;
        left: 12px;
        width: 100%;
        height: 100%;
    }

    .producst_para button {
        width: 110px;
        height: 25px;
        background-color: transparent;
        border: none;
        font-size: 12px;

        color: #ff7200;
        position: absolute;
        bottom: 10px;
        right: 138px;


    }

    /* video {
        display: none;

    } */


    .main {
        width: 100%;
        height: 605px;
        position: relative;
        top: 360px;
        background-color: #ff7200;
        z-index: -1;


    }

    .Product_titles {
        display: none;

    }

    .sub {
        display: none;
    }

    .products_swiper {
        width: 100%;
        height: 570px;
        position: absolute;
        top: 8px;
        display: block;
    }


    .swiper-container1 {
        width: 100vw;
        height: 350px;
        position: relative;
        top: 167px;
        left: 80px;
        overflow: visible;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;



    }

    .proMobTop {
        width: 100%;
        height: 20%;
        background-color: #000;
        border: 2px solid #383838;
    }

    .swiper-container1 .swiper-slide {
        position: relative;
        left: 0px;
        width: 380px !important;
        height: 550px !important;
        transition: all 200ms linear;
        background-color: #fff !important;
        box-shadow: none;
        margin-right: 19px;

        border-radius: 7px;
        display: flex;
        flex-direction: column;
        overflow: visible !important;

    }

    #file {
        width: 92%;
        height: 3px;
        position: absolute;
        bottom: -137px;
        left: 5%;
        background: transparent;



    }

    progress::-webkit-progress-bar {
        background-color: lightgray;
        width: 100%;
    }

    progress::-webkit-progress-value {
        background-color: black !important;
    }


    progress {
        -webkit-appearance: none;
        appearance: none;
    }


    #numbers_progress {
        position: absolute;
        bottom: -146px;
        left: 40%;

    }


    progress::-webkit-progress-value {
        background: black;
    }

    progress::-moz-progress-bar {
        background: lightcolor;
    }


    .swiper-slide-duplicate-next {
        background-color: white !important;
    }

    .swiper-button-next {
        color: #ff7200;
        display: none;
    }

    .swiper-button-prev {
        color: #ff7200;
        display: none;
    }

    .swiper-container1 .swiper-pagination {
        display: none;
    }


    .Product_titles a {
        color: #fff;
        font-size: 22px;
        padding-top: 1px;
        cursor: pointer;
        text-decoration: none;
    }





    .sub {
        display: none;

    }

    /*  */

    .achievements_div {
        width: 100%;
        height: 359px;
        /* position: absolute;
        top: 1120px; */
        position: relative;
        background-color: #070707;
    }

    .achievements_div h1 {
        font-size: 26px;
        position: relative;
        top: 0px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-image: linear-gradient(#fff, #fff);
        letter-spacing: 0px !important;
        background-repeat: no-repeat;
        line-height: 70px;
        background-size: 0% 4px;
        background-position: bottom;
    }

    .achivementElem {
        width: 98%;
        height: 62%;
        margin-left: auto;
        margin-right: auto;
    }

    .achiveR1 {
        width: 100%;
        height: 50%;
        display: flex;
    }

    .achieverc1 {
        width: 20%;
        height: 100%;
        margin-right: 2px;
        position: relative;
    }

    .achieveImg {
        width: 100%;
        height: 100%;
        position: relative;
        justify-content: flex-start;
        text-align: left;
        align-items: center;
        z-index: 1;
    }

    .achieve100 {
        position: absolute;
        top: 10px;
        left: 10px;
        font-size: 0.7rem;
        font-weight: 600;
        color: #ff7200;
        z-index: 10;
    }

    .achieveClient {
        position: absolute;
        top: 23px;
        left: 10px;
        font-size: 0.5rem;
        font-weight: 500;
        z-index: 10;
    }

    .achieveInstall {
        position: absolute;
        top: 32px;
        left: 10px;
        font-size: 0.5rem;
        font-weight: 500;
        z-index: 10;
    }

    .achieverc2 {
        width: 30%;
        height: 100%;
        margin-right: 2px;
    }

    .achiveI1 {
        width: 100%;
        height: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #ff7200;
        box-shadow: 0px 0px 24px black inset;
        margin-bottom: 2px;
    }

    .threefive {
        font-size: 0.7rem;
        color: #fff;
        font-weight: 600;
        position: relative;
        /* top: 10px; */
    }

    .iot {
        font-size: 0.46rem;
        font-weight: 400;
        color: #fff;
        position: relative;
        top: 8px;
    }

    .achiveI2 {
        width: 100%;
        height: 57%;
        position: relative;
    }

    .vehicle_text {
        color: #ff7200;
        font-size: 1rem;
        font-weight: 600;
        position: absolute;
        z-index: 10;
        top: 5%;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .vehicle_sub_text {
        color: #fff;
        position: absolute;
        z-index: 10;
        font-size: 0.2rem;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .achieverc3 {
        width: 20%;
        height: 100%;
        margin-right: 2px;
    }

    .achieve50 {
        width: 100%;
        height: 49%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #464444;
        box-shadow: inset 1px 1px 22px #000;
        margin-bottom: 2px;
    }

    .achieve50b {
        width: 100%;
        height: 49%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #d3d3d3;
        box-shadow: inset 1px 1px 19px #000;
        margin-bottom: 2px;
    }

    .m100m {
        color: #ff7200;
        font-size: 0.6rem !important;
        font-weight: 600;
    }

    .transactions {
        color: #fff;
        position: relative;
        bottom: 3px;
        font-size: 0.2rem;

        font-weight: 400;
    }

    .img_overlay {
        position: absolute !important;
    }

    .overlay_main_text {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -70%);

        z-index: 10;
        color: #ff7200;
        font-size: .4rem;
        font-weight: 600;
    }

    .overlay_sub_text {
        color: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, 0);
        font-size: 0.2rem;
        z-index: 10;
    }

    .m100black {
        color: #000;
        font-size: 0.7rem;
        font-weight: 600;
    }

    .transactionsm {
        color: #000;
        position: relative;
        bottom: 4px;
        font-size: 0.47rem;
    }

    .achieveI31 {
        width: 100%;
        height: 48%;

        margin-bottom: 2px;
    }

    .achieveI32 {
        width: 100%;
        height: 49%;
        margin-bottom: 2px;
    }

    .achieverc4 {
        width: 30%;
        height: 100%;
    }

    .achiveR2 {
        width: 100%;
        height: 50%;
        display: flex;
        position: relative;
    }

    .ach5 {
        width: 29%;
        height: 100%;
        margin-left: 2px;
        margin-top: 2px;
        position: relative;
    }

    .fifteen {
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 10;
    }

    .fifteenTrips {
        position: absolute;
        top: 23px;
        left: 9px;
        z-index: 10;
        font-size: 0.5rem;
    }

    .ach6 {
        width: 21%;
        height: 100%;
        margin-top: 2px;
        position: relative;
    }

    .ach7 {
        width: 20%;
        height: 100%;
        margin-top: 2px;
        margin-left: 2px;
        position: relative;
    }

    .m1000 {
        color: #fff;
        position: absolute;
        top: 10px;
        left: 8px;
        font-size: 0.7rem;
        z-index: 10;
    }

    .lynkassist {
        color: #fff;
        position: absolute;
        top: 25px;
        left: 8px;
        font-size: 0.47rem;
        z-index: 10;
    }

    .lynkassistLoc {
        color: #fff;
        position: absolute;
        top: 35px;
        left: 8px;
        font-size: 0.47rem;
        z-index: 10;
    }

    .ach8 {
        width: 30%;
        height: 100%;
        margin-left: 2px;
        margin-top: 2px;
        display: flex;
        position: relative;
    }

    .m3 {
        position: absolute;
        top: 10px;
        left: 5px;
        z-index: 10;
        color: #ff7200;
        font-size: 1.25rem;
        font-weight: 600;
    }

    .rfid_cir {
        color: #fff;
        position: absolute;
        top: 35px;
        left: 5px;
        font-size: 0.5rem;
        z-index: 10;
    }

    .lynkit_testomonial {
        width: 100%;
        /* height: 490px !important; */
        height: 350px !important;
        /* position: absolute;
        top: 1424px; */
        position: relative;
    }

    .Enterprise_div {
        width: 100%;
        height: 400px !important;
        /* position: absolute;
        top: 1739px; */
        position: relative;
        display: flex;
        justify-content: center;
        overflow: hidden;
    }

    .Enterprise_div h1 {
        font-size: 26px;
        color: #ff7200;
        text-shadow: none;
        position: absolute;
        top: -6px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px !important;
        background-image: linear-gradient(#fff, #fff);
        background-repeat: no-repeat;
        line-height: 80px;
        background-size: 0% 4px;
        background-position: bottom;
    }

    .enterprise_main_div {
        width: 80%;
        height: 550px;
        /*  background-color: aqua; */
        position: relative;
        top: 150px;
        display: flex;



    }

    .enterprise_left1 {
        display: none;
    }

    .enterprise_left2 {
        display: block;
    }

    /*  */

    .enterprise_left2 .swiper-container {
        width: 100%;
        height: 350px;
        background-color: transparent !important;
        position: absolute;
        top: 115px;
        right: 1px;
        overflow: visible !important;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;


    }

    .enterprise_left2 .swiper-container .swiper-slide {
        background-position: center !important;
        background-size: cover !important;
        position: relative;
        left: 0px;
        width: 170px !important;
        height: 320px;

        transition: all 200ms linear;
        background-color: #141414;
        box-shadow: none;


    }





    .enterprise_left2 .swiper-container .swiper-slide .initial_data .initial_data_img {
        width: 51% !important;
        height: 110px !important;
        position: relative;
        top: -39px !important;
        left: -5px !important;
    }




    .swiper-slide h2 {
        font-size: 17px;
        color: #ff7200;
        position: relative;
        left: 0px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;





    }

    .enterprise_left2 button {
        width: 102px;
        height: 23px;
        background-color: black;

        border: none;
        color: white;
        font-size: 11px;
        position: relative;
        top: 33px;
        font-family: 'Fjalla One', sans-serif;


    }

    /*  */

    .homeSlider_swiper > .swiper-slide.swiper-slide-active {
        z-index: 11 !important;

    }

    .active_para h4 {
        color: #ff7200 !important;
        font-weight: 900;
        position: relative;
        font-size: 20px;
        top: 24px;
        line-height: 20px;
        font-family: 'Fjalla One', sans-serif;
    }

    .flip-card-inner {
        position: absolute;
        top: -21px;
        left: -65px;
        width: 300px;
        height: 358px;
        text-align: center;
        transition: transform 0s;
        transform-style: preserve-3d;
        transform: rotateY(180deg);
        animation: opacity 0.5s;
        animation-fill-mode: forwards;
        animation-timing-function: linear;
        animation-delay: 0s;
        animation-iteration-count: 1;
        background-color: transparent !important;


    }

    @keyframes opacity {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 1;
        }
    }


    .active_para {
        width: 100%;
        height: 100%;
        background-color: #ff7200;
        position: absolute;
        top: 0px;
        background-color: #fff;
        transform: rotateY(180deg);


    }

    .para_div {
        width: 85%;
        height: 100%;
        position: relative;
        top: 0px;
        margin-left: auto;
        margin-right: auto;
        background-color: #fff;


    }

    .active_para p {
        color: black;
        padding-top: 35px;
        font-size: 10.3px;
        text-align: justify;
        line-height: 16px;

    }

    .swiper-button-next {
        color: #fff;
    }

    .swiper-button-prev {
        color: #fff;
    }

    .enterprise_left2 .swiper-pagination {
        position: absolute;
        top: 372px !important;
        left: 0px;
        z-index: 10;
        display: block;
    }

    swiper-pagination-current {
        background: #fff;
    }

    .enterprise_left2 .swiper-pagination-bullet-active {
        width: 7px !important;
        height: 7px !important;
        background-color: #FF5000 !important;



    }

    .enterprise_left2 .swiper-pagination-bullet {
        opacity: 1;
        width: 7px !important;
        height: 7px !important;

        background-color: #fff;
    }

    /*  */
    .customer_div1 {
        width: 100%;
        height: 900px;

        /* position: absolute;
        top: 2094px; */
        position: relative;
        display: flex;
        justify-content: center;

    }



    .customer_div1 h1 {

        font-size: 26px;
        color: black;
        text-shadow: none;
        position: absolute;
        top: 47px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-image: linear-gradient(#ff7200, #ff7200);
        background-repeat: no-repeat;
        line-height: 70px;
        background-size: 0% 4px;
        background-position: bottom;
        letter-spacing: 0px !important;


    }

    .customers_div_1 {
        width: 100% !important;
        height: 100px;
        left: 0px;
        position: absolute;
        top: 140px;
        display: flex;
        justify-content: space-around;

    }

    .customers_div_1 div {
        width: 24% !important;
        height: 100px;

        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }

    .customers_div_2 {
        width: 100% !important;
        height: 100px !important;
        position: absolute;
        top: 262px;
        display: flex;
        justify-content: space-around;

    }

    .customers_div_2 div {
        width: 24% !important;
        height: 100px !important;

        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }

    .customers_div_3 {
        width: 100%;
        height: 100px !important;
        position: absolute;
        top: 384px;
        display: flex;
        justify-content: space-around;

    }

    .customers_div_3 div {
        width: 24% !important;
        height: 100px;

        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }

    .customers_div_4 {
        width: 100% !important;
        height: 100px !important;
        position: absolute;
        top: 506px;
        display: flex;
        justify-content: space-around;

    }

    .customers_div_4 div {
        width: 24% !important;
        height: 100px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }

    .customers_div_5 {
        width: 100% !important;
        height: 100px !important;
        position: absolute;
        top: 629px;
        display: flex;
        z-index: 10;
        justify-content: space-around;

    }

    .customers_div_5 div {
        width: 24% !important;
        height: 100px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        /* box-shadow: 4px 4px 10px grey; */
    }

    .customers_div_6 {
        width: 100% !important;
        height: 100px !important;
        position: absolute;
        top: 754px;
        display: flex;
        z-index: 10;
        justify-content: space-around;

    }

    .customers_div_6 div {
        width: 24% !important;
        height: 100px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        /* box-shadow: 4px 4px 10px grey; */
    }

    .customer_images {
        width: 70px;
    }

    #cma {
        width: 80px;
        height: 115px;
    }

    #bharat {
        width: 60px;
        height: 79px;
    }

    #aditya {
        width: 70px;
        height: 64px;
    }

    #lg {
        width: 68px;
        height: 80px;
    }

    #konike {
        width: 60px;
        height: 70px;
    }

    #dadri {
        width: 70px;
        height: 75px;
    }

    #sch {
        width: 55%;
    }

    #more_3500 {
        width: 100%;
        height: 66px;
        position: relative;
        top: 811px;
        z-index: 8;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #more_3500 h1 {
        position: relative;
        font-size: 22px;
        color: black;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
        background-image: linear-gradient(white, white);
        background-repeat: none;
        background-size: 0% 0px;
        font-family: 'Fjalla One', sans-serif;
    }

    /*  */
    .whatsNew500 {
        width: 100%;
        height: 460px;
        /* position: absolute;
        top: 3013px; */
        position: relative;
    }

    .whatsNew500 h1 {
        font-size: 26px;
        z-index: 1;
        color: #ff7200;
        position: relative;
        bottom: 465px !important;
        align-items: center;
        text-align: center;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px !important;
        background-image: linear-gradient(#fff, #fff);
        letter-spacing: 0px !important;
        line-height: 100px;
        background-position: bottom;
    }

    .whatsNew500 img {
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .cardNew {
        width: 100%;
        height: 72%;
        position: absolute;
        bottom: 38px;
        overflow: hidden;
        border-radius: 0px !important;
    }

    .cardNew .swiper-container {
        width: 100%;
        height: 100%;
        top: -40px;
        left: 2px;
        position: absolute;
    }

    .cardNew .swiper-wrapper {
        width: 100%;
        height: 100%;
        position: relative;
    }

    .cardNew .swiper-slide {
        background: #141414;
        width: 180px !important;
        height: 370px !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        top: 20px;
        box-shadow: none;
        margin-right: 5px !important;
    }

    .cardNew .myCard {
        width: 100%;
        height: 100%;
        display: flex;
        text-align: center;
    }

    .cardNew .swiper-pagination {
        display: none;
    }

    .cardNew .swiper-button-next {
        display: block !important;
        color: rgb(255, 219, 219);
        position: absolute;
        top: 220px !important;
    }

    .cardNew .swiper-button-prev {
        display: block !important;
        color: white;
        position: absolute;
        top: 220px !important;
    }

    .new_img {
        width: 100% !important;
        height: 157px !important;
        position: absolute;
        left: 0px;
        top: 0px;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .whatsNew3cards .swiper-slide-active img {
        position: relative;
        top: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .new_img img {
        width: 100% !important;
        height: 70%;
        position: absolute;
        top: 28px;
        left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .new_data {

        position: relative;
        top: 175px;
        display: flex;
        flex-direction: column;
    }

    .new_data h2 {
        color: #ff7200;
        font-size: 12px;
        position: absolute;
        left: 0px;
        top: -20px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        margin-top: 0px;
        line-height: 14px;
    }

    .new_data p {
        position: relative;
        top: -20px;
        color: white;
        font-size: 9px !important;
        font-weight: 300;
        margin-top: 65px;
        padding-left: 8px;
        padding-right: 8px;
    }

    #learn_more {
        position: relative;
        top: -30px;
        color: #ff7200;
        font-size: 10px;

    }
    .bodyTextt {
        font-size: 10px;
        margin-top: 20px;
    }
    .whatsNew3cards .swiper-button-next {
        display: block !important;
        color: rgb(255, 219, 219);
        position: absolute;
        right: -69px !important;
        top: 220px !important;
    }


    .whatsNew3cards .swiper-button-prev {
        display: block !important;
        color: white;
        position: absolute;
        left: 82px !important;
        top: 220px !important;
    }


    .whatsNew3cards .swiper-pagination {
        display: none;
    }


    .lynkit_feed {
        width: 100%;
        /* height: 1010px; */
        height: 1080px;
        background-color: rgb(22, 22, 22);
        /* position: absolute;

        top: 3786px  !important; */
        position: relative;
    }

    .lynkit_feed h1 {
        color: #ff7200;
        position: relative;
        top: 20px;
        align-items: center;
        text-align: center;
        letter-spacing: 0px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 1px;
        font-size: 26px;
        box-shadow: none !important;

    }

    .feed_inner {
        width: 88%;
        height: 540px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 70px;
        display: flex;
        box-shadow: none !important;
    }


    .feed_right {
        width: 92%;
        height: 704px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
    }

    .awardsSection {
        height: 345px;
        position: relative;
        /* top: 3019px; */
    }

    .awardsInner {
        height: 100%;
    }

    .awardsHeading {
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: center;
    }

    .awardsSpans {
        font-size: 26px;
        color: #000;
        position: relative;
        top: 4px;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-size: 0% 4px;
        background-position: bottom;
    }

    .awardsListing {
        width: 100%;
        height: auto;
        position: relative;
        top: 1px;
        align-items: center;
        /* background-color: rgb(0 0 0 / 2%); */
    }

    .awardBox {
        width: 20%;
        height: 74%;
        margin-left: 0px;
        position: relative;
    }

    .awardsRow1 {
        width: 90%;
        height: 110px;
        display: flex;
        justify-content: space-between;
    }

    .awardBox2 {
        width: 51% !important;
        height: 100%;
        margin-left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .active_slide_data11 {
        width: 300px;
        height: 370px !important;
        position: absolute;
        background-color: #fff;
        box-shadow: inset 2px 1px 18px grey;
    }

    .flipCardData {
        height: 100% ! important;
        display: flex;
        flex-direction: column !important;
    }

    .active_imggg {
        width: 100%;
        height: 100% !important;
        position: relative;
        display: flex;
        justify-content: space-evenly !important;
        align-items: center !important;
        flex-direction: column !important;
    }

    .activeImagge {
        width: 50% !important;
        height: 150px ! important;
        position: relative !important;
        top: 1px !important;


    }

    .activeHeading {
        position: relative;
        top: 10px ! important;
        color: #000 ! important;
    }

    .activeBBtn {
        width: auto;
        height: 23px;
        background-color: transparent !important;
        border-radius: 12px;
        border: none;
        color: #ff7200 !important;
        font-size: 12px !important;
        position: relative;
        top: 0px;
        font-family: 'Fjalla One', sans-serif;
    }

    .enterprise_left2 button {
        width: auto;
        height: 23px;
        background-color: transparent !important;
        border-radius: 12px;
        border: none;
        color: #fff;
        font-size: 11px;
        position: relative;
        top: 15px;
        font-family: 'Fjalla One', sans-serif;
    }

    .mainMobile {
        width: 100%;
        height: 735px;
        /* position: absolute;
        top: 398px; */
        position: relative;
        overflow: hidden;
        z-index: 2;
    }

    .proMobData {
        color: #fff;
        font-size: 13px;
        font-weight: 700;
        padding: 4px;
    }

    .swiper-container1 {
        width: 100vw;
        height: 350px;
        position: absolute;
        top: 230px;
        left: 21%;
        overflow: visible;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .proMobImage {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center !important;
        align-items: center ! important;
        padding: 12px !important;
        padding-top: 4px !important;
    }

    .alter501 {
        width: 56%;
        display: flex;
        text-align: start;
    }

    .mobProductHead {
        font-size: 26px;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px !important;
        background-image: linear-gradient(#fff, #fff);
        letter-spacing: 1px !important;
        background-repeat: no-repeat;
        line-height: 100px;
        background-size: 0% 4px;
        color: #fff;
        background-position: bottom;
    }

    .PEnterDegign {
        width: 100%;
        height: 60%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        position: relative;
        top: 22%;
        overflow: visible;
    }

    .enterInnerNew {
        width: 98%;
        height: 100%;
        display: block;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: scroll;
    }

    .enterTopNew {
        width: 700px;
        height: 68%;
        display: flex;
        position: relative;
    }

    .enterBotNew {
        width: 700px;
        height: 26%;
        display: -webkit-flex;
        display: flex;
        position: relative;

    }

    .leftMover {
        position: relative;
        left: 16% !important;
    }

    .EnterpriseTextElementNew {
        width: 98%;
        height: auto;
        /* border: 1px solid #303030; */
        position: relative;
        top: 15%;
        padding: 0px;
        z-index: 10000;
        /* padding: 9px;
        position: relative;
        text-align: justify;
        overflow-y: auto;
        overflow-x: hidden; */
    }

    .stdBox {
        width: 100%;
        height: auto;
        display: flex;
        /* justify-content: center; */
        align-items: center;
        flex-direction: column;
    }

    .Enterprise_div>img {
        position: absolute;
        left: 0px;
        width: 700px;
        height: 100%;
        z-index: 1;
    }

    .mySiMGHeading {
        position: absolute;
        top: 5%;
        left: 48%;
        font-size: 12px;
        color: #fff;
        /* border: 1px solid red; */
    }

    .mySiMG {
        position: absolute;
        width: 15% !important;
        height: 58px !important;
        left: 29% ! important;
        top: 0%;
        display: block;
    }

    .stddBoxes {
        width: 100%;
        height: 300px;
        position: relative;
        top: 60px;
        overflow: scroll;
        padding: 5px;
        text-align: justify;
        padding-top: 0px;
    }

    .enterData {
        width: 100%;
        height: 32%;
        position: relative;
        top: 22%;
        display: flex;
        cursor: pointer;
    }

    .enterInnerNew::-webkit-scrollbar {
        display: none;
    }

    .enterpriseClose {
        width: 20px;
        height: 20px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: -8px;
        right: -1px;
        /* background-color: #ff7200; */
        border-radius: 50%;
        /* overflow: visible; */
        z-index: 9999;
    }

    .enterSpan {
        font-size: 8.6px;
        font-weight: 300;
        color: #F5F5F5 !important;
    }

    .entImg {
        width: 25% !important;
        height: 65% !important;
        position: relative;
        left: 5% !important;
    }

    .leftMover {
        position: relative;
        left: 14% ! important;
    }

    .proMobImg {
        width: 18% ! important;
        height: 82% ! important;
        position: relative !important;
        top: 0px !important;
        right: 0px !important;
        bottom: 20px !important;
        left: 0px !important;
    }

    .api-div500 {
        width: 100%;
        height: 1000px;
        position: absolute;
        top: 70px;
        display: block;
        background-color: #161616;
    }



    .apicard500 {
        width: 100%;
        height: 78%;
        position: absolute;
        top: 505px;
        overflow: hidden;
        border-radius: 0px !important;
    }

    .apicard500 .swiper-container {
        width: 100%;
        height: 100%;
        top: -40px;
        left: 2px;
        position: absolute;
    }

    .apicard500 .swiper-wrapper {
        width: 100%;
        height: 100%;
        position: relative;
    }

    .apicard500 .swiper-slide {
        background: black;
        width: 180px !important;
        height: 390px !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        top: 45px;
        box-shadow: none;
        margin-right: 5px !important;
    }


    .apicard500 .myCard {
        width: 100%;
        height: 100%;
        display: flex;
        text-align: center;
    }

    .apicard500 .swiper-pagination {
        display: none;
    }

    .apicard500 .swiper-button-next {
        display: block !important;
        color: rgb(255, 219, 219);
        position: absolute;
        top: 220px !important;
    }

    .apicard500 .swiper-button-prev {
        display: block !important;
        color: white;
        position: absolute;
        top: 220px !important;
    }

    .apinew_img {
        width: 100%;
        height: 156px;
        position: absolute;
        left: 0px;
        top: 0px;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .apinew_img img {
        width: 80% !important;
        margin: auto 10%;
        height: 70%;
        position: absolute;
        top: 15px;
        left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .apinew_data {

        position: relative;
        top: 170px;
        display: flex;
        flex-direction: column;
    }

    .apinew_data h2 {
        color: #ff7200;
        font-size: 12px;
        position: absolute;
        width: 100%;
        left: 0px;
        height: 45px;
        top: -15px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        margin-top: 0px;
        line-height: 14px;
    }

    .apinew_data p {
        position: relative;
        top: -10px;
        color: white;
        font-size: 10px;
        font-weight: 300;
        margin-top: 40px;
        padding-left: 8px;
        padding-right: 8px;
    }

    .home_page_footer {
        /* position: absolute;
        top: 4680px; */
        position: relative;
        width: 100%;
    }

    .mobileFont {
        font-size: 0.5rem !important;
    }




}

/* media query 15 */

@media only screen and (min-width:300px) and (max-width:383px) {
    body {
        width: 100%;
        height: 100%;
        display: inline;
        z-index: -1;

    }

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #ff7200;
        border-radius: 7px;

    }


    #lll {
        position: relative;
        left: 30px;
        font-size: 6px;
        font-weight: lighter;
    }



    .video_part {
        width: 100%;
        /* height: 420px; */
        height: 395px;
        position: relative;
        top: 0px;
        z-index: -2;
    }

    .video_part .headings {
        width: 100%;
        height: 79%;
        position: relative;
        top: 20px;
    }

    video {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50px;
        object-fit: initial;
        z-index: 1;
    }


    .three_btns_for_videos {
        width: 8%;
        height: 30px;
        display: flex;
        position: relative;

        top: 375px;
        color: #ff7200;
        justify-content: center;
        align-items: center;
        justify-content: space-between;
        z-index: 10;
    }

    .three_btns_for_videos button {

        width: 7px;
        height: 7px;
        border-radius: 12px;
        border: none;

    }


    .banner_headings {
        width: 74%;
        height: 20%;
        color: #ff7200;

        bottom: 160px;
        position: relative;
        left: 32px;
        z-index: 10;

        /*  border: 0.2px solid red;  */
    }

    .banner_headings h4 {
        font-size: 30px;
        font-weight: 700;
        text-shadow: 1px 5px 1px black;
        font-family: 'Montserrat', sans-serif;
        line-height: 27px;
    }


    .banner_video_slider {
        width: 100% !important;
        height: 380px !important;
        position: absolute;
        top: 22px;
        left: 0px;
        display: block !important;
        justify-content: center;
        /* overflow: hidden; */
        align-items: center;
    }

    .banner_btn {
        background-color: #ff7200;
        border: none;
    }

    /*  */
    /*  */



    .banner_video_slider .swiper-wrapper {
        width: 100vw !important;
        height: 329px;
        position: absolute;
        left: 60px;
        top: 40px;
        /* background-color: antiquewhite;  */
    }

    .banner_video_slider .swiper-slide {
        width: 100vw !important;
        height: 100%;
        margin-right: 0px;
    }


    /*  */
    .mobile_heading_div {
        width: 75%;
        height: 100px;
        position: relative;
        left: 16%;
        justify-content: center !important;
        align-items: center;
        text-align: center;
        z-index: 10;
        /* border: 1px solid red;       */
            top: 10rem !important;

    }

    .mobile_heading_div h2 {
        font-size: 33px !important;
        font-weight: 700;
        text-shadow: 1px 2px 1px black;
        font-family: 'Fjalla One', sans-serif !important;
        line-height: 35px;
        position: absolute;
        top: 0px;
        left: 0px !important;
    }

    .mobile_heading_div h4 {
        color: #fff;
        font-size: 10px;
        text-shadow: 1px 2px 1px black;
        font-weight: 500;
        font-family: 'Montserrat', sans-serif;
        position: relative;
        top: -5px !important;
        text-align: center;

    }

    /* 0 */
    .swiper-container-horizontal>.swiper-pagination-bullets,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {

        height: 20px;
        position: absolute;
        top: 340px !important;
        left: 18%;
        justify-content: center;
        align-items: center;
        display: flex;
        z-index: 100;

    }


    .banner_video_slider_inner .swiper-pagination .swiper-pagination-bullets {
        position: absolute;
        top: 220px !important;

    }

    .banner_video_slider_inner .swiper-pagination {
        position: absolute !important;
        top: 200px !important;
        left: 0px !important;
        border: 1px solid #ff7200 !important;
        z-index: 1;
        display: block !important;

    }

    swiper-pagination-current {
        background: #fff;
    }

    .swiper-pagination-bullet-active {
        width: 7px !important;
        height: 7px !important;
        background-color: #FF5000 !important;



    }

    .swiper-pagination-bullet {
        opacity: 1;
        width: 7px !important;
        height: 7px !important;
        background-color: white;
    }

    .banner_video_slider_inner video {
        display: block;
        position: absolute;
        top: 0px;
        left: 12px !important;
        width: 100%;
        height: 100%;
    }

    .producst_para button {
        width: 110px;
        height: 25px;
        background-color: transparent;
        border: none;
        font-size: 12px;
        border-radius: 14px;
        color: #ff7200;
        position: absolute;
        bottom: 12px;
        right: 126px;


    }
/* 
    video {
        display: none;

    } */



    .main {
        width: 100%;
        height: 647px;
        position: relative;
        top: 388px;
        background-color: #ff7200;
        z-index: -1;


    }

    .Product_heading {
        width: 90%;
        position: relative;
        text-align: center;
        justify-content: center;
        align-items: center;




    }

    .Product_titles {
        display: none;

    }

    .sub {
        display: none;
    }

    .products_swiper {
        width: 80%;
        height: 560px;
        position: absolute;
        top: 8px;
        left: 39px;
        display: block;
    }


    .swiper-container1 {
        width: 100vw;
        height: 350px;
        position: relative;
        top: 184px;
        left: 39px;
        overflow: visible;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;



    }

    .swiper-container1 .swiper-slide {
        position: relative;
        /* left: 22% !important; */
        width: 93% !important;
        height: 540px !important;
        transition: all 200ms linear;
        background-color: #fff !important;
        box-shadow: none;
        margin-right: 39px;

        border-radius: 7px;
        display: flex;
        flex-direction: column;
        overflow: visible !important;

    }

    /* .swiper-container {
        width: 100vw;
        top: 5px;
        position: relative;
        right: 72px;
        overflow: visible;
    } */

    #file {
        width: 92%;
        height: 3px;
        position: absolute;
        bottom: -143px;
        left: 5%;
        background: transparent;



    }

    progress::-webkit-progress-bar {
        background-color: lightgray;
        width: 100%;
    }

    progress::-webkit-progress-value {
        background-color: black !important;
    }

    progress {
        -webkit-appearance: none;
        appearance: none;
    }

    #numbers_progress {
        position: absolute;
        bottom: -155px;
        left: 42%;
    }


    progress::-webkit-progress-value {
        background: black;
    }

    progress::-moz-progress-bar {
        background: lightcolor;
    }


    .swiper-slide-duplicate-next {
        background-color: white !important;
    }

    .swiper-button-next {
        color: #ff7200;
        display: none;
    }

    .swiper-button-prev {
        color: #ff7200;
        display: none;
    }

    .swiper-container1 .swiper-pagination {

        display: none;
    }


    .Product_titles a {
        color: #fff;
        font-size: 22px;
        padding-top: 1px;
        cursor: pointer;
        text-decoration: none;
    }





    .sub {
        display: none;

    }


    /*  */

    .achievements_div {
        width: 100%;
        height: 353px;
        /* position: absolute;
        top: 1109px; */
        position: relative;
        background-color: #070707;
    }

    .achievements_div h1 {
        font-size: 26px;
        position: relative;
        top: 0px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px !important;
        background-image: linear-gradient(#fff, #fff);

        background-repeat: no-repeat;
        line-height: 70px;
        background-size: 0% 4px;
        background-position: bottom;




    }

    .achivementElem {
        width: 98%;
        height: 58%;
        margin-left: auto;
        margin-right: auto;
    }

    .achiveR1 {
        width: 100%;
        height: 50%;
        display: flex;
    }

    .achieverc1 {
        width: 20%;
        height: 100%;
        margin-right: 2px;
        position: relative;
    }

    .achieveImg {
        width: 100%;
        height: 100%;
        position: relative;
        justify-content: flex-start;
        text-align: left;
        align-items: center;
        z-index: 1;
    }

    .achieve100 {
        position: absolute;
        top: 10px;
        left: 10px;
        font-size: 0.7rem;
        font-weight: 600;
        color: #ff7200;
        z-index: 10;
    }

    .achieveClient {
        position: absolute;
        top: 23px;
        left: 10px;
        font-size: 0.5rem;
        font-weight: 500;
        z-index: 10;
    }

    .achieveInstall {
        position: absolute;
        top: 32px;
        left: 10px;
        font-size: 0.5rem;
        font-weight: 500;
        z-index: 10;
    }

    .achieverc2 {
        width: 30%;
        height: 100%;
        margin-right: 2px;
    }

    .achiveI1 {
        width: 100%;
        height: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #ff7200;
        box-shadow: 0px 0px 24px black inset;
        margin-bottom: 2px;
    }

    .threefive {
        font-size: 0.7rem;
        color: #fff;
        font-weight: 600;
        position: relative;
        /* top: 10px; */
    }

    .iot {
        font-size: 0.46rem;
        font-weight: 400;
        color: #fff;
        position: relative;
        top: 4px;
    }

    .achiveI2 {
        width: 100%;
        height: 57%;
        position: relative;
    }

    .achieverc3 {
        width: 20%;
        height: 100%;
        margin-right: 2px;
    }

    .achieve50 {
        width: 100%;
        height: 49%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #464444;
        box-shadow: inset 1px 1px 22px #000;
        margin-bottom: 2px;
    }

    .achieve50b {
        width: 100%;
        height: 49%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #d3d3d3;
        box-shadow: inset 1px 1px 19px #000;
        margin-bottom: 2px;
    }

    .m100m {
        color: #ff7200;
        font-size: 0.7rem;
        font-weight: 600;
    }

    .transactions {
        color: #fff;
        position: relative;
        bottom: 3px;
        font-size: 0.2rem;

        font-weight: 400;
    }

    .img_overlay {
        position: absolute !important;
    }

    .overlay_main_text {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -70%);

        z-index: 10;
        color: #ff7200;
        font-size: .5rem;
        font-weight: 600;
    }

    .overlay_sub_text {
        color: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, 0);
        font-size: 0.2rem;
        z-index: 10;
    }

    .m100black {
        color: #000;
        font-size: 0.7rem;
        font-weight: 600;
    }

    .transactionsm {
        color: #000;
        position: relative;
        bottom: 4px;
        font-size: 0.3rem;
    }

    .achieveI31 {
        width: 100%;
        height: 48%;

        margin-bottom: 2px;
    }

    .achieveI32 {
        width: 100%;
        height: 49%;
        margin-bottom: 2px;
    }

    .achieverc4 {
        width: 30%;
        height: 100%;
    }

    .achiveR2 {
        width: 100%;
        height: 50%;
        display: flex;
        position: relative;
    }

    .ach5 {
        width: 29%;
        height: 100%;
        margin-left: 2px;
        margin-top: 2px;
        position: relative;
    }

    .fifteen {
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 10;
    }

    .fifteenTrips {
        position: absolute;
        top: 23px;
        left: 9px;
        z-index: 10;
        font-size: 0.5rem;
    }

    .ach6 {
        width: 21%;
        height: 100%;
        margin-top: 2px;
        position: relative;
    }

    .ach7 {
        width: 20%;
        height: 100%;
        margin-top: 2px;
        margin-left: 2px;
        position: relative;
    }

    .m1000 {
        color: #fff;
        position: absolute;
        top: 10px;
        left: 8px;
        font-size: 0.7rem;
        z-index: 10;
    }

    .lynkassist {
        color: #fff;
        position: absolute;
        top: 25px;
        left: 8px;
        font-size: 0.47rem;
        z-index: 10;
    }

    .lynkassistLoc {
        color: #fff;
        position: absolute;
        top: 35px;
        left: 8px;
        font-size: 0.47rem;
        z-index: 10;
    }

    .ach8 {
        width: 30%;
        height: 100%;
        margin-left: 2px;
        margin-top: 2px;
        display: flex;
        position: relative;
    }

    .m3 {
        position: absolute;
        top: 10px;
        left: 5px;
        z-index: 10;
        color: #ff7200;
        font-size: 1rem;
        font-weight: 600;
    }

    .rfid_cir {
        color: #fff;
        position: absolute;
        top: 30px;
        left: 5px;
        font-size: 0.3rem;
        z-index: 10;
    }

    .lynkit_testomonial {
        width: 100%;
        /* height: 394px !important; */
        height: 340px  !important;
        /* position: absolute;
        top: 1397px; */
        position: relative;
    }

    .Enterprise_div {
        width: 100%;
        height: 394px !important;
        /* position: absolute;
        top: 1710px; */
        position: relative;
        display: flex;
        justify-content: center;
        overflow: hidden;
    }

    .Enterprise_div h1 {
        font-size: 26px;
        color: #ff7200;
        text-shadow: none;
        position: absolute;
        top: 0px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px !important;
        background-image: linear-gradient(#fff, #fff);
        background-repeat: no-repeat;
        line-height: 72px;
        background-size: 0% 4px;
        background-position: bottom;
    }

    .enterprise_main_div {
        width: 80%;
        height: 500px;
        /*  background-color: aqua; */
        position: relative;
        top: 150px;
        display: flex;



    }

    .enterprise_left1 {
        display: none;
    }

    .enterprise_left2 {
        display: block;
    }

    /*  */
    .enterprise_left2 .swiper-container {
        width: 100%;
        height: 350px;
        background-color: transparent !important;
        position: absolute;
        top: 118px;
        right: -3px;
        overflow: visible !important;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

    }

    .enterprise_left2 .swiper-container .swiper-slide {
        background-position: center !important;
        background-size: cover !important;
        position: relative;
        left: 0px;
        width: 190px !important;
        height: 300px !important;
        background: #1c1c1c;
        transition: all 200ms linear;

        background-color: #141414;
        box-shadow: none;
    }


    .swiper-slide>img {
        /* width: 18% !important; */
        height: 70px;


    }

    .enterprise_left2 .swiper-container .swiper-slide img {
        width: 52% !important;
        height: 138px !important;
        position: relative;
        top: 20px !important;
        left: 0px !important;

    }


    .enterprise_left2 .swiper-container .swiper-slide .initial_data .initial_data_img {
        width: 45% !important;
        height: 110px !important;
        position: relative;
        top: -39px !important;
        left: -5px !important;
    }

    .producst_para h2 {
        font-size: 18px !important;
        line-height: 20px !important;
    }


    .swiper-slide h2 {
        font-size: 18px;
        color: #ff7200;
        position: relative;
        left: 0px;
        top: -10px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;





    }

    .enterprise_left2 button {
        width: 102px;
        height: 23px;
        background-color: black;
        border-radius: 12px;
        border: none;
        color: white;
        font-size: 12px;
        position: relative;
        top: 25px;
    }

    /*  */

    .swiper-slide.swiper-slide-active {
        z-index: 11 !important;

    }

    .flip-card-inner {
        position: absolute;
        bottom: 0px;
        left: -34px;
        width: 260px;
        height: 350px;
        text-align: center;
        transition: transform 0.5s;
        transform-style: preserve-3d;
        transform: rotateY(180deg);
        animation: opacity 1s;
        animation-fill-mode: forwards;
        animation-timing-function: linear;
        animation-delay: 0s;
        animation-iteration-count: 1;
        background-color: transparent !important;



    }

    @keyframes opacity {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 1;
        }



    }


    .active_para {
        width: 100%;
        height: 100%;
        background-color: #ff7200;
        position: absolute;
        top: 0px;
        background-color: #fff;

        transform: rotateY(180deg);
        visibility: visible !important;
    }

    .para_div {
        width: 85%;
        height: 100%;
        position: relative !important;
        top: 0px !important;
        margin-left: auto;
        margin-right: auto;

    }

    .active_para h4 {
        color: #ff7200 !important;
        font-weight: 900;
        position: relative;
        font-size: 19px;
        top: 26px;
        line-height: 22px;
        font-family: 'Fjalla One', sans-serif;
    }

    .active_para p {
        color: black;
        padding-top: 30px;
        font-size: 10.2px;
        text-align: justify;
        line-height: 14px;


    }

    .swiper-button-next {
        color: #fff;
    }

    .swiper-button-prev {
        color: #fff;
    }

    .enterprise_left2 .swiper-pagination {
        position: absolute;
        top: 349px !important;
        left: 0px;
        z-index: 10;
        display: block;
    }

    swiper-pagination-current {
        background: #fff;
    }

    .enterprise_left2 .swiper-pagination-bullet-active {
        width: 8px;
        height: 8px;
        background-color: #FF5000 !important;



    }

    .enterprise_left2 .swiper-pagination-bullet {
        opacity: 1;
        background-color: #fff;
    }

    /*  */
    .customer_div1 {
        width: 100%;
        height: 900px;

        /* position: absolute;
        top: 2040px; */
        position: relative;
        display: flex;
        justify-content: center;

    }



    .customer_div1 h1 {

        font-size: 26px;
        color: black;
        text-shadow: none;
        position: absolute;
        top: 57px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-image: linear-gradient(#ff7200, #ff7200);
        background-repeat: no-repeat;
        line-height: 70px;
        background-size: 0% 4px;
        background-position: bottom;
        letter-spacing: 0px !important;


    }

    .customers_div_1 {
        width: 100%;
        height: 100px;
        left: 0px;
        position: absolute;
        top: 142px;
        display: flex;
        justify-content: space-around;

    }

    .customers_div_1 div {
        width: 24% !important;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }

    .customers_div_2 {
        width: 100% !important;
        height: 100px !important;
        position: absolute;
        top: 260px;
        display: flex;
        justify-content: space-around;

    }

    .customers_div_2 div {
        width: 24% !important;
        height: 100px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }

    .customers_div_3 {
        width: 100%;
        height: 100px !important;
        left: 5px;
        position: absolute;
        top: 378px;
        left: 0px;
        display: flex;
        justify-content: space-around;

    }

    .customers_div_3 div {
        width: 24% !important;
        height: 100px !important;

        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }

    .customers_div_4 {
        width: 100% !important;
        height: 100px !important;

        position: absolute;
        top: 497px;
        display: flex;
        z-index: 10;
        justify-content: space-around;

    }

    .customers_div_4 div {
        width: 24% !important;
        height: 100px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }

    .customers_div_5 {
        width: 100% !important;
        height: 100px !important;
        position: absolute;
        top: 614px;
        display: flex;
        z-index: 10;
        justify-content: space-around;

    }

    .customers_div_5 div {
        width: 24% !important;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }

    .customers_div_6 {
        width: 100% !important;
        height: 100px !important;
        position: absolute;
        top: 730px;
        display: flex;
        z-index: 10;
        justify-content: space-around;

    }

    .customers_div_6 div {
        width: 24% !important;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px #E8E8E8;
    }

    .customer_images {
        width: 63px;
    }

    #cma {
        width: 70px;
        height: 107px;
    }

    #bharat {
        width: 60px;
        height: 80px;
    }

    #aditya {
        width: 67px;
        height: 57px;
    }

    #lg {
        width: 59px;
        height: 70px;
    }

    #dadri {
        width: 60px;
        height: 60px;
    }


    #more_3500 {
        width: 100%;
        height: 66px;
        position: relative;
        top: 778px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 7;
    }

    #more_3500 h1 {
        position: relative;
        font-size: 18px;
        color: black;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
        background-image: linear-gradient(white, white);
        background-repeat: none;
        background-size: 0% 0px;
        font-family: 'Fjalla One', sans-serif;

    }

    .whatsNew500 {
        width: 100%;
        height: 480px;
        /* position: absolute;
        top: 2936px; */
        position: relative;
    }

    .whatsNew500 h1 {
        font-size: 26px;
        z-index: 1;
        color: #ff7200;
        position: relative;
        bottom: 490px !important;
        align-items: center;
        text-align: center;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px !important;
        background-image: linear-gradient(#fff, #fff);
        letter-spacing: 0px !important;
        line-height: 100px;
        background-position: bottom;
    }

    .whatsNew500 img {
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .cardNew {
        width: 100%;
        height: 82%;
        position: absolute;
        bottom: 0px;
        overflow: hidden;
        border-radius: 0px !important;
    }

    .cardNew .swiper-container {
        width: 100%;
        height: 100%;
        top: -40px;
        left: 2px;
        position: absolute;
    }

    .cardNew .swiper-wrapper {
        width: 100%;
        height: 100%;
        position: relative;
    }

    .cardNew .swiper-slide {
        background: #141414;
        width: 180px !important;
        height: 370px !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        top: 20px;
        box-shadow: none;
        margin-right: 5px !important;
    }

    .cardNew .myCard {
        width: 100%;
        height: 100%;
        display: flex;
        text-align: center;
    }

    .cardNew .swiper-pagination {
        display: none;
    }

    .cardNew .swiper-button-next {
        display: block !important;
        color: rgb(255, 219, 219);
        position: absolute;
        top: 220px !important;
    }

    .cardNew .swiper-button-prev {
        display: block !important;
        color: white;
        position: absolute;
        top: 220px !important;
    }

    .new_img {
        width: 100%;
        height: 152px;
        position: absolute;
        left: 0px;
        top: 0px;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .whatsNew3cards .swiper-slide-active img {
        position: relative;
        top: 0px;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .new_img img {
        width: 100% !important;
        height: 60%;
        position: absolute;
        top: 30px;
        left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .new_data {

        position: relative;
        top: 175px;
        display: flex;
        flex-direction: column;
    }

    .new_data h2 {
        color: #ff7200;
        font-size: 12px !important;
        position: absolute;
        left: 0px;
        top: -20px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        margin-top: 0px;
        line-height: 14px;
    }

    .new_data p {
        position: relative;
        top: -20px;
        color: white;
        font-size: 9px !important;
        font-weight: 300;
        margin-top: 65px;
        padding-left: 8px;
        padding-right: 8px;
    }

    #learn_more {
        position: relative;
        top: -4px;
        color: #ff7200;
        font-size: 10px;

    }

    .whatsNew3cards .swiper-button-next {
        display: block !important;
        color: rgb(255, 219, 219);
        position: absolute;
        top: 220px !important;
        right: -69px !important;
    }


    .whatsNew3cards .swiper-button-prev {
        display: block !important;
        color: white;
        position: absolute;
        left: 82px !important;
        top: 220px !important;
    }


    .whatsNew3cards .swiper-pagination {
        display: none;
    }


    .lynkit_feed {
        width: 100%;
        /* height: 1010px; */
        height: 1080px;
        background-color: rgb(22, 22, 22);
        /* position: absolute; */
        /* background-color: rgb(224, 223, 223); */
        /* top: 3710px !important; */
        position: relative;
    }

    .lynkit_feed h1 {
        color: #ff7200;
        position: relative;
        top: 20px;
        align-items: center;
        text-align: center;
        letter-spacing: 0px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 1px;
        font-size: 26px;

    }

    .feed_inner {
        width: 90%;
        height: 540px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 70px;
        display: flex;
    }


    .feed_right {
        width: 100%;
        height: 720px;
        /* border: 1px solid red; */
    }

    .awardsSection {
        background-color: #fff !important;
        height: 340px;
        position: relative;
        /* top: 2986px; */
    }

    .awardsInner {
        height: 100%;
    }

    .awardsHeading {
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: center;
    }

    .awardsSpans {
        font-size: 26px;
        color: #000;
        position: relative;
        top: 4px;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-size: 0% 4px;
        background-position: bottom;
    }

    .awardsListing {
        width: 100%;
        height: auto;
        position: relative;
        top: 11px;
        align-items: center;
        /* background-color: rgb(0 0 0 / 2%); */
    }

    .awardBox {
        width: 20%;
        height: 74%;
        margin-left: 0px;
        position: relative;
    }

    .awardsRow1 {
        width: 90%;
        height: 95px;
        display: flex;
        justify-content: space-around;
    }

    .awardBox2 {
        width: 50%;
        height: 100%;
        margin-left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .active_slide_data11 {
        width: 300px;
        height: 370px !important;
        position: absolute;
        background-color: #fff;
        box-shadow: inset 2px 1px 18px grey;
    }

    .flipCardData {
        height: 100% ! important;
        display: flex;
        flex-direction: column !important;
    }

    .active_imggg {
        width: 100%;
        height: 100% !important;
        position: relative;
        display: flex;
        justify-content: space-evenly !important;
        align-items: center !important;
        flex-direction: column !important;
    }

    .activeImagge {
        width: 50% !important;
        height: 150px ! important;
        position: relative !important;
        top: 1px !important;
        left: 0% !important;

    }

    .activeHeading {
        position: relative;
        top: 10px ! important;
        color: #000 ! important;
    }

    .activeBBtn {
        width: auto;
        height: 23px;
        background-color: transparent !important;
        border-radius: 12px;
        border: none;
        color: #ff7200 !important;
        font-size: 12px !important;
        position: relative;
        top: 0px;
        font-family: 'Fjalla One', sans-serif;
    }

    .enterprise_left2 button {
        width: auto;
        height: 23px;
        background-color: transparent !important;
        border-radius: 12px;
        border: none;
        color: #fff;
        font-size: 11px;
        position: relative;
        top: 15px;
        font-family: 'Fjalla One', sans-serif;
    }

    .mobProductHead {
        font-size: 26px;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px !important;
        background-image: linear-gradient(#fff, #fff);
        letter-spacing: 1px !important;
        background-repeat: no-repeat;
        line-height: 100px;
        background-size: 0% 4px;
        color: #fff;
        background-position: bottom;
    }

    .mainMobile {
        width: 100%;
        height: 735px;
        /* position: absolute;
        top: 395px; */
        position: relative;
        overflow: hidden;
        z-index: 2;
    }

    .swiper-container1 {
        width: 100vw;
        height: 350px;
        position: absolute;
        top: 195px;
        left: 4% !important;
        overflow: visible;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .proMobImage {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center !important;
        align-items: center ! important;
        padding: 9px !important;
        padding-top: 4px !important;
    }

    .alter501 {
        width: 56%;
        display: flex;
        text-align: start;
    }

    .bodyImagee {
        /* width: 84% !important; */
        width: 50% !important;
        height: 75% ! important;
        position: relative !important;
        top: 0px !important;
        left: 0% !important;
    }

    .cardContenSpan {
        font-size: 9.5px;
    }

    .PEnterDegign {
        width: 100%;
        height: 60%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        position: relative;
        top: 22%;
        overflow: visible;
    }

    .enterInnerNew {
        width: 98%;
        height: 100%;
        display: block;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: scroll;
    }

    .enterTopNew {
        width: 700px;
        height: 68%;
        display: flex;
        position: relative;
    }

    .enterBotNew {
        width: 700px;
        height: 26%;
        display: -webkit-flex;
        display: flex;
        position: relative;

    }

    .leftMover {
        position: relative;
        left: 16% !important;
    }

    .EnterpriseTextElementNew {
        width: 98%;
        height: auto;
        /* border: 1px solid #303030; */
        position: relative;
        top: 15%;
        padding: 0px;
        z-index: 10000;
        /* padding: 9px;
        position: relative;
        text-align: justify;
        overflow-y: auto;
        overflow-x: hidden; */
    }

    .stdBox {
        width: 100%;
        height: auto;
        display: flex;
        /* justify-content: center; */
        align-items: center;
        flex-direction: column;
    }

    .Enterprise_div>img {
        position: absolute;
        left: 0px;
        width: 700px;
        height: 100%;
        z-index: 1;
    }

    .mySiMGHeading {
        position: absolute;
        top: 5%;
        left: 48%;
        font-size: 12px;
        color: #fff;
        /* border: 1px solid red; */
    }

    .mySiMG {
        position: absolute;
        width: 15% !important;
        height: 58px !important;
        left: 29% ! important;
        top: 0%;
        display: block;
    }

    .stddBoxes {
        width: 100%;
        height: 300px;
        position: relative;
        top: 60px;
        overflow: scroll;
        padding: 5px;
        text-align: justify;
        padding-top: 0px;
    }

    .enterData {
        width: 100%;
        height: 32%;
        position: relative;
        top: 22%;
        display: flex;
        cursor: pointer;
    }

    .enterInnerNew::-webkit-scrollbar {
        display: none;
    }

    .enterpriseClose {
        width: 15px;
        height: 15px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: -8px;
        right: 4px;
        /* background-color: #ff7200; */
        border-radius: 50%;
        /* overflow: visible; */
        z-index: 9999;
    }

    .enterSpan {
        font-size: 8px;
        font-weight: 300;
        color: #F5F5F5 !important;
    }

    .entImg {
        width: 25% !important;
        height: 65% !important;
        position: relative;
        left: 5% !important;
    }

    .leftMover {
        position: relative;
        left: 14% ! important;
    }

    .api-div500 {
        width: 100%;
        height: 1000px;
        position: absolute;
        top: 70px;
        display: block;
        background-color: #161616;
    }



    .apicard500 {
        width: 100%;
        height: 78%;
        position: absolute;
        top: 505px;
        overflow: hidden;
        border-radius: 0px !important;
    }

    .apicard500 .swiper-container {
        width: 100%;
        height: 100%;
        top: -40px;
        left: 2px;
        position: absolute;
    }

    .apicard500 .swiper-wrapper {
        width: 100%;
        height: 100%;
        position: relative;
    }

    .apicard500 .swiper-slide {
        background: black;
        width: 180px !important;
        height: 390px !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        top: 45px;
        box-shadow: none;
        margin-right: 5px !important;
    }


    .apicard500 .myCard {
        width: 100%;
        height: 100%;
        display: flex;
        text-align: center;
    }

    .apicard500 .swiper-pagination {
        display: none;
    }

    .apicard500 .swiper-button-next {
        display: block !important;
        color: rgb(255, 219, 219);
        position: absolute;
        top: 220px !important;
    }

    .apicard500 .swiper-button-prev {
        display: block !important;
        color: white;
        position: absolute;
        top: 220px !important;
    }

    .apinew_img {
        width: 100%;
        height: 156px;
        position: absolute;
        left: 0px;
        top: 0px;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .apinew_img img {
        width: 80% !important;
        margin: auto 10%;
        height: 70%;
        position: absolute;
        top: 15px;
        left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .apinew_data {

        position: relative;
        top: 170px;
        display: flex;
        flex-direction: column;
    }

    .apinew_data h2 {
        color: #ff7200;
        font-size: 12px;
        position: absolute;
        width: 100%;
        left: 0px;
        height: 45px;
        top: -15px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        margin-top: 0px;
        line-height: 14px;
    }

    .apinew_data p {
        position: relative;
        top: -10px;
        color: white;
        font-size: 10px;
        font-weight: 300;
        margin-top: 40px;
        padding-left: 8px;
        padding-right: 8px;
    }

    .home_page_footer {
        /* position: absolute;
        top: 4659px; */
        position: relative;
        width: 100%;
    }

    .proMobImg {
        width: 18% ! important;
        height: 79% ! important;
        position: relative !important;
        top: 0px !important;
        right: 0px !important;
        bottom: 20px !important;
        left: 0px !important;
    }

    .modalIcon {
        color: red;
        font-size: 1.3rem;
    }


}

@media only screen and (min-width: 1200px) and (max-width: 1537px) {
    .achieve50b{
        height: 50%;
    }
}


.b-600 {
    font-weight: 600;
}

.f-3 {
    font-size: 3rem;
}


.ourVisionDJ {
    background-color: #070707;
    padding-top:2rem ;
    padding-bottom: 4rem;
  }
  
  .vision {
    width: 81%;
    margin-left: auto;
    margin-right: auto;
  }
 
  .ourVisionHeadingDJ h1 {
    font-size: 48px;
    color: #ff7200;
    font-family: 'Fjalla One', sans-serif;
    text-align: center;
    font-weight: 900;
  }
 

  .visionVideoContentContainerDJ {
    display: flex;
    align-items: stretch; 
    justify-content: center;
    gap: 4rem;
    margin-top: 3rem;
}

.visionVideoSectionDJ {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 40%;
}

.visionVideoSectionDJ iframe {
    width: 100%;
    height: 100%; 
    object-fit: contain;
}

.ourVisionContentDJ {
    flex-basis: 60%; 
    display: flex;
    flex-direction: column;
    justify-content: center; 
}

.ourVisionContentDJ p {
    font-size: 1.2vw;
    text-align: left;
    font-weight: 300;
    color: white;
}

@media only screen and (min-width: 2281px){
    .ourVisionHeadingDJ h1 {
        font-size: 100px;
      }
}
@media only screen and (min-width: 1901px) and (max-width: 2281px){
    .ourVisionHeadingDJ h1 {
        font-size: 80px;
      }
}
@media only screen and (min-width: 1537px) and (max-width: 1901px){
    .ourVisionHeadingDJ h1 {
        font-size: 70px;
      }
      .customer_div{
        height: 760px;
      }
}
@media only screen and (max-width:1200px) {
    .ourVisionHeadingDJ h1 {
        font-size: 48px;
      }
}
@media only screen and (max-width:1100px) {
    .ourVisionContentDJ p {
        font-size: 16px;   
    }

    .ourVisionHeadingDJ h1 {
        font-size: 48px;
      }
}
@media only screen and (max-width:1000px) {
    .ourVisionHeadingDJ h1 {
        font-size: 44px;
      }
}
@media only screen and (max-width:900px) {
    .visionVideoContentContainerDJ{
        flex-direction: column;
        gap: 2rem;
    }
    .visionVideoSectionDJ iframe{
        height: 50vh;
    }
    .ourVisionContentDJ p{
        text-align: center;
    }
}
@media only screen and (max-width:800px) {
    .ourVisionHeadingDJ h1 {
        font-size: 44px;      
      }
}
@media only screen and (max-width:700px) {
    .ourVisionHeadingDJ h1 {
        font-size: 38px;  
      }
}
@media only screen and (max-width:600px) {
    .visionVideoSectionDJ iframe{
        /* height: 50vh; */
        min-height: 35vh;
        height: 100%;
    }
}
@media only screen and (max-width:500px) {
    .visionVideoSectionDJ iframe{
        /* height: 45vh; */
        min-height: 25vh;
        height: 100%;
    }
    .ourVisionContentDJ p{
        font-size: 13px;
    }
    .ourVisionHeadingDJ h1 {
        font-size: 30px; 
      }
}