.desktop_bg{
  display: none;
}
.mobile_bg{
  display: block;
}
.desktop_view{
  display: none;
}
.mobile_view{
  display: block;
}

.mobile_container{
  background: #0d0d0d;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.mobile_container > .mobile_sec{
  position: relative;
  height: 100vh;
  width: 100%;
}

.mobile_container > .frame1 > img{
  position: absolute;
  top: 50%;
  left: 30%;
  height: 55%;
  transform: translate(-50%, -50%);
  width: auto;
  max-width: none;
}

.mobile_container > .frame2 > img{
  position: absolute;
  top: 10%;
  right: -2%;
  height: 84%;
  width: auto;
  max-width: none;
}

.outer_container{
  background: #0d0d0d;
  height: 110vh;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.outer_container > img{
  position: absolute;
  /* top: 50%; */
  left: -55%;
  /* transform: translate(-50%, -50%); */
  /* max-width: 100%; */
  max-height: 120vh;
  /* width: 100%;
  height: 100%; */
  object-fit: cover;
  z-index: 1;
}

.heading{
  position: absolute;
  top: 2.5rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.title_pill{
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.123); /* Slightly transparent white */
  backdrop-filter: blur(2px); /* Background blur effect */
  border-radius: 50px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  position: absolute;
  z-index: 3;
  border: 1px solid #ffffff7c;
  cursor: pointer;
}

.active_pill{
  background: #ff77006b;
  border-color: #ff7700;
}

.title_pill > span{
  margin: 0px !important;
  color: #ffffff;
  font-weight: 600;
  font-size: 11px;
  /* font-size: 15px; */
}

.dashed_lines{
  position: absolute;
  border: 2px dashed #ff77004d;
  z-index: 2;
  position: absolute;
  transform-origin: top left;
}

.detail_box{
  padding: 0.5rem;
  background: linear-gradient(#0c1008, #0c1008) padding-box,
  linear-gradient(to right, transparent, transparent, #FF7700) border-box ;
  border: 1px solid transparent;
  z-index: 2;
  position: absolute;
  transform-origin: top;
  color: #fff;
  font-size: 11px;
}

.active{
  display: block !important;
}

#buses{
  top: 32%;
  left: 4%;
}
#buses_htl{
  width: 17vw;
  bottom: -1px;
  left: 38px;
  rotate: 63deg;
  display: none;
}
#buses_vtl{
  width: 12vw;
  bottom: -1px;
  right: -12vw;
  rotate: 29deg;
  display: none;
}
/* #buses:hover #buses_htl,
#buses:hover #buses_vtl{
  display: block;
} */
#buses_details{
  left: -5vw;
  rotate: -92deg;
  width: 300%;
}

#vans{
  top: 15%;
  left: 30%;
}
#vans_htl{
  width: 44vw;
  bottom: -1px;
  left: 73px;
  rotate: 93deg;
  display: none;
}
#vans_vtl{
  width: 8vw;
  bottom: -1px;
  right: -8vw;
  rotate: 60deg;
  display: none;
}
/* #vans:hover #vans_htl{
  display: block;
} */
#vans_details{
  left: -5vw;
  rotate: -153deg;
  width: 680%;
  top: 5vw;
}

#field_staff{
  top: 19.5%;
  left: 43%;
}
#field_staff_htl{
  width: 20vw;
  bottom: -5px;
  left: 45px;
  rotate: 108deg;
  display: none;
}
#field_staff_vtl{
  width: 16vw;
  bottom: -2px;
  right: -16vw;
  rotate: -16deg;
  display: none;
}
/* #field_staff:hover #field_staff_htl,
#field_staff:hover #field_staff_vtl{
  display: block;
} */
#field_staff_details{
  left: -10vw;
  rotate: -93deg;
  width: 331%;
  top: 12vw;
}

#bikes{
  top: 30%;
  left: 26%;
}
#bikes_htl{
  width: 6vw;
  bottom: -5px;
  left: 33px;
  rotate: 120deg;
  display: none;
}
#bikes_vtl{
  width: 6vw;
  bottom: -4px;
  right: -5vw;
  rotate: -20deg;
  display: none;
}
/* #bikes:hover #bikes_htl,
#bikes:hover #bikes_vtl{
  display: block;
} */
#bikes_details{
  left: 22vw;
  bottom: -25vw;
  transform-origin: left;
  rotate: -101deg;
  width: 1040%;
}

#forklift{
  top: 51%;
  left: 34%;
}
#forklift_htl{
  width: 28vw;
  bottom: -2px;
  left: 73px;
  rotate: 25deg;
  display: none;
}
#forklift_vtl{
  width: 19vw;
  bottom: -1px;
  right: -20vw;
  rotate: 68deg;
  display: none;
}
/* #forklift:hover #forklift_htl,
#forklift:hover #forklift_vtl{
  display: block;
} */
#forklift_details{
  left: -15vw;
  rotate: -93deg;
  width: 390%;
  top: 16vw;
}

#chain{
  top: 35%;
  right: 14%;
}
#chain_htl{
  width: 14vw;
  bottom: -5px;
  left: 26px;
  rotate: 83deg;
  display: none;
}
#chain_vtl{
  width: 10vw;
  bottom: -1px;
  right: -11vw;
  rotate: 2deg;
  display: none;
}
/* #chain:hover #chain_htl,
#chain:hover #chain_vtl{
  display: block;
} */
#chain_details{
  left: -8vw;
  rotate: -85deg;
  width: 442%;
}

#boxes{
  top: 24%;
  right: 10%;
}
#railway{
  bottom: 15%;
  left: 38%;
}
#railway_htl{
  width: 10vw;
  bottom: 28px;
  left: 73px;
  rotate: -50deg;
  display: none;
}
#railway_vtl{
  width: 5vw;
  bottom: -1px;
  right: -7vw;
  rotate: 0deg;
  display: none;
}
/* #railway:hover #railway_htl,
#railway:hover #railway_vtl{
  display: block;
} */
#railway_details{
  left: 13vw;
  bottom: 1vw;
  transform-origin: left;
  rotate: 50deg;
  width: 931%;
}

#trucks{
  top: 49%;
  right: 11%;
}
#trucks_htl{
  width: 7vw;
  bottom: -5px;
  left: 20px;
  rotate: 96deg;
  display: none;
}
#trucks_vtl{
  width: 7vw;
  bottom: -1px;
  right: -5vw;
  rotate: 10deg;
  display: none;
}
/* #trucks:hover #trucks_htl,
#trucks:hover #trucks_vtl{
  display: block;
} */
#trucks_details{
  left: -16vw;
  rotate: -106deg;
  width: 804%;
  top: 27px;
}

@media (min-width: 750px){
  .desktop_bg{
    display: block;
  }
  .mobile_bg{
    display: none;
  }
  .desktop_view{
    display: block;
  }
  .mobile_view{
    display: none;
  }
  .outer_container > img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 120vh;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
  .title_pill{
    padding: 0.4rem 1rem;
  }
  .title_pill > span{
    font-size: 15px;
  }
  .detail_box{
    padding: 1.5rem;
    font-size: 14px;
  }
  #buses{
    top: 19%;
    left: -12%;
  }
  #buses_htl{
    width: 50vw;
    bottom: -1px;
    left: 73px;
    rotate: 32deg;
    display: none;
  }
  #buses_vtl{
    width: 12vw;
    bottom: -1px;
    right: -12vw;
    rotate: 60deg;
    display: none;
  }
  /* #buses:hover #buses_htl,
  #buses:hover #buses_vtl{
    display: block;
  } */
  #buses_details{
    left: -5vw;
    rotate: -92deg;
    width: 300%;
  }
  
  #vans{
    top: 21%;
    left: 8%;
  }
  #vans_htl{
    width: 44vw;
    bottom: -1px;
    left: 73px;
    rotate: 32deg;
    display: none;
  }
  #vans_vtl{
    width: 7vw;
    bottom: -1px;
    right: -7vw;
    rotate: 60deg;
    display: none;
  }
  /* #vans:hover #vans_htl,
  #vans:hover #vans_vtl{
    display: block;
  } */
  #vans_details{
    left: -5vw;
    rotate: -92deg;
    width: 350%;
  }
  
  #field_staff{
    top: 34.5%;
    left: 37%;
  }
  #field_staff_htl{
    width: 25vw;
    bottom: -1px;
    left: 73px;
    rotate: 33deg;
    display: none;
  }
  #field_staff_vtl{
    width: 7vw;
    bottom: -1px;
    right: -7vw;
    rotate: 60deg;
    display: none;
  }
  /* #field_staff:hover #field_staff_htl,
  #field_staff:hover #field_staff_vtl{
    display: block;
  } */
  #field_staff_details{
    left: -5vw;
    rotate: -94deg;
    width: 350%;
    top: unset;
  }

  #bikes{
    top: 43%;
    left: 20%;
  }
  #bikes_htl{
    width: 21vw;
    bottom: -2px;
    left: 33px;
    rotate: 33deg;
    display: none;
  }
  #bikes_vtl{
    width: 8vw;
    bottom: -1px;
    right: -8vw;
    rotate: -22deg;
    display: none;
  }
  /* #bikes:hover #bikes_htl,
  #bikes:hover #bikes_vtl{
    display: block;
  } */
  #bikes_details{
    left: 8vw;
    bottom: -9vw;
    transform-origin: left;
    rotate: -55deg;
    width: 621%;
  }
  
  #forklift{
    top: 42%;
    left: 53%;
  }
  #forklift_htl{
    width: 15vw;
    bottom: -2px;
    left: 73px;
    rotate: 33deg;
    display: none;
  }
  #forklift_vtl{
    width: 7vw;
    bottom: -1px;
    right: -7vw;
    rotate: 60deg;
    display: none;
  }
  /* #forklift:hover #forklift_htl,
  #forklift:hover #forklift_vtl{
    display: block;
  } */
  #forklift_details{
    left: -8vw;
    rotate: -93deg;
    width: 450%;
    top: unset;
  }
  
  #chain{
    top: 27%;
    right: 25%;
  }
  #chain_htl{
    width: 22vw;
    bottom: -1px;
    left: 73px;
    rotate: 65deg;
    display: none;
  }
  #chain_vtl{
    width: 7vw;
    bottom: -1px;
    right: -7vw;
    rotate: 20deg;
    display: none;
  }
  #chain_details{
    left: -8vw;
    rotate: -85deg;
    width: 450%;
  }
  
  #boxes{
    top: 19%;
    right: 7%;
  }
  
  #boxes_htl{
    width: 32vw;
    bottom: -5px;
    left: 50px;
    rotate: 94deg;
    display: none;
  }

  #boxes_vtl{
    width: 15vw;
    bottom: -2px;
    right: -15vw;
    rotate: 20deg;
    display: none;
  }
  #boxes_details{
    left: -12vw;
    rotate: -114deg;
    width: 341%;
    top: unset;
  }
  
  #railway{
    bottom: 26%;
    left: 38%;
  }
  #railway_htl{
    width: 10vw;
    bottom: -3px;
    left: 73px;
    rotate: 30deg;
    display: none;
  }
  #railway_vtl{
    width: 12vw;
    bottom: -1px;
    right: -12vw;
    rotate: -34deg;
    display: none;
  }
  #railway_details{
    left: 13vw;
    bottom: -3vw;
    transform-origin: left;
    rotate: 3deg;
    width: 254%;
  }
  
  #trucks{
    top: 43%;
    right: 9%;
  }
  #trucks_htl{
    width: 7vw;
    bottom: -1px;
    left: 43px;
    rotate: 96deg;
    display: none;
  }
  #trucks_vtl{
    width: 5vw;
    bottom: -1px;
    right: -5vw;
    rotate: 20deg;
    display: none;
  }
  #trucks_details{
    left: -8vw;
    rotate: -116deg;
    width: 550%;
    top: unset;
  }
}
@media (min-width: 950px){
  .outer_container > img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 120vh;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
  .title_pill{
    padding: 0.4rem 1rem;
  }
  .title_pill > span{
    font-size: 15px;
  }
  .detail_box{
    padding: 1.5rem;
    font-size: 14px;
  }
  #buses{
    top: 7%;
    left: 20%;
  }
  #buses_htl{
    width: 50vw;
    bottom: -1px;
    left: 73px;
    rotate: 32deg;
    display: none;
  }
  #buses_vtl{
    width: 12vw;
    bottom: -1px;
    right: -12vw;
    rotate: 60deg;
    display: none;
  }
  /* #buses:hover #buses_htl,
  #buses:hover #buses_vtl{
    display: block;
  } */
  #buses_details{
    left: -5vw;
    rotate: -92deg;
    width: 300%;
  }
  
  #vans{
    top: 21%;
    left: 34%;
  }
  #vans_htl{
    width: 44vw;
    bottom: -1px;
    left: 73px;
    rotate: 32deg;
    display: none;
  }
  #vans_vtl{
    width: 7vw;
    bottom: -1px;
    right: -7vw;
    rotate: 60deg;
    display: none;
  }
  /* #vans:hover #vans_htl,
  #vans:hover #vans_vtl{
    display: block;
  } */
  #vans_details{
    left: -5vw;
    rotate: -92deg;
    width: 350%;
  }
  
  #field_staff{
    top: 30.5%;
    left: 43%;
  }
  #field_staff_htl{
    width: 25vw;
    bottom: -1px;
    left: 73px;
    rotate: 33deg;
    display: none;
  }
  #field_staff_vtl{
    width: 7vw;
    bottom: -1px;
    right: -7vw;
    rotate: 60deg;
    display: none;
  }
  /* #field_staff:hover #field_staff_htl,
  #field_staff:hover #field_staff_vtl{
    display: block;
  } */
  #field_staff_details{
    left: -5vw;
    rotate: -94deg;
    width: 350%;
    top: unset;
  }
  
  #bikes{
    top: 47%;
    left: 36%;
  }
  #bikes_htl{
    width: 21vw;
    bottom: -2px;
    left: 33px;
    rotate: 33deg;
    display: none;
  }
  #bikes_vtl{
    width: 4vw;
    bottom: -1px;
    right: -4vw;
    rotate: -20deg;
    display: none;
  }
  /* #bikes:hover #bikes_htl,
  #bikes:hover #bikes_vtl{
    display: block;
  } */
  #bikes_details{
    left: 4vw;
    bottom: -6vw;
    transform-origin: left;
    rotate: -13deg;
    width: 710%;
  }
  
  #forklift{
    top: 39%;
    left: 53%;
  }
  #forklift_htl{
    width: 15vw;
    bottom: -2px;
    left: 73px;
    rotate: 33deg;
    display: none;
  }
  #forklift_vtl{
    width: 7vw;
    bottom: -1px;
    right: -7vw;
    rotate: 60deg;
    display: none;
  }
  /* #forklift:hover #forklift_htl,
  #forklift:hover #forklift_vtl{
    display: block;
  } */
  #forklift_details{
    left: -8vw;
    rotate: -93deg;
    width: 450%;
    top: unset;
  }
  
  #chain{
    top: 20%;
    right: 37%;
  }
  #chain_htl{
    width: 22vw;
    bottom: -1px;
    left: 73px;
    rotate: 65deg;
    display: none;
  }
  #chain_vtl{
    width: 7vw;
    bottom: -1px;
    right: -7vw;
    rotate: 20deg;
    display: none;
  }
  /* #chain:hover #chain_htl,
  #chain:hover #chain_vtl{
    display: block;
  } */
  #chain_details{
    left: -8vw;
    rotate: -85deg;
    width: 450%;
  }
  
  #boxes{
    top: 14%;
    right: 26%;
  }

  #boxes_htl{
    width: 26vw;
    bottom: -5px;
    left: 50px;
    rotate: 94deg;
    display: none;
  }

  #boxes_vtl{
    width: 15vw;
        bottom: -2px;
        right: -15vw;
        rotate: 11deg;
        display: none;
  }
  #boxes_details{
    left: -11vw;
    rotate: -105deg;
    width: 341%;
    top: unset;
  }
  #railway{
    bottom: 26%;
    left: 38%;
  }
  #railway_htl{
    width: 10vw;
    bottom: -3px;
    left: 73px;
    rotate: 30deg;
    display: none;
  }
  #railway_vtl{
    width: 12vw;
    bottom: -1px;
    right: -12vw;
    rotate: -34deg;
    display: none;
  }
  /* #railway:hover #railway_htl,
  #railway:hover #railway_vtl{
    display: block;
  } */
  #railway_details{
    left: 13vw;
    bottom: -3vw;
    transform-origin: left;
    rotate: 3deg;
    width: 254%;
  }
  
  #trucks{
    top: 46%;
    right: 20%;
  }
  #trucks_htl{
    width: 7vw;
    bottom: -1px;
    left: 43px;
    rotate: 96deg;
    display: none;
  }
  #trucks_vtl{
    width: 5vw;
    bottom: -1px;
    right: -5vw;
    rotate: 20deg;
    display: none;
  }
  /* #trucks:hover #trucks_htl,
  #trucks:hover #trucks_vtl{
    display: block;
  } */
  #trucks_details{
    left: -8vw;
    rotate: -116deg;
    width: 550%;
    top: unset;
  }
}
@media (min-width: 1200px){
  #buses{
    top: 3%;
    left: 22%;
  }
  #vans{
    top: 12.7%;
    left: 32%;
  }
  #field_staff{
    top: 27%;
    left: 42%;
  }
  #bikes{
    top: 46%;
    left: 36%;
  }
  #forklift{
    top: 36.5%;
    left: 53%;
  }
  #chain{
    top: 19%;
    right: 37.5%;
  }
  #boxes{
    top: 6%;
    right: 27%;
  }
  #boxes_htl{
    width: 17vw;
    bottom: -6px;
    left: 99px;
    rotate: 81deg;
    display: none;
  }

  #boxes_vtl{
    width: 15vw;
    bottom: -1px;
    right: -15vw;
    rotate: 19deg;
    display: none;
  }
  #boxes_details{
    left: -4vw;
    rotate: -100deg;
    width: 218%;
    top: unset;
  }
  #railway{
    bottom: 18%;
    left: 37%;
  }
  #trucks{
    top: 43%;
    right: 17%;
  }
}

@media (min-width: 1400px){
  .detail_box{
    font-size: 14px;
  }

  #buses{
    top: 8%;
    left: 20%;
  }
  #vans{
    top: 20.7%;
    left: 32%;
  }
  #field_staff{
    top: 31%;
    left: 42%;
  }
  #bikes{
    top: 47%;
    left: 36%;
  }
  #forklift{
    top: 39.5%;
    left: 53%;
  }
  #chain{
    top: 21%;
    right: 35.5%;
  }
  #boxes{
    top: 12%;
    right: 22%;
  }
  #railway{
    bottom: 24%;
    left: 37%;
  }
  #trucks{
    top: 46%;
    right: 17%;
  }
}

@media (min-width: 1600px){
  .detail_box{
    font-size: 16px;
  }
  #buses{
    top: 8%;
    left: 24%;
  }
  #vans{
    top: 17%;
    left: 36%;
  }
  #field_staff{
    top: 28%;
    left: 45%;
  }
  #bikes{
    top: 43%;
    left: 36%;
  }
  #forklift{
    top: 36.5%;
    left: 55%;
  }
  #chain{
    top: 19%;
    right: 38.5%;
  }
  #boxes{
    top: 8%;
    right: 26%;
  }
  #railway{
    bottom: 24%;
    left: 35%;
  }
  #trucks{
    top: 45%;
    right: 19%;
  }
}