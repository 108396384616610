.client_container{
  padding: 2rem 0px;
}

.text_content_outer{
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
  padding: 0px 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.text_content_outer > p{
  margin: none !important;
  text-align: center;
}

.text_content_outer > .info{
  font-size: 1.2rem;
  font-weight: 200;
  line-height: 121%;
}
.text_content_outer > .title{
  font-size: 4rem;
  font-weight: 300;
  line-height: 116%;
}

.text_content_outer > .divider{
  width: 80%;
  height: 1.5px;
  background: linear-gradient(to right, transparent, #ff7700, transparent);
}

.ticker_wrapper {
  width: 100%;
  overflow: hidden;
  padding: 10px;
  position: relative;
  margin: 2rem 0px;
  border-top: 1px solid #DADADA;
  border-bottom: 1px solid #DADADA;
}

.ticker {
  display: flex;
  width: max-content;
  animation: ticker-animation 180s linear infinite;
  gap: 3.5rem;
  animation-play-state: running;
}

.ticker-item {
  flex-shrink: 0;
  margin-right: 20px; /* Adjust margin */
  filter: grayscale(1);
  padding: 1.5rem;
}

.ticker_wrapper:hover .ticker{
  animation-play-state: paused;
}

.ticker-item:hover{
  filter: grayscale(0);
}

@keyframes ticker-animation {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%); /* Move left by 50% to loop without gaps */
  }
}

.ticker img {
  width: auto; /* Adjust as needed */
  height: 60px; /* Maintain consistent height */
  display: block;
}

@media (max-width:500px){
  .text_content_outer > .info{
    font-size: 1rem;
    line-height: 130%;
  }

  .ticker {
    gap: 0.5rem;
  }
  .ticker-item {
    padding: 0.5rem;
  }
  
.ticker img {
  width: 121px;
  height: 68px;
  display: block;
}
}