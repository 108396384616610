.styled_heading{
  font-size: 2rem;
}

/* Base styles for all grid items */
.grid-item {
  box-sizing: border-box;
  flex-grow: 0;
}

/* Extra-small screens (default) */
.grid-xs-1 {
  flex-basis: 8.3333% !important;
  max-width: 8.3333% !important;
}
.grid-xs-2 {
  flex-basis: 16.6667% !important;
  max-width: 16.6667% !important;
}
.grid-xs-3 {
  flex-basis: 25% !important;
  max-width: 25% !important;
}
.grid-xs-4 {
  flex-basis: 33.3333% !important;
  max-width: 33.3333% !important;
}
.grid-xs-5 {
  flex-basis: 41.6667% !important;
  max-width: 41.6667% !important;
}
.grid-xs-6 {
  flex-basis: 50% !important;
  max-width: 50% !important;
}
.grid-xs-7 {
  flex-basis: 58.3333% !important;
  max-width: 58.3333% !important;
}
.grid-xs-8 {
  flex-basis: 66.6667% !important;
  max-width: 66.6667% !important;
}
.grid-xs-9 {
  flex-basis: 75% !important;
  max-width: 75% !important;
}
.grid-xs-10 {
  flex-basis: 83.3333% !important;
  max-width: 83.3333% !important;
}
.grid-xs-11 {
  flex-basis: 91.6667% !important;
  max-width: 91.6667% !important;
}
.grid-xs-12 {
  flex-basis: 100% !important;
  max-width: 100% !important;
}

/* Small screens */
@media (min-width: 600px) {
  .grid-sm-1 {
    flex-basis: 8.3333% !important;
    max-width: 8.3333% !important;
  }
  .grid-sm-2 {
    flex-basis: 16.6667% !important;
    max-width: 16.6667% !important;
  }
  .grid-sm-3 {
    flex-basis: 25% !important;
    max-width: 25% !important;
  }
  .grid-sm-4 {
    flex-basis: 33.3333% !important;
    max-width: 33.3333% !important;
  }
  .grid-sm-5 {
    flex-basis: 41.6667% !important;
    max-width: 41.6667% !important;
  }
  .grid-sm-6 {
    flex-basis: 50% !important;
    max-width: 50% !important;
  }
  .grid-sm-7 {
    flex-basis: 58.3333% !important;
    max-width: 58.3333% !important;
  }
  .grid-sm-8 {
    flex-basis: 66.6667% !important;
    max-width: 66.6667% !important;
  }
  .grid-sm-9 {
    flex-basis: 75% !important;
    max-width: 75% !important;
  }
  .grid-sm-10 {
    flex-basis: 83.3333% !important;
    max-width: 83.3333% !important;
  }
  .grid-sm-11 {
    flex-basis: 91.6667% !important;
    max-width: 91.6667% !important;
  }
  .grid-sm-12 {
    flex-basis: 100% !important;
    max-width: 100% !important;
  }
}

/* Medium screens */
@media (min-width: 960px) {
  .styled_heading{
    font-size: 2.2rem;
  }

  /* Grid styles */
  .grid-md-1 {
    flex-basis: 8.3333% !important;
    max-width: 8.3333% !important;
  }
  .grid-md-2 {
    flex-basis: 16.6667% !important;
    max-width: 16.6667% !important;
  }
  .grid-md-3 {
    flex-basis: 25% !important;
    max-width: 25% !important;
  }
  .grid-md-4 {
    flex-basis: 33.3333% !important;
    max-width: 33.3333% !important;
  }
  .grid-md-5 {
    flex-basis: 41.6667% !important;
    max-width: 41.6667% !important;
  }
  .grid-md-6 {
    flex-basis: 50% !important;
    max-width: 50% !important;
  }
  .grid-md-7 {
    flex-basis: 58.3333% !important;
    max-width: 58.3333% !important;
  }
  .grid-md-8 {
    flex-basis: 66.6667% !important;
    max-width: 66.6667% !important;
  }
  .grid-md-9 {
    flex-basis: 75% !important;
    max-width: 75% !important;
  }
  .grid-md-10 {
    flex-basis: 83.3333% !important;
    max-width: 83.3333% !important;
  }
  .grid-md-11 {
    flex-basis: 91.6667% !important;
    max-width: 91.6667% !important;
  }
  .grid-md-12 {
    flex-basis: 100% !important;
    max-width: 100% !important;
  }
}

/* Large screens */
@media (min-width: 1280px) {
  .styled_heading{
    font-size: 3rem;
  }

  .grid-lg-1 {
    flex-basis: 8.3333% !important;
    max-width: 8.3333% !important;
  }
  .grid-lg-2 {
    flex-basis: 16.6667% !important;
    max-width: 16.6667% !important;
  }
  .grid-lg-3 {
    flex-basis: 25% !important;
    max-width: 25% !important;
  }
  .grid-lg-4 {
    flex-basis: 33.3333% !important;
    max-width: 33.3333% !important;
  }
  .grid-lg-5 {
    flex-basis: 41.6667% !important;
    max-width: 41.6667% !important;
  }
  .grid-lg-6 {
    flex-basis: 50% !important;
    max-width: 50% !important;
  }
  .grid-lg-7 {
    flex-basis: 58.3333% !important;
    max-width: 58.3333% !important;
  }
  .grid-lg-8 {
    flex-basis: 66.6667% !important;
    max-width: 66.6667% !important;
  }
  .grid-lg-9 {
    flex-basis: 75% !important;
    max-width: 75% !important;
  }
  .grid-lg-10 {
    flex-basis: 83.3333% !important;
    max-width: 83.3333% !important;
  }
  .grid-lg-11 {
    flex-basis: 91.6667% !important;
    max-width: 91.6667% !important;
  }
  .grid-lg-12 {
    flex-basis: 100% !important;
    max-width: 100% !important;
  }
}

/* Extra-large screens */
@media (min-width: 1920px) {
  .grid-xl-1 {
    flex-basis: 8.3333% !important;
    max-width: 8.3333% !important;
  }
  .grid-xl-2 {
    flex-basis: 16.6667% !important;
    max-width: 16.6667% !important;
  }
  .grid-xl-3 {
    flex-basis: 25% !important;
    max-width: 25% !important;
  }
  .grid-xl-4 {
    flex-basis: 33.3333% !important;
    max-width: 33.3333% !important;
  }
  .grid-xl-5 {
    flex-basis: 41.6667% !important;
    max-width: 41.6667% !important;
  }
  .grid-xl-6 {
    flex-basis: 50% !important;
    max-width: 50% !important;
  }
  .grid-xl-7 {
    flex-basis: 58.3333% !important;
    max-width: 58.3333% !important;
  }
  .grid-xl-8 {
    flex-basis: 66.6667% !important;
    max-width: 66.6667% !important;
  }
  .grid-xl-9 {
    flex-basis: 75% !important;
    max-width: 75% !important;
  }
  .grid-xl-10 {
    flex-basis: 83.3333% !important;
    max-width: 83.3333% !important;
  }
  .grid-xl-11 {
    flex-basis: 91.6667% !important;
    max-width: 91.6667% !important;
  }
  .grid-xl-12 {
    flex-basis: 100% !important;
    max-width: 100% !important;
  }
}