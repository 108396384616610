.title{
  font-size: 4rem;
  font-weight: 300;
  line-height: 116%;
  display: inline-block;
  color: #fff;
}
@media (max-width:1050px){
  .title{
    font-size: 3rem;
  }
}

@media (max-width:850px){
  .title{
    font-size: 2rem;
  }

}



@media (max-width:500px){
  .title{
    font-size: 1.75rem;
  }

}