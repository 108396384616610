.container {
    display: flex;
    justify-content: center;
    color: #FFFFFF;
    margin: 7rem 0rem  ;
}
.flexContainer{
    display: flex;
    justify-content: center;
    gap: 5rem;
}
.straightLine{
    border: 1px solid transparent;
    border-image: linear-gradient(180deg, #000000 0%, #FF7200 25.5%, #FF7200 25.5%, #000000 100%);
    border-image-slice: 5;   
    height: 572px;
    margin-top: -4rem;
}
.flexContainerLeftPart{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}
.flexContainerRighttPart{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}
.leftContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.6rem;
}
.leftContentHead{
    font-size: 36px;
}
.leftContentpara{
    font-size: 17px;
    max-width: 319px;
    text-align: center;
} 
.action_btn_container>button {
    box-shadow: none;
    border: none;
    padding: 0.5rem 1.2rem;
    border-radius: 0.3rem;
    color: #fff;
    font-weight: 600;
}  
.action_btn_container>button:hover {
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.6);
}  
.filled_orange {
    background: linear-gradient(90deg, #FF7700 0%, #994700 100%);
  }
.border-container {
    position: relative;
    width: 300px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.outer-border {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid transparent;
    border-image: linear-gradient(113deg, #331800 0%, #FF7700 50%, #000000 100%);
    border-image-slice: 5;
    box-sizing: border-box;
    border-image-repeat: stretch;
}

.inner-border {
    position: absolute;
    width: 70%;
    height: 70%;
    border: 1px solid #8D8D8D;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}
.inner-border>img{
    height: 100px;
}
.connecting_lines {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

/* Top-left to bottom-right connecting line */
.connecting_lines::before {
    content: '';
    position: absolute;
    width: 1px;
    height: 22%;
    background-color: #8D8D8D;
    top: 0;
    left: 0;
    transform: rotate(-45deg);
    transform-origin: top left;
    z-index: 1;
}

/* Top-right to bottom-left connecting line */
.connecting_lines::after {
    content: '';
    position: absolute;
    width: 1px;
    height: 22%;
    background-color: #8D8D8D;
    top: 0;
    right: 0;
    transform: rotate(45deg);
    transform-origin: top right;
    z-index: 1;
}

/* Bottom-left to top-right connecting line */
.connecting_lines .bottom_left_to_top_right {
    content: '';
    position: absolute;
    width: 1px;
    height: 22%;
    background-color: #8D8D8D;
    bottom: 0;
    left: 0;
    transform: rotate(45deg);
    transform-origin: bottom left;
    z-index: 1;
}

/* Bottom-right to top-left connecting line */
.connecting_lines .bottom_right_to_top_left {
    content: '';
    position: absolute;
    width: 1px;
    height: 22%;
    background-color: #8D8D8D;
    bottom: 0;
    right: 0;
    transform: rotate(-45deg);
    transform-origin: bottom right;
    z-index: 1;
}

.corner {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #FF7700;
    z-index: 2;
}

/* Positioning the squares at each corner */
.top-left {
    top: -5px;
    left: -5px;
}

.top-right {
    top: -5px;
    right: -5px;
}

.bottom-left {
    bottom: -5px;
    left: -5px;
}

.bottom-right {
    bottom: -5px;
    right: -5px;
}

@media (max-width:850px){
    .flexContainer{
        gap:2rem
    }
}