.hero_section{
    overflow-x: hidden;
    overflow-y: auto;
    display: none;
  }
 
  .main_img_with_text_container{
    background: linear-gradient(#202020 10%, #000000);
    min-height: 95vh;
    height: 95vh;
    position: relative;
    animation: fadeIn 3s;
  }


  .main_backdrop{
    height: 100%;
    width: 100%;
  }
  
  .main_backdrop img{
    height: 100%;
    width: 100%;
    margin-left: 10vw;
     object-fit: contain;
  }

  .main_title_section{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: absolute;
    left: 2.3rem;
    top: 50%;
    transform: translateY(-50%);
    max-width: 500px;
  }

  .main_title_section > p{
    font-family: "Fjalla One", sans-serif !important;
    font-size: 34px;
    font-weight: 700;
    color: #fff;
    white-space: pre-line;
    text-transform: capitalize;
    /* line-height: 3.2rem; */
  }
  .main_title_section >p>span{
    font-family: "Fjalla One", sans-serif !important;
  }
  .text_bg{
    background: url('../../assets/hero/content_background_color.svg') no-repeat center center/cover;
  }
  .button_container{
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 22px;
  }
  .app_btns{
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .app_btn{
    cursor: pointer;
    height: 30px;
    width: 30px;
  }
  
  .app_btn > img{
    height: 100%;
    width: 100%;
  }
  


.heroContainer{
    background-color: #171717;
   position: relative;
    height: 90vh;
   max-height: 585px;
}

.mobileImage {
    width: 100%;
    height: auto;
  }
  
  .d_text_container {
    position: absolute;
    top: 72%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    min-width: 100%;
  }
  .firstPara{
    color: rgba(198, 198, 198, 1);
    font-weight: 400;
    font-size: 22px;
    font-family: "Fjalla One", sans-serif;

  }

  .secondPara{
    color: rgba(255, 119, 0, 1);
    font-size: 23px;
    font-weight: 500;
    font-family: "Fjalla One", sans-serif;
    
  }
  .thirdPara{
    background: url('../../assets/hero/content_background_color.svg') no-repeat center center/cover;
    color: #FFFFFF;
    font-size: 22px;
    font-weight: 600;
    font-family: "Fjalla One", sans-serif;
  }

@media (min-width:325px){
    .mobileImage {
        
        height: 59vh;
        max-height: 391px;
      }
      .button_container{
        justify-content: center;
       margin-top: 6px;
      }
}

@media (min-width:500px){
    .mobileImage {
        height: 62vh;
      }
      .firstPara{
        font-weight: 400;
        font-size: 24px;
      }
    
      .secondPara{
        font-size: 27px;
        font-weight: 500;
        
      }
      .thirdPara{
        font-size: 28px;
        font-weight: 600;
      }
      .button_container{
       margin-top: 2px;
      }
}
@media (min-width:700px){
    .mobileImage {
        height: 70vh;
      }

      .d_text_container {
        top: 71%;       
      }
      .secondPara{
        font-size: 29px;
      }
}






@media (min-width: 980px){
    .app_btns{
      display: none;
    }
 
  .hero_section{
    display: block;
  }
  .heroContainer{
    display: none;
  }
  .button_container{
    justify-content: flex-start;
  }
  }

  
@media (min-width: 1000px) {
    .main_title_section{
      left: 6rem;
    }
  
    .main_title_section > p{
      font-size: 34px;
    }
  }
  
  @media (min-width: 1300px) {
    .main_title_section{
      left: 7rem;
      max-width: 700px;
    }
  
    .main_title_section > p{
      font-size: 40px;
    }
  }
  
  @media (min-width: 1400px) {
    .main_title_section > p{
      font-size: 42px;
    }
  
    .main_title_section{
      left: 8rem;
      max-width: 1000px;
    }
  }