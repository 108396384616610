.custom_toast {
  position: fixed;
  bottom: 15%;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  animation: fadeInOut 3s;
  z-index: 1000;
  font-weight: 700;
}

.custom_toast.success {
  background-color: #4caf50; /* Green for success */
}

.custom_toast.error {
  background-color: #f44336; /* Red for error */
}

.toast_close-btn {
  background: none;
  border: none;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  margin-left: 10px;
}


.middle_custom_toast {
  /* position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  position: relative;
  padding: 10px 20px;
  border-radius: 5px;
  color: #000;
  background: #fff;
  font-size: 17px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  /* animation: fadeInOut 3s; */
  z-index: 1000;
  font-weight: 700;
  max-width: 500px;
  min-height: 300px;
  width: 100%;
  border-radius: 10px;
  box-shadow: 1px 1px 5px #646464c9;
}

.middle_custom_toast > img{
  height: 120px;
  width: 120px;
}

.middle_custom_toast .toast_msg{
  text-align: center;
}

.middle_custom_toast > .middle_toast_close-btn{
  position: absolute;
  top: 2px;
  right: 10px;
  background: none;
  border: none;
  color: #000;
  font-size: 30px;
  cursor: pointer;
  margin-left: 10px;
  text-align: center;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: translateY(20px) translateX(-50%);
  }
  10%, 90% {
    opacity: 1;
    transform: translateY(0) translateX(-50%);
  }
  100% {
    opacity: 0;
    transform: translateY(20px) translateX(-50%);
  }
}