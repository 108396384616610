.hardware_container_outer{
  padding: 2rem 0.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.heading_para{
  position: relative;
  top: -11%;
  left: 0%;
}
.hardware_products_container{
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.product_grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}

.product_box {
  cursor: pointer;
  text-align: center;
  display: flex;
  height: 215px;
  position: relative;
}

.product_img_div {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F4F4F4;
  width: 50%;
  padding: 1.5rem;
}

.product_image {
  /* max-width: 120px;
  padding: 2rem; */
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.right_part {
  width: 50%;
}

.product_title {
  font-size: 17px;
  margin-top: 10px;
  color: #4D4E4F;
}

.product_details {
  margin-left: 12px;
  font-size: 12px;
  color: #666;
}

.right_part {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.selected .right_part {
  color: white;
}

.selected .product_title {
  color: #FF7700;
}

.selected .product_details {
  color: #FF7700;
}

.selected .product_img_div {
  background-color: #FF7700;
}

/* Top-Left Gradient */
.gradientTopLeft {
  border: 1px solid transparent;
  border-image: linear-gradient(135deg, #CBCBCB 0%, transparent 100%) 1;
}

/* Bottom-Left Gradient */
.gradientBottomLeft {
  border: 1px solid transparent;
  border-image: linear-gradient(225deg, #CBCBCB 0%, transparent 100%) 1;
}

/* Bottom-Right Gradient */
.gradientBottomRight {
  border: 1px solid transparent;
  border-image: linear-gradient(315deg, #CBCBCB 0%, transparent 100%) 1;
}

/* Top-Right Gradient */
.gradientTopRight {
  border: 1px solid transparent;
  border-image: linear-gradient(45deg, #CBCBCB 0%, transparent 100%) 1;
}

/* Add two triangles between the image and text */
.product-box {
  position: relative;
}

.selected::before,
.selected::after {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
}

.selected::before {
  left: 50%;
  border-left: 15px solid #FF7700; /* White triangle */
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
}

.selected::after {
  right: 50%;
  border-right: 15px solid #FFFFFF;
  ; /* Orange triangle */
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
}

@media screen and (min-width: 645px) {
  .product_grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 950px) {
  .product_grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1300px) {
  .hardware_container_outer {
    padding: 2rem 1rem;
  }
 
}

@media screen and (min-width: 1101px) {
  .product_grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (min-width: 1250px) {
  .hardware_container_outer {
    padding: 2rem 2rem;
  }
  .heading_para{
    left: 27%;
  }
}

@media screen and (min-width: 1500px) {
  .hardware_container_outer {
    padding: 2rem 2rem;
  }
}

@media screen and (min-width: 1600px) {
  .hardware_container_outer {
    padding: 2rem 2rem;
  }
}
