.gradient_line {
    width: 60%;
    height: 0;
    border-top: 2px solid;
    border-image-source: linear-gradient(90deg, #000000 3.71%, #FF7200 36.5%, #FF7200 69.5%, #000000 98%);
    border-image-slice: 1;
    margin: 1rem auto;
}

.large_screens {
    display: none;
    max-width: 1250px;
    margin: 0 auto;
}

.software_features_section {
    width: 85%;
    margin: auto;
    /* padding: 2rem 5rem; */
    padding-bottom:1rem ;
}

.features_outer_container {
    padding: 2rem 0rem;
    position: relative;
}

.feature_tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding: 0px 1rem;
    position: relative;
    z-index: 2;
}

.feature_tab {
    /* width: 180px; */
    width: 100%;
    max-width: 270px;
    height: 150px;
    padding: 0.1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;
    border: 1.5px solid #A09A9A;
    background-color: #1B1B1B;
    box-shadow:
        0 4px 12px rgba(0, 0, 0, 0.15),
        /* Outer shadow */
        inset 0px 0px 18px 40px rgba(0, 0, 0, 0.02);
    /* Inner shadow */
    cursor: pointer;
    transition: transform 0.2s;
    z-index: 1;
    position: relative;
}

.feature_tab:hover {
    transform: translateY(-5px);
}

.feature_tab>img {
    height: 50px;
    width: 50px;
}

.feature_tab>.title {
    font-size: 14px;
    color: #595959;
    text-align: center;
    font-weight: 600;
}

.feature_content {
    padding: 0px 1rem;
    display: flex;
    gap: 1rem;
    justify-content: center;
    /* align-items: center; */
}

.feature_content>.text_content_sf {
    padding-top: 0.5rem;
    z-index: 1;
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.feature_content>.text_content_sf>span {
    font-size: 1.3rem;
    white-space: pre-wrap;
    font-weight: 700;
    color: #FF7700;
    font-family: Fjalla One;
}

.feature_content>.text_content_sf>p {
    font-size: 1rem;
    white-space: pre-wrap;
}

.feature_content>.img_content {
    width: 100%;
    height: 34vw;
    min-height: 400px;
    max-height: 610px;
    max-width: 350px;
    padding-top: 1rem;
    z-index: 1;
}

.feature_content>.img_content img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

/* Corner Dots */

.feature_tab {
    position: relative;
}

.cornerDots {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

/* Dot styles for all four corners */
.cornerDots::before,
.cornerDots::after,
.cornerDots .bottomLeft,
.cornerDots .bottomRight {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: #FF7700;
}

/* Top-left corner dot */
.cornerDots::before {
    top: -5px;
    left: -5px;
}

/* Top-right corner dot */
.cornerDots::after {
    top: -5px;
    right: -5px;
}

/* Bottom-left corner dot */
.cornerDots .bottomLeft {
    bottom: -5px;
    left: -5px;
}

/* Bottom-right corner dot */
.cornerDots .bottomRight {
    bottom: -5px;
    right: -5px;
}

/* Ensure bottom-left and bottom-right dots are correctly added */
.cornerDots .bottomLeft,
.cornerDots .bottomRight {
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: #FF7700;
}

/* Add the bottom-left dot to the container */
.cornerDots .bottomLeft {
    bottom: -5px;
    left: -5px;
}

/* Add the bottom-right dot to the container */
.cornerDots .bottomRight {
    bottom: -5px;
    right: -5px;
}


.outline_container {
    /* position: absolute;
    top: calc(75px + 1px);
    left: 50%;
    transform: translateX(-50%);
    height: calc(104% - 150px);
    width: 93%;
    border-radius: 1rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    z-index: 0;
    background: linear-gradient(180deg, #000000 2%, rgba(114, 114, 114, 0.49) 99.99%, rgba(115, 115, 115, 0) 100%); */

    position: absolute;
    top: calc(0px + 1px);
    /* left: 2.8rem; */
    left: 50%;
    transform: translateX(-50%);
    height: calc(117% - 150px);
    width: 100%;
    /* border: 2px dashed rgba(82, 82, 82, 0.3); */
    border-radius: 1rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    z-index: 0;
    background: linear-gradient(180deg, #000000 2%, rgba(114, 114, 114, 0.49) 99.99%, rgba(115, 115, 115, 0) 100%);
}

.outline_container>button {
    position: absolute;
    bottom: -20px;
    left: 2rem;
}

/* mobile slider */
.slider-container {
    width: 100%;
    overflow-x: auto;
    /* Enable horizontal scrolling */
    scroll-snap-type: x mandatory;
    /* Snap to items when scrolling */
    display: flex;
    -webkit-overflow-scrolling: touch;
    /* Smooth scrolling on iOS */
}

.slider-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    /* Ensure wrapper takes full width */
    padding: 20px 10vw;
    /* Add padding to the wrapper to create space for peeking items */
}

.slider-item {
    flex: 0 0 80vw;
    /* Adjust the width to be slightly smaller than the viewport */
    margin-right: 2vw;
    /* Add some margin to create space between items */
    scroll-snap-align: center;
    /* Center the active item */
    /* background: #fff; */
    background: linear-gradient(180deg, #000000 0%, rgb(0 0 0 / 49%) 67.99%, rgba(115, 115, 115, 0) 100%) padding-box, linear-gradient(180deg, #FFffff 0%, #000000 100%) border-box;
    border: 2px solid transparent;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: center;
    overflow: hidden;
    /* Hide overflow within the item */
}

.slider-item img {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.slider-item>.title-row {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.slider-item>.title-row>img {
    height: 50px !important;
    width: 50px !important;
}

.slider-item>.title-row>h3 {
    margin-bottom: 0px !important;
    color: #FF7700;
}

.button_small_screen {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4px;
    position: relative;
    top: 20px;

}

.small_screens_button_outliner {
    top: calc(75px + 20px);
    height: calc(104% - 150px);
    border: 2px dashed rgba(82, 82, 82, 0.3);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    width: 100%;

}

.custom_button {
    box-shadow: none;
    border: none;
    padding: 0.5rem 1.2rem;
    border-radius: 0.3rem;
    color: #fff;
    font-weight: 500;
    width: max-content;
}

.custom_button:hover {
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.6);
}

.filled_gray {
    background: #4A4A4A;
}

.filled_orange {
    background: linear-gradient(360deg, rgba(55, 55, 55, 0.78) -119.45%, #FF7700 107.15%);
}

@media (min-width: 801px) {
    .large_screens {
        display: block;
    }

    .small_screens {
        display: none;
    }

    .button_small_screen {
        display: none;
    }

    .small_screens_button_outliner {
        display: none;
    }
}

@media (min-width: 1001px) {
    .software_features_section {
        /* padding: 2rem 1rem; */
        width: 85%;
        margin: auto;
    }

    .feature_tab>img {
        height: 70px;
        width: 70px;
    }

    .feature_content>.text_content_sf {
        padding-top: 2rem;
        width: 40%;
        gap: 1rem;
    }

    .feature_content>.text_content_sf>span {
        font-size: 2rem;
    }

    .feature_content>.text_content_sf>p {
        font-size: 1rem;
    }

    .feature_content>.img_content {
        width: 100%;
        height: 34vw;
        min-height: 400px;
        max-height: 610px;
        max-width: 600px;
        padding-top: 1rem;
        z-index: 1;
    }

    .outline_container {
        height: calc(120% - 150px);
    }

}

@media (min-width: 1250px) {
    .software_features_section {
        /* padding: 2rem 5rem; */
        width: 85%;
        margin: auto;
    }

    .outline_container {
        height: calc(120% - 150px);
    }
    .feature_content {
        gap: 5rem;
    }
}

@media screen and (min-width: 1500px) {
    .software_features_section {
        /* padding: 2rem 7rem; */
        width: 85%;
        margin: auto;
        /* width: max-content; */
    }

    .feature_tab>.title {
        font-size: 16px;
    }

    .feature_content>.img_content {
        max-width: 800px;
        width: 100%;
        height: 40vw;
        min-height: 400px;
        max-height: 610px;
        max-width: 600px;
        padding-top: 0rem;
        z-index: 1;
        margin-top: -56px;
    }

    .feature_content>.text_content_sf {
        width: 62%;
    }

    .feature_content>.text_content_sf>span {
        font-size: 2rem;
    }

    .feature_content>.text_content_sf>p {
        font-size: 1rem;
    }

    .outline_container {
        height: calc(105% - 150px);
    }
}

@media screen and (min-width: 1600px) {
    .feature_content>.text_content_sf {
        width: 84%;
    }

    .software_features_section {
        /* padding: 2rem 10rem; */
        width: 85%;
        margin: auto;
        /* width: max-content; */
    }

    .feature_content>.text_content_sf>p {
        font-size: 1.1rem;
    }

    .outline_container {
        height: calc(105% - 150px);
    }

}

@media screen and (min-width: 1733px) {
    .feature_content>.text_content_sf {
        width: 40%;
    }
}